import React, { useState } from 'react';
import { connect } from 'react-redux';
import { blogAgregar } from '../../store/actions';
import { Card, CardContent, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Regex, RegexExtra, Validate, Config } from '../../other';
import {
  InputUI,
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
  QuillUI,
  ImageUI,
  ToggleUI,
  NextActionsUI,
} from '../common';
import { WrapperLayout } from '../../layouts/WrapperLayout';

const BlogAgregar_ = ({ classes, blogAgregar }) => {
  const [state, setState] = useState({
    uploading_images: false,
    agregar: {
      pb_titulo: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Título',
        id: 'pb_titulo',
        name: 'pb_titulo',
        change_param: 'agregar',
        regex: Regex.STRING_GENERICO_2_90,
        value: '',
        default_value: '',
        placeholder: '10 tips para cuidar tu ropa',
        messages: {
          error: 'Por favor, ingrese un título válido',
          error_extra: RegexExtra.STRING_GENERICO_2_90,
        },
      },
      pb_contenido: {
        validate: false,
        value: '',
      },
      pb_portada: {
        validate: false,
        auth: true,
        uploading: false,
        required: false,
        label: 'Imagen de portada',
        id: 'portada',
        sublabels: [
          'Aparecerá en la lista de últimos artículos, recomendamos que sea una imagen de 960x600 de alto para óptima visualización',
        ],
        files: [],
        messages: {
          help: '',
          error: '',
        },
      },
    },
    forms: {
      agregar: false,
    },
    after: false,
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  });

  const handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    setState((prevState) => {
      const updatedAditional = {
        ...prevState[aditional],
        [name]: {
          ...prevState[aditional][name],
          value: value,
        },
      };

      if (blur) {
        Validate.validate_input(updatedAditional[name], blur, (input) => {
          updatedAditional[name] = input;
        });
      }

      return {
        ...prevState,
        [aditional]: updatedAditional,
      };
    });
  };

  const handleChangeQuill = (value) => {
    setState((prevState) => ({
      ...prevState,
      agregar: {
        ...prevState.agregar,
        pb_contenido: {
          ...prevState.agregar.pb_contenido,
          value: value,
        },
      },
    }));
  };

  const handleCloseAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  const handleChangeFile = (data) => {
    setState((prevState) => ({
      ...prevState,
      uploading_images: data.uploading,
      agregar: {
        ...prevState.agregar,
        pb_portada: data,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setState((prevState) => ({
      ...prevState,
      forms: {
        ...prevState.forms,
        agregar: true,
      },
    }));

    if (state.uploading_images === false) {
      let form = state.agregar;

      if (Validate.validar_formulario(form) && form.pb_portada.auth) {
        blogAgregar(
          {
            pb_titulo: form.pb_titulo.value,
            pb_contenido: form.pb_contenido.value,
            pb_portada: form.pb_portada.files.length
              ? form.pb_portada.files[0].link
              : null,
          },
          (err, resp) => {
            setState((prevState) => ({
              ...prevState,
              forms: {
                ...prevState.forms,
                agregar: false,
              },
              alert: {
                ...prevState.alert,
                open: true,
                message: resp.message,
                type: err ? 'error' : 'success',
              },
              after: err ? false : true,
            }));
          }
        );
      } else {
        Validate.validar_formulario_actualizar_obj(form, (new_form) => {
          setState((prevState) => ({
            ...prevState,
            forms: {
              ...prevState.forms,
              agregar: false,
            },
            agregar: new_form,
          }));
        });
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        forms: {
          ...prevState.forms,
          agregar: false,
        },
        alert: {
          ...prevState.alert,
          open: true,
          message: 'Debes esperar a que se cargue la imagen de portada',
          type: 'error',
        },
      }));
    }
  };

  return (
    <WrapperLayout loading={false} error={false}>
      <AlertUI
        open={state.alert.open}
        message={state.alert.message}
        type={state.alert.type}
        handleCloseAlert={handleCloseAlert}
      />
      <Grid container spacing={1.5}>
        <ToggleUI show={!state.after}>
          <Grid item xs={12}>
            <ModuleTitleUI title="Publicar nuevo artículo" />
            <Card className={classes.card}>
              <CardContent className={classes.card_content}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <InputUI
                      handleChange={handleChange}
                      input={state.agregar.pb_titulo}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ImageUI
                      data={state.agregar.pb_portada}
                      max_files={1}
                      max_size={7}
                      allowed_files={['jpg', 'png', 'jpeg', 'webp']}
                      input_allowed_mime="image/jpg,image/jpeg,image/png,image/webp"
                      handleChange={handleChangeFile}
                      cdn_prefix={Config.CLOUDFRONT_PORTADAS_CDN}
                      upload_path="cover"
                      row_height="250px"
                      reducer_max_width={960}
                      reducer_max_height={960}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      row_spacing={0}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <QuillUI
                      contenido={state.agregar.pb_contenido.value}
                      placeholder="Escriba aquí..."
                      handleChangeQuill={handleChangeQuill}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <ModuleButtonBoxUI>
              <ButtonUI
                type="callf"
                label="Publicar"
                minHeight={true}
                onClickFunc={(e) => handleSubmit(e)}
                isLoading={state.forms.agregar}
              />
            </ModuleButtonBoxUI>
          </Grid>
        </ToggleUI>
      </Grid>
      <ToggleUI show={state.after}>
        <NextActionsUI
          title="¡El artículo ha sido publicado con éxito!"
          buttons={[
            <ButtonUI type="link" label="Ver mis artículos" link="/blog" />,
            <ButtonUI
              type="link"
              label="Publicar otro artículo"
              link="/blog/agregar"
              external_link
            />,
          ]}
        />
      </ToggleUI>
    </WrapperLayout>
  );
};

const styles = (theme) => ({
  card: theme.card.card,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
  return {
    blogAgregar: (data, callback) => dispatch(blogAgregar(data, callback)),
  };
};

export const BlogAgregar = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(BlogAgregar_)
);
