import { Box, Typography } from '@mui/material';
import React from 'react';
import { amplitude_events } from '../../../components/amplitude';
import { BackgroundBox } from '../../../components/common/BackgroundBox/styles';
import MetricItem from './MetricItem';

const Metrics = ({ idCountry, overview, showBanner }) => {
  const mbContainer = !showBanner ? 0 : { xs: '195px', md: '70px' };

  return (
    <Box gridColumn={{ xs: '1 / 5', md: '1 / 9' }}>
      <BackgroundBox
        display="grid"
        mb={mbContainer}
        gap={{ xs: 1.5, sm: 2, lg: 3 }}
        sx={{ boxShadow: '0 0.07em 0.125em 0 rgba(0,0,0,.15)' }}
        gridTemplateColumns={{ xs: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
      >
        <Typography
          fontWeight="400"
          fontSize="1.12rem"
          lineHeight="24px"
          gridColumn={{ xs: '1 / 3', lg: '1 / 5' }}
        >
          Métricas
        </Typography>

        <MetricItem
          gridColumn={'1fr'}
          redirectUrl="/listado-de-ventas"
          sectionTitle="VENTAS ACTIVAS"
          information={overview.ventas_activas}
          amplitudeEvent={amplitude_events.ventas_activas.event_name}
        />

        <MetricItem
          gridColumn={'1fr'}
          redirectUrl="/listado-de-ventas"
          sectionTitle="ENVÍOS PENDIENTES"
          information={overview.envios_pendientes}
          amplitudeEvent={amplitude_events.envios_pendientes.event_name}
        />

        <MetricItem
          gridColumn={'1fr'}
          redirectUrl="/listado-de-ventas"
          sectionTitle="COBROS PENDIENTES"
          information={overview.cobros_pendientes}
          amplitudeEvent={amplitude_events.cobros_pendientes.event_name}
        />

        <MetricItem
          gridColumn={'1fr'}
          redirectUrl={idCountry === 'COL' ? '/' : '/clientes'}
          sectionTitle="CLIENTES"
          information={overview.cantidad_clientes}
          amplitudeEvent={amplitude_events.clientes.event_name}
        />
      </BackgroundBox>
    </Box>
  );
};

export default Metrics;
