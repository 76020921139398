import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  tiendaObtenerOverview,
  tiendaObtenerUltimoPago,
  tiendaObtenerAlertaMasiva,
} from '../../store/actions';
import queryString from 'query-string';
import { Box } from '@mui/material';
import { MassiveAlertUI } from '../../components/common';
import { Onboarding } from './Onboarding';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import Metrics from './Metrics';
import { useHistory } from 'react-router-dom';
import { SuscriptionBanner } from './SuscriptionBanner';
import { getHomeMessages } from './getHomeMessages';
import { showAlertUI } from '../../store/actions/alertActions';
import { VideoTutorial } from './VideoTutorial';
import { ShopInformationStatus } from './ShopInformationStatus';

const HomePage_ = ({
  getShop,
  location,
  dispatchAlert,
  getLastPayment,
  getMassiveAlert,
}) => {
  const { shopData, massiveAlert } = useSelector((state) => ({
    shopData: state.tienda,
    massiveAlert: state.alerta,
  }));
  const history = useHistory();

  const [isGetShopLoading, setIsGetShopLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isGetLastPaymentLoading, setIsGetLastPaymentLoading] = useState(true);
  const [isGetMassiveAlertLoading, setIsGetMassiveAlertLoading] =
    useState(true);
  const [onboardingStepsCompleted, setOnboardingStepsCompleted] =
    useState(false);
  const [allRequestsLoaded, setAllRequestsLoaded] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(true);

  const [state, setState] = useState({
    salesAmount: null,
    baja: false,
    cupon_descuento: false,
    proximo_vencimiento: {
      id: 0,
      monto: 0,
      fecha_vencimiento: null,
      dias_vencimiento: 0,
      fecha_corte: null,
      dias_corte: 0,
      paga: 0,
      cerrada: 0,
      fecha_pago: null,
      periodo_prueba: 0,
    },
    overview: {
      ventas_activas: 0,
      envios_pendientes: 0,
      cobros_pendientes: 0,
      cantidad_clientes: 0,
    },
  });

  useEffect(() => {
    if (localStorage.getItem('show-alert')) {
      const showAlert = JSON.parse(localStorage.getItem('show-alert'));
      dispatchAlert(showAlert);
      localStorage.removeItem('show-alert');
    }

    const { nombre, dominio } = queryString.parse(location.search);
    if (nombre && dominio) {
      history.push('/');
    }

    getShop((err, resp) => {
      let baja = false;
      if (resp.data.tienda) {
        baja = resp.data.tienda.t_offmode === 2 ? true : false;
      }
      setIsError((prev) => {
        return prev || err;
      });
      setState((prevState) => ({
        ...prevState,
        baja: baja,
        cupon_descuento: resp.data.cupon_descuento,
        overview: resp.data.overview,
      }));

      setIsGetShopLoading(false);
    });

    getLastPayment((err, resp) => {
      setIsError((prev) => prev || err);
      setState((prevState) => ({
        ...prevState,
        proximo_vencimiento: resp.data,
      }));

      setIsGetLastPaymentLoading(false);
    });

    if (massiveAlert.close === false) {
      getMassiveAlert(() => {
        setIsGetMassiveAlertLoading(false);
      });
    } else {
      setIsGetMassiveAlertLoading(false);
    }
  }, [history, massiveAlert.close, location.search]);

  useEffect(() => {
    if (
      shopData.t_prueba === 1 &&
      shopData.t_debito_automatico === 0 &&
      state.proximo_vencimiento &&
      state.proximo_vencimiento.dias_vencimiento > 10 &&
      state.proximo_vencimiento.dias_vencimiento < 31 &&
      localStorage.getItem('showSuscriptionBanner') === null
    ) {
      localStorage.setItem('showSuscriptionBanner', true);
    }
  }, [
    shopData.t_prueba,
    state.proximo_vencimiento,
    shopData.t_debito_automatico,
  ]);

  const homeMessages = getHomeMessages(state, shopData);

  const handleOnboardingError = (err) => {
    setIsError((prev) => prev || err);
  };

  const setSalesAmount = (salesAmount) => {
    setState((prevState) => ({
      ...prevState,
      salesAmount: salesAmount,
    }));
  };

  const idCountry = shopData.Country_idCountry || 'ARG';

  return (
    <WrapperLayout
      loading={
        isGetShopLoading || isGetLastPaymentLoading || isGetMassiveAlertLoading
      }
      error={isError}
    >
      <Box
        sx={{
          rowGap: '24px',
          display: 'grid',
          columnGap: { xs: '12px', md: '24px' },
          gridTemplateColumns: { xs: 'repeat(4,1fr)', md: 'repeat(12,1fr)' },
        }}
      >
        <MassiveAlertUI offMode={!state.baja} />

        <ShopInformationStatus nameStore={shopData.t_nombre} />

        <VideoTutorial
          salesAmount={state.salesAmount}
          allRequestsLoaded={allRequestsLoaded}
          onboardingStepsCompleted={onboardingStepsCompleted}
        />

        <Onboarding
          salesAmount={state.salesAmount}
          setSalesAmount={setSalesAmount}
          setOnboardingStepsCompleted={setOnboardingStepsCompleted}
          setAllRequestsLoaded={setAllRequestsLoaded}
          onboardingStepsCompleted={onboardingStepsCompleted}
          allRequestsLoaded={allRequestsLoaded}
          handleOnboardingError={handleOnboardingError}
        />

        <Metrics
          idCountry={idCountry}
          overview={state.overview}
          showBanner={homeMessages.showBanner && isBannerOpen}
        />
      </Box>

      <SuscriptionBanner
        messages={homeMessages}
        isBannerOpen={isBannerOpen}
        setIsBannerOpen={setIsBannerOpen}
      />
    </WrapperLayout>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getShop: (callback) => dispatch(tiendaObtenerOverview(callback)),
    getLastPayment: (callback) => dispatch(tiendaObtenerUltimoPago(callback)),
    getMassiveAlert: (callback) =>
      dispatch(tiendaObtenerAlertaMasiva(callback)),
    dispatchAlert: (data) => dispatch(showAlertUI(data)),
  };
};

export const HomePage = connect(null, mapDispatchToProps)(HomePage_);
