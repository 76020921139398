import React, { Component } from 'react';
import { Config, Validate } from '../../../../../src/other';
import { connect } from 'react-redux';
import ReactExport from 'react-export-excel';
import { productoObtenerProductosExportar } from '../../../../store/actions';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const filtroANivelDeStock = (productos, filtros) => {
  //filtro por cantidad de atributos
  const cant_maxima_atributos = 3;
  let productos_stock_filtrado = productos.filter((producto) =>
    producto.atributos.length <= cant_maxima_atributos ? true : false
  );

  //filtro por oferta
  productos_stock_filtrado = productos_stock_filtrado.map((producto) => {
    producto.stock = producto.stock.filter((stock) => {
      if (filtros.p_oferta === -1) return true; //no filtrar por oferta
      const con_oferta = stock.s_precio_oferta > 0;
      if (filtros.p_oferta === 0 && !con_oferta) return true;
      if (filtros.p_oferta === 1 && con_oferta) return true; //con oferta
      if (filtros.p_oferta === 2 && con_oferta && stock.s_oferta_fecha)
        return true; //oferta con fecha de finalización
      if (
        filtros.p_oferta === 3 &&
        con_oferta && //oferta con fecha de inicio y fin
        stock.s_oferta_fecha_inicio &&
        stock.s_oferta_fecha
      )
        return true;
      return false;
    });
    return producto;
  });
  //filtro por stock
  productos_stock_filtrado = productos_stock_filtrado.map((producto) => {
    producto.stock = producto.stock.filter((stock) => {
      if (filtros.estado_stock === -1) return true; //no filtrar por stock
      if (
        filtros.estado_stock === 0 &&
        stock.s_ilimitado === 0 &&
        stock.s_cantidad === 0
      )
        return true; //sin stock
      if (
        filtros.estado_stock === 1 &&
        stock.s_ilimitado === 0 &&
        stock.s_cantidad > 0
      )
        return true; //con stock
      if (filtros.estado_stock === 2 && stock.s_ilimitado === 1) return true; //con stock ilimitado
      return false;
    });
    return producto;
  });
  return productos_stock_filtrado;
};

const atributosAColumnas = (atributos, max_atributos) => {
  const columnas = atributos.map((atributo) => ({
    nombre: atributo.at_nombre, //Ej.:Talle
    valor: atributo.valor.vat_valor, //Ej:Mediano
  }));

  while (columnas.length < max_atributos)
    columnas.push({ nombre: '', valor: '' });
  return columnas;
};

const getExcelData = (data, categorias, show_mayorista, show_descripcion) => {
  let stock = [];

  const obtenerCategoriaProducto = (idCategorias) =>
    categorias.filter((cat) => cat.idCategorias === idCategorias)[0];
  for (const producto of data) {
    const tipo_venta_mayorista = producto.p_tipo_venta >= 1;

    const categoria_principal = obtenerCategoriaProducto(
      producto.Categorias_idCategorias
    );
    //performance. uso de push en lugar de spread operator
    stock.push(
      ...producto.stock.map((stock) => {
        const stock_en_oferta = stock.s_oferta > 0;
        const atributos_columnas = atributosAColumnas(stock.valoratributo, 3);

        return [
          {
            key: 'nombre',
            label: 'Nombre',
            value: producto.p_nombre,
          },
          {
            key: 'stock',
            label: 'Stock',
            value:
              stock.s_ilimitado === 0
                ? Validate.number_format_excel(stock.s_cantidad)
                : '',
          },
          {
            key: 'sku',
            label: 'SKU',
            value: stock.s_sku === null || stock.s_ku === '' ? '' : stock.s_sku,
          },
          {
            key: 'precio',
            label: 'Precio',
            value: (producto.p_mostrar_precio = 1
              ? stock.s_precio > 0
                ? Validate.number_format_excel(stock.s_precio)
                : ''
              : ''),
          },
          {
            key: 'precio_oferta',
            label: 'Precio oferta',
            value: stock_en_oferta
              ? stock.s_precio_oferta > 0
                ? Validate.number_format_excel(stock.s_precio_oferta)
                : ''
              : '',
          },
          ...(show_mayorista
            ? [
                {
                  key: 'precio_mayorista',
                  label: 'Precio mayorista',
                  value: tipo_venta_mayorista
                    ? stock.s_precio_mayorista > 0
                      ? Validate.number_format_excel(stock.s_precio_mayorista)
                      : ''
                    : '',
                },
              ]
            : []),
          {
            key: 'atributo1Nombre',
            label: 'Nombre atributo 1',
            value: atributos_columnas[0].nombre,
          },
          {
            key: 'atributo1Valor',
            label: 'Valor atributo 1',
            value: atributos_columnas[0].valor,
          },
          {
            key: 'atributo2Nombre',
            label: 'Nombre atributo 2',
            value: atributos_columnas[1].nombre,
          },
          {
            key: 'atributo2Valor',
            label: 'Valor atributo 2',
            value: atributos_columnas[1].valor,
          },
          {
            key: 'atributo3Nombre',
            label: 'Nombre atributo 3',
            value: atributos_columnas[2].nombre,
          },
          {
            key: 'atributo3Valor',
            label: 'Valor atributo 3',
            value: atributos_columnas[2].valor,
          },
          {
            key: 'categorias',
            label: 'Categorías',
            value: categoria_principal.c_nombre_full,
          },
          {
            key: 'peso',
            label: 'Peso',
            value:
              stock.s_peso > 0
                ? Validate.number_format_excel(stock.s_peso)
                : '',
          },
          {
            key: 'alto',
            label: 'Alto',
            value:
              stock.s_alto > 0
                ? Validate.number_format_excel(stock.s_alto)
                : '',
          },
          {
            key: 'ancho',
            label: 'Ancho',
            value:
              stock.s_ancho > 0
                ? Validate.number_format_excel(stock.s_ancho)
                : '',
          },
          {
            key: 'profundidad',
            label: 'Profundidad',
            value:
              stock.s_profundidad > 0
                ? Validate.number_format_excel(stock.s_profundidad)
                : '',
          },
          {
            key: 'mostrar_en_tienda',
            label: 'Mostrar en tienda',
            value: producto.p_desactivado === 0 ? 'Si' : 'No',
          },
          ...(show_descripcion
            ? [
                {
                  key: 'descripcion',
                  label: 'Descripción',
                  value: producto.p_descripcion,
                },
              ]
            : []),
          {
            key: 'producto_id',
            label: 'IDProduct',
            value: producto.idProductos,
          },
          { key: 'stock_id', label: 'IDStock', value: stock.idStock },
        ];
      })
    );
  }
  return stock;
};

const RenderExcel = ({
  data,
  categorias,
  show_mayorista,
  show_descripcion,
}) => {
  if (data.length > 0) {
    let date = Validate.now_format(Config.SHORTDATE_FORMAT_DASH);
    const excel_data = getExcelData(
      data,
      categorias,
      show_mayorista,
      show_descripcion
    );
    const columns = excel_data[0].map((cell) => cell.label);
    const rows = excel_data.map((row) => row.map((cell) => cell.value));

    const data_set = [
      {
        columns: columns,
        data: rows,
      },
    ];

    return (
      <div>
        <ExcelFile
          filename={`Exportacion-productos-${date}`}
          hideElement={true}
        >
          <ExcelSheet dataSet={data_set} name="Productos" />
        </ExcelFile>
      </div>
    );
  } else {
    return '';
  }
};

class ProductoDownloadExcel_ extends Component {
  state = {
    loading: true,
    page_limit: 1000,
    max_records: 50000,
    error: false,
    err: null,
    export_message: {
      show: false,
      message: '',
    },
  };

  componentDidUpdate() {
    this.props.exportFinish(
      this.state.error,
      this.state.err,
      this.state.export_message
    );

    this.props.setShowExport(false);
  }

  componentDidMount() {
    this.props.productoObtenerProductosExportar(
      {
        filtros: this.props.obtenerParametros(),
        limit: this.state.page_limit,
        max_records: this.state.max_records,
        filtroANivelDeStock,
      },
      (err, resp) => {
        let state;
        if (err) {
          //error al llamar backend API
          state = {
            error: true,
            err,
          };
        } else if (resp.alcanzo_max_records) {
          state = {
            export_message: {
              ...this.state.export_message,
              show: true,
              message: `Superó el tamaño máximo de archivo (${this.state.max_records} registros). Por favor, utilice filtros para segmentar la información`,
            },
          };
        } else if (resp.stock_count === 0) {
          state = {
            export_message: {
              ...this.state.export_message,
              show: true,
              message:
                'No se han encontrado resultados para los filtros seleccionados',
            },
          };
        } else {
          this.data = resp.productos;
          this.data_length = resp.stock_count;
        }

        state = {
          ...state,
          loading: false,
        };
        this.setState(state);
      }
    );
  }

  render() {
    if (this.data && this.data_length > 0)
      return (
        <RenderExcel
          data={this.data}
          categorias={this.props.categorias}
          show_mayorista={this.props.show_mayorista}
          show_descripcion={this.props.show_descripcion}
        />
      );
    return <></>;
  }
}

const mapStateToProps = (state) => {
  return {
    categorias: state.categorias,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    productoObtenerProductosExportar: (data, callback) =>
      dispatch(productoObtenerProductosExportar(data, callback)),
  };
};

export const ProductoDownloadExcel = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductoDownloadExcel_);
