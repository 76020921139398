/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Grid } from '@mui/material';
import { ButtonUI, CustomCardUI } from '../../common';
import { Config } from '../../../other';

const MercadoEnvios = (props) => {
  const { metodos_envio } = props;

  return (
    <Grid item lg={4} sm={6} xs={12}>
      <CustomCardUI
        title="Mercado Envíos"
        subtitle={
          metodos_envio.me_mercadoenvios ? 'Método activo' : 'Método no activo'
        }
        info="Sólo funciona para aquellas cuentas que ya tenían activo Mercado Envíos desde Mercado Pago, no permite la activación de nuevas cuentas."
        image={`${Config.CLOUDFRONT_CDN_PROD}/panel/medios_envio/mercadoenvios-card-img.png`}
      >
        <ButtonUI
          type="link"
          link="/metodos-envio/mercado-envios"
          label="Configuración"
          size="large"
        />
      </CustomCardUI>
    </Grid>
  );
};

export { MercadoEnvios };
