import React from 'react';
import { connect } from 'react-redux';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListItemIcon,
  Icon,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';

const actualizarCategorias = (categorias, parent, nivel) => {
  return categorias.map((categoria) => {
    if (categoria.idCategorias === parent) {
      return {
        ...categoria,
        subcategorias: nivel,
      };
    } else {
      return {
        ...categoria,
        subcategorias: actualizarCategorias(
          categoria.subcategorias,
          parent,
          nivel
        ),
      };
    }
  });
};

const CategoriasList_ = (props) => {
  const { categorias, modalHandler, handleOrdenar, classes } = props;

  const onSortEnd = ({ args, parent, nivel }) => {
    const { oldIndex, newIndex } = args;
    if (oldIndex !== newIndex) {
      let nivel_ord = arrayMove(nivel, oldIndex, newIndex);
      nivel_ord = nivel_ord.map((categoria, idx) => {
        return {
          ...categoria,
          c_orden: idx,
        };
      });
      let new_categorias =
        parent === null
          ? nivel_ord
          : actualizarCategorias(categorias, parent, nivel_ord);
      handleOrdenar(new_categorias, nivel_ord, parent);
    }
  };

  const DragHandle = sortableHandle(() => (
    <Icon classes={{ root: classes.drag_icon }}>drag_indicator</Icon>
  ));

  const SortableItem = sortableElement(({ categoria, depth }) => (
    <div
      key={categoria.idCategorias}
      className={depth ? classes.list_content : classes.list_content_root}
    >
      <ListItem
        ContainerComponent="div"
        classes={{
          root: depth ? classes.list_item : classes.list_item_root,
        }}
      >
        <ListItemIcon>
          <DragHandle />
        </ListItemIcon>
        <ListItemText
          classes={{ root: classes.list_text }}
          primary={categoria.c_nombre}
        />
        <ListItemSecondaryAction sx={{ right: '5px' }}>
          <IconButton
            aria-label="Agregar"
            onClick={() =>
              modalHandler(true, 'agregar', {
                c_padre: categoria.idCategorias,
                c_nombre_padre: categoria.c_nombre,
              })
            }
            size="large"
          >
            <Icon>add</Icon>
          </IconButton>
          <IconButton
            aria-label="Editar"
            onClick={() => modalHandler(true, 'editar', categoria)}
            size="large"
          >
            <Icon>create</Icon>
          </IconButton>
          <IconButton
            aria-label="Borrar"
            onClick={() => modalHandler(true, 'borrar', categoria)}
            size="large"
          >
            <Icon>delete</Icon>
          </IconButton>
          <IconButton
            aria-label="GoogleID"
            onClick={() => modalHandler(true, 'googleid', categoria)}
            size="large"
          >
            <Icon
              className="fab fa-google"
              style={{ fontSize: '19px' }}
              color={categoria.c_google_category_id > 0 ? 'primary' : 'inherit'}
            />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {categoria.subcategorias.length ? (
        <SortableContainer
          onSortEnd={(args) =>
            onSortEnd({
              args: args,
              parent: categoria.idCategorias,
              nivel: categoria.subcategorias,
            })
          }
          useDragHandle
        >
          {renderizarCategorias(categoria.subcategorias, depth + 1)}
        </SortableContainer>
      ) : (
        ''
      )}
    </div>
  ));

  const SortableContainer = sortableContainer(({ children }) => {
    return (
      <List component="div" classes={{ root: classes.list }}>
        {children}
      </List>
    );
  });

  const renderizarCategorias = (categorias, depth) =>
    categorias.map((categoria, index) => (
      <SortableItem
        key={categoria.idCategorias}
        index={index}
        categoria={categoria}
        depth={depth}
      />
    ));

  return (
    <SortableContainer
      onSortEnd={(args) =>
        onSortEnd({ args: args, parent: null, nivel: categorias })
      }
      useDragHandle
    >
      {renderizarCategorias(categorias, 0)}
    </SortableContainer>
  );
};

const styles = (theme) => ({
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  list_item: {
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '15px',
    marginBottom: '5px',
    position: 'relative',
    '&:before': {
      position: 'absolute',
      top: '50%',
      left: '-11px',
      content: '""',
      width: '10px',
      [theme.breakpoints.up('md')]: {
        width: '20px',
        left: '-21px',
      },
      height: '1px',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
  list_item_root: {
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '15px',
    marginBottom: '5px',
    position: 'relative',
  },
  list_content: {
    marginLeft: '10px',
    [theme.breakpoints.up('md')]: {
      marginLeft: '20px',
    },
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '1px',
      height: 'calc(100% - 28px)',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
  list_content_root: {
    marginLeft: '0px',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '1px',
      height: 'calc(100% - 28px)',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
  drag_icon: {
    cursor: 'move',
    marginRight: '16px',
  },
  list_text: {
    paddingLeft: '0px',
    paddingRight: '195px',
  },
  google_category_icon_disabled: {},
});

const mapStateToProps = (state) => {
  return {
    categorias: state.categorias,
  };
};

const mapDispatchToProps = null;

export const CategoriasList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CategoriasList_));
