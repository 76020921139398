import React from 'react';
import { Grid } from '@mui/material';
import { ModalUI, InputUI } from '../../common';

const TodoPagoModal = (props) => {
  const { modals, inputs, forms, modalHandler, handleSubmit, handleChange } =
    props;

  return (
    <ModalUI
      title="Credenciales de Todo Pago"
      subtitle="Para conseguir estas credenciales, debes ingresar a tu cuenta de Todo Pago y dirigirte a Soluciones para vendedores > integración avanzada."
      open={modals.activar_editar_todopago}
      id="activar_editar_todopago"
      button_label="Guardar"
      button_loading={forms.activar_editar_todopago}
      modalHandler={modalHandler}
      handleSubmit={handleSubmit}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <InputUI
            input={inputs.mp_todopago_merchant_id}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <InputUI
            input={inputs.mp_todopago_api_key}
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { TodoPagoModal };
