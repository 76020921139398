import React from 'react';
import { CardModuleUI } from '../../../../components/common';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { MessageTextTips } from './MessageTextTips';

export const CardStoreTips = ({
  idCountry,
  salesAmount,
  handleCloseCardTips,
}) => {
  const titleCard =
    salesAmount === 1
      ? '¡Felicitaciones! Realizaste tu primera venta'
      : 'Potenciá tu tienda virtual';

  return (
    <CardModuleUI disablePadding={true}>
      <Grid container p={2.5}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Typography
              as="h3"
              m={0}
              color="#3A3A3A"
              fontWeight="600"
              fontSize="1.125rem"
            >
              {titleCard}
            </Typography>
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={handleCloseCardTips}
              size="large"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                  fill="#A5A5A5"
                />
              </svg>
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <MessageTextTips idCountry={idCountry} salesAmount={salesAmount} />
        </Grid>
      </Grid>
    </CardModuleUI>
  );
};
