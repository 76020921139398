/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Grid, Typography, Icon } from '@mui/material';
import { InputUI, DocFileUI, ModalUI } from '../../common';
import { withStyles } from '@mui/styles';

const ModalCuentaOca_ = (props) => {
  let {
    solicitar_cuenta,
    handleSubmit,
    handleChange,
    handleChangeDoc,
    modalHandler,
    modal_state,
    form_state,
    classes,
  } = props;

  const allowed_files = ['pdf', 'doc', 'docx'];
  const max_file = 2;
  const input_allowed_mime =
    'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template,application/vnd.oasis.opendocument.text,application/vnd.oasis.opendocument.text-template,application/pdf,application/vnd.ms-word.document.macroEnabled.12,application/vnd.ms-word.template.macroEnabled.12';

  return (
    <ModalUI
      open={modal_state}
      id="solicitar_cuenta"
      title="Solicitar cuenta OCA"
      modalHandler={modalHandler}
      aditional_param="solicitar_cuenta"
      handleSubmit={handleSubmit}
      button_label="Enviar"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <div className={classes.links_box}>
            <Typography className={classes.link_title} variant="h4">
              Importante:
            </Typography>
            <Typography className={classes.link_text} variant="body1">
              <Icon className={classes.link_icon}>chevron_right</Icon>
              <span>
                Para saber más sobre OCA Epak y cómo enviar la documentación
                necesaria correctamente, haz click{' '}
                <a
                  href="https://recursos.empretienda.com/alta-oca-epak.pdf"
                  className={classes.link_link}
                  target="_blank"
                >
                  aquí
                </a>
                .
              </span>
            </Typography>
            <Typography className={classes.link_text} variant="body1">
              <Icon className={classes.link_icon}>chevron_right</Icon>
              <span>
                Para descargar el documento (datos-alta-oca.docx) para
                completar, haz click{' '}
                <a
                  href="https://recursos.empretienda.com/documentacion-oca/datos-alta-oca.docx"
                  className={classes.link_link}
                  target="_blank"
                >
                  aquí
                </a>
                .
              </span>
            </Typography>
            <Typography className={classes.link_text} variant="body1">
              <Icon className={classes.link_icon}>chevron_right</Icon>
              <span>
                Para descargar el documento (situacion-fiscal.doc) para
                completar, haz click{' '}
                <a
                  href="https://recursos.empretienda.com/documentacion-oca/situacion-fiscal.doc"
                  className={classes.link_link}
                  target="_blank"
                >
                  aquí
                </a>
                .
              </span>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <InputUI
            handleChange={handleChange}
            input={solicitar_cuenta.sc_razon_social}
          />
        </Grid>
        <Grid item xs={12}>
          <InputUI
            handleChange={handleChange}
            input={solicitar_cuenta.sc_telefono}
          />
        </Grid>
        <Grid item xs={12}>
          <DocFileUI
            data={solicitar_cuenta.sc_doc_alta_oca}
            handleChangeDoc={handleChangeDoc}
            max_size={max_file}
            allowed_files={allowed_files}
            input_allowed_mime={input_allowed_mime}
          />
        </Grid>
        <Grid item xs={12}>
          <DocFileUI
            data={solicitar_cuenta.sc_doc_situacion_fiscal}
            handleChangeDoc={handleChangeDoc}
            max_size={max_file}
            allowed_files={allowed_files}
            input_allowed_mime={input_allowed_mime}
          />
        </Grid>
        <Grid item xs={12}>
          <DocFileUI
            data={solicitar_cuenta.sc_doc_constancia_iibb}
            handleChangeDoc={handleChangeDoc}
            max_size={max_file}
            allowed_files={allowed_files}
            input_allowed_mime={input_allowed_mime}
          />
        </Grid>
        <Grid item xs={12}>
          <DocFileUI
            data={solicitar_cuenta.sc_doc_constancia_afip}
            handleChangeDoc={handleChangeDoc}
            max_size={max_file}
            allowed_files={allowed_files}
            input_allowed_mime={input_allowed_mime}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

const styles = (theme) => ({
  links_box: {
    backgroundColor: '#f5f5f5',
    padding: '10px',
    boxSizing: 'border-box',
  },
  link_title: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1.4rem',
    marginBottom: '1rem',
  },
  link_text: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.8rem',
    marginBottom: '0.5rem',
  },
  link_icon: {
    fontSize: '1rem',
    lineHeight: '1.4rem',
  },
  link_link: {
    color: 'inherit',
  },
});

export const ModalCuentaOca = withStyles(styles)(ModalCuentaOca_);
