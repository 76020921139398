import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Regex, RegexExtra, Validate } from '../../other';
import {
  createAmplitudeEventWithDevice,
  notifications_stock_amplitude_events,
} from '../../components/amplitude';
import {
  AlertUI,
  ButtonUI,
  DropdownUI,
  InputUI,
  MenuItemUI,
  ModalUI,
  ModuleTitleUI,
} from '../../components/common';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import {
  tiendaActualizarNotificacionesStock,
  tiendaConfigurarNotificacionesStock,
  tiendaObtener,
} from '../../store/actions';
import { Grid } from '@mui/material';

const StockNotificationsPage_ = ({
  tienda,
  tiendaObtener,
  tiendaActualizarNotificacionesStock,
  tiendaConfigurarNotificacionesStock,
}) => {
  const [stockConfiguration, setStockConfiguration] = useState({
    t_notificacion_stock_cantidad: {
      auth: false,
      validate: true,
      required: true,
      disabled: false,
      error: false,
      type: 'number',
      label: 'Cantidad para notificar',
      id: 't_notificacion_stock_cantidad',
      name: 't_notificacion_stock_cantidad',
      change_param: 'configuracion_stock',
      regex: Regex.NUMERO_ENTERO_POSITIVO,
      value: '',
      default_value: '',
      placeholder: ' ',
      messages: {
        error: 'Por favor, ingrese una cantidad válida',
        help: 'Cuando el stock de cualquier producto o variante llegue a la cantidad especificada, vas a ser notificado',
        error_extra: RegexExtra.NUMERO_ENTERO_POSITIVO,
      },
    },
  });

  const [isShopLoading, setIsShopLoading] = useState(true);
  const [isShopError, setIsShopError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStockConfigurationLoading, setIsStockConfigurationLoading] =
    useState(false);
  const [isNotificationStockLoading, setIsNotificationStockLoading] =
    useState(false);

  const [alert, setAlert] = useState({
    open: false,
    type: 'error',
    message: '',
  });

  useEffect(() => {
    tiendaObtener((err, resp) => {
      if (err) {
        setIsShopLoading(false);
        setIsShopError(true);
      } else {
        Validate.cargar_formulario_sin_nulos(
          resp.data,
          stockConfiguration,
          (configuracion_stock_obj) => {
            setStockConfiguration(configuracion_stock_obj);
            setIsShopLoading(false);
            setIsShopError(false);
          }
        );
      }
    });
  }, []);

  const handleChange = (e, blur, _aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    setStockConfiguration({
      ...stockConfiguration,
      [name]: {
        ...stockConfiguration[name],
        value: value,
      },
    });

    if (blur) {
      Validate.validate_input(stockConfiguration[name], blur, (input) => {
        setStockConfiguration({
          ...stockConfiguration,
          [name]: input,
        });
      });
    }
  };

  const modalHandler = (open) => {
    setIsModalOpen(open);
  };

  const handleSwitchStockNotificationsActivation = (e) => {
    e.preventDefault();
    setIsNotificationStockLoading(true);

    tiendaActualizarNotificacionesStock(
      {
        t_notificacion_stock: tienda.t_notificacion_stock ? 0 : 1,
      },
      (err, resp) => {
        setIsNotificationStockLoading(false);
        setAlert({
          ...alert,
          open: true,
          message: resp.message,
          type: err ? 'error' : 'success',
        });
        if (!err && tienda.t_notificacion_stock === 1) {
          createAmplitudeEventWithDevice(
            notifications_stock_amplitude_events
              .productos_notificaciones_stock_activar.event_name
          );
        }
      }
    );
  };

  const handleNotificationsStockConfiguration = (e) => {
    e.preventDefault();
    setIsStockConfigurationLoading(true);

    let form = stockConfiguration;

    if (Validate.validar_formulario(form)) {
      tiendaConfigurarNotificacionesStock(
        {
          t_notificacion_stock_cantidad:
            form.t_notificacion_stock_cantidad.value,
        },
        (err, resp) => {
          setIsStockConfigurationLoading(false);
          setAlert({
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          });
          setIsModalOpen(err);
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        setStockConfiguration(new_form);
        setIsStockConfigurationLoading(false);
      });
    }
  };

  const handleCloseAlert = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const activar_desactivar_btn = tienda.t_notificacion_stock ? (
    <DropdownUI
      label="Configuración"
      minHeight={true}
      isLoading={isNotificationStockLoading}
    >
      <MenuItemUI type="callf" onClickFunction={() => modalHandler(true)}>
        Configurar
      </MenuItemUI>
      <MenuItemUI
        type="callf"
        onClickFunction={handleSwitchStockNotificationsActivation}
      >
        Desactivar
      </MenuItemUI>
    </DropdownUI>
  ) : (
    <ButtonUI
      type="callf"
      label="Activar"
      fullWidth={false}
      minHeight={true}
      onClickFunc={handleSwitchStockNotificationsActivation}
      isLoading={isNotificationStockLoading}
    />
  );

  const subtitles = [
    'Las notificaciones de stock te ayudarán a llevar un control del mismo.',
    'Te llegará un email avisándote cuando la cantidad de stock de un producto llegue a un nivel bajo, que vos podés configurar.',
    'Activando la función podrás configurar la cantidad de stock para ser notificado.',
  ];

  return (
    <WrapperLayout loading={isShopLoading} error={isShopError}>
      <ModalUI
        open={isModalOpen}
        id="configuracion_stock"
        title="Configuración de notificaciones"
        modalHandler={modalHandler}
        aditional_param="configuracion_stock"
        handleSubmit={handleNotificationsStockConfiguration}
        button_label="Guardar"
        button_loading={isStockConfigurationLoading}
      >
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChange}
              input={stockConfiguration.t_notificacion_stock_cantidad}
              step="1"
            />
          </Grid>
        </Grid>
      </ModalUI>
      <AlertUI
        open={alert.open}
        type={alert.type}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />
      <ModuleTitleUI
        title="Notificaciones de stock"
        subtitles={subtitles}
        button={activar_desactivar_btn}
        titleMarginRight={2}
      />
    </WrapperLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    tiendaActualizarNotificacionesStock: (data, callback) =>
      dispatch(tiendaActualizarNotificacionesStock(data, callback)),
    tiendaConfigurarNotificacionesStock: (data, callback) =>
      dispatch(tiendaConfigurarNotificacionesStock(data, callback)),
  };
};

export const StockNotificationsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(StockNotificationsPage_);
