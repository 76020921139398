import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { withRouter, useHistory } from 'react-router-dom';
import {
  adminLogout,
  tiendaObtener,
  tiendaActualizarApagarTemporalmente,
  metodospagoObtener,
  authBrazeUser,
  splitioSetCustomerId,
  dominioObtenerDominioPreferencial,
} from '../../store/actions';
import { alpha } from '@mui/material/styles';
import {
  AppBar,
  Drawer,
  Hidden,
  Button,
  IconButton,
  Icon,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import amplitude from 'amplitude-js';
import * as braze from '@braze/web-sdk';
import { Config, Regex, RegexExtra, Validate } from '../../other';
import { connectSplit } from '@splitsoftware/splitio-redux';
import {
  amplitude_events,
  createAmplitudeEventWithDevice,
  on_off_store_amplitude_events,
} from '../../components/amplitude';
import { BRAZE_ERRORS } from '../../other/errorCodes';
import { AlertUI, ErrorLayout, ToggleUI } from '../../components/common';
import { UserMenuLayout } from '../GlobalPrivateLayout/subcomponents/UserMenuLayout';
import { DrawerLayout } from '../GlobalPrivateLayout/subcomponents/DrawerLayout';
import { useAuth0 } from '@auth0/auth0-react';
import { showAlertUI } from '../../store/actions/alertActions';
import { useTheme } from '@emotion/react';

const GlobalPrivateLayout_ = ({
  classes,
  adminLogout,
  error,
  tiendaObtener,
  dominioObtenerDominioPreferencial,
  tiendaActualizarApagarTemporalmente,
  metodospagoObtener,
  authBrazeUser,
  splitioSetCustomerId,
  tienda,
  splitio,
  children,
  dominios,
  dispatchAlert,
  alert,
}) => {
  const [state, setState] = useState({
    apagar_temporalmente: {
      t_offmode_mensaje: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Mensaje para tus clientes',
        id: 't_offmode_mensaje',
        name: 't_offmode_mensaje',
        change_param: 'apagar_temporalmente',
        regex: Regex.DESCRIPCION_0_200,
        value: '',
        default_value: '',
        placeholder: 'Estaremos cerrados hasta el ....',
        messages: {
          error: 'Por favor, ingrese un mensaje válido',
          error_extra: RegexExtra.DESCRIPCION_0_200,
        },
      },
    },
    layoutLoading: {
      menu: true,
      error: false,
      splitio: true,
      private_layout: true,
    },
    mobileOpen: false,
    anchorEl: null,
    profileMenuOpen: false,
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    braze: {
      jwtExpired: false,
      errorCode: '',
      signature: '',
      timestamp: null,
    },
  });
  const history = useHistory();
  const isBuilder = history.location.pathname === '/builder';
  const { logout: logoutAuth0 } = useAuth0();

  const [avoidDrawerLogic, setAvoidDrawerLogic] = useState(false);

  const [forceRenderDrawer, setForceRenderDrawer] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleCloseAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  const handleDrawerToggle = () => {
    setState((prevState) => ({
      ...prevState,
      mobileOpen: !prevState.mobileOpen,
    }));
  };

  const handleProfileMenu = (e, action) => {
    if (action === 'open') {
      setState((prevState) => ({
        ...prevState,
        anchorEl: e.currentTarget,
        profileMenuOpen: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        anchorEl: null,
        profileMenuOpen: false,
      }));
    }
  };

  const logout = () => {
    logoutAuth0({
      logoutParams: {
        returnTo: `${window.location.origin}?idCountry=${tienda.Country_idCountry}`,
      },
    });
    adminLogout();
  };

  const closeMobileDrawer = () => {
    setState((prevState) => ({
      ...prevState,
      mobileOpen: false,
    }));
  };

  useEffect(() => {
    const unlisten = history.listen(() => {
      closeMobileDrawer();
    });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    tiendaObtener((err, _resp) => {
      if (err) {
        setState((prevState) => ({
          ...prevState,
          layoutLoading: {
            ...prevState.layoutLoading,
            private_layout: false,
            error: true,
            menu: false,
          },
        }));
      } else {
        Validate.cargar_formulario_sin_nulos(
          tienda,
          state.apagar_temporalmente,
          (apagar_temporalmente_obj) => {
            setState((prevState) => ({
              ...prevState,
              apagar_temporalmente: apagar_temporalmente_obj,
              layoutLoading: {
                ...prevState.layoutLoading,
                private_layout: false,
                error: false,
                menu: false,
              },
            }));
          }
        );
      }
    });
  }, []);

  useEffect(() => {
    if (tienda?.idTienda) {
      splitioSetCustomerId({
        idTienda: tienda.idTienda,
      });

      setState((prevState) => ({
        ...prevState,
        layoutLoading: {
          ...prevState.layoutLoading,
          menu: false,
        },
      }));

      authBrazeUser((err, { data }) => {
        if (!err && tienda.idTienda) {
          const brazeToken = data;
          const idCountry = tienda.Country_idCountry;

          if (!Config.MOCK_ACTIVE) {
            braze.changeUser(tienda.idTienda, brazeToken);
            braze.getUser().setEmail(tienda.t_email);
            braze.getUser().setCustomUserAttribute('País', idCountry);
          }

          if (localStorage.getItem('first-login')) {
            if (!Config.MOCK_ACTIVE) {
              braze.logCustomEvent('empretienda_crea_tienda');
              braze.getUser().setCustomUserAttribute('Status', 'Inactivo');
            }

            localStorage.removeItem('first-login');
          }

          metodospagoObtener((error, resp) => {
            if (!error && resp.data) {
              if (!Config.MOCK_ACTIVE) {
                braze
                  .getUser()
                  .setCustomUserAttribute(
                    'Uala Bis Integrado',
                    resp.data.mp_uala
                  );
              }
            }
          });
        }
      });
    }

    if (
      !state.layoutLoading.menu &&
      state.layoutLoading.splitio &&
      splitio.isReady
    ) {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          layoutLoading: {
            ...prevState.layoutLoading,
            splitio: false,
          },
        }));
      }, 500);
    }

    if (
      state.braze.jwtExpired &&
      state.braze.errorCode === BRAZE_ERRORS.EXPIRED &&
      !state.braze.timestamp
    ) {
      authBrazeUser((err, { data }) => {
        if (!err) {
          if (!Config.MOCK_ACTIVE) {
            braze.setSdkAuthenticationSignature(data);
          }

          setState((prevState) => ({
            ...prevState,
            braze: {
              jwtExpired: false,
              errorCode: '',
              signature: data,
              timestamp: null,
            },
          }));
        } else {
          const timestamp = new Date();
          timestamp.setMinutes(timestamp.getMinutes() + 5);

          setState((prevState) => ({
            ...prevState,
            braze: {
              ...prevState.braze,
              timestamp: timestamp,
            },
          }));
        }
      });
    }
  }, [tienda, state.braze]);

  useEffect(() => {
    dominioObtenerDominioPreferencial((err, resp) => {});
  }, []);

  const handleChangeOffStore = (event) => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!event.target.checked) {
      tiendaActualizarApagarTemporalmente(
        {
          t_offmode_mensaje: tienda.t_offmode_mensaje,
          t_offmode: 0,
        },
        (err, resp) => {
          if (err) {
            setState((prevState) => ({
              ...prevState,
              alert: {
                ...prevState.alert,
                open: true,
                message: resp.message,
                type: 'error',
              },
            }));
          } else {
            createAmplitudeEventWithDevice(
              on_off_store_amplitude_events
                .mi_tienda_selecciona_encender_tienda_panel.event_name,
              { source: 'switch' }
            );
          }
        }
      );
    } else {
      tiendaActualizarApagarTemporalmente(
        {
          t_offmode_mensaje: tienda.t_offmode_mensaje,
          t_offmode: 1,
        },
        (err, resp) => {
          if (err) {
            setState((prevState) => ({
              ...prevState,
              alert: {
                ...prevState.alert,
                open: true,
                message: resp.message,
                type: 'error',
              },
            }));
          } else {
            createAmplitudeEventWithDevice(
              on_off_store_amplitude_events
                .mi_tienda_selecciona_apagar_tienda_panel.event_name,
              { source: 'switch' }
            );
          }
        }
      );
    }
  };

  const { Country_idCountry: idCountry = 'ARG' } = tienda;

  amplitude.getInstance().setUserId(tienda.idTienda);

  if (!Config.MOCK_ACTIVE) {
    braze.subscribeToSdkAuthenticationFailures(({ errorCode, signature }) => {
      if (
        (!state.braze.jwtExpired &&
          String(errorCode) === BRAZE_ERRORS.EXPIRED &&
          (state.braze.signature === '' ||
            signature === state.braze.signature)) ||
        (state.braze.timestamp && Date.now() > state.braze.timestamp.getTime())
      ) {
        setState((prevState) => ({
          ...prevState,
          braze: {
            jwtExpired: true,
            errorCode: String(errorCode),
            signature: signature,
            timestamp: null,
          },
        }));
      }
    });
  }

  return (
    <div style={{ display: isBuilder ? 'block' : 'flex' }}>
      <AlertUI
        open={alert.open}
        message={alert.message}
        type={alert.type}
        handleCloseAlert={() => dispatchAlert()}
      />
      {!isBuilder ? (
        <>
          <AppBar
            position="fixed"
            className={classes.appBar}
            sx={{ zIndex: 1002 }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
                size="large"
              >
                <Icon>menu</Icon>
              </IconButton>
              <img
                onClick={() => {
                  createAmplitudeEventWithDevice(
                    amplitude_events.selecciona_home_logo.event_name,
                    amplitude_events.selecciona_home_logo.event_params
                  );
                  setAvoidDrawerLogic((prev) => !prev);
                  setForceRenderDrawer((prev) => !prev);
                  history.push(`/`);
                }}
                src={`${Config.CLOUDFRONT_CDN_PROD}/landingpage/images/v4/empretienda-green-logo.png`}
                alt="Logo panel"
                className={classes.logo}
              />
              <div className={classes.grow} />
              <div className={classes.sectionUser}>
                <ToggleUI show={isMobile}>
                  <IconButton
                    onClick={() => {
                      createAmplitudeEventWithDevice(
                        amplitude_events.tutoriales__panel_superior.event_name,
                        amplitude_events.tutoriales__panel_superior.event_params
                      );

                      setAvoidDrawerLogic((prev) => !prev);
                      setForceRenderDrawer((prev) => !prev);
                      history.push(`/soporte`);
                    }}
                    color="primary"
                    size="large"
                  >
                    <Icon>help_outline</Icon>
                  </IconButton>

                  <IconButton
                    aria-owns={state.mobileOpen ? 'menu-appbar' : undefined}
                    aria-haspopup="true"
                    onClick={(e) => handleProfileMenu(e, 'open')}
                    color="primary"
                    size="large"
                  >
                    <Icon>account_circle</Icon>
                  </IconButton>
                </ToggleUI>
                <ToggleUI show={!isMobile}>
                  <Button
                    color="inherit"
                    classes={{ root: classes.navbarButton }}
                    onClick={() => {
                      createAmplitudeEventWithDevice(
                        amplitude_events.selecciona_soporte.event_name
                      );

                      setAvoidDrawerLogic(true);
                      setForceRenderDrawer((prev) => !prev);

                      history.push(`/soporte`);
                    }}
                  >
                    <Icon style={{ marginRight: 5 }}>help_outline</Icon>
                    Enviar consulta
                  </Button>
                  <Button
                    color="inherit"
                    classes={{ root: classes.navbarButton }}
                    onClick={() => {
                      createAmplitudeEventWithDevice(
                        amplitude_events.tutoriales__panel_superior.event_name,
                        amplitude_events.tutoriales__panel_superior.event_params
                      );
                      setAvoidDrawerLogic(true);
                      setForceRenderDrawer((prev) => !prev);
                      window.open(
                        'https://empretienda.helpjuice.com/',
                        '_blank'
                      );
                    }}
                  >
                    <Icon style={{ marginRight: 5 }}>info_outline</Icon>{' '}
                    Tutoriales
                  </Button>

                  <Button
                    color="inherit"
                    classes={{
                      root: classes.navbarButtonWithoutHighlight,
                    }}
                    disabled={!dominios[0]?.d_nombre && !dominios?.d_nombre}
                    onClick={(e) => {
                      if (dominios[0]?.d_nombre || dominios?.d_nombre) {
                        createAmplitudeEventWithDevice(
                          amplitude_events.ver_tienda__panel_superior
                            .event_name,
                          amplitude_events.ver_tienda__panel_superior
                            .event_params
                        );
                        const link =
                          'https://' +
                          (dominios[0]?.d_nombre || dominios?.d_nombre);
                        window.open(link, '_blank');
                      }
                    }}
                  >
                    <Icon style={{ marginRight: 5 }}>store</Icon> Ver mi tienda
                  </Button>
                  <Button
                    color="inherit"
                    classes={{
                      root: state.profileMenuOpen
                        ? classes.navbarButtonWithHighLight
                        : classes.navbarButtonWithoutHighlight,
                    }}
                    onClick={(e) => {
                      handleProfileMenu(e, 'open');
                    }}
                  >
                    <Icon style={{ marginRight: 5 }}>person</Icon> Mi cuenta
                  </Button>
                </ToggleUI>
                <UserMenuLayout
                  idCountry={idCountry}
                  history={history}
                  anchorEl={state.anchorEl}
                  profileMenuOpen={state.profileMenuOpen}
                  handleProfileMenu={handleProfileMenu}
                  logout={logout}
                  setAvoidDrawerLogic={setAvoidDrawerLogic}
                  setForceRenderDrawer={setForceRenderDrawer}
                />
              </div>
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer}>
            <Hidden mdUp implementation="css">
              <Drawer
                container={null}
                variant="temporary"
                anchor="left"
                open={state.mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                  root: classes.drawerRoot,
                }}
              >
                <DrawerLayout
                  url={history.location.pathname}
                  loading={state.layoutLoading}
                  offMode={tienda.t_offmode}
                  idTienda={tienda.idTienda}
                  isDebtor={tienda.t_deuda}
                  isSuscribed={tienda.t_debito_automatico}
                  idCountry={tienda.Country_idCountry}
                  domainName={dominios?.d_nombre || dominios[0]?.d_nombre}
                  handleDrawerToggle={handleDrawerToggle}
                  handleChangeOffStore={handleChangeOffStore}
                  avoidDrawerLogic={avoidDrawerLogic}
                  key={forceRenderDrawer ? 'forceUpdate' : 'normalUpdate'}
                  dispatchAlert={dispatchAlert}
                />
              </Drawer>
            </Hidden>
            <Hidden mdDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                <DrawerLayout
                  url={history.location.pathname}
                  loading={state.layoutLoading}
                  offMode={tienda.t_offmode}
                  idTienda={tienda.idTienda}
                  isDebtor={tienda.t_deuda}
                  isSuscribed={tienda.t_debito_automatico}
                  idCountry={tienda.Country_idCountry}
                  domainName={dominios?.d_nombre || dominios[0]?.d_nombre}
                  handleDrawerToggle={handleDrawerToggle}
                  handleChangeOffStore={handleChangeOffStore}
                  avoidDrawerLogic={avoidDrawerLogic}
                  key={forceRenderDrawer ? 'forceUpdate' : 'normalUpdate'}
                  dispatchAlert={dispatchAlert}
                />
              </Drawer>
            </Hidden>
          </nav>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {children}
          </main>
        </>
      ) : (
        <>
          {error ? (
            <ErrorLayout url={history.location.pathname} />
          ) : (
            <>{children}</>
          )}
          <AlertUI
            open={state.alert.open}
            message={state.alert.message}
            type={state.alert.type}
            handleCloseAlert={handleCloseAlert}
          />
        </>
      )}
    </div>
  );
};

const styles = (theme) => ({
  badge: {
    background: '#ec268f',
  },
  logo: {
    width: 'auto',
    height: '20px',
    marginLeft: '-10px',
    margin: 'auto',
    display: 'block',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      height: '28px',
    },
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: '300px',
      flexShrink: 0,
    },
  },

  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  grow: {
    flexGrow: 1,
  },
  navbarButton: {
    color: theme.texts.navbar_buttons,
    fontSize: '0.875rem',
    fontWeight: 500,
    '&:focus': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
      fontWeight: '700',
    },
  },
  navbarButtonWithoutHighlight: {
    color: theme.texts.navbar_buttons,
    fontSize: '0.875rem',
    fontWeight: 500,
  },
  navbarButtonWithHighLight: {
    color: theme.palette.primary.main,
    fontSize: '0.875rem',
    fontWeight: '700',
  },

  toolbar: theme.mixins.toolbar,

  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  appBar: {
    backgroundColor: '#fff',
    boxShadow: '0 0.07em 0.125em 0 rgba(0,0,0,.15)',
  },
  menuButton: {
    marginRight: 20,
    color: theme.texts.menu_hambuger,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: '300px',
    zIndex: 1000,
    border: '0px solid transparent',
  },
  drawerRoot: {
    zIndex: 1201,
  },
  content: {
    flexGrow: 1,
    maxWidth: '100vw',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 'calc(100vw - 300px)',
      padding: theme.spacing(3),
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionUser: {
    display: 'flex',
  },
});

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    beta: state.beta,
    tienda: state.tienda,
    metodos_pago: state.metodos_pago,
    authBrazeUser: state.authBrazeUser,
    dominios: state.dominios,
    alert: state.showAlert,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminLogout: () => dispatch(adminLogout()),
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    tiendaActualizarApagarTemporalmente: (data, callback) =>
      dispatch(tiendaActualizarApagarTemporalmente(data, callback)),
    metodospagoObtener: (callback) => dispatch(metodospagoObtener(callback)),
    authBrazeUser: (callback) => dispatch(authBrazeUser(callback)),
    splitioSetCustomerId: (data) => dispatch(splitioSetCustomerId(data)),
    dominioObtenerDominioPreferencial: (callback) =>
      dispatch(dominioObtenerDominioPreferencial(callback)),
    dispatchAlert: (data) => dispatch(showAlertUI(data)),
  };
};

export const GlobalPrivateLayout = withRouter(
  withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(connectSplit()(GlobalPrivateLayout_))
  )
);
