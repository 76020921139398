/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  metodosenvioObtener,
  metodoenvioConfigurarEPick,
} from '../../store/actions';
import { Config, Regex, RegexExtra, Validate } from '../../other';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  InputUI,
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
  CheckboxUI,
  ModuleCardTitleUI,
  SelectUI,
  ToggleUI,
} from '../common';
import {
  method_shipment_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../amplitude';
import * as braze from '@braze/web-sdk';
import { WrapperLayout } from '../../layouts/WrapperLayout';

class EPick_ extends Component {
  state = {
    epick: {
      me_epick: {
        validate: false,
        name: 'me_epick',
        label: 'Activar E-Pick',
        value: 1,
        change_param: 'epick',
      },
      me_epick_solicitante: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Persona responsable',
        id: 'me_epick_solicitante',
        name: 'me_epick_solicitante',
        change_param: 'epick',
        regex: Regex.STRING_GENERICO_2_45,
        value: '',
        default_value: '',
        placeholder: 'Nombre del responsable',
        messages: {
          error: 'Por favor, ingrese un titular válido',
          help: 'Nombre completo del titular o responsable del envío',
          error_extra: RegexExtra.STRING_GENERICO_2_45,
        },
      },
      me_epick_telefono: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Teléfono del responsable',
        id: 'me_epick_telefono',
        name: 'me_epick_telefono',
        change_param: 'epick',
        regex: Regex.TELEFONO,
        value: '',
        default_value: '',
        placeholder: 'Teléfono del responsable',
        messages: {
          error: 'Por favor, ingrese un teléfono válido',
          help: 'Será utilizado para medio de contacto para los envíos',
          error_extra: RegexExtra.TELEFONO,
        },
      },
      me_epick_calle: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Calle',
        id: 'me_epick_calle',
        name: 'me_epick_calle',
        change_param: 'epick',
        regex: Regex.CALLE_1_30,
        value: '',
        default_value: '',
        placeholder: 'Av. Pellegrini',
        messages: {
          error: 'Por favor, ingrese una calle válida',
          error_extra: RegexExtra.CALLE_1_30,
        },
      },
      me_epick_nro: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Número',
        id: 'me_epick_nro',
        name: 'me_epick_nro',
        change_param: 'epick',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: '13210',
        messages: {
          error: 'Por favor, ingrese un número de calle válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      me_epick_piso: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Piso',
        id: 'me_epick_piso',
        name: 'me_epick_piso',
        change_param: 'epick',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: '10',
        messages: {
          error: 'Por favor, ingrese un número de piso válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      me_epick_dpto: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Dpto',
        id: 'me_epick_dpto',
        name: 'me_epick_dpto',
        change_param: 'epick',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: 'A',
        messages: {
          error: 'Por favor, ingrese un número de dpto válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      me_epick_cp: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Código postal',
        id: 'me_epick_cp',
        name: 'me_epick_cp',
        change_param: 'epick',
        regex: Regex.CODIGO_POSTAL,
        value: '',
        default_value: '',
        placeholder: '1234',
        messages: {
          error: 'Por favor, ngrese un número de código postal válido',
          error_extra: RegexExtra.CODIGO_POSTAL,
        },
      },
      me_epick_localidad: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Ciudad',
        id: 'me_epick_localidad',
        name: 'me_epick_localidad',
        change_param: 'epick',
        regex: Regex.CIUDAD_2_45,
        value: '',
        default_value: '',
        placeholder: 'Rosario',
        messages: {
          error: 'Por favor, ingrese una ciudad válida',
          error_extra: RegexExtra.CIUDAD_2_45,
        },
      },
      me_epick_provincia: {
        validate: false,
        required: true,
        error: false,
        label: 'Provincia',
        id: 'me_epick_provincia',
        name: 'me_epick_provincia',
        change_param: 'epick',
        value: 'BUENOS AIRES',
        messages: {
          error: 'Por favor, seleccione una opción',
        },
      },
      me_epick_tipo: {
        validate: false,
        required: true,
        error: false,
        label: 'Ofrecer envío gratis',
        id: 'me_epick_tipo',
        name: 'me_epick_tipo',
        change_param: 'epick',
        value: 0,
        messages: {
          error: 'Por favor, seleccione una opción',
        },
      },
      me_epick_gratis_minimo: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Monto mínimo',
        id: 'me_epick_gratis_minimo',
        name: 'me_epick_gratis_minimo',
        change_param: 'epick',
        regex: Regex.PRECIO.ARG,
        value: '',
        default_value: '',
        placeholder: '3000',
        messages: {
          help: 'El monto mínimo requerido de la compra para poder acceder al envío gratis',
          error: 'Por favor, ingrese un monto mínimo válido',
          error_extra: RegexExtra.PRECIO.ARG,
        },
      },
      me_epick_recargo_monto: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Monto fijo a recargar/descontar',
        id: 'me_epick_recargo_monto',
        name: 'me_epick_recargo_monto',
        change_param: 'epick',
        regex: Regex.PRECIO_CON_SIGNO.ARG,
        value: '',
        default_value: '',
        placeholder: '50.00',
        messages: {
          error: 'Por favor, ingrese un monto válido',
          error_extra: RegexExtra.PRECIO_CON_SIGNO.ARG,
        },
      },
      me_epick_recargo_porcentaje: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Porcentaje sobre la tarifa a recargar/descontar',
        id: 'me_epick_recargo_porcentaje',
        name: 'me_epick_recargo_porcentaje',
        change_param: 'epick',
        regex: Regex.PORCENTAJE_CON_SIGNO,
        value: '',
        default_value: '',
        placeholder: '5',
        messages: {
          error: 'Por favor, ingrese un porcentaje válido',
          error_extra: RegexExtra.PORCENTAJE_CON_SIGNO,
        },
      },
    },
    loading: {
      epick: true,
      error: false,
    },
    forms: {
      epick: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    selects: {
      provincias: [
        {
          value: 'BUENOS AIRES',
          label: 'Buenos Aires',
        },
        {
          value: 'CATAMARCA',
          label: 'Catamarca',
        },
        {
          value: 'CHACO',
          label: 'Chaco',
        },
        {
          value: 'CHUBUT',
          label: 'Chubut',
        },
        {
          value: 'CORDOBA',
          label: 'Córdoba',
        },
        {
          value: 'CORRIENTES',
          label: 'Corrientes',
        },
        {
          value: 'ENTRE RIOS',
          label: 'Entre Ríos',
        },
        {
          value: 'FORMOSA',
          label: 'Formosa',
        },
        {
          value: 'JUJUY',
          label: 'Jujuy',
        },
        {
          value: 'LA PAMPA',
          label: 'La Pampa',
        },
        {
          value: 'LA RIOJA',
          label: 'La Rioja',
        },
        {
          value: 'MENDOZA',
          label: 'Mendoza',
        },
        {
          value: 'MISIONES',
          label: 'Misiones',
        },
        {
          value: 'NEUQUEN',
          label: 'Neuquén',
        },
        {
          value: 'RIO NEGRO',
          label: 'Río Negro',
        },
        {
          value: 'SALTA',
          label: 'Salta',
        },
        {
          value: 'SAN JUAN',
          label: 'San Juan',
        },
        {
          value: 'SAN LUIS',
          label: 'San Luis',
        },
        {
          value: 'SANTA CRUZ',
          label: 'Santa Cruz',
        },
        {
          value: 'SANTA FE',
          label: 'Santa Fe',
        },
        {
          value: 'SGO. DEL ESTERO',
          label: 'Santiago del Estero',
        },
        {
          value: 'TIERRA DEL FUEGO',
          label: 'Tierra del Fuego',
        },
        {
          value: 'TUCUMAN',
          label: 'Tucumán',
        },
      ],
      me_epick_tipo: [
        {
          value: 0,
          label: 'No',
        },
        {
          value: 1,
          label: 'Si (Para todos los productos)',
        },
        {
          value: 2,
          label: 'Si (Ventas mayores a cierto monto)',
        },
      ],
    },
  };

  componentDidMount() {
    this.props.metodosenvioObtener((err, resp) => {
      if (err) {
        this.setState({
          loading: {
            ...this.state.loading,
            epick: false,
            error: true,
          },
        });
      } else {
        Validate.cargar_formulario_sin_nulos(
          this.props.metodos_envio,
          this.state.epick,
          (epick_obj) => {
            this.setState({
              epick: epick_obj,
              loading: {
                ...this.state.loading,
                epick: false,
              },
            });
          }
        );
      }
    });
  }

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      {
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: input,
            },
          });
        });
      }
    );
  };

  handleChangeSelect = (e, aditional) => {
    let name = e.target.name;
    let value =
      name === 'me_epick_provincia'
        ? Validate.trim(e.target.value)
        : Validate.trim_int(e.target.value);

    this.setState({
      [aditional]: {
        ...this.state[aditional],
        [name]: {
          ...this.state[aditional][name],
          value: value,
        },
      },
    });
  };

  handleChangeCheckbox = (e, aditional) => {
    let name = e.target.name;

    this.setState({
      [aditional]: {
        ...this.state[aditional],
        [name]: {
          ...this.state[aditional][name],
          value: this.state[aditional][name]['value'] ? 0 : 1,
        },
      },
    });
  };

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        epick: true,
      },
    });

    let form = this.state.epick;

    if (Validate.validar_formulario(form)) {
      this.props.metodoenvioConfigurarEPick(
        {
          me_epick: form.me_epick.value,
          me_epick_solicitante: form.me_epick_solicitante.value,
          me_epick_telefono: form.me_epick_telefono.value,
          me_epick_calle: form.me_epick_calle.value,
          me_epick_nro: form.me_epick_nro.value,
          me_epick_piso: form.me_epick_piso.value,
          me_epick_dpto: form.me_epick_dpto.value,
          me_epick_cp: form.me_epick_cp.value,
          me_epick_localidad: form.me_epick_localidad.value,
          me_epick_provincia: form.me_epick_provincia.value,
          me_epick_tipo: form.me_epick_tipo.value,
          me_epick_gratis_minimo: form.me_epick_gratis_minimo.value,
          me_epick_recargo_porcentaje: form.me_epick_recargo_porcentaje.value,
          me_epick_recargo_monto: form.me_epick_recargo_monto.value,
        },
        (err, resp) => {
          this.setState(
            {
              forms: {
                ...this.state.forms,
                epick: false,
              },
              alert: {
                ...this.state.alert,
                open: true,
                message: resp.message,
                type: err ? 'error' : 'success',
              },
            },
            () => {
              if (!err && form.me_epick.value === 1) {
                createAmplitudeEventWithDevice(
                  method_shipment_amplitude_events.metodos_envio_activar
                    .event_name,
                  { metodo_envio: 'e-pick', success: true }
                );

                if (this.props.idCountry === 'ARG') {
                  braze.logCustomEvent('empretienda_metodos_envío_activar', {
                    metodo_envio: 'e-pick',
                  });
                }
              } else {
                createAmplitudeEventWithDevice(
                  method_shipment_amplitude_events.metodos_envio_desactivar
                    .event_name,
                  { metodo_envio: 'e-pick' }
                );
              }
            }
          );
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState(
          {
            forms: {
              ...this.state.forms,
              epick: false,
            },
            epick: new_form,
            alert: {
              ...this.state.alert,
              open: true,
              message: 'Por favor, revisá tus datos, tenés algunos errores',
              type: 'error',
            },
          },
          () => {
            createAmplitudeEventWithDevice(
              method_shipment_amplitude_events.metodos_envio_activar.event_name,
              { metodo_envio: 'e-pick', success: false }
            );
          }
        );
      });
    }
  };

  render() {
    let classes = this.props.classes;

    let ejemplo = 358.0;
    let porcentaje = Validate.trim_float(
      this.state.epick.me_epick_recargo_porcentaje.value
    );
    let porcentaje_a_ejemplo = Validate.trim_float(
      Validate.get_fixed_percent(ejemplo, porcentaje)
    );
    let monto = Validate.trim_float(
      this.state.epick.me_epick_recargo_monto.value
    );
    let total = ejemplo + porcentaje_a_ejemplo + monto;
    total = total > 0 ? total : 0;
    let porcentaje_label = porcentaje >= 0 ? 'recargo' : 'descuento';
    let monto_fijo_label = monto >= 0 ? 'recargo' : 'descuento';

    return (
      <WrapperLayout
        loading={this.state.loading.epick}
        error={this.state.loading.error}
      >
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <form onSubmit={this.handleSubmit}>
              <ModuleTitleUI
                title="E-Pick"
                image={`${Config.CLOUDFRONT_CDN_PROD}/panel/logos-integraciones/epick-logo.png`}
                subtitles={[
                  <span>
                    E-Pick es un servicio low cost que ofrece envíos puerta a
                    puerta.
                  </span>,
                  <span>
                    Te recomendamos ver nuestro tutorial para que veas como
                    funciona desde{' '}
                    <a
                      href="https://recursos.empretienda.com/tutorial-epick.pdf"
                      target="_blank"
                      style={{ color: 'inherit' }}
                    >
                      <u>aquí</u>
                    </a>
                    .
                  </span>,
                  <span>
                    Podés ver su zona de cobertura{' '}
                    <a
                      href="http://bit.ly/MapaCobertura-E-Pick"
                      target="_blank"
                      style={{ color: 'inherit' }}
                    >
                      <u>aquí</u>
                    </a>
                    .
                  </span>,
                ]}
              />
              <Card className={classes.card}>
                <CardContent className={classes.card_content}>
                  <Grid container spacing={1.5}>
                    <ModuleCardTitleUI
                      title="Información de contacto"
                      subtitles={[
                        'Esta información sera utilizada para contactarte en caso de ser necesario.',
                      ]}
                    />
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.epick.me_epick_solicitante}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.epick.me_epick_telefono}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card_below}>
                <CardContent className={classes.card_content}>
                  <Grid container spacing={1.5}>
                    <ModuleCardTitleUI
                      title="Envío gratis"
                      subtitles={[
                        'En esta sección podés configurar los envíos gratuitos a través de E-Pick.',
                      ]}
                    />
                    <Grid item xs={12}>
                      <SelectUI
                        data={this.state.epick.me_epick_tipo}
                        options={this.state.selects.me_epick_tipo}
                        handleChangeSelect={this.handleChangeSelect}
                      />
                    </Grid>
                    <ToggleUI show={this.state.epick.me_epick_tipo.value === 2}>
                      <Grid item xs={12}>
                        <InputUI
                          handleChange={this.handleChange}
                          input={this.state.epick.me_epick_gratis_minimo}
                          adornment="$"
                        />
                      </Grid>
                    </ToggleUI>
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card_below}>
                <CardContent className={classes.card_content}>
                  <Grid container spacing={3}>
                    <ModuleCardTitleUI
                      title="Recargos y/o descuentos (opcional)"
                      subtitles={[
                        'Podés configurar un aumento o disminución en monto fijo y/o porcentaje al monto tarifado por E-Pick. Por ejemplo, podés agregar el porcentaje de comisión por venta de los métodos de pago, costos de embalaje, despacho, etc o hacer un descuento por una parte del envío a tu cliente y vos abonar esa diferencia.',
                        'Para descontar sólo tenés que poner el signo menos (-) delante del monto y/o porcentaje.',
                      ]}
                    />
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.epick.me_epick_recargo_porcentaje}
                        end_adornment="%"
                        step="0.01"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.epick.me_epick_recargo_monto}
                        adornment="$"
                        step="0.01"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography variant="body1" mb={1}>
                        Ejemplo de cómo tarifaría suponiendo que el costo del
                        envío sea de ${Validate.number_format(ejemplo)} (monto
                        no real, los costos de envío pueden variar según origen,
                        destino, dimensiones y peso del paquete):
                      </Typography>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell sx={{ paddingLeft: '0px' }}>
                              Ejemplo tarifa final E-Pick
                            </TableCell>
                            <TableCell align="right">
                              ${Validate.number_format(ejemplo)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ paddingLeft: '0px' }}>
                              Porcentaje de {porcentaje_label} ({porcentaje}%)
                            </TableCell>
                            <TableCell align="right">
                              ${Validate.number_format(porcentaje_a_ejemplo)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ paddingLeft: '0px' }}>
                              Monto de {monto_fijo_label}
                            </TableCell>
                            <TableCell align="right">
                              ${Validate.number_format(monto)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ paddingLeft: '0px' }}>
                              Tarifa final a pagar por el cliente
                            </TableCell>
                            <TableCell align="right">
                              ${Validate.number_format(total)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card_below}>
                <CardContent className={classes.card_content}>
                  <Grid container spacing={1.5}>
                    <ModuleCardTitleUI
                      title="Dirección de retiro"
                      subtitles={[
                        'Esta información será utilizada para retirar los paquetes.',
                      ]}
                    />
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.epick.me_epick_calle}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.epick.me_epick_nro}
                      />
                    </Grid>
                    <Grid item lg={4} xs={6}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.epick.me_epick_piso}
                      />
                    </Grid>
                    <Grid item lg={4} xs={6}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.epick.me_epick_dpto}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectUI
                        data={this.state.epick.me_epick_provincia}
                        options={this.state.selects.provincias}
                        handleChangeSelect={this.handleChangeSelect}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.epick.me_epick_localidad}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={this.state.epick.me_epick_cp}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CheckboxUI
                        handleChangeCheckbox={this.handleChangeCheckbox}
                        checkbox={this.state.epick.me_epick}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <ModuleButtonBoxUI>
                <ButtonUI
                  type="submit"
                  label="Guardar"
                  minHeight={true}
                  isLoading={this.state.forms.epick}
                />
              </ModuleButtonBoxUI>
            </form>
          </Grid>
        </Grid>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_below: theme.card.card_below,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
});

const mapStateToProps = (state) => {
  return {
    metodos_envio: state.metodos_envio,
    idCountry: state.tienda.Country_idCountry,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    metodosenvioObtener: (callback) => dispatch(metodosenvioObtener(callback)),
    metodoenvioConfigurarEPick: (data, callback) =>
      dispatch(metodoenvioConfigurarEPick(data, callback)),
  };
};

export const EPick = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(EPick_)
);
