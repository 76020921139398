import React from 'react';
import { connect } from 'react-redux';
import {
  Avatar,
  IconButton,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Validate } from '../../../../other';

const renderizarLocales = (locales, modalHandler, classes) => {
  let mobile = Validate.is_mobile();

  if (locales.length > 0) {
    return locales.map((local, index) => {
      return (
        <ListItem divider={index < locales.length - 1} key={local.idLocales}>
          {!mobile ? (
            <ListItemAvatar>
              <Avatar>
                <Icon>store</Icon>
              </Avatar>
            </ListItemAvatar>
          ) : (
            ''
          )}
          <ListItemText
            primary={local.l_nombre}
            classes={{ root: classes.list_text }}
            secondary={
              <span className={classes.span}>
                <span className={classes.span}>{local.l_telefono}</span>
                <span className={classes.span}>{local.l_direccion}</span>
              </span>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              aria-label="Editar"
              onClick={(e) => modalHandler(true, 'editar', local)}
              size="large"
            >
              <Icon>edit</Icon>
            </IconButton>
            <IconButton
              aria-label="Borrar"
              onClick={(e) => modalHandler(true, 'borrar', local)}
              size="large"
            >
              <Icon>delete</Icon>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
  } else {
    return (
      <ListItem>
        <ListItemText
          primary="No se han encontrado locales activos"
          secondary="Puedes agregar uno haciendo click en Agregar"
        />
      </ListItem>
    );
  }
};

const LocalesList_ = (props) => {
  let { locales, classes, modalHandler } = props;

  return (
    <List classes={{ root: classes.list }}>
      {renderizarLocales(locales, modalHandler, classes)}
    </List>
  );
};

const styles = (theme) => ({
  span: {
    display: 'block',
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  list: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '0',
    '&:last-child': {
      borderBottom: '0px solid transparent',
    },
  },
  list_text: {
    paddingRight: '100px',
  },
});

const mapStateToProps = (state) => {
  return {
    locales: state.locales,
  };
};

const mapDispatchToProps = null;

export const LocalesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(LocalesList_));
