import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { adminPromo } from '../../store/actions';
import { Config, Validate } from '../../other';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  PublicLayout,
  ButtonUI,
  AlertUI,
  ToggleUI,
  CardModuleUI,
} from '../common';

class Promo_ extends Component {
  recaptchaRef = React.createRef();

  state = {
    promo: {
      t_email_hash: {
        validate: false,
        value: '',
      },
      code: {
        validate: false,
        value: '',
      },
    },
    next_due: '',
    days: 0,
    after: false,
    forms: {
      promo: false,
    },
    recaptcha_state: false,
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  componentDidMount() {
    let token = this.props.match.params.token.split('-');
    if (token.length === 2) {
      let t_email_hash = token[0];
      let days = Validate.trim_int(token[1]) === 0 ? 30 : 15;
      let code = Validate.trim_int(this.props.match.params.code);

      if (t_email_hash.length === 64 && code > 0) {
        this.setState({
          promo: {
            ...this.state.promo,
            t_email_hash: {
              validate: false,
              value: t_email_hash,
            },
            code: {
              validate: false,
              value: code,
            },
          },
          days: days,
        });
      } else {
        this.props.history.push('/login');
      }
    } else {
      this.props.history.push('/login');
    }
  }

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  handleSubmit = (t_captcha) => {
    this.setState(
      {
        forms: {
          ...this.state.forms,
          promo: true,
        },
        recaptcha_state: false,
      },
      () => {
        if (t_captcha) {
          this.props.adminPromo(
            {
              t_email_hash: this.state.promo.t_email_hash.value,
              code: this.state.promo.code.value,
              t_captcha: t_captcha,
            },
            (err, resp) => {
              if (err) {
                this.setState(
                  {
                    forms: {
                      ...this.state.forms,
                      promo: false,
                    },
                    alert: {
                      ...this.state.alert,
                      open: true,
                      message: resp.message,
                    },
                  },
                  () => this.resetRecaptcha()
                );
              } else {
                this.setState({
                  forms: {
                    ...this.state.forms,
                    promo: false,
                  },
                  next_due: Validate.utc2local_format(
                    resp.data.f_fecha_vencimiento,
                    Config.SHORTDATE_FULLFORMAT
                  ),
                  after: true,
                });
              }
            }
          );
        } else {
          this.setState(
            {
              forms: {
                ...this.state.forms,
                promo: false,
              },
              alert: {
                open: true,
                type: 'error',
                message:
                  'Ha ocurrido un error con el captcha, intente nuevamente',
              },
            },
            () => this.resetRecaptcha()
          );
        }
      }
    );
  };

  fireCaptcha = (e) => {
    e.preventDefault();
    this.setState(
      {
        recaptcha_state: true,
      },
      () => this.recaptchaRef.current.execute()
    );
  };

  resetRecaptcha = () => {
    this.setState(
      {
        recaptcha_state: false,
      },
      () => this.recaptchaRef.current.reset()
    );
  };

  render() {
    let { classes } = this.props;

    return (
      <PublicLayout>
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <form onSubmit={this.fireCaptcha}>
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <CardModuleUI big_shadow>
                <div className={classes.card_padding}>
                  <div className={classes.logo_wrapper}>
                    <Link to="/login">
                      <img
                        src={`${Config.CLOUDFRONT_CDN_PROD}/landingpage/images/v4/empretienda-green-logo.png`}
                        alt="Empretienda logo"
                        className={classes.logo}
                      />
                    </Link>
                  </div>
                  <ToggleUI show={!this.state.after}>
                    <ReCAPTCHA
                      ref={this.recaptchaRef}
                      size="invisible"
                      sitekey={Config.RECAPTCHA_PUBLIC_KEY}
                      onChange={(token) => this.handleSubmit(token)}
                      onExpired={() => this.resetRecaptcha()}
                      onErrored={() => this.resetRecaptcha()}
                    />
                    <p className={classes.text}>
                      Queremos que sigas haciendolo real con Empretienda, por
                      eso te damos {this.state.days} días extra gratis para que
                      lleves tu negocio al siguiente nivel.
                    </p>
                    <div className={classes.separator}></div>
                    <ButtonUI
                      type="submit"
                      label={'Activar mis ' + this.state.days + ' días gratis'}
                      size="large"
                      border_radius
                      fullWidth={false}
                      isLoading={this.state.forms.promo}
                      disabled={this.state.recaptcha_state}
                    />
                  </ToggleUI>
                  <ToggleUI show={this.state.after}>
                    <p className={classes.text}>
                      Tus {this.state.days} días extra fueron agregados con
                      éxito y vencen el {this.state.next_due}.
                    </p>
                    <div className={classes.separator}></div>
                    <ButtonUI
                      type="link"
                      label="Iniciar sesión"
                      size="large"
                      border_radius
                      fullWidth={false}
                      link="/login"
                    />
                  </ToggleUI>
                </div>
              </CardModuleUI>
            </Grid>
          </Grid>
        </form>
      </PublicLayout>
    );
  }
}

const styles = (theme) => ({
  separator: {
    width: '100%',
    height: '15px',
  },
  card_padding: {
    padding: '10px',
    [theme.breakpoints.up('sm')]: {
      padding: '10px 30px',
    },
  },
  logo_wrapper: {
    marginBottom: '30px',
  },
  logo: {
    width: '250px',
    maxWidth: '100%',
  },
  text: {
    fontWeight: 'bold',
    lineHeight: '1.5',
    [theme.breakpoints.up('sm')]: {
      width: '70%',
    },
  },
});

const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => {
  return {
    adminPromo: (usuario, callback) => dispatch(adminPromo(usuario, callback)),
  };
};

export const Promo = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Promo_)
);
