import React, { Component } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid/v1';
import async from 'async';
import {
  tiendaObtener,
  bloqueObtenerBloques,
  tiendaActualizarTemplate,
  productoObtenerProductosOrdenados,
  tiendaGenerarPrevisualizacionTemplate,
} from '../../store/actions';
import { Validate, Regex, RegexExtra, Config } from '../../other';
import {
  DrawerLayout,
  DrawerContent,
  NavigationLayout,
  ShopPreview,
  BuilderLoader,
  BuilderWrapper,
} from './subcomponents';
import { AlertUI, ErrorLayout } from '../common';
import {
  design_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../amplitude';
import * as braze from '@braze/web-sdk';

const is_mobile = Validate.is_mobile();

class Builder_ extends Component {
  state = {
    deviceActive: 'desktop',
    loading_tienda: true,
    loading_bloques: true,
    loading_productos_ord: true,
    loading_preview_html: true,
    update_preview_html: false,
    spinner_message: 'Cargando editor...',
    saving_changes: false,
    uploading_images: false,
    error: false,
    active_page: -1,
    back_page: -1,
    drawer_status: false,
    drawer_title: '',

    settings: {
      template_id: {
        validate: false,
        value: 1,
      },
      t_logo: {
        validate: false,
        auth: true,
        uploading: false,
        required: false,
        label: '',
        id: 't_logo',
        sublabels: [''],
        files: [],
        messages: {
          help: '',
          error: '',
        },
      },
      t_favicon: {
        validate: false,
        auth: true,
        uploading: false,
        required: false,
        label: '',
        id: 't_favicon',
        sublabels: [],
        files: [],
        messages: {
          help: '',
          error: '',
        },
      },
      color: {
        validate: false,
        required: true,
        error: false,
        label: 'Color de plantilla',
        id: 'color',
        name: 'color',
        change_param: 'settings',
        value: 1,
        messages: {
          error: '',
        },
      },
      primary_text: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Color de texto',
        id: '',
        name: 'primary_text',
        change_param: 'settings',
        regex: Regex.COLOR,
        value: '',
        default_value: '',
        placeholder: '#000000',
        messages: {
          error_extra: RegexExtra.COLOR,
          error: '',
        },
      },
      primary_background: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Color primario',
        id: '',
        name: 'primary_background',
        change_param: 'settings',
        regex: Regex.COLOR,
        value: '',
        default_value: '',
        placeholder: '#000000',
        messages: {
          error_extra: RegexExtra.COLOR,
          error: '',
        },
      },
      primary_contrastText: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Contraste color primario',
        id: '',
        name: 'primary_contrastText',
        change_param: 'settings',
        regex: Regex.COLOR,
        value: '',
        default_value: '',
        placeholder: '#000000',
        messages: {
          error_extra: RegexExtra.COLOR,
          error: '',
        },
      },
      secondary_text: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Color de texto secundario',
        id: '',
        name: 'secondary_text',
        change_param: 'settings',
        regex: Regex.COLOR,
        value: '',
        default_value: '',
        placeholder: '#000000',
        messages: {
          error_extra: RegexExtra.COLOR,
          error: '',
        },
      },
      secondary_background: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Color secundario',
        id: '',
        name: 'secondary_background',
        change_param: 'settings',
        regex: Regex.COLOR,
        value: '',
        default_value: '',
        placeholder: '#000000',
        messages: {
          error_extra: RegexExtra.COLOR,
          error: '',
        },
      },
      secondary_contrastText: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Contraste color secundario',
        id: '',
        name: 'secondary_contrastText',
        change_param: 'settings',
        regex: Regex.COLOR,
        value: '',
        default_value: '',
        placeholder: '#000000',
        messages: {
          error_extra: RegexExtra.COLOR,
          error: '',
        },
      },
      body_background: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Fondo',
        id: '',
        name: 'body_background',
        change_param: 'settings',
        regex: Regex.COLOR,
        value: '',
        default_value: '',
        placeholder: '#000000',
        messages: {
          error_extra: RegexExtra.COLOR,
          error: '',
        },
      },
      font_title: {
        validate: false,
        required: true,
        error: false,
        label: 'Fuente de títulos',
        id: 'font_title',
        name: 'font_title',
        change_param: 'settings',
        value: 20,
        messages: {
          error: '',
        },
      },
      font_text: {
        validate: false,
        required: true,
        error: false,
        label: 'Fuente de textos',
        id: 'font_text',
        name: 'font_text',
        change_param: 'settings',
        value: 20,
        messages: {
          error: '',
        },
      },
      message_text: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Mensaje',
        id: '',
        name: 'message_text',
        change_param: 'settings',
        regex: Regex.DESCRIPCION_0_1000,
        value: '',
        default_value: '',
        placeholder: '',
        messages: {
          error_extra: RegexExtra.DESCRIPCION_0_1000,
          error: '',
        },
      },
      message_link: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Link',
        id: '',
        name: 'message_link',
        change_param: 'settings',
        regex: Regex.LINK,
        value: '',
        default_value: '',
        placeholder: '',
        messages: {
          error_extra: RegexExtra.LINK,
          error: 'Por favor, ingresa un link válido',
        },
      },
      css: {
        validate: false,
        name: 'css',
        id: '',
        change_param: 'settings',
        value: '',
        placeholder: 'Escriba o pegue su código CSS aquí',
      },
      products_feed_order: {
        validate: false,
        required: true,
        error: false,
        label: 'Orden de productos',
        id: 'products_feed_order',
        name: 'products_feed_order',
        change_param: 'settings',
        value: 0,
        messages: {
          error: '',
        },
      },
      products_feed_filter: {
        validate: false,
        required: true,
        error: false,
        label: 'Filtro de atributos',
        id: 'products_feed_filter',
        name: 'products_feed_filter',
        change_param: 'settings',
        value: 0,
        messages: {
          error: '',
          help: 'Los filtros pueden demorar hasta 15 minutos en actualizarse o aparecer',
        },
      },
      products_feed_product_subtext_type: {
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de leyenda',
        id: 'products_feed_product_subtext_type',
        name: 'products_feed_product_subtext_type',
        change_param: 'settings',
        value: 0,
        messages: {
          error: '',
        },
      },
      products_feed_product_subtext: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Leyenda de productos',
        id: '',
        name: 'products_feed_product_subtext',
        change_param: 'settings',
        regex: Regex.STRING_GENERICO_2_45,
        value: '',
        default_value: '',
        placeholder: ' ',
        messages: {
          error: 'Por favor, ingrese una leyenda válida',
          error_extra: RegexExtra.STRING_GENERICO_2_45,
        },
      },
      products_feed_product_subtext_cuotas: {
        validate: false,
        required: true,
        error: false,
        label: 'Cuotas sin interés ofrecidas',
        id: 'products_feed_product_subtext_cuotas',
        name: 'products_feed_product_subtext_cuotas',
        change_param: 'settings',
        value: 3,
        messages: {
          error: '',
        },
      },
      products_detail_photo_display: {
        validate: false,
        required: true,
        error: false,
        label: 'Visualización de fotos',
        id: 'products_detail_photo_display',
        name: 'products_detail_photo_display',
        change_param: 'settings',
        value: 0,
        messages: {
          error: '',
        },
      },
      products_detail_photo_zoom: {
        validate: false,
        required: true,
        error: false,
        label: 'Zoom de fotos',
        id: 'products_detail_photo_zoom',
        name: 'products_detail_photo_zoom',
        change_param: 'settings',
        value: 0,
        messages: {
          error: '',
        },
      },
      checkout_comments: {
        validate: false,
        required: true,
        error: false,
        label: 'Permitir notas',
        id: 'checkout_comments',
        name: 'checkout_comments',
        change_param: 'settings',
        value: 0,
        messages: {
          error: '',
        },
      },
      checkout_short_billing_form: {
        validate: false,
        required: true,
        error: false,
        label: 'Formulario de facturación simple',
        id: 'checkout_short_billing_form',
        name: 'checkout_short_billing_form',
        change_param: 'settings',
        value: 0,
        messages: {
          error: '',
        },
      },
      checkout_step_review: {
        validate: false,
        required: true,
        error: false,
        label: 'Paso de revisión',
        id: 'checkout_step_review',
        name: 'checkout_step_review',
        change_param: 'settings',
        value: 0,
        messages: {
          error: '',
        },
      },
      other_border_radius: {
        validate: false,
        required: true,
        error: false,
        label: 'Bordes redondeados',
        id: 'other_border_radius',
        name: 'other_border_radius',
        change_param: 'settings',
        value: 0,
        messages: {
          error: '',
        },
      },
      other_menu_label_products: {
        auth: true,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Menú de productos',
        id: '',
        name: 'other_menu_label_products',
        change_param: 'settings',
        regex: Regex.STRING_GENERICO_2_45,
        value: '',
        default_value: '',
        placeholder: '',
        messages: {
          error_extra: RegexExtra.STRING_GENERICO_2_45,
          error: '',
        },
      },
      other_menu_label_wholesaler: {
        auth: true,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Menú de mayoristas',
        id: '',
        name: 'other_menu_label_wholesaler',
        change_param: 'settings',
        regex: Regex.STRING_GENERICO_2_45,
        value: '',
        default_value: '',
        placeholder: '',
        messages: {
          error_extra: RegexExtra.STRING_GENERICO_2_45,
          error: '',
        },
      },
    },

    agregar: {
      bl_tipo: {
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de sección',
        id: 'bl_tipo',
        name: 'bl_tipo',
        change_param: 'agregar',
        value: 0,
        messages: {
          error: '',
        },
      },
    },

    bloques: [],

    products_feed: [],

    alert: {
      open: false,
      type: 'error',
      message: '',
    },

    selects: {
      macro_pages: [
        {
          value: -1,
          label: 'Personalización',
        },
        {
          value: -3,
          label: 'Plantillas',
        },
        {
          value: -2,
          label: 'Secciones',
        },
        {
          value: -5,
          label: 'Productos',
        },
        {
          value: -6,
          label: 'Checkout',
        },
      ],
      templates: [
        {
          nombre: 'Aura',
          id: 1,
          imagen: `${Config.CLOUDFRONT_CDN_PROD}/panel/plantillas/aura/black.jpg`,
          secondary: false,
        },
        {
          nombre: 'Eris',
          id: 9,
          imagen: `${Config.CLOUDFRONT_CDN_PROD}/panel/plantillas/eris-theme.png`,
          secondary: false,
        },
        {
          nombre: 'Elara',
          id: 10,
          imagen: `${Config.CLOUDFRONT_CDN_PROD}/panel/plantillas/elara-theme.jpg`,
          secondary: false,
        },
        {
          nombre: 'Mimas',
          id: 8,
          imagen: `${Config.CLOUDFRONT_CDN_PROD}/panel/plantillas/mimas-theme.jpg`,
          secondary: true,
        },
        {
          nombre: 'Favourite',
          id: 2,
          imagen: `${Config.CLOUDFRONT_CDN_PROD}/panel/plantillas/favourite/black.png`,
          secondary: false,
        },
        {
          nombre: 'Classic',
          id: 3,
          imagen: `${Config.CLOUDFRONT_CDN_PROD}/panel/plantillas/classic/black.png`,
          secondary: true,
        },
        {
          nombre: 'Light',
          id: 4,
          imagen: `${Config.CLOUDFRONT_CDN_PROD}/panel/plantillas/light/black.png`,
          secondary: false,
        },
        {
          nombre: 'Elegant',
          id: 5,
          imagen: `${Config.CLOUDFRONT_CDN_PROD}/panel/plantillas/elegant/black.png`,
          secondary: true,
        },
        {
          nombre: 'Simple',
          id: 6,
          imagen: `${Config.CLOUDFRONT_CDN_PROD}/panel/plantillas/simple/black.png`,
          secondary: true,
        },
        {
          nombre: 'Integral',
          id: 7,
          imagen: `${Config.CLOUDFRONT_CDN_PROD}/panel/plantillas/integral/black.png`,
          secondary: true,
        },
      ],
      colores: [
        {
          id: 1,
          gama: 'Negro',
          primary: {
            background: '#000000',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#1c1c1c',
            text: '#1c1c1c',
            contrastText: '#ffffff',
          },
        },
        {
          id: 2,
          gama: 'Gris',
          primary: {
            background: '#f0f4f1',
            text: '#000000',
            contrastText: '#333333',
          },
          secondary: {
            background: '#f6f6f6',
            text: '#000000',
            contrastText: '#333333',
          },
        },
        {
          id: 3,
          gama: 'Rojo',
          primary: {
            background: '#ea3a3a',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#ed5758',
            text: '#000000',
            contrastText: '#ffffff',
          },
        },
        {
          id: 4,
          gama: 'Rosa pastel',
          primary: {
            background: '#ffcae6',
            text: '#000000',
            contrastText: '#333333',
          },
          secondary: {
            background: '#f3f3f3',
            text: '#000000',
            contrastText: '#333333',
          },
        },
        {
          id: 5,
          gama: 'Coral pastel',
          primary: {
            background: '#ffc0cb',
            text: '#000000',
            contrastText: '#333333',
          },
          secondary: {
            background: '#f3f3f3',
            text: '#000000',
            contrastText: '#333333',
          },
        },
        {
          id: 6,
          gama: 'Celeste pastel',
          primary: {
            background: '#e0ffff',
            text: '#000000',
            contrastText: '#333333',
          },
          secondary: {
            background: '#f3f3f3',
            text: '#000000',
            contrastText: '#333333',
          },
        },
        {
          id: 7,
          gama: 'Violeta pastel',
          primary: {
            background: '#e8d7ff',
            text: '#000000',
            contrastText: '#333333',
          },
          secondary: {
            background: '#f3f3f3',
            text: '#000000',
            contrastText: '#333333',
          },
        },
        {
          id: 8,
          gama: 'Verde pastel',
          primary: {
            background: '#ddffe3',
            text: '#000000',
            contrastText: '#333333',
          },
          secondary: {
            background: '#f3f3f3',
            text: '#000000',
            contrastText: '#333333',
          },
        },
        {
          id: 9,
          gama: 'Amarillo pastel',
          primary: {
            background: '#fcffcc',
            text: '#000000',
            contrastText: '#333333',
          },
          secondary: {
            background: '#f3f3f3',
            text: '#000000',
            contrastText: '#333333',
          },
        },
        {
          id: 10,
          gama: 'Naranja pastel',
          primary: {
            background: '#ffdcba',
            text: '#000000',
            contrastText: '#333333',
          },
          secondary: {
            background: '#f3f3f3',
            text: '#000000',
            contrastText: '#333333',
          },
        },
        {
          id: 11,
          gama: 'Azul pastel',
          primary: {
            background: '#cfdbff',
            text: '#000000',
            contrastText: '#333333',
          },
          secondary: {
            background: '#f3f3f3',
            text: '#000000',
            contrastText: '#333333',
          },
        },
        {
          id: 12,
          gama: 'Magenta',
          primary: {
            background: '#74113f',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#74113f',
            text: '#000000',
            contrastText: '#ffffff',
          },
        },
        {
          id: 13,
          gama: 'Verde',
          primary: {
            background: '#00ae66',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#17aa70',
            text: '#000000',
            contrastText: '#ffffff',
          },
        },
        {
          id: 14,
          gama: 'Celeste',
          primary: {
            background: '#6594ec',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#89cdfa',
            text: '#000000',
            contrastText: '#ffffff',
          },
        },
        {
          id: 15,
          gama: 'Naranja',
          primary: {
            background: '#ff7f00',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#fc9733',
            text: '#000000',
            contrastText: '#ffffff',
          },
        },
        {
          id: 16,
          gama: 'Salmón',
          primary: {
            background: '#fa8071',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#dd999a',
            text: '#000000',
            contrastText: '#ffffff',
          },
        },
        {
          id: 17,
          gama: 'Violeta',
          primary: {
            background: '#622582',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#6e748c',
            text: '#000000',
            contrastText: '#ffffff',
          },
        },
        {
          id: 18,
          gama: 'Amarillo',
          primary: {
            background: '#ffe164',
            text: '#000000',
            contrastText: '#000000',
          },
          secondary: {
            background: '#1c1c1c',
            text: '#000000',
            contrastText: '#ffffff',
          },
        },
        {
          id: 19,
          gama: 'Marrón',
          primary: {
            background: '#663200',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#c09a6b',
            text: '#000000',
            contrastText: '#ffffff',
          },
        },
        {
          id: 20,
          gama: 'Azul',
          primary: {
            background: '#446CB3',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#6f96db',
            text: '#000000',
            contrastText: '#ffffff',
          },
        },
        {
          id: 21,
          gama: 'Verde agua',
          primary: {
            background: '#47d6c4',
            text: '#000000',
            contrastText: '#ffffff',
          },
          secondary: {
            background: '#f3f3f3',
            text: '#000000',
            contrastText: '#333333',
          },
        },
      ],
      fonts: [
        {
          label: 'Alegreya',
          value: 1,
          font_family: "'Alegreya', serif",
          font_setting: 'Alegreya:wght@400;500;700',
        },
        {
          label: 'Asap Condensed',
          value: 2,
          font_family: "'Asap Condensed', sans-serif",
          font_setting: 'Asap+Condensed:wght@400;500;700',
        },
        {
          label: 'Baloo 2',
          value: 3,
          font_family: "'Baloo 2', cursive",
          font_setting: 'Baloo+2:wght@400;500;700',
        },
        {
          label: 'Balsamiq Sans',
          value: 4,
          font_family: "'Balsamiq Sans', cursive",
          font_setting: 'Balsamiq+Sans:wght@400;700',
        },
        {
          label: 'Bitter',
          value: 5,
          font_family: "'Bitter', serif",
          font_setting: 'Bitter:wght@400;700',
        },
        {
          label: 'Cairo',
          value: 6,
          font_family: "'Cairo', sans-serif",
          font_setting: 'Cairo:wght@300;400;700',
        },
        {
          label: 'Heebo',
          value: 7,
          font_family: "'Heebo', sans-serif",
          font_setting: 'Heebo:wght@300;400;500;700',
        },
        {
          label: 'Inconsolata',
          value: 8,
          font_family: "'Inconsolata', monospace",
          font_setting: 'Inconsolata:wght@300;400;500;700',
        },
        {
          label: 'Josefin Sans',
          value: 9,
          font_family: "'Josefin Sans', sans-serif",
          font_setting: 'Josefin+Sans:wght@300;400;500;700',
        },
        {
          label: 'Jost',
          value: 10,
          font_family: "'Jost', sans-serif",
          font_setting: 'Jost:wght@300;400;500;700',
        },
        {
          label: 'Kanit',
          value: 11,
          font_family: "'Kanit', sans-serif",
          font_setting: 'Kanit:wght@300;400;500;700',
        },
        {
          label: 'Lato',
          value: 12,
          font_family: "'Lato', sans-serif",
          font_setting: 'Lato:wght@300;400;700',
        },
        {
          label: 'Libre Baskerville',
          value: 13,
          font_family: "'Libre Baskerville', serif",
          font_setting: 'Libre+Baskerville:wght@400;700',
        },
        {
          label: 'Libre Caslon Text',
          value: 14,
          font_family: "'Libre Caslon Text', serif",
          font_setting: 'Libre+Caslon+Text:wght@400;700',
        },
        {
          label: 'Literata',
          value: 15,
          font_family: "'Literata', serif",
          font_setting: 'Literata:wght@400;500;700',
        },
        {
          label: 'Lora',
          value: 16,
          font_family: "'Lora', serif",
          font_setting: 'Lora:wght@400;500;700',
        },
        {
          label: 'Merriweather',
          value: 17,
          font_family: "'Merriweather', serif",
          font_setting: 'Merriweather:wght@300;400;700',
        },
        {
          label: 'Montserrat',
          value: 18,
          font_family: "'Montserrat', sans-serif",
          font_setting: 'Montserrat:wght@300;400;500;700',
        },
        {
          label: 'Nunito',
          value: 19,
          font_family: "'Nunito', sans-serif",
          font_setting: 'Nunito:wght@300;400;700',
        },
        {
          label: 'Open Sans',
          value: 20,
          font_family: "'Open Sans', sans-serif",
          font_setting: 'Open+Sans:wght@300;400;700',
        },
        {
          label: 'Oswald',
          value: 21,
          font_family: "'Oswald', sans-serif",
          font_setting: 'Oswald:wght@300;400;500;700',
        },
        {
          label: 'Playfair Display',
          value: 22,
          font_family: "'Playfair Display', serif",
          font_setting: 'Playfair+Display:wght@400;500;700',
        },
        {
          label: 'Poppins',
          value: 23,
          font_family: "'Poppins', sans-serif",
          font_setting: 'Poppins:wght@300;400;500;700',
        },
        {
          label: 'Raleway',
          value: 24,
          font_family: "'Raleway', sans-serif",
          font_setting: 'Raleway:wght@300;400;500;700',
        },
        {
          label: 'Roboto',
          value: 25,
          font_family: "'Roboto', sans-serif",
          font_setting: 'Roboto:wght@300;400;500;700',
        },
        {
          label: 'Rubik',
          value: 26,
          font_family: "'Rubik', sans-serif",
          font_setting: 'Rubik:wght@300;400;500;700',
        },
        {
          label: 'Teko',
          value: 27,
          font_family: "'Teko', sans-serif",
          font_setting: 'Teko:wght@300;400;500;700',
        },
        {
          label: 'Titillium Web',
          value: 28,
          font_family: "'Titillium Web', sans-serif",
          font_setting: 'Titillium+Web:wght@300;400;700',
        },
        {
          label: 'Trirong',
          value: 29,
          font_family: "'Trirong', serif",
          font_setting: 'Trirong:wght@300;400;500;700',
        },
        {
          label: 'Work Sans',
          value: 30,
          font_family: "'Work Sans', sans-serif",
          font_setting: 'Work+Sans:wght@300;400;500;700',
        },
      ],
      products_feed_order: [
        {
          value: 0,
          label: 'Más nuevo a más viejo',
        },
        {
          value: 1,
          label: 'Más viejo a más nuevo',
        },
        {
          value: 2,
          label: 'Precio menor a mayor',
        },
        {
          value: 3,
          label: 'Precio mayor a menor',
        },
        {
          value: 4,
          label: 'Orden establecido',
        },
      ],
      products_feed_product_subtext_type: [
        {
          value: 0,
          label: 'Mensaje con texto',
        },
        {
          value: 1,
          label: 'Mostrar cuotas sin interés ofrecidas',
        },
      ],
      products_feed_product_subtext_cuotas: [
        {
          value: 3,
          label: '3 cuotas sin interés',
        },
        {
          value: 6,
          label: '6 cuotas sin interés',
        },
        {
          value: 9,
          label: '9 cuotas sin interés',
        },
        {
          value: 12,
          label: '12 cuotas sin interés',
        },
        {
          value: 18,
          label: '18 cuotas sin interés',
        },
      ],
      products_detail_photo_display: [
        {
          value: 0,
          label: 'Carrousel',
        },
        {
          value: 1,
          label: 'Grilla con miniaturas',
        },
      ],
      other_border_radius: [
        {
          value: 0,
          label: 'Recto',
        },
        {
          value: 3,
          label: 'Redondeado pequeño',
        },
        {
          value: 6,
          label: 'Redondeado normal',
        },
        {
          value: 10,
          label: 'Redondeado grande',
        },
        {
          value: 20,
          label: 'Redondeado muy grande',
        },
      ],
      bloques: [
        {
          value: 0,
          label: 'Seleccionar una sección',
        },
        {
          value: 1,
          label: 'Carrousel de banners',
        },
        {
          value: 2,
          label: 'Feed principal de productos',
        },
        {
          value: 3,
          label: 'Conjunto de productos',
        },
        {
          value: 4,
          label: 'Lista de categorías',
        },
        {
          value: 5,
          label: 'Galería',
        },
        {
          value: 6,
          label: 'Imágenes con link',
        },
        {
          value: 11,
          label: 'Grillas de imágenes con link',
        },
        {
          value: 7,
          label: 'Texto',
        },
        {
          value: 8,
          label: 'Información de compra',
        },
        {
          value: 9,
          label: 'Imagen con texto y botón',
        },
        {
          value: 10,
          label: 'Código HTML (avanzado)',
        },
      ],
      bloque_1: {
        width: [
          {
            value: 0,
            label: 'Toda la pantalla',
          },
          {
            value: 1,
            label: 'Centrado',
          },
        ],
      },
      bloque_4: {
        type: [
          {
            value: 0,
            label: 'Categorías raíz',
          },
          {
            value: 1,
            label: 'Todas las categorías',
          },
        ],
        mode: [
          {
            value: 0,
            label: 'Mixto (lista y slider)',
          },
          {
            value: 1,
            label: 'Sólo slider',
          },
        ],
      },
      bloque_8: {
        icons: [
          {
            value: 0,
            label: 'Envío',
          },
          {
            value: 1,
            label: 'Pago online',
          },
          {
            value: 2,
            label: 'Pago seguro',
          },
          {
            value: 3,
            label: 'Whatsapp',
          },
          {
            value: 4,
            label: 'Pago efectivo',
          },
          {
            value: 5,
            label: 'Compra en casa',
          },
          {
            value: 6,
            label: 'Descuento / Oferta',
          },
          {
            value: 7,
            label: 'Devolución',
          },
          {
            value: 8,
            label: 'Dirección / Punto de entrega',
          },
          {
            value: 9,
            label: 'Email',
          },
          {
            value: 10,
            label: 'Teléfono',
          },
          {
            value: 11,
            label: 'Pago transferencia',
          },
        ],
      },
      bloque_9: {
        image_size: [
          {
            value: 0,
            label: '1/4',
          },
          {
            value: 1,
            label: '1/3',
          },
          {
            value: 2,
            label: '1/2',
          },
        ],
        image_position: [
          {
            value: 0,
            label: 'Izquierda',
          },
          {
            value: 1,
            label: 'Derecha',
          },
        ],
      },
      bloque_alineacion: [
        {
          value: 0,
          label: 'Centrado',
        },
        {
          value: 1,
          label: 'Izquierda',
        },
        {
          value: 2,
          label: 'Derecha',
        },
        {
          value: 3,
          label: 'Justificado',
        },
      ],
      bloque_espaciado: [
        {
          value: 0,
          label: 'Muy grande',
        },
        {
          value: 1,
          label: 'Grande',
        },
        {
          value: 2,
          label: 'Normal',
        },
        {
          value: 3,
          label: 'Pequeño',
        },
        {
          value: 4,
          label: 'Muy pequeño',
        },
        {
          value: 5,
          label: 'Sin espaciado',
        },
      ],
      bloque_gap: [
        {
          value: 0,
          label: 'Grande',
        },
        {
          value: 1,
          label: 'Normal',
        },
        {
          value: 2,
          label: 'Pequeño',
        },
        {
          value: 3,
          label: 'Sin espaciado',
        },
      ],
      bloque_si_no: [
        {
          value: 1,
          label: 'Si',
        },
        {
          value: 0,
          label: 'No',
        },
      ],
      bloque_row: [
        {
          value: 2,
          label: '2 por fila',
        },
        {
          value: 3,
          label: '3 por fila',
        },
        {
          value: 4,
          label: '4 por fila',
        },
      ],
      bloque_row_mobile: [
        {
          value: 1,
          label: '1 por fila',
        },
        {
          value: 2,
          label: '2 por fila',
        },
      ],
      bloque_display: [
        {
          value: 1,
          label: 'Carrousel',
        },
        {
          value: 2,
          label: 'Grilla',
        },
        {
          value: 3,
          label: 'Grilla Masonry',
        },
      ],
    },

    preview_width: is_mobile ? 'mobile' : 'desktop',
    preview_html: '',
    idCountry: 'ARG',
  };

  componentDidMount() {
    async.parallel(
      [
        (callback) => {
          this.props.tiendaObtener((err, resp) => {
            if (err) {
              this.setState(
                {
                  loading_tienda: false,
                  error: true,
                },
                () => callback(new Error())
              );
            } else {
              let tienda = this.props.tienda;
              let t_template_data = tienda.t_template_data;
              let template_id = tienda.Templates_idTemplates;
              let t_logo = tienda.t_logo;
              let t_favicon = tienda.t_favicon;

              let settings = {
                ...this.state.settings,
                template_id: {
                  ...this.state.settings.template_id,
                  value: template_id,
                },
                t_logo: {
                  ...this.state.settings.t_logo,
                  files: t_logo
                    ? [
                        {
                          link: t_logo,
                          url: '',
                          title: '',
                          button: '',
                        },
                      ]
                    : [],
                },
                t_favicon: {
                  ...this.state.settings.t_favicon,
                  files: t_favicon
                    ? [
                        {
                          link: t_favicon,
                          url: '',
                          title: '',
                          button: '',
                        },
                      ]
                    : [],
                },
                color: {
                  ...this.state.settings.color,
                  value: t_template_data.id,
                },
                primary_background: {
                  ...this.state.settings.primary_background,
                  auth: true,
                  value: t_template_data.primary.background,
                },
                primary_text: {
                  ...this.state.settings.primary_text,
                  auth: true,
                  value: t_template_data.primary.text,
                },
                primary_contrastText: {
                  ...this.state.settings.primary_contrastText,
                  auth: true,
                  value: t_template_data.primary.contrastText,
                },
                secondary_background: {
                  ...this.state.settings.secondary_background,
                  auth: true,
                  value: t_template_data.secondary.background,
                },
                secondary_text: {
                  ...this.state.settings.secondary_text,
                  auth: true,
                  value: t_template_data.secondary.text,
                },
                secondary_contrastText: {
                  ...this.state.settings.secondary_contrastText,
                  auth: true,
                  value: t_template_data.secondary.contrastText,
                },
                body_background: {
                  ...this.state.settings.body_background,
                  auth: true,
                  value: t_template_data.body.background,
                },
                font_title: {
                  ...this.state.settings.font_title,
                  value: t_template_data.fonts.title,
                },
                font_text: {
                  ...this.state.settings.font_text,
                  value: t_template_data.fonts.text,
                },
                message_text: {
                  ...this.state.settings.message_text,
                  value: t_template_data.message.text,
                },
                message_link: {
                  ...this.state.settings.message_link,
                  value: t_template_data.message.link,
                },
                css: {
                  ...this.state.settings.css,
                  value: t_template_data.css,
                },
                products_feed_order: {
                  ...this.state.settings.products_feed_order,
                  value: t_template_data.products_feed.order,
                },
                products_feed_filter: {
                  ...this.state.settings.products_feed_filter,
                  value: t_template_data.products_feed.filter,
                },
                products_feed_product_subtext_type: {
                  ...this.state.settings.products_feed_product_subtext_type,
                  value: t_template_data.products_feed.product_subtext_type,
                },
                products_feed_product_subtext: {
                  ...this.state.settings.products_feed_product_subtext,
                  value: t_template_data.products_feed.product_subtext,
                },
                products_feed_product_subtext_cuotas: {
                  ...this.state.settings.products_feed_product_subtext_cuotas,
                  value: t_template_data.products_feed.product_subtext_cuotas,
                },
                products_detail_photo_display: {
                  ...this.state.settings.products_detail_photo_display,
                  value: t_template_data.products_detail.photo_display,
                },
                products_detail_photo_zoom: {
                  ...this.state.settings.products_detail_photo_zoom,
                  value: t_template_data.products_detail.photo_zoom,
                },
                checkout_comments: {
                  ...this.state.settings.checkout_comments,
                  value: t_template_data.checkout.comments,
                },
                checkout_short_billing_form: {
                  ...this.state.settings.checkout_short_billing_form,
                  value: t_template_data.checkout.short_billing_form,
                },
                checkout_step_review: {
                  ...this.state.settings.checkout_step_review,
                  value: t_template_data.checkout.step_review,
                },
                other_border_radius: {
                  ...this.state.settings.other_border_radius,
                  value: t_template_data.other.border_radius,
                },
                other_menu_label_products: {
                  ...this.state.settings.other_menu_label_products,
                  value: t_template_data.other.menu_label_products,
                },
                other_menu_label_wholesaler: {
                  ...this.state.settings.other_menu_label_wholesaler,
                  value: t_template_data.other.menu_label_wholesaler,
                },
              };

              this.setState(
                {
                  drawer_title: '',
                  settings: settings,
                  loading_tienda: false,
                  error: this.state.error || false,
                  idCountry: tienda?.Country_idCountry || 'ARG',
                },
                () => callback()
              );
            }
          });
        },
        (callback) => {
          this.props.bloqueObtenerBloques((err, resp) => {
            if (err) {
              this.setState(
                {
                  loading_bloques: false,
                  error: true,
                },
                () => callback(new Error())
              );
            } else {
              //mapeo los bloques
              let bloques = this.props.bloques.map((bloque) => {
                return this.loadBlock(bloque.bl_tipo, true, bloque);
              });

              this.setState(
                {
                  loading_bloques: false,
                  error: this.state.error || false,
                  bloques: bloques,
                },
                () => callback()
              );
            }
          });
        },
        (callback) => {
          this.props.productoObtenerProductosOrdenados((err, resp) => {
            if (err) {
              this.setState(
                {
                  loading_productos_ord: false,
                  error: true,
                },
                () => callback(new Error())
              );
            } else {
              this.setState(
                {
                  loading_productos_ord: false,
                  error: this.state.error || false,
                  products_feed: resp.data,
                },
                () => callback()
              );
            }
          });
        },
      ],
      (err) => {
        if (err) {
          this.setState({
            error: true,
            loading_preview_html: false,
          });
        } else {
          this.handleUpdatePreview();
        }
      }
    );
  }

  openCloseDrawer = () =>
    this.setState({ drawer_status: !this.state.drawer_status });

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  handleChangePage = (e, new_page) => {
    if (e) e.preventDefault();

    if (!this.state.uploading_images) {
      if (this.state.active_page >= 0) {
        let bloque = this.state.bloques[this.state.active_page];

        if (bloque.bl_tipo.value === 10) {
          if (
            !Validate.contiene_substring(bloque.bl_data.code.value, 'http://')
          ) {
            if (
              !Validate.contiene_substring(bloque.bl_data.code.value, '<?') &&
              !Validate.contiene_substring(bloque.bl_data.code.value, '?>')
            ) {
              this.setState({
                active_page: Validate.trim_int(new_page),
                back_page: new_page >= 0 ? -2 : -1,
                drawer_title: '',
              });
            } else {
              this.setState({
                alert: {
                  ...this.state.alert,
                  open: true,
                  message: 'No es posible escribir código PHP',
                  type: 'error',
                },
              });
            }
          } else {
            this.setState({
              alert: {
                ...this.state.alert,
                open: true,
                message:
                  'Los links e imágenes ingresados en el código HTML deben ser usando HTTPS',
                type: 'error',
              },
            });
          }
        } else if (
          Validate.validar_formulario(bloque) &&
          Validate.validar_formulario(bloque.bl_data)
        ) {
          let flag = true;
          let message = '';

          if ('image' in bloque.bl_data) {
            if (!bloque.bl_data.image.auth) {
              flag = false;
              message = 'Debes cargar la imagen';
            }
          } else if ('items' in bloque.bl_data) {
            if (!bloque.bl_data.items.auth) {
              flag = false;
              message = 'Debes cargar las imágenes';
            }
          }

          if (flag) {
            this.setState({
              active_page: Validate.trim_int(new_page),
              back_page: new_page >= 0 ? -2 : -1,
              drawer_title: '',
            });
          } else {
            this.setState({
              alert: {
                open: true,
                type: 'error',
                message: message,
              },
            });
          }
        } else {
          this.setState({
            alert: {
              ...this.state.alert,
              open: true,
              message: 'Por favor, revisa los campos',
              type: 'error',
            },
          });
        }
      } else {
        this.setState({
          active_page: Validate.trim_int(new_page),
          back_page: new_page >= 0 ? -2 : -1,
          drawer_title: '',
        });
      }
    } else {
      this.setState({
        alert: {
          open: true,
          type: 'error',
          message: 'Debes esperar a que se terminen de cargar las imágenes',
        },
      });
    }
  };

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      {
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: input,
            },
          });
        });
      }
    );
  };

  handleChangeBlock = (e, blur, change_param) => {
    let value = e.target.value;
    let name = e.target.name;

    let bloques = this.state.bloques.map((bloque) => {
      if (bloque.bl_nombre.change_param === change_param) {
        if (Validate.in_array(name, ['bl_nombre'])) {
          let input = bloque[name];
          input.value = value;
          input = Validate.validate_input_sync(input, blur);
          return {
            ...bloque,
            [name]: input,
          };
        } else {
          let nameArr = name.split('bldata_');
          name = nameArr[1];

          let input = bloque.bl_data[name];
          input.value = value;
          input = Validate.validate_input_sync(input, blur);

          return {
            ...bloque,
            bl_data: {
              ...bloque.bl_data,
              [name]: input,
            },
          };
        }
      } else {
        return bloque;
      }
    });

    let drawer_title = name === 'bl_nombre' ? value : this.state.drawer_title;

    this.setState({
      drawer_title: drawer_title,
      bloques: bloques,
    });
  };

  handleChangeProductsBlock = (change_param, products) => {
    let bloques = this.state.bloques.map((bloque) => {
      if (bloque.bl_nombre.change_param === change_param) {
        return {
          ...bloque,
          bl_data: {
            ...bloque.bl_data,
            products: products,
          },
        };
      } else {
        return bloque;
      }
    });

    this.setState({
      bloques: bloques,
    });
  };

  handleChangeSelect = (e, aditional) => {
    let name = e.target.name;
    let value = Validate.trim_int(e.target.value);

    switch (name) {
      case 'color':
        let color = this.state.selects.colores.filter(
          (color) => color.id === value
        )[0];

        this.setState({
          settings: {
            ...this.state.settings,
            color: {
              ...this.state.settings.color,
              value: value,
            },
            primary_background: {
              ...this.state.settings.primary_background,
              auth: true,
              error: false,
              value: color.primary.background,
            },
            primary_text: {
              ...this.state.settings.primary_text,
              auth: true,
              error: false,
              value: color.primary.text,
            },
            primary_contrastText: {
              ...this.state.settings.primary_contrastText,
              auth: true,
              error: false,
              value: color.primary.contrastText,
            },
            secondary_background: {
              ...this.state.settings.secondary_background,
              auth: true,
              error: false,
              value: color.secondary.background,
            },
            secondary_text: {
              ...this.state.settings.secondary_text,
              auth: true,
              error: false,
              value: color.secondary.text,
            },
            secondary_contrastText: {
              ...this.state.settings.secondary_contrastText,
              auth: true,
              error: false,
              value: color.secondary.contrastText,
            },
          },
        });

        break;

      default:
        this.setState({
          [aditional]: {
            ...this.state[aditional],
            [name]: {
              ...this.state[aditional][name],
              value: value,
            },
          },
        });
        break;
    }
  };

  handleChangeSelectBlock = (e, change_param) => {
    let nameArr = e.target.name.split('bldata_');
    let name = nameArr[1];
    let value = Validate.trim_int(e.target.value);

    let bloques = this.state.bloques.map((bloque) => {
      if (bloque.bl_nombre.change_param === change_param) {
        return {
          ...bloque,
          bl_data: {
            ...bloque.bl_data,
            [name]: {
              ...bloque.bl_data[name],
              value: value,
            },
          },
        };
      } else {
        return bloque;
      }
    });

    this.setState({
      bloques: bloques,
    });
  };

  handleChangeTemplate = (e, template) => {
    e.preventDefault();

    this.setState({
      drawer_title: '',
      settings: {
        ...this.state.settings,
        template_id: {
          ...this.state.settings.template_id,
          value: template.id,
        },
      },
    });
  };

  handleChangeFileSettings = (data) => {
    this.setState({
      uploading_images: data.uploading,
      settings: {
        ...this.state.settings,
        [data.id]: data,
      },
    });
  };

  handleChangeFileBlock = (data) => {
    let nameArr = data.id.split('_');
    let element = nameArr[nameArr.length - 2];
    let change_param = nameArr[nameArr.length - 1];

    let bloques = this.state.bloques.map((bloque) => {
      if (bloque.bl_nombre.change_param === change_param) {
        return {
          ...bloque,
          bl_data: {
            ...bloque.bl_data,
            [element]: data,
          },
        };
      } else {
        return bloque;
      }
    });

    this.setState({
      uploading_images: data.uploading,
      bloques: bloques,
    });
  };

  handleOrdenarBlocks = (bloques) => {
    this.setState({
      bloques: bloques,
    });
  };

  handleDisableEnableBlock = (idx) => {
    let bloques = this.state.bloques.map((bloque, index) => {
      return index === idx
        ? {
            ...bloque,
            bl_activo: {
              ...bloque.activo,
              value: bloque.bl_activo.value ? 0 : 1,
            },
          }
        : bloque;
    });
    this.setState({ bloques: bloques });
  };

  handleAgregarBlock = (e) => {
    e.preventDefault();

    if (this.state.bloques.length < 12) {
      let bl_tipo = this.state.agregar.bl_tipo.value;
      let flag = true;

      if (bl_tipo === 2) {
        //debo buscar que no haya otro feed de productos
        flag = this.state.bloques.filter((bloque) => bloque.bl_tipo.value === 2)
          .length
          ? false
          : true;
      }

      if (flag) {
        let nuevo_bloque = this.loadBlock(bl_tipo);
        let nuevo_bloque_idx = this.state.bloques.length;

        this.setState(
          {
            bloques: [...this.state.bloques, nuevo_bloque],
            agregar: {
              ...this.state.agregar,
              bl_tipo: {
                ...this.state.agregar.bl_tipo,
                value: 0,
              },
            },
          },
          () => this.handleChangePage(null, nuevo_bloque_idx)
        );
      } else {
        this.setState({
          alert: {
            open: true,
            type: 'error',
            message:
              'No se puede tener mas de un feed principal de productos, agregá un conjunto de productos en su lugar',
          },
        });
      }
    } else {
      this.setState({
        alert: {
          open: true,
          type: 'error',
          message: 'La cantidad máxima de bloques es 12',
        },
      });
    }
  };

  handleChangeGridDesign = (type, change_param, max_files) => {
    let bloques = this.state.bloques.map((bloque) => {
      if (bloque.bl_nombre.change_param === change_param) {
        let input = {
          ...bloque.bl_data.type,
          value: type,
        };

        let files = bloque.bl_data.items.files;

        if (files.length > max_files) {
          //remuevo los sobrantes
          files = files.slice(0, max_files);
        } else {
          //agrego imagenes faltantes
          let faltantes = max_files - files.length;
          for (let i = 0; i < faltantes; i++) {
            files = [
              ...files,
              {
                link: 'block-preview-empty.jpeg',
                url: '',
                title: '',
                button: '',
              },
            ];
          }
        }

        return {
          ...bloque,
          bl_data: {
            ...bloque.bl_data,
            type: input,
            items: {
              ...bloque.bl_data.items,
              files: files,
            },
          },
        };
      } else {
        return bloque;
      }
    });

    this.setState({
      bloques: bloques,
    });
  };

  handleBorrarBlock = (e, idx) => {
    e.preventDefault();

    let bloques = this.state.bloques.filter((bloque, index) => idx !== index);

    this.setState({
      bloques: bloques,
    });
  };

  handleChangeFeedProducts = (products_feed) => {
    this.setState({
      products_feed: products_feed,
    });
  };

  handleChangePreviewWidth = (width) => {
    this.setState({
      preview_width: width,
    });
  };

  handleUpdatePreview = () => {
    if (!this.state.uploading_images) {
      this.setState(
        {
          loading_preview_html: true,
          spinner_message: 'Cargando previsualización...',
        },
        () => {
          let { settings, bloques } = this.state;

          if (Validate.validar_formulario(settings)) {
            let flag = true;
            let bloques_error = [];
            bloques.forEach((bloque, index) => {
              if (
                !(
                  Validate.validar_formulario(bloque) &&
                  Validate.validar_formulario(bloque.bl_data)
                )
              ) {
                flag = false;
                bloques_error = [
                  ...bloques_error,
                  bloque.bl_nombre.value
                    ? bloque.bl_nombre.value
                    : 'Sección ' + (index + 1),
                ];
              } else {
                if ('image' in bloque.bl_data) {
                  if (!bloque.bl_data.image.auth) {
                    flag = false;
                    bloques_error = [
                      ...bloques_error,
                      bloque.bl_nombre.value
                        ? bloque.bl_nombre.value
                        : 'Sección ' + (index + 1),
                    ];
                  }
                } else if ('items' in bloque.bl_data) {
                  if (!bloque.bl_data.items.auth) {
                    flag = false;
                    bloques_error = [
                      ...bloques_error,
                      bloque.bl_nombre.value
                        ? bloque.bl_nombre.value
                        : 'Sección ' + (index + 1),
                    ];
                  } else {
                    if (bloque.bl_tipo.value === 11) {
                      let type = bloque.bl_data.type.value;
                      let cantidad_imagenes = bloque.bl_data.items.files.length;
                      if (
                        (type === 0 && cantidad_imagenes !== 2) ||
                        (Validate.in_array(type, [1, 2]) &&
                          cantidad_imagenes !== 3) ||
                        (Validate.in_array(type, [3, 4]) &&
                          cantidad_imagenes !== 4) ||
                        (type === 5 && cantidad_imagenes !== 5)
                      ) {
                        flag = false;
                        bloques_error = [
                          ...bloques_error,
                          bloque.bl_nombre.value
                            ? bloque.bl_nombre.value +
                              ' (debes cargar todas las imágenes)'
                            : 'Sección ' +
                              (index + 1) +
                              ' (debes cargar todas las imágenes)',
                        ];
                      }
                    }
                  }
                } else if ('products' in bloque.bl_data) {
                  if (bloque.bl_data.products.length === 0) {
                    flag = false;
                    bloques_error = [
                      ...bloques_error,
                      bloque.bl_nombre.value
                        ? bloque.bl_nombre.value
                        : 'Sección ' + (index + 1),
                    ];
                  }
                }
              }
            });

            if (flag) {
              let body = this.obtenerObjetoBody();

              this.props.tiendaGenerarPrevisualizacionTemplate(
                body,
                (err, resp) => {
                  createAmplitudeEventWithDevice(
                    design_amplitude_events.diseño_selecciona_ver_cambios
                      .event_name
                  );

                  if (err) {
                    this.setState({
                      error: true,
                      loading_preview_html: false,
                    });
                  } else {
                    this.setState(
                      {
                        loading_preview_html: false,
                        preview_html: resp.data.html,
                      },
                      () => {
                        this.forceUpdate();
                      }
                    );
                  }
                }
              );
            } else {
              bloques = bloques.map((bloque) => {
                bloque =
                  Validate.validar_formulario_actualizar_obj_sync(bloque);
                let bl_data = Validate.validar_formulario_actualizar_obj_sync(
                  bloque.bl_data
                );
                return {
                  ...bloque,
                  bl_data: bl_data,
                };
              });

              let message =
                bloques_error.length >= 2
                  ? 'Por favor, verificar los campos de las secciones: ' +
                    bloques_error.join(', ')
                  : 'Por favor, verificar los campos de la sección "' +
                    bloques_error[0] +
                    '"';

              this.setState({
                loading_preview_html: false,
                bloques: bloques,
                alert: {
                  open: true,
                  type: 'error',
                  message: message,
                },
              });
            }
          } else {
            settings =
              Validate.validar_formulario_actualizar_obj_sync(settings);
            this.setState({
              loading_preview_html: false,
              settings: settings,
              alert: {
                open: true,
                type: 'error',
                message: 'Por favor, verificar los campos señalados con error.',
              },
            });
          }
        }
      );
    } else {
      this.setState({
        alert: {
          open: true,
          type: 'error',
          message: 'Debes esperar a que se carguen las imágenes',
        },
      });
    }
  };

  loadBlock = (bl_tipo, fill = false, bloque = null) => {
    let change_param = uuid().split('-')[0];

    let bloque_obj = {
      idBloques: {
        validate: false,
        value: fill ? bloque.idBloques : 0,
      },
      bl_activo: {
        validate: false,
        value: fill ? bloque.bl_activo : 1,
      },
      bl_tipo: {
        validate: false,
        value: bl_tipo,
      },
      bl_nombre: {
        auth: true,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nombre de la sección',
        id: '',
        name: 'bl_nombre',
        change_param: change_param,
        regex: Regex.STRING_GENERICO_2_20,
        value: fill
          ? bloque.bl_nombre
          : 'Sección ' + (this.state.bloques.length + 1),
        default_value: '',
        placeholder: '',
        messages: {
          help: '',
          error: 'Por favor, ingrese un nombre de sección válido',
          error_extra: RegexExtra.STRING_GENERICO_2_20,
        },
      },
      bl_data: {},
    };

    switch (bl_tipo) {
      //NOMBRE: Carrousel de banners
      case 1:
        let banners_items = fill
          ? bloque.bl_data.items
          : [
              {
                link: 'block-preview-banner.jpg',
                url: '',
                title: '',
                button: '',
              },
            ];

        bloque_obj.bl_data = {
          width: {
            validate: false,
            required: true,
            error: false,
            label: 'Ancho',
            id: '',
            name: 'bldata_width',
            change_param: change_param,
            value: fill ? bloque.bl_data.width : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          autoplay: {
            validate: false,
            required: true,
            error: false,
            label: 'Autoplay',
            id: '',
            name: 'bldata_autoplay',
            change_param: change_param,
            value: fill ? bloque.bl_data.autoplay : 1,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          seconds: {
            auth: true,
            validate: true,
            required: true,
            disabled: false,
            error: false,
            type: 'number',
            label: 'Tiempo de cambio (segundos)',
            id: '',
            name: 'bldata_seconds',
            change_param: change_param,
            regex: Regex.NUMERO_ENTERO_POSITIVO,
            value: fill ? bloque.bl_data.seconds : '5',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.NUMERO_ENTERO_POSITIVO,
              error: 'Por favor, ingrese un tiempo válido',
            },
          },
          items: {
            validate: false,
            auth: banners_items.length ? true : false,
            uploading: false,
            required: true,
            label: '',
            id: 'bldata_items_' + change_param,
            sublabels: [''],
            files: banners_items,
            messages: {
              help: '',
              error: '',
            },
          },
        };

        break;

      //NOMBRE: Products feed [UNIQUE]
      case 2:
        bloque_obj.bl_data = {
          title: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Título',
            id: '',
            name: 'bldata_title',
            change_param: change_param,
            regex: Regex.STRING_GENERICO_2_90,
            value: fill ? bloque.bl_data.title : 'Título de la sección',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.STRING_GENERICO_2_90,
              error: 'Por favor, ingrese un título válido',
            },
          },
          title_align: {
            validate: false,
            required: true,
            error: false,
            label: 'Alineación del título',
            id: '',
            name: 'bldata_title_align',
            change_param: change_param,
            value: fill ? bloque.bl_data.title_align : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          text: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Texto',
            id: '',
            name: 'bldata_text',
            change_param: change_param,
            regex: Regex.DESCRIPCION_20_5000,
            value: fill ? bloque.bl_data.text : '',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.DESCRIPCION_20_5000,
              error: 'Por favor, ingrese un texto válido',
            },
          },
          text_align: {
            validate: false,
            required: true,
            error: false,
            label: 'Alineación del texto',
            id: '',
            name: 'bldata_text_align',
            change_param: change_param,
            value: fill ? bloque.bl_data.text_align : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          background: {
            auth: true,
            validate: true,
            required: true,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Color de fondo',
            id: '',
            name: 'bldata_background',
            change_param: change_param,
            regex: Regex.COLOR,
            value: fill ? bloque.bl_data.background : '#ffffff',
            default_value: '',
            placeholder: '#000000',
            messages: {
              error_extra: RegexExtra.COLOR,
              error: '',
            },
          },
          section_size: {
            validate: false,
            required: true,
            error: false,
            label: 'Espaciado de bloque',
            id: '',
            name: 'bldata_section_size',
            change_param: change_param,
            value: fill ? bloque.bl_data.section_size : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          show_more_btn: {
            auth: true,
            validate: true,
            required: true,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Botón',
            id: '',
            name: 'bldata_show_more_btn',
            change_param: change_param,
            regex: Regex.STRING_GENERICO_2_45,
            value: fill ? bloque.bl_data.show_more_btn : 'Ver más',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.STRING_GENERICO_2_45,
              error: 'Por favor, ingrese un valor del botón válido',
            },
          },
        };
        break;

      //NOMBRE: Products set
      case 3:
        bloque_obj.bl_data = {
          type: {
            validate: false,
            required: true,
            error: false,
            label: 'Tipo de visualización',
            id: '',
            name: 'bldata_type',
            change_param: change_param,
            value: fill ? bloque.bl_data.type : 2,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          title: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Título',
            id: '',
            name: 'bldata_title',
            change_param: change_param,
            regex: Regex.STRING_GENERICO_2_90,
            value: fill ? bloque.bl_data.title : '',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.STRING_GENERICO_2_90,
              error: 'Por favor, ingrese un título válido',
            },
          },
          title_align: {
            validate: false,
            required: true,
            error: false,
            label: 'Alineación del título',
            id: '',
            name: 'bldata_title_align',
            change_param: change_param,
            value: fill ? bloque.bl_data.title_align : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          text: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Texto',
            id: '',
            name: 'bldata_text',
            change_param: change_param,
            regex: Regex.DESCRIPCION_20_5000,
            value: fill ? bloque.bl_data.text : '',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.DESCRIPCION_20_5000,
              error: 'Por favor, ingrese un texto válido',
            },
          },
          text_align: {
            validate: false,
            required: true,
            error: false,
            label: 'Alineación del texto',
            id: '',
            name: 'bldata_text_align',
            change_param: change_param,
            value: fill ? bloque.bl_data.text_align : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          per_row: {
            validate: false,
            required: true,
            error: false,
            label: 'Productos por fila',
            id: '',
            name: 'bldata_per_row',
            change_param: change_param,
            value: fill ? bloque.bl_data.per_row : 3,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          per_row_mobile: {
            validate: false,
            required: true,
            error: false,
            label: 'Productos por fila (celulares)',
            id: '',
            name: 'bldata_per_row_mobile',
            change_param: change_param,
            value: fill ? bloque.bl_data.per_row_mobile : 2,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          background: {
            auth: true,
            validate: true,
            required: true,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Color de fondo',
            id: '',
            name: 'bldata_background',
            change_param: change_param,
            regex: Regex.COLOR,
            value: fill ? bloque.bl_data.background : '#ffffff',
            default_value: '',
            placeholder: '#000000',
            messages: {
              error_extra: RegexExtra.COLOR,
              error: '',
            },
          },
          section_size: {
            validate: false,
            required: true,
            error: false,
            label: 'Espaciado de bloque',
            id: '',
            name: 'bldata_section_size',
            change_param: change_param,
            value: fill ? bloque.bl_data.section_size : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          products: fill ? bloque.bl_data.products : [],
        };
        break;

      //NOMBRE: Lista de categorías
      case 4:
        bloque_obj.bl_data = {
          title: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Título',
            id: '',
            name: 'bldata_title',
            change_param: change_param,
            regex: Regex.STRING_GENERICO_2_90,
            value: fill ? bloque.bl_data.title : 'PRODUCTOS',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.STRING_GENERICO_2_90,
              error: 'Por favor, ingrese un título válido',
            },
          },
          type: {
            validate: false,
            required: true,
            error: false,
            label: 'Mostrar',
            id: '',
            name: 'bldata_type',
            change_param: change_param,
            value: fill ? bloque.bl_data.type : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          mode: {
            validate: false,
            required: true,
            error: false,
            label: 'Modo de visualización',
            id: '',
            name: 'bldata_mode',
            change_param: change_param,
            value: fill ? bloque.bl_data.mode : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
        };
        break;

      //NOMBRE: Galería
      case 5:
        let galeria_items = fill
          ? bloque.bl_data.items
          : [
              {
                link: 'block-preview-empty.jpeg',
                url: '',
                title: '',
                button: '',
              },
              {
                link: 'block-preview-empty.jpeg',
                url: '',
                title: '',
                button: '',
              },
              {
                link: 'block-preview-empty.jpeg',
                url: '',
                title: '',
                button: '',
              },
            ];

        bloque_obj.bl_data = {
          title: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Título',
            id: '',
            name: 'bldata_title',
            change_param: change_param,
            regex: Regex.STRING_GENERICO_2_90,
            value: fill ? bloque.bl_data.title : 'Título de la galería',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.STRING_GENERICO_2_90,
              error: 'Por favor, ingrese un título válido',
            },
          },
          title_align: {
            validate: false,
            required: true,
            error: false,
            label: 'Alineación del título',
            id: '',
            name: 'bldata_title_align',
            change_param: change_param,
            value: fill ? bloque.bl_data.title_align : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          text: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Texto',
            id: '',
            name: 'bldata_text',
            change_param: change_param,
            regex: Regex.DESCRIPCION_20_5000,
            value: fill
              ? bloque.bl_data.text
              : 'Acá podés escribir el texto que quieras, por ejemplo, relacionado a la galería.',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.DESCRIPCION_20_5000,
              error: 'Por favor, ingrese un texto válido',
            },
          },
          text_align: {
            validate: false,
            required: true,
            error: false,
            label: 'Alineación del texto',
            id: '',
            name: 'bldata_text_align',
            change_param: change_param,
            value: fill ? bloque.bl_data.text_align : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          items: {
            validate: false,
            auth: galeria_items.length ? true : false,
            uploading: false,
            required: true,
            label: '',
            id: 'bldata_items_' + change_param,
            sublabels: [''],
            files: galeria_items,
            messages: {
              help: '',
              error: '',
            },
          },
          gap: {
            validate: false,
            required: true,
            error: false,
            label: 'Espaciado de fotos',
            id: '',
            name: 'bldata_gap',
            change_param: change_param,
            value: fill ? bloque.bl_data.gap : 1,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          per_row: {
            validate: false,
            required: true,
            error: false,
            label: 'Fotos por fila',
            id: '',
            name: 'bldata_per_row',
            change_param: change_param,
            value: fill ? bloque.bl_data.per_row : 3,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          per_row_mobile: {
            validate: false,
            required: true,
            error: false,
            label: 'Fotos por fila (celulares)',
            id: '',
            name: 'bldata_per_row_mobile',
            change_param: change_param,
            value: fill ? bloque.bl_data.per_row_mobile : 2,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          masonry: {
            validate: false,
            required: true,
            error: false,
            label: 'Efecto Masonry',
            id: '',
            name: 'bldata_masonry',
            change_param: change_param,
            value: fill ? bloque.bl_data.masonry : 1,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          background: {
            auth: true,
            validate: true,
            required: true,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Color de fondo',
            id: '',
            name: 'bldata_background',
            change_param: change_param,
            regex: Regex.COLOR,
            value: fill ? bloque.bl_data.background : '#ffffff',
            default_value: '',
            placeholder: '#000000',
            messages: {
              error_extra: RegexExtra.COLOR,
              error: '',
            },
          },
          section_size: {
            validate: false,
            required: true,
            error: false,
            label: 'Espaciado de bloque',
            id: '',
            name: 'bldata_section_size',
            change_param: change_param,
            value: fill ? bloque.bl_data.section_size : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
        };
        break;

      //NOMBRE: Imagenes con link
      case 6:
        let imagenes_items = fill
          ? bloque.bl_data.items
          : [
              {
                link: 'block-preview-bag.jpeg',
                url: '',
                title: 'CARTERAS',
                button: '',
              },
              {
                link: 'block-preview-dress.jpeg',
                url: '',
                title: 'VESTIDOS',
                button: '',
              },
              {
                link: 'block-preview-shirt.jpeg',
                url: '',
                title: 'CAMISAS',
                button: '',
              },
            ];

        bloque_obj.bl_data = {
          title: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Título',
            id: '',
            name: 'bldata_title',
            change_param: change_param,
            regex: Regex.STRING_GENERICO_2_90,
            value: fill ? bloque.bl_data.title : 'Título de sección',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.STRING_GENERICO_2_90,
              error: 'Por favor, ingrese un título válido',
            },
          },
          title_align: {
            validate: false,
            required: true,
            error: false,
            label: 'Alineación del título',
            id: '',
            name: 'bldata_title_align',
            change_param: change_param,
            value: fill ? bloque.bl_data.title_align : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          text: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Texto',
            id: '',
            name: 'bldata_text',
            change_param: change_param,
            regex: Regex.DESCRIPCION_20_5000,
            value: fill
              ? bloque.bl_data.text
              : 'Acá podés escribir el texto que quieras, por ejemplo, relacionado a la sección.',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.DESCRIPCION_20_5000,
              error: 'Por favor, ingrese un texto válido',
            },
          },
          text_align: {
            validate: false,
            required: true,
            error: false,
            label: 'Alineación del texto',
            id: '',
            name: 'bldata_text_align',
            change_param: change_param,
            value: fill ? bloque.bl_data.text_align : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          items: {
            validate: false,
            auth: imagenes_items.length ? true : false,
            uploading: false,
            required: true,
            label: '',
            id: 'bldata_items_' + change_param,
            sublabels: [''],
            files: imagenes_items,
            messages: {
              help: '',
              error: '',
            },
          },
          gap: {
            validate: false,
            required: true,
            error: false,
            label: 'Espaciado de fotos',
            id: '',
            name: 'bldata_gap',
            change_param: change_param,
            value: fill ? bloque.bl_data.gap : 1,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          per_row: {
            validate: false,
            required: true,
            error: false,
            label: 'Fotos por fila',
            id: '',
            name: 'bldata_per_row',
            change_param: change_param,
            value: fill ? bloque.bl_data.per_row : 3,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          per_row_mobile: {
            validate: false,
            required: true,
            error: false,
            label: 'Fotos por fila (celulares)',
            id: '',
            name: 'bldata_per_row_mobile',
            change_param: change_param,
            value: fill ? bloque.bl_data.per_row_mobile : 1,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          type: {
            validate: false,
            required: true,
            error: false,
            label: 'Visualización',
            id: '',
            name: 'bldata_type',
            change_param: change_param,
            value: fill ? bloque.bl_data.type : 2,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          background: {
            auth: true,
            validate: true,
            required: true,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Color de fondo',
            id: '',
            name: 'bldata_background',
            change_param: change_param,
            regex: Regex.COLOR,
            value: fill ? bloque.bl_data.background : '#ffffff',
            default_value: '',
            placeholder: '#000000',
            messages: {
              error_extra: RegexExtra.COLOR,
              error: '',
            },
          },
          section_size: {
            validate: false,
            required: true,
            error: false,
            label: 'Espaciado de bloque',
            id: '',
            name: 'bldata_section_size',
            change_param: change_param,
            value: fill ? bloque.bl_data.section_size : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
        };
        break;

      //NOMBRE: Texto
      case 7:
        bloque_obj.bl_data = {
          title: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Título',
            id: '',
            name: 'bldata_title',
            change_param: change_param,
            regex: Regex.STRING_GENERICO_2_90,
            value: fill ? bloque.bl_data.title : 'Título',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.STRING_GENERICO_2_90,
              error: 'Por favor, ingrese un título válido',
            },
          },
          title_align: {
            validate: false,
            required: true,
            error: false,
            label: 'Alineación del título',
            id: '',
            name: 'bldata_title_align',
            change_param: change_param,
            value: fill ? bloque.bl_data.title_align : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          text: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Texto',
            id: '',
            name: 'bldata_text',
            change_param: change_param,
            regex: Regex.DESCRIPCION_20_5000,
            value: fill
              ? bloque.bl_data.text
              : 'Acá podés escribir el texto que quieras.',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.DESCRIPCION_20_5000,
              error: 'Por favor, ingrese un texto válido',
            },
          },
          text_align: {
            validate: false,
            required: true,
            error: false,
            label: 'Alineación del texto',
            id: '',
            name: 'bldata_text_align',
            change_param: change_param,
            value: fill ? bloque.bl_data.text_align : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          button: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Texto del botón',
            id: '',
            name: 'bldata_button',
            change_param: change_param,
            regex: Regex.STRING_GENERICO_2_20,
            value: fill ? bloque.bl_data.button : '',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.STRING_GENERICO_2_20,
              error: 'Por favor, ingrese un texto de botón válido',
            },
          },
          button_link: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Link del botón',
            id: '',
            name: 'bldata_button_link',
            change_param: change_param,
            regex: Regex.LINK,
            value: fill ? bloque.bl_data.button_link : '',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.LINK,
              error: 'Por favor, ingrese un link válido',
            },
          },
          button_align: {
            validate: false,
            required: true,
            error: false,
            label: 'Alineación del botón',
            id: '',
            name: 'bldata_button_align',
            change_param: change_param,
            value: fill ? bloque.bl_data.button_align : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          text_color: {
            auth: true,
            validate: true,
            required: true,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Color de textos',
            id: '',
            name: 'bldata_text_color',
            change_param: change_param,
            regex: Regex.COLOR,
            value: fill ? bloque.bl_data.text_color : '#000000',
            default_value: '',
            placeholder: '#000000',
            messages: {
              error_extra: RegexExtra.COLOR,
              error: '',
            },
          },
          background: {
            auth: true,
            validate: true,
            required: true,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Color de fondo',
            id: '',
            name: 'bldata_background',
            change_param: change_param,
            regex: Regex.COLOR,
            value: fill ? bloque.bl_data.background : '#f5f5f5',
            default_value: '',
            placeholder: '#000000',
            messages: {
              error_extra: RegexExtra.COLOR,
              error: '',
            },
          },
          section_size: {
            validate: false,
            required: true,
            error: false,
            label: 'Espaciado de bloque',
            id: '',
            name: 'bldata_section_size',
            change_param: change_param,
            value: fill ? bloque.bl_data.section_size : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
        };
        break;

      //NOMBRE: Informacion de compra
      case 8:
        bloque_obj.bl_data = {
          title_1: {
            auth: true,
            validate: true,
            required: true,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Título',
            id: '',
            name: 'bldata_title_1',
            change_param: change_param,
            regex: Regex.STRING_GENERICO_2_90,
            value: fill ? bloque.bl_data.title_1 : 'ENVÍOS A TODO EL PAÍS',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.STRING_GENERICO_2_90,
              error: 'Por favor, ingrese un título válido',
            },
          },
          text_1: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Texto',
            id: '',
            name: 'bldata_text_1',
            change_param: change_param,
            regex: Regex.DESCRIPCION_20_200,
            value: fill ? bloque.bl_data.text_1 : 'COMPRÁ SIN SALIR DE TU CASA',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.DESCRIPCION_20_200,
              error: 'Por favor, ingrese un texto válido',
            },
          },
          icon_1: {
            validate: false,
            required: true,
            error: false,
            label: 'Icono',
            id: '',
            name: 'bldata_icon_1',
            change_param: change_param,
            value: fill ? bloque.bl_data.icon_1 : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          title_2: {
            auth: true,
            validate: true,
            required: true,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Título',
            id: '',
            name: 'bldata_title_2',
            change_param: change_param,
            regex: Regex.STRING_GENERICO_2_90,
            value: fill ? bloque.bl_data.title_2 : 'HASTA 12 CUOTAS',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.STRING_GENERICO_2_90,
              error: 'Por favor, ingrese un título válido',
            },
          },
          text_2: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Texto',
            id: '',
            name: 'bldata_text_2',
            change_param: change_param,
            regex: Regex.DESCRIPCION_20_200,
            value: fill ? bloque.bl_data.text_2 : 'CON TODAS LAS TARJETAS',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.DESCRIPCION_20_200,
              error: 'Por favor, ingrese un texto válido',
            },
          },
          icon_2: {
            validate: false,
            required: true,
            error: false,
            label: 'Icono',
            id: '',
            name: 'bldata_icon_2',
            change_param: change_param,
            value: fill ? bloque.bl_data.icon_2 : 1,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          title_3: {
            auth: true,
            validate: true,
            required: true,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Título',
            id: '',
            name: 'bldata_title_3',
            change_param: change_param,
            regex: Regex.STRING_GENERICO_2_90,
            value: fill ? bloque.bl_data.title_3 : 'COMPRA SEGURA',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.STRING_GENERICO_2_90,
              error: 'Por favor, ingrese un título válido',
            },
          },
          text_3: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Texto',
            id: '',
            name: 'bldata_text_3',
            change_param: change_param,
            regex: Regex.DESCRIPCION_20_200,
            value: fill ? bloque.bl_data.text_3 : 'PROTEGEMOS TUS DATOS',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.DESCRIPCION_20_200,
              error: 'Por favor, ingrese un texto válido',
            },
          },
          icon_3: {
            validate: false,
            required: true,
            error: false,
            label: 'Icono',
            id: '',
            name: 'bldata_icon_3',
            change_param: change_param,
            value: fill ? bloque.bl_data.icon_3 : 2,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          text_color: {
            auth: true,
            validate: true,
            required: true,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Color de textos',
            id: '',
            name: 'bldata_text_color',
            change_param: change_param,
            regex: Regex.COLOR,
            value: fill ? bloque.bl_data.text_color : '#000000',
            default_value: '',
            placeholder: '#000000',
            messages: {
              error_extra: RegexExtra.COLOR,
              error: '',
            },
          },
          background: {
            auth: true,
            validate: true,
            required: true,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Color de fondo',
            id: '',
            name: 'bldata_background',
            change_param: change_param,
            regex: Regex.COLOR,
            value: fill ? bloque.bl_data.background : '#f5f5f5',
            default_value: '',
            placeholder: '#000000',
            messages: {
              error_extra: RegexExtra.COLOR,
              error: '',
            },
          },
        };
        break;

      //NOMBRE: Imagen con texto y botón
      case 9:
        let image = fill
          ? [bloque.bl_data.image]
          : [
              {
                link: 'block-preview-bag.jpeg',
                url: '',
                title: '',
                button: '',
              },
            ];

        bloque_obj.bl_data = {
          title: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Título',
            id: '',
            name: 'bldata_title',
            change_param: change_param,
            regex: Regex.STRING_GENERICO_2_90,
            value: fill ? bloque.bl_data.title : 'Título de sección',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.STRING_GENERICO_2_90,
              error: 'Por favor, ingrese un título válido',
            },
          },
          title_align: {
            validate: false,
            required: true,
            error: false,
            label: 'Alineación del título',
            id: '',
            name: 'bldata_title_align',
            change_param: change_param,
            value: fill ? bloque.bl_data.title_align : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          text: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Texto',
            id: '',
            name: 'bldata_text',
            change_param: change_param,
            regex: Regex.DESCRIPCION_20_5000,
            value: fill
              ? bloque.bl_data.text
              : 'Acá podés escribir el texto que quieras, por ejemplo, relacionado a la sección.',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.DESCRIPCION_20_5000,
              error: 'Por favor, ingrese un texto válido',
            },
          },
          text_align: {
            validate: false,
            required: true,
            error: false,
            label: 'Alineación del texto',
            id: '',
            name: 'bldata_text_align',
            change_param: change_param,
            value: fill ? bloque.bl_data.text_align : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          button: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Texto del botón',
            id: '',
            name: 'bldata_button',
            change_param: change_param,
            regex: Regex.STRING_GENERICO_2_20,
            value: fill ? bloque.bl_data.button : 'BOTÓN',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.STRING_GENERICO_2_20,
              error: 'Por favor, ingrese un texto de botón válido',
            },
          },
          button_link: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Link del botón',
            id: '',
            name: 'bldata_button_link',
            change_param: change_param,
            regex: Regex.LINK,
            value: fill ? bloque.bl_data.button_link : '',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.LINK,
              error: 'Por favor, ingrese un link válido',
            },
          },
          button_align: {
            validate: false,
            required: true,
            error: false,
            label: 'Alineación del botón',
            id: '',
            name: 'bldata_button_align',
            change_param: change_param,
            value: fill ? bloque.bl_data.button_align : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          image: {
            validate: false,
            auth: true,
            uploading: false,
            required: true,
            label: '',
            id: 'bldata_image_' + change_param,
            sublabels: [''],
            files: image,
            messages: {
              help: '',
              error: '',
            },
          },
          image_size: {
            validate: false,
            required: true,
            error: false,
            label: 'Tamaño de imagen',
            id: '',
            name: 'bldata_image_size',
            change_param: change_param,
            value: fill ? bloque.bl_data.image_size : 2,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          image_position: {
            validate: false,
            required: true,
            error: false,
            label: 'Posición de la imagen',
            id: '',
            name: 'bldata_image_position',
            change_param: change_param,
            value: fill ? bloque.bl_data.image_position : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          background: {
            auth: true,
            validate: true,
            required: true,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Color de fondo',
            id: '',
            name: 'bldata_background',
            change_param: change_param,
            regex: Regex.COLOR,
            value: fill ? bloque.bl_data.background : '#ffffff',
            default_value: '',
            placeholder: '#000000',
            messages: {
              error_extra: RegexExtra.COLOR,
              error: '',
            },
          },
          section_size: {
            validate: false,
            required: true,
            error: false,
            label: 'Espaciado de bloque',
            id: '',
            name: 'bldata_section_size',
            change_param: change_param,
            value: fill ? bloque.bl_data.section_size : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
        };
        break;

      //NOMBRE: Codigo HTML
      case 10:
        let default_html = `<section class="uk-section-block">
    <h1 class="uk-text-center">Hola mundo!</h1>
</section>`;

        bloque_obj.bl_data = {
          code: {
            validate: false,
            name: 'bldata_code',
            id: '',
            change_param: change_param,
            value: fill ? bloque.bl_data.code : default_html,
            placeholder: 'Escriba o pegue su código HTML aquí',
          },
        };
        break;

      //NOMBRE: Grillas de imágenes con link
      case 11:
        let grid_image_items = fill
          ? bloque.bl_data.items
          : [
              {
                link: 'block-preview-empty.jpeg',
                url: '',
                title: '',
                button: '',
              },
              {
                link: 'block-preview-empty.jpeg',
                url: '',
                title: '',
                button: '',
              },
            ];

        bloque_obj.bl_data = {
          type: {
            validate: false,
            change_param: change_param,
            value: fill ? bloque.bl_data.type : 0,
          },
          title: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Título',
            id: '',
            name: 'bldata_title',
            change_param: change_param,
            regex: Regex.STRING_GENERICO_2_90,
            value: fill ? bloque.bl_data.title : 'Título de la sección',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.STRING_GENERICO_2_90,
              error: 'Por favor, ingrese un título válido',
            },
          },
          title_align: {
            validate: false,
            required: true,
            error: false,
            label: 'Alineación del título',
            id: '',
            name: 'bldata_title_align',
            change_param: change_param,
            value: fill ? bloque.bl_data.title_align : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          text: {
            auth: true,
            validate: true,
            required: false,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Texto',
            id: '',
            name: 'bldata_text',
            change_param: change_param,
            regex: Regex.DESCRIPCION_20_5000,
            value: fill ? bloque.bl_data.text : '',
            default_value: '',
            placeholder: '',
            messages: {
              error_extra: RegexExtra.DESCRIPCION_20_5000,
              error: 'Por favor, ingrese un texto válido',
            },
          },
          text_align: {
            validate: false,
            required: true,
            error: false,
            label: 'Alineación del texto',
            id: '',
            name: 'bldata_text_align',
            change_param: change_param,
            value: fill ? bloque.bl_data.text_align : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          background: {
            auth: true,
            validate: true,
            required: true,
            disabled: false,
            error: false,
            type: 'text',
            label: 'Color de fondo',
            id: '',
            name: 'bldata_background',
            change_param: change_param,
            regex: Regex.COLOR,
            value: fill ? bloque.bl_data.background : '#ffffff',
            default_value: '',
            placeholder: '#000000',
            messages: {
              error_extra: RegexExtra.COLOR,
              error: '',
            },
          },
          items: {
            validate: false,
            auth: grid_image_items.length ? true : false,
            uploading: false,
            required: true,
            label: '',
            id: 'bldata_items_' + change_param,
            sublabels: [''],
            files: grid_image_items,
            messages: {
              help: '',
              error: '',
            },
          },
          gap: {
            validate: false,
            required: true,
            error: false,
            label: 'Espaciado de fotos',
            id: '',
            name: 'bldata_gap',
            change_param: change_param,
            value: fill ? bloque.bl_data.gap : 1,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
          section_size: {
            validate: false,
            required: true,
            error: false,
            label: 'Espaciado de bloque',
            id: '',
            name: 'bldata_section_size',
            change_param: change_param,
            value: fill ? bloque.bl_data.section_size : 0,
            messages: {
              help: '',
              error: 'Por favor, elija una opción',
            },
          },
        };
        break;

      default:
        break;
    }

    return bloque_obj;
  };

  obtenerObjetoBloques = () => {
    let bloques = this.state.bloques.map((bloque) => {
      let bloque_body = {
        idBloques: bloque.idBloques.value,
        bl_activo: bloque.bl_activo.value,
        bl_tipo: bloque.bl_tipo.value,
        bl_nombre: bloque.bl_nombre.value,
        bl_data: {},
      };

      Object.keys(bloque.bl_data).forEach((bl_data_key) => {
        switch (bl_data_key) {
          case 'items':
            bloque_body.bl_data['items'] = bloque.bl_data[bl_data_key].files;
            break;

          case 'image':
            bloque_body.bl_data['image'] = bloque.bl_data[bl_data_key].files[0];
            break;

          case 'products':
            bloque_body.bl_data['products'] = bloque.bl_data[bl_data_key].map(
              (producto) => producto.idProductos
            );
            break;

          default:
            bloque_body.bl_data[bl_data_key] =
              bloque.bl_data[bl_data_key].value;
            break;
        }
      });

      return bloque_body;
    });

    return bloques;
  };

  obtenerObjetoBody = () => {
    let { settings, products_feed } = this.state;

    products_feed = products_feed.map((producto) => producto.idProductos);

    let body = {
      Templates_idTemplates: settings.template_id.value,
      t_logo: settings.t_logo.files.length ? settings.t_logo.files[0].link : '',
      t_favicon: settings.t_favicon.files.length
        ? settings.t_favicon.files[0].link
        : 'favicon.png',
      t_template_data: {
        id: settings.color.value,
        css: settings.css.value,
        body: {
          background: settings.body_background.value,
        },
        fonts: {
          text: settings.font_text.value,
          title: settings.font_title.value,
        },
        primary: {
          text: settings.primary_text.value,
          background: settings.primary_background.value,
          contrastText: settings.primary_contrastText.value,
        },
        secondary: {
          text: settings.secondary_text.value,
          background: settings.secondary_background.value,
          contrastText: settings.secondary_contrastText.value,
        },
        message: {
          text: settings.message_text.value,
          link: settings.message_link.value,
        },
        products_feed: {
          order: settings.products_feed_order.value,
          filter: settings.products_feed_filter.value,
          masonry: 0,
          product_subtext: settings.products_feed_product_subtext.value,
          product_subtext_type:
            settings.products_feed_product_subtext_type.value,
          product_subtext_cuotas:
            settings.products_feed_product_subtext_cuotas.value,
        },
        products_detail: {
          photo_display: settings.products_detail_photo_display.value,
          photo_zoom: settings.products_detail_photo_zoom.value,
        },
        checkout: {
          comments: settings.checkout_comments.value,
          short_billing_form: settings.checkout_short_billing_form.value,
          step_review: settings.checkout_step_review.value,
        },
        other: {
          border_radius: settings.other_border_radius.value,
          menu_label_products: settings.other_menu_label_products.value,
          menu_label_wholesaler: settings.other_menu_label_wholesaler.value,
          menu_type: 0,
        },
        popup: {
          active: 0,
          type: 0,
          visibility: 0,
          visibility_place: 0,
          title: '',
          image: '',
          text: '',
          link: '',
          button: '',
          html: '',
        },
      },
      products_feed: products_feed,
      bloques: this.obtenerObjetoBloques(),
    };

    return body;
  };

  handleSubmit = (e, redirectToHome = false) => {
    e.preventDefault();

    if (!this.state.uploading_images) {
      this.setState(
        {
          spinner_message: 'Validando datos...',
          saving_changes: true,
        },
        () => {
          let { settings, bloques } = this.state;

          if (Validate.validar_formulario(settings)) {
            let flag = true;
            let bloques_error = [];
            bloques.forEach((bloque, index) => {
              if (
                !(
                  Validate.validar_formulario(bloque) &&
                  Validate.validar_formulario(bloque.bl_data)
                )
              ) {
                flag = false;
                bloques_error = [
                  ...bloques_error,
                  bloque.bl_nombre.value
                    ? bloque.bl_nombre.value
                    : 'Sección ' + (index + 1),
                ];
              } else {
                if ('image' in bloque.bl_data) {
                  if (!bloque.bl_data.image.auth) {
                    flag = false;
                    bloques_error = [
                      ...bloques_error,
                      bloque.bl_nombre.value
                        ? bloque.bl_nombre.value
                        : 'Sección ' + (index + 1),
                    ];
                  }
                } else if ('items' in bloque.bl_data) {
                  if (!bloque.bl_data.items.auth) {
                    flag = false;
                    bloques_error = [
                      ...bloques_error,
                      bloque.bl_nombre.value
                        ? bloque.bl_nombre.value
                        : 'Sección ' + (index + 1),
                    ];
                  } else {
                    if (bloque.bl_tipo.value === 11) {
                      let type = bloque.bl_data.type.value;
                      let cantidad_imagenes = bloque.bl_data.items.files.length;
                      if (
                        (type === 0 && cantidad_imagenes !== 2) ||
                        (Validate.in_array(type, [1, 2]) &&
                          cantidad_imagenes !== 3) ||
                        (Validate.in_array(type, [3, 4]) &&
                          cantidad_imagenes !== 4) ||
                        (type === 5 && cantidad_imagenes !== 5)
                      ) {
                        flag = false;
                        bloques_error = [
                          ...bloques_error,
                          bloque.bl_nombre.value
                            ? bloque.bl_nombre.value +
                              ' (debes cargar todas las imágenes)'
                            : 'Sección ' +
                              (index + 1) +
                              ' (debes cargar todas las imágenes)',
                        ];
                      }
                    }
                  }
                } else if ('products' in bloque.bl_data) {
                  if (bloque.bl_data.products.length === 0) {
                    flag = false;
                    bloques_error = [
                      ...bloques_error,
                      bloque.bl_nombre.value
                        ? bloque.bl_nombre.value
                        : 'Sección ' + (index + 1),
                    ];
                  }
                }
              }
            });

            if (flag) {
              let body = this.obtenerObjetoBody();

              this.setState(
                {
                  spinner_message: 'Guardando cambios...',
                  saving_changes: true,
                },
                () => {
                  this.props.tiendaActualizarTemplate(body, (err, resp) => {
                    if (err) {
                      this.setState({
                        saving_changes: false,
                        alert: {
                          open: true,
                          type: 'error',
                          message: resp.message,
                        },
                      });
                    } else {
                      if (body?.t_logo && this.state.idCountry === 'ARG') {
                        braze.logCustomEvent('empretienda_diseño_carga_logo');
                      }

                      this.props.bloqueObtenerBloques(async (err, resp2) => {
                        if (err) {
                          this.setState({
                            saving_changes: false,
                            alert: {
                              open: true,
                              type: 'error',
                              message: resp2.message,
                            },
                          });
                        } else {
                          //mapeo los bloques
                          let bloques = this.props.bloques.map((bloque) => {
                            return this.loadBlock(bloque.bl_tipo, true, bloque);
                          });

                          if (redirectToHome) {
                            await this.handleUpdatePreview();
                            await createAmplitudeEventWithDevice(
                              design_amplitude_events.diseño_selecciona_guardar
                                .event_name
                            );

                            localStorage.setItem(
                              'show-alert',
                              JSON.stringify({
                                open: true,
                                type: 'success',
                                message: resp.message,
                              })
                            );

                            this.props.history.push('/');
                          } else {
                            this.setState(
                              {
                                saving_changes: false,
                                alert: {
                                  open: true,
                                  type: 'success',
                                  message: resp.message,
                                },
                                bloques: bloques,
                              },
                              () => {
                                this.handleUpdatePreview();
                                createAmplitudeEventWithDevice(
                                  design_amplitude_events
                                    .diseño_selecciona_guardar.event_name
                                );
                              }
                            );
                          }
                        }
                      });
                    }
                  });
                }
              );
            } else {
              bloques = bloques.map((bloque) => {
                bloque =
                  Validate.validar_formulario_actualizar_obj_sync(bloque);
                let bl_data = Validate.validar_formulario_actualizar_obj_sync(
                  bloque.bl_data
                );
                return {
                  ...bloque,
                  bl_data: bl_data,
                };
              });

              let message =
                bloques_error.length >= 2
                  ? 'Por favor, verificar los campos de las secciones: ' +
                    bloques_error.join(', ')
                  : 'Por favor, verificar los campos de la sección "' +
                    bloques_error[0] +
                    '"';

              this.setState({
                saving_changes: false,
                bloques: bloques,
                alert: {
                  open: true,
                  type: 'error',
                  message: message,
                },
              });
            }
          } else {
            settings =
              Validate.validar_formulario_actualizar_obj_sync(settings);
            this.setState({
              saving_changes: false,
              settings: settings,
              alert: {
                open: true,
                type: 'error',
                message: 'Por favor, verificar los campos señalados con error.',
              },
            });
          }
        }
      );
    } else {
      this.setState({
        alert: {
          open: true,
          type: 'error',
          message: 'Debes esperar a que se carguen las imágenes',
        },
      });
    }
  };

  render() {
    if (
      this.state.loading_tienda ||
      this.state.loading_bloques ||
      this.state.loading_productos_ord ||
      this.state.loading_preview_html ||
      this.state.saving_changes
    ) {
      return <BuilderLoader message={this.state.spinner_message} />;
    } else if (this.state.error) {
      return (
        <div style={{ margin: '50px 20px' }}>
          <ErrorLayout url="/builder" />
        </div>
      );
    } else {
      return (
        <>
          <AlertUI
            open={this.state.alert.open}
            message={this.state.alert.message}
            type={this.state.alert.type}
            handleCloseAlert={this.handleCloseAlert}
          />
          <BuilderWrapper fonts={this.state.selects.fonts}>
            <NavigationLayout
              active_page={this.state.active_page}
              openCloseDrawer={this.openCloseDrawer}
              selects={this.state.selects}
              drawer_title={this.state.drawer_title}
              handleSubmit={this.handleSubmit}
              back_page={this.state.back_page}
              handleChangePage={this.handleChangePage}
              handleChangePreviewWidth={this.handleChangePreviewWidth}
              handleUpdatePreview={this.handleUpdatePreview}
              deviceActive={this.state.deviceActive}
              handleChangeDeviceActive={(device) =>
                this.setState({ deviceActive: device })
              }
            />
            <DrawerLayout
              openCloseDrawer={this.openCloseDrawer}
              drawer_status={this.state.drawer_status}
              drawer_title={this.state.drawer_title}
              back_page={this.state.back_page}
              handleUpdatePreview={this.handleUpdatePreview}
              active_page={this.state.active_page}
            >
              <DrawerContent
                active_page={this.state.active_page}
                tienda={this.props.tienda}
                settings={this.state.settings}
                agregar={this.state.agregar}
                selects={this.state.selects}
                bloques={this.state.bloques}
                productos={this.state.products_feed}
                handleChange={this.handleChange}
                handleChangePage={this.handleChangePage}
                handleChangeBlock={this.handleChangeBlock}
                handleBorrarBlock={this.handleBorrarBlock}
                handlerShowAlertUI={(alert) => {
                  this.setState({ alert: alert });
                }}
                handleChangeSelect={this.handleChangeSelect}
                handleAgregarBlock={this.handleAgregarBlock}
                handleOrdenarBlocks={this.handleOrdenarBlocks}
                handleChangeTemplate={this.handleChangeTemplate}
                handleChangeFileBlock={this.handleChangeFileBlock}
                handleChangeGridDesign={this.handleChangeGridDesign}
                handleChangeSelectBlock={this.handleChangeSelectBlock}
                handleDisableEnableBlock={this.handleDisableEnableBlock}
                handleChangeFileSettings={this.handleChangeFileSettings}
                handleChangeFeedProducts={this.handleChangeFeedProducts}
                handleChangeProductsBlock={this.handleChangeProductsBlock}
              />
            </DrawerLayout>
            <ShopPreview
              preview_width={this.state.preview_width}
              preview_html={this.state.preview_html}
            />
          </BuilderWrapper>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
    bloques: state.bloques,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    bloqueObtenerBloques: (callback) =>
      dispatch(bloqueObtenerBloques(callback)),
    tiendaActualizarTemplate: (data, callback) =>
      dispatch(tiendaActualizarTemplate(data, callback)),
    tiendaGenerarPrevisualizacionTemplate: (data, callback) =>
      dispatch(tiendaGenerarPrevisualizacionTemplate(data, callback)),
    productoObtenerProductosOrdenados: (callback) =>
      dispatch(productoObtenerProductosOrdenados(callback)),
  };
};

export const Builder = connect(mapStateToProps, mapDispatchToProps)(Builder_);
