/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import {
  categoriasObtenerFlattenJerarquico,
  categoriaBorrarOfertaMasiva,
  productoBorrarOfertaMasiva,
} from '../../../store/actions';
import { Config, Validate } from '../../../other';
import {
  Card,
  CardContent,
  Chip,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
  SelectUI,
  ToggleUI,
  NextActionsUI,
  AutosuggestUI,
} from '../../common';
import { WrapperLayout } from '../../../layouts/WrapperLayout';

let primera_opcion_categoria = {
  idCategorias: 0,
  c_nombre: 'Seleccione una categoría',
  c_prefix: 'Seleccione una categoría',
  c_link_full: '',
  c_link: '',
  c_nombre_full: 'Seleccione una categoría',
  c_nombre_slash: 'Seleccione una categoría',
  c_padre: null,
  padres: [],
  hijos: [],
};

class BorrarOferta_ extends Component {
  state = {
    borrar_oferta_masiva: {
      tipo: {
        validate: false,
        required: true,
        error: false,
        label: 'Borrar oferta sobre',
        id: 'tipo',
        name: 'tipo',
        change_param: 'borrar_oferta_masiva',
        value: 0,
        messages: {
          error: 'Por favor, seleccione una opción',
        },
      },
      idCategorias: {
        validate: false,
        required: true,
        error: false,
        label: 'Categoría',
        id: 'idCategorias',
        name: 'idCategorias',
        change_param: 'borrar_oferta_masiva',
        value: 0,
        messages: {
          error: '',
        },
      },
      aplicar_hijos: {
        validate: false,
        required: true,
        error: false,
        label: 'Aplicar a sus subcategorías',
        id: 'aplicar_hijos',
        name: 'aplicar_hijos',
        change_param: 'borrar_oferta_masiva',
        value: 1,
        messages: {
          error: 'Por favor, seleccione una opción',
        },
      },
      productos: {
        validate: false,
        selected: [],
      },
    },
    categorias: [],
    after: false,
    loading: {
      categorias: false,
      error: false,
    },
    forms: {
      borrar_oferta_masiva: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    selects: {
      categorias: [primera_opcion_categoria],
      tipo: [
        {
          value: 0,
          label: 'Seleccionar opción',
        },
        {
          value: 1,
          label: 'Una categoría',
        },
        {
          value: 2,
          label: 'Ciertos productos',
        },
      ],
      aplicar_hijos: [
        {
          value: 0,
          label: 'No',
        },
        {
          value: 1,
          label: 'Sí',
        },
      ],
    },
    feed_input_value: '',
    feed: [],
  };

  //autosuggest
  debounceMethod = null;

  handleOnSuggestionSelected = (selected_suggestion) => {
    this.setState({
      borrar_oferta_masiva: {
        ...this.state.borrar_oferta_masiva,
        productos: {
          ...this.state.borrar_oferta_masiva.productos,
          selected: [
            ...this.state.borrar_oferta_masiva.productos.selected,
            selected_suggestion,
          ],
        },
      },
      feed_input_value: '',
    });
  };

  canBeOption = (id) => {
    return this.state.borrar_oferta_masiva.productos.selected.filter(
      (option) => {
        return option.idProductos === id;
      }
    ).length > 0
      ? false
      : true;
  };

  handleSuggestionFetchData = (value) => {
    const inputValue = Validate.trim_lowercase(value);
    if (inputValue.length > 1) {
      axios
        .get(`${Config.BACKEND_ENDPOINT}/producto`, {
          params: { search: inputValue },
        })
        .then((resp) => {
          //filtro las que ya existen en los chips para evitar duplicados
          let feed = resp.data.data.filter((option) => {
            return (
              this.canBeOption(option.idProductos) &&
              option.p_mostrar_precio === 1 &&
              option.p_tipo_venta !== 1
            );
          });

          this.setState({ feed: feed });
        })
        .catch((err) => {
          this.setState({
            alert: {
              ...this.state.alert,
              open: true,
              message: 'Ha ocurrido un error al buscar, intente nuevamente',
              type: 'error',
            },
          });
        });
    }
  };

  handleDeleteChip = (deleted_option) => {
    let selected = this.state.borrar_oferta_masiva.productos.selected.filter(
      (option) => {
        return option.idProductos !== deleted_option.idProductos;
      }
    );

    this.setState({
      borrar_oferta_masiva: {
        ...this.state.borrar_oferta_masiva,
        productos: {
          ...this.state.borrar_oferta_masiva.productos,
          selected: selected,
        },
      },
      feed_input_value: '',
    });
  };

  handleSuggestionsFetchRequested = ({ value }) => this.debounceMethod(value);

  handleSuggestionsClearRequested = () => this.setState({ feed: [] });

  handleSuggestionsChangeSelected = (e, { newValue }) =>
    this.setState({ feed_input_value: newValue });
  //end autosuggest

  componentDidMount() {
    this.debounceMethod = debounce(this.handleSuggestionFetchData, 500);
  }

  cargarCategorias = () => {
    this.setState({
      loading: {
        ...this.state.loading,
        categorias: true,
      },
    });
    this.props.categoriasObtenerFlattenJerarquico((err, resp) => {
      this.setState({
        loading: {
          ...this.state.loading,
          error: err ? true : this.state.loading.error,
          categorias: false,
        },
        selects: {
          ...this.state.selects,
          categorias: [primera_opcion_categoria, ...resp.data],
        },
        categorias: [...resp.data],
      });
    });
  };

  isButtonDisabled = () => {
    let { tipo, idCategorias, productos } = this.state.borrar_oferta_masiva;

    switch (tipo.value) {
      case 0:
        return true;

      case 1:
        return idCategorias.value === 0;

      case 2:
        return productos.selected.length === 0;

      default:
        break;
    }
  };

  handleChangeSelect = (e, aditional) => {
    let name = e.target.name;
    let value = Validate.trim_int(e.target.value);

    this.setState(
      {
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        if (name === 'tipo' && value === 1) {
          this.cargarCategorias();
        }
      }
    );
  };

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      {
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: input,
            },
          });
        });
      }
    );
  };

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  borrarOfertaCategoria = (body) => {
    this.props.categoriaBorrarOfertaMasiva(
      {
        ...body,
      },
      (err, resp) => {
        this.setState({
          forms: {
            ...this.state.forms,
            borrar_oferta_masiva: false,
          },
          after: err ? false : true,
          alert: {
            ...this.state.alert,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          },
        });
      }
    );
  };

  borrarOfertaProductos = (body) => {
    this.props.productoBorrarOfertaMasiva(
      {
        ...body,
      },
      (err, resp) => {
        this.setState({
          forms: {
            ...this.state.forms,
            borrar_oferta_masiva: false,
          },
          after: err ? false : true,
          alert: {
            ...this.state.alert,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          },
        });
      }
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        borrar_oferta_masiva: true,
      },
    });

    let form = this.state.borrar_oferta_masiva;

    if (Validate.validar_formulario(form)) {
      let tipo = form.tipo.value;
      if (tipo === 1) {
        let body = {
          idCategorias: form.idCategorias.value,
          aplicar_hijos: form.aplicar_hijos.value,
        };
        this.borrarOfertaCategoria(body);
      } else if (tipo === 2) {
        let idProductos =
          this.state.borrar_oferta_masiva.productos.selected.map((element) => {
            return element.idProductos;
          });
        let body = {
          idProductos: idProductos,
        };
        this.borrarOfertaProductos(body);
      }
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          forms: {
            ...this.state.forms,
            borrar_oferta_masiva: false,
          },
          borrar_oferta_masiva: new_form,
        });
      });
    }
  };

  mostrarCategorias = () => {
    let classes = this.props.classes;
    if (this.state.borrar_oferta_masiva.idCategorias.value !== 0) {
      let selected_cat = this.state.categorias.filter(
        (categoria) =>
          categoria.idCategorias ===
          this.state.borrar_oferta_masiva.idCategorias.value
      )[0];
      let selected_cat_hijos_chips = '';
      let text;

      if (this.state.borrar_oferta_masiva.aplicar_hijos.value === 0) {
        text =
          'Se eliminarán las ofertas de todos los productos de la siguiente categoría, ya sea que el producto esté agregado como principal o secundario en la misma:';
      } else {
        text =
          'Se eliminarán las ofertas de todos los productos de las siguientes categorías, ya sea que el producto esté agregado como principal o secundario en las mismas:';
        selected_cat_hijos_chips = selected_cat.hijos.map((hijo) => {
          let cat = this.state.categorias.filter(
            (cat) => cat.idCategorias === hijo
          )[0];
          return (
            <Chip
              className={classes.chip}
              component="span"
              color="primary"
              label={cat.c_nombre}
              key={hijo}
            />
          );
        });
      }

      return (
        <div className={classes.box}>
          <Typography>{text}</Typography>
          <Chip
            className={classes.chip}
            component="span"
            color="primary"
            label={selected_cat.c_nombre}
            key={selected_cat.idCategorias}
          />
          {selected_cat_hijos_chips}
        </div>
      );
    } else {
      return '';
    }
  };

  render() {
    let classes = this.props.classes;
    return (
      <WrapperLayout loading={false} error={this.state.loading.error}>
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <ToggleUI show={this.state.after}>
              <NextActionsUI
                title="¡La oferta ha sido eliminada con éxito!"
                buttons={[
                  <ButtonUI
                    type="link"
                    label="Ver mis ofertas"
                    link="/ofertas"
                  />,
                  <ButtonUI
                    type="link"
                    label="Borrar otra oferta"
                    link="/ofertas/eliminar"
                    external_link
                  />,
                  <ButtonUI
                    type="link"
                    label="Agregar nueva oferta"
                    link="/ofertas/agregar"
                    external_link
                  />,
                ]}
              />
            </ToggleUI>
            <ToggleUI show={!this.state.after}>
              <form onSubmit={this.handleSubmit}>
                <ModuleTitleUI
                  title="Borrar oferta"
                  subtitles={[
                    'Antes de eliminar una oferta desde esta sección te recomendamos ver el tutorial correspondiente',
                    'Si eliminas, por ejemplo, una oferta de una categoría y en esa categoría tenias un producto con un precio de oferta diferente, se van a eliminar TODAS las ofertas que tenga esa categoría (todos los productos que estén en esa categoría van a quedar solo con el precio de venta y sin ningún precio promocional de oferta).',
                  ]}
                  back_button
                  back_button_link="/ofertas"
                  button={
                    <ButtonUI
                      type="link"
                      label="Tutorial"
                      fullWidth={false}
                      link="https://youtu.be/MTbofpaIdxc"
                      external_link
                      open_new_tab
                    />
                  }
                />
                <Card className={classes.card}>
                  <CardContent className={classes.card_content}>
                    <Grid container spacing={1.5}>
                      <Grid item xs={12}>
                        <SelectUI
                          data={this.state.borrar_oferta_masiva.tipo}
                          options={this.state.selects.tipo}
                          handleChangeSelect={this.handleChangeSelect}
                        />
                      </Grid>
                      <ToggleUI
                        show={
                          this.state.borrar_oferta_masiva.tipo.value !== 0 &&
                          !this.state.loading.categorias
                        }
                      >
                        <ToggleUI
                          show={
                            this.state.borrar_oferta_masiva.tipo.value === 1
                          }
                        >
                          <Grid item lg={6} xs={12}>
                            <SelectUI
                              data={
                                this.state.borrar_oferta_masiva.idCategorias
                              }
                              options={this.state.selects.categorias}
                              handleChangeSelect={this.handleChangeSelect}
                              value_atribute="idCategorias"
                              label_atribute="c_nombre"
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <SelectUI
                              data={
                                this.state.borrar_oferta_masiva.aplicar_hijos
                              }
                              options={this.state.selects.aplicar_hijos}
                              handleChangeSelect={this.handleChangeSelect}
                            />
                          </Grid>
                          <ToggleUI
                            show={
                              this.state.borrar_oferta_masiva.tipo.value ===
                                1 &&
                              this.state.borrar_oferta_masiva.idCategorias
                                .value !== 0
                            }
                          >
                            <Grid item xs={12}>
                              {this.mostrarCategorias()}
                            </Grid>
                          </ToggleUI>
                        </ToggleUI>
                        <ToggleUI
                          show={
                            this.state.borrar_oferta_masiva.tipo.value === 2
                          }
                        >
                          <Grid item xs={12}>
                            <AutosuggestUI
                              suggestions={this.state.feed}
                              input_value={this.state.feed_input_value}
                              suggestion_selected={
                                this.state.borrar_oferta_masiva.productos
                                  .selected
                              }
                              multiple
                              suggestion_index={'p_nombre'}
                              label={'Productos'}
                              placeholder={'Ej: Remera Onix'}
                              handleOnSuggestionSelected={
                                this.handleOnSuggestionSelected
                              }
                              handleSuggestionsFetchRequested={
                                this.handleSuggestionsFetchRequested
                              }
                              handleSuggestionsClearRequested={
                                this.handleSuggestionsClearRequested
                              }
                              handleSuggestionsChangeSelected={
                                this.handleSuggestionsChangeSelected
                              }
                              handleDeleteChip={this.handleDeleteChip}
                            />
                          </Grid>
                        </ToggleUI>
                      </ToggleUI>
                      {this.state.loading.categorias ? (
                        <Grid item xs={12}>
                          <LinearProgress />
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </CardContent>
                </Card>
                <ModuleButtonBoxUI>
                  <ButtonUI
                    type="submit"
                    label="Borrar"
                    isLoading={this.state.forms.borrar_oferta_masiva}
                    minHeight={true}
                    disabled={this.isButtonDisabled()}
                  />
                </ModuleButtonBoxUI>
              </form>
            </ToggleUI>
          </Grid>
        </Grid>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
  text_link: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  box: {
    padding: '10px',
    backgroundColor: '#f3f3f3',
    borderRadius: '4px',
  },
  chip: theme.chip,
});

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
  return {
    categoriasObtenerFlattenJerarquico: (callback) =>
      dispatch(categoriasObtenerFlattenJerarquico(callback)),
    categoriaBorrarOfertaMasiva: (data, callback) =>
      dispatch(categoriaBorrarOfertaMasiva(data, callback)),
    productoBorrarOfertaMasiva: (data, callback) =>
      dispatch(productoBorrarOfertaMasiva(data, callback)),
  };
};
export const BorrarOferta = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(BorrarOferta_)
);
