import {
  ClickAwayListener,
  Icon,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Config, Validate } from '../../../other';
import { ToggleUI } from '../../../components/common';

const SalesTableRow = ({
  venta,
  classes,
  tableClass,
  selectedShipment,
  idCountry,
  modalHandler,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const router = useHistory();

  const handleSaveQueryParams = () => {
    if (router.location.search) {
      localStorage.setItem('filter_query_params', router.location.search);
    }
  };

  return (
    <TableRow hover>
      <TableCell classes={{ root: tableClass }}>
        <Link
          to={'/listado-de-ventas/orden/' + venta.v_numero}
          className={classes.link_order}
          onClick={handleSaveQueryParams}
          data-testid={`Orden-${venta.v_numero}`}
        >
          <u>{venta.v_numero}</u>
        </Link>
      </TableCell>
      <TableCell classes={{ root: tableClass }}>
        <div
          className={classes.customer_name}
          data-testid={`Cliente-${venta.v_numero}`}
        >
          {Validate.trim_ucwords(
            venta.v_facturacion_nombre + ' ' + venta.v_facturacion_apellido
          )}
          <ToggleUI show={venta.v_observacion ? true : false}>
            <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
              <Tooltip
                PopperProps={{ disablePortal: true }}
                onClose={() => setTooltipOpen(false)}
                open={tooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={venta.v_observacion}
                classes={{
                  tooltip: classes.tooltip,
                }}
              >
                <IconButton
                  aria-label="Observacion"
                  onClick={() => setTooltipOpen(true)}
                  size="large"
                  data-testid={`Observacion-${venta.v_numero}`}
                >
                  <Icon style={{ fontSize: '1rem' }}>message</Icon>
                </IconButton>
              </Tooltip>
            </ClickAwayListener>
          </ToggleUI>
        </div>
      </TableCell>
      <TableCell
        classes={{ root: tableClass }}
        data-testid={`Pago-${venta.v_numero}`}
      >
        {Validate.label_estado_pago(venta.v_estado_pago)}
      </TableCell>
      <TableCell
        classes={{ root: tableClass }}
        data-testid={`Fecha-${venta.v_numero}`}
      >
        {Validate.utc2local_format(venta.v_fecha, Config.SHORTDATE_FORMAT)}
      </TableCell>
      <TableCell
        classes={{ root: tableClass }}
        data-testid={`Metodo-Pago-${venta.v_numero}`}
      >
        {Validate.label_metodo_pago(venta.v_metodo_pago)}
      </TableCell>
      <TableCell
        classes={{ root: tableClass }}
        data-testid={`Metodo-Envio-${venta.v_numero}`}
      >
        {Validate.label_metodo_envio(venta.v_metodo_envio, selectedShipment)}
      </TableCell>
      <TableCell
        classes={{ root: tableClass }}
        data-testid={`Envio-${venta.v_numero}`}
      >
        {Validate.label_estado_envio(
          venta.v_metodo_envio,
          venta.v_estado_envio
        )}
      </TableCell>
      <TableCell
        classes={{ root: tableClass }}
        data-testid={`Costo-Envio-${venta.v_numero}`}
      >
        {Validate.label_costo_envio(
          venta.v_metodo_envio,
          venta.v_envio_costo,
          venta.v_envio_gratis,
          selectedShipment
        )}
      </TableCell>
      <TableCell
        classes={{ root: tableClass }}
        data-testid={`Monto-${venta.v_numero}`}
      >
        ${Validate.number_format(venta.v_monto, idCountry)}
      </TableCell>
      <TableCell classes={{ root: tableClass }} align="center">
        <IconButton
          aria-label="Archivar"
          onClick={(e) => modalHandler(true, 'archivar_orden', venta)}
          disabled={venta.v_archivada ? true : false}
          size="large"
          data-testid={`Archivar-${venta.v_numero}`}
        >
          <Icon className="fas fa-archive" style={{ fontSize: '0.8rem' }} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default SalesTableRow;
