import React, { Component } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { withStyles } from '@mui/styles';

class TableErrors_ extends Component {
  render() {
    const { classes, import_errors } = this.props;
    const importingErrors = import_errors.length;
    const multipleErrors = importingErrors > 1;

    return (
      <Grid item xs={12}>
        <p className={classes.import_text}>
          Se detect{multipleErrors ? 'aron ' : 'ó '}
          <span className={classes.error_text}>
            {importingErrors} {multipleErrors ? 'errores' : 'error'}
          </span>{' '}
          en su planilla de productos
        </p>
        <Table className={classes.table_errors}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.column_name}>N°</TableCell>
              <TableCell
                sx={{ minWidth: '150px' }}
                className={classes.column_name}
              >
                Nombre del producto
              </TableCell>
              <TableCell className={classes.column_name}>Fila</TableCell>
              <TableCell className={classes.column_name}>Columna</TableCell>
              <TableCell className={classes.column_name}>
                Tipo de error
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {import_errors.map((error, i) => (
              <TableRow key={i}>
                <TableCell
                  className={classes.error_item}
                  component="th"
                  scope="row"
                >
                  {i + 1}
                </TableCell>
                <TableCell className={classes.error_item}>
                  {error.product_name}
                </TableCell>
                <TableCell className={classes.error_item}>
                  {error.row_number}
                </TableCell>
                <TableCell className={classes.error_item}>
                  {error.column_name}
                </TableCell>
                <TableCell className={classes.error_item}>
                  {error.error_type}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  import_text: {
    fontWeight: '500',
    fontSize: '1.25rem',
    lineHeight: '23px',
    color: '#000000',
    marginBottom: '24px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '0px',
      fontSize: '1rem',
      lineHeight: '18.75px',
    },
  },
  error_text: {
    color: '#FF0000',
  },
  table_errors: {
    margin: '20px 0px 40px 0px',
    '&::-webkit-scrollbar': {
      width: '17px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#F2F4F6',
    },
    '&::-webkit-scrollbar-thumb': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.5)',
      backgroundColor: '#C4C4C4',
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: '75vw',
      whiteSpace: 'nowrap',
      overflowX: 'scroll',
    },
  },
  error_item: {
    fontSize: '1rem',
    fontWeight: '600',
    lineHeight: '19px',
    color: '#242424',
    borderBottom: 'none',
  },
  column_name: {
    border: 'none',
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

export const TableErrors = withStyles(styles)(TableErrors_);
