import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import {
  clienteObtenerClientes,
  clienteObtenerClientesExportar,
  clienteRevocarMayoristaAccion,
  tiendaObtener,
  clienteEditarCliente,
  clienteBloquearCliente,
  clienteDesbloquearCliente,
} from '../../store/actions';
import { Box, Button, Grid, Icon, LinearProgress } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AlertUI, InputUI, ToggleUI, ModuleTitleUI } from '../common';
import { Validate, Regex, RegexExtra } from '../../other';
import {
  ClientesList,
  FilterSidenav,
  ModalRevocar,
  ModalVerInformacion,
  ModalEditar,
  ModalBloquear,
  ModalDesbloquear,
} from './subcomponents';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import ButtonMui from '../common/ButtonMui';
class Clientes_ extends Component {
  state = {
    //datos para el modal borrar
    filter: {
      search: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Buscar',
        id: 'search',
        name: 'search',
        change_param: 'filter',
        regex: Regex.ANYTHING,
        value: '',
        default_value: '',
        placeholder: 'Nombre, email, teléfono, ...',
        messages: {
          error: '',
          error_extra: RegexExtra.ANYTHING,
        },
      },
      cl_mayorista: {
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de cliente',
        id: 'cl_mayorista',
        name: 'cl_mayorista',
        change_param: 'filter',
        value: -2,
        messages: {
          error: '',
        },
      },
      cl_bloqueado: {
        validate: false,
        required: true,
        error: false,
        label: 'Bloqueados',
        id: 'cl_bloqueado',
        name: 'cl_bloqueado',
        change_param: 'filter',
        value: -1,
        messages: {
          error: '',
        },
      },
      cl_registro_completo: {
        validate: false,
        required: true,
        error: false,
        label: 'Cliente registrado',
        id: 'cl_registro_completo',
        name: 'cl_registro_completo',
        change_param: 'filter',
        value: -1,
        messages: {
          error: '',
        },
      },
      page: 0,
    },

    load_more: {
      loading: false,
      show: false,
    },

    revocar: {
      idClientes: null,
      cl_nombre: '',
      cl_apellido: '',
    },

    bloquear: {
      idClientes: null,
      cl_nombre: '',
      cl_apellido: '',
    },

    desbloquear: {
      idClientes: null,
      cl_nombre: '',
      cl_apellido: '',
    },

    ver_informacion: {
      idClientes: null,
      cl_email: '',
      cl_mayorista: '',
      cl_nombre: '',
      cl_apellido: '',
      cl_telefono: '',
      cl_tipo_identificacion: '',
      cl_numero_identificacion: '',
      cl_calle: '',
      cl_numero: '',
      cl_piso: '',
      cl_dpto: '',
      cl_ciudad: '',
      cl_provincia: '',
      cl_pais: '',
    },

    //campos para modal editar
    editar: {
      idClientes: {
        validate: false,
        value: 0,
      },
      cl_nombre: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nombre',
        id: 'cl_nombre',
        name: 'cl_nombre',
        change_param: 'editar',
        regex: Regex.NOMBRE_2_45,
        value: '',
        default_value: '',
        placeholder: 'Nombre cliente',
        messages: {
          error: 'Por favor, ingrese un nombre de cliente válido',
          error_extra: RegexExtra.NOMBRE_2_45,
        },
      },
      cl_apellido: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Apellido',
        id: 'cl_apellido',
        name: 'cl_apellido',
        change_param: 'editar',
        regex: Regex.NOMBRE_2_45,
        value: '',
        default_value: '',
        placeholder: 'Apellido cliente',
        messages: {
          error: 'Por favor, ingrese un apellido de cliente válido',
          error_extra: RegexExtra.NOMBRE_2_45,
        },
      },
      cl_email: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'email',
        label: 'Email',
        id: 'cl_email',
        name: 'cl_email',
        change_param: 'editar',
        regex: Regex.EMAIL,
        value: '',
        default_value: '',
        placeholder: 'Email cliente',
        messages: {
          error: 'Por favor, ingrese un email de cliente válido',
          error_extra: RegexExtra.EMAIL,
        },
      },
      cl_mayorista: {
        auth: true,
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de cliente',
        id: 'cl_mayorista',
        name: 'cl_mayorista',
        change_param: 'editar',
        value: null,
        default_value: null,
        messages: {
          error: '',
        },
      },
      cl_tipo_identificacion: {
        auth: true,
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de documento',
        id: 'cl_tipo_identificacion',
        name: 'cl_tipo_identificacion',
        change_param: 'editar',
        value: null,
        default_value: null,
        messages: {
          error: '',
        },
      },
      cl_numero_identificacion: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Documento',
        id: 'cl_numero_identificacion',
        name: 'cl_numero_identificacion',
        change_param: 'editar',
        regex: Regex.CUIT_GUION_OPCIONAL,
        value: '',
        default_value: '',
        placeholder: 'Documento',
        messages: {
          error: 'Por favor, ingrese una Identificación válida',
          error_extra: RegexExtra.CUIT_GUION_OPCIONAL,
        },
      },
      cl_telefono: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Teléfono',
        id: 'cl_telefono',
        name: 'cl_telefono',
        change_param: 'editar',
        regex: Regex.TELEFONO_COMPUESTO,
        value: '',
        default_value: '',
        placeholder: 'Teléfono',
        messages: {
          error: 'Por favor, ingrese un teléfono válido',
          error_extra: RegexExtra.TELEFONO_COMPUESTO,
        },
      },
      cl_calle: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Dirección',
        id: 'cl_calle',
        name: 'cl_calle',
        change_param: 'editar',
        regex: Regex.CALLE_1_30,
        value: '',
        default_value: '',
        placeholder: 'Dirección',
        messages: {
          error: 'Por favor, ingrese una dirección válida',
          error_extra: RegexExtra.CALLE_1_30,
        },
      },
      cl_numero: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Número',
        id: 'cl_numero',
        name: 'cl_numero',
        change_param: 'editar',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: 'Número',
        messages: {
          error: 'Por favor, ingrese un número válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      cl_dpto: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Dpto',
        id: 'cl_dpto',
        name: 'cl_dpto',
        change_param: 'editar',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: 'Dpto',
        messages: {
          error: 'Por favor, ingrese un departamento válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      cl_piso: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Piso',
        id: 'cl_piso',
        name: 'cl_piso',
        change_param: 'editar',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: 'Piso',
        messages: {
          error: 'Por favor, ingrese un número de piso válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      cl_provincia: {
        auth: true,
        validate: false,
        required: true,
        error: false,
        label: 'Provincia',
        id: 'cl_provincia',
        name: 'cl_provincia',
        change_param: 'editar',
        value: null,
        default_value: null,
        messages: {
          error: '',
        },
      },
      cl_ciudad: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Ciudad',
        id: 'cl_ciudad',
        name: 'cl_ciudad',
        change_param: 'editar',
        regex: Regex.CALLE_1_30,
        value: '',
        default_value: '',
        placeholder: 'Ciudad',
        messages: {
          error: 'Por favor, ingrese una ciudad válida',
          error_extra: RegexExtra.CALLE_1_30,
        },
      },
    },

    selects: {
      cl_mayorista: [
        {
          value: -2,
          label: 'Todos',
        },
        {
          value: -1,
          label: 'Solicitud mayorista pendiente',
        },
        {
          value: 0,
          label: 'Sólo minoristas',
        },
        {
          value: 1,
          label: 'Sólo mayoristas',
        },
      ],
      cl_mayorista_tipo: [
        {
          value: -1,
          label: 'Solicitud mayorista pendiente',
        },
        {
          value: 0,
          label: 'Minorista',
        },
        {
          value: 1,
          label: 'Mayorista',
        },
      ],
      cl_tipo_identificacion: [
        {
          value: 'DNI',
          label: 'DNI',
        },
        {
          value: 'CUIT',
          label: 'CUIT',
        },
      ],
      cl_provincia: [
        {
          value: 'BUENOS AIRES',
          label: 'BUENOS AIRES',
        },
        {
          value: 'CAPITAL FEDERAL',
          label: 'CAPITAL FEDERAL',
        },
        {
          value: 'CATAMARCA',
          label: 'CATAMARCA',
        },
        {
          value: 'CHACO',
          label: 'CHACO',
        },
        {
          value: 'CHUBUT',
          label: 'CHUBUT',
        },
        {
          value: 'CORDOBA',
          label: 'CORDOBA',
        },
        {
          value: 'CORRIENTES',
          label: 'CORRIENTES',
        },
        {
          value: 'ENTRE RIOS',
          label: 'ENTRE RIOS',
        },
        {
          value: 'FORMOSA',
          label: 'FORMOSA',
        },
        {
          value: 'JUJUY',
          label: 'JUJUY',
        },
        {
          value: 'LA PAMPA',
          label: 'LA PAMPA',
        },
        {
          value: 'LA RIOJA',
          label: 'LA RIOJA',
        },
        {
          value: 'MENDOZA',
          label: 'MENDOZA',
        },
        {
          value: 'MISIONES',
          label: 'MISIONES',
        },
        {
          value: 'NEUQUEN',
          label: 'NEUQUEN',
        },
        {
          value: 'RIO NEGRO',
          label: 'RIO NEGRO',
        },
        {
          value: 'SALTA',
          label: 'SALTA',
        },
        {
          value: 'SAN JUAN',
          label: 'SAN JUAN',
        },
        {
          value: 'SAN LUIS',
          label: 'SAN LUIS',
        },
        {
          value: 'SANTA CRUZ',
          label: 'SANTA CRUZ',
        },
        {
          value: 'SANTA FE',
          label: 'SANTA FE',
        },
        {
          value: 'SGO. DEL ESTERO',
          label: 'SGO. DEL ESTERO',
        },
        {
          value: 'TIERRA DEL FUEGO',
          label: 'TIERRA DEL FUEGO',
        },
        {
          value: 'TUCUMAN',
          label: 'TUCUMAN',
        },
      ],
      cl_bloqueado: [
        {
          value: -1,
          label: 'Todos',
        },
        {
          value: 0,
          label: 'No bloqueados',
        },
        {
          value: 1,
          label: 'Bloqueados',
        },
      ],
      cl_registro_completo: [
        {
          value: -1,
          label: 'Todos',
        },
        {
          value: 0,
          label: 'No',
        },
        {
          value: 1,
          label: 'Sí',
        },
      ],
    },

    //carga de datos
    loading: {
      init_clientes: true,
      clientes: true,
      exportar: false,
      tienda: true,
      error: false,
    },

    //modales
    modals: {
      revocar: false,
      ver_informacion: false,
      editar: false,
      bloquear: false,
      desbloquear: false,
    },

    //forms
    forms: {
      revocar: false,
      editar: false,
      bloquear: false,
      desbloquear: false,
    },

    //alerta
    alert: {
      open: false,
      type: 'error',
      message: '',
    },

    //sidenav
    filters_sidenav: false,

    //Tipo de sidenav según el botón
    sidenav_type: 'filtrar',

    //Datos de ventas para exportar
    data_exportar: [],
  };
  debounceSearch = null;

  obtenerParametros = (type) => {
    let filter = this.state.filter;

    let body = {
      ventas: 1,
    };
    if (type === 'filtrar') body = { ...body, page: filter.page };
    if (filter.cl_mayorista.value !== -2)
      body = { ...body, cl_mayorista: filter.cl_mayorista.value };
    if (filter.cl_bloqueado.value !== -1)
      body = { ...body, cl_bloqueado: filter.cl_bloqueado.value };
    if (filter.cl_registro_completo.value !== -1)
      body = {
        ...body,
        cl_registro_completo: filter.cl_registro_completo.value,
      };
    if (filter.search.value !== '')
      body = { ...body, search: filter.search.value };

    return body;
  };

  componentDidMount() {
    this.debounceMethod = debounce(this.cargarClientes, 500);
    this.props.tiendaObtener((err, resp) => {
      this.setState({
        loading: {
          ...this.state.loading,
          tienda: false,
          error: this.state.loading.error || err ? true : false,
        },
      });
    });
    this.cargarClientes();
  }

  cargarClientes = () => {
    this.setState({
      loading: {
        ...this.state.loading,
        clientes: true,
      },
      load_more: {
        loading: this.state.filter.page > 0 ? true : false,
        show: this.state.filter.page > 0 ? true : false,
      },
    });

    this.props.clienteObtenerClientes(
      this.obtenerParametros('filtrar'),
      (err, resp) => {
        let cantidad_resultados = resp.data.length;

        this.setState({
          loading: {
            ...this.state.loading,
            init_clientes: false,
            clientes: false,
            error: this.state.loading.error || err ? true : false,
          },
          load_more: {
            loading: false,
            show: cantidad_resultados === 15,
          },
          filter: {
            ...this.state.filter,
            page: cantidad_resultados === 15 ? this.state.filter.page + 1 : 0,
          },
        });
      }
    );
  };

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    if (aditional === 'filter') {
      //input de search
      if (!blur) {
        this.setState(
          {
            [aditional]: {
              ...this.state[aditional],
              [name]: {
                ...this.state[aditional][name],
                value: value,
              },
              page: 0,
            },
          },
          () => this.debounceMethod()
        );
      }
    } else {
      //inputs comunes
      this.setState(
        {
          [aditional]: {
            ...this.state[aditional],
            [name]: {
              ...this.state[aditional][name],
              value: value,
            },
          },
        },
        () => {
          Validate.validate_input(
            this.state[aditional][name],
            blur,
            (input) => {
              this.setState({
                [aditional]: {
                  ...this.state[aditional],
                  [name]: input,
                },
              });
            }
          );
        }
      );
    }
  };

  handleChangeSelect = (e, aditional) => {
    let name = e.target.name;
    let value =
      name === 'cl_tipo_identificacion' || name === 'cl_provincia'
        ? Validate.trim(e.target.value)
        : Validate.trim_int(e.target.value);

    this.setState({
      [aditional]: {
        ...this.state[aditional],
        [name]: {
          ...this.state[aditional][name],
          value: value,
        },
      },
    });
  };

  limpiarFiltros = () => {
    this.setState({
      filter: {
        ...this.state.filter,
        cl_mayorista: {
          ...this.state.filter.cl_mayorista,
          value: -2,
        },
        cl_bloqueado: {
          ...this.state.filter.cl_bloqueado,
          value: -1,
        },
        cl_registro_completo: {
          ...this.state.filter.cl_registro_completo,
          value: 1,
        },
        search: {
          ...this.state.filter.search,
          value: '',
        },
        page: 0,
      },
    });
  };

  //submits
  handleSubmitRevocar = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        revocar: true,
      },
    });

    let form = this.state.revocar;

    this.props.clienteRevocarMayoristaAccion(
      { idClientes: form.idClientes },
      (err, resp) => {
        if (err) {
          this.setState({
            forms: {
              ...this.state.forms,
              revocar: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'error',
            },
          });
        } else {
          this.setState({
            forms: {
              ...this.state.forms,
              revocar: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'success',
            },
            modals: {
              ...this.state.modals,
              revocar: false,
            },
          });
        }
      }
    );
  };

  handleSubmitEditar = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        editar: true,
      },
    });
    let form = this.state.editar;

    if (Validate.validar_formulario(form)) {
      this.props.clienteEditarCliente(
        {
          idClientes: form.idClientes.value,
          cl_nombre: form.cl_nombre.value,
          cl_apellido: form.cl_apellido.value,
          cl_email: form.cl_email.value,
          cl_mayorista: form.cl_mayorista.value,
          cl_numero_identificacion: form.cl_numero_identificacion.value,
          cl_tipo_identificacion: form.cl_tipo_identificacion.value,
          cl_telefono: form.cl_telefono.value,
          cl_calle: form.cl_calle.value,
          cl_numero: form.cl_numero.value,
          cl_piso: form.cl_piso.value,
          cl_dpto: form.cl_dpto.value,
          cl_ciudad: form.cl_ciudad.value,
          cl_provincia: form.cl_provincia.value,
        },
        (err, resp) => {
          if (err) {
            this.setState({
              forms: {
                ...this.state.forms,
                editar: false,
              },
              alert: {
                open: true,
                message: resp.message,
                type: 'error',
              },
            });
          } else {
            this.setState({
              forms: {
                ...this.state.forms,
                editar: false,
              },
              alert: {
                open: true,
                message: resp.message,
                type: 'success',
              },
              modals: {
                ...this.state.modals,
                editar: false,
              },
            });
          }
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          editar: new_form,
          forms: {
            ...this.state.forms,
            editar: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: 'Por favor, revisá tus datos, tenés algunos errores',
            type: 'error',
          },
        });
      });
    }
  };

  handleSubmitBloquear = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        bloquear: true,
      },
    });

    let form = this.state.bloquear;

    this.props.clienteBloquearCliente(
      { idClientes: form.idClientes },
      (err, resp) => {
        if (err) {
          this.setState({
            forms: {
              ...this.state.forms,
              bloquear: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'error',
            },
          });
        } else {
          this.setState({
            forms: {
              ...this.state.forms,
              bloquear: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'success',
            },
            modals: {
              ...this.state.modals,
              bloquear: false,
            },
          });
        }
      }
    );
  };

  handleSubmitDesbloquear = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        desbloquear: true,
      },
    });

    let form = this.state.desbloquear;

    this.props.clienteDesbloquearCliente(
      { idClientes: form.idClientes },
      (err, resp) => {
        if (err) {
          this.setState({
            forms: {
              ...this.state.forms,
              desbloquear: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'error',
            },
          });
        } else {
          this.setState({
            forms: {
              ...this.state.forms,
              desbloquear: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'success',
            },
            modals: {
              ...this.state.modals,
              desbloquear: false,
            },
          });
        }
      }
    );
  };

  //abrir y cerrar drawer
  handleDrawer = (open, type) => {
    this.setState({
      filters_sidenav: open,
      sidenav_type: type,
      data_exportar: this.state.filters_sidenav === false && [],
    });
  };

  //abre y cierra los modales
  modalHandler = (open, id, aditional) => {
    let cliente;
    switch (id) {
      case 'revocar':
        cliente = aditional;
        this.setState({
          modals: {
            ...this.state.modals,
            [id]: open,
          },
          revocar: {
            idClientes: cliente.idClientes,
            cl_nombre: cliente.cl_nombre,
            cl_apellido: cliente.cl_apellido,
          },
        });

        break;
      case 'ver_informacion':
        cliente = aditional;

        this.setState({
          modals: {
            ...this.state.modals,
            [id]: open,
          },
          ver_informacion: {
            idClientes: cliente.idClientes,
            cl_email: cliente.cl_email,
            cl_mayorista: cliente.cl_mayorista,
            cl_nombre: cliente.cl_nombre,
            cl_apellido: cliente.cl_apellido,
            cl_telefono: cliente.cl_telefono,
            cl_tipo_identificacion: cliente.cl_tipo_identificacion,
            cl_numero_identificacion: cliente.cl_numero_identificacion,
            cl_calle: cliente.cl_calle,
            cl_numero: cliente.cl_numero,
            cl_piso: cliente.cl_piso,
            cl_dpto: cliente.cl_dpto,
            cl_ciudad: cliente.cl_ciudad,
            cl_provincia: cliente.cl_provincia,
            cl_pais: cliente.cl_pais,
          },
        });

        break;

      case 'editar':
        if (open) {
          let cliente = aditional;

          Validate.cargar_formulario_sin_nulos(
            cliente,
            Validate.limpiar_formulario(this.state.editar),
            (editar_obj) => {
              this.setState({
                editar: editar_obj,
                modals: {
                  ...this.state.modals,
                  [id]: open,
                },
              });
            }
          );
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              [id]: open,
            },
          });
        }
        break;

      case 'bloquear':
        cliente = aditional;
        this.setState({
          modals: {
            ...this.state.modals,
            [id]: open,
          },
          bloquear: {
            idClientes: cliente.idClientes,
            cl_nombre: cliente.cl_nombre,
            cl_apellido: cliente.cl_apellido,
          },
        });

        break;

      case 'desbloquear':
        cliente = aditional;
        this.setState({
          modals: {
            ...this.state.modals,
            [id]: open,
          },
          desbloquear: {
            idClientes: cliente.idClientes,
            cl_nombre: cliente.cl_nombre,
            cl_apellido: cliente.cl_apellido,
          },
        });

        break;

      default:
        this.setState({
          modals: {
            ...this.state.modals,
            [id]: open,
          },
        });

        break;
    }
  };

  //cierra las alertas
  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  exportarClientes = () => {
    this.setState({
      loading: {
        ...this.state.loading,
        exportar: true,
      },
      data_exportar: [],
    });

    this.props.clienteObtenerClientesExportar(
      this.obtenerParametros('exportar'),
      (err, resp) => {
        if (err) {
          this.setState({
            loading: {
              ...this.state.loading,
              init_clientes: false,
              clientes: false,
              exportar: false,
              error: this.state.loading.error || err ? true : false,
            },
            alert: {
              ...this.state.alert,
              open: true,
              message: resp.message,
              type: 'error',
            },
          });
        } else {
          let state = {
            loading: {
              ...this.state.loading,
              init_clientes: false,
              clientes: false,
              exportar: false,
              error: this.state.loading.error || err ? true : false,
            },
            data_exportar: resp.data,
          };

          if (resp.data.length === 0) {
            state = {
              ...state,
              alert: {
                ...this.state.alert,
                open: true,
                message:
                  'No se han encontrado resultados para los filtros seleccionados',
                type: 'error',
              },
            };
          }

          this.setState(state);
        }
      }
    );
  };

  handleSubmitFilter = (type) => {
    type === 'filtrar'
      ? this.setState(
          {
            filter: {
              ...this.state.filter,
              page: 0,
            },
          },
          () => {
            this.cargarClientes();
          }
        )
      : this.exportarClientes();
  };

  render() {
    return (
      <WrapperLayout
        loading={this.state.loading.tienda || this.state.loading.init_clientes}
        error={this.state.loading.error}
      >
        <ModalRevocar
          revocar={this.state.revocar}
          handleSubmit={this.handleSubmitRevocar}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.revocar}
          form_state={this.state.forms.revocar}
        />
        <ModalVerInformacion
          ver_informacion={this.state.ver_informacion}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.ver_informacion}
        />
        <ModalEditar
          editar={this.state.editar}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.editar}
          form_state={this.state.forms.editar}
          handleChangeSelect={this.handleChangeSelect}
          handleChange={this.handleChange}
          selects={this.state.selects}
          handleSubmit={this.handleSubmitEditar}
        />
        <ModalBloquear
          bloquear={this.state.bloquear}
          handleSubmit={this.handleSubmitBloquear}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.bloquear}
          form_state={this.state.forms.bloquear}
        />
        <ModalDesbloquear
          bloquear={this.state.desbloquear}
          handleSubmit={this.handleSubmitDesbloquear}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.desbloquear}
          form_state={this.state.forms.desbloquear}
        />
        <AlertUI
          open={this.state.alert.open}
          type={this.state.alert.type}
          message={this.state.alert.message}
          handleCloseAlert={this.handleCloseAlert}
        />
        <FilterSidenav
          filter={this.state.filter}
          selects={this.state.selects}
          isFiltersDrawerOpen={this.state.filters_sidenav}
          handleDrawer={this.handleDrawer}
          handleChangeSelect={this.handleChangeSelect}
          limpiarFiltros={this.limpiarFiltros}
          handleChangeDate={this.handleChangeDate}
          handleSubmitFilter={this.handleSubmitFilter}
          type={this.state.sidenav_type}
          loading={this.state.loading}
          data_exportar={this.state.data_exportar}
        />
        <ModuleTitleUI title="Clientes" />
        <Box sx={{ marginTop: '30px' }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item lg={5} md={6} xs={12} sx={{ paddingBottom: '4px' }}>
              <InputUI
                handleChange={this.handleChange}
                input={this.state.filter.search}
                end_adornment={<Icon>search</Icon>}
              />
            </Grid>
            <Grid item lg={2} md={3} xs={6}>
              <Button
                onClick={(e) => this.handleDrawer(true, 'exportar')}
                variant="outlined"
                color="info"
                sx={{ height: '56px', borderColor: 'rgba(0,0,0,0.23)' }}
                size="large"
                startIcon={<DownloadIcon />}
                fullWidth
              >
                Exportar
              </Button>
            </Grid>
            <Grid item lg={2} md={3} xs={6}>
              <Button
                onClick={(e) => this.handleDrawer(true, 'filtrar')}
                variant="outlined"
                color="info"
                sx={{ height: '56px', borderColor: 'rgba(0,0,0,0.23)' }}
                size="large"
                startIcon={<FilterListIcon />}
                fullWidth
              >
                Filtrar
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ margin: '20px 0px 30px 0px' }}>
          {this.state.filter.page === 0 && this.state.loading.clientes ? (
            <LinearProgress />
          ) : (
            <ClientesList modalHandler={this.modalHandler} />
          )}
        </Box>
        <ToggleUI show={this.state.load_more.show}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <ButtonMui
                disabled={this.state.load_more.loading}
                onClick={this.cargarClientes}
                isLoading={this.state.load_more.loading}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Ver más
              </ButtonMui>
            </Grid>
          </Grid>
        </ToggleUI>
      </WrapperLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
    clientes: state.clientes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clienteObtenerClientes: (data, callback) =>
      dispatch(clienteObtenerClientes(data, callback)),
    clienteObtenerClientesExportar: (data, callback) =>
      dispatch(clienteObtenerClientesExportar(data, callback)),
    clienteRevocarMayoristaAccion: (data, callback) =>
      dispatch(clienteRevocarMayoristaAccion(data, callback)),
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    clienteEditarCliente: (data, callback) =>
      dispatch(clienteEditarCliente(data, callback)),
    clienteBloquearCliente: (data, callback) =>
      dispatch(clienteBloquearCliente(data, callback)),
    clienteDesbloquearCliente: (data, callback) =>
      dispatch(clienteDesbloquearCliente(data, callback)),
  };
};

export const Clientes = connect(mapStateToProps, mapDispatchToProps)(Clientes_);
