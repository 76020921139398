import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  tiendaObtener,
  tiendaActualizarProductosDigitales,
} from '../../store/actions';
import { Grid } from '@mui/material';
import { ButtonUI, AlertUI, ModuleTitleUI } from '../../components/common';
import { WrapperLayout } from '../../layouts/WrapperLayout';

const DigitalProductsPage_ = ({
  tienda,
  tiendaObtener,
  tiendaActualizarProductosDigitales,
}) => {
  const [isShopLoading, setIsShopLoading] = useState(true);

  const [errorLoadingShop, setErrorLoadingShop] = useState(false);

  const [isDigitalProductsLoading, setIsDigitalProductsLoading] =
    useState(false);

  const [alert, setAlert] = useState({
    open: false,
    type: 'error',
    message: '',
  });

  useEffect(() => {
    tiendaObtener((err, _resp) => {
      if (err) {
        setErrorLoadingShop(true);
      }
      setIsShopLoading(false);
    });
  }, [tiendaObtener]);

  const handleCloseAlert = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsDigitalProductsLoading(true);

    tiendaActualizarProductosDigitales(
      {
        t_productos_digitales: tienda.t_productos_digitales ? 0 : 1,
      },
      (err, resp) => {
        setIsDigitalProductsLoading(false);
        setAlert({
          open: true,
          message: resp.message,
          type: err ? 'error' : 'success',
        });
      }
    );
  };

  return (
    <WrapperLayout loading={isShopLoading} error={errorLoadingShop}>
      <AlertUI
        open={alert.open}
        message={alert.message}
        type={alert.type}
        handleCloseAlert={handleCloseAlert}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ModuleTitleUI
            title="Productos digitales"
            subtitles={[
              'Esta función te permite vender productos digitales en tu tienda online. Cuando se activa, cambia el formulario para agregar un producto.',
              'Deberás seleccionar el tipo de producto, físico o dígital.',
              'En caso de que sea un producto digital, podrás definir si es con descarga automática (se agrega el link que se envía automáticamente) o manual con un plazo de entrega.',
              'Además, tendrás la posibilidad de agregar una observación adicional que se envía con el mail de confirmación de compra.',
            ]}
            button={
              <ButtonUI
                type="callf"
                label={tienda.t_productos_digitales ? 'Desactivar' : 'Activar'}
                minHeight={true}
                fullWidth={false}
                onClickFunc={handleSubmit}
                isLoading={isDigitalProductsLoading}
              />
            }
          />
        </Grid>
      </Grid>
    </WrapperLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    tiendaActualizarProductosDigitales: (data, callback) =>
      dispatch(tiendaActualizarProductosDigitales(data, callback)),
  };
};

export const DigitalProductsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(DigitalProductsPage_);
