import { useAuth0 } from '@auth0/auth0-react';
import React, { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Splash } from '../../common/Splash';

const LoginStep = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const router = useHistory();
  const searchParams = new URLSearchParams(router.location.search);
  const idCountry = searchParams.get('idCountry') || 'ARG';

  useLayoutEffect(() => {
    if (isAuthenticated) {
      router.push('/');
    } else {
      localStorage.removeItem('showSuscriptionBanner');
      localStorage.removeItem('show-message-tips');
      loginWithRedirect({
        authorizationParams: {
          idCountry: idCountry,
        },
      });
    }
  }, [isAuthenticated]);

  return <>{isLoading ? <Splash /> : ''}</>;
};

export { LoginStep };
