import React from 'react';
import { withStyles } from '@mui/styles';
import { CircularProgress, Typography } from '@mui/material';

const BuilderLoader_ = ({ classes, message }) => {
  return (
    <div className={classes.root}>
      <div className={classes.loading}>
        <CircularProgress color="primary" />
        <Typography classes={{ root: classes.loading_text }}>
          {message}
        </Typography>
      </div>
    </div>
  );
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    margin: '0 auto',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  loading_text: {
    marginTop: '20px',
    textAlign: 'center',
  },
});

export const BuilderLoader = withStyles(styles, { withTheme: true })(
  BuilderLoader_
);
