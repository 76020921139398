import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  enviopersonalizadoObtenerEnviosPersonalizados,
  enviopersonalizadoBorrar,
  metodosenvioObtener,
  metodoenvioActivarEnvioPersonalizado,
  metodoenvioDesactivarEnvioPersonalizado,
} from '../../../store/actions';
import { Card, CardHeader, CardContent, Divider } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ButtonUI, AlertUI, ModuleTitleUI, ToggleUI } from '../../common';
import { EnviosPersonalizadosList, ModalBorrar } from './subcomponents';
import {
  method_shipment_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../amplitude';
import * as braze from '@braze/web-sdk';
import { WrapperLayout } from '../../../layouts/WrapperLayout';

class EnvioPersonalizado_ extends Component {
  state = {
    //carga de datos
    loading: {
      metodos_envio: true,
      envio_personalizado: true,
      error: false,
    },

    modals: {
      borrar: false,
    },

    borrar: {
      idEnviosPersonalizados: null,
      ep_nombre: '',
    },

    //forms
    forms: {
      activar_envio_personalizado: false,
      desactivar_envio_personalizado: false,
      borrar: false,
    },

    //alerta
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  componentDidMount() {
    this.props.metodosenvioObtener((err, resp) => {
      this.setState({
        loading: {
          ...this.state.loading,
          metodos_envio: false,
          error: this.state.loading.error || err ? true : false,
        },
      });
    });
    this.props.enviopersonalizadoObtenerEnviosPersonalizados((err, resp) => {
      this.setState({
        loading: {
          ...this.state.loading,
          envio_personalizado: false,
          error: this.state.loading.error || err ? true : false,
        },
      });
    });
  }

  //submits
  handleActivarEnvioPersonalizadoSubmit = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        activar_envio_personalizado: true,
      },
    });

    this.props.metodoenvioActivarEnvioPersonalizado((err, resp) => {
      this.setState(
        {
          forms: {
            ...this.state.forms,
            activar_envio_personalizado: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          },
        },
        () => {
          if (!err) {
            createAmplitudeEventWithDevice(
              method_shipment_amplitude_events.metodos_envio_activar.event_name,
              {
                metodo_envio: 'envios_personalizados',
                success: true,
              }
            );

            if (this.props.idCountry === 'ARG') {
              braze.logCustomEvent('empretienda_metodos_envío_activar', {
                metodo_envio: 'envios_personalizados',
              });
            }
          } else {
            createAmplitudeEventWithDevice(
              method_shipment_amplitude_events.metodos_envio_activar.event_name,
              {
                metodo_envio: 'envios_personalizados',
                success: false,
              }
            );
          }
        }
      );
    });
  };
  handleDesactivarEnvioPersonalizadoSubmit = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        desactivar_envio_personalizado: true,
      },
    });

    this.props.metodoenvioDesactivarEnvioPersonalizado((err, resp) => {
      this.setState(
        {
          forms: {
            ...this.state.forms,
            desactivar_envio_personalizado: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          },
        },
        () => {
          if (!err) {
            createAmplitudeEventWithDevice(
              method_shipment_amplitude_events.metodos_envio_desactivar
                .event_name,
              { metodo_envio: 'envios_personalizados' }
            );
          } else {
            createAmplitudeEventWithDevice(
              method_shipment_amplitude_events.metodos_envio_desactivar
                .event_name,
              { metodo_envio: 'envios_personalizados' }
            );
          }
        }
      );
    });
  };
  handleSubmitBorrar = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        borrar: true,
      },
    });

    let form = this.state.borrar;

    this.props.enviopersonalizadoBorrar(
      { idEnviosPersonalizados: form.idEnviosPersonalizados },
      (err, resp) => {
        if (err) {
          this.setState({
            forms: {
              ...this.state.forms,
              borrar: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'error',
            },
          });
        } else {
          this.setState({
            forms: {
              ...this.state.forms,
              borrar: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'success',
            },
            modals: {
              ...this.state.modals,
              borrar: false,
            },
          });
        }
      }
    );
  };

  //cierra las alertas
  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  //abre y cierra los modales
  modalHandler = (open, id, aditional) => {
    switch (id) {
      case 'borrar':
        if (open) {
          let enviope = aditional;
          this.setState({
            borrar: {
              ...this.state.borrar,
              idEnviosPersonalizados: enviope.idEnviosPersonalizados,
              ep_nombre: enviope.ep_nombre,
            },
            modals: {
              ...this.state.modals,
              [id]: open,
            },
          });
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              [id]: open,
            },
          });
        }
        break;
      default:
        break;
    }
  };

  render() {
    const { classes } = this.props;

    const activar_desactivar_btn = this.props.metodos_envio
      .me_personalizados ? (
      <ButtonUI
        type="callf"
        label="Desactivar"
        fullWidth={false}
        onClickFunc={this.handleDesactivarEnvioPersonalizadoSubmit}
        isLoading={this.state.forms.desactivar_envio_personalizado}
        minHeight={true}
      />
    ) : (
      <ButtonUI
        type="callf"
        label="Activar"
        fullWidth={false}
        onClickFunc={this.handleActivarEnvioPersonalizadoSubmit}
        isLoading={this.state.forms.activar_envio_personalizado}
        minHeight={true}
      />
    );

    return (
      <WrapperLayout
        loading={
          this.state.loading.envio_personalizado ||
          this.state.loading.metodos_envio
        }
        error={this.state.loading.error}
      >
        <AlertUI
          open={this.state.alert.open}
          type={this.state.alert.type}
          message={this.state.alert.message}
          handleCloseAlert={this.handleCloseAlert}
        />
        <ModalBorrar
          borrar={this.state.borrar}
          handleSubmit={this.handleSubmitBorrar}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.borrar}
          form_state={this.state.forms.borrar}
        />
        <ModuleTitleUI
          title="Envíos personalizados"
          subtitles={[
            'Aquí podrás configurar tus propios medios de envío.',
            'Podrás determinar un nombre, descripción, precio y forma de pago.',
            'Podrás configurar su disponibilidad en función del precio, peso y ubicación geográfica.',
          ]}
          button={activar_desactivar_btn}
        />
        <ToggleUI
          show={this.props.metodos_envio.me_personalizados ? true : false}
        >
          <Card className={classes.card_below}>
            <CardHeader
              title="Métodos activos"
              className={classes.card_header}
              classes={{ action: classes.card_action_button }}
              action={
                <ButtonUI
                  type="link"
                  label="Agregar"
                  link="/metodos-envio/envios-personalizados/agregar"
                />
              }
            />
            <Divider />
            <CardContent className={classes.card_content}>
              <EnviosPersonalizadosList modalHandler={this.modalHandler} />
            </CardContent>
          </Card>
        </ToggleUI>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_below: {
    ...theme.card.card,
    marginTop: '40px',
  },
  card_header: theme.card.card_header,
  card_content: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  card_action_button: theme.card.card_action_button,
});

const mapStateToProps = (state) => {
  return {
    metodos_envio: state.metodos_envio,
    idCountry: state.tienda.Country_idCountry,
    envio_personalizado: state.envio_personalizado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    enviopersonalizadoObtenerEnviosPersonalizados: (callback) =>
      dispatch(enviopersonalizadoObtenerEnviosPersonalizados(callback)),
    metodosenvioObtener: (callback) => dispatch(metodosenvioObtener(callback)),
    metodoenvioActivarEnvioPersonalizado: (callback) =>
      dispatch(metodoenvioActivarEnvioPersonalizado(callback)),
    metodoenvioDesactivarEnvioPersonalizado: (callback) =>
      dispatch(metodoenvioDesactivarEnvioPersonalizado(callback)),
    enviopersonalizadoBorrar: (data, callback) =>
      dispatch(enviopersonalizadoBorrar(data, callback)),
  };
};

export const EnvioPersonalizado = withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(EnvioPersonalizado_)
);
