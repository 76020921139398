import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Grid } from '@mui/material';
import {
  metodospagoObtener,
  metodopagoActivarMercadoPago,
  metodopagoDesactivarMercadoPago,
  metodopagoActivarTodoPago,
  metodopagoDesactivarTodoPago,
  metodopagoActivarUala,
  metodopagoDesactivarUala,
  metodopagoActivarDeposito,
  metodopagoDesactivarDeposito,
  metodopagoActivarEfectivo,
  metodopagoDesactivarEfectivo,
  metodopagoActivarAcordar,
  metodopagoDesactivarAcordar,
  metodopagoObtenerUrlMobbex,
  metodopagoActivarMobbex,
  metodopagoDesactivarMobbex,
} from '../../store/actions';
import { Regex, RegexExtra, Validate } from '../../other';
import { AlertUI, ToggleUI } from '../common';
import {
  MercadoPago,
  TodoPago,
  UalaBis,
  Efectivo,
  Mobbex,
  Acordar,
  Deposito,
} from './subcomponents';
import {
  TodoPagoModal,
  DepositoModal,
  EfectivoModal,
  UalaModal,
} from './modals';
import { createAmplitudeEventWithDevice, amplitude_events } from '../amplitude';
import * as braze from '@braze/web-sdk';
import { withStyles } from '@mui/styles';
import { WrapperLayout } from '../../layouts/WrapperLayout';

class MetodosPago_ extends Component {
  state = {
    activar_editar_todopago: {
      mp_todopago_merchant_id: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nro. de Comercio (Merchant id)',
        id: 'mp_todopago_merchant_id',
        name: 'mp_todopago_merchant_id',
        change_param: 'activar_editar_todopago',
        regex: Regex.TODOPAGO_MERCHANT_ID,
        value: '',
        default_value: '',
        placeholder: '123456',
        messages: {
          error: 'Por favor, ingrese un número de comercio válido',
          error_extra: RegexExtra.TODOPAGO_MERCHANT_ID,
        },
      },
      mp_todopago_api_key: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Credenciales (API Key)',
        id: 'mp_todopago_api_key',
        name: 'mp_todopago_api_key',
        change_param: 'activar_editar_todopago',
        regex: Regex.TODOPAGO_API_KEY,
        value: '',
        default_value: '',
        placeholder: 'TODOPAGO 323a4da4cc184d1f4b5d2b963c5342e2',
        messages: {
          error: 'Por favor, ingrese un api key válido',
          error_extra: RegexExtra.TODOPAGO_API_KEY,
        },
      },
    },
    activar_editar_uala: {
      mp_uala_username: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Usuario (username)',
        id: 'mp_uala_username',
        name: 'mp_uala_username',
        change_param: 'activar_editar_uala',
        regex: Regex.STRING_GENERICO_2_90,
        value: '',
        default_value: '',
        placeholder: '',
        messages: {
          error: 'Por favor, ingrese un usuario válido',
          error_extra: RegexExtra.STRING_GENERICO_2_90,
        },
      },
      mp_uala_client_id: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Identificador de usuario (clientId)',
        id: 'mp_uala_client_id',
        name: 'mp_uala_client_id',
        change_param: 'activar_editar_uala',
        regex: Regex.STRING_GENERICO_5_90,
        value: '',
        default_value: '',
        placeholder: '',
        messages: {
          error: 'Por favor, ingrese un identificador de usuario válido',
          error_extra: RegexExtra.STRING_GENERICO_5_90,
        },
      },
      mp_uala_client_secret_id: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Identificador secreto (clienteSecret)',
        id: 'mp_uala_client_secret_id',
        name: 'mp_uala_client_secret_id',
        change_param: 'activar_editar_uala',
        regex: Regex.STRING_GENERICO_5_90,
        value: '',
        default_value: '',
        placeholder: '',
        messages: {
          error: 'Por favor, ingrese un identificador secreto válido',
          error_extra: RegexExtra.STRING_GENERICO_5_90,
        },
      },
    },
    activar_editar_efectivo: {
      mp_efectivo_descuento: {
        validate: false,
        required: true,
        error: false,
        label: 'Aplicar descuento por pago en efectivo',
        id: 'mp_efectivo_descuento',
        name: 'mp_efectivo_descuento',
        change_param: 'activar_editar_efectivo',
        value: 0,
        messages: {
          help: 'Aplica un descuento al monto de la venta por elegir este método de pago',
          error: '',
        },
      },
      mp_efectivo_porcentaje: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Porcentaje de descuento',
        id: 'mp_efectivo_porcentaje',
        name: 'mp_efectivo_porcentaje',
        change_param: 'activar_editar_efectivo',
        regex: Regex.NUMERO_ENTERO,
        value: '',
        default_value: '',
        placeholder: '10',
        messages: {
          error: 'Por favor, ingrese un porcentaje válido',
          error_extra: RegexExtra.NUMERO_ENTERO,
        },
      },
      mp_efectivo_descripcion: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Descripción',
        id: 'mp_efectivo_descripcion',
        name: 'mp_efectivo_descripcion',
        change_param: 'activar_editar_efectivo',
        regex: Regex.DESCRIPCION_0_200,
        value: '',
        default_value: '',
        placeholder: '',
        messages: {
          help: 'Esta descripción le aparecerá al cliente al momento de seleccionar el método de pago',
          error: 'Por favor, ingrese una descripción válida',
          error_extra: RegexExtra.DESCRIPCION_0_200,
        },
      },
    },
    activar_editar_deposito: {
      mp_deposito_datos: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Datos bancarios',
        id: 'mp_deposito_datos',
        name: 'mp_deposito_datos',
        change_param: 'activar_editar_deposito',
        regex: Regex.DESCRIPCION_20_500,
        value: '',
        default_value: '',
        placeholder: ' ',
        messages: {
          error: 'Por favor, ingrese sus datos bancarios',
          help: 'Serán enviados al cliente por email luego de realizar la compra',
          error_extra: RegexExtra.DESCRIPCION_20_500,
        },
      },
      mp_deposito_descripcion: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Descripción',
        id: 'mp_deposito_descripcion',
        name: 'mp_deposito_descripcion',
        change_param: 'activar_editar_deposito',
        regex: Regex.DESCRIPCION_0_200,
        value: '',
        default_value: '',
        placeholder: ' ',
        messages: {
          error: 'Por favor, ingrese una descripción válida',
          help: 'Esta información será visualizada por el cliente al seleccionar el método de pago',
          error_extra: RegexExtra.DESCRIPCION_0_200,
        },
      },
      mp_deposito_comprobante: {
        validate: false,
        name: 'mp_deposito_comprobante',
        label:
          'Requerirle al cliente que cargue un comprobante del depósito (En el correo de detalle de orden se agregará un texto en el que se solicita el envío del comprobante).',
        value: 0,
        change_param: 'activar_editar_deposito',
      },
      mp_deposito_descuento: {
        validate: false,
        required: true,
        error: false,
        label: 'Aplicar descuento por pago en transferencia',
        id: 'mp_deposito_descuento',
        name: 'mp_deposito_descuento',
        change_param: 'activar_editar_deposito',
        value: 0,
        messages: {
          help: 'Aplica un descuento al monto de la venta por elegir este método de pago',
          error: '',
        },
      },
      mp_deposito_porcentaje: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Porcentaje de descuento',
        id: 'mp_deposito_porcentaje',
        name: 'mp_deposito_porcentaje',
        change_param: 'activar_editar_deposito',
        regex: Regex.NUMERO_ENTERO,
        value: '',
        default_value: '',
        placeholder: '10',
        messages: {
          error: 'Por favor, ingrese un porcentaje válido',
          error_extra: RegexExtra.NUMERO_ENTERO,
        },
      },
    },
    loading: {
      metodos_pago: true,
      tienda: true,
      error: false,
    },
    forms: {
      desactivar_mercadopago: false,
      desactivar_todopago: false,
      activar_editar_todopago: false,
      desactivar_uala: false,
      activar_editar_uala: false,
      desactivar_deposito: false,
      activar_editar_deposito: false,
      activar_editar_efectivo: false,
      desactivar_efectivo: false,
      activar_acordar: false,
      desactivar_acordar: false,
      obtener_url_mobbex: false,
      desactivar_mobbex: false,
    },
    modals: {
      activar_editar_todopago: false,
      activar_editar_uala: false,
      activar_editar_efectivo: false,
      activar_editar_deposito: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    selects: {
      opciones_mp_efectivo_descuento: [
        {
          value: 0,
          label: 'No',
        },
        {
          value: 1,
          label: 'Si',
        },
      ],
      opciones_mp_deposito_descuento: [
        {
          value: 0,
          label: 'No',
        },
        {
          value: 1,
          label: 'Si',
        },
      ],
    },
  };

  componentDidMount() {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams.code) {
      let code = queryParams.code;
      this.props.metodopagoActivarMercadoPago({ code: code }, (err, resp) => {
        this.setState(
          {
            loading: {
              ...this.state.loading,
              metodos_pago: false,
              error: err ? true : false,
            },
            alert: {
              ...this.state.alert,
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            },
          },
          () => {
            if (!err) {
              createAmplitudeEventWithDevice(
                amplitude_events.activar_metodo_pago.event_name,
                { metodo_pago: 'mercado_pago' }
              );

              if (this.props.idCountry === 'ARG') {
                braze.logCustomEvent('empretienda_metodos_pago_activar', {
                  metodo_pago: 'mercado_pago',
                });
              }
            }
          }
        );
      });
    } else if (queryParams.connectId && queryParams.connectStatus) {
      if (queryParams.connectStatus === 'done') {
        let code = queryParams.connectId;
        this.props.metodopagoActivarMobbex(
          { connect_id: code },
          (err, resp) => {
            this.setState(
              {
                loading: {
                  ...this.state.loading,
                  metodos_pago: false,
                  error: err ? true : false,
                },
                alert: {
                  ...this.state.alert,
                  open: true,
                  message: resp.message,
                  type: err ? 'error' : 'success',
                },
              },
              () => {
                if (!err) {
                  createAmplitudeEventWithDevice(
                    amplitude_events.activar_metodo_pago.event_name,
                    { metodo_pago: 'mobbex' }
                  );

                  if (this.props.idCountry === 'ARG') {
                    braze.logCustomEvent('empretienda_metodos_pago_activar', {
                      metodo_pago: 'mobbex',
                    });
                  }
                }
              }
            );
          }
        );
      } else {
        this.setState({
          loading: {
            ...this.state.loading,
            metodos_pago: false,
            error: true,
          },
        });
      }
    } else {
      this.props.metodospagoObtener((err, resp) => {
        this.setState({
          loading: {
            ...this.state.loading,
            metodos_pago: false,
            error: err ? true : false,
          },
        });
      });
    }
  }

  componentDidUpdate() {
    if (
      this.props &&
      this.state.loading.tienda &&
      this.props.tienda &&
      this.props.tienda.Country_idCountry
    ) {
      const idCountry = this.props.tienda.Country_idCountry;

      const mp_uala_username_regex =
        idCountry === 'COL' ? Regex.EMAIL : Regex.STRING_GENERICO_2_90;

      const mp_uala_username_messages = {
        error:
          idCountry === 'COL'
            ? 'Por favor, ingresa un email válido'
            : 'Por favor, ingrese un usuario válido',
        error_extra: idCountry === 'COL' ? '' : RegexExtra.STRING_GENERICO_2_90,
      };

      let mp_uala_client_id_messages_error =
        this.state.activar_editar_uala.mp_uala_client_id.messages.error;
      let mp_uala_client_secret_id_messages_error =
        this.state.activar_editar_uala.mp_uala_client_secret_id.messages.error;

      if (idCountry === 'COL') {
        mp_uala_client_id_messages_error =
          mp_uala_client_id_messages_error.replace('ingrese', 'ingresa');
        mp_uala_client_secret_id_messages_error =
          mp_uala_client_secret_id_messages_error.replace('ingrese', 'ingresa');
      }

      this.setState({
        activar_editar_uala: {
          ...this.state.activar_editar_uala,
          mp_uala_username: {
            ...this.state.activar_editar_uala.mp_uala_username,
            regex: mp_uala_username_regex,
            messages: mp_uala_username_messages,
          },
          mp_uala_client_id: {
            ...this.state.activar_editar_uala.mp_uala_client_id,
            messages: {
              ...this.state.activar_editar_uala.mp_uala_client_id.messages,
              error: mp_uala_client_id_messages_error,
            },
          },
          mp_uala_client_secret_id: {
            ...this.state.activar_editar_uala.mp_uala_client_secret_id,
            messages: {
              ...this.state.activar_editar_uala.mp_uala_client_secret_id
                .messages,
              error: mp_uala_client_secret_id_messages_error,
            },
          },
        },
        loading: {
          ...this.state.loading,
          tienda: false,
        },
      });
    }
  }

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      {
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: input,
            },
          });
        });
      }
    );
  };

  handleChangeCheckbox = (e, aditional) => {
    let name = e.target.name;

    this.setState({
      [aditional]: {
        ...this.state[aditional],
        [name]: {
          ...this.state[aditional][name],
          value: this.state[aditional][name]['value'] ? 0 : 1,
        },
      },
    });
  };

  handleChangeSelect = (e, aditional) => {
    let value = Validate.trim_int(e.target.value);
    let name = e.target.name;

    this.setState({
      [aditional]: {
        ...this.state[aditional],
        [name]: {
          ...this.state[aditional][name],
          value: value,
        },
      },
    });
  };

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  modalHandler = (open, id, aditional) => {
    switch (id) {
      case 'activar_editar_todopago':
        Validate.cargar_formulario_sin_nulos(
          this.props.metodos_pago,
          this.state.activar_editar_todopago,
          (activar_editar_todopago_obj) => {
            this.setState({
              activar_editar_todopago: activar_editar_todopago_obj,
              modals: {
                ...this.state.modals,
                [id]: open,
              },
            });
          }
        );
        break;
      case 'activar_editar_uala':
        Validate.cargar_formulario_sin_nulos(
          this.props.metodos_pago,
          this.state.activar_editar_uala,
          (activar_editar_uala_obj) => {
            this.setState({
              activar_editar_uala: activar_editar_uala_obj,
              modals: {
                ...this.state.modals,
                [id]: open,
              },
            });
          }
        );
        break;
      case 'activar_editar_efectivo':
        Validate.cargar_formulario_sin_nulos(
          this.props.metodos_pago,
          this.state.activar_editar_efectivo,
          (activar_editar_efectivo_obj) => {
            this.setState({
              activar_editar_efectivo: activar_editar_efectivo_obj,
              modals: {
                ...this.state.modals,
                [id]: open,
              },
            });
          }
        );
        break;
      case 'activar_editar_deposito':
        Validate.cargar_formulario_sin_nulos(
          this.props.metodos_pago,
          this.state.activar_editar_deposito,
          (activar_editar_deposito_obj) => {
            this.setState({
              activar_editar_deposito: activar_editar_deposito_obj,
              modals: {
                ...this.state.modals,
                [id]: open,
              },
            });
          }
        );
        break;
      default:
        break;
    }
  };

  handleDeshabilitarMercadoPagoSubmit = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        desactivar_mercadopago: true,
      },
    });

    this.props.metodopagoDesactivarMercadoPago((err, resp) => {
      this.setState(
        {
          forms: {
            ...this.state.forms,
            desactivar_mercadopago: false,
          },
          loading: {
            ...this.state.loading,
            error: err ? true : false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          },
        },
        () => {
          if (!err) {
            createAmplitudeEventWithDevice(
              amplitude_events.desactivar_metodo_pago.event_name,
              { metodo_pago: 'mercado_pago' }
            );
          }
        }
      );
    });
  };

  handleActivarEditarTodoPagoSubmit = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        activar_editar_todopago: true,
      },
    });

    let form = this.state.activar_editar_todopago;

    if (Validate.validar_formulario(form)) {
      this.props.metodopagoActivarTodoPago(
        {
          mp_todopago_merchant_id: form.mp_todopago_merchant_id.value,
          mp_todopago_api_key: form.mp_todopago_api_key.value,
        },
        (err, resp) => {
          if (err) {
            this.setState({
              forms: {
                ...this.state.forms,
                activar_editar_todopago: false,
              },
              alert: {
                open: true,
                message: resp.message,
                type: 'error',
              },
            });
          } else {
            this.setState({
              forms: {
                ...this.state.forms,
                activar_editar_todopago: false,
              },
              alert: {
                open: true,
                message: resp.message,
                type: 'success',
              },
              modals: {
                ...this.state.modals,
                activar_editar_todopago: false,
              },
            });
          }
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          activar_editar_todopago: new_form,
          forms: {
            ...this.state.forms,
            activar_editar_todopago: false,
          },
        });
      });
    }
  };

  handleDeshabilitarTodoPagoSubmit = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        desactivar_todopago: true,
      },
    });

    this.props.metodopagoDesactivarTodoPago((err, resp) => {
      this.setState({
        forms: {
          ...this.state.forms,
          desactivar_todopago: false,
        },
        loading: {
          ...this.state.loading,
          error: err ? true : false,
        },
        alert: {
          ...this.state.alert,
          open: true,
          message: resp.message,
          type: err ? 'error' : 'success',
        },
      });
    });
  };

  handleActivarEditarUalaSubmit = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        activar_editar_uala: true,
      },
    });

    let form = this.state.activar_editar_uala;

    if (Validate.validar_formulario(form)) {
      this.props.metodopagoActivarUala(
        {
          mp_uala_username: form.mp_uala_username.value,
          mp_uala_client_id: form.mp_uala_client_id.value,
          mp_uala_client_secret_id: form.mp_uala_client_secret_id.value,
        },
        (err, resp) => {
          if (err) {
            this.setState({
              forms: {
                ...this.state.forms,
                activar_editar_uala: false,
              },
              alert: {
                open: true,
                message: resp.message,
                type: 'error',
              },
            });
          } else {
            this.setState(
              {
                forms: {
                  ...this.state.forms,
                  activar_editar_uala: false,
                },
                alert: {
                  open: true,
                  message: resp.message,
                  type: 'success',
                },
                modals: {
                  ...this.state.modals,
                  activar_editar_uala: false,
                },
              },
              () => {
                createAmplitudeEventWithDevice(
                  amplitude_events.activa_uala_bis.event_name
                );

                createAmplitudeEventWithDevice(
                  amplitude_events.activar_metodo_pago.event_name,
                  { metodo_pago: 'uala_bis' }
                );

                braze.getUser().setCustomUserAttribute('Uala Bis Integrado', 1);
                if (this.props.idCountry === 'ARG') {
                  braze.logCustomEvent('empretienda_metodos_pago_activar', {
                    metodo_pago: 'uala_bis',
                  });
                }

                if (window.gtag) {
                  window.gtag('event', 'Integracion', {
                    event_category: 'Cobros',
                    event_label: 'Checkout Bis',
                    event_value: 'Alta',
                  });
                }
              }
            );
          }
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          activar_editar_uala: new_form,
          forms: {
            ...this.state.forms,
            activar_editar_uala: false,
          },
        });
      });
    }
  };

  handleDeshabilitarUalaSubmit = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        desactivar_uala: true,
      },
    });

    this.props.metodopagoDesactivarUala((err, resp) => {
      this.setState(
        {
          forms: {
            ...this.state.forms,
            desactivar_uala: false,
          },
          loading: {
            ...this.state.loading,
            error: err ? true : false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          },
        },
        () => {
          if (!err) {
            createAmplitudeEventWithDevice(
              amplitude_events.desactivar_metodo_pago.event_name,
              { metodo_pago: 'uala_bis' }
            );

            braze.getUser().setCustomUserAttribute('Uala Bis Integrado', 0);
          }
        }
      );
    });
  };

  handleActivarEditarDepositoSubmit = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        activar_editar_deposito: true,
      },
    });

    let form = this.state.activar_editar_deposito;

    if (Validate.validar_formulario(form)) {
      let porcentaje = Validate.trim_int(form.mp_deposito_porcentaje.value);
      if (porcentaje >= 0 && porcentaje <= 100) {
        this.props.metodopagoActivarDeposito(
          {
            mp_deposito_datos: form.mp_deposito_datos.value,
            mp_deposito_descripcion: form.mp_deposito_descripcion.value,
            mp_deposito_comprobante: form.mp_deposito_comprobante.value,
            mp_deposito_descuento: form.mp_deposito_descuento.value,
            mp_deposito_porcentaje: form.mp_deposito_porcentaje.value,
          },
          (err, resp) => {
            if (err) {
              this.setState({
                forms: {
                  ...this.state.forms,
                  activar_editar_deposito: false,
                },
                alert: {
                  open: true,
                  message: resp.message,
                  type: 'error',
                },
              });
            } else {
              this.setState(
                {
                  forms: {
                    ...this.state.forms,
                    activar_editar_deposito: false,
                  },
                  alert: {
                    open: true,
                    message: resp.message,
                    type: 'success',
                  },
                  modals: {
                    ...this.state.modals,
                    activar_editar_deposito: false,
                  },
                },
                () => {
                  createAmplitudeEventWithDevice(
                    amplitude_events.activar_metodo_pago.event_name,
                    { metodo_pago: 'transferencia' }
                  );

                  if (this.props.idCountry === 'ARG') {
                    braze.logCustomEvent('empretienda_metodos_pago_activar', {
                      metodo_pago: 'transferencia',
                    });
                  }
                }
              );
            }
          }
        );
      } else {
        this.setState({
          forms: {
            ...this.state.forms,
            activar_editar_deposito: false,
          },
          alert: {
            open: true,
            message: 'Ingrese un porcentaje de descuento entre 0 y 100',
            type: 'error',
          },
        });
      }
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          activar_editar_deposito: new_form,
          forms: {
            ...this.state.forms,
            activar_editar_deposito: false,
          },
        });
      });
    }
  };

  handleDeshabilitarDepositoSubmit = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        desactivar_deposito: true,
      },
    });

    this.props.metodopagoDesactivarDeposito((err, resp) => {
      this.setState(
        {
          forms: {
            ...this.state.forms,
            desactivar_deposito: false,
          },
          loading: {
            ...this.state.loading,
            error: err ? true : false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          },
        },
        () => {
          if (!err) {
            createAmplitudeEventWithDevice(
              amplitude_events.desactivar_metodo_pago.event_name,
              { metodo_pago: 'transferencia' }
            );
          }
        }
      );
    });
  };

  handleDesactivarEfectivoSubmit = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        desactivar_efectivo: true,
      },
    });

    this.props.metodopagoDesactivarEfectivo((err, resp) => {
      this.setState(
        {
          forms: {
            ...this.state.forms,
            desactivar_efectivo: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          },
        },
        () => {
          if (!err) {
            createAmplitudeEventWithDevice(
              amplitude_events.desactivar_metodo_pago.event_name,
              { metodo_pago: 'efectivo' }
            );
          }
        }
      );
    });
  };

  handleActivarEditarEfectivoSubmit = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        activar_editar_efectivo: true,
      },
    });

    let form = this.state.activar_editar_efectivo;

    if (Validate.validar_formulario(form)) {
      let porcentaje = Validate.trim_int(form.mp_efectivo_porcentaje.value);
      if (porcentaje >= 0 && porcentaje <= 100) {
        this.props.metodopagoActivarEfectivo(
          {
            mp_efectivo_descuento: form.mp_efectivo_descuento.value,
            mp_efectivo_porcentaje: form.mp_efectivo_porcentaje.value,
            mp_efectivo_descripcion: form.mp_efectivo_descripcion.value,
          },
          (err, resp) => {
            if (err) {
              this.setState({
                forms: {
                  ...this.state.forms,
                  activar_editar_efectivo: false,
                },
                alert: {
                  open: true,
                  message: resp.message,
                  type: 'error',
                },
              });
            } else {
              this.setState(
                {
                  forms: {
                    ...this.state.forms,
                    activar_editar_efectivo: false,
                  },
                  alert: {
                    open: true,
                    message: resp.message,
                    type: 'success',
                  },
                  modals: {
                    ...this.state.modals,
                    activar_editar_efectivo: false,
                  },
                },
                () => {
                  createAmplitudeEventWithDevice(
                    amplitude_events.activar_metodo_pago.event_name,
                    { metodo_pago: 'efectivo' }
                  );

                  if (this.props.idCountry === 'ARG') {
                    braze.logCustomEvent('empretienda_metodos_pago_activar', {
                      metodo_pago: 'efectivo',
                    });
                  }
                }
              );
            }
          }
        );
      } else {
        this.setState({
          forms: {
            ...this.state.forms,
            activar_editar_efectivo: false,
          },
          alert: {
            open: true,
            message: 'Ingrese un porcentaje de descuento entre 0 y 100',
            type: 'error',
          },
        });
      }
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          activar_editar_efectivo: new_form,
          forms: {
            ...this.state.forms,
            activar_editar_efectivo: false,
          },
        });
      });
    }
  };

  handleDesactivarAcordarSubmit = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        desactivar_acordar: true,
      },
    });

    this.props.metodopagoDesactivarAcordar((err, resp) => {
      this.setState(
        {
          forms: {
            ...this.state.forms,
            desactivar_acordar: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          },
        },
        () => {
          if (!err) {
            createAmplitudeEventWithDevice(
              amplitude_events.desactivar_metodo_pago.event_name,
              { metodo_pago: 'acuerdo' }
            );
          }
        }
      );
    });
  };

  handleActivarAcordarSubmit = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        activar_acordar: true,
      },
    });

    this.props.metodopagoActivarAcordar((err, resp) => {
      this.setState(
        {
          forms: {
            ...this.state.forms,
            activar_acordar: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          },
        },
        () => {
          if (!err) {
            createAmplitudeEventWithDevice(
              amplitude_events.activar_metodo_pago.event_name,
              { metodo_pago: 'acuerdo' }
            );

            if (this.props.idCountry === 'ARG') {
              braze.logCustomEvent('empretienda_metodos_pago_activar', {
                metodo_pago: 'acuerdo',
              });
            }
          }
        }
      );
    });
  };

  handleObtenerUrlMobbexSubmit = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        obtener_url_mobbex: true,
      },
    });

    this.props.metodopagoObtenerUrlMobbex((err, resp) => {
      if (err) {
        this.setState({
          forms: {
            ...this.state.forms,
            obtener_url_mobbex: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: resp.message,
            type: 'error',
          },
        });
      } else {
        window.location.replace(resp.data.url);
      }
    });
  };

  handleDesactivarMobbexSubmit = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        desactivar_mobbex: true,
      },
    });

    this.props.metodopagoDesactivarMobbex((err, resp) => {
      this.setState(
        {
          forms: {
            ...this.state.forms,
            desactivar_mobbex: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          },
        },
        () => {
          if (!err) {
            createAmplitudeEventWithDevice(
              amplitude_events.desactivar_metodo_pago.event_name,
              { metodo_pago: 'mobbex' }
            );
          }
        }
      );
    });
  };

  render() {
    const { metodos_pago, tienda } = this.props;
    const idCountry = tienda ? tienda.Country_idCountry : 'ARG';

    return (
      <WrapperLayout
        loading={this.state.loading.metodos_pago}
        error={this.state.loading.error}
      >
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <TodoPagoModal
          modals={this.state.modals}
          inputs={this.state.activar_editar_todopago}
          forms={this.state.forms}
          modalHandler={this.modalHandler}
          handleSubmit={this.handleActivarEditarTodoPagoSubmit}
          handleChange={this.handleChange}
        />
        <UalaModal
          modals={this.state.modals}
          inputs={this.state.activar_editar_uala}
          forms={this.state.forms}
          modalHandler={this.modalHandler}
          handleSubmit={this.handleActivarEditarUalaSubmit}
          handleChange={this.handleChange}
          idCountry={idCountry}
        />
        <EfectivoModal
          modals={this.state.modals}
          inputs={this.state.activar_editar_efectivo}
          forms={this.state.forms}
          selects={this.state.selects}
          modalHandler={this.modalHandler}
          handleSubmit={this.handleActivarEditarEfectivoSubmit}
          handleChange={this.handleChange}
          handleChangeSelect={this.handleChangeSelect}
        />
        <DepositoModal
          modals={this.state.modals}
          inputs={this.state.activar_editar_deposito}
          forms={this.state.forms}
          modalHandler={this.modalHandler}
          handleSubmit={this.handleActivarEditarDepositoSubmit}
          handleChange={this.handleChange}
          handleChangeCheckbox={this.handleChangeCheckbox}
          selects={this.state.selects}
          handleChangeSelect={this.handleChangeSelect}
        />
        <Grid container spacing={{ xs: 0, md: 3 }}>
          <UalaBis
            metodos_pago={metodos_pago}
            forms={this.state.forms}
            modalHandler={this.modalHandler}
            handleDeshabilitarUalaSubmit={this.handleDeshabilitarUalaSubmit}
            idCountry={idCountry}
          />
          <ToggleUI show={idCountry === 'ARG'}>
            <MercadoPago
              metodos_pago={metodos_pago}
              forms={this.state.forms}
              handleDeshabilitarMercadoPagoSubmit={
                this.handleDeshabilitarMercadoPagoSubmit
              }
            />
          </ToggleUI>
          <ToggleUI show={idCountry === 'ARG'}>
            <Mobbex
              metodos_pago={metodos_pago}
              forms={this.state.forms}
              handleObtenerUrlMobbexSubmit={this.handleObtenerUrlMobbexSubmit}
              handleDesactivarMobbexSubmit={this.handleDesactivarMobbexSubmit}
            />
          </ToggleUI>
          <ToggleUI
            show={metodos_pago.mp_todopago === 1 && idCountry === 'ARG'}
          >
            <TodoPago
              metodos_pago={metodos_pago}
              forms={this.state.forms}
              modalHandler={this.modalHandler}
              handleDeshabilitarTodoPagoSubmit={
                this.handleDeshabilitarTodoPagoSubmit
              }
            />
          </ToggleUI>
          <ToggleUI show={idCountry === 'ARG'}>
            <Efectivo
              metodos_pago={metodos_pago}
              forms={this.state.forms}
              modalHandler={this.modalHandler}
              handleDesactivarEfectivoSubmit={
                this.handleDesactivarEfectivoSubmit
              }
            />
          </ToggleUI>
          <ToggleUI show={idCountry === 'ARG'}>
            <Deposito
              metodos_pago={metodos_pago}
              forms={this.state.forms}
              modalHandler={this.modalHandler}
              handleDeshabilitarDepositoSubmit={
                this.handleDeshabilitarDepositoSubmit
              }
            />
          </ToggleUI>
          <Acordar
            metodos_pago={metodos_pago}
            forms={this.state.forms}
            handleDesactivarAcordarSubmit={this.handleDesactivarAcordarSubmit}
            handleActivarAcordarSubmit={this.handleActivarAcordarSubmit}
          />
        </Grid>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
});

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
    metodos_pago: state.metodos_pago,
    idCountry: state.tienda.Country_idCountry,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    metodospagoObtener: (callback) => dispatch(metodospagoObtener(callback)),
    metodopagoActivarMercadoPago: (data, callback) =>
      dispatch(metodopagoActivarMercadoPago(data, callback)),
    metodopagoDesactivarMercadoPago: (callback) =>
      dispatch(metodopagoDesactivarMercadoPago(callback)),
    metodopagoActivarTodoPago: (data, callback) =>
      dispatch(metodopagoActivarTodoPago(data, callback)),
    metodopagoDesactivarTodoPago: (callback) =>
      dispatch(metodopagoDesactivarTodoPago(callback)),
    metodopagoActivarUala: (data, callback) =>
      dispatch(metodopagoActivarUala(data, callback)),
    metodopagoDesactivarUala: (callback) =>
      dispatch(metodopagoDesactivarUala(callback)),
    metodopagoActivarDeposito: (data, callback) =>
      dispatch(metodopagoActivarDeposito(data, callback)),
    metodopagoDesactivarDeposito: (callback) =>
      dispatch(metodopagoDesactivarDeposito(callback)),
    metodopagoActivarEfectivo: (data, callback) =>
      dispatch(metodopagoActivarEfectivo(data, callback)),
    metodopagoDesactivarEfectivo: (callback) =>
      dispatch(metodopagoDesactivarEfectivo(callback)),
    metodopagoActivarAcordar: (callback) =>
      dispatch(metodopagoActivarAcordar(callback)),
    metodopagoDesactivarAcordar: (callback) =>
      dispatch(metodopagoDesactivarAcordar(callback)),
    metodopagoObtenerUrlMobbex: (callback) =>
      dispatch(metodopagoObtenerUrlMobbex(callback)),
    metodopagoActivarMobbex: (data, callback) =>
      dispatch(metodopagoActivarMobbex(data, callback)),
    metodopagoDesactivarMobbex: (callback) =>
      dispatch(metodopagoDesactivarMobbex(callback)),
  };
};

export const MetodosPago = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MetodosPago_)
);
