import React, { Fragment } from 'react';
import {
  SwipeableDrawer,
  Grid,
  IconButton,
  Typography,
  Icon,
  Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import ReactExport from 'react-export-excel';
import { SelectUI, ButtonUI } from '../../common';
import { Validate, Config } from '../../../other';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const es_iphone = Validate.is_iphone();

const tipoCliente = (tipo) => {
  switch (tipo) {
    case -1:
      return 'Solicitud mayorista pendiente';

    case 0:
      return 'Minorista';

    case 1:
      return 'Mayorista';

    default:
      break;
  }
};

const RenderExcel = ({ data }) => {
  if (data.length > 0) {
    let date = Validate.now_format(Config.SHORTDATE_FORMAT_DASH);
    data = data.map((cliente) => {
      return {
        nombre: Validate.trim_ucwords(cliente.cl_nombre),
        apellido: Validate.trim_ucwords(cliente.cl_apellido),
        cl_email: cliente.cl_email,
        cl_tipo_identificacion: cliente.cl_tipo_identificacion,
        cl_numero_identificacion: cliente.cl_numero_identificacion,
        cl_mayorista: tipoCliente(cliente.cl_mayorista),
        cl_telefono: cliente.cl_telefono,
        cl_calle: Validate.trim_ucwords(cliente.cl_calle),
        cl_numero: cliente.cl_numero,
        cl_piso: cliente.cl_piso,
        cl_dpto: cliente.cl_dpto,
        cl_ciudad: Validate.trim_ucwords(cliente.cl_ciudad),
        cl_provincia: cliente.cl_provincia,
      };
    });

    return (
      <ExcelFile filename={`Exportacion-clientes-${date}`} hideElement={true}>
        <ExcelSheet data={data} name="Clientes">
          <ExcelColumn label="Nombre" value="nombre" />
          <ExcelColumn label="Apellido" value="apellido" />
          <ExcelColumn label="Email" value="cl_email" />
          <ExcelColumn
            label="Tipo de documento"
            value="cl_tipo_identificacion"
          />
          <ExcelColumn label="Documento" value="cl_numero_identificacion" />
          <ExcelColumn label="Tipo de cliente" value="cl_mayorista" />
          <ExcelColumn label="Teléfono" value="cl_telefono" />
          <ExcelColumn label="Dirección" value="cl_calle" />
          <ExcelColumn label="Número" value="cl_numero" />
          <ExcelColumn label="Piso" value="cl_piso" />
          <ExcelColumn label="Dpto" value="cl_dpto" />
          <ExcelColumn label="Ciudad" value="cl_ciudad" />
          <ExcelColumn label="Provincia" value="cl_provincia" />
        </ExcelSheet>
      </ExcelFile>
    );
  } else {
    return '';
  }
};

const FilterSidenav_ = (props) => {
  let {
    classes,
    filter,
    selects,
    isFiltersDrawerOpen,
    handleDrawer,
    handleChangeSelect,
    limpiarFiltros,
    handleSubmitFilter,
    type,
    loading,
    data_exportar,
  } = props;

  return (
    <Fragment>
      <SwipeableDrawer
        anchor="right"
        open={isFiltersDrawerOpen}
        onClose={() => handleDrawer(false, type)}
        onOpen={() => handleDrawer(true, type)}
        disableBackdropTransition={!es_iphone}
        disableDiscovery={es_iphone}
      >
        <div className={classes.filters_sidenav_box}>
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={0.5}>
                <Grid item>
                  <IconButton
                    aria-label="Cancelar"
                    onClick={() => handleDrawer(false, type)}
                    size="large"
                  >
                    <Icon>clear</Icon>
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    {type === 'filtrar' ? 'Filtrar' : 'Exportar'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SelectUI
                data={filter.cl_mayorista}
                options={selects.cl_mayorista}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectUI
                data={filter.cl_bloqueado}
                options={selects.cl_bloqueado}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
            <Grid item xs={12} mb={0.5}>
              <SelectUI
                data={filter.cl_registro_completo}
                options={selects.cl_registro_completo}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
            <Grid item xs={12} mb={0.5}>
              <ButtonUI
                type="callf"
                label={type === 'exportar' ? 'Exportar' : 'Aplicar filtros'}
                size="large"
                isLoading={
                  type === 'exportar' ? loading.exportar : loading.clientes
                }
                onClickFunc={() => handleSubmitFilter(type)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={(e) => limpiarFiltros()}
                variant="outlined"
                size="large"
                className={classes.button}
                fullWidth
              >
                <div className={classes.icon_center}>
                  <Icon className={classes.icon_badge}>delete</Icon>
                  Limpiar filtros
                </div>
              </Button>
            </Grid>
          </Grid>
        </div>
      </SwipeableDrawer>
      <RenderExcel data={data_exportar} />
    </Fragment>
  );
};

const styles = (theme) => ({
  icon_center: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px 0px',
  },
  icon_badge: {
    marginRight: '10px',
  },
  button: {
    marginTop: '2px',
  },
  filters_sidenav_box: {
    width: '300px',
    padding: theme.spacing(2),
  },
});

export const FilterSidenav = withStyles(styles, { withTheme: true })(
  FilterSidenav_
);
