import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { createAmplitudeEventWithDevice } from '../../../components/amplitude';
import { useHistory } from 'react-router-dom';

const MetricItem = ({
  amplitudeEvent,
  redirectUrl,
  sectionTitle,
  information,
  gridColumn,
  ...rest
}) => {
  const router = useHistory();

  const handleItemAction = () => {
    createAmplitudeEventWithDevice(amplitudeEvent);
    router.push(redirectUrl);
  };

  const handleOnKeyPress = ({ code }) => {
    code === 'Enter' && handleItemAction();
  };

  return (
    <Card
      sx={{
        backgroundColor: '#F5F6F8',
        cursor: 'pointer',
        '&:hover': {
          background: 'rgba(0, 0, 0, 0.05)',
        },
        gridColumn: gridColumn,
      }}
      tabIndex={0}
      onClick={handleItemAction}
      onKeyDown={handleOnKeyPress}
      role="button"
      {...rest}
    >
      <CardContent>
        <Typography
          m={0}
          fontWeight={400}
          lineHeight="14px"
          color="#A5A5A5"
          fontSize="0.75rem"
          sx={{ textDecoration: 'none', height: '28px' }}
        >
          {sectionTitle}
        </Typography>
        <Typography
          m={0}
          fontWeight={400}
          lineHeight="28px"
          color="#383838"
          fontSize="1.125rem"
          sx={{
            textDecoration: 'none',
            mt: { xs: '6px', md: 0 },
          }}
        >
          {information}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MetricItem;
