import React from 'react';
import ReactExport from 'react-export-excel';
import { Validate, Config } from '../../../other';
import DataExcelVentas from './DataExcelVentas.json';

const parse_productos = (v_arr_carrito_json) => {
  let productos_prev = JSON.parse(v_arr_carrito_json);

  let productos = [];

  for (let idStock in productos_prev) {
    productos.push(productos_prev[idStock]);
  }
  return productos;
};

const productos_string = (product_list) => {
  let productos = [];
  product_list.forEach((product) => {
    let producto = `${product.cantidad}x ${product.nombre}`;
    if (product.atributos > 0) {
      let atributos_st = [];
      let atributos = product.info_atributos;
      for (let item in atributos) {
        let atributo = `${atributos[item].at_nombre}: ${atributos[item].vat_valor}`;
        atributos_st.push(atributo);
      }
      producto = `${producto} (${atributos_st.toString()})`;
    }

    producto = `${producto} (SKU: ${product.sku ? product.sku : '-'})`;

    productos.push(producto);
  });

  let productos_st = productos.toString();

  return productos_st;
};

const RenderExcel = ({ data, idCountry }) => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  if (data.length > 0) {
    let date = Validate.now_format(Config.SHORTDATE_FORMAT_DASH);
    data = data.map((venta) => {
      let envio_seleccionado = JSON.parse(venta.v_arr_envio_seleccionado_json);
      let productos_list = parse_productos(venta.v_arr_carrito_json);
      let metodo_envio_extra = Validate.labels_metodo_envio_extra(
        venta.v_metodo_envio,
        envio_seleccionado
      );

      let v_facturacion_nombrecompleto = venta.v_facturacion_nombre;
      if (venta.v_facturacion_apellido) {
        v_facturacion_nombrecompleto += ' ' + venta.v_facturacion_apellido;
      }

      let envioCalle = Validate.trim_ucwords(venta.v_envio_calle);
      let facturacionCalle = Validate.trim_ucwords(venta.v_facturacion_calle);

      if (idCountry === 'COL') {
        envioCalle = venta.v_envio_calle + ' - ' + venta.v_envio_numero;

        facturacionCalle =
          venta.v_facturacion_calle + ' - ' + venta.v_facturacion_numero;
      }

      return {
        ...venta,
        v_fecha: Validate.utc2local_format(venta.v_fecha, Config.DATE_FORMAT),
        v_facturacion_nombrecompleto: Validate.trim_ucwords(
          v_facturacion_nombrecompleto
        ),
        cl_email: venta.cliente.cl_email,
        pago: Validate.label_estado_pago(venta.v_estado_pago),
        metodo_pago: Validate.label_metodo_pago(venta.v_metodo_pago),
        envio: Validate.label_estado_envio(
          venta.v_metodo_envio,
          venta.v_estado_envio
        ),
        metodo_envio: Validate.label_metodo_envio(
          venta.v_metodo_envio,
          envio_seleccionado
        ),
        v_monto: Validate.number_format_excel(venta.v_monto),
        costo_envio: Validate.number_format_excel(
          Validate.label_costo_envio_exportar(
            venta.v_metodo_envio,
            venta.v_envio_costo,
            venta.v_envio_gratis,
            envio_seleccionado
          )
        ),
        metodo_envio_tipo: metodo_envio_extra.tipo_envio,
        metodo_envio_tipo_sucursal: metodo_envio_extra.sucursal,
        v_envio_nombre: Validate.trim_ucwords(venta.v_envio_nombre),
        v_envio_apellido: Validate.trim_ucwords(venta.v_envio_apellido),
        v_envio_calle: envioCalle,
        v_envio_ciudad: Validate.trim_ucwords(venta.v_envio_ciudad),
        v_facturacion_calle: facturacionCalle,
        v_facturacion_ciudad: Validate.trim_ucwords(venta.v_facturacion_ciudad),
        productos: productos_string(productos_list),
        facturada: venta.v_factura ? 'Si' : 'No',
      };
    });

    return (
      <ExcelFile filename={`Exportacion-ventas-${date}`} hideElement={true}>
        <ExcelSheet data={data} name="Cupones">
          {DataExcelVentas[idCountry || 'ARG'].map(({ label, value }, idx) => {
            return <ExcelColumn label={label} value={value} key={idx} />;
          })}
        </ExcelSheet>
      </ExcelFile>
    );
  } else {
    return '';
  }
};

export default RenderExcel;
