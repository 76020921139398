import React from 'react';
import { Grid } from '@mui/material';
import { SelectUI, ToggleUI } from '../../common';
import { InfoText } from './';
import { useSelector } from 'react-redux';

const ShopCheckoutSettings = (props) => {
  let { settings, selects, handleChangeSelect } = props;
  const idCountry =
    useSelector((state) => state.tienda.Country_idCountry) || 'ARG';

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <InfoText
          first
          title="Notas de checkout"
          subtitle="Activando esta opción, tus clientes van a poder dejarte comentarios adicionales antes de finalizar una compra y los veras en el detalle de la orden."
        />
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <SelectUI
              data={settings.checkout_comments}
              options={selects.bloque_si_no}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
        </Grid>
      </Grid>
      <ToggleUI show={idCountry !== 'COL'}>
        <Grid item xs={12}>
          <InfoText
            first
            title="Formulario de facturación simple"
            subtitle="Activando esta opción, tus clientes sólo deberan completar su nombre, apellido y documento en el formulario de facturación de tu checkout."
          />
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <SelectUI
                data={settings.checkout_short_billing_form}
                options={selects.bloque_si_no}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
          </Grid>
        </Grid>
      </ToggleUI>
      <Grid item xs={12}>
        <InfoText
          first
          title="Paso de revisión"
          subtitle="Activando esta opción, se agregará un tercer paso extra en tu checkout donde tus clientes podrán ver un resumen de sus datos ingresados antes de finalizar."
        />
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <SelectUI
              data={settings.checkout_step_review}
              options={selects.bloque_si_no}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export { ShopCheckoutSettings };
