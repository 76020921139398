/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Grid } from '@mui/material';
import {
  ModalUI,
  InputUI,
  ModuleCardTitleUI,
} from '../../../../../components/common';

const ModalNotificarEnvio = (props) => {
  let {
    notificar_envio_enviado,
    venta,
    handleSubmit,
    handleChange,
    modalHandler,
    modal_state,
    form_state,
  } = props;

  let html = '';

  if (venta) {
    //solo se usa cuando es correo argentino
    if (venta.v_metodo_envio === 8) {
      let peso = 0;
      let carrito = JSON.parse(venta.v_arr_carrito_json);
      for (let i in carrito) {
        peso += carrito[i].peso * carrito[i].cantidad;
      }
      html = (
        <ModuleCardTitleUI
          title="Pasos antes de notificar el envío"
          subtitles={[
            <span style={{ marginBottom: '10px' }}>
              1. Ingresar a tu cuenta de{' '}
              <a
                href="https://www.correoargentino.com.ar/MiCorreo/public/login"
                target="_blank"
                style={{
                  color: 'inherit',
                  textDecoration: 'underline',
                }}
              >
                Mi Correo
              </a>
              .
            </span>,
            <span style={{ marginBottom: '10px' }}>
              2. Crear un envío con los datos de envío brindados del cliente.
            </span>,
            <span style={{ marginBottom: '10px' }}>
              3. El peso total calculado para esta orden y utilizado para
              calcular el costo de envío es de <strong>{peso}kg</strong> y debes
              cargarlo en Mi Correo para obtener el mismo resultado.
            </span>,
            <span>
              4. Luego de dar de alta el envío en Mi Correo, imprimir la
              etiqueta y despachar el paquete, puedes utilizar este formulario
              para enviarle un mensaje al cliente y colocar la orden como
              enviada. Adicionalmente, puedes enviarle el número de seguimiento.
            </span>,
          ]}
        />
      );
    }
  }

  return (
    <ModalUI
      open={modal_state}
      id="notificar_envio_enviado"
      title="Notificar envío y colocar como enviado"
      subtitle={notificar_envio_enviado.modal_subtitle.value}
      modalHandler={modalHandler}
      aditional_param="notificar_envio_enviado"
      handleSubmit={handleSubmit}
      button_label="Notificar"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        {html}
        <Grid item xs={12}>
          <InputUI
            handleChange={handleChange}
            multiline
            input={notificar_envio_enviado.neo_razon}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalNotificarEnvio };
