import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const BuilderWrapper = ({ fonts, children }) => {
  let google_fonts_link = 'https://fonts.googleapis.com/css2';
  fonts.forEach((font, index) => {
    if (index) {
      google_fonts_link += '&family=' + font.font_setting;
    } else {
      google_fonts_link += '?family=' + font.font_setting;
    }
  });
  google_fonts_link += '&display=swap';

  return (
    <Fragment>
      <Helmet>
        <link rel="stylesheet" href={google_fonts_link} />
      </Helmet>
      <div style={{ display: 'flex' }}>{children}</div>
    </Fragment>
  );
};

export { BuilderWrapper };
