const metodosenvioObtener = () => {
  return {
    status: 200,
    data: {
      idMediosEnvio: 217,
      me_retiro_local: 0,
      me_acordar: 1,
      me_oca: 0,
      me_personalizados: 0,
      me_correo_argentino: 0,
      me_mercadoenvios: 0,
      me_pickit: 0,
      me_pudo: 0,
      me_epick: 0,
      me_emprepack: 0,
      me_mercadoenvios_tipo: 0,
      me_mercadoenvios_minimo: 0,
      me_oca_usuario: null,
      me_oca_clave: null,
      me_oca_nrocuenta: null,
      me_oca_solicitante: null,
      me_oca_cuit: null,
      me_oca_operativa_pp: null,
      me_oca_operativa_ps: null,
      me_oca_operativa_sp: null,
      me_oca_operativa_ss: null,
      me_oca_calle: null,
      me_oca_nro: null,
      me_oca_piso: null,
      me_oca_dpto: null,
      me_oca_cp: null,
      me_oca_localidad: null,
      me_oca_provincia: null,
      me_oca_provincia_id: 2,
      me_oca_tipo: 0,
      me_oca_gratis_sucursal: 0,
      me_oca_gratis_domicilio: 0,
      me_oca_gratis_minimo: 0,
      me_oca_recargo_monto: 0,
      me_oca_recargo_porcentaje: 0,
      me_pickit_api_key: null,
      me_pickit_token: null,
      me_pudo_api_key: null,
      me_pudo_secret_key: null,
      me_correo_argentino_postal: null,
      me_correo_argentino_prioritario: 1,
      me_correo_argentino_clasico: 1,
      me_correo_argentino_tipo_cuenta: 0,
      me_correo_argentino_tipo: 0,
      me_correo_argentino_gratis_sucursal: 0,
      me_correo_argentino_gratis_domicilio: 0,
      me_correo_argentino_gratis_minimo: 0,
      me_correo_argentino_recargo_monto: 0,
      me_correo_argentino_recargo_porcentaje: 0,
      me_correo_argentino_email: null,
      me_correo_argentino_password: null,
      me_epick_solicitante: null,
      me_epick_telefono: null,
      me_epick_calle: null,
      me_epick_nro: null,
      me_epick_piso: null,
      me_epick_dpto: null,
      me_epick_cp: null,
      me_epick_localidad: null,
      me_epick_provincia: null,
      me_epick_tipo: 0,
      me_epick_gratis_minimo: 0,
      me_epick_recargo_monto: 0,
      me_epick_recargo_porcentaje: 0,
      me_emprepack_office_id: 0,
      me_emprepack_tipo: 0,
      me_emprepack_gratis_minimo: 0,
      me_emprepack_recargo_monto: 0,
      me_emprepack_recargo_porcentaje: 0,
      me_emprepack_gratis_sucursal: 0,
      me_emprepack_gratis_domicilio: 0,
    },
    message: 'Ok',
  };
};

export default {
  metodosenvioObtener,
};
