import React, { useEffect } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ProductRow } from './ProductRow';
import { ProductRowMobile } from './ProductRowMobile';
import {
  createAmplitudeEventWithDevice,
  import_amplitude_events,
} from '../../amplitude';

const ConfirmarCambios_ = (props) => {
  useEffect(() => {
    createAmplitudeEventWithDevice(
      import_amplitude_events.importar_producto_successfull.event_name,
      import_amplitude_events.importar_producto_successfull.event_params
    );
  }, []);

  const {
    classes,
    handleGetNewProductsFiltered,
    handleGetEditedProductsFiltered,
  } = props;

  const IterateProducts = (screen, mode) => {
    let products_filtered;

    if (mode === 'new') {
      products_filtered = handleGetNewProductsFiltered();
    } else {
      products_filtered = handleGetEditedProductsFiltered();
    }

    return products_filtered.map((chunk_product, index) => {
      return ProductWithStock(
        screen,
        chunk_product.p_nombre,
        chunk_product.categorias,
        chunk_product.stock,
        index
      );
    });
  };

  const ProductWithStock = (
    screen,
    product_name,
    category_name,
    chunk_stock,
    index
  ) => {
    if (screen === 'desktop') {
      return chunk_stock.map((stock_element, index) => {
        return (
          <ProductRow
            key={`stock-element-${index}`}
            product_name={product_name}
            category_name={category_name}
            stock_element={stock_element}
            index={index}
          />
        );
      });
    } else {
      return (
        <ProductRowMobile
          key={index}
          product_name={product_name}
          category_name={category_name}
          chunk_stock={chunk_stock}
        />
      );
    }
  };

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12}>
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                className={classes.new_products_panel}
                expandIcon={<ExpandMoreIcon />}
              >
                <div>
                  <h2 className={classes.new_products_title}>
                    Productos nuevos
                  </h2>
                  <span className={classes.products_quantity}>
                    {handleGetNewProductsFiltered().length} PRODUCTOS
                  </span>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.panel_padding}>
                <Table className={classes.table_desktop}>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.panel_padding}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.table_title}>
                                Nombre del producto
                              </TableCell>
                              <TableCell className={classes.table_title}>
                                Atributos
                              </TableCell>
                              <TableCell className={classes.table_title}>
                                Stock
                              </TableCell>
                              <TableCell className={classes.table_title}>
                                Precio
                              </TableCell>
                              <TableCell className={classes.table_title}>
                                Oferta
                              </TableCell>
                              <TableCell className={classes.table_title}>
                                Precio Mayorista
                              </TableCell>
                              <TableCell className={classes.table_title}>
                                Categorías
                              </TableCell>
                              <TableCell className={classes.table_title}>
                                Dimensiones
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {IterateProducts('desktop', 'new')}
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <div className={classes.table_mobile}>
                  {IterateProducts('mobile', 'new')}
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ marginTop: '20px' }}>
              <AccordionSummary
                className={classes.edited_products_panel}
                expandIcon={<ExpandMoreIcon />}
              >
                <div>
                  <h2 className={classes.edited_products_title}>
                    Productos editados
                  </h2>
                  <span className={classes.products_quantity}>
                    {handleGetEditedProductsFiltered().length} PRODUCTOS
                  </span>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.panel_padding}>
                <Table className={classes.table_desktop}>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.panel_padding}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.table_title}>
                                Nombre del producto
                              </TableCell>
                              <TableCell className={classes.table_title}>
                                Atributos
                              </TableCell>
                              <TableCell className={classes.table_title}>
                                Stock
                              </TableCell>
                              <TableCell className={classes.table_title}>
                                Precio
                              </TableCell>
                              <TableCell className={classes.table_title}>
                                Oferta
                              </TableCell>
                              <TableCell className={classes.table_title}>
                                Mayorista
                              </TableCell>
                              <TableCell className={classes.table_title}>
                                Categorías
                              </TableCell>
                              <TableCell className={classes.table_title}>
                                Dimensiones
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {IterateProducts('desktop', 'edited')}
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <div className={classes.table_mobile}>
                  {IterateProducts('mobile', 'edited')}
                </div>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  new_products_panel: {
    backgroundColor: 'rgba(0, 182, 95, 0.05)',
    borderRadius: '6px',
  },
  new_products_title: {
    fontSize: '1.25rem',
    color: '#53B755',
    LineHeight: '18.75px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  },
  panel_padding: {
    padding: '0px',
    overflowX: 'scroll',
    [theme.breakpoints.down('md')]: {
      padding: '8px 24px 24px',
    },
  },
  table_title: {
    border: 'none',
    fontWeight: '400',
    fontSize: '0.87rem',
    lineHeight: '16px',
    color: '#6C6C6C',
  },
  edited_products_panel: {
    background: 'rgba(249, 153, 9, 0.05)',
    borderRadius: '6px',
  },
  edited_products_title: {
    fontSize: '1.25rem',
    color: '#F99909',
    LineHeight: '18.75px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  },
  products_quantity: {
    fontSize: '14px',
    color: '#6C6C6C',
  },
  table_desktop: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  table_mobile: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
});

export const ConfirmarCambios = React.memo(
  withStyles(styles)(ConfirmarCambios_)
);
