import React from 'react';
import { withStyles } from '@mui/styles';
import uuid from 'uuid/v1';

const IframePreview = ({ children, classes, preview_width }) => {
  return (
    <iframe
      title="live-preview"
      id={uuid()}
      srcDoc={children}
      className={
        preview_width === 'desktop' ? classes.iframe : classes.iframe_mobile
      }
    ></iframe>
  );
};
const ShopPreview_ = (props) => {
  let { classes, preview_width, preview_html } = props;

  let html = preview_html;

  if (html) {
    let test = html.split('</head>');
    test[1] =
      '<style type="text/css">* { pointer-events: none !important; }</style></head>' +
      test[1];
    html = test.join('');
    html = html.replace('/js/main.js', '/js/mainp.js');
    html = html.replace(
      '<script src="https://www.google.com/recaptcha/api.js?render=explicit" async defer></script>',
      ''
    );
  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.iframe_container}>
        <IframePreview classes={classes} preview_width={preview_width}>
          {html}
        </IframePreview>
      </div>
    </main>
  );
};

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 'calc(100% - 300px)',
    },
    boxSizing: 'border-box',
    padding: '0px',
  },
  iframe_container: {
    display: 'flex',
    justifyContent: 'center',
  },
  iframe: {
    border: '0px solid transparent',
    width: '100%',
    height: '100vh',
  },
  iframe_mobile: {
    border: '0px solid transparent',
    maxWidth: '500px',
    width: '100%',
    height: '100vh',
  },
});

export const ShopPreview = withStyles(styles, { withTheme: true })(
  ShopPreview_
);
