/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  tiendaObtener,
  tiendaActualizarGoogleAnalytics,
} from '../../store/actions';
import { withStyles } from '@mui/styles';
import { Regex, RegexExtra, Validate, Config } from '../../other';
import { Card, CardContent, Grid } from '@mui/material';
import {
  InputUI,
  ButtonUI,
  CheckboxUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
} from '../common';
import { WrapperLayout } from '../../layouts/WrapperLayout';

class GoogleAnalytics_ extends Component {
  state = {
    google_analytics: {
      t_google_analytics: {
        validate: false,
        name: 't_google_analytics',
        label: 'Activar Google Analytics',
        value: 1,
        change_param: 'google_analytics',
      },
      t_google_analytics_codigo: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'ID de Seguimiento',
        id: 't_google_analytics_codigo',
        name: 't_google_analytics_codigo',
        change_param: 'google_analytics',
        regex: Regex.GOOGLE_ANALYTICS,
        value: '',
        default_value: '',
        placeholder: 'XX-XXXXXXX-X',
        messages: {
          error: 'Ingrese un ID de seguimiento válido',
          error_extra: RegexExtra.GOOGLE_ANALYTICS,
        },
      },
    },
    loading: {
      google_analytics: true,
      error: false,
    },
    forms: {
      google_analytics: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  componentDidMount() {
    this.props.tiendaObtener((err, resp) => {
      if (err) {
        this.setState({
          loading: {
            ...this.state.loading,
            google_analytics: false,
            error: true,
          },
        });
      } else {
        Validate.cargar_formulario_sin_nulos(
          this.props.tienda,
          this.state.google_analytics,
          (google_analytics_obj) => {
            this.setState({
              google_analytics: google_analytics_obj,
              loading: {
                ...this.state.loading,
                google_analytics: false,
                error: false,
              },
            });
          }
        );
      }
    });
  }

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      {
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: input,
            },
          });
        });
      }
    );
  };

  handleChangeCheckbox = (e, aditional) => {
    let name = e.target.name;

    this.setState({
      [aditional]: {
        ...this.state[aditional],
        [name]: {
          ...this.state[aditional][name],
          value: this.state[aditional][name]['value'] ? 0 : 1,
        },
      },
    });
  };

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        google_analytics: true,
      },
    });

    let form = this.state.google_analytics;

    if (Validate.validar_formulario(form)) {
      if (
        (form.t_google_analytics.value === 1 &&
          form.t_google_analytics_codigo.value !== '') ||
        form.t_google_analytics.value === 0
      ) {
        this.props.tiendaActualizarGoogleAnalytics(
          {
            t_google_analytics: form.t_google_analytics.value,
            t_google_analytics_codigo: form.t_google_analytics_codigo.value,
          },
          (err, resp) => {
            this.setState({
              forms: {
                ...this.state.forms,
                google_analytics: false,
              },
              alert: {
                ...this.state.alert,
                open: true,
                message: resp.message,
                type: err ? 'error' : 'success',
              },
            });
          }
        );
      } else {
        this.setState({
          forms: {
            ...this.state.forms,
            google_analytics: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message:
              'Debes agregar el ID de seguimiento para activar Google Analytics',
            type: 'error',
          },
        });
      }
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          forms: {
            ...this.state.forms,
            google_analytics: false,
          },
          google_analytics: new_form,
        });
      });
    }
  };

  render() {
    let classes = this.props.classes;
    return (
      <WrapperLayout
        loading={this.state.loading.google_analytics}
        error={this.state.loading.error}
      >
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <form onSubmit={this.handleSubmit}>
              <ModuleTitleUI
                title="Google Analytics"
                image={`${Config.CLOUDFRONT_CDN_PROD}/panel/logos-integraciones/google-analytics-logo.png`}
                subtitles={[
                  <span>
                    <a
                      href="https://support.google.com/analytics/answer/9304153?hl=es"
                      target="_blank"
                      style={{ textDecoration: 'underline' }}
                    >
                      Crea y configura tu cuenta en Google Analytics
                    </a>
                    , una herramienta que te permite medir las ventas, las
                    conversiones y obtener información detallada sobre cómo los
                    visitantes utilizan tu tienda, cómo llegan a ella y qué
                    acciones realizan.
                  </span>,
                ]}
              />
              <Card className={classes.card}>
                <CardContent className={classes.card_content}>
                  <Grid container>
                    <Grid item xs={12} pb={3}>
                      <InputUI
                        handleChange={this.handleChange}
                        input={
                          this.state.google_analytics.t_google_analytics_codigo
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CheckboxUI
                        handleChangeCheckbox={this.handleChangeCheckbox}
                        checkbox={
                          this.state.google_analytics.t_google_analytics
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <ModuleButtonBoxUI>
                <ButtonUI
                  type="submit"
                  label="Guardar"
                  isLoading={this.state.forms.google_analytics}
                />
              </ModuleButtonBoxUI>
            </form>
          </Grid>
        </Grid>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
  card_subheader: theme.card.card_subheader,
  card_subheader_icon: theme.card.card_subheader_icon,
});

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    tiendaActualizarGoogleAnalytics: (data, callback) =>
      dispatch(tiendaActualizarGoogleAnalytics(data, callback)),
  };
};

export const GoogleAnalytics = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(GoogleAnalytics_)
);
