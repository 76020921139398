import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  IconButton,
  Icon,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Validate } from '../../../other';

const PaginasList_ = (props) => {
  let { paginas, classes, modalHandler, handleOrdenar, dominios } = props;
  const router = useHistory();

  const onSortEnd = ({ args }) => {
    const { oldIndex, newIndex } = args;
    if (oldIndex !== newIndex) {
      let paginas_ord = arrayMove(paginas, oldIndex, newIndex);
      paginas_ord = paginas_ord.map((pagina, idx) => {
        return {
          ...pagina,
          pa_orden: idx,
        };
      });
      handleOrdenar(paginas_ord);
    }
  };

  const SortableItem = sortableElement(({ pagina, idx }) => {
    let link;

    if (pagina.pa_tipo) {
      link = Validate.contiene_substring(pagina.pa_link, 'http')
        ? pagina.pa_link
        : 'https://' + dominios.d_nombre + '/' + pagina.pa_link;
    } else {
      link = 'https://' + dominios.d_nombre + '/' + pagina.pa_link;
    }

    return (
      <ListItem
        ContainerComponent="div"
        key={pagina.idPaginas}
        classes={{ root: classes.list_item }}
      >
        <ListItemIcon>
          <DragHandle />
        </ListItemIcon>
        <ListItemText
          classes={{ root: classes.list_text }}
          primary={pagina.pa_titulo}
          secondary={
            <span className={classes.span}>
              <span className={classes.span}>
                {pagina.pa_tipo ? 'Link externo' : 'Página de contenido'}
              </span>
            </span>
          }
        />
        <ListItemSecondaryAction sx={{ right: '5px' }}>
          <IconButton
            aria-label="Ver"
            href={link}
            size="large"
            target="_blank"
            rel="noreferrer"
          >
            <Icon>open_in_new</Icon>
          </IconButton>
          <IconButton
            aria-label="Editar"
            onClick={() => router.push(`/paginas/${pagina.idPaginas}`)}
            size="large"
          >
            <Icon>edit</Icon>
          </IconButton>
          <IconButton
            aria-label="Borrar"
            onClick={(e) => modalHandler(true, 'borrar', pagina)}
            size="large"
          >
            <Icon>delete</Icon>
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  });

  const renderizarPaginas = (paginas) =>
    paginas.map((pagina, index) => (
      <SortableItem
        key={pagina.idPaginas}
        index={index}
        idx={index}
        pagina={pagina}
      />
    ));

  const DragHandle = sortableHandle(() => (
    <Icon classes={{ root: classes.drag_icon }}>drag_indicator</Icon>
  ));

  const SortableContainer = sortableContainer(({ children }) => {
    return (
      <List component="div" classes={{ root: classes.list }}>
        {children}
      </List>
    );
  });

  if (paginas.length) {
    return (
      <SortableContainer
        onSortEnd={(args) => onSortEnd({ args: args })}
        useDragHandle
      >
        {renderizarPaginas(paginas)}
      </SortableContainer>
    );
  } else {
    return (
      <List classes={{ root: classes.list }}>
        <ListItem>
          <ListItemText
            primary="No se han encontrado páginas activas"
            secondary="Puedes agregar una haciendo click en Agregar"
          />
        </ListItem>
      </List>
    );
  }
};

const styles = (theme) => ({
  span: {
    display: 'block',
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  list_item: {
    backgroundColor: '#fff',
    outline: '1px solid #e0e0e0',
  },
  list: {
    padding: '0',
    listStyle: 'none',
  },
  list_text: {
    paddingLeft: '0px',
    paddingRight: '100px',
  },
  drag_icon: {
    cursor: 'move',
    marginRight: '16px',
  },
});

const mapStateToProps = (state) => {
  return {
    paginas: state.paginas,
    auth: state.auth,
    dominios: state.dominios,
  };
};

const mapDispatchToProps = null;

export const PaginasList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(PaginasList_));
