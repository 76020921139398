/* eslint-disable default-case */
import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import async from 'async';
import { connect } from 'react-redux';
import uuid from 'uuid/v1';
import {
  categoriasObtenerFlattenJerarquico,
  tiendaObtener,
  metodosenvioObtener,
  productoAgregar,
  productoObtenerProducto,
} from '../../store/actions';
import { Grid, Fab, Icon } from '@mui/material';
import { Regex, RegexExtra, Validate } from '../../other';
import {
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
  ToggleUI,
  CardModuleUI,
  NextActionsUI,
  ModalUI,
  ChipsInputUI,
  InputUI,
  ModuleCardTitleUI,
} from '../common';
import {
  InfoSection,
  AtributosSection,
  PrecioStockSection,
  DimensionesSection,
  ModalVarianteImagen,
  ModalCategoriasSecundarias,
} from './subcomponents';
import {
  createAmplitudeEventWithDevice,
  amplitude_events,
  AmplitudeRenderErrorUI,
} from '../amplitude';
import * as braze from '@braze/web-sdk';
import { WrapperLayout } from '../../layouts/WrapperLayout';

const es_mobile = Validate.is_mobile();

class ProductoAgregar_ extends Component {
  state = {
    uploading_images: false,
    error: false,
    categorias: true,
    tienda: true,
    producto: true,
    metodos_envio: true,

    after: false,
    after_link: '#!',
    after_id: 0,
    dimensiones_requeridas: false,

    //estos campos se repiten siempre
    common: {
      fotos: {
        validate: false,
        auth: false,
        uploading: false,
        required: true,
        label: '',
        id: 'fotos',
        sublabels: [''],
        files: [],
        messages: {
          help: '',
          error: '',
        },
      },
      categorias_secundarias: {
        validate: false,
        values: [],
        values_back: [],
        id: 'categorias_secundarias',
        name: 'categorias_secundarias',
        label: 'Categorías secundarias',
        change_param: 'common',
        help: '',
      },
      p_nombre: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nombre del producto',
        id: 'p_nombre',
        name: 'p_nombre',
        change_param: 'common',
        regex: Regex.NOMBRE_PRODUCTO,
        value: '',
        default_value: '',
        placeholder: 'Mochila ecológica',
        messages: {
          error: 'Por favor, ingrese un nombre de producto válido.',
          error_extra: RegexExtra.NOMBRE_PRODUCTO,
        },
      },
      idCategorias: {
        validate: false,
        required: true,
        error: false,
        label: 'Categoría del producto',
        id: 'idCategorias',
        name: 'idCategorias',
        change_param: 'common',
        value: null,
        messages: {
          help: '',
          error: 'Por favor, selecciona una categoría para tu producto.',
        },
      },
      p_descripcion: {
        validate: false,
        value: '',
      },
      p_tipo_venta: {
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de venta',
        id: 'p_tipo_venta',
        name: 'p_tipo_venta',
        change_param: 'common',
        value: 0,
        messages: {
          help: '',
          error: 'Por favor, selecciona un tipo de venta para tu producto.',
        },
      },
      p_producto_digital: {
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de producto',
        id: 'p_producto_digital',
        name: 'p_producto_digital',
        change_param: 'common',
        value: 0,
        messages: {
          help: '',
          error: 'Por favor, selecciona un tipo de producto.',
        },
      },
      p_atributos: {
        validate: false,
        required: true,
        error: false,
        label: 'Usar atributos',
        id: 'p_atributos',
        name: 'p_atributos',
        change_param: 'common',
        value: 0,
        messages: {
          error: 'Por favor, selecciona una opción de atributo.',
        },
      },
    },

    //estos campos se usan para autocompletado
    //y para cuando el producto no tiene atributos
    //activos
    atributos_off: {
      p_precio: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Precio',
        id: '',
        name: 'p_precio',
        change_param: 'atributos_off',
        regex: Regex.PRECIO_POSITIVO.ARG,
        value: '',
        default_value: '',
        placeholder: '3300.99',
        messages: {
          error: 'Por favor, ingrese un precio válido.',
          error_extra: RegexExtra.PRECIO_POSITIVO.ARG,
        },
      },
      p_precio_oferta: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Precio oferta',
        id: '',
        name: 'p_precio_oferta',
        change_param: 'atributos_off',
        regex: Regex.PRECIO_POSITIVO.ARG,
        value: '',
        default_value: '',
        placeholder: '2999.99',
        messages: {
          error: 'Por favor, ingrese un precio de oferta válido.',
          error_extra: RegexExtra.PRECIO_POSITIVO.ARG,
        },
      },
      p_mostrar_precio: {
        validate: false,
        name: 'p_mostrar_precio',
        label:
          'Productos sin precio. Se mostrará un botón "Consultar" en lugar de "Agregar al carrito".',
        value: 1,
        change_param: 'atributos_off',
      },
      p_precio_mayorista: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Precio mayorista',
        id: '',
        name: 'p_precio_mayorista',
        change_param: 'atributos_off',
        regex: Regex.PRECIO_POSITIVO.ARG,
        value: '',
        default_value: '',
        placeholder: '3300.99',
        messages: {
          error: 'Por favor, ingrese un precio mayorista válido.',
          error_extra: RegexExtra.PRECIO_POSITIVO.ARG,
        },
      },
      p_cantidad_minima: {
        auth: true,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Cantidad mínima',
        id: '',
        name: 'p_cantidad_minima',
        change_param: 'atributos_off',
        regex: Regex.NUMERO_ENTERO_POSITIVO,
        value: '1',
        default_value: '1',
        placeholder: '1',
        messages: {
          error: 'Por favor, ingrese una cantidad mayorista válida.',
          error_extra: RegexExtra.NUMERO_ENTERO_POSITIVO,
        },
      },
      s_cantidad: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Stock',
        id: '',
        name: 's_cantidad',
        change_param: 'atributos_off',
        regex: Regex.NUMERO_ENTERO,
        value: '',
        default_value: '',
        placeholder: '∞',
        messages: {
          help: 'Si se deja este campo vacío, se considera con stock ilimitado.',
          error: 'Por favor, ingrese una cantidad de stock válida.',
          error_extra: RegexExtra.NUMERO_ENTERO,
        },
      },
      s_sku: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'SKU',
        id: '',
        name: 's_sku',
        change_param: 'atributos_off',
        regex: Regex.SKU,
        value: '',
        default_value: '',
        placeholder: '342131232',
        messages: {
          help: 'Sirve para identificar este producto por código.',
          error: 'Por favor, ingrese un código de artículo válido.',
          error_extra: RegexExtra.SKU,
        },
      },
      s_producto_digital_link: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Link de descarga',
        id: '',
        name: 's_producto_digital_link',
        change_param: 'atributos_off',
        regex: Regex.LINK,
        value: '',
        default_value: '',
        placeholder: 'https://drive.google.com/linkdearchivo',
        messages: {
          help: 'Será el link que se le va a enviar al cliente cuando el pago haya sido finalizado.',
          error: 'Por favor, ingrese un link de descarga válido.',
          error_extra: RegexExtra.LINK,
        },
      },
      s_producto_digital_plazo: {
        auth: true,
        validate: false,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Plazo de entrega',
        id: '',
        name: 's_producto_digital_plazo',
        change_param: 'atributos_off',
        regex: Regex.STRING_GENERICO_2_45,
        value: '',
        default_value: '',
        placeholder: '4 días',
        messages: {
          help: 'Será el plazo aproximado de entrega del producto luego de finalizarse el pago.',
          error: 'Por favor, ingrese un plazo de entrega válido.',
          error_extra: RegexExtra.STRING_GENERICO_2_45,
        },
      },
      s_producto_digital_observacion: {
        auth: true,
        validate: false,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Observación para el cliente',
        id: '',
        name: 's_producto_digital_observacion',
        change_param: 'atributos_off',
        regex: Regex.DESCRIPCION_0_200,
        value: '',
        default_value: '',
        placeholder: 'El link estará disponible por x días',
        messages: {
          help: 'Será enviado al cliente luego de acreditado el pago.',
          error: 'Por favor, ingrese una observación válida.',
          error_extra: RegexExtra.DESCRIPCION_0_200,
        },
      },
      p_alto: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Alto',
        id: '',
        name: 'p_alto',
        change_param: 'atributos_off',
        regex: Regex.NUMERO_ENTERO_POSITIVO,
        value: '',
        default_value: '',
        placeholder: '10',
        messages: {
          error: 'Por favor, ingrese un alto válido',
          error_extra: RegexExtra.NUMERO_ENTERO_POSITIVO,
        },
      },
      p_ancho: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Ancho',
        id: '',
        name: 'p_ancho',
        change_param: 'atributos_off',
        regex: Regex.NUMERO_ENTERO_POSITIVO,
        value: '',
        default_value: '',
        placeholder: '10',
        messages: {
          error: 'Por favor, ingrese un ancho válido',
          error_extra: RegexExtra.NUMERO_ENTERO_POSITIVO,
        },
      },
      p_profundidad: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Profundidad',
        id: '',
        name: 'p_profundidad',
        change_param: 'atributos_off',
        regex: Regex.NUMERO_ENTERO_POSITIVO,
        value: '',
        default_value: '',
        placeholder: '15',
        messages: {
          error: 'Por favor, ingrese una profundidad válida',
          error_extra: RegexExtra.NUMERO_ENTERO_POSITIVO,
        },
      },
      p_peso: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Peso',
        id: '',
        name: 'p_peso',
        change_param: 'atributos_off',
        regex: Regex.PESO,
        value: '',
        default_value: '',
        placeholder: '0.5',
        messages: {
          error: 'Por favor, ingrese un peso válido',
          error_extra: RegexExtra.PESO,
        },
      },
      ui_dimensiones: {
        validate: false,
        required: true,
        error: false,
        label: 'Cargar dimensiones',
        id: 'ui_dimensiones',
        name: 'ui_dimensiones',
        change_param: 'atributos_off',
        value: 0,
        messages: {
          help: '',
          error: 'Por favor, selecciona una opción',
        },
      },
    },

    //estos campos son utilizados para
    //cuando el producto tiene atributos
    atributos_on: {
      atributos: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Atributos',
        id: 'atributos',
        name: 'atributos',
        change_param: 'atributos_on',
        regex: Regex.ATRIBUTO,
        value: '',
        default_value: '',
        placeholder: 'Ej: Talle',
        chips: [],
        chips_back: [],
        messages: {
          help: es_mobile
            ? 'Para agregar atributos, ingrese cada atributo seguido de una coma.'
            : 'Para agregar atributos, ingrese cada atributo seguido de un enter o una coma.',
          error: 'Por favor, ingrese un nombre de atributo válido.',
          error_extra: RegexExtra.ATRIBUTO,
        },
      },
      stock: [],
    },

    //campos solo administrativos
    imagen_variante: {
      change_param: null,
      link: null,
      variante: null,
    },

    forms: {
      agregar: false,
    },
    selects: {
      p_atributos: [
        {
          value: 0,
          label: 'No',
        },
        {
          value: 1,
          label: 'Si',
        },
      ],
      ui_dimensiones: [
        {
          value: 0,
          label: 'No',
        },
        {
          value: 1,
          label: 'Si',
        },
      ],
      p_tipo_venta: [
        {
          value: 0,
          label: 'Solo minorista',
        },
        {
          value: 1,
          label: 'Solo mayorista',
        },
        {
          value: 2,
          label: 'Minorista y mayorista',
        },
      ],
      p_producto_digital: [
        {
          value: 0,
          label: 'Producto físico',
        },
        {
          value: 1,
          label: 'Producto digital (descarga automática)',
        },
        {
          value: 2,
          label: 'Producto digital (envío manual)',
        },
      ],
      categorias: [],
    },
    modals: {
      configurar_atributos: false,
      imagen_variante: false,
      categorias_secundarias: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  componentDidMount() {
    async.parallel(
      [
        (callback) => {
          this.props.categoriasObtenerFlattenJerarquico((err, resp) => {
            let idCategoriaPrimera = resp.data.length
              ? resp.data[0].idCategorias
              : null;
            this.setState(
              {
                error: err ? true : this.state.error,
                categorias: false,
                selects: {
                  ...this.state.selects,
                  categorias: resp.data,
                },
                common: {
                  ...this.state.common,
                  idCategorias: {
                    ...this.state.common.idCategorias,
                    value: idCategoriaPrimera,
                  },
                  categorias_secundarias: {
                    ...this.state.common.categorias_secundarias,
                    values: idCategoriaPrimera ? [idCategoriaPrimera] : [],
                    values_back: idCategoriaPrimera ? [idCategoriaPrimera] : [],
                  },
                },
              },
              () => {
                if (err) {
                  callback(new Error());
                } else {
                  callback();
                }
              }
            );
          });
        },
        (callback) => {
          this.props.tiendaObtener((err, resp) => {
            const idCountry =
              this.props.tienda && this.props.tienda.Country_idCountry
                ? this.props.tienda.Country_idCountry
                : 'ARG';

            this.setState(
              {
                error: err ? true : this.state.error,
                tienda: false,
                atributos_off: {
                  ...this.state.atributos_off,
                  p_precio: {
                    ...this.state.atributos_off.p_precio,
                    regex: Regex.PRECIO_POSITIVO[idCountry],
                    placeholder: idCountry === 'COL' ? '3300' : '3300.99',
                    messages: {
                      ...this.state.atributos_off.p_precio.messages,
                      error_extra: RegexExtra.PRECIO_POSITIVO[idCountry],
                    },
                  },
                  p_precio_oferta: {
                    ...this.state.atributos_off.p_precio_oferta,
                    regex: Regex.PRECIO_POSITIVO[idCountry],
                    placeholder: idCountry === 'COL' ? '2999' : '2999.99',
                    messages: {
                      ...this.state.atributos_off.p_precio.messages,
                      error_extra: RegexExtra.PRECIO_POSITIVO[idCountry],
                    },
                  },
                  p_precio_mayorista: {
                    ...this.state.atributos_off.p_precio_mayorista,
                    regex: Regex.PRECIO_POSITIVO[idCountry],
                    placeholder: idCountry === 'COL' ? '3300' : '3300.99',
                    messages: {
                      ...this.state.atributos_off.p_precio.messages,
                      error_extra: RegexExtra.PRECIO_POSITIVO[idCountry],
                    },
                  },
                },
              },
              () => {
                if (err) {
                  callback(new Error());
                } else {
                  callback();
                }
              }
            );
          });
        },
        (callback) => {
          this.props.metodosenvioObtener((err, resp) => {
            if (err) {
              this.setState(
                {
                  error: true,
                  metodos_envio: false,
                },
                () => callback(new Error())
              );
            } else {
              let metodos_envio = resp.data;
              let atributos_off = this.state.atributos_off;

              let dimensiones_requeridas =
                Validate.dimensiones_requeridas(metodos_envio);

              if (dimensiones_requeridas) {
                atributos_off = Validate.bulk_enable_disable_input(
                  atributos_off,
                  ['p_alto', 'p_ancho', 'p_profundidad', 'p_peso'],
                  [],
                  [],
                  true
                );
              }

              this.setState(
                {
                  error: false,
                  metodos_envio: false,
                  dimensiones_requeridas: dimensiones_requeridas,
                  atributos_off: {
                    ...atributos_off,
                    ui_dimensiones: {
                      ...atributos_off.ui_dimensiones,
                      value: dimensiones_requeridas ? 1 : 0,
                    },
                  },
                },
                () => callback()
              );
            }
          });
        },
      ],
      (err, results) => {
        if (err) {
          this.setState({ producto: false, error: true });
        } else {
          let idProductos = Validate.trim_int(
            this.props.match.params.idProductos
          );
          if (idProductos) {
            //duplico producto
            this.duplicateProductState(idProductos);
          } else {
            //agrego producto normal
            this.setState({ producto: false, error: false });
          }
        }
      }
    );
  }

  componentDidUpdate(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      window.location.reload();
    }
  }

  duplicateProductState = (idProductos) => {
    this.props.productoObtenerProducto(
      { idProductos: idProductos },
      (err, resp) => {
        if (err) {
          this.setState({
            error: true,
            producto: false,
          });
        } else {
          let producto = resp.data;
          let { common, atributos_off, atributos_on } = this.state;

          //ETAPA 1
          //cargo la categoria principal del producto
          common = {
            ...common,
            idCategorias: {
              ...common.idCategorias,
              value: producto.Categorias_idCategorias,
            },
          };
          //fin cargo la categoria principal del producto

          //ETAPA 2
          //cargo las categorias secundarias del producto
          let categorias_secundarias = producto.categorias_productos.map(
            (cat_prod) => cat_prod.idCategorias
          );
          common = {
            ...common,
            categorias_secundarias: {
              ...common.categorias_secundarias,
              values: categorias_secundarias,
              values_back: categorias_secundarias,
            },
          };
          //fin cargo las categorias secundarias del producto

          //ETAPA 3
          //cargo el resto de los valores de common
          common = Validate.cargar_formulario_sin_nulos_with_excluded_sync(
            producto,
            common,
            ['p_nombre', 'fotos', 'categorias_secundarias']
          );
          //fin cargo el resto de los valores de common

          //ETAPA 4
          //cargo los atributos off
          //cargo las validaciones segun el tipo de producto
          let enable_arr = [],
            disable_arr = [],
            disable_disable_arr = [];

          //p_tipo_venta
          switch (producto.p_tipo_venta) {
            //0 -> minorista
            case 0:
              enable_arr = producto.p_mostrar_precio
                ? ['p_precio', 'p_precio_oferta']
                : [];
              disable_arr = ['p_precio_mayorista', 'p_cantidad_minima'];
              disable_disable_arr = producto.p_mostrar_precio
                ? []
                : ['p_precio', 'p_precio_oferta'];
              break;

            //1 -> mayorista
            case 1:
              enable_arr = ['p_precio_mayorista', 'p_cantidad_minima'];
              disable_arr = producto.p_mostrar_precio
                ? ['p_precio', 'p_precio_oferta']
                : [];
              disable_disable_arr = producto.p_mostrar_precio
                ? []
                : ['p_precio', 'p_precio_oferta'];
              break;

            //2 -> minorista y mayorista
            case 2:
              enable_arr = producto.p_mostrar_precio
                ? [
                    'p_precio_mayorista',
                    'p_cantidad_minima',
                    'p_precio',
                    'p_precio_oferta',
                  ]
                : ['p_precio_mayorista', 'p_cantidad_minima'];
              disable_arr = [];
              disable_disable_arr = producto.p_mostrar_precio
                ? []
                : ['p_precio', 'p_precio_oferta'];
              break;
          }

          let ui_dimensiones =
            atributos_off.ui_dimensiones.value || producto.p_peso > 0 ? 1 : 0;

          atributos_off = {
            ...atributos_off,
            ui_dimensiones: {
              ...atributos_off.ui_dimensiones,
              value: ui_dimensiones,
            },
          };

          //p_producto_digital
          switch (producto.p_producto_digital) {
            //0 -> Producto físico
            case 0:
              enable_arr = ui_dimensiones
                ? [
                    ...enable_arr,
                    'p_alto',
                    'p_ancho',
                    'p_profundidad',
                    'p_peso',
                  ]
                : [...enable_arr];
              disable_arr = ui_dimensiones
                ? [
                    ...disable_arr,
                    's_producto_digital_link',
                    's_producto_digital_plazo',
                    's_producto_digital_observacion',
                  ]
                : [
                    ...disable_arr,
                    's_producto_digital_link',
                    's_producto_digital_plazo',
                    's_producto_digital_observacion',
                    'p_alto',
                    'p_ancho',
                    'p_profundidad',
                    'p_peso',
                  ];
              break;

            //1 -> Producto digital (descarga automática)
            case 1:
              enable_arr = [
                ...enable_arr,
                's_producto_digital_link',
                's_producto_digital_observacion',
              ];
              disable_arr = [
                ...disable_arr,
                'p_alto',
                'p_ancho',
                'p_profundidad',
                'p_peso',
                's_producto_digital_plazo',
              ];
              break;

            //2 -> Producto digital (envío manual)
            case 2:
              enable_arr = [
                ...enable_arr,
                's_producto_digital_plazo',
                's_producto_digital_observacion',
              ];
              disable_arr = [
                ...disable_arr,
                'p_alto',
                'p_ancho',
                'p_profundidad',
                'p_peso',
                's_producto_digital_link',
              ];
              break;
          }

          atributos_off = Validate.bulk_enable_disable_input(
            atributos_off,
            enable_arr,
            disable_arr,
            disable_disable_arr,
            true
          );
          let load_data_atributos_off = producto.stock.length
            ? {
                ...producto,
                ...producto.stock[0],
              }
            : producto;

          if (producto.stock.length) {
            load_data_atributos_off.s_cantidad =
              load_data_atributos_off.s_ilimitado
                ? ''
                : load_data_atributos_off.s_cantidad;
          }
          load_data_atributos_off.p_precio = load_data_atributos_off.p_precio
            ? load_data_atributos_off.p_precio
            : '';
          load_data_atributos_off.p_precio_oferta =
            load_data_atributos_off.p_oferta === 0
              ? ''
              : load_data_atributos_off.p_precio_oferta;
          load_data_atributos_off.p_precio_mayorista =
            load_data_atributos_off.p_precio_mayorista
              ? load_data_atributos_off.p_precio_mayorista
              : '';
          load_data_atributos_off.p_peso =
            load_data_atributos_off.p_peso === 0
              ? ''
              : load_data_atributos_off.p_peso;
          load_data_atributos_off.p_alto =
            load_data_atributos_off.p_alto === 0
              ? ''
              : load_data_atributos_off.p_alto;
          load_data_atributos_off.p_ancho =
            load_data_atributos_off.p_ancho === 0
              ? ''
              : load_data_atributos_off.p_ancho;
          load_data_atributos_off.p_profundidad =
            load_data_atributos_off.p_profundidad === 0
              ? ''
              : load_data_atributos_off.p_profundidad;

          atributos_off = Validate.cargar_formulario_sin_nulos_sync(
            load_data_atributos_off,
            atributos_off
          );

          //ETAPA 5
          let chips = producto.atributos.map((atributo) => {
            return {
              key: 'at_' + Validate.trim_keyfy(atributo.at_nombre),
              value: atributo.at_nombre,
            };
          });
          atributos_on = {
            ...atributos_on,
            atributos: {
              ...atributos_on.atributos,
              auth: true,
              chips: chips,
              chips_back: chips,
              value: '',
            },
          };

          this.setState(
            {
              common: common,
              atributos_off: atributos_off,
              atributos_on: atributos_on,
            },
            () => {
              if (producto.stock.length) {
                let stock = producto.stock.map((stock_item) => {
                  let inputs = this.crearBloqueConAtributos();

                  stock_item.valoratributo.forEach((stk_item_vat) => {
                    stock_item[
                      'at_' + Validate.trim_keyfy(stk_item_vat.at_nombre)
                    ] = stk_item_vat.valor.vat_valor;
                  });

                  stock_item.s_cantidad = stock_item.s_ilimitado
                    ? ''
                    : stock_item.s_cantidad;
                  stock_item.s_precio = stock_item.s_precio
                    ? stock_item.s_precio
                    : '';
                  stock_item.s_precio_oferta =
                    stock_item.s_oferta === 0 ? '' : stock_item.s_precio_oferta;
                  stock_item.s_precio_mayorista = stock_item.s_precio_mayorista
                    ? stock_item.s_precio_mayorista
                    : '';
                  stock_item.s_peso =
                    stock_item.s_peso === 0 ? '' : stock_item.s_peso;
                  stock_item.s_alto =
                    stock_item.s_alto === 0 ? '' : stock_item.s_alto;
                  stock_item.s_ancho =
                    stock_item.s_ancho === 0 ? '' : stock_item.s_ancho;
                  stock_item.s_profundidad =
                    stock_item.s_profundidad === 0
                      ? ''
                      : stock_item.s_profundidad;

                  return Validate.cargar_formulario_sin_nulos_with_excluded_sync(
                    stock_item,
                    inputs,
                    ['s_imagen']
                  );
                });

                this.setState({
                  error: false,
                  producto: false,
                  atributos_on: {
                    ...atributos_on,
                    stock: stock,
                  },
                });
              } else {
                this.setState({
                  error: false,
                  producto: false,
                });
              }
            }
          );
        }
      }
    );
  };

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      {
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: input,
            },
          });
        });
      }
    );
  };

  handleChipChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;
    let last_char = value[value.length ? value.length - 1 : 0];

    if (value.length - 1 > 0 && (last_char === '\n' || last_char === ',')) {
      if (this.state[aditional][name].auth) {
        let chips = this.state.atributos_on.atributos.chips;
        let new_chip_key =
          'at_' + Validate.trim_keyfy(this.state.atributos_on.atributos.value);
        let new_chip = Validate.trim_ucwords(
          this.state.atributos_on.atributos.value
        );

        //reviso que no exista en la lista de chips
        let existe = chips.filter((chip) => chip.key === new_chip_key).length
          ? true
          : false;

        if (!existe) {
          this.setState({
            atributos_on: {
              ...this.state.atributos_on,
              atributos: {
                ...this.state.atributos_on.atributos,
                chips: [
                  ...chips,
                  {
                    key: new_chip_key,
                    value: new_chip,
                  },
                ],
                value: '',
              },
            },
          });
        } else {
          this.setState({
            alert: {
              open: true,
              message: 'Los atributos no pueden repetirse',
              type: 'error',
            },
          });
        }
      } else {
        Validate.validate_input(this.state[aditional][name], true, (input) => {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: input,
            },
          });
        });
      }
    } else {
      this.setState(
        {
          [aditional]: {
            ...this.state[aditional],
            [name]: {
              ...this.state[aditional][name],
              value: value,
            },
          },
        },
        () => {
          Validate.validate_input(
            this.state[aditional][name],
            blur,
            (input) => {
              if (value.length) {
                this.setState(
                  {
                    [aditional]: {
                      ...this.state[aditional],
                      [name]: input,
                    },
                  },
                  () => {
                    if (blur) {
                      setTimeout(() => {
                        this.setState({
                          alert: {
                            ...this.state.alert,
                            open: true,
                            type: 'error',
                            message:
                              'Debes escribir el atributo seguido de un enter ó coma',
                          },
                        });
                      }, 200);
                    }
                  }
                );
              } else {
                this.setState({
                  [aditional]: {
                    ...this.state[aditional],
                    [name]: {
                      ...input,
                      error: false,
                    },
                  },
                });
              }
            }
          );
        }
      );
    }
  };

  handleChipDelete = (remove_chip, index) => {
    let chips = this.state.atributos_on.atributos.chips.filter(
      (chip) => chip.key !== remove_chip.key
    );
    this.setState({
      atributos_on: {
        ...this.state.atributos_on,
        atributos: {
          ...this.state.atributos_on.atributos,
          error: false,
          chips: chips,
          value: '',
        },
      },
    });
  };

  handleChangeSelect = (e, aditional) => {
    let value = Validate.trim_int(e.target.value);
    let name = e.target.name;
    let enable_arr,
      disable_arr,
      disable_disable_arr,
      enable_arr_stock,
      disable_arr_stock,
      disable_disable_arr_stock;
    let atributos_off, stock, dimensiones;

    switch (name) {
      case 'p_tipo_venta':
        let mostrar_precio = this.state.atributos_off.p_mostrar_precio.value;

        switch (value) {
          //0 -> minorista
          case 0:
            enable_arr = mostrar_precio ? ['p_precio', 'p_precio_oferta'] : [];
            disable_arr = ['p_precio_mayorista', 'p_cantidad_minima'];
            disable_disable_arr = mostrar_precio
              ? []
              : ['p_precio', 'p_precio_oferta'];

            enable_arr_stock = mostrar_precio
              ? ['s_precio', 's_precio_oferta']
              : [];
            disable_arr_stock = ['s_precio_mayorista', 's_cantidad_minima'];
            disable_disable_arr_stock = mostrar_precio
              ? []
              : ['s_precio', 's_precio_oferta'];
            break;

          //1 -> mayorista
          case 1:
            enable_arr = ['p_precio_mayorista', 'p_cantidad_minima'];
            disable_arr = mostrar_precio ? ['p_precio', 'p_precio_oferta'] : [];
            disable_disable_arr = mostrar_precio
              ? []
              : ['p_precio', 'p_precio_oferta'];

            enable_arr_stock = ['s_precio_mayorista', 's_cantidad_minima'];
            disable_arr_stock = mostrar_precio
              ? ['s_precio', 's_precio_oferta']
              : [];
            disable_disable_arr_stock = mostrar_precio
              ? []
              : ['s_precio', 's_precio_oferta'];
            break;

          //2 -> minorista y mayorista
          case 2:
            enable_arr = mostrar_precio
              ? [
                  'p_precio_mayorista',
                  'p_cantidad_minima',
                  'p_precio',
                  'p_precio_oferta',
                ]
              : ['p_precio_mayorista', 'p_cantidad_minima'];
            disable_arr = [];
            disable_disable_arr = mostrar_precio
              ? []
              : ['p_precio', 'p_precio_oferta'];

            enable_arr_stock = mostrar_precio
              ? [
                  's_precio_mayorista',
                  's_cantidad_minima',
                  's_precio',
                  's_precio_oferta',
                ]
              : ['s_precio_mayorista', 's_cantidad_minima'];
            disable_arr_stock = [];
            disable_disable_arr_stock = mostrar_precio
              ? []
              : ['s_precio', 's_precio_oferta'];
            break;
        }

        atributos_off = Validate.bulk_enable_disable_input(
          this.state.atributos_off,
          enable_arr,
          disable_arr,
          disable_disable_arr,
          true
        );

        stock = this.state.atributos_on.stock.map((inputs) => {
          return Validate.bulk_enable_disable_input(
            inputs,
            enable_arr_stock,
            disable_arr_stock,
            disable_disable_arr_stock,
            true
          );
        });

        this.setState({
          [aditional]: {
            ...this.state[aditional],
            [name]: {
              ...this.state[aditional][name],
              value: value,
            },
          },
          atributos_off: atributos_off,
          atributos_on: {
            ...this.state.atributos_on,
            stock: stock,
          },
        });

        break;

      case 'p_atributos':
        if (value) {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: {
                ...this.state[aditional][name],
                value: value,
              },
            },
            modals: {
              ...this.state.modals,
              configurar_atributos: true,
            },
          });
        } else {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: {
                ...this.state[aditional][name],
                value: value,
              },
            },
            atributos_on: {
              ...this.state.atributos_on,
              atributos: {
                ...this.state.atributos_on.atributos,
                error: false,
                chips: [],
                chips_back: [],
                value: '',
              },
              stock: [],
            },
          });
        }

        break;

      case 'p_producto_digital':
        dimensiones = this.state.atributos_off.ui_dimensiones.value;

        switch (value) {
          //0 -> Producto físico
          case 0:
            enable_arr = dimensiones
              ? ['p_alto', 'p_ancho', 'p_profundidad', 'p_peso']
              : [];
            disable_arr = dimensiones
              ? [
                  's_producto_digital_link',
                  's_producto_digital_plazo',
                  's_producto_digital_observacion',
                ]
              : [
                  's_producto_digital_link',
                  's_producto_digital_plazo',
                  's_producto_digital_observacion',
                  'p_alto',
                  'p_ancho',
                  'p_profundidad',
                  'p_peso',
                ];
            disable_disable_arr = [];

            enable_arr_stock = dimensiones
              ? ['s_alto', 's_ancho', 's_profundidad', 's_peso']
              : [];
            disable_arr_stock = dimensiones
              ? [
                  's_producto_digital_link',
                  's_producto_digital_plazo',
                  's_producto_digital_observacion',
                ]
              : [
                  's_producto_digital_link',
                  's_producto_digital_plazo',
                  's_producto_digital_observacion',
                  's_alto',
                  's_ancho',
                  's_profundidad',
                  's_peso',
                ];
            disable_disable_arr_stock = [];
            break;

          //1 -> Producto digital (descarga automática)
          case 1:
            enable_arr = [
              's_producto_digital_link',
              's_producto_digital_observacion',
            ];
            disable_arr = [
              'p_alto',
              'p_ancho',
              'p_profundidad',
              'p_peso',
              's_producto_digital_plazo',
            ];
            disable_disable_arr = [];

            enable_arr_stock = [
              's_producto_digital_link',
              's_producto_digital_observacion',
            ];
            disable_arr_stock = [
              's_alto',
              's_ancho',
              's_profundidad',
              's_peso',
              's_producto_digital_plazo',
            ];
            disable_disable_arr_stock = [];
            break;

          //2 -> Producto digital (envío manual)
          case 2:
            enable_arr = [
              's_producto_digital_plazo',
              's_producto_digital_observacion',
            ];
            disable_arr = [
              'p_alto',
              'p_ancho',
              'p_profundidad',
              'p_peso',
              's_producto_digital_link',
            ];
            disable_disable_arr = [];

            enable_arr_stock = [
              's_producto_digital_plazo',
              's_producto_digital_observacion',
            ];
            disable_arr_stock = [
              's_alto',
              's_ancho',
              's_profundidad',
              's_peso',
              's_producto_digital_link',
            ];
            disable_disable_arr_stock = [];
            break;
        }

        atributos_off = Validate.bulk_enable_disable_input(
          this.state.atributos_off,
          enable_arr,
          disable_arr,
          disable_disable_arr,
          true
        );

        stock = this.state.atributos_on.stock.map((inputs) => {
          return Validate.bulk_enable_disable_input(
            inputs,
            enable_arr_stock,
            disable_arr_stock,
            disable_disable_arr_stock,
            true
          );
        });

        this.setState({
          [aditional]: {
            ...this.state[aditional],
            [name]: {
              ...this.state[aditional][name],
              value: value,
            },
          },
          atributos_off: atributos_off,
          atributos_on: {
            ...this.state.atributos_on,
            stock: stock,
          },
        });

        break;

      case 'ui_dimensiones':
        if (value) {
          enable_arr = ['p_alto', 'p_ancho', 'p_profundidad', 'p_peso'];
          disable_arr = [];
          disable_disable_arr = [];

          enable_arr_stock = ['s_alto', 's_ancho', 's_profundidad', 's_peso'];
          disable_arr_stock = [];
          disable_disable_arr_stock = [];
        } else {
          enable_arr = [];
          disable_arr = ['p_alto', 'p_ancho', 'p_profundidad', 'p_peso'];
          disable_disable_arr = [];

          enable_arr_stock = [];
          disable_arr_stock = ['s_alto', 's_ancho', 's_profundidad', 's_peso'];
          disable_disable_arr_stock = [];
        }

        atributos_off = Validate.bulk_enable_disable_input(
          this.state.atributos_off,
          enable_arr,
          disable_arr,
          disable_disable_arr,
          true
        );

        stock = this.state.atributos_on.stock.map((inputs) => {
          return Validate.bulk_enable_disable_input(
            inputs,
            enable_arr_stock,
            disable_arr_stock,
            disable_disable_arr_stock,
            true
          );
        });

        this.setState({
          atributos_off: {
            ...atributos_off,
            ui_dimensiones: {
              ...atributos_off.ui_dimensiones,
              value: value,
            },
          },
          atributos_on: {
            ...this.state.atributos_on,
            stock: stock,
          },
        });

        break;

      case 'idCategorias':
        let idCategoriasViejo = this.state.common.idCategorias.value;
        let categorias_secundarias =
          this.state.common.categorias_secundarias.values;

        if (idCategoriasViejo !== value) {
          categorias_secundarias = categorias_secundarias.filter(
            (idCat) => idCat !== idCategoriasViejo
          );
          if (categorias_secundarias.indexOf(value) === -1) {
            categorias_secundarias = [...categorias_secundarias, value];
          }
        }

        this.setState({
          common: {
            ...this.state.common,
            idCategorias: {
              ...this.state.common.idCategorias,
              value: value,
            },
            categorias_secundarias: {
              ...this.state.common.categorias_secundarias,
              values: categorias_secundarias,
              values_back: categorias_secundarias,
            },
          },
        });
        break;

      default:
        this.setState({
          [aditional]: {
            ...this.state[aditional],
            [name]: {
              ...this.state[aditional][name],
              value: value,
            },
          },
        });
        break;
    }
  };

  handleChangeMultipleSelect = (e, aditional) => {
    let values = e.target.value;

    if (!Validate.in_array(this.state.common.idCategorias.value, values)) {
      values = [this.state.common.idCategorias.value, ...values];
    }

    this.setState({
      common: {
        ...this.state.common,
        categorias_secundarias: {
          ...this.state.common.categorias_secundarias,
          values: values,
        },
      },
    });
  };

  handleChangeQuill = (value) => {
    this.setState({
      common: {
        ...this.state.common,
        p_descripcion: {
          ...this.state.common.p_descripcion,
          value: value,
        },
      },
    });
  };

  handleChangeFile = (data) => {
    if (data.uploading) {
      this.setState({
        uploading_images: data.uploading,
      });
    } else {
      if (this.state.atributos_on.stock.length) {
        let files = data.files;

        let stock = this.state.atributos_on.stock.map((inputs) => {
          if (files.length) {
            if (Validate.in_array_obj(inputs.s_imagen.link, files, 'link')) {
              return inputs;
            } else {
              return {
                ...inputs,
                s_imagen: {
                  ...inputs.s_imagen,
                  link: files[0].link,
                },
              };
            }
          } else {
            return {
              ...inputs,
              s_imagen: {
                ...inputs.s_imagen,
                link: null,
              },
            };
          }
        });

        this.setState({
          uploading_images: data.uploading,
          common: {
            ...this.state.common,
            fotos: data,
          },
          atributos_on: {
            ...this.state.atributos_on,
            stock: stock,
          },
        });
      } else {
        this.setState({
          uploading_images: data.uploading,
          common: {
            ...this.state.common,
            fotos: data,
          },
        });
      }
    }
  };

  handleChangeCheckbox = (e, aditional) => {
    let name = e.target.name;
    let value = this.state[aditional][name]['value'] ? 0 : 1;
    let enable_arr,
      disable_arr,
      disable_disable_arr,
      enable_arr_stock,
      disable_arr_stock,
      disable_disable_arr_stock;

    if (value) {
      //con precio
      enable_arr = ['p_precio', 'p_precio_oferta'];
      disable_arr = [];
      disable_disable_arr = [];
      enable_arr_stock = ['s_precio', 's_precio_oferta'];
      disable_arr_stock = [];
      disable_disable_arr_stock = [];
    } else {
      //sin precio
      enable_arr = [];
      disable_arr = [];
      disable_disable_arr = ['p_precio', 'p_precio_oferta'];
      enable_arr_stock = [];
      disable_arr_stock = [];
      disable_disable_arr_stock = ['s_precio', 's_precio_oferta'];
    }

    let atributos_off = Validate.bulk_enable_disable_input(
      this.state.atributos_off,
      enable_arr,
      disable_arr,
      disable_disable_arr,
      true
    );

    let stock = this.state.atributos_on.stock.map((inputs) => {
      inputs = {
        ...inputs,
        s_mostrar_precio: {
          ...inputs.s_mostrar_precio,
          value: value,
        },
      };
      return Validate.bulk_enable_disable_input(
        inputs,
        enable_arr_stock,
        disable_arr_stock,
        disable_disable_arr_stock,
        true
      );
    });

    this.setState({
      atributos_off: {
        ...atributos_off,
        p_mostrar_precio: {
          ...atributos_off.p_mostrar_precio,
          value: value,
        },
      },
      atributos_on: {
        ...this.state.atributos_on,
        stock: stock,
      },
    });
  };

  handleChangeDynamicInput = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;
    let edit_input = aditional;

    let stock = this.state.atributos_on.stock.map((inputs) => {
      if (inputs.s_precio.change_param === edit_input) {
        inputs[name].value = value;
        inputs[name] = Validate.validate_input_sync(inputs[name], blur);
        return inputs;
      } else {
        return inputs;
      }
    });

    this.setState({
      atributos_on: {
        ...this.state.atributos_on,
        stock: stock,
      },
    });
  };

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  crearObjetoAtributosDinamicos = (change_param, chips = []) => {
    let atributos_inputs = {};
    let atributo_femenino, error_msg;

    let atributos_chips = chips.length
      ? chips
      : this.state.atributos_on.atributos.chips;

    atributos_chips.forEach(({ key, value }) => {
      atributo_femenino = value[value.length - 1] === 'a' ? true : false;
      error_msg =
        'Por favor, ingrese un' +
        (atributo_femenino ? 'a' : '') +
        ' ' +
        value.toLowerCase() +
        ' válid' +
        (atributo_femenino ? 'a' : 'o');

      atributos_inputs[key] = {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: value,
        id: '',
        name: key,
        change_param: change_param,
        regex: Regex.VALOR_ATRIBUTO,
        value: '',
        default_value: '',
        placeholder: value,
        messages: {
          error: error_msg,
          error_extra: RegexExtra.VALOR_ATRIBUTO,
        },
      };
    });

    return atributos_inputs;
  };

  crearBloqueConAtributos = () => {
    let {
      p_precio,
      p_precio_oferta,
      p_mostrar_precio,
      p_precio_mayorista,
      p_cantidad_minima,
      s_cantidad,
      s_sku,
      s_producto_digital_link,
      s_producto_digital_plazo,
      s_producto_digital_observacion,
      p_alto,
      p_ancho,
      p_profundidad,
      p_peso,
    } = this.state.atributos_off;

    let change_param = uuid().split('-')[0];

    let atributos_inputs = this.crearObjetoAtributosDinamicos(change_param);

    return {
      ...atributos_inputs,
      opciones_avanzadas: {
        validate: false,
        state: false,
      },
      s_imagen: {
        validate: false,
        link: this.state.common.fotos.files.length
          ? this.state.common.fotos.files[0].link
          : null,
      },
      s_precio: {
        ...p_precio,
        name: 's_precio',
        change_param: change_param,
        messages: {
          ...p_precio.messages,
          help: '',
        },
      },
      s_precio_oferta: {
        ...p_precio_oferta,
        name: 's_precio_oferta',
        change_param: change_param,
        messages: {
          ...p_precio_oferta.messages,
          help: '',
        },
      },
      s_mostrar_precio: {
        ...p_mostrar_precio,
        name: 's_mostrar_precio',
        change_param: change_param,
        messages: {
          ...p_mostrar_precio.messages,
          help: '',
        },
      },
      s_precio_mayorista: {
        ...p_precio_mayorista,
        name: 's_precio_mayorista',
        change_param: change_param,
        messages: {
          ...p_precio_mayorista.messages,
          help: '',
        },
      },
      s_cantidad_minima: {
        ...p_cantidad_minima,
        auth: true,
        value: '1',
        name: 's_cantidad_minima',
        change_param: change_param,
        messages: {
          ...p_cantidad_minima.messages,
          help: '',
        },
      },
      s_cantidad: {
        ...s_cantidad,
        name: 's_cantidad',
        change_param: change_param,
        messages: {
          ...s_cantidad.messages,
          help: '',
        },
      },
      s_sku: {
        ...s_sku,
        name: 's_sku',
        change_param: change_param,
        messages: {
          ...s_sku.messages,
          help: '',
        },
      },
      s_producto_digital_link: {
        ...s_producto_digital_link,
        name: 's_producto_digital_link',
        change_param: change_param,
        messages: {
          ...s_producto_digital_link.messages,
          help: '',
        },
      },
      s_producto_digital_plazo: {
        ...s_producto_digital_plazo,
        name: 's_producto_digital_plazo',
        change_param: change_param,
        messages: {
          ...s_producto_digital_plazo.messages,
          help: '',
        },
      },
      s_producto_digital_observacion: {
        ...s_producto_digital_observacion,
        name: 's_producto_digital_observacion',
        change_param: change_param,
        messages: {
          ...s_producto_digital_observacion.messages,
          help: '',
        },
      },
      s_alto: {
        ...p_alto,
        name: 's_alto',
        change_param: change_param,
        messages: {
          ...p_alto.messages,
          help: '',
        },
      },
      s_ancho: {
        ...p_ancho,
        name: 's_ancho',
        change_param: change_param,
        messages: {
          ...p_ancho.messages,
          help: '',
        },
      },
      s_profundidad: {
        ...p_profundidad,
        name: 's_profundidad',
        change_param: change_param,
        messages: {
          ...p_profundidad.messages,
          help: '',
        },
      },
      s_peso: {
        ...p_peso,
        name: 's_peso',
        change_param: change_param,
        messages: {
          ...p_peso.messages,
          help: '',
        },
      },
    };
  };

  propagarBloquesConAtributos = (stock) => {
    let {
      p_precio,
      p_precio_oferta,
      p_mostrar_precio,
      p_precio_mayorista,
      s_cantidad,
      s_sku,
      s_producto_digital_link,
      s_producto_digital_plazo,
      s_producto_digital_observacion,
      p_alto,
      p_ancho,
      p_profundidad,
      p_peso,
    } = this.state.atributos_off;

    return stock.map((inputs) => {
      let change_param = inputs.s_precio.change_param;

      return {
        ...inputs,
        s_precio: {
          ...p_precio,
          name: 's_precio',
          change_param: change_param,
          messages: {
            ...p_precio.messages,
            help: '',
          },
        },
        s_precio_oferta: {
          ...p_precio_oferta,
          name: 's_precio_oferta',
          change_param: change_param,
          messages: {
            ...p_precio_oferta.messages,
            help: '',
          },
        },
        s_mostrar_precio: {
          ...p_mostrar_precio,
          name: 's_mostrar_precio',
          change_param: change_param,
          messages: {
            ...p_mostrar_precio.messages,
            help: '',
          },
        },
        s_precio_mayorista: {
          ...p_precio_mayorista,
          name: 's_precio_mayorista',
          change_param: change_param,
          messages: {
            ...p_precio_mayorista.messages,
            help: '',
          },
        },
        s_cantidad: {
          ...s_cantidad,
          name: 's_cantidad',
          change_param: change_param,
          messages: {
            ...s_cantidad.messages,
            help: '',
          },
        },
        s_sku: {
          ...s_sku,
          name: 's_sku',
          change_param: change_param,
          messages: {
            ...s_sku.messages,
            help: '',
          },
        },
        s_producto_digital_link: {
          ...s_producto_digital_link,
          name: 's_producto_digital_link',
          change_param: change_param,
          messages: {
            ...s_producto_digital_link.messages,
            help: '',
          },
        },
        s_producto_digital_plazo: {
          ...s_producto_digital_plazo,
          name: 's_producto_digital_plazo',
          change_param: change_param,
          messages: {
            ...s_producto_digital_plazo.messages,
            help: '',
          },
        },
        s_producto_digital_observacion: {
          ...s_producto_digital_observacion,
          name: 's_producto_digital_observacion',
          change_param: change_param,
          messages: {
            ...s_producto_digital_observacion.messages,
            help: '',
          },
        },
        s_alto: {
          ...p_alto,
          name: 's_alto',
          change_param: change_param,
          messages: {
            ...p_alto.messages,
            help: '',
          },
        },
        s_ancho: {
          ...p_ancho,
          name: 's_ancho',
          change_param: change_param,
          messages: {
            ...p_ancho.messages,
            help: '',
          },
        },
        s_profundidad: {
          ...p_profundidad,
          name: 's_profundidad',
          change_param: change_param,
          messages: {
            ...p_profundidad.messages,
            help: '',
          },
        },
        s_peso: {
          ...p_peso,
          name: 's_peso',
          change_param: change_param,
          messages: {
            ...p_peso.messages,
            help: '',
          },
        },
      };
    });
  };

  actualizarBloquesConAtributos = (atributos_agregar, atributos_borrar) => {
    let atributos_borrar_keys = atributos_borrar.map(
      (atributo) => atributo.key
    );
    let change_param, new_inputs;

    return this.state.atributos_on.stock.map((inputs) => {
      if (atributos_borrar_keys.length) {
        inputs = Validate.omit_keys(inputs, atributos_borrar_keys);
      }
      if (atributos_agregar.length) {
        change_param = inputs.s_precio.change_param;
        new_inputs = this.crearObjetoAtributosDinamicos(
          change_param,
          atributos_agregar
        );
        inputs = {
          ...new_inputs,
          ...inputs,
        };
      }

      return inputs;
    });
  };

  handleAgregarNuevoBloqueConAtributos = (e) => {
    e.preventDefault();

    let nuevo_stock = this.crearBloqueConAtributos();

    this.setState({
      atributos_on: {
        ...this.state.atributos_on,
        stock: [...this.state.atributos_on.stock, nuevo_stock],
      },
    });
  };

  handleChangeDeleteBloqueConAtributos = (e, change_param) => {
    e.preventDefault();

    let stock = this.state.atributos_on.stock.filter((inputs) => {
      return inputs.s_precio.change_param !== change_param;
    });

    this.setState({
      atributos_on: {
        ...this.state.atributos_on,
        stock: stock,
      },
    });
  };

  handleOpcionesAvanzadasAtributos = (e, change_param) => {
    e.preventDefault();

    let stock = this.state.atributos_on.stock.map((inputs) => {
      if (inputs.s_precio.change_param === change_param) {
        inputs.opciones_avanzadas.state = !inputs.opciones_avanzadas.state;
        return inputs;
      } else {
        return inputs;
      }
    });

    this.setState({
      atributos_on: {
        ...this.state.atributos_on,
        stock: stock,
      },
    });
  };

  modalHandler = (open, id, save) => {
    switch (id) {
      case 'configurar_atributos':
        if (open) {
          this.setState({
            modals: {
              ...this.state.modals,
              [id]: open,
            },
          });
        } else {
          if (save) {
            //hay potenciales cambios
            if (Validate.validar_formulario(this.state.atributos_off)) {
              //reviso si hay chips
              if (this.state.atributos_on.atributos.chips.length) {
                //hay chips
                if (this.state.atributos_on.stock.length) {
                  let atributos_nuevos =
                    this.state.atributos_on.atributos.chips;
                  let atributos_antiguos =
                    this.state.atributos_on.atributos.chips_back;

                  let atributos_agregar = atributos_nuevos.filter(
                    (atributo) => {
                      return !Validate.in_array_obj(
                        atributo.key,
                        atributos_antiguos,
                        'key'
                      );
                    }
                  );

                  let atributos_borrar = atributos_antiguos.filter(
                    (atributo) => {
                      return !Validate.in_array_obj(
                        atributo.key,
                        atributos_nuevos,
                        'key'
                      );
                    }
                  );

                  if (atributos_agregar.length || atributos_borrar.length) {
                    let nuevo_stock = this.actualizarBloquesConAtributos(
                      atributos_agregar,
                      atributos_borrar
                    );
                    nuevo_stock = this.propagarBloquesConAtributos(nuevo_stock);

                    this.setState({
                      modals: {
                        ...this.state.modals,
                        [id]: open,
                      },
                      atributos_on: {
                        ...this.state.atributos_on,
                        atributos: {
                          ...this.state.atributos_on.atributos,
                          error: false,
                          chips_back: atributos_nuevos,
                          value: '',
                        },
                        stock: nuevo_stock,
                      },
                    });
                  } else {
                    let nuevo_stock2 = this.propagarBloquesConAtributos(
                      this.state.atributos_on.stock
                    );
                    this.setState({
                      modals: {
                        ...this.state.modals,
                        [id]: open,
                      },
                      atributos_on: {
                        ...this.state.atributos_on,
                        stock: nuevo_stock2,
                      },
                    });
                  }
                } else {
                  this.setState({
                    modals: {
                      ...this.state.modals,
                      [id]: open,
                    },
                    atributos_on: {
                      ...this.state.atributos_on,
                      atributos: {
                        ...this.state.atributos_on.atributos,
                        error: false,
                        chips_back: this.state.atributos_on.atributos.chips,
                        value: '',
                      },
                      stock: [this.crearBloqueConAtributos()],
                    },
                  });
                }
              } else {
                /**
                 * EL MODAL SE GUARDO PERO NO HAY CHIPS,
                 * BORRAR TABLA Y PASAR A MODO SINGLE
                 */
                this.setState({
                  modals: {
                    ...this.state.modals,
                    [id]: open,
                  },
                  atributos_on: {
                    ...this.state.atributos_on,
                    atributos: {
                      ...this.state.atributos_on.atributos,
                      error: false,
                      chips: [],
                      chips_back: [],
                      value: '',
                    },
                    stock: [],
                  },
                  common: {
                    ...this.state.common,
                    p_atributos: {
                      ...this.state.common.p_atributos,
                      value: 0,
                    },
                  },
                });
              }
            } else {
              let atributos_off =
                Validate.validar_formulario_actualizar_obj_sync(
                  this.state.atributos_off
                );
              this.setState({
                atributos_off: atributos_off,
                alert: {
                  open: true,
                  type: 'error',
                  message: 'Por favor, debes completar los campos obligatorios',
                },
              });
            }
          } else {
            /**
             * EL MODAL SE CERRO SIN GUARDAR
             *
             * Reponer los chips (si existe tabla),
             * evita que se abra el modal,
             * se borre un chip y se cierre.
             *
             * si no hay tabla generada,
             * cierro el select de atributos
             */
            this.setState({
              modals: {
                ...this.state.modals,
                [id]: open,
              },
              atributos_on: {
                ...this.state.atributos_on,
                atributos: {
                  ...this.state.atributos_on.atributos,
                  error: false,
                  chips: this.state.atributos_on.atributos.chips_back,
                  value: '',
                },
              },
              common: {
                ...this.state.common,
                p_atributos: {
                  ...this.state.common.p_atributos,
                  value: this.state.atributos_on.stock.length ? 1 : 0,
                },
              },
            });
          }
        }
        break;
      case 'imagen_variante':
        if (open) {
          let { filled, change_param, link, variante } = save;

          if (filled) {
            this.setState({
              modals: {
                ...this.state.modals,
                [id]: open,
              },
              imagen_variante: {
                change_param: change_param,
                link: link,
                variante: variante,
              },
            });
          } else {
            this.setState({
              alert: {
                open: true,
                message:
                  'Para seleccionar una imagen para la variante debes agregar una foto a tu producto',
                type: 'error',
              },
            });
          }
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              [id]: open,
            },
          });
        }
        break;
      case 'categorias_secundarias':
        if (open) {
          this.setState({
            modals: {
              ...this.state.modals,
              [id]: open,
            },
          });
        } else {
          if (save) {
            this.setState({
              modals: {
                ...this.state.modals,
                [id]: open,
              },
              common: {
                ...this.state.common,
                categorias_secundarias: {
                  ...this.state.common.categorias_secundarias,
                  values_back: this.state.common.categorias_secundarias.values,
                },
              },
            });
          } else {
            this.setState({
              modals: {
                ...this.state.modals,
                [id]: open,
              },
              common: {
                ...this.state.common,
                categorias_secundarias: {
                  ...this.state.common.categorias_secundarias,
                  values: this.state.common.categorias_secundarias.values_back,
                },
              },
            });
          }
        }
        break;
      default:
        this.setState({
          modals: {
            ...this.state.modals,
            [id]: open,
          },
        });
        break;
    }
  };

  obtenerObjetoSubmit = () => {
    let data = {};
    let common = this.state.common;

    //saco los elementos de common
    for (let key in common) {
      if (key === 'fotos') {
        data['imagenes'] = common.fotos.files.map((file) => file.link);
      } else if (key === 'categorias_secundarias') {
        data['categorias_secundarias'] = common[key].values;
      } else {
        data[key] = common[key].value;
      }
    }

    //agrego el campo p_cantidad_minima
    let p_cantidad_minima = parseInt(
      this.state.atributos_off.p_cantidad_minima.value
    );
    data['p_cantidad_minima'] = p_cantidad_minima > 0 ? p_cantidad_minima : 1;

    if (common.p_atributos.value) {
      let atributos_on = this.state.atributos_on;
      data['atributos'] = atributos_on.atributos.chips_back;
      data['stock'] = [];
      atributos_on.stock.forEach((inputs, idx) => {
        data['stock'][idx] = {};
        for (let key in inputs) {
          if (key !== 'opciones_avanzadas') {
            if (key === 's_imagen') {
              common.fotos.files.forEach((file, idx_file) => {
                if (inputs[key].link === file.link) {
                  data['stock'][idx][key] = idx_file;
                }
              });
            } else {
              data['stock'][idx][key] = inputs[key].value;
            }
          }
        }
      });
    } else {
      let atributos_off = this.state.atributos_off;
      let single_stock = {};
      let new_key = '';
      for (let key in atributos_off) {
        if (
          Validate.in_array(key, [
            'p_precio',
            'p_precio_oferta',
            'p_mostrar_precio',
            'p_precio_mayorista',
            'p_cantidad_minima',
            'p_alto',
            'p_ancho',
            'p_profundidad',
            'p_peso',
          ])
        ) {
          new_key = Validate.reemplazar_string(key, 'p_', 's_');
          single_stock[new_key] = atributos_off[key].value;
        } else if (
          Validate.in_array(key, [
            's_cantidad',
            's_sku',
            's_producto_digital_link',
            's_producto_digital_plazo',
            's_producto_digital_observacion',
          ])
        ) {
          single_stock[key] = atributos_off[key].value;
        }
      }

      data['atributos'] = [];
      data['stock'] = [
        {
          ...single_stock,
          s_imagen: 0,
        },
      ];
    }

    return data;
  };

  hayVariantesDuplicadas = (stock) => {
    let str;
    let formated = Validate.remove_duplicates(stock, (inputs) => {
      str = '';
      Object.keys(inputs).forEach((key) => {
        if (Validate.contiene_substring(key, 'at_')) {
          str += inputs[key] + '_';
        }
      });
      return Validate.trim_uppercase(str);
    });
    return formated.length !== stock.length;
  };

  armarLinkShareProducto = (p_link) => {
    let idCategorias = this.state.common.idCategorias.value;
    let c_link_full = this.props.categorias.filter(
      (cat) => cat.idCategorias === idCategorias
    )[0].c_link_full;
    return (
      'https://' + this.props.dominios.d_nombre + c_link_full + '/' + p_link
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let body;
    let precios_oferta = true;
    this.setState({
      forms: {
        ...this.state.forms,
        agregar: true,
      },
    });

    if (this.state.uploading_images === false) {
      if (this.state.common.fotos.auth) {
        if (this.state.common.p_atributos.value) {
          if (this.state.atributos_on.stock.length) {
            let flag = true;

            this.state.atributos_on.stock.forEach((inputs, idx) => {
              if (!Validate.validar_formulario(inputs)) {
                flag = false;
              }
              if (inputs.s_precio_oferta.value !== '') {
                if (
                  Validate.trim_float(inputs.s_precio_oferta.value) >=
                  Validate.trim_float(inputs.s_precio.value)
                ) {
                  precios_oferta = false;
                }
              }
            });

            if (flag && Validate.validar_formulario(this.state.common)) {
              if (precios_oferta) {
                body = this.obtenerObjetoSubmit();
                let stock = body.stock;
                if (!this.hayVariantesDuplicadas(stock)) {
                  this.props.productoAgregar(body, (err, resp) => {
                    if (err) {
                      this.setState({
                        forms: {
                          ...this.state.forms,
                          agregar: false,
                        },
                        alert: {
                          open: true,
                          type: 'error',
                          message: resp.message,
                        },
                      });
                    } else {
                      this.setState(
                        {
                          forms: {
                            ...this.state.forms,
                            agregar: false,
                          },
                          alert: {
                            open: true,
                            type: 'success',
                            message: resp.message,
                          },
                          after: true,
                          after_id: resp.data.idProductos,
                          after_link: this.armarLinkShareProducto(
                            resp.data.p_link
                          ),
                        },
                        () => {
                          createAmplitudeEventWithDevice(
                            amplitude_events.agregar_producto_successfull
                              .event_name,
                            amplitude_events.agregar_producto_successfull
                              .event_params
                          );

                          if (this.props.idCountry === 'ARG') {
                            braze.logCustomEvent(
                              'empretienda_productos_selecciona_agregar_producto'
                            );
                          }
                        }
                      );
                    }
                  });
                } else {
                  this.setState({
                    forms: {
                      ...this.state.forms,
                      agregar: false,
                    },
                    alert: {
                      open: true,
                      type: 'error',
                      message:
                        'Hay 2 o más variantes repetidas, por favor, revisalas',
                    },
                  });
                }
              } else {
                this.setState({
                  forms: {
                    ...this.state.forms,
                    agregar: false,
                  },
                  alert: {
                    open: true,
                    type: 'error',
                    message:
                      'El precio de oferta de alguna variante es mayor o igual al precio normal',
                  },
                });
              }
            } else {
              let common = Validate.validar_formulario_actualizar_obj_sync(
                this.state.common
              );
              let stock = this.state.atributos_on.stock.map((inputs) => {
                return Validate.validar_formulario_actualizar_obj_sync(inputs);
              });

              this.setState({
                common: common,
                atributos_on: {
                  ...this.state.atributos_on,
                  stock: stock,
                },
                alert: {
                  open: true,
                  type: 'error',
                  message: 'Por favor, revisa tus campos, hay algunos errores',
                },
                forms: {
                  ...this.state.forms,
                  agregar: false,
                },
              });
            }
          } else {
            this.setState({
              alert: {
                open: true,
                type: 'error',
                message: 'Debes crear al menos una variante para tu producto',
              },
              forms: {
                ...this.state.forms,
                agregar: false,
              },
            });
          }
        } else {
          if (
            Validate.validar_formulario(this.state.common) &&
            Validate.validar_formulario(this.state.atributos_off)
          ) {
            if (this.state.atributos_off.p_precio_oferta.value !== '') {
              if (
                Validate.trim_float(
                  this.state.atributos_off.p_precio_oferta.value
                ) >=
                Validate.trim_float(this.state.atributos_off.p_precio.value)
              ) {
                precios_oferta = false;
              }
            }

            if (precios_oferta) {
              body = this.obtenerObjetoSubmit();
              this.props.productoAgregar(body, (err, resp) => {
                if (err) {
                  this.setState({
                    forms: {
                      ...this.state.forms,
                      agregar: false,
                    },
                    alert: {
                      open: true,
                      type: 'error',
                      message: resp.message,
                    },
                  });
                } else {
                  this.setState(
                    {
                      forms: {
                        ...this.state.forms,
                        agregar: false,
                      },
                      alert: {
                        open: true,
                        type: 'success',
                        message: resp.message,
                      },
                      after: true,
                      after_id: resp.data.idProductos,
                      after_link: this.armarLinkShareProducto(resp.data.p_link),
                    },
                    () => {
                      createAmplitudeEventWithDevice(
                        amplitude_events.agregar_producto_successfull
                          .event_name,
                        amplitude_events.agregar_producto_successfull
                          .event_params
                      );

                      if (this.props.idCountry === 'ARG') {
                        braze.logCustomEvent(
                          'empretienda_productos_selecciona_agregar_producto'
                        );
                      }
                    }
                  );
                }
              });
            } else {
              this.setState({
                forms: {
                  ...this.state.forms,
                  agregar: false,
                },
                alert: {
                  open: true,
                  type: 'error',
                  message:
                    'El precio de oferta debe ser menor al precio normal',
                },
              });
            }
          } else {
            let common = Validate.validar_formulario_actualizar_obj_sync(
              this.state.common
            );
            let atributos_off = Validate.validar_formulario_actualizar_obj_sync(
              this.state.atributos_off
            );
            this.setState({
              common: common,
              atributos_off: atributos_off,
              alert: {
                open: true,
                type: 'error',
                message: 'Por favor, revisa tus campos, hay algunos errores',
              },
              forms: {
                ...this.state.forms,
                agregar: false,
              },
            });
          }
        }
      } else {
        this.setState({
          alert: {
            open: true,
            type: 'error',
            message: 'Debes seleccionar al menos una imagen para tu producto',
          },
          forms: {
            ...this.state.forms,
            agregar: false,
          },
        });
      }
    } else {
      this.setState({
        alert: {
          open: true,
          type: 'error',
          message: 'Debes esperar a que las imágenes terminen de cargar',
        },
        forms: {
          ...this.state.forms,
          agregar: false,
        },
      });
    }
  };

  handleSubmitCategoriasSecundarias = (e) => {
    e.preventDefault();
    this.modalHandler(false, 'categorias_secundarias', true);
  };

  handlePreChangeVarianteImagen = (link) => {
    this.setState({
      imagen_variante: {
        ...this.state.imagen_variante,
        link: link,
      },
    });
  };

  handleChangeVarianteImagen = (e) => {
    e.preventDefault();

    let { link, change_param } = this.state.imagen_variante;

    let stock = this.state.atributos_on.stock.map((inputs) => {
      if (inputs.s_precio.change_param === change_param) {
        return {
          ...inputs,
          s_imagen: {
            ...inputs.s_imagen,
            link: link,
          },
        };
      } else {
        return inputs;
      }
    });

    this.setState({
      atributos_on: {
        ...this.state.atributos_on,
        stock: stock,
      },
      modals: {
        ...this.state.modals,
        imagen_variante: false,
      },
    });
  };

  render() {
    let { categorias, tienda, classes } = this.props;

    const idCountry = tienda ? tienda.Country_idCountry : 'ARG';

    return (
      <WrapperLayout
        loading={
          this.state.categorias ||
          this.state.tienda ||
          this.state.metodos_envio ||
          this.state.producto
        }
        error={this.state.error}
      >
        <AlertUI
          hideSeconds={4000}
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <AmplitudeRenderErrorUI
          amplitude_event_failed={amplitude_events.agregar_producto_failed}
          open={this.state.alert.open}
          type={this.state.alert.type}
        />

        <ModalVarianteImagen
          imagen_variante={this.state.imagen_variante}
          handleSubmit={this.handleChangeVarianteImagen}
          handlePreChangeVarianteImagen={this.handlePreChangeVarianteImagen}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.imagen_variante}
          fotos={this.state.common.fotos}
        />

        <ModalCategoriasSecundarias
          handleSubmit={this.handleSubmitCategoriasSecundarias}
          common={this.state.common}
          handleChangeMultipleSelect={this.handleChangeMultipleSelect}
          options={this.state.selects.categorias}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.categorias_secundarias}
        />

        <ModalUI
          open={this.state.modals.configurar_atributos}
          id="configurar_atributos"
          title="Configuración"
          modalHandler={this.modalHandler}
          aditional_param={false}
          handleSubmit={(e) => {
            e.preventDefault();
            this.modalHandler(false, 'configurar_atributos', true);
          }}
          button_label="Guardar"
        >
          <Grid container spacing={3}>
            <ToggleUI
              show={Validate.in_array(
                this.state.common.p_tipo_venta.value,
                [1, 2]
              )}
            >
              <ModuleCardTitleUI
                title="Cantidad mínima mayorista por producto"
                subtitles={[
                  'Indica la cantidad de mínima de productos, sin importar las variantes, que el cliente mayorista debe comprar de este producto.',
                ]}
              />
              <Grid item sm={12} xs={12}>
                <InputUI
                  handleChange={this.handleChange}
                  input={this.state.atributos_off.p_cantidad_minima}
                  step="1"
                />
              </Grid>
            </ToggleUI>
            <ModuleCardTitleUI
              title="Configurar atributos"
              subtitles={[
                'Esta información será utilizada para autocompletado al crear nuevas variantes.',
                'No modificará las variantes existentes y podrás editar cada valor en cada variante que crees.',
              ]}
            />
            <Grid item xs={12}>
              <ChipsInputUI
                handleChipChange={this.handleChipChange}
                handleChipDelete={this.handleChipDelete}
                chip_label="value"
                chipsinput={this.state.atributos_on.atributos}
              />
            </Grid>
            <Grid item xs={12}>
              <PrecioStockSection
                handleChange={this.handleChange}
                handleChangeCheckbox={this.handleChangeCheckbox}
                common={this.state.common}
                atributos_off={this.state.atributos_off}
                idCountry={idCountry}
              />
            </Grid>
            <ToggleUI
              show={
                this.state.common.p_producto_digital.value === 0 ? true : false
              }
            >
              <Grid item xs={12}>
                <DimensionesSection
                  dimensiones_requeridas={this.state.dimensiones_requeridas}
                  handleChange={this.handleChange}
                  handleChangeSelect={this.handleChangeSelect}
                  selects={this.state.selects}
                  atributos_off={this.state.atributos_off}
                  common={this.state.common}
                />
              </Grid>
            </ToggleUI>
          </Grid>
        </ModalUI>

        <ToggleUI show={!this.state.after}>
          <Grid container>
            <ToggleUI show={categorias.length ? true : false}>
              <Grid item xs={12}>
                <ModuleTitleUI
                  title="Agregar producto"
                  button={
                    <ButtonUI
                      type="callf"
                      label={es_mobile ? 'Importar' : 'Importar productos'}
                      fullWidth={false}
                      onClickFunc={() => {
                        this.props.history.push('/productos/importacion');
                        createAmplitudeEventWithDevice(
                          amplitude_events.productos_importacion_masiva
                            .event_name,
                          { source: 'agregar producto' }
                        );
                      }}
                    />
                  }
                />

                <InfoSection
                  tienda={tienda}
                  handleChangeFile={this.handleChangeFile}
                  handleChange={this.handleChange}
                  handleChangeSelect={this.handleChangeSelect}
                  handleChangeQuill={this.handleChangeQuill}
                  modalHandler={this.modalHandler}
                  selects={this.state.selects}
                  common={this.state.common}
                />

                <CardModuleUI
                  below
                  with_toggle
                  show={
                    this.state.common.p_atributos.value === 0 ? true : false
                  }
                >
                  <PrecioStockSection
                    handleChange={this.handleChange}
                    handleChangeCheckbox={this.handleChangeCheckbox}
                    common={this.state.common}
                    atributos_off={this.state.atributos_off}
                    idCountry={idCountry}
                  />
                </CardModuleUI>

                <AtributosSection
                  selects={this.state.selects}
                  common={this.state.common}
                  atributos_on={this.state.atributos_on}
                  atributos_off={this.state.atributos_off}
                  modalHandler={this.modalHandler}
                  handleChangeSelect={this.handleChangeSelect}
                  handleChangeDynamicInput={this.handleChangeDynamicInput}
                  handleOpcionesAvanzadasAtributos={
                    this.handleOpcionesAvanzadasAtributos
                  }
                  handleChangeDeleteBloqueConAtributos={
                    this.handleChangeDeleteBloqueConAtributos
                  }
                  handleAgregarNuevoBloqueConAtributos={
                    this.handleAgregarNuevoBloqueConAtributos
                  }
                />

                <CardModuleUI
                  below
                  with_toggle
                  show={
                    this.state.common.p_producto_digital.value === 0 &&
                    this.state.common.p_atributos.value === 0
                      ? true
                      : false
                  }
                >
                  <DimensionesSection
                    dimensiones_requeridas={this.state.dimensiones_requeridas}
                    handleChange={this.handleChange}
                    handleChangeSelect={this.handleChangeSelect}
                    selects={this.state.selects}
                    atributos_off={this.state.atributos_off}
                    common={this.state.common}
                  />
                </CardModuleUI>

                <ModuleButtonBoxUI lgSize={3} xlSize={3}>
                  <ButtonUI
                    type="callf"
                    label="Agregar producto"
                    onClickFunc={(e) => this.handleSubmit(e)}
                    isLoading={this.state.forms.agregar}
                  />
                </ModuleButtonBoxUI>
              </Grid>
            </ToggleUI>
            <ToggleUI show={categorias.length === 0 ? true : false}>
              <NextActionsUI
                title="Para agregar un producto, debes crear al menos 1 categoría"
                buttons={[
                  <ButtonUI
                    type="link"
                    label="Crear categoría"
                    link="/categorias"
                  />,
                ]}
              />
            </ToggleUI>
          </Grid>
        </ToggleUI>
        <ToggleUI show={this.state.after}>
          <NextActionsUI
            title="¡El producto ha sido cargado con éxito!"
            social_actions={[
              <Fab
                color="secondary"
                size="small"
                aria-label="Add"
                className={classes.fab}
                href={
                  'https://www.facebook.com/sharer/sharer.php?u=' +
                  this.state.after_link
                }
                target="_blank"
              >
                <Icon className="fab fa-facebook-f" />
              </Fab>,
              <Fab
                color="secondary"
                size="small"
                aria-label="Add"
                className={classes.fab}
                href={'whatsapp://send?text=' + this.state.after_link}
                target="_blank"
              >
                <Icon className="fab fa-whatsapp" />
              </Fab>,
              <Fab
                color="secondary"
                size="small"
                aria-label="Add"
                className={classes.fab}
                href={'https://twitter.com/share?url=' + this.state.after_link}
                target="_blank"
              >
                <Icon className="fab fa-twitter" />
              </Fab>,
              <Fab
                color="secondary"
                size="small"
                aria-label="Add"
                className={classes.fab}
                href={this.state.after_link}
                target="_blank"
              >
                <Icon>open_in_new</Icon>
              </Fab>,
            ]}
            buttons={[
              <ButtonUI
                type="link"
                label="Duplicar producto"
                link={'/productos/agregar/' + this.state.after_id}
              />,
              <ButtonUI
                type="link"
                label="Ver mis productos"
                link="/productos"
              />,
              <ButtonUI
                type="link"
                label="Agregar otro producto"
                link="/productos/agregar"
                external_link
              />,
            ]}
          />
        </ToggleUI>
      </WrapperLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
    categorias: state.categorias,
    dominios: state.dominios,
    metodos_envio: state.metodos_envio,
    idCountry: state.tienda.Country_idCountry,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    categoriasObtenerFlattenJerarquico: (callback) =>
      dispatch(categoriasObtenerFlattenJerarquico(callback)),
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    metodosenvioObtener: (callback) => dispatch(metodosenvioObtener(callback)),
    productoAgregar: (data, callback) =>
      dispatch(productoAgregar(data, callback)),
    productoObtenerProducto: (data, callback) =>
      dispatch(productoObtenerProducto(data, callback)),
  };
};

const styles = (theme) => ({
  fab: {
    boxShadow: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
});

export const ProductoAgregar = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ProductoAgregar_));
