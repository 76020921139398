import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { tiendaActualizarGeneral } from '../../store/actions';
import { Regex, RegexExtra } from '../../other';
import { Box, Grid, Typography } from '@mui/material';
import { AlertUI } from '../../components/common';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import { useAuth0 } from '@auth0/auth0-react';
import useForm from '../../hooks/useForm';
import { BackgroundBox } from '../../components/common/BackgroundBox/styles';
import ButtonMui from '../../components/common/ButtonMui';
import { isEmpty } from 'lodash';

const ShopInformationPage_ = ({ tiendaActualizarGeneral }) => {
  const tienda = useSelector((state) => state.tienda);
  const { Form, Input } = useForm();
  const { user } = useAuth0();
  const argIdCountry = user.app_metadata.id_country !== 'COL';
  const enter = argIdCountry ? 'Ingresá' : 'Ingresa';
  const [isUpdateShopInfoLoading, setIsUpdateShopInfoLoading] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'error',
  });

  const handleCloseAlert = () => {
    setAlert((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleSubmit = (formData) => {
    if (!formData) return;
    setIsUpdateShopInfoLoading(true);

    const { shopName, contactEmail, shopAddress, phoneNumber } = formData;

    const body = {
      t_nombre: shopName.value,
      t_email: contactEmail.value,
      t_direccion: shopAddress.value,
      t_telefono: phoneNumber.value,
    };

    tiendaActualizarGeneral(body, (err, resp) => {
      setAlert({
        type: err ? 'error' : 'success',
        open: true,
        message: resp.message,
      });
      setIsUpdateShopInfoLoading(false);
    });
  };

  return (
    <WrapperLayout loading={isEmpty(tienda)}>
      <AlertUI
        open={alert.open}
        message={alert.message}
        type={alert.type}
        handleCloseAlert={handleCloseAlert}
      />
      <Box>
        <Typography as="h1" fontSize={{ xs: '1.4rem', lg: '1.7rem' }} mb={2}>
          Información de mi tienda
        </Typography>
        <Form onSubmit={handleSubmit}>
          <BackgroundBox sx={{ padding: '20px' }} mb={3}>
            <Grid container spacing={2.5}>
              <Grid item xs={12}>
                <Input
                  name="shopEmail"
                  defaultValue={user?.email}
                  regex={Regex.EMAIL}
                  label="Usuario"
                  disabled
                  helperText="Email registrado para iniciar sesión en tu panel"
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="shopName"
                  defaultValue={tienda?.t_nombre}
                  label="Nombre de la marca (editable)"
                  placeholder="Mi marca"
                  helperText="Nombre de tu negocio"
                  regex={Regex.NOMBRE_TIENDA}
                  msgError={`${enter} un nombre de tienda válido`}
                  msgErrorExtra={RegexExtra.NOMBRE_TIENDA}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="contactEmail"
                  defaultValue={tienda?.t_email}
                  label="Email de contacto (editable)"
                  placeholder="mimarca@algo.com"
                  helperText="Email que aparecerá en la parte inferior de tu tienda. Allí te llegarán todas las notificaciones (ventas, consultas, etc.)."
                  regex={Regex.EMAIL}
                  msgError={`${enter} un email válido.`}
                  msgErrorExtra={RegexExtra.EMAIL}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  required={argIdCountry ? false : true}
                  name="shopAddress"
                  defaultValue={tienda?.t_direccion}
                  label="Dirección de la tienda"
                  helperText="Aparecerá en la parte inferior de tu tienda"
                  regex={
                    argIdCountry
                      ? Regex.DIRECCION_4_65
                      : Regex.DIRECCION_COLOMBIA_COMPLETA
                  }
                  msgError={`${enter} una dirección válida.`}
                  msgErrorExtra={
                    argIdCountry
                      ? RegexExtra.DIRECCION_4_65
                      : RegexExtra.DIRECCION_COLOMBIA_COMPLETA
                  }
                  placeholder={
                    argIdCountry
                      ? 'Ej Libertador 1892, CABA, Buenos Aires'
                      : 'Ej Calle 23 #45A - 21, Barrio El Poblado, Medellín'
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  required={argIdCountry ? false : true}
                  name="phoneNumber"
                  defaultValue={tienda?.t_telefono}
                  label="Teléfono de la tienda"
                  helperText="Aparecerá en la parte inferior de tu tienda"
                  placeholder={
                    argIdCountry ? 'Ej 1122335598' : 'Ej 571234567890'
                  }
                  regex={
                    argIdCountry
                      ? Regex.TELEFONO
                      : Regex.TELEFONO_COMPUESTO_COLOMBIA
                  }
                  msgError={`${enter} un número de teléfono válido con código de área.`}
                  msgErrorExtra={RegexExtra.EMPTY}
                />
              </Grid>
            </Grid>
          </BackgroundBox>
          <ButtonMui
            type="submit"
            sx={{ width: { xs: '100%', md: '240px' } }}
            isLoading={isUpdateShopInfoLoading}
          >
            Guardar
          </ButtonMui>
        </Form>
      </Box>
    </WrapperLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    tiendaActualizarGeneral: (data, callback) =>
      dispatch(tiendaActualizarGeneral(data, callback)),
  };
};

export const ShopInformationPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopInformationPage_);
