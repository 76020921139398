/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import {
  ModuleTitleUI,
  CardModuleUI,
  ModuleCardTitleUI,
  ButtonUI,
} from '../../components/common';
import { Grid } from '@mui/material';
import { WrapperLayout } from '../../layouts/WrapperLayout';

export const ForumPage = () => {
  return (
    <WrapperLayout loading={false} error={false}>
      <ModuleTitleUI
        title="Foro Empretienda"
        button={
          <ButtonUI
            type="link"
            label="Tutorial"
            fullWidth={false}
            external_link
            open_new_tab
            link="https://recursos.empretienda.com/foro-empretienda.pdf"
          />
        }
      />
      <CardModuleUI below>
        <Grid container spacing={3}>
          <ModuleCardTitleUI
            title={
              <span>
                Accedé a nuestro foro para interactuar con la comunidad de
                emprendedoras y emprendedores que eligen nuestra plataforma, te
                recomendamos ver el tutorial para saber cómo utilizarlo.
                <br />
                <br />
                Para ingresar es necesario que crees una cuenta desde el botón
                que te dejamos a continuación y verifiques tu email luego de
                completar el registro,{' '}
                <span style={{ color: 'red' }}>
                  si no recibes el email de verificación revisá en spam
                </span>
                .
              </span>
            }
          />
          <Grid item sm="auto" xs={12}>
            <ButtonUI
              type="link"
              label="Registrarse"
              external_link
              open_new_tab
              link="https://foro.empretienda.com/invites/1GBbAsaEaC"
            />
          </Grid>
          <Grid item sm="auto" xs={12}>
            <ButtonUI
              type="link"
              color="secondary"
              label="Iniciar sesión"
              external_link
              open_new_tab
              link="https://foro.empretienda.com/login"
            />
          </Grid>
        </Grid>
      </CardModuleUI>
    </WrapperLayout>
  );
};
