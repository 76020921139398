import React from 'react';
import { Grid, Icon } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { PlantillaCard } from './PlantillaCard';
import { ToggleUI } from '../../common';

const TemplateSettings_ = (props) => {
  let { template_id, templates, handleChangeTemplate, classes } = props;

  const idCountry =
    useSelector((state) => state.tienda.Country_idCountry) || 'ARG';

  const TemplateList = () => {
    if (idCountry === 'COL') {
      const auraTemplate = templates.filter(
        (template) => template.nombre === 'Aura'
      )[0];

      return (
        <PlantillaCard
          key={0}
          template={auraTemplate}
          template_id={template_id}
          handleChangeTemplate={handleChangeTemplate}
        />
      );
    }

    return templates.map((template, index) => (
      <PlantillaCard
        key={index}
        template={template}
        template_id={template_id}
        handleChangeTemplate={handleChangeTemplate}
      />
    ));
  };

  const templateSelected = templates.find((el) => el.id === template_id);

  return (
    <Grid container spacing={3}>
      <ToggleUI show={!!templateSelected}>
        <div className={classes.templateSelected}>
          <span>
            <Icon>check_circle</Icon>
          </span>
          Plantilla en uso: {templateSelected.nombre}
        </div>
      </ToggleUI>
      <TemplateList />
    </Grid>
  );
};

const styles = (theme) => ({
  templateSelected: {
    position: 'relative',
    width: '100%',
    height: '40px',
    display: 'flex',
    fontWeight: 500,
    borderRadius: '2px',
    alignItems: 'center',
    margin: '24px 0px 0px 24px',
    justifyContent: 'center',
    border: '1px solid #53B755',
    color: theme.texts.secondary,
    gap: '10px',
    '& span': {
      color: '#53B755',
    },
  },
  template_container: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  template_image: {
    width: '100%',
    height: 'auto',
  },
  template_caption: {
    padding: '10px',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  template_name: {
    fontSize: '20px',
    fontWeight: '300',
  },
});

export const TemplateSettings = withStyles(styles, { withTheme: true })(
  TemplateSettings_
);
