/* eslint-disable no-unreachable */
/* eslint-disable default-case */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import {
  Avatar,
  IconButton,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
  Hidden,
} from '@mui/material';
import { MenuItemUI, DropdownUI } from '../../../common';
import { Validate, Config } from '../../../../other';

const mobile = Validate.is_mobile();

const renderizarCuponesList = (
  cupones,
  t_mayorista,
  modalHandler,
  classes,
  idCountry
) => {
  const obtenerTipoCupon = (cupon) => {
    switch (cupon.cd_tipo) {
      case 0:
        return 'Descuento del ' + cupon.cd_porcentaje + '%';
        break;
      case 1:
        return (
          'Descuento de $' + Validate.number_format(cupon.cd_monto, idCountry)
        );
        break;
      case 2:
        return '2x1 (paga unidad de mayor precio)';
        break;
      case 3:
        return '3x2 (paga unidades de mayor precio)';
        break;
      case 4:
        return '4x2 (paga unidades de mayor precio)';
        break;
      case 5:
        return '4x3 (paga unidades de mayor precio)';
        break;
      case 6:
        return (
          'Descuento del ' +
          cupon.cd_porcentaje +
          '% en segunda unidad (de menor precio)'
        );
        break;
    }
  };

  const obtenerAlcanceCupon = (cupon) => {
    switch (cupon.cd_alcance) {
      case -1:
        return (
          <Chip
            className={classes.chip}
            component="span"
            color="primary"
            label="Todos los productos"
          />
        );
        break;
      case 0:
        return cupon.categorias_cupon.map((categoria) => {
          return (
            <Chip
              className={classes.chip}
              component="span"
              color="primary"
              label={categoria.c_nombre}
              key={categoria.idCategorias}
            />
          );
        });
        break;
      case 1:
        return cupon.productos_cupon.map((producto) => {
          return (
            <Chip
              className={classes.chip}
              component="span"
              color="primary"
              label={producto.p_nombre}
              key={producto.idProductos}
            />
          );
        });
        break;
    }
  };

  const obtenerLimiteUsoCupon = (cupon) => {
    switch (cupon.cd_uso) {
      case -1:
        return 'Sin límite';
        break;
      case 0:
        return cupon.cd_uso_cantidad === 1
          ? '1 compra'
          : cupon.cd_uso_cantidad + ' compras';
        break;
      case 1:
        return cupon.cd_uso_cantidad === 1
          ? '1 compra por cliente'
          : cupon.cd_uso_cantidad + ' compras por cliente';
        break;
    }
  };

  const obtenerCondicionUsoCupon = (cupon) => {
    switch (cupon.cd_condicion) {
      case -1:
        return 'Sin condición';
        break;
      case 0:
        return (
          'Compras mayores a $' +
          Validate.number_format(cupon.cd_condicion_monto, idCountry)
        );
        break;
      case 1:
        return 'Compras de ' + cupon.cd_condicion_cantidad + ' o más productos';
        break;
    }
  };

  const obtenerDuracionCupon = (cupon) => {
    switch (cupon.cd_duracion) {
      case -1:
        return 'Ilimitado';
        break;
      case 0:
        return (
          'Desde el ' +
          Validate.utc2local_format(
            cupon.cd_duracion_inicio,
            Config.DATE_FORMAT
          )
        );
        break;
      case 1:
        return (
          'Hasta el ' +
          Validate.utc2local_format(cupon.cd_duracion_fin, Config.DATE_FORMAT)
        );
        break;
      case 2:
        return (
          'Desde el ' +
          Validate.utc2local_format(
            cupon.cd_duracion_inicio,
            Config.DATE_FORMAT
          ) +
          ' hasta el ' +
          Validate.utc2local_format(cupon.cd_duracion_fin, Config.DATE_FORMAT)
        );
        break;
    }
  };

  if (cupones.length > 0) {
    return cupones.map((cupon, index) => {
      return (
        <ListItem
          divider={index < cupones.length - 1}
          key={cupon.idCuponesDescuento}
        >
          {!mobile ? (
            <ListItemAvatar>
              <Avatar>
                <Icon>local_offer</Icon>
              </Avatar>
            </ListItemAvatar>
          ) : (
            ''
          )}
          <ListItemText
            primary={cupon.cd_codigo}
            secondary={
              <span className={classes.span}>
                <span className={classes.span}>
                  Tipo: {obtenerTipoCupon(cupon)}
                </span>
                <span className={classes.span}>
                  Límite de uso: {obtenerLimiteUsoCupon(cupon)}
                </span>
                <span className={classes.span}>
                  Condición de uso: {obtenerCondicionUsoCupon(cupon)}
                </span>
                <span className={classes.span}>
                  Tiempo de validez: {obtenerDuracionCupon(cupon)}
                </span>
                {obtenerAlcanceCupon(cupon)}
              </span>
            }
            classes={{ root: classes.list_text }}
          />
          <ListItemSecondaryAction>
            <Hidden mdUp>
              <DropdownUI type="icon">
                <MenuItemUI
                  type="callf"
                  onClickFunction={() =>
                    modalHandler(true, 'desactivar_activar', cupon)
                  }
                >
                  {cupon.cd_desactivado ? 'Activar cupón' : 'Desactivar cupón'}
                </MenuItemUI>
                <MenuItemUI
                  type="link"
                  link={'/cupones/' + cupon.idCuponesDescuento}
                >
                  Editar cupón
                </MenuItemUI>
                <MenuItemUI
                  type="callf"
                  onClickFunction={() => modalHandler(true, 'borrar', cupon)}
                >
                  Borrar cupón
                </MenuItemUI>
              </DropdownUI>
            </Hidden>
            <Hidden mdDown>
              <IconButton
                aria-label="Borrar"
                onClick={(e) => modalHandler(true, 'desactivar_activar', cupon)}
                size="large"
              >
                <Icon>
                  {cupon.cd_desactivado ? 'visibility_off' : 'visibility'}
                </Icon>
              </IconButton>
              <IconButton
                aria-label="Editar"
                component={(props) => (
                  <Link
                    to={'/cupones/' + cupon.idCuponesDescuento}
                    {...props}
                  />
                )}
                size="large"
              >
                <Icon>edit</Icon>
              </IconButton>
              <IconButton
                aria-label="Borrar"
                onClick={(e) => modalHandler(true, 'borrar', cupon)}
                size="large"
              >
                <Icon>delete</Icon>
              </IconButton>
            </Hidden>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
  } else {
    return (
      <ListItem>
        <ListItemText
          primary="No hay cupones de descuento"
          secondary="Puedes agregar uno haciendo click en agregar"
        />
      </ListItem>
    );
  }
};

const CuponesList_ = (props) => {
  let { cupones, tienda, modalHandler, classes } = props;

  const idCountry = tienda ? tienda.Country_idCountry : 'ARG';

  return (
    <List classes={{ root: classes.list }}>
      {renderizarCuponesList(
        cupones,
        tienda.t_mayorista,
        modalHandler,
        classes,
        idCountry
      )}
    </List>
  );
};

const styles = (theme) => ({
  span: {
    display: 'block',
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  list: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '0',
    '&:last-child': {
      borderBottom: '0px solid transparent',
    },
  },
  list_text: {
    paddingRight: '0px',
    [theme.breakpoints.up('md')]: {
      paddingRight: '150px',
    },
  },
  chip: theme.chip,
});

const mapStateToProps = (state) => {
  return {
    cupones: state.cupones,
    tienda: state.tienda,
  };
};

const mapDispatchToProps = null;

export const CuponesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CuponesList_));
