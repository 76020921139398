import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  localObtenerLocales,
  localAgregar,
  localEditar,
  localBorrar,
  metodosenvioObtener,
  metodoenvioActivarRetiroLocal,
  metodoenvioDesactivarRetiroLocal,
} from '../../../store/actions';
import { Card, CardHeader, CardContent, Divider } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ButtonUI, AlertUI, ModuleTitleUI, ToggleUI } from '../../common';
import { Regex, RegexExtra, Validate } from '../../../other';
import {
  ModalAgregar,
  ModalEditar,
  ModalBorrar,
  LocalesList,
} from './subcomponents';
import {
  method_shipment_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../amplitude';
import * as braze from '@braze/web-sdk';
import { WrapperLayout } from '../../../layouts/WrapperLayout';

class Local_ extends Component {
  state = {
    //campos para modal agregar
    agregar: {
      l_nombre: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nombre',
        id: 'agregar_l_nombre',
        name: 'l_nombre',
        change_param: 'agregar',
        regex: Regex.STRING_GENERICO_5_90,
        value: '',
        default_value: '',
        placeholder: 'Showroom Rosario',
        messages: {
          error: 'Por favor, ingrese un nombre de local válido',
          error_extra: RegexExtra.STRING_GENERICO_5_90,
        },
      },
      l_direccion: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Dirección',
        id: 'agregar_l_direccion',
        name: 'l_direccion',
        change_param: 'agregar',
        regex: Regex.DIRECCION_4_65,
        value: '',
        default_value: '',
        placeholder: 'Av. Pellegrini 1234, Rosario, Santa Fe',
        messages: {
          error: 'Por favor, ingrese una dirección válida',
          error_extra: RegexExtra.DIRECCION_4_65,
        },
      },
      l_horarios: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Horarios',
        id: 'agregar_l_horarios',
        name: 'l_horarios',
        change_param: 'agregar',
        regex: Regex.STRING_GENERICO_5_90,
        value: '',
        default_value: '',
        placeholder: 'Lunes a viernes de 10:00 a 18:00',
        messages: {
          error: 'Por favor, ingrese un horario válido',
          error_extra: RegexExtra.STRING_GENERICO_5_90,
        },
      },
      l_telefono: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Teléfono',
        id: 'agregar_l_telefono',
        name: 'l_telefono',
        change_param: 'agregar',
        regex: Regex.TELEFONO_COMPUESTO,
        value: '',
        default_value: '',
        placeholder: '1234578990',
        messages: {
          error: 'Por favor, ingrese un teléfono válido',
          error_extra: RegexExtra.TELEFONO_COMPUESTO,
        },
      },
      l_descripcion: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Descripción',
        id: 'agregar_l_descripcion',
        name: 'l_descripcion',
        change_param: 'agregar',
        regex: Regex.DESCRIPCION_0_200,
        value: '',
        default_value: '',
        placeholder: 'Retiro a las 48hs previo pago',
        messages: {
          error: 'Por favor, ingrese una descripción válida',
          help: 'Esta información se le mostrará a los clientes cuando calculen el costo de envío o lo seleccionen en el checkout.',
          error_extra: RegexExtra.DESCRIPCION_0_200,
        },
      },
    },
    //campos para modal editar
    editar: {
      idLocales: {
        validate: false,
        value: null,
      },
      l_nombre: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nombre',
        id: 'editar_l_nombre',
        name: 'l_nombre',
        change_param: 'editar',
        regex: Regex.STRING_GENERICO_5_90,
        value: '',
        default_value: '',
        placeholder: 'Showroom Rosario',
        messages: {
          error: 'Por favor, ingrese un nombre de local válido',
          error_extra: RegexExtra.STRING_GENERICO_5_90,
        },
      },
      l_direccion: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Dirección',
        id: 'editar_l_direccion',
        name: 'l_direccion',
        change_param: 'editar',
        regex: Regex.DIRECCION_4_65,
        value: '',
        default_value: '',
        placeholder: 'Av. Pellegrini 1234, Rosario, Santa Fe',
        messages: {
          error: 'Por favor, ingrese una dirección válida',
          error_extra: RegexExtra.DIRECCION_4_65,
        },
      },
      l_horarios: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Horarios',
        id: 'editar_l_horarios',
        name: 'l_horarios',
        change_param: 'editar',
        regex: Regex.STRING_GENERICO_5_90,
        value: '',
        default_value: '',
        placeholder: 'Lunes a viernes de 10:00 a 18:00',
        messages: {
          error: 'Por favor, ingrese un horario válido',
          error_extra: RegexExtra.STRING_GENERICO_5_90,
        },
      },
      l_telefono: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Teléfono',
        id: 'editar_l_telefono',
        name: 'l_telefono',
        change_param: 'editar',
        regex: Regex.TELEFONO_COMPUESTO,
        value: '',
        default_value: '',
        placeholder: '1234578990',
        messages: {
          error: 'Por favor, ingrese un teléfono válido',
          error_extra: RegexExtra.TELEFONO_COMPUESTO,
        },
      },
      l_descripcion: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Descripción',
        id: 'editar_l_descripcion',
        name: 'l_descripcion',
        change_param: 'editar',
        regex: Regex.DESCRIPCION_0_200,
        value: '',
        default_value: '',
        placeholder: 'Retiro a las 48hs previo pago',
        messages: {
          error: 'Por favor, ingrese una descripción válida',
          help: 'Esta información se le mostrará a los clientes cuando calculen el costo de envío o lo seleccionen en el checkout.',
          error_extra: RegexExtra.DESCRIPCION_0_200,
        },
      },
    },

    //datos para el modal borrar
    borrar: {
      idLocales: null,
      l_nombre: '',
    },

    //carga de datos
    loading: {
      metodos_envio: true,
      locales: true,
      error: false,
    },

    //modales
    modals: {
      agregar: false,
      editar: false,
      borrar: false,
    },

    //forms
    forms: {
      activar_retiro_local: false,
      desactivar_retiro_local: false,
      agregar: false,
      editar: false,
      borrar: false,
    },

    //alerta
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  //maneja el cambio de todos los inputs
  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      {
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: input,
            },
          });
        });
      }
    );
  };

  componentDidMount() {
    this.props.metodosenvioObtener((err, resp) => {
      this.setState({
        loading: {
          ...this.state.loading,
          metodos_envio: false,
          error: this.state.loading.error || err ? true : false,
        },
      });
    });
    this.props.localObtenerLocales((err, resp) => {
      this.setState({
        loading: {
          ...this.state.loading,
          locales: false,
          error: this.state.loading.error || err ? true : false,
        },
      });
    });
  }

  //submits
  handleActivarRetiroLocalSubmit = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        activar_retiro_local: true,
      },
    });

    this.props.metodoenvioActivarRetiroLocal((err, resp) => {
      this.setState(
        {
          forms: {
            ...this.state.forms,
            activar_retiro_local: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          },
        },
        () => {
          if (!err) {
            createAmplitudeEventWithDevice(
              method_shipment_amplitude_events.metodos_envio_activar.event_name,
              { metodo_envio: 'retiro', success: true }
            );

            if (this.props.idCountry === 'ARG') {
              braze.logCustomEvent('empretienda_metodos_envío_activar', {
                metodo_envio: 'retiro',
              });
            }
          } else {
            createAmplitudeEventWithDevice(
              method_shipment_amplitude_events.metodos_envio_activar.event_name,
              { metodo_envio: 'retiro', success: false }
            );
          }
        }
      );
    });
  };
  handleDesactivarRetiroLocalSubmit = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        desactivar_retiro_local: true,
      },
    });

    this.props.metodoenvioDesactivarRetiroLocal((err, resp) => {
      this.setState(
        {
          forms: {
            ...this.state.forms,
            desactivar_retiro_local: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          },
        },
        () => {
          if (!err) {
            createAmplitudeEventWithDevice(
              method_shipment_amplitude_events.metodos_envio_desactivar
                .event_name,
              { metodo_envio: 'retiro' }
            );
          } else {
            createAmplitudeEventWithDevice(
              method_shipment_amplitude_events.metodos_envio_desactivar
                .event_name,
              { metodo_envio: 'retiro' }
            );
          }
        }
      );
    });
  };

  handleSubmitAgregar = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        agregar: true,
      },
    });
    let form = this.state.agregar;

    if (Validate.validar_formulario(form)) {
      this.props.localAgregar(
        {
          l_nombre: form.l_nombre.value,
          l_direccion: form.l_direccion.value,
          l_horarios: form.l_horarios.value,
          l_telefono: form.l_telefono.value,
          l_descripcion: form.l_descripcion.value,
        },
        (err, resp) => {
          if (err) {
            this.setState({
              forms: {
                ...this.state.forms,
                agregar: false,
              },
              alert: {
                open: true,
                message: resp.message,
                type: 'error',
              },
            });
          } else {
            this.setState(
              {
                forms: {
                  ...this.state.forms,
                  agregar: false,
                },
                alert: {
                  open: true,
                  message: resp.message,
                  type: 'success',
                },
                modals: {
                  ...this.state.modals,
                  agregar: false,
                },
              },
              () => {
                let form_obj = Validate.limpiar_formulario(this.state.agregar);
                this.setState({
                  agregar: form_obj,
                });
              }
            );
          }
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          agregar: new_form,
          forms: {
            ...this.state.forms,
            agregar: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: 'Por favor, revisá tus datos, tenés algunos errores',
            type: 'error',
          },
        });
      });
    }
  };

  handleSubmitEditar = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        editar: true,
      },
    });
    let form = this.state.editar;

    if (Validate.validar_formulario(form)) {
      this.props.localEditar(
        {
          idLocales: form.idLocales.value,
          l_nombre: form.l_nombre.value,
          l_direccion: form.l_direccion.value,
          l_horarios: form.l_horarios.value,
          l_telefono: form.l_telefono.value,
          l_descripcion: form.l_descripcion.value,
        },
        (err, resp) => {
          if (err) {
            this.setState({
              forms: {
                ...this.state.forms,
                editar: false,
              },
              alert: {
                open: true,
                message: resp.message,
                type: 'error',
              },
            });
          } else {
            this.setState(
              {
                forms: {
                  ...this.state.forms,
                  editar: false,
                },
                alert: {
                  open: true,
                  message: resp.message,
                  type: 'success',
                },
                modals: {
                  ...this.state.modals,
                  editar: false,
                },
              },
              () => {
                let form_obj = Validate.limpiar_formulario(this.state.editar);
                this.setState({
                  editar: form_obj,
                });
              }
            );
          }
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          editar: new_form,
          forms: {
            ...this.state.forms,
            editar: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: 'Por favor, revisá tus datos, tenés algunos errores',
            type: 'error',
          },
        });
      });
    }
  };

  handleSubmitBorrar = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        borrar: true,
      },
    });

    let form = this.state.borrar;

    this.props.localBorrar({ idLocales: form.idLocales }, (err, resp) => {
      if (err) {
        this.setState({
          forms: {
            ...this.state.forms,
            borrar: false,
          },
          alert: {
            open: true,
            message: resp.message,
            type: 'error',
          },
        });
      } else {
        this.setState({
          forms: {
            ...this.state.forms,
            borrar: false,
          },
          alert: {
            open: true,
            message: resp.message,
            type: 'success',
          },
          modals: {
            ...this.state.modals,
            borrar: false,
          },
        });
      }
    });
  };

  //abre y cierra los modales
  modalHandler = (open, id, aditional) => {
    switch (id) {
      case 'agregar':
        this.setState(
          {
            modals: {
              ...this.state.modals,
              [id]: open,
            },
          },
          () => {
            if (!open) {
              //limpio el formulario del modal agregar
              let form_obj = Validate.limpiar_formulario(this.state.agregar);
              this.setState({
                agregar: form_obj,
              });
            }
          }
        );
        break;
      case 'borrar':
        if (open) {
          let local = aditional;
          this.setState({
            borrar: {
              ...this.state.borrar,
              idLocales: local.idLocales,
              l_nombre: local.l_nombre,
            },
            modals: {
              ...this.state.modals,
              [id]: open,
            },
          });
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              [id]: open,
            },
          });
        }
        break;
      case 'editar':
        if (open) {
          let local = aditional;
          Validate.cargar_formulario_sin_nulos(
            local,
            this.state.editar,
            (editar_obj) => {
              this.setState({
                editar: editar_obj,
                modals: {
                  ...this.state.modals,
                  [id]: open,
                },
              });
            }
          );
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              [id]: open,
            },
          });
        }
        break;
      default:
        break;
    }
  };

  //cierra las alertas
  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  render() {
    const { classes, metodos_envio } = this.props;

    const activar_desactivar_btn = metodos_envio.me_retiro_local ? (
      <ButtonUI
        type="callf"
        label="Desactivar"
        fullWidth={false}
        onClickFunc={this.handleDesactivarRetiroLocalSubmit}
        isLoading={this.state.forms.desactivar_retiro_local}
        minHeight={true}
      />
    ) : (
      <ButtonUI
        type="callf"
        label="Activar"
        fullWidth={false}
        onClickFunc={this.handleActivarRetiroLocalSubmit}
        isLoading={this.state.forms.activar_retiro_local}
        minHeight={true}
      />
    );

    return (
      <WrapperLayout
        loading={this.state.loading.locales || this.state.loading.metodos_envio}
        error={this.state.loading.error}
      >
        <ModalAgregar
          agregar={this.state.agregar}
          handleSubmit={this.handleSubmitAgregar}
          handleChange={this.handleChange}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.agregar}
          form_state={this.state.forms.agregar}
        />
        <ModalEditar
          editar={this.state.editar}
          handleSubmit={this.handleSubmitEditar}
          handleChange={this.handleChange}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.editar}
          form_state={this.state.forms.editar}
        />
        <ModalBorrar
          borrar={this.state.borrar}
          handleSubmit={this.handleSubmitBorrar}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.borrar}
          form_state={this.state.forms.borrar}
        />
        <AlertUI
          open={this.state.alert.open}
          type={this.state.alert.type}
          message={this.state.alert.message}
          handleCloseAlert={this.handleCloseAlert}
        />
        <ModuleTitleUI
          title="Retiros en local"
          subtitles={[
            'En el momento de compra, los clientes tienen la opción de elegir en que local o domicilio poder retirarla.',
          ]}
          button={activar_desactivar_btn}
        />
        <ToggleUI show={metodos_envio.me_retiro_local ? true : false}>
          <Card className={classes.card_below}>
            <CardHeader
              title="Mis locales"
              className={classes.card_header}
              classes={{ action: classes.card_action_button }}
              action={
                <ButtonUI
                  type="callf"
                  label="Agregar"
                  onClickFunc={() =>
                    this.modalHandler(true, 'agregar', 'agregar')
                  }
                />
              }
            />
            <Divider />
            <CardContent className={classes.card_content}>
              <LocalesList modalHandler={this.modalHandler} />
            </CardContent>
          </Card>
        </ToggleUI>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_below: {
    ...theme.card.card,
    marginTop: '40px',
  },
  card_header: theme.card.card_header,
  card_content: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  card_action_button: theme.card.card_action_button,
});

const mapStateToProps = (state) => {
  return {
    locales: state.locales,
    metodos_envio: state.metodos_envio,
    idCountry: state.tienda.Country_idCountry,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    localAgregar: (data, callback) => dispatch(localAgregar(data, callback)),
    localEditar: (data, callback) => dispatch(localEditar(data, callback)),
    localBorrar: (data, callback) => dispatch(localBorrar(data, callback)),
    localObtenerLocales: (callback) => dispatch(localObtenerLocales(callback)),
    metodosenvioObtener: (callback) => dispatch(metodosenvioObtener(callback)),
    metodoenvioActivarRetiroLocal: (callback) =>
      dispatch(metodoenvioActivarRetiroLocal(callback)),
    metodoenvioDesactivarRetiroLocal: (callback) =>
      dispatch(metodoenvioDesactivarRetiroLocal(callback)),
  };
};

export const Local = withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(Local_)
);
