import React, { Component } from 'react';
import { debounce } from 'lodash';
import { Icon, Grid } from '@mui/material';
import { InputUI, CardModuleUI, ModuleTitleUI, ButtonUI } from '../../common';
import { CardText, Page } from '../subcomponents';
import { Regex, RegexExtra } from '../../../other';
import { withStyles } from '@mui/styles';
import { ConfirmarCambios } from './ConfirmarCambios';

class FiltrarProducto_ extends Component {
  new_products_filtered = [];
  edited_products_filtered = [];

  state = {
    filter: {
      search: {
        auth: false,
        validate: false,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Buscar por nombre',
        id: 'search',
        name: 'search',
        change_param: 'filter',
        regex: Regex.NOMBRE_PRODUCTO,
        value: '',
        default_value: '',
        placeholder: 'Nombre del producto',
        messages: {
          error: '',
          error_extra: RegexExtra.NOMBRE_PRODUCTO,
        },
      },
    },
    refresh_component: 0,
  };

  componentDidMount() {
    this.debounceMethod = debounce(this.filtrarProductos, 500);

    this.debounceMethod();
  }

  filtrarProductos() {
    // const new_products_filtered = this.props.new_products.map
    this.new_products_filtered = this.props.new_products.filter(
      (chunk_product) => {
        return chunk_product.p_nombre
          .toLowerCase()
          .indexOf(this.state.filter.search.value.toLowerCase()) >= 0
          ? true
          : false;
      }
    );
    this.edited_products_filtered = this.props.edited_products.filter(
      (chunk_product) => {
        return chunk_product.p_nombre
          .toLowerCase()
          .indexOf(this.state.filter.search.value.toLowerCase()) >= 0
          ? true
          : false;
      }
    );

    this.setState({
      refresh_component: this.state.refresh_component + 1,
    });
  }

  handleGetNewProductsFiltered = () => {
    return this.new_products_filtered;
  };

  handleGetEditedProductsFiltered = () => {
    return this.edited_products_filtered;
  };

  handleChange = (e, blur, aditional) => {
    if (!blur) {
      let value = e.target.value;
      let name = e.target.name;

      this.setState(
        {
          [aditional]: {
            ...this.state[aditional],
            [name]: {
              ...this.state[aditional][name],
              value: value,
            },
          },
        },
        () => this.debounceMethod()
      );
    }
  };

  render() {
    const { classes, handleConfirmarProceso, handleVolverASubir } = this.props;

    return (
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <ModuleTitleUI title="Confirme los cambios realizados" />
        </Grid>
        <Grid item xs={12}>
          <CardModuleUI>
            <Grid container>
              <Grid item xs={12}>
                <CardText text="Confirme para continuar con el proceso de importación de productos o vuelva a subir la planilla." />
              </Grid>
              <Grid style={{ marginBottom: '39px' }} item xs={12}>
                <InputUI
                  handleChange={this.handleChange}
                  input={this.state.filter.search}
                  end_adornment={<Icon>search</Icon>}
                />
              </Grid>
              <Grid item xs={12}>
                <ConfirmarCambios
                  handleGetNewProductsFiltered={
                    this.handleGetNewProductsFiltered
                  }
                  handleGetEditedProductsFiltered={
                    this.handleGetEditedProductsFiltered
                  }
                  refresh_component={this.state.refresh_component}
                />
              </Grid>
            </Grid>
          </CardModuleUI>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.buttons_desktop}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Page firstvalue="3" lastvalue="de 4" />
              </Grid>
              <Grid item>
                <ButtonUI
                  type="callf"
                  fullWidth={false}
                  variant="text"
                  label="Volver a subir el excel"
                  onClickFunc={(e) => handleVolverASubir()}
                />
                <ButtonUI
                  type="callf"
                  fullWidth={false}
                  variant="contained"
                  label="Confirmar"
                  onClickFunc={(e) => handleConfirmarProceso()}
                />
              </Grid>
            </Grid>
          </div>
          <div className={classes.buttons_mobile}>
            <Grid container>
              <Grid item xs={12}>
                <ButtonUI
                  type="callf"
                  fullWidth
                  variant="contained"
                  label="Confirmar"
                  onClickFunc={(e) => handleConfirmarProceso()}
                />
              </Grid>
              <Grid item xs={12}>
                <ButtonUI
                  type="callf"
                  fullWidth
                  variant="text"
                  label="Volver a subir el excel"
                  onClickFunc={(e) => handleVolverASubir()}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: '36px' }}>
                <Page firstvalue="3" lastvalue="de 4" />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  buttons_desktop: {
    display: 'block',
    marginTop: '10px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  buttons_mobile: {
    display: 'none',
    marginTop: '36px',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
});

export const FiltrarProducto = withStyles(styles)(FiltrarProducto_);
