import React from 'react';
import { ButtonUI, DropdownUI, MenuItemUI } from '../../common';
import { GridItemCard } from '../components/GridItemCard';
import { CustomCardUIWithDetails } from '../components/CustomCardUIWithDetails';
import { Config } from '../../../other';

const MercadoPago = (props) => {
  const { metodos_pago, forms, handleDeshabilitarMercadoPagoSubmit } = props;

  const cargarBoton = () => {
    if (metodos_pago.mp_mercadopago) {
      return (
        <DropdownUI
          label="Configuración"
          size="large"
          isLoading={forms.desactivar_mercadopago}
        >
          <MenuItemUI
            type="link"
            link={metodos_pago.mp_mercadopago_activate_link}
            external
          >
            Cambiar cuenta
          </MenuItemUI>
          <MenuItemUI
            type="callf"
            onClickFunction={handleDeshabilitarMercadoPagoSubmit}
          >
            Desactivar
          </MenuItemUI>
        </DropdownUI>
      );
    } else {
      return (
        <ButtonUI
          type="link"
          link={metodos_pago.mp_mercadopago_activate_link}
          external_link
          label="Activar"
          size="large"
        />
      );
    }
  };

  return (
    <GridItemCard lg={6}>
      <CustomCardUIWithDetails
        title="Mercado Pago"
        ivaInfo="6,99% + IVA"
        subtitle={
          metodos_pago.mp_mercadopago ? 'Método activo' : 'Método no activo'
        }
        image={`${Config.CLOUDFRONT_CDN_PROD}/panel/medios_pago/mercadopago-card-img.png`}
        info={
          <>
            Te permitirá que tus clientes puedan pagar con tarjetas de crédito,
            débito, transferencia y efectivo. Mercado Pago te cobrará una
            comisión por cada venta que realices.{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
              href="https://www.mercadopago.com.ar/ayuda/costo-recibir-pagos_220"
            >
              Ver info
            </a>
          </>
        }
      >
        {cargarBoton()}
      </CustomCardUIWithDetails>
    </GridItemCard>
  );
};

export { MercadoPago };
