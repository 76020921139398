import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import async from 'async';
import axios from 'axios';
import arrayMove from 'array-move';
import { connect } from 'react-redux';
import { adminLogout } from '../../store/actions';
import { Grid, Icon, LinearProgress } from '@mui/material';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { ModuleCardTitleUI, AlertUI, ModalUI, InputUI } from '.';
import { Config, Validate, Regex, RegexExtra } from '../../other';
import 'blueimp-canvas-to-blob/js/canvas-to-blob';
import loadImage from 'blueimp-load-image';
import { ToggleUI } from './ToggleUI';
import { withStyles } from '@mui/styles';

class ImageUI_ extends Component {
  state = {
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    agregar_url: {
      index: {
        validate: false,
        value: 0,
      },
      url: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Link',
        id: 'url',
        name: 'url',
        change_param: 'agregar_url',
        regex: Regex.LINK,
        value: '',
        default_value: '',
        placeholder: '',
        messages: {
          error: 'Por favor, ingrese un link válido',
          help: '',
          error_extra: RegexExtra.LINK,
        },
      },
    },
    agregar_title: {
      index: {
        validate: false,
        value: 0,
      },
      title: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Título',
        id: 'title',
        name: 'title',
        change_param: 'agregar_title',
        regex: Regex.STRING_GENERICO_2_45,
        value: '',
        default_value: '',
        placeholder: '',
        messages: {
          error: 'Por favor, ingrese un título válido',
          help: '',
          error_extra: RegexExtra.STRING_GENERICO_2_45,
        },
      },
    },
    modals: {
      agregar_url: false,
      agregar_title: false,
    },
    is_uploading: false,
    uploading_number: 0,
    progress: 0,
  };

  handleUploadImages = (images) => {
    let {
      upload_path,
      reducer_max_height,
      reducer_max_width,
      data,
      handleChange,
    } = this.props;

    handleChange({
      ...data,
      uploading: true,
    });

    this.setState(
      {
        is_uploading: true,
        uploading_number: images.length,
        progress: 0,
      },
      () => {
        let body = new FormData();
        body.append('max_width', reducer_max_width);
        body.append('max_height', reducer_max_height);
        images.forEach((image) => {
          body.append('images[]', image);
        });

        axios
          .post(Config.BACKEND_ENDPOINT + '/upload/' + upload_path, body, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
              let percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              percentCompleted = percentCompleted <= 90 ? percentCompleted : 90;
              this.setState({ progress: percentCompleted });
            },
          })
          .then((resp) => {
            let new_files = resp.data.data.map((link) => {
              return {
                link: link,
                url: '',
                title: '',
                button: '',
              };
            });

            this.setState(
              {
                is_uploading: false,
                uploading_number: 0,
                progress: 0,
              },
              () => {
                handleChange({
                  ...data,
                  auth: true,
                  uploading: false,
                  files: data.files.concat(new_files),
                });
              }
            );
          })
          .catch((err) => {
            let resp = Validate.procesar_error_axios(err.response);

            if (this.props.handlerShowAlertUI) {
              this.props.handlerShowAlertUI({
                open: true,
                type: 'error',
                message: resp.message,
              });

              this.setState(
                {
                  is_uploading: false,
                  uploading_number: 0,
                  progress: 0,
                },
                () => {
                  handleChange({
                    ...data,
                    auth:
                      data.required && data.files.length === 0 ? false : true,
                    uploading: false,
                    messages: {
                      ...data.messages,
                      error: resp.message,
                    },
                  });
                }
              );
            } else {
              this.setState(
                {
                  is_uploading: false,
                  uploading_number: 0,
                  progress: 0,
                  alert: {
                    ...this.state.alert,
                    open: true,
                    message: resp.message,
                  },
                },
                () => {
                  handleChange({
                    ...data,
                    auth:
                      data.required && data.files.length === 0 ? false : true,
                    uploading: false,
                    messages: {
                      ...data.messages,
                      error: resp.message,
                    },
                  });
                }
              );
            }
          });
      }
    );
  };

  handleCompressImage = (image, callback) => {
    let { reducer_max_height, reducer_max_width } = this.props;
    if (image.type === 'image/gif') {
      return callback(image);
    } else {
      loadImage(
        image,
        (img) => {
          img.toBlob((blob) => {
            let imgReduced = new File([blob], image.name, {
              type: image.type,
              lastModified: Date.now(),
            });
            return callback(imgReduced);
          }, image.type);
        },
        {
          meta: true,
          canvas: true,
          imageSmoothingQuality: 'high',
          imageSmoothingEnabled: true,
          maxWidth: reducer_max_width,
          maxHeight: reducer_max_height,
          orientation: true,
        }
      );
    }
  };

  processSingleFile = () => {
    let { data, handleChange } = this.props;
    let id = data.id;
    let empic = document.getElementById('empic_' + id);
    let image = empic.files[0];
    empic.value = '';

    if (image !== undefined) {
      let { valid, message } = this.validarImagen(image);
      if (valid) {
        this.handleCompressImage(image, (imageReduced) =>
          this.handleUploadImages([imageReduced])
        );
      } else {
        if (this.props.handlerShowAlertUI) {
          this.props.handlerShowAlertUI({
            open: true,
            type: 'error',
            message: message,
          });

          handleChange({
            ...data,
            auth: data.required ? false : true,
            files: [],
            messages: {
              ...data.messages,
              error: message,
            },
          });

          empic.value = '';
        } else {
          this.setState(
            {
              alert: {
                ...this.state.alert,
                open: true,
                message: message,
              },
            },
            () => {
              handleChange({
                ...data,
                auth: data.required ? false : true,
                files: [],
                messages: {
                  ...data.messages,
                  error: message,
                },
              });
              empic.value = '';
            }
          );
        }
      }
    }
  };

  processMultipleFiles = () => {
    let { data, max_files, handleChange } = this.props;
    let id = data.id;
    let empic = document.getElementById('empic_' + id);
    let files = [...empic.files];
    empic.value = '';

    if (!(files.length === 0 || files[0] === undefined)) {
      if (files.length + data.files.length <= max_files) {
        let filesArr = [];
        let i, validation;
        for (i = 0; i < files.length; i++) {
          validation = this.validarImagen(files[i]);
          if (validation.valid) {
            filesArr.push(files[i]);
          } else {
            break;
          }
        }

        if (validation.valid) {
          async.map(
            filesArr,
            (image, callback) => {
              this.handleCompressImage(image, (imageReduced) =>
                callback(null, imageReduced)
              );
            },
            (err, imagesReduced) => this.handleUploadImages(imagesReduced)
          );
        } else {
          if (this.props.handlerShowAlertUI) {
            this.props.handlerShowAlertUI({
              open: true,
              type: 'error',
              message: validation.message,
            });

            handleChange({
              ...data,
              auth: data.required && data.files.length === 0 ? false : true,
              messages: {
                ...data.messages,
                error: validation.message,
              },
            });
          } else {
            this.setState(
              {
                alert: {
                  ...this.state.alert,
                  open: true,
                  message: validation.message,
                },
              },
              () => {
                handleChange({
                  ...data,
                  auth: data.required && data.files.length === 0 ? false : true,
                  messages: {
                    ...data.messages,
                    error: validation.message,
                  },
                });
              }
            );
          }
        }
      } else {
        const msgAlert = 'Puedes subir hasta ' + max_files + ' imágenes';

        if (this.props.handlerShowAlertUI) {
          this.props.handlerShowAlertUI({
            open: true,
            type: 'error',
            message: msgAlert,
          });

          handleChange({
            ...data,
            messages: {
              ...data.messages,
              error: msgAlert,
            },
          });
        } else {
          this.setState(
            {
              alert: {
                ...this.state.alert,
                open: true,
                message: msgAlert,
              },
            },
            () => {
              handleChange({
                ...data,
                messages: {
                  ...data.messages,
                  error: msgAlert,
                },
              });
            }
          );
        }
      }
    }
  };

  validarImagen = (file) => {
    let { allowed_files, max_size } = this.props;
    let { name, size } = file;

    let extensionArr = name.split('.');
    if (extensionArr.length > 1) {
      let extension = Validate.trim_lowercase(
        extensionArr[extensionArr.length - 1]
      ); //obtengo la extension
      if (Validate.in_array(extension, allowed_files)) {
        size = size / 1024 / 1024; //convierto a mb
        if (size <= max_size) {
          if (extension === 'gif') {
            if (size <= 0.59) {
              return {
                valid: true,
                message: '',
              };
            } else {
              return {
                valid: false,
                message:
                  'La imagen ' +
                  name +
                  ' no debe exceder los 500kb de tamaño, intenta reduciéndola con alguna herramienta',
              };
            }
          } else {
            return {
              valid: true,
              message: '',
            };
          }
        } else {
          return {
            valid: false,
            message:
              'La imagen ' +
              name +
              ' no debe exceder los ' +
              max_size +
              'mb de tamaño',
          };
        }
      } else {
        let tipos = allowed_files.join(', ');
        return {
          valid: false,
          message: 'La imagen ' + name + ' debe ser de tipo: ' + tipos,
        };
      }
    } else {
      let tipos = allowed_files.join(', ');
      return {
        valid: false,
        message: 'La imagen ' + name + ' debe ser de tipo: ' + tipos,
      };
    }
  };

  removerImagen = (index) => {
    let { data, handleChange } = this.props;
    let { files } = data;

    files = files.filter((file, idx) => {
      return index !== idx;
    });

    handleChange({
      ...data,
      auth: data.required && files.length === 0 ? false : true,
      files: files,
      messages: {
        ...data.messages,
        error: '',
      },
    });
  };

  renderizarImagen = (file, index) => {
    let {
      xs,
      sm,
      md,
      lg,
      classes,
      row_height,
      cdn_prefix,
      enable_title = false,
      enable_url = false,
      z_index = 1300,
    } = this.props;

    let link = cdn_prefix + file.link;

    return (
      <Grid
        item
        lg={lg}
        sm={sm}
        md={md}
        xs={xs}
        key={index}
        style={{ zIndex: z_index }}
      >
        <div className={classes.empic_image_box} style={{ height: row_height }}>
          <Icon
            className={classes.empic_remove_icon}
            onClick={(e) => this.removerImagen(index)}
          >
            clear
          </Icon>
          <ToggleUI show={enable_url}>
            <Icon
              className={classes.empic_url_icon}
              onClick={(e) => this.modalHandler(true, 'agregar_url', index)}
            >
              {file.url ? 'link' : 'link_off'}
            </Icon>
          </ToggleUI>
          <ToggleUI show={enable_title}>
            <Icon
              className={classes.empic_title_icon}
              onClick={(e) => this.modalHandler(true, 'agregar_title', index)}
            >
              {file.title ? 'title' : 'format_strikethrough'}
            </Icon>
          </ToggleUI>
          <img
            src={link}
            className={classes.empic_image_preview}
            alt="imagen empic"
          />
        </div>
      </Grid>
    );
  };

  renderizarEmpty = (index, uploading) => {
    let {
      xs,
      sm,
      md,
      lg,
      classes,
      row_height,
      data,
      z_index = 1300,
    } = this.props;

    let { id } = data;

    let content = uploading ? (
      <div
        className={classes.empic_empty_box}
        style={{ height: row_height, zIndex: z_index }}
      >
        <div className={classes.empic_loading_box}>
          <LinearProgress variant="determinate" value={this.state.progress} />
        </div>
      </div>
    ) : (
      <label htmlFor={'empic_' + id}>
        <div className={classes.empic_empty_box} style={{ height: row_height }}>
          <Icon className={classes.empic_empty_icon}>photo</Icon>
        </div>
      </label>
    );

    return (
      <Grid
        item
        lg={lg}
        sm={sm}
        md={md}
        xs={xs}
        key={index}
        style={{ zIndex: z_index }}
      >
        {content}
      </Grid>
    );
  };

  onSortEnd = ({ newIndex, oldIndex }) => {
    let { data, handleChange } = this.props;

    if (newIndex !== oldIndex) {
      let new_files = arrayMove(data.files, oldIndex, newIndex);
      handleChange({
        ...data,
        files: new_files,
      });
    }
  };

  singleMode = () => {
    let { input_allowed_mime, classes } = this.props;
    let { files, label, sublabels, id } = this.props.data;

    let html_head = label ? (
      <ModuleCardTitleUI title={label} subtitles={sublabels} />
    ) : (
      ''
    );

    return (
      <Grid container spacing={1.5}>
        {html_head}
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <input
          type="file"
          id={'empic_' + id}
          accept={input_allowed_mime}
          className={classes.empic_input}
          onChange={this.processSingleFile}
        />
        {files.length
          ? this.renderizarImagen(files[0], 0)
          : this.renderizarEmpty(0, this.state.is_uploading)}
      </Grid>
    );
  };

  multipleMode = () => {
    let { input_allowed_mime, max_files, classes } = this.props;

    let { files, label, sublabels, id } = this.props.data;

    const SortableItem = SortableElement(({ file, idx }) =>
      this.renderizarImagen(file, idx)
    );

    const SortableContainerr = SortableContainer(({ children }) => {
      return (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {children}
          </Grid>
        </Grid>
      );
    });

    let html = files.map((file, index) => {
      return <SortableItem file={file} idx={index} index={index} key={index} />;
    });

    let html_empties = new Array(max_files - files.length)
      .fill({})
      .map((emptie, index) => {
        let uploading =
          this.state.is_uploading && index < this.state.uploading_number
            ? true
            : false;
        return this.renderizarEmpty(index + files.length, uploading);
      });

    let html_head = label ? (
      <ModuleCardTitleUI title={label} subtitles={sublabels} />
    ) : (
      ''
    );

    let mobile = Validate.is_mobile();

    return (
      <Grid container spacing={1.5}>
        {html_head}
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <input
          type="file"
          id={'empic_' + id}
          accept={input_allowed_mime}
          className={classes.empic_input}
          onChange={this.processMultipleFiles}
          multiple
        />
        <SortableContainerr
          axis="xy"
          onSortEnd={this.onSortEnd}
          pressDelay={mobile ? 100 : 200}
        >
          {html}
          {html_empties}
        </SortableContainerr>
      </Grid>
    );
  };

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      {
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: input,
            },
          });
        });
      }
    );
  };

  modalHandler = (open, id, index) => {
    if (open) {
      let input;
      switch (id) {
        case 'agregar_url':
          input = this.state.agregar_url.url;
          input.value = this.props.data.files[index].url;
          input = Validate.validate_input_sync(input, true);

          this.setState({
            agregar_url: {
              ...this.state.agregar_url,
              index: {
                ...this.state.agregar_url.index,
                value: index,
              },
              url: input,
            },
            modals: {
              ...this.state.modals,
              [id]: true,
            },
          });
          break;
        case 'agregar_title':
          input = this.state.agregar_title.title;
          input.value = this.props.data.files[index].title;
          input = Validate.validate_input_sync(input, true);

          this.setState({
            agregar_title: {
              ...this.state.agregar_title,
              index: {
                ...this.state.agregar_title.index,
                value: index,
              },
              title: input,
            },
            modals: {
              ...this.state.modals,
              [id]: true,
            },
          });
          break;
        default:
          break;
      }
    } else {
      this.setState({
        modals: {
          ...this.state.modals,
          [id]: false,
        },
      });
    }
  };

  handleSubmitLink = (e) => {
    e.preventDefault();
    let form = this.state.agregar_url;
    if (Validate.validar_formulario(form)) {
      let { data, handleChange } = this.props;
      let index = this.state.agregar_url.index.value;
      let url = Validate.trim(this.state.agregar_url.url.value);
      data.files[index].url = url;
      this.setState(
        {
          modals: {
            ...this.state.modals,
            agregar_url: false,
          },
        },
        () => handleChange(data)
      );
    }
  };

  handleSubmitTitle = (e) => {
    e.preventDefault();
    let form = this.state.agregar_title;
    if (Validate.validar_formulario(form)) {
      let { data, handleChange } = this.props;
      let index = this.state.agregar_title.index.value;
      let title = Validate.trim(this.state.agregar_title.title.value);
      data.files[index].title = title;
      this.setState(
        {
          modals: {
            ...this.state.modals,
            agregar_title: false,
          },
        },
        () => handleChange(data)
      );
    }
  };

  render() {
    let { max_files } = this.props;
    let html = max_files === 1 ? this.singleMode() : this.multipleMode();

    return (
      <Fragment>
        <ModalUI
          open={this.state.modals.agregar_url}
          id="agregar_url"
          title="Hipervínculo"
          subtitle="Este es el link al que llevara al cliente si hace click sobre la imagen."
          modalHandler={this.modalHandler}
          aditional_param="agregar_url"
          handleSubmit={this.handleSubmitLink}
          button_label="Guardar"
        >
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <InputUI
                handleChange={this.handleChange}
                input={this.state.agregar_url.url}
              />
            </Grid>
          </Grid>
        </ModalUI>
        <ModalUI
          open={this.state.modals.agregar_title}
          id="agregar_title"
          title="Título"
          subtitle="Aparecerá sobre la imagen"
          modalHandler={this.modalHandler}
          aditional_param="agregar_title"
          handleSubmit={this.handleSubmitTitle}
          button_label="Guardar"
        >
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <InputUI
                handleChange={this.handleChange}
                input={this.state.agregar_title.title}
              />
            </Grid>
          </Grid>
        </ModalUI>
        {html}
      </Fragment>
    );
  }
}

const styles = (theme) => ({
  empic_empty_box: {
    border: '1px dotted #9e9e9e',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  empic_loading_box: {
    padding: '0px 10%',
    boxSizing: 'border-box',
    flexGrow: 1,
  },
  empic_empty_icon: {
    fontSize: '50px',
  },
  empic_input: {
    display: 'none',
  },
  empic_image_box: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  empic_image_preview: {
    width: 'auto',
    height: 'auto',
    maxHeight: '95%',
    maxWidth: '95%',
    zIndex: '998 !important',
  },
  empic_remove_icon: {
    position: 'absolute',
    fontSize: '25px',
    alignSelf: 'flex-start',
    color: '#fff',
    top: '0',
    right: '0',
    cursor: 'pointer',
    textShadow: '1px 0px 3px rgba(150, 150, 150, 1)',
    zIndex: '999 !important',
  },
  empic_url_icon: {
    position: 'absolute',
    fontSize: '25px',
    alignSelf: 'flex-start',
    color: '#fff',
    top: 'calc(100% - 25px)',
    right: '0',
    cursor: 'pointer',
    textShadow: '1px 0px 3px rgba(150, 150, 150, 1)',
    zIndex: '999 !important',
  },
  empic_title_icon: {
    position: 'absolute',
    fontSize: '25px',
    alignSelf: 'flex-start',
    color: '#fff',
    top: 'calc(100% - 25px)',
    left: '0',
    cursor: 'pointer',
    textShadow: '1px 0px 3px rgba(150, 150, 150, 1)',
    zIndex: '999 !important',
  },
});

ImageUI_.propTypes = {
  /**
   * Enable user from adding an url for the picture
   * Default: false
   */
  enable_url: PropTypes.bool,

  /**
   * Enable user from adding a text for the picture
   * Default: false
   */
  enable_title: PropTypes.bool,

  /**
   * Determines the z-Index of the images
   * Default: 1300
   */
  z_index: PropTypes.number,

  /**
   * Endpoint to upload the images
   */
  upload_path: PropTypes.string.isRequired,

  /**
   * Object used to add some styling with withStyles
   */
  classes: PropTypes.object,

  /**
   * The object initialized in state
   */
  data: PropTypes.shape({
    validate: PropTypes.bool.isRequired,
    auth: PropTypes.bool.isRequired,
    uploading: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.any,
    sublabels: PropTypes.array,
    files: PropTypes.arrayOf(
      PropTypes.shape({
        //solo contiene el numero de archivos insertados
        link: PropTypes.string.isRequired, //link for preview
        url: PropTypes.string.isRequired, //hipervinculo
        title: PropTypes.string.isRequired, //title
        button: PropTypes.string.isRequired, //button (not implemented)
      })
    ).isRequired,
    messages: PropTypes.shape({
      help: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      error: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }).isRequired,
  }),

  /**
   * number of files allowed
   */
  max_files: PropTypes.number.isRequired,

  /**
   * max size in mb for a single file
   */
  max_size: PropTypes.number.isRequired,

  /**
   * max size in mb for a single file
   */
  reducer_max_width: PropTypes.number.isRequired,

  /**
   * max size in mb for a single file
   */
  reducer_max_height: PropTypes.number.isRequired,

  /**
   * file extensions allowed, used in validation func
   * ej: ["jpg","png",...]
   */
  allowed_files: PropTypes.arrayOf(PropTypes.string).isRequired,

  /**
   * file extensions allowed, used in the input
   * ej: image/jpg,image/png,image/jpeg
   */
  input_allowed_mime: PropTypes.string.isRequired,

  /**
   * the function used to send the files data
   * handleChange(data:obj)
   */
  handleChange: PropTypes.func.isRequired,

  /**
   * this is used to preview images
   */
  cdn_prefix: PropTypes.string.isRequired,

  /**
   * defines the height of each single image square
   */
  row_height: PropTypes.string.isRequired,

  /**
   * preview size in mobile (default 12)
   */
  xs: PropTypes.number,

  /**
   * preview size in tablet (default 12)
   */
  sm: PropTypes.number,

  /**
   * preview size in big tablets and small desktop screens (default 12)
   */
  md: PropTypes.number,

  /**
   * preview size in large and hd screens (default 12)
   */
  lg: PropTypes.number,
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
  return {
    adminLogout: () => dispatch(adminLogout()),
  };
};

export const ImageUI = withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(ImageUI_)
);
