import React, { Component } from 'react';
import { connect } from 'react-redux';
import Chart from 'chart.js/auto';
import { ventaObtenerVentas } from '../../store/actions';
import { Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import {
  AlertUI,
  ModuleTitleUI,
  ModuleCardTitleUI,
  DropdownUI,
  MenuItemUI,
  CardModuleUI,
} from '../common';
import { Validate, Config } from '../../other';
import { MasVendidosList, ModalPeriodo } from './subcomponents';
import { WrapperLayout } from '../../layouts/WrapperLayout';

const fecha = new Date();
const meses = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];
const mes_actual = fecha.getMonth();
const year_actual = fecha.getFullYear();
const cantidad_dias_mes = new Date(year_actual, mes_actual + 1, 0).getDate();

let total_en_ventas = 0;
let cant_de_ventas = 0;

const ventasYear = (ventas) => {
  let mes, fecha;
  let metricasYear = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  ventas.forEach((venta) => {
    if (venta.v_estado_pago === 1) {
      fecha = venta.v_fecha.split('-');
      mes = parseInt(fecha[1]) - 1;

      total_en_ventas += parseFloat(venta.v_monto);
      cant_de_ventas++;

      metricasYear[mes] += parseFloat(venta.v_monto);
    }
  });

  return metricasYear;
};

const ventasMes = (ventas) => {
  let fecha, dia;
  let metricasMes = new Array(cantidad_dias_mes);
  for (let i = 0; i < metricasMes.length; i++) metricasMes[i] = 0;

  ventas.forEach((venta) => {
    if (venta.v_estado_pago === 1) {
      fecha = venta.v_fecha.split('-');
      dia = parseInt(fecha[2].split(' ')[0]).toString();

      total_en_ventas += parseFloat(venta.v_monto);
      cant_de_ventas++;

      metricasMes[dia - 1] += parseFloat(venta.v_monto);
    }
  });
  return metricasMes;
};

const ventasDia = (ventas) => {
  let fecha, hora, hora_exacta;
  let metricasDia = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ];
  ventas.forEach((venta) => {
    if (venta.v_estado_pago === 1) {
      fecha = venta.v_fecha.split('-');
      hora = fecha[2].split(' ');
      hora_exacta = parseInt(hora[1].split(':'));

      total_en_ventas += parseFloat(venta.v_monto);
      cant_de_ventas++;

      metricasDia[hora_exacta] += parseFloat(venta.v_monto);
    }
  });

  return metricasDia;
};

let custom_label = [];
let custom_fecha_desde,
  custom_fecha_hasta,
  custom_hora_desde,
  custom_hora_hasta;

const ventasSeleccionarPeriodo = (ventas, cant_dias, desde, hasta) => {
  let dia, mes, fecha, fecha_format, year, hora, hora_exacta;
  let metricas_x = [];

  let hora_desde, mes_desde, fecha_desde, year_desde, mes_desde_nombre;
  hora_desde = moment(desde).hour();
  fecha_desde = parseInt(desde.format('DD'));
  mes_desde = parseInt(desde.format('MM')) - 1;
  year_desde = parseInt(desde.format('YYYY'));
  mes_desde_nombre = Validate.trim_ucwords(
    moment(desde, 'YYY-MM-DD').format('MMM')
  );

  let hora_hasta, mes_hasta, fecha_hasta, year_hasta, mes_hasta_nombre;
  hora_hasta = moment(hasta).hour();
  fecha_hasta = parseInt(hasta.format('DD'));
  mes_hasta = parseInt(hasta.format('MM')) - 1;
  year_hasta = parseInt(hasta.format('YYYY'));
  mes_hasta_nombre = Validate.trim_ucwords(
    moment(hasta, 'YYY-MM-DD').format('MMM')
  );

  let desde_format = moment(desde).format('YYYY-MM-DD HH:mm');
  let hasta_format = moment(hasta).format('YYYY-MM-DD HH:mm');

  custom_fecha_desde = moment(desde).format(Config.SHORTDATE_FULLFORMAT);
  custom_hora_desde = moment(desde).format('HH:mm');
  custom_fecha_hasta = moment(hasta).format(Config.SHORTDATE_FULLFORMAT);
  custom_hora_hasta = moment(hasta).format('HH:mm');

  if (cant_dias <= 1) {
    if (fecha_desde !== fecha_hasta) {
      //Genero los arrays de los días con 24hs
      let horas_desde = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ];
      let horas_hasta = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ];

      // Genero los labels
      let horas_desde_label = [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
      ];
      let horas_hasta_label = [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
      ];

      // Corto los labels y los concateno
      horas_desde_label = horas_desde_label.slice(
        hora_desde,
        horas_desde_label.length
      );
      horas_hasta_label = horas_hasta_label.slice(0, hora_hasta + 1);

      // Asigno los labels a utilizar
      custom_label = horas_desde_label.concat(horas_hasta_label);

      ventas.forEach((venta) => {
        if (venta.v_estado_pago === 1) {
          fecha = venta.v_fecha.split('-');
          fecha_format = moment(venta.v_fecha).format('YYYY-MM-DD HH:mm');
          dia = parseInt(fecha[2].split(' ')[0]);
          mes = parseInt(fecha[1]) - 1;
          year = parseInt(fecha[0]);
          hora = fecha[2].split(' ');
          hora_exacta = parseInt(hora[1].split(':'));

          if (
            fecha_format >= desde_format &&
            dia === fecha_desde &&
            mes === mes_desde &&
            year === year_desde
          ) {
            total_en_ventas += parseFloat(venta.v_monto);
            cant_de_ventas++;
            horas_desde[hora_exacta] += parseFloat(venta.v_monto);
          } else if (
            fecha_format <= hasta_format &&
            dia === fecha_hasta &&
            mes === mes_hasta &&
            year === year_hasta
          ) {
            total_en_ventas += parseFloat(venta.v_monto);
            cant_de_ventas++;
            horas_hasta[hora_exacta] += parseFloat(venta.v_monto);
          }
        }
      });

      // Corto el array de valores del eje x y concateno
      horas_desde = horas_desde.slice(hora_desde, horas_desde.length);
      horas_hasta = horas_hasta.slice(0, hora_hasta + 1);

      metricas_x = horas_desde.concat(horas_hasta);

      return metricas_x;
    } else {
      //Genero el arrays del días con 24hs
      let horas = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ];

      // Genero el label
      let horas_label = [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
      ];

      // Corto el label
      horas_label = horas_label.slice(hora_desde, hora_hasta + 1);

      // Asigno los labels a utilizar
      custom_label = horas_label;

      ventas.forEach((venta) => {
        if (venta.v_estado_pago === 1) {
          fecha = venta.v_fecha.split('-');
          fecha_format = moment(venta.v_fecha).format('YYYY-MM-DD HH:mm');

          dia = parseInt(fecha[2].split(' ')[0]);
          mes = parseInt(fecha[1]) - 1;
          year = parseInt(fecha[0]);
          hora = fecha[2].split(' ');
          hora_exacta = parseInt(hora[1].split(':'));

          if (
            fecha_format >= desde_format &&
            fecha_format <= hasta_format &&
            dia === fecha_desde &&
            mes === mes_desde &&
            year === year_desde
          ) {
            total_en_ventas += parseFloat(venta.v_monto);
            cant_de_ventas++;
            horas[hora_exacta] += parseFloat(venta.v_monto);
          }
        }
      });

      // Corto el array de valores del eje x y concateno
      horas = horas.slice(hora_desde, hora_hasta + 1);

      metricas_x = horas;

      return metricas_x;
    }
  } else if (cant_dias > 1 && cant_dias <= 31) {
    if (mes_desde !== mes_hasta) {
      const cantidad_dias_mes_desde = new Date(
        year_desde,
        mes_desde + 1,
        0
      ).getDate();
      const cantidad_dias_mes_hasta = new Date(
        year_hasta,
        mes_hasta + 1,
        0
      ).getDate();

      // Genero los arrays de los meses selccionados en [0, 0, ...etc]
      let dias_desde = new Array(cantidad_dias_mes_desde);
      let dias_hasta = new Array(cantidad_dias_mes_hasta);
      dias_desde.fill(0, 0, dias_desde.length);
      dias_hasta.fill(0, 0, dias_hasta.length);

      // Genero los labels con día + mes
      let dias_desde_label = Object.keys(dias_desde).map(
        (dia) => `${parseInt(dia) + 1} ${mes_desde_nombre}`
      );
      let dias_hasta_label = Object.keys(dias_hasta).map(
        (dia) => `${parseInt(dia) + 1} ${mes_hasta_nombre}`
      );

      // Corto los labels y los concateno
      dias_desde_label = dias_desde_label.slice(
        fecha_desde - 1,
        dias_desde_label.length
      );
      dias_hasta_label = dias_hasta_label.slice(0, fecha_hasta);

      // Asigno los labels a utilizar
      custom_label = dias_desde_label.concat(dias_hasta_label);

      ventas.forEach((venta) => {
        if (venta.v_estado_pago === 1) {
          fecha = venta.v_fecha.split('-');
          fecha_format = moment(venta.v_fecha).format('YYYY-MM-DD HH:mm');
          dia = parseInt(fecha[2].split(' ')[0]).toString();
          mes = parseInt(fecha[1]) - 1;
          year = parseInt(fecha[0]);

          if (
            fecha_format >= desde_format &&
            mes === mes_desde &&
            year === year_desde
          ) {
            total_en_ventas += parseFloat(venta.v_monto);
            cant_de_ventas++;
            dias_desde[dia - 1] += parseFloat(venta.v_monto);
          } else if (
            fecha_format <= hasta_format &&
            mes === mes_hasta &&
            year === year_hasta
          ) {
            total_en_ventas += parseFloat(venta.v_monto);
            cant_de_ventas++;
            dias_hasta[dia - 1] += parseFloat(venta.v_monto);
          }
        }
      });

      // Corto el array de valores del eje x y concateno
      let dias_desde_array = dias_desde.slice(
        fecha_desde - 1,
        dias_desde.length
      );
      let dias_hasta_array = dias_hasta.slice(0, fecha_hasta);

      metricas_x = dias_desde_array.concat(dias_hasta_array);

      return metricas_x;
    } else {
      let fecha, dia;
      let metricasDias = new Array(cantidad_dias_mes);
      for (let i = 0; i < metricasDias.length; i++) metricasDias[i] = 0;
      let label_dias = Object.keys(metricasDias).map(
        (dia) => 'Día ' + (parseInt(dia) + 1)
      );

      ventas.forEach((venta) => {
        if (venta.v_estado_pago === 1) {
          fecha = venta.v_fecha.split('-');
          fecha_format = moment(venta.v_fecha).format('YYYY-MM-DD HH:mm');
          dia = parseInt(fecha[2].split(' ')[0]).toString();

          if (fecha_format >= desde_format && fecha_format <= hasta_format) {
            total_en_ventas += parseFloat(venta.v_monto);
            cant_de_ventas++;
            metricasDias[dia - 1] += parseFloat(venta.v_monto);
          }
        }
      });

      custom_label = label_dias.slice(fecha_desde - 1, fecha_hasta);
      metricasDias = metricasDias.slice(fecha_desde - 1, fecha_hasta);

      return metricasDias;
    }
  } else if (cant_dias > 31 && cant_dias <= 365) {
    if (year_desde !== year_hasta) {
      // Genero los labels con mes + año
      let meses_desde_label = meses.slice(mes_desde, meses.length);
      let meses_hasta_label = meses.slice(0, mes_hasta + 1);

      meses_desde_label = meses_desde_label.map(
        (mes) => `${mes} ${year_desde}`
      );
      meses_hasta_label = meses_hasta_label.map(
        (mes) => `${mes} ${year_hasta}`
      );

      //Concateno los arrays de los dos años
      custom_label = meses_desde_label.concat(meses_hasta_label);

      // Genero el array para el eje x
      let meses_desde = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let meses_hasta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      ventas.forEach((venta) => {
        if (venta.v_estado_pago === 1) {
          fecha = venta.v_fecha.split('-');
          fecha_format = moment(venta.v_fecha).format('YYYY-MM-DD HH:mm');
          dia = parseInt(fecha[2].split(' ')[0]);
          mes = parseInt(fecha[1]) - 1;
          year = parseInt(fecha[0]);

          if (fecha_format >= desde_format && year === year_desde) {
            total_en_ventas += parseFloat(venta.v_monto);
            cant_de_ventas++;
            meses_desde[mes] += parseFloat(venta.v_monto);
          } else if (fecha_format <= hasta_format && year === year_hasta) {
            total_en_ventas += parseFloat(venta.v_monto);
            cant_de_ventas++;
            meses_hasta[mes] += parseFloat(venta.v_monto);
          }
        }
      });

      // Armo el array de valores del eje x y concateno
      meses_desde = meses_desde.slice(mes_desde, meses.length);
      meses_hasta = meses_hasta.slice(0, mes_hasta + 1);

      metricas_x = meses_desde.concat(meses_hasta);
      return metricas_x;
    } else {
      // Genero los labels con mes desde a hasta
      custom_label = meses.slice(mes_desde, mes_hasta + 1);

      // Completo el array en [0, 0, etc...]
      for (let i = 0; i < 12; i++) metricas_x[i] = 0;

      ventas.forEach((venta) => {
        if (venta.v_estado_pago === 1) {
          fecha = venta.v_fecha.split('-');
          fecha_format = moment(venta.v_fecha).format('YYYY-MM-DD HH:mm');
          dia = parseInt(fecha[2].split(' ')[0]);
          mes = parseInt(fecha[1]) - 1;

          if (fecha_format >= desde_format && fecha_format <= hasta_format) {
            total_en_ventas += parseFloat(venta.v_monto);
            cant_de_ventas++;
            metricas_x[mes] += parseFloat(venta.v_monto);
          }
        }
      });

      metricas_x = metricas_x.slice(mes_desde, mes_hasta + 1);
      return metricas_x;
    }
  } else if (cant_dias > 365) {
    let years = [];
    let years_label = [];
    let index;

    // Creo el array para los valores del eje x en [0, 0, etc]
    for (let i = 0; i <= year_hasta - year_desde; i++) {
      years[i] = 0;
    }
    metricas_x = years;

    // Creo los labels con los años
    for (let i = year_desde; i <= year_hasta; i++) {
      years_label.push(i);
    }
    custom_label = years_label;

    ventas.forEach((venta) => {
      if (venta.v_estado_pago === 1) {
        fecha = venta.v_fecha.split('-');
        fecha_format = moment(venta.v_fecha).format('YYYY-MM-DD HH:mm');
        dia = parseInt(fecha[2].split(' ')[0]);
        mes = parseInt(fecha[1]) - 1;
        year = parseInt(fecha[0]);

        if (fecha_format >= desde_format && fecha_format <= hasta_format) {
          total_en_ventas += parseFloat(venta.v_monto);
          cant_de_ventas++;
          index = years_label.indexOf(year);
          metricas_x[index] += parseFloat(venta.v_monto);
        }
      }
    });

    return metricas_x;
  }
};

let init_date = moment().set('second', 0);

class Metricas_ extends Component {
  chartRef = React.createRef();
  state = {
    chart: null,
    loading: {
      ventas: true,
      metricas: true,
      error: false,
      tienda: true,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    filter: {
      filtro_ventas: 0,
    },
    seleccionar_periodo: {
      periodo_desde: {
        validate: false,
        momentobj: init_date.clone().subtract(1, 'days'),
        label: 'Desde',
        name: 'periodo_desde',
        change_param: 'seleccionar_periodo',
      },
      periodo_hasta: {
        validate: false,
        momentobj: init_date.clone(),
        label: 'Hasta',
        name: 'periodo_hasta',
        change_param: 'seleccionar_periodo',
      },
    },
    //modals
    modals: {
      seleccionar_periodo: false,
    },
    //forms
    forms: {
      seleccionar_periodo: false,
    },
  };

  setFilter = (filtro_ventas) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          filtro_ventas: filtro_ventas,
        },
        loading: {
          ...this.state.loading,
          metricas: true,
        },
      },
      () => this.cargarVentas(filtro_ventas)
    );
  };

  obtenerParametros = (desde, hasta) => {
    return {
      v_estado_pago: 1,
      desde: desde,
      hasta: hasta,
      page: -1,
    };
  };

  graficoVentas = () => {
    total_en_ventas = 0;
    cant_de_ventas = 0;

    const idCountry = this.props.tienda.Country_idCountry;

    let ventas = this.props.ventas.map((venta) => {
      return {
        ...venta,
        v_fecha: Validate.utc2local_format(
          venta.v_fecha,
          Config.DATETIME_FORMAT
        ),
      };
    });

    let data;
    let labels;

    switch (this.state.filter.filtro_ventas) {
      case -1:
        data = ventasYear(ventas);
        labels = meses;
        break;

      case 0:
        data = ventasMes(ventas);
        const label_dias_mes = Object.keys(data).map(
          (dia) => 'Día ' + (parseInt(dia) + 1)
        );
        labels = label_dias_mes;
        break;

      case 1:
        const horas = [
          '00:00',
          '01:00',
          '02:00',
          '03:00',
          '04:00',
          '05:00',
          '06:00',
          '07:00',
          '08:00',
          '09:00',
          '10:00',
          '11:00',
          '12:00',
          '13:00',
          '14:00',
          '15:00',
          '16:00',
          '17:00',
          '18:00',
          '19:00',
          '20:00',
          '21:00',
          '22:00',
          '23:00',
        ];
        data = ventasDia(ventas);
        labels = horas;
        break;

      case 2:
        let fecha_desde =
          this.state.seleccionar_periodo.periodo_desde.momentobj.clone();
        let fecha_hasta =
          this.state.seleccionar_periodo.periodo_hasta.momentobj.clone();

        let resta = moment.duration(fecha_hasta.diff(fecha_desde));
        let cant_dias = parseInt(
          resta.asMilliseconds() / (1000 * 60 * 60 * 24)
        );

        data = ventasSeleccionarPeriodo(
          ventas,
          cant_dias,
          fecha_desde,
          fecha_hasta
        );
        labels = custom_label;
        break;

      default:
        break;
    }

    let chart = this.state.chart;
    if (chart) {
      chart.data = {
        labels,
        datasets: [
          {
            label: 'Ventas',
            data,
            borderColor: '#53B755',
          },
        ],
      };
      chart.update();
    } else {
      const chartConfig = {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: 'Ventas',
              data,
              borderColor: '#53B755',
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: function (value, index, values) {
                  if (
                    idCountry === 'COL' &&
                    values[0]?.value === 0 &&
                    values[10]?.value === 1
                  ) {
                    return (
                      '$' + Validate.number_format(value * 1000, idCountry)
                    );
                  }
                  return '$' + Validate.number_format(value, idCountry);
                },
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  return (
                    ' ' +
                    context.dataset.label +
                    ': $' +
                    Validate.number_format(context.parsed.y, idCountry)
                  );
                },
              },
            },
          },
        },
      };
      chart = new Chart(this.chartRef.current, chartConfig);
    }

    this.setState({
      chart: chart,
    });
  };

  cargarVentas = (filter) => {
    let desde, hasta;
    switch (filter) {
      case -1:
        desde = Validate.moment_local2utc(moment().startOf('year')).format(
          Config.DATETIME_FORMAT
        );
        hasta = Validate.moment_local2utc(moment().endOf('year')).format(
          Config.DATETIME_FORMAT
        );
        break;

      case 0:
        desde = Validate.moment_local2utc(moment().startOf('month')).format(
          Config.DATETIME_FORMAT
        );
        hasta = Validate.moment_local2utc(moment().endOf('month')).format(
          Config.DATETIME_FORMAT
        );
        break;

      case 1:
        desde = Validate.moment_local2utc(moment().startOf('day')).format(
          Config.DATETIME_FORMAT
        );
        hasta = Validate.moment_local2utc(moment().endOf('day')).format(
          Config.DATETIME_FORMAT
        );
        break;

      case 2:
        desde = Validate.moment_local2utc(
          this.state.seleccionar_periodo.periodo_desde.momentobj.clone()
        ).format(Config.DATETIME_FORMAT);
        hasta = Validate.moment_local2utc(
          this.state.seleccionar_periodo.periodo_hasta.momentobj
            .clone()
            .set('second', 59)
        ).format(Config.DATETIME_FORMAT);
        break;

      default:
        break;
    }

    this.props.ventaObtenerVentas(
      this.obtenerParametros(desde, hasta),
      (err, resp) => {
        this.setState(
          {
            loading: {
              ...this.state.loading,
              ventas: false,
              metricas: false,
              error: err ? true : false,
            },
          },
          () => this.graficoVentas()
        );
      }
    );
  };

  componentDidMount() {
    this.cargarVentas(this.state.filter.filtro_ventas);
  }

  componentDidUpdate() {
    if (
      this.props &&
      this.state.loading.tienda &&
      this.props.tienda &&
      this.props.tienda.Country_idCountry
    ) {
      this.setState({
        loading: {
          ...this.state.loading,
          tienda: false,
        },
      });
    }
  }

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  modalHandler = (open, id) => {
    switch (id) {
      case 'seleccionar_periodo':
        if (open) {
          this.setState({
            modals: {
              ...this.state.modals,
              [id]: open,
            },
          });
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              [id]: open,
            },
          });
        }
        break;

      default:
        this.setState({
          modals: {
            ...this.state.modals,
            [id]: open,
          },
        });
        break;
    }
  };

  handleSubmitSeleccionarPeriodo = (e) => {
    e.preventDefault();

    let fecha_desde = this.state.seleccionar_periodo.periodo_desde.momentobj
      .clone()
      .set('milliseconds', 0);
    let fecha_hasta = this.state.seleccionar_periodo.periodo_hasta.momentobj
      .clone()
      .set('milliseconds', 0);

    let resta_fechas = moment.duration(fecha_hasta.diff(fecha_desde));

    fecha_desde = Validate.moment_local2utc_format(fecha_desde);
    fecha_hasta = Validate.moment_local2utc_format(fecha_hasta);

    if (
      !Validate.moment_comparar(fecha_desde, fecha_hasta) &&
      fecha_desde !== fecha_hasta
    ) {
      this.setState({
        alert: {
          ...this.state.alert,
          open: true,
          type: 'error',
          message: 'La fecha desde no puede ser mayor a la fecha hasta',
        },
      });
    } else if (
      resta_fechas.years() < 1 &&
      resta_fechas.months() < 1 &&
      resta_fechas.days() < 1 &&
      resta_fechas.hours() < 1 &&
      resta_fechas.minutes() >= 0 &&
      resta_fechas.seconds() >= 0
    ) {
      this.setState({
        alert: {
          ...this.state.alert,
          open: true,
          type: 'error',
          message: 'Debe seleccionar al menos una hora de diferencia',
        },
      });
    } else {
      this.setFilter(2);

      this.setState({
        forms: {
          ...this.state.forms,
          seleccionar_periodo: false,
        },
        modals: {
          ...this.state.modals,
          seleccionar_periodo: false,
        },
      });
    }
  };

  handleChangeDate = (momentobj, name, aditional) => {
    this.setState({
      [aditional]: {
        ...this.state[aditional],
        [name]: {
          ...this.state[aditional][name],
          momentobj: momentobj,
        },
      },
    });
  };

  render() {
    let { classes } = this.props;
    let title, subtitle;

    const idCountry = this.props.tienda.Country_idCountry || 'ARG';

    switch (this.state.filter.filtro_ventas) {
      case -1:
        title = `Ventas del ${year_actual}`;
        subtitle =
          'En el gráfico se muestran todas las ventas finalizadas del año divididas por meses.';
        break;

      case 0:
        title = `Ventas de ${Validate.trim_lowercase(meses[mes_actual])}`;
        subtitle = `En el gráfico se muestran todas las ventas finalizadas del mes de ${Validate.trim_lowercase(
          meses[mes_actual]
        )} dividida por días.`;
        break;

      case 1:
        title = `Ventas de hoy`;
        subtitle = `En el gráfico se muestran todas las ventas finalizadas del día de hoy dividida por horas.`;
        break;

      case 2:
        title = 'Ventas';
        subtitle = `En el gráfico se muestran todas las ventas finalizadas desde el ${custom_fecha_desde} a las ${custom_hora_desde} hasta el ${custom_fecha_hasta} a las ${custom_hora_hasta}.`;
        break;

      default:
        break;
    }

    subtitle += ' No se tienen en cuenta los costos de envío.';

    return (
      <WrapperLayout
        loading={this.state.loading.ventas || this.state.loading.tienda}
        error={this.state.loading.error}
      >
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <ModalPeriodo
          handleSubmit={this.handleSubmitSeleccionarPeriodo}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.seleccionar_periodo}
          form_state={this.state.forms.seleccionar_periodo}
          handleChangeDate={this.handleChangeDate}
          periodo_desde={this.state.seleccionar_periodo.periodo_desde}
          periodo_hasta={this.state.seleccionar_periodo.periodo_hasta}
        />
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <ModuleTitleUI
              title="Métricas"
              button={
                <DropdownUI
                  label="Filtrar"
                  isLoading={this.state.loading.metricas}
                >
                  <MenuItemUI
                    type="callf"
                    onClickFunction={() => this.setFilter(-1)}
                  >
                    Ventas del {year_actual}
                  </MenuItemUI>
                  <MenuItemUI
                    type="callf"
                    onClickFunction={() => this.setFilter(0)}
                  >
                    Ventas de {meses[mes_actual]}
                  </MenuItemUI>
                  <MenuItemUI
                    type="callf"
                    onClickFunction={() => this.setFilter(1)}
                  >
                    Ventas de hoy
                  </MenuItemUI>
                  <MenuItemUI
                    type="callf"
                    onClickFunction={() =>
                      this.modalHandler(true, 'seleccionar_periodo')
                    }
                  >
                    Otro período
                  </MenuItemUI>
                </DropdownUI>
              }
            />
            <Grid
              container
              spacing={1.5}
              classes={{ container: classes.stat_container }}
            >
              <Grid item md={3} xs={6}>
                <CardModuleUI disablePadding>
                  <div className={classes.card_content_title}>
                    <Typography className={classes.stat_title}>
                      TOTAL EN VENTAS
                    </Typography>
                    <Typography className={classes.stat_subtitle}>
                      ${Validate.number_format(total_en_ventas, idCountry)}
                    </Typography>
                  </div>
                </CardModuleUI>
              </Grid>
              <Grid item md={3} xs={6}>
                <CardModuleUI disablePadding>
                  <div className={classes.card_content_title}>
                    <Typography className={classes.stat_title}>
                      CANTIDAD DE VENTAS
                    </Typography>
                    <Typography className={classes.stat_subtitle}>
                      {cant_de_ventas}
                    </Typography>
                  </div>
                </CardModuleUI>
              </Grid>
            </Grid>
            <CardModuleUI>
              <ModuleCardTitleUI title={title} subtitles={[subtitle]} />
              <canvas ref={this.chartRef} style={{ maxWidth: '100%' }} />
            </CardModuleUI>
            <MasVendidosList />
          </Grid>
        </Grid>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card_content_title: {
    padding: '10px !important',
  },
  stat_container: {
    marginTop: '20px',
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      marginTop: '40px',
    },
  },
  stat_title: {
    padding: 0,
    margin: 0,
    color: '#9e9e9e',
    fontSize: '11px',
    textDecoration: 'none',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.8rem',
    },
  },
  stat_subtitle: {
    padding: 0,
    margin: 0,
    color: '#263238',
    fontSize: '18px',
    textDecoration: 'none',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
    },
  },
});

const mapStateToProps = (state) => {
  return {
    ventas: state.ventas,
    tienda: state.tienda,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ventaObtenerVentas: (data, callback) =>
      dispatch(ventaObtenerVentas(data, callback)),
  };
};

export const Metricas = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Metricas_)
);
