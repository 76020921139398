import React from 'react';
import { Drawer, Hidden, Icon, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ButtonUI } from '../../common';

const DrawerWrapper = ({
  classes,
  children,
  openCloseDrawer,
  handleUpdatePreview,
}) => {
  return (
    <div>
      <div className={classes.toolbar_sidenav}>
        <div className={classes.toolbar_title}>
          <IconButton
            color="inherit"
            aria-label="Close drawer"
            onClick={openCloseDrawer}
          >
            <Icon>close</Icon>
          </IconButton>
        </div>
        <ButtonUI
          type="callf"
          size="small"
          color="primary"
          fullWidth={false}
          variant="outlined"
          label="Ver cambios"
          aria-label="ReloadPreview"
          onClickFunc={async (e) => {
            await handleUpdatePreview(e);
            openCloseDrawer(e);
          }}
          classes={{ root: classes.buttonRefresh }}
        />
      </div>
      <div>{children}</div>
    </div>
  );
};

const DrawerLayout_ = ({
  classes,
  children,
  back_page,
  active_page,
  drawer_title,
  drawer_status,
  openCloseDrawer,
  handleUpdatePreview,
}) => {
  let drawer = (
    <DrawerWrapper
      classes={classes}
      back_page={back_page}
      active_page={active_page}
      drawer_title={drawer_title}
      openCloseDrawer={openCloseDrawer}
      handleUpdatePreview={handleUpdatePreview}
    >
      {children}
    </DrawerWrapper>
  );

  return (
    <nav className={classes.drawer}>
      <Hidden mdUp implementation="css">
        <Drawer
          anchor="left"
          variant="temporary"
          open={drawer_status}
          onClose={openCloseDrawer}
          classes={{ paper: classes.drawerPaper }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          classes={{ paper: classes.drawerPaper }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

const styles = (theme) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: '300px',
      flexShrink: 0,
    },
    zIndex: 1,
  },
  toolbar: theme.mixins.toolbar,
  toolbar_sidenav: {
    ...theme.mixins.toolbar,
    color: 'rgba(0, 0, 0, 0.87)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '0px 10px 0px 5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolbar_title: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  drawerPaper: {
    width: '300px',
    overflowX: 'hidden',
  },
  buttonRefresh: {
    padding: '4px 24px',
  },
});

export const DrawerLayout = withStyles(styles, { withTheme: true })(
  DrawerLayout_
);
