import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Regex, RegexExtra, Validate } from '../../other';
import {
  categoriasObtener,
  categoriasAgregar,
  categoriasEditar,
  categoriasBorrar,
  categoriasOrdenar,
  categoriasConfigurarGoogleCategoryId,
} from '../../store/actions';
import { Grid } from '@mui/material';
import { AlertUI, ModuleTitleUI, ButtonUI } from '../common';
import {
  CategoriasList,
  ModalAgregar,
  ModalEditar,
  ModalBorrar,
  ModalGoogleID,
} from './subcomponents';
import { createAmplitudeEventWithDevice, amplitude_events } from '../amplitude';
import * as braze from '@braze/web-sdk';
import { WrapperLayout } from '../../layouts/WrapperLayout';

class Categorias_ extends Component {
  state = {
    agregar: {
      c_nombre: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nombre',
        id: 'agregar_c_nombre',
        name: 'c_nombre',
        change_param: 'agregar',
        regex: Regex.CATEGORIA_NOMBRE,
        value: '',
        default_value: '',
        placeholder: 'Pantalones',
        messages: {
          error: 'Ingrese un nombre de categoría válida',
          error_extra: RegexExtra.CATEGORIA_NOMBRE,
        },
      },
      c_padre: {
        validate: false,
        value: null,
        c_nombre_padre: '',
      },
    },
    editar: {
      c_nombre: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nombre',
        id: 'editar_c_nombre',
        name: 'c_nombre',
        change_param: 'editar',
        regex: Regex.CATEGORIA_NOMBRE,
        value: '',
        default_value: '',
        placeholder: 'Pantalones',
        messages: {
          error: 'Ingrese un nombre de categoría válida',
          error_extra: RegexExtra.CATEGORIA_NOMBRE,
        },
      },
      idCategorias: {
        validate: false,
        value: null,
      },
    },
    borrar: {
      c_nombre: {
        validate: false,
        value: '',
      },
      idCategorias: {
        validate: false,
        value: null,
      },
    },
    googleid: {
      idCategorias: {
        validate: false,
        value: null,
      },
      c_google_category_id: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'ID de categoría de Google',
        id: 'googleid_c_google_category_id',
        name: 'c_google_category_id',
        change_param: 'googleid',
        regex: Regex.NUMERO_ENTERO,
        value: '',
        default_value: '',
        placeholder: 'Ej: 166',
        messages: {
          error:
            'Ingrese un ID de categoría de Google válido. Coloca 0 para quitar la categoría de Google',
          error_extra: RegexExtra.NUMERO_ENTERO,
        },
      },
    },
    loading: {
      categorias: true,
      error: false,
    },
    forms: {
      agregar: false,
      editar: false,
      borrar: false,
      googleid: false,
    },
    modals: {
      agregar: false,
      editar: false,
      borrar: false,
      googleid: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  componentDidMount() {
    this.props.categoriasObtener((err, resp) => {
      this.setState({
        loading: {
          ...this.state.loading,
          categorias: false,
          error: err ? true : false,
        },
      });
    });
  }

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      {
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: input,
            },
          });
        });
      }
    );
  };

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  modalHandler = (open, id, aditional) => {
    switch (id) {
      case 'agregar':
        let state = open
          ? {
              modals: {
                ...this.state.modals,
                [id]: open,
              },
              agregar: {
                ...this.state.agregar,
                c_padre: {
                  validate: false,
                  value: aditional.c_padre,
                  c_nombre_padre: aditional.c_nombre_padre,
                },
              },
            }
          : {
              modals: {
                ...this.state.modals,
                [id]: open,
              },
            };

        this.setState(state, () => {
          if (!open) {
            //limpio el formulario del modal agregar
            let form_obj = Validate.limpiar_formulario(this.state.agregar);
            this.setState({
              agregar: form_obj,
            });
          }
        });
        break;
      case 'editar':
        if (open) {
          let categoria = aditional;
          Validate.cargar_formulario_sin_nulos(
            categoria,
            this.state.editar,
            (editar_obj) => {
              this.setState({
                editar: editar_obj,
                modals: {
                  ...this.state.modals,
                  [id]: open,
                },
              });
            }
          );
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              [id]: open,
            },
          });
        }
        break;
      case 'borrar':
        if (open) {
          let categoria = aditional;
          Validate.cargar_formulario_sin_nulos(
            categoria,
            this.state.borrar,
            (borrar_obj) => {
              this.setState({
                borrar: borrar_obj,
                modals: {
                  ...this.state.modals,
                  [id]: open,
                },
              });
            }
          );
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              [id]: open,
            },
          });
        }
        break;
      case 'googleid':
        if (open) {
          let categoria = aditional;

          categoria.c_google_category_id = categoria.c_google_category_id
            ? categoria.c_google_category_id
            : '';

          Validate.cargar_formulario_sin_nulos(
            categoria,
            this.state.googleid,
            (googleid_obj) => {
              this.setState({
                googleid: googleid_obj,
                modals: {
                  ...this.state.modals,
                  [id]: open,
                },
              });
            }
          );
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              [id]: open,
            },
          });
        }
        break;
      default:
        break;
    }
  };

  handleSubmitAgregar = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        agregar: true,
      },
    });

    let form = this.state.agregar;

    if (Validate.validar_formulario(form)) {
      this.props.categoriasAgregar(
        {
          c_nombre: form.c_nombre.value,
          c_padre: form.c_padre.value,
        },
        (err, resp) => {
          if (err) {
            this.setState(
              {
                forms: {
                  ...this.state.forms,
                  agregar: false,
                },
                alert: {
                  ...this.state.alert,
                  open: true,
                  message: resp.message,
                  type: 'error',
                },
              },
              () => {
                createAmplitudeEventWithDevice(
                  amplitude_events.agregar_categoria_failed.event_name,
                  amplitude_events.agregar_categoria_failed.event_params
                );
              }
            );
          } else {
            this.setState(
              {
                forms: {
                  ...this.state.forms,
                  agregar: false,
                },
                alert: {
                  ...this.state.alert,
                  open: true,
                  message: resp.message,
                  type: 'success',
                },
                modals: {
                  ...this.state.modals,
                  agregar: false,
                },
              },
              () => {
                createAmplitudeEventWithDevice(
                  amplitude_events.agregar_categoria_successful.event_name,
                  amplitude_events.agregar_categoria_successful.event_params
                );

                if (resp.data.length === 1) {
                  braze.logCustomEvent('empretienda_primera_categoria_creada');
                }
              }
            );
          }
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          forms: {
            ...this.state.forms,
            agregar: false,
          },
          agregar: new_form,
        });
      });
    }
  };

  handleSubmitEditar = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        editar: true,
      },
    });

    let form = this.state.editar;

    if (Validate.validar_formulario(form)) {
      this.props.categoriasEditar(
        {
          c_nombre: form.c_nombre.value,
          idCategorias: form.idCategorias.value,
        },
        (err, resp) => {
          if (err) {
            this.setState({
              forms: {
                ...this.state.forms,
                editar: false,
              },
              alert: {
                ...this.state.alert,
                open: true,
                message: resp.message,
                type: 'error',
              },
            });
          } else {
            this.setState({
              forms: {
                ...this.state.forms,
                editar: false,
              },
              alert: {
                ...this.state.alert,
                open: true,
                message: resp.message,
                type: 'success',
              },
              modals: {
                ...this.state.modals,
                editar: false,
              },
            });
          }
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          forms: {
            ...this.state.forms,
            editar: false,
          },
          editar: new_form,
        });
      });
    }
  };

  handleSubmitBorrar = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        borrar: true,
      },
    });

    let form = this.state.borrar;

    this.props.categoriasBorrar(
      {
        idCategorias: form.idCategorias.value,
      },
      (err, resp) => {
        if (err) {
          this.setState({
            forms: {
              ...this.state.forms,
              borrar: false,
            },
            alert: {
              ...this.state.alert,
              open: true,
              message: resp.message,
              type: 'error',
            },
          });
        } else {
          this.setState({
            forms: {
              ...this.state.forms,
              borrar: false,
            },
            alert: {
              ...this.state.alert,
              open: true,
              message: resp.message,
              type: 'success',
            },
            modals: {
              ...this.state.modals,
              borrar: false,
            },
          });
        }
      }
    );
  };

  handleSubmitEditarGoogleCategoryId = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        googleid: true,
      },
    });

    let form = this.state.googleid;

    if (Validate.validar_formulario(form)) {
      this.props.categoriasConfigurarGoogleCategoryId(
        {
          c_google_category_id: form.c_google_category_id.value,
          idCategorias: form.idCategorias.value,
        },
        (err, resp) => {
          if (err) {
            this.setState({
              forms: {
                ...this.state.forms,
                googleid: false,
              },
              alert: {
                ...this.state.alert,
                open: true,
                message: resp.message,
                type: 'error',
              },
            });
          } else {
            this.setState({
              forms: {
                ...this.state.forms,
                googleid: false,
              },
              alert: {
                ...this.state.alert,
                open: true,
                message: resp.message,
                type: 'success',
              },
              modals: {
                ...this.state.modals,
                googleid: false,
              },
            });
          }
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          forms: {
            ...this.state.forms,
            googleid: false,
          },
          googleid: new_form,
        });
      });
    }
  };

  handleOrdenar = (new_categorias, nivel, parent) => {
    this.props.categoriasOrdenar(
      {
        new_categorias: new_categorias,
        nivel: nivel,
        parent: parent,
      },
      (err) => {
        if (err) {
          this.setState({
            loading: {
              error: true,
            },
          });
        }
      }
    );
  };

  render() {
    return (
      <WrapperLayout
        loading={this.state.loading.categorias}
        error={this.state.loading.error}
      >
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <ModalAgregar
          agregar={this.state.agregar}
          handleSubmit={this.handleSubmitAgregar}
          handleChange={this.handleChange}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.agregar}
          form_state={this.state.forms.agregar}
        />
        <ModalEditar
          editar={this.state.editar}
          handleSubmit={this.handleSubmitEditar}
          handleChange={this.handleChange}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.editar}
          form_state={this.state.forms.editar}
        />
        <ModalBorrar
          borrar={this.state.borrar}
          handleSubmit={this.handleSubmitBorrar}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.borrar}
          form_state={this.state.forms.borrar}
        />
        <ModalGoogleID
          googleid={this.state.googleid}
          handleSubmit={this.handleSubmitEditarGoogleCategoryId}
          handleChange={this.handleChange}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.googleid}
          form_state={this.state.forms.googleid}
        />
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <ModuleTitleUI
              title="Categorías"
              subtitles={[
                'En esta sección podrás agregar las categorías y subcategorías para luego subir los productos en tu tienda online.',
                'Recomendamos que los nombres de las categorías sean representativos y concisos para que tus clientes encuentren más rápido lo que necesitan y, además, ayudar al posicionamiento de la tienda en los buscadores.',
              ]}
              button={
                <ButtonUI
                  type="callf"
                  label="Agregar"
                  fullWidth={false}
                  onClickFunc={() =>
                    this.modalHandler(true, 'agregar', {
                      c_padre: null,
                      c_nombre_padre: '',
                    })
                  }
                />
              }
            />
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <CategoriasList
                  modalHandler={this.modalHandler}
                  handleOrdenar={this.handleOrdenar}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </WrapperLayout>
    );
  }
}

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
  return {
    categoriasObtener: (callback) => dispatch(categoriasObtener(callback)),
    categoriasAgregar: (data, callback) =>
      dispatch(categoriasAgregar(data, callback)),
    categoriasEditar: (data, callback) =>
      dispatch(categoriasEditar(data, callback)),
    categoriasBorrar: (data, callback) =>
      dispatch(categoriasBorrar(data, callback)),
    categoriasOrdenar: (data, callback) =>
      dispatch(categoriasOrdenar(data, callback)),
    categoriasConfigurarGoogleCategoryId: (data, callback) =>
      dispatch(categoriasConfigurarGoogleCategoryId(data, callback)),
  };
};

export const Categorias = connect(
  mapStateToProps,
  mapDispatchToProps
)(Categorias_);
