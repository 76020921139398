import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToggleUI } from '../../../../components/common';
import { ventaObtenerVentas } from '../../../../store/actions';
import { VentaDetails } from './VentaDetails';

const OrderPage_ = (props) => {
  const history = useHistory();
  const VENTA_ID_THAT_GENERATE_ERROR = -1;
  const [saleId, setSaleId] = useState(0);
  const [url, setUrl] = useState('');
  const [location, setLocation] = useState('');
  const [lastSaleId, setLastSaleId] = useState(0);

  useEffect(() => {
    const handleObtenerVentasAsync = async (err, resp) => {
      if (resp.data && resp.data.length && resp.data.length > 0)
        setLastSaleId(Number(resp.data[0].v_numero));
      // si hay errores, paso un valor aguas abajo para que se genere un error en Venta
      // que ya tiene el manejo de errores en WrapperLayout
      if (err) setSaleId(Number(VENTA_ID_THAT_GENERATE_ERROR));
    };

    if (props.match && props.match.params && props.match.params.id) {
      setSaleId(Number(props.match.params.id));
      setUrl(props.match.url);
      setLocation(props.location.search);
    }

    props.ventaObtenerVentas(
      {
        page: 0,
      },
      handleObtenerVentasAsync
    );
  }, []);

  if (!saleId) {
    return '';
  }

  const redirectToPreviousSale = () => {
    if (saleId > 1) {
      const previousSaleId = Number(saleId) - 1;
      history.push(`/listado-de-ventas/orden/${previousSaleId}`);
      setSaleId(previousSaleId);
    }
  };

  const redirectToNextSale = () => {
    if (saleId < lastSaleId) {
      const nextSaleId = Number(saleId) + 1;
      history.push(`/listado-de-ventas/orden/${nextSaleId}`);
      setSaleId(nextSaleId);
    }
  };

  if (lastSaleId > 0) {
    const venta_html = (
      <VentaDetails
        id={saleId}
        idCountry={props.idCountry}
        router_url={url}
        location={location}
        lastSaleId={lastSaleId}
        redirectToPreviousSale={redirectToPreviousSale}
        redirectToNextSale={redirectToNextSale}
      />
    );

    return (
      <>
        <ToggleUI show={saleId % 2 === 0}>{venta_html}</ToggleUI>
        <ToggleUI show={saleId % 2 !== 0}>{venta_html}</ToggleUI>
      </>
    );
  }
  return '';
};

const mapStateToProps = (state) => ({
  idCountry: state.tienda.Country_idCountry,
});

const mapDispatchToProps = (dispatch) => {
  return {
    ventaObtenerVentas: (data, callback) =>
      dispatch(ventaObtenerVentas(data, callback)),
  };
};

export const OrderPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderPage_);
