import React from 'react';
import { Card, CardContent, Tab, Tabs } from '@mui/material';
import {
  ShopSettings,
  TemplateSettings,
  BlockSettings,
  BlockEdit,
  ShopProductsSettings,
  ShopCheckoutSettings,
} from './';
import { withStyles } from '@mui/styles';
import {
  createAmplitudeEventWithDevice,
  design_amplitude_events,
} from '../../amplitude';

const DrawerContent_ = (props) => {
  let {
    active_page,
    tienda,
    settings,
    agregar,
    selects,
    bloques,
    productos,
    handleChange,
    handleChangeBlock,
    handleBorrarBlock,
    handleChangePage,
    handleDisableEnableBlock,
    handleChangeSelect,
    handleChangeSelectBlock,
    handleAgregarBlock,
    handleOrdenarBlocks,
    handleChangeTemplate,
    handleChangeFileBlock,
    handleChangeGridDesign,
    handleChangeFileSettings,
    handleChangeFeedProducts,
    handleChangeProductsBlock,
    handlerShowAlertUI,
    classes,
  } = props;

  const handleDispatchAmplitudeEvents = (e, value) => {
    switch (value) {
      // personalizacion
      case -1:
        createAmplitudeEventWithDevice(
          design_amplitude_events.diseño_selecciona_personalizacion.event_name
        );
        break;
      // secciones
      case -2:
        createAmplitudeEventWithDevice(
          design_amplitude_events.diseño_selecciona_secciones.event_name
        );
        break;
      // plantillas
      case -3:
        createAmplitudeEventWithDevice(
          design_amplitude_events.diseño_selecciona_plantillas.event_name
        );
        break;
      //productos -> productos conf
      case -5:
        createAmplitudeEventWithDevice(
          design_amplitude_events.diseño_selecciona_productos.event_name
        );
        break;
      //checkout
      case -6:
        createAmplitudeEventWithDevice(
          design_amplitude_events.diseño_selecciona_checkout.event_name
        );
        break;
    }

    handleChangePage(e, value);
  };

  let html;

  switch (active_page) {
    // personalizacion
    case -1:
      html = (
        <ShopSettings
          tienda={tienda}
          settings={settings}
          selects={selects}
          handleChange={handleChange}
          handlerShowAlertUI={handlerShowAlertUI}
          handleChangeSelect={handleChangeSelect}
          handleChangeFileSettings={handleChangeFileSettings}
        />
      );
      break;

    // secciones
    case -2:
      html = (
        <BlockSettings
          bloques={bloques}
          agregar={agregar}
          selects={selects}
          handleChangeSelect={handleChangeSelect}
          handleOrdenarBlocks={handleOrdenarBlocks}
          handleChangePage={handleChangePage}
          handleBorrarBlock={handleBorrarBlock}
          handleAgregarBlock={handleAgregarBlock}
          handleDisableEnableBlock={handleDisableEnableBlock}
        />
      );
      break;

    // plantillas
    case -3:
      html = (
        <TemplateSettings
          template_id={settings.template_id.value}
          templates={selects.templates}
          handleChangeTemplate={handleChangeTemplate}
        />
      );
      break;

    //productos -> productos conf
    case -5:
      html = (
        <ShopProductsSettings
          tienda={tienda}
          selects={selects}
          settings={settings}
          productos={productos}
          handleChange={handleChange}
          handlerShowAlertUI={handlerShowAlertUI}
          handleChangeSelect={handleChangeSelect}
          handleChangeFeedProducts={handleChangeFeedProducts}
        />
      );
      break;

    //checkout
    case -6:
      html = (
        <ShopCheckoutSettings
          settings={settings}
          selects={selects}
          handleChangeSelect={handleChangeSelect}
        />
      );
      break;

    //inicio -> bloques
    default:
      html = (
        <BlockEdit
          template_id={settings.template_id.value}
          active_page={active_page}
          bloques={bloques}
          selects={selects}
          handleChangeBlock={handleChangeBlock}
          handleChangeFileBlock={handleChangeFileBlock}
          handleChangeSelectBlock={handleChangeSelectBlock}
          handleChangePage={handleChangePage}
          handleChangeGridDesign={handleChangeGridDesign}
          handleChangeProductsBlock={handleChangeProductsBlock}
          handlerShowAlertUI={handlerShowAlertUI}
        />
      );
      break;
  }

  return (
    <>
      <Card className={classes.card}>
        <CardContent classes={{ root: classes.card_content_tab }}>
          <Tabs
            value={active_page >= 0 ? -2 : active_page}
            scrollButtons
            textColor="primary"
            variant="scrollable"
            indicatorColor="primary"
            classes={{
              scrollButtons: classes.scrollButtons,
            }}
            onChange={handleDispatchAmplitudeEvents}
            allowScrollButtonsMobile
          >
            {selects.macro_pages.map((el, key) => (
              <Tab
                key={key}
                label={el.label}
                value={el.value}
                classes={{
                  root: classes.tabItem,
                }}
              />
            ))}
          </Tabs>
        </CardContent>
      </Card>
      <div className={classes.drawer_content}>{html}</div>
    </>
  );
};

const styles = () => ({
  scrollButtons: {
    width: 'auto',
    backgroundColor: '#F2F2F2',
  },
  tabItem: {
    fontSize: '0.8125rem',
    padding: '21.25px 24px',
    minWidth: 'auto',
    backgroundColor: '#FAFAFA',
    textTransform: 'none',
  },
  card: {
    margin: '12px 12px 0',
  },
  card_content_tab: {
    padding: '0 !important',
  },
  drawer_content: {
    boxSizing: 'border-box',
    padding: '20px 12px',
  },
});

export const DrawerContent = withStyles(styles, { withTheme: true })(
  DrawerContent_
);
