import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';

const dominioObtenerDominios = (callback) => {
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/dominio')
      .then((resp) => {
        //guardo en redux
        const dominios = resp.data.data;
        dispatch({ type: Reducers.DOMINIO_GUARDAR, data: dominios });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const dominioObtenerDominioGratuito = (callback) => {
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/dominio/gratuito')
      .then((resp) => {
        //guardo en redux
        const dominio = resp.data.data;
        dispatch({ type: Reducers.DOMINIO_GUARDAR, data: dominio });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const dominioObtenerDominioPreferencial = (callback) => {
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/dominio/preferencial')
      .then((resp) => {
        //guardo en redux
        const dominio = resp.data.data;
        dispatch({ type: Reducers.DOMINIO_GUARDAR, data: dominio });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const dominioSolicitar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .post(Config.BACKEND_ENDPOINT + '/dominio/configurar', data)
      .then((resp) => {
        //guardo en redux
        let d_metadata = resp.data.data;
        let dominio = {
          d_activo: 0,
          d_metadata: d_metadata,
          d_nombre: d_metadata.dominio,
          d_pago: 1,
        };

        dispatch({ type: Reducers.DOMINIO_AGREGAR, data: dominio });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const dominioMigrar = (callback) => {
  return (dispatch, _getState) => {
    axios
      .post(Config.BACKEND_ENDPOINT + '/dominio/migrar')
      .then((resp) => {
        //guardo en redux
        let dominio = resp.data.data;

        dispatch({ type: Reducers.DOMINIO_MIGRAR, data: dominio });
        dispatch({
          type: Reducers.AUTH_ACTUALIZAR_DOMINIO,
          data: dominio,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const dominioConfirmarDNS = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(`${Config.BACKEND_ENDPOINT}/dominio/configurar/${data.id}/dns`)
      .then((resp) => {
        //guardo en redux
        let d_metadata = resp.data.data;
        let dominio = {
          d_activo: 0,
          d_metadata: d_metadata,
          d_nombre: d_metadata.dominio,
          d_pago: 1,
        };

        dispatch({ type: Reducers.DOMINIO_EDITAR, data: dominio });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const dominioConfirmarCNAME = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        `${Config.BACKEND_ENDPOINT}/dominio/configurar/${data.id}/alta-subdominio`
      )
      .then((resp) => {
        //guardo en redux
        let d_metadata = resp.data.data;
        let dominio = {
          d_activo: 0,
          d_metadata: d_metadata,
          d_nombre: d_metadata.dominio,
          d_pago: 1,
        };

        dispatch({ type: Reducers.DOMINIO_EDITAR, data: dominio });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const dominioABMRecord = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(`${Config.BACKEND_ENDPOINT}/dominio/${data.idDominios}/record`, data)
      .then((resp) => {
        //guardo en redux
        let resp_data = resp.data.data;
        dispatch({
          type: Reducers.DOMINIO_EDITAR_REGISTROS,
          data: resp_data,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

export {
  dominioObtenerDominios,
  dominioObtenerDominioGratuito,
  dominioObtenerDominioPreferencial,
  dominioSolicitar,
  dominioConfirmarDNS,
  dominioConfirmarCNAME,
  dominioABMRecord,
  dominioMigrar,
};
