import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  configuracionmayoristaObtener,
  configuracionmayoristaEditar,
  clienteObtenerClientes,
  clienteSolicitudMayoristaAccion,
  tiendaObtener,
  tiendaActualizarVentaMayorista,
} from '../../store/actions';
import { Card, CardHeader, CardContent, Divider } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ToggleUI,
  DropdownUI,
  MenuItemUI,
} from '../common';
import { Regex, RegexExtra, Validate } from '../../other';
import {
  ModalConfiguracionMayorista,
  SolicitudesList,
  ModalSolicitud,
} from './subcomponents';
import { WrapperLayout } from '../../layouts/WrapperLayout';

class VentaMayorista_ extends Component {
  state = {
    //campos para modal agregar
    configuracion_mayorista: {
      cm_limitar_cantidad_minima: {
        validate: false,
        required: true,
        error: false,
        label: 'Limitar cantidad mínima de compra',
        id: 'cm_limitar_cantidad_minima',
        name: 'cm_limitar_cantidad_minima',
        change_param: 'configuracion_mayorista',
        value: 0,
        messages: {
          error: '',
        },
      },
      cm_cantidad_minima: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Cantidad mínima',
        id: 'cm_cantidad_minima',
        name: 'cm_cantidad_minima',
        change_param: 'configuracion_mayorista',
        regex: Regex.NUMERO_ENTERO_POSITIVO,
        value: '',
        default_value: '',
        placeholder: ' ',
        messages: {
          error: 'Por favor, ingrese una cantidad mínima válida',
          help: 'Ingresar la cantidad mínima de productos en general para cada compra mayorista. Al agregar o editar un producto “mayorista” tendrás la posibilidad de limitar la cantidad mínima de compra de ese producto en particular.',
          error_extra: RegexExtra.NUMERO_ENTERO_POSITIVO,
        },
      },
      cm_limitar_precio_minimo: {
        validate: false,
        required: true,
        error: false,
        label: 'Limitar monto mínimo de compra',
        id: 'cm_limitar_precio_minimo',
        name: 'cm_limitar_precio_minimo',
        change_param: 'configuracion_mayorista',
        value: 0,
        messages: {
          error: '',
        },
      },
      cm_precio_minimo: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Monto mínimo',
        id: 'cm_precio_minimo',
        name: 'cm_precio_minimo',
        change_param: 'configuracion_mayorista',
        regex: Regex.PRECIO.ARG,
        value: '',
        default_value: '',
        placeholder: ' ',
        messages: {
          error: 'Por favor, ingrese una monto mínimo válido',
          help: 'Ingresar el monto mínimo para una venta mayorista',
          error_extra: RegexExtra.PRECIO.ARG,
        },
      },
    },

    //campos para modal editar
    solicitud_mayorista: {
      idClientes: null,
      cl_nombre: '',
      cl_mayorista: null,
    },

    //carga de datos
    loading: {
      configuracion_mayorista: true,
      clientes: true,
      tienda: true,
      error: false,
    },

    //modales
    modals: {
      configuracion_mayorista: false,
      solicitud_mayorista: false,
    },

    //forms
    forms: {
      venta_mayorista: false,
      configuracion_mayorista: false,
      solicitud_mayorista: false,
    },

    //selects
    selects: {
      opciones_limitar_cantidad: [
        {
          value: 0,
          label: 'No limitar cantidad mínima',
        },
        {
          value: 1,
          label: 'Limitar cantidad mínima',
        },
      ],
      opciones_limitar_monto: [
        {
          value: 0,
          label: 'No limitar monto mínimo',
        },
        {
          value: 1,
          label: 'Limitar monto mínimo',
        },
      ],
    },

    //alerta
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  //maneja el cambio de todos los inputs
  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      {
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: input,
            },
          });
        });
      }
    );
  };

  componentDidMount() {
    this.props.configuracionmayoristaObtener((err, resp) => {
      if (err) {
        this.setState({
          loading: {
            ...this.state.loading,
            configuracion_mayorista: false,
            error: true,
          },
        });
      } else {
        let cm_props = this.props.configuracion_mayorista;
        let cm_state = {
          ...this.state.configuracion_mayorista,
          cm_cantidad_minima: {
            ...this.state.configuracion_mayorista.cm_cantidad_minima,
            validate: cm_props.cm_limitar_cantidad_minima ? true : false,
          },
          cm_precio_minimo: {
            ...this.state.configuracion_mayorista.cm_precio_minimo,
            validate: cm_props.cm_limitar_precio_minimo ? true : false,
          },
        };

        Validate.cargar_formulario_sin_nulos(
          cm_props,
          cm_state,
          (configuracion_mayorista_obj) => {
            this.setState({
              configuracion_mayorista: configuracion_mayorista_obj,
              loading: {
                ...this.state.loading,
                configuracion_mayorista: false,
                error: this.state.loading.error ? true : false,
              },
            });
          }
        );
      }
    });
    this.props.tiendaObtener((err, resp) => {
      const idCountry =
        this.props.tienda && this.props.tienda.Country_idCountry
          ? this.props.tienda.Country_idCountry
          : 'ARG';

      this.setState({
        configuracion_mayorista: {
          ...this.state.configuracion_mayorista,
          cm_precio_minimo: {
            ...this.state.configuracion_mayorista.cm_precio_minimo,
            regex: Regex.PRECIO[idCountry],
            messages: {
              ...this.state.configuracion_mayorista.cm_precio_minimo.messages,
              error_extra: RegexExtra.PRECIO[idCountry],
            },
          },
        },
        loading: {
          ...this.state.loading,
          tienda: false,
          error: this.state.loading.error || err ? true : false,
        },
      });
    });
    this.props.clienteObtenerClientes(
      {
        cl_mayorista: -1,
        cl_bloqueado: 0,
        cl_registro_completo: 1,
      },
      (err, resp) => {
        this.setState({
          loading: {
            ...this.state.loading,
            clientes: false,
            error: this.state.loading.error || err ? true : false,
          },
        });
      }
    );
  }

  //abre y cierra los modales
  modalHandler = (open, id, aditional) => {
    switch (id) {
      case 'configuracion_mayorista':
        this.setState({
          modals: {
            ...this.state.modals,
            configuracion_mayorista: open,
          },
        });
        break;
      case 'solicitud_mayorista_aceptar':
        if (open) {
          const cliente = aditional;
          this.setState({
            modals: {
              ...this.state.modals,
              solicitud_mayorista: open,
            },
            solicitud_mayorista: {
              idClientes: cliente.idClientes,
              cl_nombre: cliente.cl_nombre + ' ' + cliente.cl_apellido,
              cl_mayorista: 1,
            },
          });
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              solicitud_mayorista: open,
            },
          });
        }
        break;
      case 'solicitud_mayorista_rechazar':
        if (open) {
          const cliente = aditional;
          this.setState({
            modals: {
              ...this.state.modals,
              solicitud_mayorista: open,
            },
            solicitud_mayorista: {
              idClientes: cliente.idClientes,
              cl_nombre: cliente.cl_nombre + ' ' + cliente.cl_apellido,
              cl_mayorista: 0,
            },
          });
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              solicitud_mayorista: open,
            },
          });
        }
        break;
      default:
        break;
    }
  };

  //manipula los selects
  handleChangeSelect = (e, aditional) => {
    let value = Validate.trim_int(e.target.value);
    let name = e.target.name;
    let cm_state = this.state.configuracion_mayorista;

    if (name === 'cm_limitar_cantidad_minima') {
      cm_state = {
        ...cm_state,
        cm_cantidad_minima: {
          ...cm_state.cm_cantidad_minima,
          validate: parseInt(value) ? true : false,
        },
      };
    }
    if (name === 'cm_limitar_precio_minimo') {
      cm_state = {
        ...cm_state,
        cm_precio_minimo: {
          ...cm_state.cm_precio_minimo,
          validate: parseInt(value) ? true : false,
        },
      };
    }

    this.setState(
      {
        [aditional]: {
          ...cm_state,
          [name]: {
            ...cm_state[name],
            value: value,
          },
        },
      },
      () => {
        Validate.validar_formulario_actualizar_obj(
          this.state.configuracion_mayorista,
          (new_form) => {
            this.setState({
              configuracion_mayorista: new_form,
            });
          }
        );
      }
    );
  };

  //submits
  handleActivarDesactivarVentaMayoristaSubmit = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        venta_mayorista: true,
      },
    });

    this.props.tiendaActualizarVentaMayorista(
      {
        t_mayorista: this.props.tienda.t_mayorista ? 0 : 1,
      },
      (err, resp) => {
        this.setState({
          forms: {
            ...this.state.forms,
            venta_mayorista: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          },
        });
      }
    );
  };

  handleAceptarCancelarSolicitudMayoristaSubmit = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        solicitud_mayorista: true,
      },
    });

    this.props.clienteSolicitudMayoristaAccion(
      {
        idClientes: this.state.solicitud_mayorista.idClientes,
        cl_mayorista: this.state.solicitud_mayorista.cl_mayorista,
      },
      (err, resp) => {
        this.setState({
          forms: {
            ...this.state.forms,
            solicitud_mayorista: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          },
          modals: {
            ...this.state.modals,
            solicitud_mayorista: err ? true : false,
          },
        });
      }
    );
  };

  handleConfiguracionMayoristaSubmit = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        configuracion_mayorista: true,
      },
    });

    let form = this.state.configuracion_mayorista;

    if (Validate.validar_formulario(form)) {
      this.props.configuracionmayoristaEditar(
        {
          cm_limitar_cantidad_minima: form.cm_limitar_cantidad_minima.value,
          cm_cantidad_minima: form.cm_cantidad_minima.value,
          cm_limitar_precio_minimo: form.cm_limitar_precio_minimo.value,
          cm_precio_minimo: form.cm_precio_minimo.value,
        },
        (err, resp) => {
          this.setState({
            forms: {
              ...this.state.forms,
              configuracion_mayorista: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            },
            modals: {
              ...this.state.modals,
              configuracion_mayorista: err ? true : false,
            },
          });
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          configuracion_mayorista: new_form,
          forms: {
            ...this.state.forms,
            configuracion_mayorista: false,
          },
        });
      });
    }
  };

  //cierra las alertas
  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  render() {
    const { classes, tienda } = this.props;

    const activar_desactivar_btn = tienda.t_mayorista ? (
      <DropdownUI
        label="Configuración"
        isLoading={this.state.forms.venta_mayorista}
      >
        <MenuItemUI
          type="callf"
          onClickFunction={() =>
            this.modalHandler(true, 'configuracion_mayorista')
          }
        >
          Configurar
        </MenuItemUI>
        <MenuItemUI
          type="callf"
          onClickFunction={this.handleActivarDesactivarVentaMayoristaSubmit}
        >
          Desactivar
        </MenuItemUI>
      </DropdownUI>
    ) : (
      <ButtonUI
        type="callf"
        label="Activar"
        minHeight={true}
        fullWidth={false}
        onClickFunc={this.handleActivarDesactivarVentaMayoristaSubmit}
        isLoading={this.state.forms.venta_mayorista}
      />
    );

    const subtitles = tienda.t_mayorista
      ? [
          'La función de venta mayorista se encuentra activa en tu tienda.',
          'Podés configurarla o desactivarla haciendo click en el botón "Configuración".',
        ]
      : [
          'Esta función te permite vender productos por mayor y por menor. Cuando se activa cambia el formulario para agregar un producto. Deberás seleccionar si el producto es sólo mayorista, sólo minorista o ambos.',
          'En el menú de tu tienda aparecerá la pestaña "MAYORISTA" y para poder ingresar a la misma te enviarán una solicitud de acceso que podrás aceptar o rechazar desde esta sección del panel.',
          'Cada vez que tengas una solicitud de mayorista te enviaremos un mail notificándolo.',
          'Podés configurar la venta mayorista una vez que la actives desde el botón "Configuración". Podés limitar la cantidad mínima de compra y el monto mínimo de compra.',
          'Además podrás definir cantidades mínimas de unidades por cada producto en particular al agregarlo.',
        ];

    return (
      <WrapperLayout
        loading={
          this.state.loading.tienda ||
          this.state.loading.clientes ||
          this.state.loading.configuracion_mayorista
        }
        error={this.state.loading.error}
      >
        <ModalConfiguracionMayorista
          configuracion_mayorista={this.state.configuracion_mayorista}
          handleSubmit={this.handleConfiguracionMayoristaSubmit}
          handleChange={this.handleChange}
          handleChangeSelect={this.handleChangeSelect}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.configuracion_mayorista}
          form_state={this.state.forms.configuracion_mayorista}
          selects={this.state.selects}
        />
        <ModalSolicitud
          solicitud_mayorista={this.state.solicitud_mayorista}
          handleSubmit={this.handleAceptarCancelarSolicitudMayoristaSubmit}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.solicitud_mayorista}
          form_state={this.state.forms.solicitud_mayorista}
        />
        <AlertUI
          open={this.state.alert.open}
          type={this.state.alert.type}
          message={this.state.alert.message}
          handleCloseAlert={this.handleCloseAlert}
        />
        <ModuleTitleUI
          title="Venta mayorista"
          subtitles={subtitles}
          button={activar_desactivar_btn}
        />
        <ToggleUI show={tienda.t_mayorista ? true : false}>
          <Card className={classes.card_below}>
            <CardHeader
              title="Solicitudes pendientes"
              className={classes.card_header}
              classes={{ action: classes.card_action_button }}
            />
            <Divider />
            <CardContent className={classes.card_content}>
              <SolicitudesList modalHandler={this.modalHandler} />
            </CardContent>
          </Card>
        </ToggleUI>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_below: {
    ...theme.card.card,
    marginTop: '40px',
  },
  card_header: theme.card.card_header,
  card_content: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  card_action_button: theme.card.card_action_button,
});

const mapStateToProps = (state) => {
  return {
    configuracion_mayorista: state.configuracion_mayorista,
    tienda: state.tienda,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    configuracionmayoristaObtener: (callback) =>
      dispatch(configuracionmayoristaObtener(callback)),
    clienteObtenerClientes: (data, callback) =>
      dispatch(clienteObtenerClientes(data, callback)),
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    tiendaActualizarVentaMayorista: (data, callback) =>
      dispatch(tiendaActualizarVentaMayorista(data, callback)),
    configuracionmayoristaEditar: (data, callback) =>
      dispatch(configuracionmayoristaEditar(data, callback)),
    clienteSolicitudMayoristaAccion: (data, callback) =>
      dispatch(clienteSolicitudMayoristaAccion(data, callback)),
  };
};

export const VentaMayorista = withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(VentaMayorista_)
);
