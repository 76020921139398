import React, { Component } from 'react';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { ToggleUI, AlertUI } from '../../common';
import { ProgressCard } from '../subcomponents/ProgressCard';

import { ResumenImportacion } from './ResumenImportacion';
import { convertListOfBackendErrors } from '../importar/ImportError';
import {
  trackObtenerTrack,
  tiendaActualizarApagarTemporalmente,
  tiendaObtener,
} from '../../../store/actions';

import { Regex, RegexExtra, Validate } from '../../../other';
import {
  on_off_store_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../amplitude';
import { WrapperLayout } from '../../../layouts/WrapperLayout';
const procesamiento_track_etapa = {
  impactando_tienda: 'IMPACTANDO_TIENDA',
  resumen: 'RESUMEN',
  ir_a_administrar_productos: 'IR_A_ADMINISTRAR_PRODUCTOS',
  cargar_mas_productos: 'CARGAR_MAS_PRODUCTOS',
};

const TRACK_INTERVAL_TIME = 15000;
const TRACK_TOTAL_PERCENTAGE = 40;
const TRACK_INITIAL_PERCENTAGE = 60;

export class ImportacionEstadoTrack_ extends Component {
  //Por tamaño quedan fuera de state
  planilla_chunks_errores = [];
  track_metadata = [];
  state = {
    apagar_temporalmente: {
      t_offmode_mensaje: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Mensaje para tus clientes',
        id: 't_offmode_mensaje',
        name: 't_offmode_mensaje',
        change_param: 'apagar_temporalmente',
        regex: Regex.DESCRIPCION_0_200,
        value: '',
        default_value: '',
        placeholder: 'Estaremos cerrados hasta el ....',
        messages: {
          error: 'Por favor, ingrese un mensaje válido',
          error_extra: RegexExtra.DESCRIPCION_0_200,
        },
      },
    },
    loading: {
      track: true,
      tienda: true,
      error: false,
    },
    forms: {
      apagar_temporalmente_encender: false,
    },
    modals: {
      apagar_temporalmente_encender: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    progress_card: {
      title: 'Agregando tus productos',
      subtitle: 'Estamos importando tus productos',
      text: 'Este proceso puede demorar varios minutos, pero puedes salir de esta pantalla en caso de que necesites hacer otras tareas en tu tienda.',
      completion_percentage: 70,
    },
    etapa: '',
    products_edited: 0,
    products_created: 0,
    trackInterval: null,
  };

  componentDidMount() {
    const idTrack = Validate.trim_int(this.props.match.params.idTrack);
    this.cargarTrackDeMigracion(idTrack); //Only de first execution
    this.trackCheckProgress(idTrack); //rest of executions

    this.props.tiendaObtener((err, resp) => {
      if (err) {
        this.setState({
          loading: {
            ...this.state.loading,
            tienda: false,
            error: true,
          },
        });
      } else {
        this.setState({
          loading: {
            ...this.state.loading,
            tienda: false,
            error: false,
          },
        });
      }
    });
  }

  trackCheckProgress = (idTrack) => {
    let trackInterval = setInterval(() => {
      this.cargarTrackDeMigracion(idTrack);
    }, TRACK_INTERVAL_TIME);
    this.setState({
      trackInterval,
    });
  };

  handleUpdateCompletionPercentage = (completion_percentage) => {
    this.setState({
      progress_card: {
        ...this.state.progress_card,
        completion_percentage: Math.ceil(completion_percentage),
      },
    });
  };

  componentWillUnmount() {
    clearInterval(this.state.trackInterval);
  }

  cargarTrackDeMigracion = (idTrack) => {
    this.setState({
      loading: {
        ...this.state.loading,
        ultimo_track_de_migracion: true,
      },
    });

    this.props.trackObtenerTrack({ idTrack }, (err, resp) => {
      let track_corriendo;
      let products_edited = 0;
      let products_created = 0;
      if (!err && resp.data.track_batch && resp.data.track_batch === 1) {
        track_corriendo = true;
        const cantidad_de_chunks = resp.data.track_data.metadata.length;
        const chunks_procesados = resp.data.track_data.metadata.reduce(
          (previous_value, chunk_meta) => {
            const chunk_meta_status = chunk_meta.status;
            return previous_value + (chunk_meta_status > 0 ? 1 : 0);
          },
          0
        );

        this.handleUpdateCompletionPercentage(
          TRACK_INITIAL_PERCENTAGE +
            TRACK_TOTAL_PERCENTAGE * (chunks_procesados / cantidad_de_chunks)
        );
      } else {
        track_corriendo = false;
        clearInterval(this.state.trackInterval); //dejar de preguntar por el estado del proceso

        for (const chunk_metadata of resp.data.track_data.metadata) {
          products_edited += chunk_metadata.edited;
          products_created += chunk_metadata.created;

          const chunk_errors = convertListOfBackendErrors(
            chunk_metadata.errors
          );
          this.planilla_chunks_errores.push(...chunk_errors);
        }
      }

      this.setState({
        loading: {
          ...this.state.loading,
          track: false,
          error: this.state.loading.error || err ? true : false,
        },
        etapa: track_corriendo
          ? procesamiento_track_etapa.impactando_tienda
          : procesamiento_track_etapa.resumen,
        products_edited,
        products_created,
      });
    });
  };

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  modalHandler = (open, id, aditional) => {
    this.setState({
      modals: {
        ...this.state.modals,
        [id]: open,
      },
    });
  };

  handleIrAAdministrarProductos = () => {
    this.setState({
      etapa: procesamiento_track_etapa.ir_a_administrar_productos,
    });
  };

  handleCargarMasProductos = () => {
    this.setState({
      etapa: procesamiento_track_etapa.cargar_mas_productos,
    });
  };
  handleSubmitEncender = (e) => {
    e.preventDefault();

    this.setState(
      {
        forms: {
          ...this.state.forms,
          apagar_temporalmente_encender: true,
        },
      },
      () => {
        createAmplitudeEventWithDevice(
          on_off_store_amplitude_events
            .mi_tienda_selecciona_encender_tienda_importacion.event_name,
          on_off_store_amplitude_events
            .mi_tienda_selecciona_encender_tienda_importacion.event_params
        );
      }
    );

    let form = this.state.apagar_temporalmente;

    if (Validate.validar_formulario(form)) {
      this.props.tiendaActualizarApagarTemporalmente(
        {
          t_offmode_mensaje: this.props.tienda.t_offmode_mensaje,
          t_offmode: 0,
        },
        (err, resp) => {
          if (err) {
            this.setState({
              forms: {
                ...this.state.forms,
                apagar_temporalmente_encender: false,
              },
              alert: {
                ...this.state.alert,
                open: true,
                message: resp.message,
                type: 'error',
              },
            });
          } else {
            this.setState({
              forms: {
                ...this.state.forms,
                apagar_temporalmente_encender: false,
              },
              alert: {
                ...this.state.alert,
                open: true,
                message: resp.message,
                type: 'success',
              },
              modals: {
                ...this.state.modals,
                apagar_temporalmente_encender: false,
              },
            });
          }
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          forms: {
            ...this.state.forms,
            apagar_temporalmente_encender: false,
          },
          apagar_temporalmente: new_form,
        });
      });
    }
  };
  render() {
    if (
      this.state.etapa !== '' &&
      !this.state.loading.track &&
      !this.state.loading.tienda
    ) {
      return (
        <WrapperLayout
          loading={this.state.loading.importar}
          error={this.state.loading.error}
        >
          <AlertUI
            open={this.state.alert.open}
            message={this.state.alert.message}
            type={this.state.alert.type}
            handleCloseAlert={this.handleCloseAlert}
          />
          <Grid container spacing={1.5}>
            <ToggleUI
              show={
                this.state.etapa === procesamiento_track_etapa.resumen
                  ? true
                  : false
              }
            >
              <Grid item xs={12}>
                <ResumenImportacion
                  handleSubmitEncender={this.handleSubmitEncender}
                  modalHandler={this.modalHandler}
                  modal_state={this.state.modals.apagar_temporalmente_encender}
                  form_state={this.state.forms.apagar_temporalmente_encender}
                  handleIrAAdministrarProductos={
                    this.handleIrAAdministrarProductos
                  }
                  t_offmode={this.props.tienda.t_offmode}
                  handleCargarMasProductos={this.handleCargarMasProductos}
                  products_edited={this.state.products_edited}
                  products_created={this.state.products_created}
                  planilla_chunks_errores={this.planilla_chunks_errores}
                />
              </Grid>
            </ToggleUI>
            <ToggleUI
              show={
                this.state.etapa === procesamiento_track_etapa.impactando_tienda
                  ? true
                  : false
              }
            >
              <Grid item xs={12}>
                <ProgressCard
                  title={this.state.progress_card.title}
                  subtitle={this.state.progress_card.subtitle}
                  text={this.state.progress_card.text}
                  completion_percentage={
                    this.state.progress_card.completion_percentage
                  }
                />
              </Grid>
            </ToggleUI>
            <ToggleUI
              show={
                this.state.etapa ===
                procesamiento_track_etapa.ir_a_administrar_productos
                  ? true
                  : false
              }
            >
              <Route
                render={(props) => (
                  <Redirect
                    to={{
                      pathname: '/productos',
                      state: { from: props.location },
                    }}
                  />
                )}
              />
            </ToggleUI>
            <ToggleUI
              show={
                this.state.etapa ===
                procesamiento_track_etapa.cargar_mas_productos
                  ? true
                  : false
              }
            >
              <Route
                render={(props) => (
                  <Redirect
                    to={{
                      pathname: '/productos/importacion/',
                      state: { from: props.location },
                    }}
                  />
                )}
              />
            </ToggleUI>
          </Grid>
        </WrapperLayout>
      );
    }
    return '';
  }
}

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    trackObtenerTrack: (data, callback) =>
      dispatch(trackObtenerTrack(data, callback)),
    tiendaActualizarApagarTemporalmente: (data, callback) =>
      dispatch(tiendaActualizarApagarTemporalmente(data, callback)),
  };
};

export const ImportacionEstadoTrack = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportacionEstadoTrack_);
