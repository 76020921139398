import React, { useState } from 'react';
import {
  Card,
  CardActions,
  CardMedia,
  CardActionArea,
  CardContent,
  Divider,
  Grid,
  Icon,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { ToggleUI } from '../../common';
import { InfoCard } from './InfoCard';
import { IconGreen } from './IconGreen';
import stylesObj from './styles.json';

const CustomCardUIWithDetails_ = ({
  classes,
  image,
  title,
  subtitle,
  info,
  children,
  ivaInfo = '',
  recomendado = false,
}) => {
  const [help, setHelp] = useState(false);

  const setOpenHelp = () => {
    setHelp(!help);
  };

  return (
    <Card className={recomendado ? classes.card_recommended : classes.card}>
      <div style={{ position: 'relative', overflow: 'hidden' }}>
        <div>
          <CardActionArea
            classes={{ focusHighlight: classes.card_action_area }}
            onClick={() => setOpenHelp()}
          >
            <ToggleUI show={recomendado}>
              <div className={classes.box_recommended}>
                <span className={classes.text_recommended}>
                  {IconGreen}{' '}
                  <span className={classes.test_margin_left}>Recomendado</span>
                </span>
              </div>
            </ToggleUI>
            <div className={classes.card_header}>
              <CardMedia
                className={classes.card_media}
                image={image}
                component="img"
              />
            </div>
            <CardContent className={classes.card_content}>
              <div className={classes.infocard_container}>
                <div className={classes.infocard}>
                  <InfoCard title="Comisión" subtitle={ivaInfo} />
                  <InfoCard title="Acreditación" subtitle="inmediata" />
                </div>
                <div className={classes.card_titles_container}>
                  <div className={classes.card_titles}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      className={classes.card_detail_header_title}
                    >
                      {title}
                    </Typography>
                    <Typography
                      gutterBottom
                      component="p"
                      className={classes.card_subheader}
                    >
                      {subtitle}
                    </Typography>
                  </div>
                  <Icon className={classes.card_header_icon}>help_outline</Icon>
                </div>
              </div>
            </CardContent>
          </CardActionArea>
        </div>
        <div
          className={`${classes.card_reveal} ${
            help ? classes.card_reveal_show : ''
          }`}
        >
          <CardContent className={classes.card_info_content}>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              className={classes.card_help_title}
            >
              {title}
              <Icon
                className={classes.card_header_icon}
                onClick={() => setOpenHelp()}
              >
                clear
              </Icon>
            </Typography>
            <Typography variant="body1" className={classes.card_help_text}>
              {info}
            </Typography>
          </CardContent>
        </div>
      </div>

      <Divider />
      <CardActions className={classes.card_actions}>
        <Grid container spacing={0}>
          <Grid item xs={12} px={0.5}>
            {children}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

const cardStyles = (theme) => ({
  ...stylesObj,
  card: {
    ...theme.card.card,
    ...stylesObj.card,
  },
  card_content: {
    ...theme.card.card_content,
    padding: '0px 24px 24px 24px !important',
  },
  card_info_content: {
    ...theme.card.card_content,
    padding: '0px 12px 12px 12px !important',
  },
  card_reveal: {
    ...stylesObj.card_reveal,
    transition: theme.transitions.create('all', { duration: '500ms' }),
  },
  card_actions: theme.card.card_actions_public,
  card_subheader: {
    ...theme.card.card_subheader,
    ...stylesObj.card_subheader,
  },
  card_media: {
    width: 'auto',
    display: 'block',
    objectFit: 'cover',
    position: 'absolute',
    margin: '0 0 0 6px',
    height: '100px',
    top: '-8px',
  },
  card_header_icon: {
    ...stylesObj.card_header_icon,
    marginLeft: '7px',
  },
  card_titles_container: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    right: '-16px',
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  card_titles: {
    ...stylesObj.card_titles,
    alignItems: 'flex-end',
  },
  box_recommended: {
    ...stylesObj.box_recommended,
    top: '36px',
    right: '28px',
  },
  card_recommended: {
    ...theme.card.card,
    ...stylesObj.card_recommended,
  },
  infocard_container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infocard: {
    display: 'flex',
  },
});

export const CustomCardUIWithDetails = withStyles(cardStyles)(
  CustomCardUIWithDetails_
);
