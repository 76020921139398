import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { debounce } from 'lodash';
import {
  Grid,
  IconButton,
  Icon,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { ToggleUI, AutosuggestUI } from '../../common';
import { Validate, Config } from '../../../other';
import moment from 'moment';

const determinar_estado_oferta = (item) => {
  let ahora = moment().local();
  let fin = Validate.utc2local(item.p_oferta_fecha);
  if (item.p_oferta === 2) {
    //fecha de fin
    if (ahora < fin) {
      return (
        '[Finaliza ' + Validate.utc2relative_local(item.p_oferta_fecha) + ']'
      );
    } else {
      return '';
    }
  } else {
    let inicio = Validate.utc2local(item.p_oferta_fecha_inicio);
    if (ahora < inicio) {
      return (
        '[Comienza ' +
        Validate.utc2relative_local(item.p_oferta_fecha_inicio) +
        ']'
      );
    } else if (ahora < fin) {
      return (
        '[Finaliza ' + Validate.utc2relative_local(item.p_oferta_fecha) + ']'
      );
    } else {
      return '';
    }
  }
};

class ShopProductsFeeder_ extends Component {
  state = {
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    feed_input_value: '',
    feed: [],
  };

  //autosuggest
  debounceMethod = null;

  handleOnSuggestionSelected = (selected_suggestion) =>
    this.handleAgregarProductos(selected_suggestion);

  handleSuggestionFetchData = (value) => {
    const inputValue = Validate.trim_lowercase(value);

    if (inputValue.length > 1) {
      axios
        .get(Config.BACKEND_ENDPOINT + '/producto/buscar', {
          params: {
            search: inputValue,
          },
        })
        .then((resp) => {
          const productos_new = resp?.data?.data?.filter((prod_new) => {
            let flag = true;
            this.props.productos.forEach((prod_added) => {
              if (prod_new.idProductos === prod_added.idProductos) {
                flag = false;
              }
            });
            return flag;
          });
          this.setState({ feed: productos_new });
        })
        .catch((err) => {
          this.props.handlerShowAlertUI({
            open: true,
            message: 'Ha ocurrido un error al buscar, intente nuevamente',
            type: 'error',
          });
        });
    }
  };

  handleSuggestionsFetchRequested = ({ value }) => this.debounceMethod(value);

  handleSuggestionsClearRequested = () => this.setState({ feed: [] });

  handleSuggestionsChangeSelected = (e, { newValue }) => {
    this.setState({
      feed_input_value: newValue,
    });
  };
  //end autosuggest.

  handleAgregarProductos = (feed_selected) => {
    let { max_products = -1 } = this.props;

    let existe_producto = this.props.productos.filter(
      (producto) => producto.idProductos === feed_selected.idProductos
    ).length;

    if (max_products === -1 || this.props.productos.length < max_products) {
      if (!existe_producto) {
        this.setState({ feed_input_value: '' });
        this.props.handleChangeProducts([
          {
            ...feed_selected,
            p_orden: this.props.productos.length + 1,
          },
          ...this.props.productos,
        ]);
      } else {
        this.setState({
          feed_input_value: '',
        });

        this.props.handlerShowAlertUI({
          open: true,
          message: 'El producto ya existe en la lista',
          type: 'error',
        });
      }
    } else {
      this.setState({
        feed_input_value: '',
      });

      this.props.handlerShowAlertUI({
        open: true,
        message: 'No se pueden agregar más de ' + max_products + ' productos',
        type: 'error',
      });
    }
  };

  handleOrdenarProductos = (products_feed) =>
    this.props.handleChangeProducts(products_feed);

  handleEliminarProductos = (producto) => {
    let products_feed = this.props.productos.filter(
      (prod) => prod.idProductos !== producto.idProductos
    );
    let counter = products_feed.length + 1;
    products_feed = products_feed.map((products_feed) => {
      counter--;
      return {
        ...products_feed,
        p_orden: counter,
      };
    });
    this.props.handleChangeProducts(products_feed);
  };

  //cierra las alertas
  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  componentDidMount() {
    this.debounceMethod = debounce(this.handleSuggestionFetchData, 500);
  }

  render() {
    let { productos, tienda, classes } = this.props;

    const idCountry = tienda ? tienda.Country_idCountry : 'ARG';

    const onSortEnd = ({ args }) => {
      const { oldIndex, newIndex } = args;
      if (oldIndex !== newIndex) {
        let productos_ord = arrayMove(productos, oldIndex, newIndex);
        let counter = productos_ord.length + 1;
        productos_ord = productos_ord.map((producto) => {
          counter--;
          return {
            ...producto,
            p_orden: counter,
          };
        });
        this.handleOrdenarProductos(productos_ord);
      }
    };

    const SortableItem = sortableElement(({ producto, idx }) => {
      return (
        <ListItem
          ContainerComponent="div"
          key={producto.idProductos}
          classes={{
            root: classes.list_item,
            container: classes.list_item_container,
          }}
        >
          <ListItemIcon classes={{ root: classes.list_item_icon }}>
            <DragHandle />
          </ListItemIcon>
          <ListItemText
            classes={{
              root: classes.list_text,
              primary: classes.list_text_primary,
            }}
            primary={producto.p_nombre}
            secondary={
              <span className={classes.span}>
                <span className={classes.span}>
                  <ToggleUI show={producto.p_oferta === 0}>
                    {producto.p_mostrar_precio === 0
                      ? 'Sin precio'
                      : '$' +
                        Validate.number_format(producto.p_precio, idCountry)}
                  </ToggleUI>
                  <ToggleUI show={producto.p_oferta === 1}>
                    <del>
                      {'$' +
                        Validate.number_format(producto.p_precio, idCountry)}
                    </del>{' '}
                    {'$' +
                      Validate.number_format(
                        producto.p_precio_oferta,
                        idCountry
                      )}
                  </ToggleUI>
                  <ToggleUI show={producto.p_oferta > 1}>
                    <del>
                      {'$' +
                        Validate.number_format(producto.p_precio, idCountry)}
                    </del>{' '}
                    {'$' +
                      Validate.number_format(
                        producto.p_precio_oferta,
                        idCountry
                      )}{' '}
                    {determinar_estado_oferta(producto)}
                  </ToggleUI>
                </span>
                <ToggleUI show={producto.p_desactivado}>
                  <span className={classes.span}>Producto desactivado</span>
                </ToggleUI>
                <ToggleUI
                  show={
                    tienda.t_productos_digitales === 0 &&
                    producto.p_producto_digital > 0
                  }
                >
                  <span className={classes.span}>
                    Producto no visible (Productos digitales desactivados)
                  </span>
                </ToggleUI>
              </span>
            }
          />
          <ListItemSecondaryAction
            classes={{ root: classes.list_item_secondary_action }}
          >
            <IconButton
              classes={{ root: classes.remove_icon_button }}
              aria-label="Remover"
              onClick={(e) => this.handleEliminarProductos(producto)}
              size="large"
            >
              <Icon classes={{ root: classes.remove_icon }}>clear</Icon>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });

    const renderizarProductosOrdenados = (productos) =>
      productos.map((producto, index) => (
        <SortableItem
          key={producto.idProductos}
          index={index}
          idx={index}
          producto={producto}
        />
      ));

    const DragHandle = sortableHandle(() => (
      <Icon classes={{ root: classes.drag_icon }}>drag_indicator</Icon>
    ));

    const SortableContainer = sortableContainer(({ children }) => {
      return (
        <List component="div" classes={{ root: classes.list }}>
          {children}
        </List>
      );
    });

    let html = productos.length ? (
      <SortableContainer
        onSortEnd={(args) => onSortEnd({ args: args })}
        useDragHandle
      >
        {renderizarProductosOrdenados(productos)}
      </SortableContainer>
    ) : (
      <List classes={{ root: classes.list }}>
        <ListItem
          classes={{
            root: classes.list_item,
            container: classes.list_item_container,
          }}
        >
          <ListItemText
            classes={{
              root: classes.list_text,
              primary: classes.list_text_primary,
            }}
            primary="No tenés productos"
          />
        </ListItem>
      </List>
    );

    return (
      <>
        <Grid container spacing={1.5}>
          <Grid item xs={12} style={{ zIndex: 1400 }}>
            <AutosuggestUI
              suggestions={this.state.feed}
              input_value={this.state.feed_input_value}
              suggestion_selected={null}
              multiple={false}
              suggestion_index="p_nombre"
              label="Agregar producto"
              placeholder="Ej: Mochila ecológica"
              handleOnSuggestionSelected={this.handleOnSuggestionSelected}
              handleSuggestionsFetchRequested={
                this.handleSuggestionsFetchRequested
              }
              handleSuggestionsClearRequested={
                this.handleSuggestionsClearRequested
              }
              handleSuggestionsChangeSelected={
                this.handleSuggestionsChangeSelected
              }
            />
          </Grid>
          <Grid item xs={12}>
            {html}
          </Grid>
        </Grid>
      </>
    );
  }
}

const styles = (theme) => ({
  span: {
    display: 'block',
    width: '90%',
    fontSize: '12px',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  list_item_container: {
    zIndex: 1300,
  },
  list_item: {
    backgroundColor: '#fff',
    outline: '1px solid #e0e0e0',
    paddingLeft: '8px',
    paddingRight: '8px',
    zIndex: 1300,
    userSelect: 'none',
  },
  list: {
    padding: '0',
    listStyle: 'none',
  },
  list_text: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  list_text_primary: {
    fontSize: '14px',
  },
  list_item_secondary_action: {
    zIndex: 1300,
  },
  list_item_icon: {
    marginRight: '8px',
    zIndex: 1300,
  },
  drag_icon: {
    cursor: 'move',
    fontSize: '16px',
  },
  remove_icon_button: {
    padding: '6px',
  },
  remove_icon: {
    fontSize: '16px',
  },
});

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};

const mapDispatchToProps = null;

export const ShopProductsFeeder = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ShopProductsFeeder_));
