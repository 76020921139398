/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  metodosenvioObtener,
  metodosenvioObtenerDatosEmprepack,
  metodosenvioObtenerSucursalesEmprepack,
  tiendaObtenerDatosFacturacion,
  metodosenvioSolicitarEmprepack,
  metodosenvioEditarDireccionEmprepack,
  metodosenvioEditarContactoEmprepack,
  metodoenvioConfigurarEmprepack,
} from '../../store/actions';
import { Config, Regex, RegexExtra, Validate } from '../../other';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  InputUI,
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
  CheckboxUI,
  ModuleCardTitleUI,
  SelectUI,
  ToggleUI,
  CardModuleUI,
  DropdownUI,
  MenuItemUI,
  ModalUI,
  DocFileUI,
} from '../common';
import {
  method_shipment_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../amplitude';
import * as braze from '@braze/web-sdk';
import { WrapperLayout } from '../../layouts/WrapperLayout';

const obtener_label_customer_status = (customer_status) => {
  let customer_status_label = '';

  switch (customer_status) {
    case -1:
      customer_status_label =
        'Su cuenta de Emprepack aún no esta activa, debes enviar toda la documentación para proceder. El proceso de evaluación puede demorar hasta 1 semana, al aceptar la cuenta comenzará a funcionar automáticamente en tu tienda.';
      break;
    case 0:
      customer_status_label =
        'Su cuenta de Emprepack se encuentra en proceso de verificación de documentación. El proceso de evaluación puede demorar hasta 1 semana, al aceptar la cuenta comenzará a funcionar automáticamente en tu tienda.';
      break;
    case 1:
      customer_status_label = 'Su cuenta de Emprepack se encuentra activa.';
      break;
    case 2:
      customer_status_label =
        'Su cuenta se encuentra con deuda, debes abonarla desde Acciones -> Abonar deuda.';
      break;
    case 3:
      customer_status_label =
        'Su cuenta se encuentra bloqueada. Para mayor información, contactese por email a hola@emprepack.com';
      break;
    case 4:
      customer_status_label =
        'Los archivos de verificación enviados son invalidos, debes volver a enviarlos.';
      break;
    default:
      customer_status_label = '';
      break;
  }
  return customer_status_label;
};

const DatosFacturacion = () => {
  return (
    <CardModuleUI below>
      <Grid container spacing={1.5}>
        <ModuleCardTitleUI
          title={
            'Para poder activar Emprepack debes cargar tus datos de facturación desde Mi tienda -> Mi plan -> Configuración -> Datos de facturación'
          }
        />
        <Grid item xs={12}>
          <ButtonUI type="link" label="Cargar" fullWidth={false} link="/plan" />
        </Grid>
      </Grid>
    </CardModuleUI>
  );
};

const DatosEnvioGratis = ({
  configuraciones,
  selects,
  handleChange,
  handleChangeSelect,
  handleChangeCheckbox,
}) => {
  return (
    <Grid container spacing={1.5}>
      <ModuleCardTitleUI
        title="Envío gratis"
        subtitles={[
          'En esta sección podés configurar los envíos gratuitos a través de Emprepack.',
        ]}
      />
      <Grid item xs={12}>
        <SelectUI
          data={configuraciones.me_emprepack_tipo}
          options={selects.me_emprepack_tipo}
          handleChangeSelect={handleChangeSelect}
        />
      </Grid>
      <ToggleUI show={configuraciones.me_emprepack_tipo.value === 2}>
        <Grid item xs={12}>
          <InputUI
            handleChange={handleChange}
            input={configuraciones.me_emprepack_gratis_minimo}
            adornment="$"
          />
        </Grid>
      </ToggleUI>
      <ToggleUI show={configuraciones.me_emprepack_tipo.value >= 1}>
        <Grid item xs={12}>
          <CheckboxUI
            handleChangeCheckbox={handleChangeCheckbox}
            checkbox={configuraciones.me_emprepack_gratis_sucursal}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxUI
            handleChangeCheckbox={handleChangeCheckbox}
            checkbox={configuraciones.me_emprepack_gratis_domicilio}
          />
        </Grid>
      </ToggleUI>
    </Grid>
  );
};

const DatosEnvioRecargo = ({ configuraciones, handleChange }) => {
  let ejemplo = 500;
  let porcentaje = Validate.trim_float(
    configuraciones.me_emprepack_recargo_porcentaje.value
  );
  let porcentaje_a_ejemplo = Validate.trim_float(
    Validate.get_fixed_percent(ejemplo, porcentaje)
  );
  let monto = Validate.trim_float(
    configuraciones.me_emprepack_recargo_monto.value
  );
  let total = ejemplo + porcentaje_a_ejemplo + monto;
  total = total > 0 ? total : 0;
  let porcentaje_label = porcentaje >= 0 ? 'recargo' : 'descuento';
  let monto_fijo_label = monto >= 0 ? 'recargo' : 'descuento';

  return (
    <Grid container spacing={3}>
      <ModuleCardTitleUI
        title="Recargos y/o descuentos (opcional)"
        subtitles={[
          'Podés configurar un aumento o disminución en monto fijo y/o porcentaje al monto tarifado por EmprePack. Por ejemplo, podés agregar el porcentaje de comisión por venta de los métodos de pago, costos de embalaje, despacho, etc o hacer un descuento por una parte del envío a tu cliente y vos abonar esa diferencia.',
          'Para descontar sólo tenés que poner el signo menos (-) delante del monto y/o porcentaje.',
        ]}
      />
      <Grid item xs={12}>
        <InputUI
          handleChange={handleChange}
          input={configuraciones.me_emprepack_recargo_porcentaje}
          end_adornment="%"
          step="0.01"
        />
      </Grid>
      <Grid item xs={12}>
        <InputUI
          handleChange={handleChange}
          input={configuraciones.me_emprepack_recargo_monto}
          adornment="$"
          step="0.01"
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Typography variant="body1" mb={1}>
          Ejemplo de cómo tarifaría suponiendo que el costo del envío sea de $
          {Validate.number_format(ejemplo)} (monto no real, los costos de envío
          pueden variar según origen, destino, dimensiones y peso del paquete):
        </Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{ paddingLeft: '0px' }}>
                Ejemplo tarifa final Emprepack
              </TableCell>
              <TableCell align="right">
                ${Validate.number_format(ejemplo)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ paddingLeft: '0px' }}>
                Porcentaje de {porcentaje_label} ({porcentaje}%)
              </TableCell>
              <TableCell align="right">
                ${Validate.number_format(porcentaje_a_ejemplo)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ paddingLeft: '0px' }}>
                Monto de {monto_fijo_label}
              </TableCell>
              <TableCell align="right">
                ${Validate.number_format(monto)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ paddingLeft: '0px' }}>
                Tarifa final a pagar por el cliente
              </TableCell>
              <TableCell align="right">
                ${Validate.number_format(total)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

const DatosEnvioContacto = ({
  emprepack,
  kyc_dni,
  handleChange,
  handleChangeDoc,
}) => {
  return (
    <Grid container spacing={1.5}>
      <ModuleCardTitleUI
        title="Información de contacto"
        subtitles={[
          'Esta información sera utilizada para contactarte en caso de ser necesario.',
        ]}
      />
      <Grid item xs={12}>
        <InputUI handleChange={handleChange} input={emprepack.customer_email} />
      </Grid>
      <Grid item lg={6} xs={12}>
        <InputUI handleChange={handleChange} input={emprepack.customer_name} />
      </Grid>
      <Grid item lg={6} xs={12}>
        <InputUI
          handleChange={handleChange}
          input={emprepack.customer_surname}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <InputUI
          handleChange={handleChange}
          input={emprepack.customer_identification}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <InputUI handleChange={handleChange} input={emprepack.customer_phone} />
      </Grid>
      <Grid item xs={12}>
        <DocKyc handleChangeDoc={handleChangeDoc} input={kyc_dni.dni_frontal} />
      </Grid>
      <Grid item xs={12}>
        <DocKyc handleChangeDoc={handleChangeDoc} input={kyc_dni.dni_reverso} />
      </Grid>
      <Grid item xs={12}>
        <DocKyc handleChangeDoc={handleChangeDoc} input={kyc_dni.dni_selfie} />
      </Grid>
    </Grid>
  );
};

const DatosEnvioDomicilio = ({
  emprepack,
  configuraciones,
  kyc_domicilio,
  selects,
  handleChange,
  handleChangeSelect,
  handleChangeDoc,
  filtrarSucursales,
  handleChangeZipCode,
}) => {
  return (
    <Grid container spacing={1.5}>
      <ModuleCardTitleUI
        title="Dirección de origen"
        subtitles={[
          'Esta información será utilizada para determinar el origen del envío.',
        ]}
      />
      <Grid item xs={12}>
        <InputUI
          handleChange={handleChange}
          input={emprepack.customer_street}
        />
      </Grid>
      <Grid item lg={4} xs={12}>
        <InputUI
          handleChange={handleChange}
          input={emprepack.customer_street_number}
        />
      </Grid>
      <Grid item lg={4} xs={6}>
        <InputUI handleChange={handleChange} input={emprepack.customer_floor} />
      </Grid>
      <Grid item lg={4} xs={6}>
        <InputUI
          handleChange={handleChange}
          input={emprepack.customer_apartment}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <InputUI handleChange={handleChange} input={emprepack.customer_city} />
      </Grid>
      <Grid item lg={6} xs={12}>
        <SelectUI
          data={emprepack.customer_state}
          options={selects.provincias}
          handleChangeSelect={handleChangeSelect}
        />
      </Grid>
      <Grid item xs={12}>
        <InputUI
          handleChange={handleChangeZipCode}
          input={emprepack.customer_zipcode}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectUI
          data={configuraciones.me_emprepack_office_id}
          options={filtrarSucursales(selects.sucursales)}
          value_atribute="office_id"
          handleChangeSelect={handleChangeSelect}
        />
      </Grid>
      <Grid item xs={12}>
        <DocKyc
          handleChangeDoc={handleChangeDoc}
          input={kyc_domicilio.comprobante_domicilio}
        />
      </Grid>
    </Grid>
  );
};

const DocKyc = ({ input, handleChangeDoc }) => {
  const allowed_files = ['pdf', 'jpeg', 'jpg', 'png'];
  const max_size = 3;
  const input_allowed_mime = 'image/jpg,image/jpeg,image/png,application/pdf';

  return (
    <DocFileUI
      data={input}
      handleChangeDoc={handleChangeDoc}
      max_size={max_size}
      allowed_files={allowed_files}
      input_allowed_mime={input_allowed_mime}
    />
  );
};

const EstadoCuenta = ({ customer_status }) => {
  return (
    <CardModuleUI>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ModuleCardTitleUI
            title="Estado de cuenta"
            subtitles={[obtener_label_customer_status(customer_status)]}
          />
        </Grid>
      </Grid>
    </CardModuleUI>
  );
};

const MenuConfiguracion = ({ customer_status, modalHandler }) => {
  if (customer_status >= 0) {
    return (
      <DropdownUI label="Configuración">
        <MenuItemUI
          type="callf"
          onClickFunction={(e) => modalHandler(true, 'editar_domicilio')}
        >
          Editar dirección de origen
        </MenuItemUI>
        <MenuItemUI
          type="callf"
          onClickFunction={(e) => modalHandler(true, 'editar_contacto')}
        >
          Editar información de contacto
        </MenuItemUI>
      </DropdownUI>
    );
  } else {
    return (
      <ButtonUI
        type="link"
        label="Tutorial"
        link="https://recursos.empretienda.com/emprepack.pdf"
        fullWidth={false}
        external_link
        open_new_tab
      />
    );
  }
};

const ModalDatosDomicilio = (props) => {
  let {
    emprepack,
    configuraciones,
    handleSubmit,
    modalHandler,
    modal_state,
    form_state,
    kyc_domicilio,
    selects,
    handleChange,
    handleChangeSelect,
    handleChangeDoc,
    filtrarSucursales,
    handleChangeZipCode,
  } = props;

  return (
    <ModalUI
      open={modal_state}
      id="editar_domicilio"
      title="Editar domicilio"
      subtitle="Tene en cuenta que al editar el domicilio, se reiniciará el proceso de alta con una demora de 72hs. En ese tiempo su tienda no podrá utilizar Emprepack."
      modalHandler={modalHandler}
      aditional_param="editar_domicilio"
      handleSubmit={handleSubmit}
      button_label="Editar"
      button_loading={form_state}
    >
      <DatosEnvioDomicilio
        emprepack={emprepack}
        configuraciones={configuraciones}
        kyc_domicilio={kyc_domicilio}
        selects={selects}
        handleChange={handleChange}
        handleChangeSelect={handleChangeSelect}
        handleChangeDoc={handleChangeDoc}
        filtrarSucursales={filtrarSucursales}
        handleChangeZipCode={handleChangeZipCode}
      />
    </ModalUI>
  );
};

const ModalDatosContacto = (props) => {
  let {
    emprepack,
    handleSubmit,
    modalHandler,
    modal_state,
    form_state,
    kyc_dni,
    handleChange,
    handleChangeDoc,
  } = props;

  return (
    <ModalUI
      open={modal_state}
      id="editar_contacto"
      title="Editar contacto"
      subtitle="Tene en cuenta que al editar la información de contacto, se reiniciará el proceso de alta con una demora de 72hs. En ese tiempo su tienda no podrá utilizar Emprepack."
      modalHandler={modalHandler}
      aditional_param="editar_contacto"
      handleSubmit={handleSubmit}
      button_label="Editar"
      button_loading={form_state}
    >
      <DatosEnvioContacto
        emprepack={emprepack}
        kyc_dni={kyc_dni}
        handleChange={handleChange}
        handleChangeDoc={handleChangeDoc}
      />
    </ModalUI>
  );
};

const ModalTOS = (props) => {
  let { modalHandler, modal_state } = props;

  return (
    <ModalUI
      open={modal_state}
      id="tos"
      title="TÉRMINOS Y CONDICIONES EMPREPACK – ANDREANI"
      modalHandler={modalHandler}
      aditional_param="tos"
      handleSubmit={(e) => {
        e.preventDefault();
        modalHandler(false, 'tos');
      }}
      button_label="Cerrar"
      wide_modal={true}
    >
      <Grid container spacing={1.5}>
        <Grid item>
          <Typography variant="h5" gutterBottom>
            TÉRMINOS Y CONDICIONES EMPREPACK – ANDREANI
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            1. ACERCA DE EMPREPACK
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            EMPREPACK es una solución desarrollada por EMPRETIENDA para poder
            utilizar el servicio de logística eCommerce de ANDREANI.
            <br />
            Los emprendedores y los Usuarios podrán ofrecer y coordinar el envío
            de los productos comercializados a través de las tiendas de
            EMPRETIENDA mediante el servicio de transporte y/o logística y/o
            correo postal brindado por ANDREANI.
            <br />
            EMPREPACK, le permite al emprendedor acceder al servicio de
            eCommerce de ANDREANI sin tener que cumplimentar el mínimo de envíos
            requeridos por dicha firma. EMPREPACK se limita a calcular el valor
            del envío del producto previo a la compra y a facilitar la
            generación de las etiquetas necesarias para el posterior envío del
            producto a través de la firma ANDREANI.
            <br />
            ANDREANI será la firma responsable y encargada de realizar
            directamente los servicios de envíos a favor de los compradores
            cuando los mismos sean requeridos a través de la plataforma de
            EMPREPACK. Los compradores y emprendedores celebran con dicha firma
            un contrato de transporte de mercaderías que se regirá por lo
            convenido entre las partes y la normativa aplicable al caso.
            <br />
            EMPREPACK sólo facilita la herramienta para que el Usuario o
            emprendedor pueda acceder de forma más cómoda, efectiva y accesible
            al servicio de logística y entrega de los productos adquiridos en
            las tiendas de EMPRETIENDA.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            2. TÉRMINOS Y CONDICIONES DE EMPREPACK
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            Los términos y condiciones de EMPREPACK son parte de los Términos y
            Condiciones de EMPRETIENDA.
            <br />
            La aceptación de los términos de EMPREPACK implica la aceptación de
            los términos y condiciones de EMPRETIENDA y de las condiciones
            negociales que estipule ANDREANI en sus contratos de transporte.
            <br />
            Los presentes términos y condiciones (en adelante los “Términos y
            Condiciones”) tienen carácter vinculante y obligatorio y son
            aplicados a la utilización de los servicios EMPREPACK, cuyo
            responsable es EMPRETIENDA SAS, CUIT 30-71671409-4 con domicilio en
            calle 20 N°1406 Las Parejas, Santa Fe, Argentina.
            <br />
            La utilización de los Servicios EMPREPACK atribuye la condición de
            usuario y/o emprendedor del Sitio e implica que usted tiene la
            capacidad legal para suscribir este contrato y que presta la
            conformidad y aceptación voluntaria, expresa, plena y sin reservas,
            de todos los derechos y obligaciones que asume, como así también de
            cada una de las cláusulas de los Términos y Condiciones y de la
            políticas de privacidad de datos personales de EMPRETIENDA Y
            EMPREPACK, publicadas en los sitios de la empresa.
            <br />
            En caso de no estar de acuerdo con los mismos deberá abstenerse de
            utilizar los Servicios. Tanto el comprador como el emprendedor
            consienten expresamente que todos los datos incorporados que sean
            necesarios para el uso de EMPREPACK sean recolectados y almacenados
            y se le dará el tratamiento estipulado en las políticas de
            privacidad de EMPRETIENDA.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            3. REGISTRACIÓN - ACTIVAR EMPREPACK EN LA TIENDA
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            Para contar con el servicio EMPREPACK ANDREANI el emprendedor deberá
            solicitar el alta de su cuenta EMPREPACK, accediendo a la opción
            desde Mi tienda &gt; Métodos de envío, y seleccionar la opción de
            EMPREPACK/ANDREANI. El emprendedor deberá acreditar su identidad y
            remitir la documentación solicitada a EMPRETIENDA, quién tendrá un
            plazo de 1 semana para revisar el cumplimiento de los requisitos
            solicitados.
            <br />
            EMPREPACK tiene el derecho de rechazar, cancelar o suspender con o
            sin causa una solicitud o el uso de los servicios de EMPREPACK, sin
            que tal decisión genere para el Usuario derechos de indemnización o
            resarcimiento.
            <br />
            El Emprendedor con una cuenta EMPREPACK activa podrá ofrecer en sus
            publicaciones el servicio de envío EMPREPACK – ANDREANI y quedará
            sujeto a las condiciones y disponibilidad del servicio que
            establezca ANDREANI para el producto ofrecido. El producto deberá
            ser apto para ser transportado por ANDREANI; y el destino del envío
            debe ser un domicilio válido dentro del territorio de la República
            Argentina y encontrarse dentro de los destinos operativos y
            cubiertos por la firma ANDREANI.
            <br />
            El emprendedor es responsable de seleccionar la categoría más
            adecuada, configurar las correctas dimensiones para el producto
            publicado y verificar la disponibilidad del Servicio de ANDREANI
            para ese bien. Cualquier consecuencia derivada de la categorización
            incorrecta del bien al ser publicado, diferentes dimensiones entre
            las configuradas y efectivamente despachadas o de la omisión de la
            verificación de las limitaciones respecto del Servicio de ANDREANI
            será asumida por el emprendedor (por ejemplo, mayor costo del envío
            o imposibilidad de transportar el bien).
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            4. FUNCIONAMIENTO DEL SERVICIO. PAGO DEL SERVICIO
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            Una vez enviada y aceptada la solicitud de cuenta de EMPREPACK, el
            emprendedor contará con esta opción de envío y en su tienda
            comenzará a calcularse (tarifarse) el costo del envío a través de
            EMPREPACK.
            <br />
            El cálculo de la tarifa dependerá del domicilio del emprendedor y/o
            lugar de entrega y/o retiro del producto vendido, las dimensiones,
            peso y/u otras características de los productos y el código postal
            de destino que selecciona el cliente.
            <br />
            EMPREPACK publicará la opción de envío para que los compradores
            puedan elegir el servicio.
            <br />
            Cuando un comprador seleccione la opción de EMPREPACK ANDREANI en
            una tienda abonará el total de la orden al emprendedor, productos +
            envío, a través del método de pago que seleccione.
            <br />
            Una vez que el emprendedor reciba el pago podrá dar de alta la
            etiqueta del envío desde el menú de acciones de ventas del panel
            administrador de EMPRETIENDA y acceder al link directo para abonarle
            a EMPRETIENDA el costo del envío a través de Mercado Pago ó Mobbex.
            EMPRETIENDA será la encargada de pagarle a ANDREANI el servicio.
            <br />
            Una vez abonado por el EMPRENDEDOR el envío, podrá descargar del
            menú de acciones la etiqueta para imprimir.
            <br />
            En caso que el EMPRENDEDOR haya optado porque el costo del envío
            esté a cargo del comprador, el EMPRENDEDOR se obliga a incluir en el
            documento fiscal correspondiente el costo de envío y el precio del
            producto.
            <br />
            El EMPRENDEDOR se obliga expresamente a no cobrar ni solicitar sumas
            adicionales de dinero alguno al comprador por cuestiones
            relacionadas al uso de EMPREPACK.
            <br />
            Una vez que se da de alta el envío, el comprador puede hacer un
            seguimiento del mismo desde la tienda online desde la sección "Mi
            cuenta" o en el sitio de ANDREANI.
            <br />
            Al hacer click en "seguir envío" le va a aparecer un recuadro con el
            estado del mismo. Una vez que efectivamente se despache el paquete y
            sea ingresado al sistema de ANDREANI, ellos enviaran un mail con
            toda la información. La recepción de los mails enviados desde
            ANDREANI queda sujeta a la disponibilidad de su servicio y correctos
            datos cargados por el comprador.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            5. COSTO A CARGO DEL EMPRENDEDOR
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            El emprendedor podrá ofrecer en sus publicaciones la opción de
            “Envío gratis”.
            <br />
            El emprendedor se hará cargo de todos los costos por el uso de
            EMPREPACK modalidad de “Envío gratis”.
            <br />
            El emprendedor está obligado a entregar el envío (paquete
            conteniendo los bienes a ser transportados) en cualquier punto de
            admisión de ANDREANI debidamente autorizado o solicitar su retiro
            (en caso que tuviera esta opción disponible y con su respectivo
            costo adicional) en las condiciones requeridas para paquete y
            producto. Asimismo, deberá respetar lo establecido para la fecha de
            entrega para evitar que sea rechazado el envío.
            <br />
            En el supuesto que ANDREANI rechace el envío por no haber cumplido
            con las condiciones estipuladas el emprendedor deberá negociar
            directamente con el comprador la forma de envío del producto. En
            este caso el emprendedor se hará cargo íntegramente de cualquier
            cargo extra originado por el envío del producto.
            <br />
            El emprendedor deberá entregar el envío con la etiqueta respectiva y
            pegada adecuadamente, con información precisa respecto del paquete,
            contenido y destino. La elección incorrecta de las etiquetas es
            responsabilidad exclusiva del emprendedor, quién se hará cargo de
            daños y perjuicios resultantes de este incumplimiento.
            <br />
            El emprendedor deberá configurar correctamente las dimensiones de
            los bienes en su tienda. En caso que el emprendedor despache en
            ANDREANI un paquete de mayor dimensiones a las configuradas será su
            exclusiva responsabilidad, se hará cargo de abonar la diferencia de
            precio a EMPRETIENDA y de daños y perjuicios resultantes de este
            incumplimiento.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            6. PROCEDIMIENTO PARA UTILIZAR EMPREPACK
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            Una vez activo el método de envío EMPREPACK en la tienda, el
            comprador ingresa el código postal y/o domicilio donde desea recibir
            el envío y recibirá información sobre la disponibilidad del servicio
            y sus costos.
            <br />
            El costo del envío será visualizado separadamente del valor del
            producto seleccionado y podrá ser abonado mediante los métodos de
            pago disponibles en la tienda del emprendedor.
            <br />
            El comprador que decida adquirir un producto donde la publicación
            ofrezca el envío por EMPREPACK, deberá brindar toda la información
            completa y correcta para el envío y recepción del producto. El
            comprador reconoce que será el único responsable por las
            consecuencias resultantes de un incumplimiento de dicha obligación.
            <br />
            Una vez que el comprador haya completado la información para el
            envío del producto en cuestión, abonado el valor del producto
            ofertado y el costo del envío calculado por ANDREANI (en caso de
            corresponder), el emprendedor recibirá la información cargada por el
            comprador para el envío del producto y procederá a pagar el envío a
            EMPRETIENDA, imprimir las etiquetas para el envío del producto,
            cerrar y empaquetar correctamente el producto a enviar, respetando
            para ello la naturaleza del mismo según corresponda, siguiendo las
            directivas que establezca ANDREANI.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            7. PROCEDIMIENTO EN CASO DE NO ENTREGA
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            Una vez despachado efectivamente el producto e ingresado al sistema
            de ANDREANI, ellos enviarán un mail con toda la información respecto
            a la entrega y/o retiro. En caso de no entrega y/o inconvenientes
            con el envío los emprendedores deberán dirigirse vía mail a
            hola@emprepack.com informando el número de seguimiento del envío y
            detallando el problema.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            8. CANCELACIÓN DE LA OPERACIÓN POR CUALQUIER CAUSA
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            En caso que el comprador decida devolver el artículo al emprendedor
            o se arrepienta o cancele la transacción luego del envío del
            artículo, EMPRETIENDA no reintegrará el monto abonado por el envío
            ya que el mismo ha sido utilizado. En este caso, será el emprendedor
            quién asuma este costo frente al usuario.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            9. RESPONSABILIDAD DEL EMPRENDEDOR
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            El emprendedor se compromete a entregar a ANDREANI y EMPRETIENDA
            toda la documentación necesaria que deba presentar o ser requerida
            por cualquier autoridad según la legislación vigente en la materia.
            En virtud de ello, el emprendedor será el único responsable por la
            falta de entrega de dicha documentación, y su falta no podrá ser
            invocada para atribuir un incumplimiento a ANDREANI o EMPRETIENDA.
            <br />
            Es responsabilidad tanto del comprador como del emprendedor la
            veracidad de la información requerida por ANDREANI y EMPRETIENDA.
            Los despachos deben cumplimentar con la normativa exigida por
            ANDREANI y las normas que regulan el transporte de cosas. El
            emprendedor autoriza a ANDREANI a inspeccionar los envíos, sin
            previa solicitud, siempre y cuando dicha inspección se deba realizar
            por cuestiones de seguridad o por solicitud de alguna autoridad
            competente o cualquier otra razón justificada.
            <br />
            El emprendedor será absolutamente responsable del medio de
            transporte contratado y eximirá de toda responsabilidad a
            EMPRETIENDA por cualquier reclamo vinculado al servicio de envío. El
            emprendedor será responsable por las averías, daños o destrucción
            que sufran o causen los bienes transportados derivada de los
            defectos propios de los bienes transportados o su embalaje.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            10. RESPONSABILIDAD DEL COMPRADOR
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            El comprador será absolutamente responsable, si por su error
            voluntario o involuntario, dolo o culpa completara errónea o
            falsamente los datos necesarios para realizar la entrega del paquete
            o por no haber persona que pueda recibir el envío o retirarlo en el
            lugar que el comprador hubiera seleccionado.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            11. NOTIFICACIONES Y CANCELACIÓN DE LA CUENTA O ANUNCIO
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            El EMPRENDEDOR puede cancelar la cuenta activa en cualquier momento
            directamente desde su panel administrador.
            <br />
            LA EMPRESA se reserva el derecho de bloquear su acceso al Servicio y
            el de sus EMPRENDEDORES habilitados e interrumpir su uso ante el
            incumplimiento de los presentes términos y condiciones.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            12. INCUMPLIMIENTO E INDEMNIZACIÓN
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            La violación de estos TÉRMINOS y CONDICIONES lo hará responsable de
            indemnizar cualquier daño y perjuicio ocasionado a EMPRETIENDA y/o a
            sus empleados, funcionarios, directores y agentes. Esta
            indemnización se extiende a todos los gastos, pérdidas, o cualquier
            otro daño, directo o indirecto, patrimonial o extrapatrimonial
            ocasionado, incluyendo, pero no limitando a los gastos legales y
            honorarios de abogados.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            13. JURISDICCIÓN Y LEGISLACIÓN APLICABLE
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            Toda controversia derivada del presente contrato o que guarde
            relación con este será resuelta por un árbitros del Tribunal de
            Arbitraje del Colegio de Abogados de Rosario conforme con su
            Reglamento de Arbitraje.
            <br />
          </Typography>
        </Grid>
      </Grid>
    </ModalUI>
  );
};

class Emprepack_ extends Component {
  state = {
    configuraciones: {
      me_emprepack: {
        validate: false,
        name: 'me_emprepack',
        label: 'Activar Emprepack',
        value: 1,
        change_param: 'configuraciones',
      },
      me_emprepack_tipo: {
        validate: false,
        required: true,
        error: false,
        label: 'Ofrecer envío gratis',
        id: 'me_emprepack_tipo',
        name: 'me_emprepack_tipo',
        change_param: 'configuraciones',
        value: 0,
        messages: {
          error: 'Por favor, seleccione una opción',
        },
      },
      me_emprepack_gratis_minimo: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Monto mínimo',
        id: 'me_emprepack_gratis_minimo',
        name: 'me_emprepack_gratis_minimo',
        change_param: 'configuraciones',
        regex: Regex.PRECIO.ARG,
        value: '',
        default_value: '',
        placeholder: '3000',
        messages: {
          help: 'El monto mínimo requerido de la compra para poder acceder al envío gratis',
          error: 'Por favor, ingrese un monto mínimo válido',
          error_extra: RegexExtra.PRECIO.ARG,
        },
      },
      me_emprepack_recargo_monto: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Monto fijo a recargar/descontar',
        id: 'me_emprepack_recargo_monto',
        name: 'me_emprepack_recargo_monto',
        change_param: 'configuraciones',
        regex: Regex.PRECIO_CON_SIGNO.ARG,
        value: '',
        default_value: '',
        placeholder: '50.00',
        messages: {
          error: 'Por favor, ingrese un monto válido',
          error_extra: RegexExtra.PRECIO_CON_SIGNO.ARG,
        },
      },
      me_emprepack_recargo_porcentaje: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Porcentaje sobre la tarifa a recargar/descontar',
        id: 'me_emprepack_recargo_porcentaje',
        name: 'me_emprepack_recargo_porcentaje',
        change_param: 'configuraciones',
        regex: Regex.PORCENTAJE_CON_SIGNO,
        value: '',
        default_value: '',
        placeholder: '5',
        messages: {
          error: 'Por favor, ingrese un porcentaje válido',
          error_extra: RegexExtra.PORCENTAJE_CON_SIGNO,
        },
      },
      me_emprepack_office_id: {
        validate: false,
        required: true,
        error: false,
        label: 'Sucursal de despacho',
        id: 'me_emprepack_office_id',
        name: 'me_emprepack_office_id',
        change_param: 'configuraciones',
        value: 0,
        messages: {
          error: 'Por favor, seleccione una opción',
          help: 'Aquí podrás elegir la sucursal Andreani donde despacharás los paquetes.',
        },
      },
      me_emprepack_gratis_sucursal: {
        validate: false,
        name: 'me_emprepack_gratis_sucursal',
        label: 'Ofrecer envío gratis para envíos a sucursal',
        value: 0,
        change_param: 'configuraciones',
      },
      me_emprepack_gratis_domicilio: {
        validate: false,
        name: 'me_emprepack_gratis_domicilio',
        label: 'Ofrecer envío gratis para envíos a domicilio',
        value: 0,
        change_param: 'configuraciones',
      },
    },
    emprepack: {
      customer_email: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Email',
        id: 'customer_email',
        name: 'customer_email',
        change_param: 'emprepack',
        regex: Regex.EMAIL,
        value: '',
        default_value: '',
        placeholder: 'Email',
        messages: {
          error: 'Por favor, ingrese un email válido',
          error_extra: RegexExtra.EMAIL,
        },
      },
      customer_name: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nombre del responsable',
        id: 'customer_name',
        name: 'customer_name',
        change_param: 'emprepack',
        regex: Regex.NOMBRE_2_45,
        value: '',
        default_value: '',
        placeholder: 'Nombre del responsable',
        messages: {
          error: 'Por favor, ingrese un nombre válido',
          help: 'Debe coincidir con el nombre del DNI adjunto',
          error_extra: RegexExtra.NOMBRE_2_45,
        },
      },
      customer_surname: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Apellido del responsable',
        id: 'customer_surname',
        name: 'customer_surname',
        change_param: 'emprepack',
        regex: Regex.NOMBRE_2_45,
        value: '',
        default_value: '',
        placeholder: 'Apellido del responsable',
        messages: {
          error: 'Por favor, ingrese un apellido válido',
          help: 'Debe coincidir con el apellido del DNI adjunto',
          error_extra: RegexExtra.NOMBRE_2_45,
        },
      },
      customer_identification: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'DNI del responsable',
        id: 'customer_identification',
        name: 'customer_identification',
        change_param: 'emprepack',
        regex: Regex.DNI,
        value: '',
        default_value: '',
        placeholder: 'DNI del responsable',
        messages: {
          error: 'Por favor, ingrese un DNI válido',
          help: 'Debe coincidir con el número de documento del DNI adjunto',
          error_extra: RegexExtra.DNI,
        },
      },
      customer_phone: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Teléfono del responsable',
        id: 'customer_phone',
        name: 'customer_phone',
        change_param: 'emprepack',
        regex: Regex.TELEFONO,
        value: '',
        default_value: '',
        placeholder: 'Teléfono del responsable',
        messages: {
          error: 'Por favor, ingrese un teléfono válido',
          help: 'Será utilizado para medio de contacto para los envíos',
          error_extra: RegexExtra.TELEFONO,
        },
      },
      customer_street: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Calle',
        id: 'customer_street',
        name: 'customer_street',
        change_param: 'emprepack',
        regex: Regex.CALLE_1_30,
        value: '',
        default_value: '',
        placeholder: 'Av. Pellegrini',
        messages: {
          error: 'Por favor, ingrese una calle válida',
          error_extra: RegexExtra.CALLE_1_30,
        },
      },
      customer_street_number: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Número',
        id: 'customer_street_number',
        name: 'customer_street_number',
        change_param: 'emprepack',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: '13210',
        messages: {
          error: 'Por favor, ingrese un número de calle válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      customer_floor: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Piso',
        id: 'customer_floor',
        name: 'customer_floor',
        change_param: 'emprepack',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: '10',
        messages: {
          error: 'Por favor, ingrese un número de piso válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      customer_apartment: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Dpto',
        id: 'customer_apartment',
        name: 'customer_apartment',
        change_param: 'emprepack',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: 'A',
        messages: {
          error: 'Por favor, ingrese un número de dpto válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      customer_city: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Ciudad',
        id: 'customer_city',
        name: 'customer_city',
        change_param: 'emprepack',
        regex: Regex.CIUDAD_2_45,
        value: '',
        default_value: '',
        placeholder: 'Rosario',
        messages: {
          error: 'Por favor, ingrese una ciudad válida',
          error_extra: RegexExtra.CIUDAD_2_45,
        },
      },
      customer_state: {
        validate: false,
        required: true,
        error: false,
        label: 'Provincia',
        id: 'customer_state',
        name: 'customer_state',
        change_param: 'emprepack',
        value: 'Buenos Aires',
        messages: {
          error: 'Por favor, seleccione una opción',
        },
      },
      customer_zipcode: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Código postal',
        id: 'customer_zipcode',
        name: 'customer_zipcode',
        change_param: 'emprepack',
        regex: Regex.CODIGO_POSTAL,
        value: '',
        default_value: '',
        placeholder: '1234',
        messages: {
          error: 'Por favor, ngrese un número de código postal válido',
          error_extra: RegexExtra.CODIGO_POSTAL,
        },
      },
      customer_status: {
        validate: false,
        value: -2,
      },
    },
    kyc_dni: {
      dni_frontal: {
        validate: true,
        auth: false,
        required: true,
        id: 'dni_frontal',
        name: 'dni_frontal',
        change_param: 'kyc_dni',
        file: null,
        messages: {
          help: 'Sube una foto de la parte frontal de tu DNI',
          error: 'Por favor, sube un archivo válido',
        },
      },
      dni_reverso: {
        validate: true,
        auth: false,
        required: true,
        id: 'dni_reverso',
        name: 'dni_reverso',
        change_param: 'kyc_dni',
        file: null,
        messages: {
          help: 'Sube una foto de la parte trasera de tu DNI',
          error: 'Por favor, sube un archivo válido',
        },
      },
      dni_selfie: {
        validate: true,
        auth: false,
        required: true,
        id: 'dni_selfie',
        name: 'dni_selfie',
        change_param: 'kyc_dni',
        file: null,
        messages: {
          help: 'Sube una selfie donde se vea tu rostro y la parte frontal de tu DNI',
          error: 'Por favor, sube un archivo válido',
        },
      },
    },
    kyc_domicilio: {
      comprobante_domicilio: {
        validate: true,
        auth: false,
        required: true,
        id: 'comprobante_domicilio',
        name: 'comprobante_domicilio',
        change_param: 'kyc_domicilio',
        file: null,
        messages: {
          help: 'Sube un documento que compruebe tu domicilio y este a tu nombre, puede ser una factura o similar',
          error: 'Por favor, sube un archivo válido',
        },
      },
    },
    facturacion_activa: -1,
    loading_medios_envio: true,
    loading_datos_facturacion: true,
    loading_emprepack: true,
    loading_sucursales: true,
    loading_error: false,
    forms: {
      configurar_solicitar_emprepack: false,
      editar_domicilio: false,
      editar_contacto: false,
    },
    modals: {
      editar_domicilio: false,
      editar_contacto: false,
      tos: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    selects: {
      provincias: [
        {
          value: 'Buenos Aires',
          label: 'Buenos Aires',
        },
        {
          value: 'C.A.B.A',
          label: 'C.A.B.A',
        },
        {
          value: 'Catamarca',
          label: 'Catamarca',
        },
        {
          value: 'Chaco',
          label: 'Chaco',
        },
        {
          value: 'Chubut',
          label: 'Chubut',
        },
        {
          value: 'Córdoba',
          label: 'Córdoba',
        },
        {
          value: 'Corrientes',
          label: 'Corrientes',
        },
        {
          value: 'Entre Ríos',
          label: 'Entre Ríos',
        },
        {
          value: 'Formosa',
          label: 'Formosa',
        },
        {
          value: 'Jujuy',
          label: 'Jujuy',
        },
        {
          value: 'La Pampa',
          label: 'La Pampa',
        },
        {
          value: 'La Rioja',
          label: 'La Rioja',
        },
        {
          value: 'Mendoza',
          label: 'Mendoza',
        },
        {
          value: 'Misiones',
          label: 'Misiones',
        },
        {
          value: 'Neuquén',
          label: 'Neuquén',
        },
        {
          value: 'Río Negro',
          label: 'Río Negro',
        },
        {
          value: 'Salta',
          label: 'Salta',
        },
        {
          value: 'San Juan',
          label: 'San Juan',
        },
        {
          value: 'San Luis',
          label: 'San Luis',
        },
        {
          value: 'Santa Cruz',
          label: 'Santa Cruz',
        },
        {
          value: 'Santa Fe',
          label: 'Santa Fe',
        },
        {
          value: 'Santiago del Estero',
          label: 'Santiago del Estero',
        },
        {
          value: 'Tierra del Fuego',
          label: 'Tierra del Fuego',
        },
        {
          value: 'Tucumán',
          label: 'Tucumán',
        },
      ],
      me_emprepack_tipo: [
        {
          value: 0,
          label: 'No',
        },
        {
          value: 1,
          label: 'Si (Para todos los productos)',
        },
        {
          value: 2,
          label: 'Si (Ventas mayores a cierto monto)',
        },
      ],
      sucursales: [],
    },
  };

  componentDidMount() {
    this.props.tiendaObtenerDatosFacturacion((err, resp) => {
      this.setState({
        loading_datos_facturacion: false,
        loading_error: err ? true : this.state.loading_error,
        facturacion_activa: err ? 0 : resp.data.df_facturacion_activada,
      });
    });

    this.props.metodosenvioObtenerDatosEmprepack((err, resp) => {
      if (err) {
        this.setState({
          loading_emprepack: false,
          loading_error: err ? true : this.state.loading_error,
        });
      } else {
        Validate.cargar_formulario_sin_nulos(
          resp.data,
          this.state.emprepack,
          (emprepack_obj) => {
            this.setState({
              emprepack: emprepack_obj,
              loading_emprepack: false,
            });
          }
        );
      }
    });

    this.props.metodosenvioObtenerSucursalesEmprepack(
      { zipcode: 0 },
      (err, resp) => {
        if (err) {
          this.setState({
            loading_sucursales: false,
            loading_error: err ? true : this.state.loading_error,
          });
        } else {
          let sucursales_back = Validate.object_array_sort_by(
            resp.data,
            'office_state'
          ).map((sucursal) => {
            return {
              ...sucursal,
              label:
                sucursal.office_street +
                ' ' +
                sucursal.office_street_number +
                ', ' +
                sucursal.office_city +
                ', ' +
                sucursal.office_state,
            };
          });
          let sucursales = [
            {
              office_id: 0,
              label: 'Selecciona una sucursal',
            },
            ...sucursales_back,
          ];
          this.setState(
            {
              loading_sucursales: false,
              selects: {
                ...this.state.selects,
                sucursales: sucursales,
              },
            },
            () => {
              this.props.metodosenvioObtener((err, resp) => {
                if (err) {
                  this.setState({
                    loading_medios_envio: false,
                    loading_error: err ? true : this.state.loading_error,
                  });
                } else {
                  Validate.cargar_formulario_sin_nulos(
                    this.props.metodos_envio,
                    this.state.configuraciones,
                    (configuraciones_obj) => {
                      this.setState({
                        configuraciones: configuraciones_obj,
                        loading_medios_envio: false,
                      });
                    }
                  );
                }
              });
            }
          );
        }
      }
    );
  }

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      {
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: input,
            },
          });
        });
      }
    );
  };

  handleChangeZipCode = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      {
        configuraciones: {
          ...this.state.configuraciones,
          me_emprepack_office_id: {
            ...this.state.configuraciones.me_emprepack_office_id,
            value: blur
              ? this.state.configuraciones.me_emprepack_office_id.value
              : 0,
          },
        },
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: input,
            },
          });
        });
      }
    );
  };

  handleChangeSelect = (e, aditional) => {
    let name = e.target.name;
    let value =
      name === 'customer_state'
        ? Validate.trim(e.target.value)
        : Validate.trim_int(e.target.value);

    this.setState({
      [aditional]: {
        ...this.state[aditional],
        [name]: {
          ...this.state[aditional][name],
          value: value,
        },
      },
    });
  };

  handleChangeCheckbox = (e, aditional) => {
    let name = e.target.name;

    this.setState({
      [aditional]: {
        ...this.state[aditional],
        [name]: {
          ...this.state[aditional][name],
          value: this.state[aditional][name]['value'] ? 0 : 1,
        },
      },
    });
  };

  handleChangeDoc = (err, data) => {
    let { change_param, name } = data;

    this.setState({
      [change_param]: {
        ...this.state[change_param],
        [name]: data,
      },
    });
  };

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  //abre y cierra los modales
  modalHandler = (open, id) => {
    this.setState({
      modals: {
        ...this.state.modals,
        [id]: open,
      },
    });
  };

  handleSubmitSolicitarEmprepack = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        configurar_solicitar_emprepack: true,
      },
    });

    let { configuraciones, emprepack, kyc_dni, kyc_domicilio } = this.state;

    if (
      Validate.validar_formulario(configuraciones) &&
      Validate.validar_formulario(emprepack)
    ) {
      if (this.state.configuraciones.me_emprepack_office_id.value > 0) {
        if (Validate.validar_formulario(kyc_domicilio)) {
          if (Validate.validar_formulario(kyc_dni)) {
            this.props.metodosenvioSolicitarEmprepack(
              {
                customer_email: emprepack.customer_email.value,
                customer_name: emprepack.customer_name.value,
                customer_surname: emprepack.customer_surname.value,
                customer_identification:
                  emprepack.customer_identification.value,
                customer_phone: emprepack.customer_phone.value,
                customer_street: emprepack.customer_street.value,
                customer_street_number: emprepack.customer_street_number.value,
                customer_floor: emprepack.customer_floor.value,
                customer_apartment: emprepack.customer_apartment.value,
                customer_city: emprepack.customer_city.value,
                customer_state: emprepack.customer_state.value,
                customer_zipcode: emprepack.customer_zipcode.value,
                me_emprepack: configuraciones.me_emprepack.value,
                me_emprepack_tipo: configuraciones.me_emprepack_tipo.value,
                me_emprepack_gratis_minimo:
                  configuraciones.me_emprepack_gratis_minimo.value,
                me_emprepack_recargo_monto:
                  configuraciones.me_emprepack_recargo_monto.value,
                me_emprepack_recargo_porcentaje:
                  configuraciones.me_emprepack_recargo_porcentaje.value,
                me_emprepack_office_id:
                  configuraciones.me_emprepack_office_id.value,
                me_emprepack_gratis_sucursal:
                  configuraciones.me_emprepack_gratis_sucursal.value,
                me_emprepack_gratis_domicilio:
                  configuraciones.me_emprepack_gratis_domicilio.value,
                dni_frontal: kyc_dni.dni_frontal.file,
                dni_reverso: kyc_dni.dni_reverso.file,
                dni_selfie: kyc_dni.dni_selfie.file,
                comprobante_domicilio: kyc_domicilio.comprobante_domicilio.file,
              },
              (err, resp) => {
                this.setState(
                  {
                    forms: {
                      ...this.state.forms,
                      configurar_solicitar_emprepack: false,
                    },
                    emprepack: {
                      ...this.state.emprepack,
                      customer_status: {
                        validate: false,
                        value: err ? -1 : 0,
                      },
                    },
                    alert: {
                      ...this.state.alert,
                      open: true,
                      message: err
                        ? resp.message
                        : 'La activación de Emprepack ha sido solicitada con éxito. Este proceso puede demorar hasta 1 semana.',
                      type: err ? 'error' : 'success',
                    },
                  },
                  () => {
                    //ver lo de emprepack
                  }
                );
              }
            );
          } else {
            this.setState({
              forms: {
                ...this.state.forms,
                configurar_solicitar_emprepack: false,
              },
              alert: {
                ...this.state.alert,
                open: true,
                message:
                  'Sube las fotos de tu DNI, del frente, reverso y selfie',
                type: 'error',
              },
            });
          }
        } else {
          this.setState({
            forms: {
              ...this.state.forms,
              configurar_solicitar_emprepack: false,
            },
            alert: {
              ...this.state.alert,
              open: true,
              message:
                'Sube un documento que compruebe tu domicilio y este a tu nombre para verificar la dirección',
              type: 'error',
            },
          });
        }
      } else {
        this.setState({
          forms: {
            ...this.state.forms,
            configurar_solicitar_emprepack: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: 'Debes seleccionar una sucursal de entrega',
            type: 'error',
          },
        });
      }
    } else {
      Validate.validar_formulario_actualizar_obj(
        configuraciones,
        (new_configuraciones) => {
          Validate.validar_formulario_actualizar_obj(
            emprepack,
            (new_emprepack) => {
              this.setState({
                forms: {
                  ...this.state.forms,
                  configurar_solicitar_emprepack: false,
                },
                emprepack: new_emprepack,
                configuraciones: new_configuraciones,
                alert: {
                  ...this.state.alert,
                  open: true,
                  message: 'Por favor, revisá tus datos, tenés algunos errores',
                  type: 'error',
                },
              });
            }
          );
        }
      );
    }
  };

  handleSubmitConfigurarEmprepack = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        configurar_solicitar_emprepack: true,
      },
    });

    let configuraciones = this.state.configuraciones;

    if (Validate.validar_formulario(configuraciones)) {
      this.props.metodoenvioConfigurarEmprepack(
        {
          me_emprepack: configuraciones.me_emprepack.value,
          me_emprepack_tipo: configuraciones.me_emprepack_tipo.value,
          me_emprepack_gratis_minimo:
            configuraciones.me_emprepack_gratis_minimo.value,
          me_emprepack_recargo_monto:
            configuraciones.me_emprepack_recargo_monto.value,
          me_emprepack_recargo_porcentaje:
            configuraciones.me_emprepack_recargo_porcentaje.value,
          me_emprepack_gratis_sucursal:
            configuraciones.me_emprepack_gratis_sucursal.value,
          me_emprepack_gratis_domicilio:
            configuraciones.me_emprepack_gratis_domicilio.value,
        },
        (err, resp) => {
          this.setState(
            {
              forms: {
                ...this.state.forms,
                configurar_solicitar_emprepack: false,
              },
              alert: {
                ...this.state.alert,
                open: true,
                message: resp.message,
                type: err ? 'error' : 'success',
              },
            },
            () => {
              if (!err && configuraciones.me_emprepack.value === 1) {
                createAmplitudeEventWithDevice(
                  method_shipment_amplitude_events.metodos_envio_activar
                    .event_name,
                  { metodo_envio: 'emprepack', success: true }
                );

                if (this.props.idCountry === 'ARG') {
                  braze.logCustomEvent('empretienda_metodos_envío_activar', {
                    metodo_envio: 'emprepack',
                  });
                }
              } else {
                createAmplitudeEventWithDevice(
                  method_shipment_amplitude_events.metodos_envio_desactivar
                    .event_name,
                  { metodo_envio: 'emprepack' }
                );
              }
            }
          );
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(
        configuraciones,
        (new_configuraciones) => {
          this.setState(
            {
              forms: {
                ...this.state.forms,
                configurar_solicitar_emprepack: false,
              },
              configuraciones: new_configuraciones,
              alert: {
                ...this.state.alert,
                open: true,
                message: 'Por favor, revisá tus datos, tenés algunos errores',
                type: 'error',
              },
            },
            () => {
              createAmplitudeEventWithDevice(
                method_shipment_amplitude_events.metodos_envio_activar
                  .event_name,
                { metodo_envio: 'emprepack', success: false }
              );
            }
          );
        }
      );
    }
  };

  handleSubmitEditarDomicilio = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        editar_domicilio: true,
      },
    });

    let { configuraciones, emprepack, kyc_domicilio } = this.state;

    if (
      Validate.validar_formulario(configuraciones) &&
      Validate.validar_formulario(emprepack)
    ) {
      if (this.state.configuraciones.me_emprepack_office_id.value > 0) {
        if (Validate.validar_formulario(kyc_domicilio)) {
          this.props.metodosenvioEditarDireccionEmprepack(
            {
              customer_street: emprepack.customer_street.value,
              customer_street_number: emprepack.customer_street_number.value,
              customer_floor: emprepack.customer_floor.value,
              customer_apartment: emprepack.customer_apartment.value,
              customer_city: emprepack.customer_city.value,
              customer_state: emprepack.customer_state.value,
              customer_zipcode: emprepack.customer_zipcode.value,
              me_emprepack_office_id:
                configuraciones.me_emprepack_office_id.value,
              comprobante_domicilio: kyc_domicilio.comprobante_domicilio.file,
            },
            (err, resp) => {
              this.setState({
                forms: {
                  ...this.state.forms,
                  editar_domicilio: false,
                },
                emprepack: {
                  ...this.state.emprepack,
                  customer_status: {
                    validate: false,
                    value: err ? -1 : 0,
                  },
                },
                modals: {
                  ...this.state.modals,
                  editar_domicilio: err ? true : false,
                },
                alert: {
                  ...this.state.alert,
                  open: true,
                  message: resp.message,
                  type: err ? 'error' : 'success',
                },
              });
            }
          );
        } else {
          this.setState({
            forms: {
              ...this.state.forms,
              editar_domicilio: false,
            },
            alert: {
              ...this.state.alert,
              open: true,
              message:
                'Sube un documento que compruebe tu domicilio y este a tu nombre para verificar la dirección',
              type: 'error',
            },
          });
        }
      } else {
        this.setState({
          forms: {
            ...this.state.forms,
            editar_domicilio: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: 'Debes seleccionar una sucursal de entrega',
            type: 'error',
          },
        });
      }
    } else {
      Validate.validar_formulario_actualizar_obj(emprepack, (new_emprepack) => {
        this.setState({
          forms: {
            ...this.state.forms,
            editar_domicilio: false,
          },
          emprepack: new_emprepack,
          alert: {
            ...this.state.alert,
            open: true,
            message: 'Por favor, revisá tus datos, tenés algunos errores',
            type: 'error',
          },
        });
      });
    }
  };

  handleSubmitEditarContacto = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        editar_contacto: true,
      },
    });

    let { emprepack, kyc_dni } = this.state;

    if (Validate.validar_formulario(emprepack)) {
      if (Validate.validar_formulario(kyc_dni)) {
        this.props.metodosenvioEditarContactoEmprepack(
          {
            customer_email: emprepack.customer_email.value,
            customer_name: emprepack.customer_name.value,
            customer_surname: emprepack.customer_surname.value,
            customer_identification: emprepack.customer_identification.value,
            customer_phone: emprepack.customer_phone.value,
            dni_frontal: kyc_dni.dni_frontal.file,
            dni_reverso: kyc_dni.dni_reverso.file,
            dni_selfie: kyc_dni.dni_selfie.file,
          },
          (err, resp) => {
            this.setState({
              forms: {
                ...this.state.forms,
                editar_contacto: false,
              },
              emprepack: {
                ...this.state.emprepack,
                customer_status: {
                  validate: false,
                  value: err ? -1 : 0,
                },
              },
              modals: {
                ...this.state.modals,
                editar_contacto: err ? true : false,
              },
              alert: {
                ...this.state.alert,
                open: true,
                message: resp.message,
                type: err ? 'error' : 'success',
              },
            });
          }
        );
      } else {
        this.setState({
          forms: {
            ...this.state.forms,
            editar_contacto: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: 'Sube las fotos de tu DNI, del frente, reverso y selfie',
            type: 'error',
          },
        });
      }
    } else {
      Validate.validar_formulario_actualizar_obj(emprepack, (new_emprepack) => {
        this.setState({
          forms: {
            ...this.state.forms,
            editar_contacto: false,
          },
          emprepack: new_emprepack,
          alert: {
            ...this.state.alert,
            open: true,
            message: 'Por favor, revisá tus datos, tenés algunos errores',
            type: 'error',
          },
        });
      });
    }
  };

  filtrarSucursales = () => {
    let customer_zipcode = this.state.emprepack.customer_zipcode.value;
    let sucursales = this.state.selects.sucursales;

    return customer_zipcode
      ? sucursales.filter((sucursal) => {
          return (
            sucursal.office_id === 0 ||
            customer_zipcode === sucursal.office_zipcode ||
            Validate.in_array(
              customer_zipcode,
              sucursal.office_enabled_zipcodes
            )
          );
        })
      : sucursales;
  };

  render() {
    let show_settings =
      this.state.emprepack.customer_status.value === -1 ||
      this.state.emprepack.customer_status.value > 0;

    let onSubmitFunc =
      this.state.emprepack.customer_status.value === -1
        ? this.handleSubmitSolicitarEmprepack
        : this.handleSubmitConfigurarEmprepack;

    return (
      <WrapperLayout
        loading={
          this.state.loading_medios_envio ||
          this.state.loading_datos_facturacion ||
          this.state.loading_emprepack ||
          this.state.loading_sucursales
        }
        error={this.state.loading_error}
      >
        <ModalDatosDomicilio
          emprepack={this.state.emprepack}
          configuraciones={this.state.configuraciones}
          handleSubmit={this.handleSubmitEditarDomicilio}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.editar_domicilio}
          form_state={this.state.forms.editar_domicilio}
          kyc_domicilio={this.state.kyc_domicilio}
          selects={this.state.selects}
          handleChange={this.handleChange}
          handleChangeSelect={this.handleChangeSelect}
          handleChangeDoc={this.handleChangeDoc}
          filtrarSucursales={this.filtrarSucursales}
          handleChangeZipCode={this.handleChangeZipCode}
        />
        <ModalDatosContacto
          emprepack={this.state.emprepack}
          handleSubmit={this.handleSubmitEditarContacto}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.editar_contacto}
          form_state={this.state.forms.editar_contacto}
          kyc_dni={this.state.kyc_dni}
          handleChange={this.handleChange}
          handleChangeDoc={this.handleChangeDoc}
        />
        <ModalTOS
          modal_state={this.state.modals.tos}
          modalHandler={this.modalHandler}
        />
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <form onSubmit={onSubmitFunc}>
              <ModuleTitleUI
                title="Emprepack"
                subtitles={[
                  <span>
                    Emprepack es una solución desarrollada por Empretienda para
                    poder utilizar el servicio de logística eCommerce de
                    Andreani.
                  </span>,
                  <span>
                    Podés ver más información del servicio haciendo click{' '}
                    <u>
                      <a
                        href="https://recursos.empretienda.com/emprepack.pdf"
                        target="_blank"
                        style={{ color: 'inherit' }}
                      >
                        aquí
                      </a>
                    </u>
                  </span>,
                ]}
                image={`${Config.CLOUDFRONT_CDN_PROD}/panel/logos-integraciones/emprepack-logo.png`}
                button={
                  <MenuConfiguracion
                    customer_status={this.state.emprepack.customer_status.value}
                    modalHandler={this.modalHandler}
                  />
                }
              />
              <ToggleUI
                show={
                  this.state.emprepack.customer_status.value === -1 &&
                  this.state.facturacion_activa === 0
                }
              >
                <DatosFacturacion />
              </ToggleUI>
              <ToggleUI show={this.state.emprepack.customer_status.value === 0}>
                <EstadoCuenta
                  customer_status={this.state.emprepack.customer_status.value}
                />
              </ToggleUI>
              <ToggleUI
                show={this.state.facturacion_activa === 1 && show_settings}
              >
                <EstadoCuenta
                  customer_status={this.state.emprepack.customer_status.value}
                />
                <CardModuleUI
                  below
                  show={this.state.emprepack.customer_status.value === -1}
                  with_toggle
                >
                  <DatosEnvioContacto
                    emprepack={this.state.emprepack}
                    kyc_dni={this.state.kyc_dni}
                    handleChange={this.handleChange}
                    handleChangeDoc={this.handleChangeDoc}
                  />
                </CardModuleUI>
                <CardModuleUI
                  below
                  show={this.state.emprepack.customer_status.value === -1}
                  with_toggle
                >
                  <DatosEnvioDomicilio
                    emprepack={this.state.emprepack}
                    configuraciones={this.state.configuraciones}
                    kyc_domicilio={this.state.kyc_domicilio}
                    selects={this.state.selects}
                    handleChange={this.handleChange}
                    handleChangeSelect={this.handleChangeSelect}
                    handleChangeDoc={this.handleChangeDoc}
                    filtrarSucursales={this.filtrarSucursales}
                    handleChangeZipCode={this.handleChangeZipCode}
                  />
                </CardModuleUI>
                <CardModuleUI below>
                  <DatosEnvioGratis
                    configuraciones={this.state.configuraciones}
                    selects={this.state.selects}
                    handleChange={this.handleChange}
                    handleChangeSelect={this.handleChangeSelect}
                    handleChangeCheckbox={this.handleChangeCheckbox}
                  />
                </CardModuleUI>
                <CardModuleUI below>
                  <DatosEnvioRecargo
                    configuraciones={this.state.configuraciones}
                    handleChange={this.handleChange}
                  />
                </CardModuleUI>
                <CardModuleUI below>
                  <Grid container spacing={1.5}>
                    <Grid item xs={12}>
                      <ModuleCardTitleUI
                        title="Términos y condiciones para activación y uso de Emprepack"
                        subtitles={[
                          <span>
                            Al activar Emprepack declaro que acepto los{' '}
                            <u>
                              <a
                                href="#!"
                                style={{
                                  color: 'inherit',
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.modalHandler(true, 'tos');
                                }}
                              >
                                términos y condiciones
                              </a>
                            </u>{' '}
                            del servicio y he leído el{' '}
                            <u>
                              <a
                                href="https://recursos.empretienda.com/emprepack.pdf"
                                target="_blank"
                                style={{
                                  color: 'inherit',
                                }}
                              >
                                documento
                              </a>
                            </u>{' '}
                            del mismo.
                          </span>,
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CheckboxUI
                        handleChangeCheckbox={this.handleChangeCheckbox}
                        checkbox={this.state.configuraciones.me_emprepack}
                      />
                    </Grid>
                  </Grid>
                </CardModuleUI>
                <ModuleButtonBoxUI>
                  <ButtonUI
                    type="submit"
                    label={
                      this.state.emprepack.customer_status.value === -1
                        ? 'Solicitar activación'
                        : 'Guardar'
                    }
                    minHeight={true}
                    isLoading={this.state.forms.configurar_solicitar_emprepack}
                  />
                </ModuleButtonBoxUI>
              </ToggleUI>
            </form>
          </Grid>
        </Grid>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_below: theme.card.card_below,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
});

const mapStateToProps = (state) => {
  return {
    metodos_envio: state.metodos_envio,
    idCountry: state.tienda.Country_idCountry,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    metodosenvioObtener: (callback) => dispatch(metodosenvioObtener(callback)),
    metodosenvioObtenerDatosEmprepack: (callback) =>
      dispatch(metodosenvioObtenerDatosEmprepack(callback)),
    metodosenvioObtenerSucursalesEmprepack: (data, callback) =>
      dispatch(metodosenvioObtenerSucursalesEmprepack(data, callback)),
    tiendaObtenerDatosFacturacion: (callback) =>
      dispatch(tiendaObtenerDatosFacturacion(callback)),
    metodosenvioSolicitarEmprepack: (data, callback) =>
      dispatch(metodosenvioSolicitarEmprepack(data, callback)),
    metodosenvioEditarDireccionEmprepack: (data, callback) =>
      dispatch(metodosenvioEditarDireccionEmprepack(data, callback)),
    metodosenvioEditarContactoEmprepack: (data, callback) =>
      dispatch(metodosenvioEditarContactoEmprepack(data, callback)),
    metodoenvioConfigurarEmprepack: (data, callback) =>
      dispatch(metodoenvioConfigurarEmprepack(data, callback)),
  };
};

export const Emprepack = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Emprepack_)
);
