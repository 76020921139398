import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';

const SelectUIOptions = (options, value_atribute, label_atribute) => {
  return options.map((option) => {
    return (
      <MenuItem
        value={option[value_atribute]}
        key={option[value_atribute]}
        style={{ fontFamily: option.font_family }}
      >
        {option[label_atribute]}
      </MenuItem>
    );
  });
};

const renderValue = (value, options, value_atribute, label_atribute) => {
  if (!options.length) {
    return '';
  } else {
    let selected_value = options.filter(
      (opt) => value === opt[value_atribute]
    )[0];
    return (
      <span style={{ fontFamily: selected_value.font_family }}>
        {selected_value[label_atribute]}
      </span>
    );
  }
};

const forceUpdateAndRenderWithFirstOption = (
  handleChangeSelect,
  value,
  name,
  change_param
) => {
  let fake_e = {
    target: {
      value: value,
      name: name,
    },
  };
  handleChangeSelect(fake_e, change_param);
};

const SelectFont_ = (props) => {
  let {
    classes,
    data,
    options,
    handleChangeSelect,
    value_atribute = 'value',
    label_atribute = 'label',
  } = props;

  let {
    //required,
    error,
    id,
    name,
    label,
    value,
    change_param = null,
    messages,
  } = data;

  //if value null and there is options
  //force the first option of array
  //to be choosen and update data.value
  //with its value
  if (value === null && options.length) {
    forceUpdateAndRenderWithFirstOption(
      handleChangeSelect,
      options[0][value_atribute],
      name,
      change_param
    );
  }

  //if value null, bypass first render and prevent null warning
  value = value === null ? '' : value;

  let select_props = {
    native: false,
    classes: { select: classes.selected },
    renderValue: (value) =>
      renderValue(value, options, value_atribute, label_atribute),
  };

  return (
    <TextField
      select
      id={id}
      fullWidth
      name={name}
      error={error}
      label={label}
      value={value}
      margin="dense"
      variant="outlined"
      SelectProps={select_props}
      InputLabelProps={{
        shrink: true,
      }}
      FormHelperTextProps={{
        className: classes.helper_text,
      }}
      onChange={(e) => handleChangeSelect(e, change_param)}
      helperText={error ? messages.error : messages.help}
    >
      {SelectUIOptions(options, value_atribute, label_atribute)}
    </TextField>
  );
};

const styles = (theme) => ({
  avatar: {
    width: '30px',
    height: '30px',
    margin: 0,
  },
  helper_text: {
    marginLeft: 0,
  },
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
});

SelectFont_.propTypes = {
  //Object used to add some styling with withStyles
  classes: PropTypes.object,

  //Data to make the select
  data: PropTypes.shape({
    validate: PropTypes.bool.isRequired, //determina si hay que validarlo en submit
    required: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    change_param: PropTypes.any, //adds a second parameter to handleChangeSelect if needed
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    messages: PropTypes.shape({
      help: PropTypes.string,
      error: PropTypes.string.isRequired,
    }),
  }),

  //The array of objects to display the options
  options: PropTypes.arrayOf(PropTypes.object).isRequired,

  //Function used to update the controlled select
  //handleChangeSelect(e,change_param = null)
  handleChangeSelect: PropTypes.func.isRequired,

  //true if there are images to show on options false if not
  //Default: true
  icons: PropTypes.bool,

  //The atribute of options we want to get the value of
  //Default: options.value
  value_atribute: PropTypes.string,

  //The atribute of options we want to show on label
  //Default: options.label
  label_atribute: PropTypes.string,
};

export const SelectFont = withStyles(styles)(SelectFont_);
