/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import {
  categoriasObtenerFlattenJerarquico,
  categoriaCrearOfertaMasiva,
  productoCrearOfertaMasiva,
} from '../../../store/actions';
import moment from 'moment';
import { Config, Validate, Regex, RegexExtra } from '../../../other';
import {
  Card,
  CardContent,
  Chip,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
  SelectUI,
  InputUI,
  ToggleUI,
  NextActionsUI,
  DateTimeUI,
  AutosuggestUI,
} from '../../common';
import { WrapperLayout } from '../../../layouts/WrapperLayout';

let init_date = moment().set('second', 0);

let primera_opcion_categoria = {
  idCategorias: 0,
  c_nombre: 'Seleccione una categoría',
  c_prefix: 'Seleccione una categoría',
  c_link_full: '',
  c_link: '',
  c_nombre_full: 'Seleccione una categoría',
  c_nombre_slash: 'Seleccione una categoría',
  c_padre: null,
  padres: [],
  hijos: [],
};

class AgregarOferta_ extends Component {
  state = {
    oferta_masiva: {
      tipo: {
        validate: false,
        required: true,
        error: false,
        label: 'Aplicar oferta sobre',
        id: 'tipo',
        name: 'tipo',
        change_param: 'oferta_masiva',
        value: 0,
        messages: {
          error: 'Por favor, seleccione una opción',
        },
      },
      idCategorias: {
        validate: false,
        required: true,
        error: false,
        label: 'Categoría',
        id: 'idCategorias',
        name: 'idCategorias',
        change_param: 'oferta_masiva',
        value: 0,
        messages: {
          error: '',
        },
      },
      aplicar_hijos: {
        validate: false,
        required: true,
        error: false,
        label: 'Aplicar a sus subcategorías',
        id: 'aplicar_hijos',
        name: 'aplicar_hijos',
        change_param: 'oferta_masiva',
        value: 1,
        messages: {
          error: 'Por favor, seleccione una opción',
        },
      },
      redondeo: {
        validate: false,
        required: true,
        error: false,
        label: 'Redondeo',
        id: 'redondeo',
        name: 'redondeo',
        change_param: 'oferta_masiva',
        value: 0,
        messages: {
          help: 'Podés determinar que los precios de los productos se redondeen con precisión sin cifras decimales. Por ejemplo, si el precio de oferta es $2.958,93, con redondeo y sin cifras decimales quedaría en $2.959,00. Tené en cuenta que esto podría afectar el porcentaje de la oferta',
          error: 'Por favor, seleccione una opción',
        },
      },
      p_oferta: {
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de oferta',
        id: 'p_oferta',
        name: 'p_oferta',
        change_param: 'oferta_masiva',
        value: 0,
        messages: {
          error: 'Por favor, seleccione una opción',
        },
      },
      porcentaje: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Porcentaje de oferta',
        id: 'porcentaje',
        name: 'porcentaje',
        change_param: 'oferta_masiva',
        regex: Regex.NUMERO_ENTERO_POSITIVO,
        value: '',
        default_value: '',
        placeholder: '15',
        messages: {
          help: 'Indica el porcentaje de descuento que se aplicará a los productos. Puede que en algunos casos, por cuestiones de redondeo a un máximo de 2 cifras decimales, tu producto figure con un porcentaje de oferta 1% menor ó mayor al ingresado',
          error: 'Por favor, ingrese un porcentaje válido',
          error_extra: RegexExtra.NUMERO_ENTERO_POSITIVO,
        },
      },
      p_oferta_fecha_inicio: {
        validate: false,
        momentobj: init_date.clone(),
        label: 'Desde',
        name: 'p_oferta_fecha_inicio',
        change_param: 'oferta_masiva',
      },
      p_oferta_fecha: {
        validate: false,
        momentobj: init_date.clone().add(1, 'days'),
        label: 'Hasta',
        name: 'p_oferta_fecha',
        change_param: 'oferta_masiva',
      },
      productos: {
        validate: false,
        selected: [],
      },
    },
    categorias: [],
    after: false,
    loading: {
      categorias: false,
      error: false,
    },
    forms: {
      oferta_masiva: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    selects: {
      categorias: [primera_opcion_categoria],
      tipo: [
        {
          value: 0,
          label: 'Seleccionar opción',
        },
        {
          value: 1,
          label: 'Una categoría',
        },
        {
          value: 2,
          label: 'Ciertos productos',
        },
      ],
      aplicar_hijos: [
        {
          value: 0,
          label: 'No',
        },
        {
          value: 1,
          label: 'Sí',
        },
      ],
      redondeo: [
        {
          value: 0,
          label: 'No',
        },
        {
          value: 1,
          label: 'Sí',
        },
      ],
      p_oferta: [
        {
          value: 0,
          label: 'Seleccionar opción',
        },
        {
          value: 1,
          label: 'Oferta por tiempo ilimitado',
        },
        {
          value: 2,
          label: 'Oferta con fecha de finalización',
        },
        {
          value: 3,
          label: 'Oferta con fecha de inicio y finalización',
        },
      ],
    },
    feed_input_value: '',
    feed: [],
  };

  //autosuggest
  debounceMethod = null;

  handleOnSuggestionSelected = (selected_suggestion) => {
    this.setState({
      oferta_masiva: {
        ...this.state.oferta_masiva,
        productos: {
          ...this.state.oferta_masiva.productos,
          selected: [
            ...this.state.oferta_masiva.productos.selected,
            selected_suggestion,
          ],
        },
      },
      feed_input_value: '',
    });
  };

  canBeOption = (id) => {
    return this.state.oferta_masiva.productos.selected.filter((option) => {
      return option.idProductos === id;
    }).length > 0
      ? false
      : true;
  };

  handleSuggestionFetchData = (value) => {
    const inputValue = Validate.trim_lowercase(value);
    if (inputValue.length > 1) {
      axios
        .get(`${Config.BACKEND_ENDPOINT}/producto`, {
          params: { search: inputValue },
        })
        .then((resp) => {
          //filtro las que ya existen en los chips para evitar duplicados
          let feed = resp.data.data.filter((option) => {
            return (
              this.canBeOption(option.idProductos) &&
              option.p_mostrar_precio === 1 &&
              option.p_tipo_venta !== 1
            );
          });

          this.setState({ feed: feed });
        })
        .catch((err) => {
          this.setState({
            alert: {
              ...this.state.alert,
              open: true,
              message: 'Ha ocurrido un error al buscar, intente nuevamente',
              type: 'error',
            },
          });
        });
    }
  };

  handleDeleteChip = (deleted_option) => {
    let selected = this.state.oferta_masiva.productos.selected.filter(
      (option) => {
        return option.idProductos !== deleted_option.idProductos;
      }
    );

    this.setState({
      oferta_masiva: {
        ...this.state.oferta_masiva,
        productos: {
          ...this.state.oferta_masiva.productos,
          selected: selected,
        },
      },
      feed_input_value: '',
    });
  };

  handleSuggestionsFetchRequested = ({ value }) => this.debounceMethod(value);

  handleSuggestionsClearRequested = () => this.setState({ feed: [] });

  handleSuggestionsChangeSelected = (e, { newValue }) =>
    this.setState({ feed_input_value: newValue });
  //end autosuggest

  componentDidMount() {
    this.debounceMethod = debounce(this.handleSuggestionFetchData, 500);
  }

  componentDidUpdate(prevProps) {
    const idCountry = this.props.tienda.Country_idCountry;

    if (prevProps !== this.props && idCountry === 'COL') {
      this.setState({
        oferta_masiva: {
          ...this.state.oferta_masiva,
          porcentaje: {
            ...this.state.oferta_masiva.porcentaje,
            messages: {
              ...this.state.oferta_masiva.porcentaje.messages,
              help: '',
            },
          },
        },
      });
    }
  }

  cargarCategorias = () => {
    this.setState({
      loading: {
        ...this.state.loading,
        categorias: true,
      },
    });
    this.props.categoriasObtenerFlattenJerarquico((err, resp) => {
      this.setState({
        loading: {
          ...this.state.loading,
          error: err ? true : this.state.loading.error,
          categorias: false,
        },
        selects: {
          ...this.state.selects,
          categorias: [primera_opcion_categoria, ...resp.data],
        },
        categorias: [...resp.data],
      });
    });
  };

  isButtonDisabled = () => {
    let { tipo, idCategorias, porcentaje, p_oferta, productos } =
      this.state.oferta_masiva;

    switch (tipo.value) {
      case 0:
        return true;

      case 1:
        return (
          idCategorias.value === 0 ||
          porcentaje.value === '' ||
          p_oferta.value === 0
        );

      case 2:
        return (
          productos.selected.length === 0 ||
          porcentaje.value === '' ||
          p_oferta.value === 0
        );

      default:
        break;
    }
  };

  handleChangeDate = (momentobj, name, aditional) => {
    this.setState({
      [aditional]: {
        ...this.state[aditional],
        [name]: {
          ...this.state[aditional][name],
          momentobj: momentobj,
        },
      },
    });
  };

  handleChangeSelect = (e, aditional) => {
    let name = e.target.name;
    let value = Validate.trim_int(e.target.value);

    this.setState(
      {
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        if (name === 'tipo' && value === 1) {
          this.cargarCategorias();
        }
      }
    );
  };

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      {
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: input,
            },
          });
        });
      }
    );
  };

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  crearOfertaCategoria = (body) => {
    this.props.categoriaCrearOfertaMasiva(
      {
        ...body,
      },
      (err, resp) => {
        this.setState({
          forms: {
            ...this.state.forms,
            oferta_masiva: false,
          },
          after: err ? false : true,
          alert: {
            ...this.state.alert,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          },
        });
      }
    );
  };

  crearOfertaProductos = (body) => {
    this.props.productoCrearOfertaMasiva(
      {
        ...body,
      },
      (err, resp) => {
        this.setState({
          forms: {
            ...this.state.forms,
            oferta_masiva: false,
          },
          after: err ? false : true,
          alert: {
            ...this.state.alert,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          },
        });
      }
    );
  };

  validateDates = (p_oferta, fecha1, fecha2) => {
    let resta_fechas = moment.duration(fecha2.diff(fecha1));

    fecha1 = Validate.moment_local2utc_format(fecha1);
    fecha2 = Validate.moment_local2utc_format(fecha2);

    if (!Validate.moment_comparar(fecha1, fecha2) && fecha1 !== fecha2) {
      this.setState({
        alert: {
          ...this.state.alert,
          open: true,
          type: 'error',
          message:
            p_oferta === 2
              ? 'La fecha de finalización debe ser mayor a la fecha actual'
              : 'La fecha desde no puede ser mayor a la fecha hasta',
        },
        forms: {
          ...this.state.forms,
          oferta_masiva: false,
        },
      });
      return false;
    } else if (
      resta_fechas.years() < 1 &&
      resta_fechas.months() < 1 &&
      resta_fechas.days() < 1 &&
      resta_fechas.hours() < 1 &&
      resta_fechas.minutes() >= 0 &&
      resta_fechas.minutes() < 5 &&
      resta_fechas.seconds() >= 0
    ) {
      this.setState({
        alert: {
          ...this.state.alert,
          open: true,
          type: 'error',
          message:
            p_oferta === 3
              ? 'Debe haber al menos 5 minutos de diferencia entre ambas fechas'
              : 'La fecha de fin debe tener al menos 5 minutos de diferencia con la fecha actual',
        },
        forms: {
          ...this.state.forms,
          oferta_masiva: false,
        },
      });
      return false;
    } else {
      return true;
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        oferta_masiva: true,
      },
    });

    let form = this.state.oferta_masiva;
    let porcentaje = parseInt(form.porcentaje.value);

    const idCountry = this.props.tienda.Country_idCountry;

    let body = {
      p_oferta: form.p_oferta.value,
      porcentaje: porcentaje,
    };

    if (idCountry === 'COL') {
      this.setState({
        oferta_masiva: {
          ...this.state.oferta_masiva,
          redondeo: {
            ...this.state.oferta_masiva.redondeo,
            value: 1,
          },
        },
      });
      body = {
        ...body,
        redondeo: 1,
      };
    } else {
      body = {
        ...body,
        redondeo: form.redondeo.value,
      };
    }

    if (Validate.validar_formulario(form)) {
      if (porcentaje >= 1 && porcentaje <= 99) {
        let tipo = form.tipo.value;

        if (tipo === 1) {
          body = {
            ...body,
            idCategorias: form.idCategorias.value,
            aplicar_hijos: form.aplicar_hijos.value,
          };
        } else if (tipo === 2) {
          let idProductos = this.state.oferta_masiva.productos.selected.map(
            (element) => {
              return element.idProductos;
            }
          );
          body = {
            ...body,
            idProductos: idProductos,
          };
        }

        let fecha_hasta = form.p_oferta_fecha.momentobj
          .clone()
          .set('milliseconds', 0)
          .set('seconds', 0);
        let valid_date;

        switch (form.p_oferta.value) {
          case 1:
            valid_date = true;
            break;

          case 2:
            let fecha_actual = moment()
              .set('milliseconds', 0)
              .set('seconds', 0);
            valid_date = this.validateDates(
              form.p_oferta.value,
              fecha_actual,
              fecha_hasta
            );
            if (valid_date) {
              body = {
                ...body,
                p_oferta_fecha: Validate.moment_local2utc(
                  form.p_oferta_fecha.momentobj.clone()
                ).format(Config.DATETIME_FORMAT),
              };
            }
            break;

          case 3:
            let fecha_desde = form.p_oferta_fecha_inicio.momentobj
              .clone()
              .set('milliseconds', 0)
              .set('seconds', 0);
            valid_date = this.validateDates(
              form.p_oferta.value,
              fecha_desde,
              fecha_hasta
            );
            if (valid_date) {
              body = {
                ...body,
                p_oferta_fecha_inicio: Validate.moment_local2utc(
                  form.p_oferta_fecha_inicio.momentobj.clone()
                ).format(Config.DATETIME_FORMAT),
                p_oferta_fecha: Validate.moment_local2utc(
                  form.p_oferta_fecha.momentobj.clone()
                ).format(Config.DATETIME_FORMAT),
              };
            }
            break;

          default:
            break;
        }
        if (valid_date) {
          tipo === 1
            ? this.crearOfertaCategoria(body)
            : this.crearOfertaProductos(body);
        }
      } else {
        this.setState({
          forms: {
            ...this.state.forms,
            oferta_masiva: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: 'El porcentaje debe estar entre 1 y 99',
            type: 'error',
          },
        });
      }
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          forms: {
            ...this.state.forms,
            oferta_masiva: false,
          },
          oferta_masiva: new_form,
        });
      });
    }
  };

  mostrarCategorias = () => {
    let classes = this.props.classes;
    if (this.state.oferta_masiva.idCategorias.value !== 0) {
      let selected_cat = this.state.categorias.filter(
        (categoria) =>
          categoria.idCategorias === this.state.oferta_masiva.idCategorias.value
      )[0];
      let selected_cat_hijos_chips = '';
      let text;

      if (this.state.oferta_masiva.aplicar_hijos.value === 0) {
        text =
          'La oferta se aplicará a todos los productos de la siguiente categoría, ya sea que el producto esté agregado como principal o secundario en la misma:';
      } else {
        text =
          'La oferta se aplicará a todos los productos de las siguientes categorías, ya sea que el producto esté agregado como principal o secundario en las mismas:';
        selected_cat_hijos_chips = selected_cat.hijos.map((hijo) => {
          let cat = this.state.categorias.filter(
            (cat) => cat.idCategorias === hijo
          )[0];
          return (
            <Chip
              className={classes.chip}
              component="span"
              color="primary"
              label={cat.c_nombre}
              key={hijo}
            />
          );
        });
      }

      return (
        <div className={classes.box}>
          <Typography>{text}</Typography>
          <Chip
            className={classes.chip}
            component="span"
            color="primary"
            label={selected_cat.c_nombre}
            key={selected_cat.idCategorias}
          />
          {selected_cat_hijos_chips}
        </div>
      );
    } else {
      return '';
    }
  };

  render() {
    let classes = this.props.classes;

    const idCountry = this.props.tienda.Country_idCountry;

    return (
      <WrapperLayout loading={false} error={this.state.loading.error}>
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <ToggleUI show={this.state.after}>
              <NextActionsUI
                title="¡La oferta ha sido creada con éxito!"
                buttons={[
                  <ButtonUI
                    type="link"
                    label="Ver mis ofertas"
                    link="/ofertas"
                  />,
                  <ButtonUI
                    type="link"
                    label="Crear otra oferta"
                    link="/ofertas/agregar"
                    external_link
                  />,
                ]}
              />
            </ToggleUI>
            <ToggleUI show={!this.state.after}>
              <form onSubmit={this.handleSubmit}>
                <ModuleTitleUI
                  title="Agregar oferta"
                  subtitles={[
                    'Te recomendamos ver tutorial de cómo agregar una oferta',
                  ]}
                  back_button
                  back_button_link="/ofertas"
                  button={
                    <ButtonUI
                      type="link"
                      label="Tutorial"
                      fullWidth={false}
                      link="https://youtu.be/MTbofpaIdxc"
                      external_link
                      open_new_tab
                    />
                  }
                />
                <Card className={classes.card}>
                  <CardContent className={classes.card_content}>
                    <Grid container>
                      <Grid item xs={12} p={1.5}>
                        <SelectUI
                          data={this.state.oferta_masiva.tipo}
                          options={this.state.selects.tipo}
                          handleChangeSelect={this.handleChangeSelect}
                        />
                      </Grid>
                      <ToggleUI
                        show={
                          this.state.oferta_masiva.tipo.value !== 0 &&
                          !this.state.loading.categorias
                        }
                      >
                        <ToggleUI
                          show={this.state.oferta_masiva.tipo.value === 1}
                        >
                          <Grid item lg={6} xs={12} p={1.5}>
                            <SelectUI
                              data={this.state.oferta_masiva.idCategorias}
                              options={this.state.selects.categorias}
                              handleChangeSelect={this.handleChangeSelect}
                              value_atribute="idCategorias"
                              label_atribute="c_nombre"
                            />
                          </Grid>
                          <Grid item lg={6} xs={12} p={1.5}>
                            <SelectUI
                              data={this.state.oferta_masiva.aplicar_hijos}
                              options={this.state.selects.aplicar_hijos}
                              handleChangeSelect={this.handleChangeSelect}
                            />
                          </Grid>
                          <ToggleUI
                            show={
                              this.state.oferta_masiva.tipo.value === 1 &&
                              this.state.oferta_masiva.idCategorias.value !== 0
                            }
                          >
                            <Grid item xs={12} p={1.5}>
                              {this.mostrarCategorias()}
                            </Grid>
                          </ToggleUI>
                        </ToggleUI>
                        <ToggleUI
                          show={this.state.oferta_masiva.tipo.value === 2}
                        >
                          <Grid item xs={12} p={1.5}>
                            <AutosuggestUI
                              suggestions={this.state.feed}
                              input_value={this.state.feed_input_value}
                              suggestion_selected={
                                this.state.oferta_masiva.productos.selected
                              }
                              multiple
                              suggestion_index={'p_nombre'}
                              label={'Productos'}
                              placeholder={'Ej: Remera Onix'}
                              handleOnSuggestionSelected={
                                this.handleOnSuggestionSelected
                              }
                              handleSuggestionsFetchRequested={
                                this.handleSuggestionsFetchRequested
                              }
                              handleSuggestionsClearRequested={
                                this.handleSuggestionsClearRequested
                              }
                              handleSuggestionsChangeSelected={
                                this.handleSuggestionsChangeSelected
                              }
                              handleDeleteChip={this.handleDeleteChip}
                            />
                          </Grid>
                        </ToggleUI>
                        <Grid item xs={12} p={1.5}>
                          <InputUI
                            handleChange={this.handleChange}
                            input={this.state.oferta_masiva.porcentaje}
                            end_adornment="%"
                          />
                        </Grid>
                        <ToggleUI show={idCountry !== 'COL'}>
                          <Grid item xs={12} p={1.5}>
                            <SelectUI
                              data={this.state.oferta_masiva.redondeo}
                              options={this.state.selects.redondeo}
                              handleChangeSelect={this.handleChangeSelect}
                            />
                          </Grid>
                        </ToggleUI>
                        <Grid item xs={12} p={1.5}>
                          <SelectUI
                            data={this.state.oferta_masiva.p_oferta}
                            options={this.state.selects.p_oferta}
                            handleChangeSelect={this.handleChangeSelect}
                          />
                        </Grid>
                        <ToggleUI
                          show={this.state.oferta_masiva.p_oferta.value === 3}
                        >
                          <Grid item sm={6} xs={12} p={1.5}>
                            <DateTimeUI
                              handleChangeDate={this.handleChangeDate}
                              inputdate={
                                this.state.oferta_masiva.p_oferta_fecha_inicio
                              }
                            />
                          </Grid>
                        </ToggleUI>
                        <ToggleUI
                          show={
                            this.state.oferta_masiva.p_oferta.value === 3 ||
                            this.state.oferta_masiva.p_oferta.value === 2
                          }
                        >
                          <Grid
                            item
                            sm={
                              this.state.oferta_masiva.p_oferta.value === 3
                                ? 6
                                : 12
                            }
                            xs={12}
                            p={1.5}
                          >
                            <DateTimeUI
                              handleChangeDate={this.handleChangeDate}
                              inputdate={
                                this.state.oferta_masiva.p_oferta_fecha
                              }
                            />
                          </Grid>
                        </ToggleUI>
                      </ToggleUI>
                      {this.state.loading.categorias ? (
                        <Grid item xs={12} p={1.5}>
                          <LinearProgress />
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </CardContent>
                </Card>
                <ModuleButtonBoxUI>
                  <ButtonUI
                    type="submit"
                    label="Agregar"
                    isLoading={this.state.forms.oferta_masiva}
                    minHeight={true}
                    disabled={this.isButtonDisabled()}
                  />
                </ModuleButtonBoxUI>
              </form>
            </ToggleUI>
          </Grid>
        </Grid>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
  text_link: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  box: {
    padding: '10px',
    backgroundColor: '#f3f3f3',
    borderRadius: '4px',
  },
  chip: theme.chip,
});

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    categoriasObtenerFlattenJerarquico: (callback) =>
      dispatch(categoriasObtenerFlattenJerarquico(callback)),
    categoriaCrearOfertaMasiva: (data, callback) =>
      dispatch(categoriaCrearOfertaMasiva(data, callback)),
    productoCrearOfertaMasiva: (data, callback) =>
      dispatch(productoCrearOfertaMasiva(data, callback)),
  };
};
export const AgregarOferta = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AgregarOferta_)
);
