/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { connect } from 'react-redux';
import { tiendaCloseAlertaMasiva } from '../../store/actions';
import { Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import green from '@mui/material/colors/green';
import amber from '@mui/material/colors/amber';
import IconButton from '@mui/material/IconButton';
import SnackbarContent from '@mui/material/SnackbarContent';
import { withStyles } from '@mui/styles';
import { ToggleUI } from './';

const MassiveAlertContent = ({ messages, classes }) => {
  return messages.map((message, index) => {
    if (message.type === 'text') {
      return (
        <span
          className={message.title ? classes.title : classes.text}
          key={index}
        >
          {message.text}
        </span>
      );
    } else {
      return (
        <span className={classes.text} key={index}>
          <a
            target="_blank"
            href={message.link}
            className={classes.link}
            rel="noopener noreferrer"
          >
            {message.text}
          </a>
        </span>
      );
    }
  });
};

const MassiveAlertUI_ = (props) => {
  let { classes, tiendaCloseAlertaMasiva, alerta, offMode } = props;
  let { close, type, messages } = alerta;

  return (
    <ToggleUI show={close === false && messages.length > 0 && !offMode}>
      <Grid item xs={12}>
        <div className={classes.snackbar}>
          <SnackbarContent
            className={`${classes[type]} ${classes.content}`}
            classes={{
              root: classes.snackbar_content,
              action: classes.snackbar_action,
            }}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
                <MassiveAlertContent messages={messages} classes={classes} />
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={tiendaCloseAlertaMasiva}
              >
                <CloseIcon className={classes.icon} />
              </IconButton>,
            ]}
          />
        </div>
      </Grid>
    </ToggleUI>
  );
};

const styles = (theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  message: {
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%',
    boxSizing: 'border-box',
    boxShadow: 'none',
  },
  text: {
    marginBottom: '5px',
    fontSize: '1rem',
    fontWeight: '500',
  },
  title: {
    marginBottom: '5px',
    fontSize: '1.2rem',
    fontWeight: '500',
  },
  link: {
    marginTop: '10px',
    display: 'inline-block',
    color: 'inherit',
    backgroundColor: 'rgba(0,0,0,0.2)',
    padding: '7.5px 10px',
    borderRadius: '4px',
  },
  snackbar: {
    width: '100%',
  },
  snackbar_content: {
    flexWrap: 'nowrap',
    alignItems: 'start',
  },
  snackbar_action: {
    marginTop: '10px',
  },
});

const mapStateToProps = (state) => {
  return {
    alerta: state.alerta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tiendaCloseAlertaMasiva: () => dispatch(tiendaCloseAlertaMasiva()),
  };
};

export const MassiveAlertUI = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MassiveAlertUI_)
);
