import React from 'react';
import SalesTableRow from './SalesTableRow';
import { TableBody } from '@mui/material';

const SalesTableBody = ({ ventas, classes, idCountry, modalHandler }) => {
  return (
    <TableBody>
      {ventas.map((venta, index) => {
        return (
          <SalesTableRow
            selectedShipment={JSON.parse(venta.v_arr_envio_seleccionado_json)}
            tableClass={
              ventas.length - 1 === index
                ? classes.table_cell_last
                : classes.table_cell
            }
            venta={venta}
            classes={classes}
            idCountry={idCountry}
            modalHandler={modalHandler}
            key={venta.v_numero}
          />
        );
      })}
    </TableBody>
  );
};

export default SalesTableBody;
