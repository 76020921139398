/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Grid } from '@mui/material';
import { ButtonUI, CustomCardUI } from '../../common';
import { Config } from '../../../other';

const RetiroLocal = (props) => {
  const { metodos_envio } = props;

  return (
    <Grid item lg={4} sm={6} xs={12}>
      <CustomCardUI
        title="Retiro en local"
        subtitle={
          metodos_envio.me_retiro_local ? 'Método activo' : 'Método no activo'
        }
        info="Podrás configurar direcciones de retiro para tus productos. Ya sea un local o domicilio. Luego de la compra tus cliente podrán seleccionar donde retirarla."
        image={`${Config.CLOUDFRONT_CDN_PROD}/panel/medios_envio/retirolocal-card-img.png`}
      >
        <ButtonUI
          type="link"
          link="/metodos-envio/locales"
          label="Configuración"
          size="large"
        />
      </CustomCardUI>
    </Grid>
  );
};

export { RetiroLocal };
