import React, { Component } from 'react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ButtonUI, ModuleTitleUI, CardModuleUI } from '../../common';
import {
  createAmplitudeEventWithDevice,
  import_amplitude_events,
} from '../../amplitude';
class ErrorInesperado_ extends Component {
  iniciarValidacion = () => {
    this.props.validarFormatoStart(); //pasar a la siguiente etapa: validarPlanilla
  };

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  componentDidMount() {
    createAmplitudeEventWithDevice(
      import_amplitude_events.confirmar_importacion_failed.event_name,
      import_amplitude_events.confirmar_importacion_failed.event_params
    );
  }

  render() {
    const { classes, handleVolverAApagar } = this.props;

    return (
      <Grid container>
        <Grid item xs={12}>
          <ModuleTitleUI title="Error inesperado" />
        </Grid>
        <Grid item xs={12}>
          <CardModuleUI>
            <Grid container>
              <Grid item xs={12}>
                <p className={classes.import_text}>
                  Lo sentimos, ocurrio un error al cargar tus productos{' '}
                </p>
                <p>
                  Se detectó un error inesperado que impide que continuemos el
                  proceso. Por favor vuelve a intentarlo.{' '}
                </p>
              </Grid>
            </Grid>
          </CardModuleUI>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: '20px' }}
          >
            <div className={classes.buttons_desktop}>
              <Grid item>
                <ButtonUI
                  fullWidth={false}
                  type="callf"
                  label="Volver al inicio"
                  onClickFunc={(e) => handleVolverAApagar()}
                />
              </Grid>
            </div>
            <div className={classes.buttons_mobile}>
              <Grid item>
                <ButtonUI
                  fullWidth={false}
                  type="callf"
                  label="Volver"
                  onClickFunc={(e) => handleVolverAApagar()}
                />
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  import_text: {
    fontWeight: '500',
    fontSize: '1.25rem',
    lineHeight: '23px',
    color: '#000000',
    marginBottom: '24px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '0px',
      fontSize: '0.87rem',
      lineHeight: '20px',
    },
  },
  buttons_desktop: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  buttons_mobile: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginBottom: '5px',
    },
  },
});

export const ErrorInesperado = withStyles(styles)(ErrorInesperado_);
