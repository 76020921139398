import React from 'react';
import {
  SwipeableDrawer,
  Grid,
  IconButton,
  Typography,
  Icon,
  Button,
  Box,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { SelectUI, ToggleUI, DateTimeUI } from '../../../components/common';
import { Validate } from '../../../other';
import RenderExcel from './RenderExcel';
import ButtonMui from '../../../components/common/ButtonMui';

export const FiltersDrawer = ({
  filtersState,
  selects,
  isFiltersDrawerOpen,
  isExcelDownloading,
  isSalesLoading,
  handleDrawerOpen,
  handleChangeSelect,
  cleanFilters,
  handleChangeDate,
  handleSubmitFilter,
  drawerType,
  exportData,
  idCountry,
}) => {
  const isIphone = Validate.is_iphone();
  const { fromDate, untilDate } = filtersState;

  let selects_v_metodo_pago = selects.v_metodo_pago;
  let selects_v_metodo_envio = selects.v_metodo_envio;

  if (idCountry === 'COL') {
    selects_v_metodo_pago = [
      {
        label: 'Todos',
        value: 0,
      },
      {
        label: 'Ualá Bis',
        value: 7,
      },
      {
        label: 'Acordar',
        value: 3,
      },
    ];

    selects_v_metodo_envio = [
      {
        value: 0,
        label: 'Todos',
      },
      {
        value: 3,
        label: 'Acordar',
      },
      {
        value: 7,
        label: 'Email',
      },
    ];
  }
  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={isFiltersDrawerOpen}
        onClose={() => handleDrawerOpen(false, drawerType)}
        onOpen={() => handleDrawerOpen(true, drawerType)}
        disableBackdropTransition={!isIphone}
        disableDiscovery={isIphone}
      >
        <Box width="300px" p="16px">
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={0.5}>
                <Grid item>
                  <IconButton
                    aria-label="Cancelar"
                    onClick={() => handleDrawerOpen(false, drawerType)}
                    size="large"
                  >
                    <Icon>clear</Icon>
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    {drawerType === 'filterSales' ? 'Filtrar' : 'Exportar'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SelectUI
                data={filtersState.v_metodo_pago}
                options={selects_v_metodo_pago}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectUI
                data={filtersState.v_estado_pago}
                options={selects.v_estado_pago}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectUI
                data={filtersState.v_metodo_envio}
                options={selects_v_metodo_envio}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectUI
                data={filtersState.v_estado_envio}
                options={selects.v_estado_envio}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectUI
                data={filtersState.v_archivada}
                options={selects.v_archivada}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectUI
                data={filtersState.v_mayorista}
                options={selects.v_mayorista}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectUI
                data={filtersState.v_factura}
                options={selects.v_factura}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
            <Grid item xs={12} mb={1}>
              <SelectUI
                data={filtersState.v_filtrar_fecha}
                options={selects.v_filtrar_fecha}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
            <ToggleUI show={filtersState.v_filtrar_fecha.value === 1}>
              <Grid item xs={6} mb={1}>
                <DateTimeUI
                  handleChangeDate={handleChangeDate}
                  inputdate={fromDate}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimeUI
                  handleChangeDate={handleChangeDate}
                  inputdate={untilDate}
                />
              </Grid>
            </ToggleUI>
            <Grid item xs={12}>
              <ButtonMui
                fullWidth
                isLoading={
                  drawerType === 'exportExcel'
                    ? isExcelDownloading
                    : isSalesLoading
                }
                onClick={() =>
                  handleSubmitFilter(drawerType, fromDate, untilDate)
                }
                data-testid={`boton-aplicar-exportar`}
              >
                {drawerType === 'exportExcel' ? 'Exportar' : 'Aplicar filtros'}
              </ButtonMui>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={cleanFilters}
                variant="outlined"
                fullWidth
                startIcon={<DeleteIcon />}
                data-testid={`boton-limpiar-filtros`}
              >
                Limpiar filtros
              </Button>
            </Grid>
          </Grid>
        </Box>
      </SwipeableDrawer>
      <RenderExcel data={exportData} idCountry={idCountry} />
    </>
  );
};
