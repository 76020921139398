import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';
import {
  ventaObtenerVenta,
  ventaCancelarVenta,
  ventaDevolverStock,
  ventaDevolverDinero,
  metodosenvioObtener,
  metodoenvioObtenerSucursalesAdmisionOca,
  ventaDarAltaEnvioOca,
  ventaDarAltaEnvioEmprepack,
  ventaNotificarEnvioColocarEnviado,
  ventaColocarComoRetirada,
  ventaColocarComoAbonada,
  ventaEnviarMensajeCliente,
  ventaEditarDatosFacturacion,
  ventaEditarDatosEnvio,
  ventaArchivarVentaDetail,
  ventaEntregarDescargables,
  ventaActualizacionManualMercadoPago,
  tiendaObtener,
  ventaAbonarEnvio,
  ventaCancelarEnvio,
  ventaMarcarComoFacturada,
} from '../../../../store/actions';
import { Grid, Icon } from '@mui/material';
import { AlertUI, ModuleTitleUI } from '../../../../components/common';
import { Config, Regex, RegexExtra, Validate } from '../../../../other';
import {
  sales_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../../../components/amplitude';
import { WrapperLayout } from '../../../../layouts/WrapperLayout';
import { ModalMessage } from './subcomponents/ModalMessage';
import { ModalCancelarOrden } from './subcomponents/ModalCancelarOrden';
import { ModalDevolverStock } from './subcomponents/ModalDevolverStock';
import { ModalDevolverDinero } from './subcomponents/ModalDevolverDinero';
import { ModalAltaEnvioOca } from './subcomponents/ModalAltaEnvioOca';
import { ModalNotificarEnvio } from './subcomponents/ModalNotificarEnvio';
import { ModalEntregarDescargables } from './subcomponents/ModalEntregarDescargables';
import { ModalColocarRetirada } from './subcomponents/ModalColocarRetirada';
import { ModalColocarAbonada } from './subcomponents/ModalColocarAbonada';
import { ModalMensajeOrden } from './subcomponents/ModalMensajeOrden';
import { ModalActualizarOrdenMercadoPago } from './subcomponents/ModalActualizarOrdenMercadoPago';
import { ModalArchivarOrden } from './subcomponents/ModalArchivarOrden';
import { ModalAbonarEnvio } from './subcomponents/ModalAbonarEnvio';
import { ModalAltaEnvioEmprepack } from './subcomponents/ModalAltaEnvioEmprepack';
import { ModalCancelarEnvio } from './subcomponents/ModalCancelarEnvio';
import { ModalEditarDatosFacturacion } from './subcomponents/ModalEditarDatosFacturacion';
import { ModalEditarDatosEnvio } from './subcomponents/ModalEditarDatosEnvio';
import { ModalMarcarComoFacturada } from './subcomponents/ModalMarcarComoFacturada';
import { OrderNavigationButtons } from './subcomponents/OrderNavigationButtons';
import { OrdenAcciones } from './subcomponents/OrdenAcciones';
import { OrdenOverview } from './subcomponents/OrdenOverview';
import { OrdenDetalle } from './subcomponents/OrdenDetalle';
import { OrdenInfoCliente } from './subcomponents/OrdenInfoCliente';
import { OrdenInfoEnvio } from './subcomponents/OrdenInfoEnvio';
import { OrdenInfoFacturacion } from './subcomponents/OrdenInfoFacturacion';

let init_date = moment().set('second', 0);

class VentaDetails_ extends Component {
  state = {
    //carga de datos
    loading_venta: true,
    loading_metodosenvio: true,
    loading_tienda: true,
    loading_sucursales: false,
    loading_error: false,

    data: {
      venta: {},
      cliente: {},
      ayuda: {},
    },

    label_cupon: '',

    //modales
    modals: {
      message: false,
      cancelar_orden: false,
      devolver_stock: false,
      devolver_dinero: false,
      alta_envio_oca_imprimir: false,
      notificar_envio_enviado: false,
      colocar_retirada: false,
      colocar_abonada: false,
      enviar_mensaje_cliente: false,
      editar_datos_facturacion: false,
      actualizacion_manual_mp: false,
      archivar_orden: false,
      entregar_descargables: false,
      abonar_envio: false,
      alta_envio_emprepack_imprimir: false,
      cancelar_envio: false,
      editar_datos_envio: false,
      marcar_como_facturada: false,
    },

    //forms
    forms: {
      cancelar_orden: false,
      devolver_stock: false,
      devolver_dinero: false,
      alta_envio_oca_imprimir: false,
      notificar_envio_enviado: false,
      colocar_retirada: false,
      colocar_abonada: false,
      enviar_mensaje_cliente: false,
      editar_datos_facturacion: false,
      actualizacion_manual_mp: false,
      archivar_orden: false,
      entregar_descargables: false,
      abonar_envio: false,
      alta_envio_emprepack_imprimir: false,
      cancelar_envio: false,
      editar_datos_envio: false,
      marcar_como_facturada: false,
    },

    //messages
    message: {
      description: '',
    },

    //cancelar orden
    cancelar_orden: {
      modal_subtitle: {
        validate: false,
        value: '',
      },
      cao_razon: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Motivo',
        id: 'cao_razon',
        name: 'cao_razon',
        change_param: 'cancelar_orden',
        regex: Regex.DESCRIPCION_0_500,
        value: '',
        default_value: '',
        placeholder: 'Ej: No hay stock',
        messages: {
          error: 'Por favor, ingrese una razón válida',
          help: 'Esta información le va a llegar al cliente junto con la notificación de la cancelación',
          error_extra: RegexExtra.DESCRIPCION_0_500,
        },
      },
    },

    //devolver stock
    devolver_stock: {},

    //devolver dinero
    devolver_dinero: {
      modal_subtitle: {
        validate: false,
        value: '',
      },
      devo_razon: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Motivo',
        id: 'devo_razon',
        name: 'devo_razon',
        change_param: 'devolver_dinero',
        regex: Regex.DESCRIPCION_0_500,
        value: '',
        default_value: '',
        placeholder: 'Ej: No hay stock',
        messages: {
          error: 'Por favor, ingrese una razón válida',
          help: 'Esta información le va a llegar al cliente junto con la notificación de la devolución',
          error_extra: RegexExtra.DESCRIPCION_0_500,
        },
      },
    },

    //alta envio oca
    alta_envio_oca_imprimir: {
      aoca_alta_finalizada: {
        validate: false,
        value: false,
      },
      aoca_nro_remito: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Número de remito',
        id: 'aoca_nro_remito',
        name: 'aoca_nro_remito',
        change_param: 'alta_envio_oca_imprimir',
        regex: Regex.NUMERO_ENTERO_POSITIVO,
        value: '',
        default_value: '',
        placeholder: 'Ej: Número de orden',
        messages: {
          error:
            'Por favor, ingrese un número de remito válido, puede ser el # de orden',
          error_extra: RegexExtra.NUMERO_ENTERO_POSITIVO,
        },
      },
      aoca_tipo_envio: {
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de envío',
        id: 'aoca_tipo_envio',
        name: 'aoca_tipo_envio',
        change_param: 'alta_envio_oca_imprimir',
        value: 1,
        messages: {
          error: '',
        },
      },
      aoca_franja_horaria_retiro: {
        validate: false,
        required: true,
        error: false,
        label: 'Franja horaria para el retiro',
        id: 'aoca_franja_horaria_retiro',
        name: 'aoca_franja_horaria_retiro',
        change_param: 'alta_envio_oca_imprimir',
        value: 1,
        messages: {
          error: '',
        },
      },
      aoca_fecha_retiro: {
        validate: false,
        momentobj: init_date,
        label: 'Fecha de retiro',
        name: 'aoca_fecha_retiro',
        change_param: 'alta_envio_oca_imprimir',
      },
      aoca_fecha_entrega: {
        validate: false,
        momentobj: init_date,
        label: 'Fecha de entrega',
        name: 'aoca_fecha_entrega',
        change_param: 'alta_envio_oca_imprimir',
      },
      aoca_sucursal_entrega: {
        validate: false,
        required: true,
        error: false,
        label: 'Sucursal de entrega',
        id: 'aoca_sucursal_entrega',
        name: 'aoca_sucursal_entrega',
        change_param: 'alta_envio_oca_imprimir',
        value: null,
        messages: {
          error: '',
        },
      },
      aoca_embalaje: {
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de embalaje',
        id: 'aoca_embalaje',
        name: 'aoca_embalaje',
        change_param: 'alta_envio_oca_imprimir',
        value: 1,
        messages: {
          error: '',
        },
      },
    },

    //alta envio emprepack
    alta_envio_emprepack_imprimir: {
      aemp_alta_finalizada: {
        validate: false,
        value: false,
      },
    },

    //notificar el envío al cliente y colocar como enviado
    notificar_envio_enviado: {
      modal_subtitle: {
        validate: false,
        value: '',
      },
      neo_razon: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Mensaje para el cliente',
        id: 'neo_razon',
        name: 'neo_razon',
        change_param: 'notificar_envio_enviado',
        regex: Regex.DESCRIPCION_0_5000,
        value: '',
        default_value: '',
        placeholder: 'Ej: Hemos enviado el paquete...',
        messages: {
          error: 'Por favor, ingrese un mensaje válido',
          help: 'Esta información le va a llegar al cliente junto con la notificación de envío',
          error_extra: RegexExtra.DESCRIPCION_0_500,
        },
      },
    },

    //entrego descargables
    entregar_descargables: {
      edo_mensaje: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Mensaje',
        id: 'edo_mensaje',
        name: 'edo_mensaje',
        change_param: 'entregar_descargables',
        regex: Regex.DESCRIPCION_0_5000,
        value: '',
        default_value: '',
        placeholder: 'Mensaje',
        messages: {
          error: 'Por favor, ingrese un mensaje válido',
          help: 'Este mensaje se agregara al correo, también puedes usarlo para agregar links',
          error_extra: RegexExtra.DESCRIPCION_0_500,
        },
      },
    },

    //coloco como retirada
    colocar_retirada: {},

    //coloco como abonada
    colocar_abonada: {},

    //archivar orden
    archivar_orden: {},

    //enviar mensaje al cliente
    enviar_mensaje_cliente: {
      emo_mensaje: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Mensaje para el cliente',
        id: 'emo_mensaje',
        name: 'emo_mensaje',
        change_param: 'enviar_mensaje_cliente',
        regex: Regex.DESCRIPCION_20_5000,
        value: '',
        default_value: '',
        placeholder: 'Mensaje',
        messages: {
          error: 'Por favor, ingrese un mensaje válido',
          help: 'Este mensaje le va a llegar al cliente junto con el detalle de la orden',
          error_extra: RegexExtra.DESCRIPCION_20_5000,
        },
      },
    },

    //editar datos de facturacion
    editar_datos_facturacion: {
      modal_subtitle: {
        validate: false,
        value: '',
      },
      v_numero: {
        validate: false,
        value: 0,
      },
      v_facturacion_nombre: {
        auth: true,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nombre',
        id: 'v_facturacion_nombre',
        name: 'v_facturacion_nombre',
        change_param: 'editar_datos_facturacion',
        regex: Regex.NOMBRE_2_45,
        value: '',
        default_value: '',
        placeholder: 'Nombre',
        messages: {
          error: 'Por favor, ingrese un nombre válido',
          error_extra: RegexExtra.NOMBRE_2_45,
        },
      },
      v_facturacion_apellido: {
        auth: true,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Apellido',
        id: 'v_facturacion_apellido',
        name: 'v_facturacion_apellido',
        change_param: 'editar_datos_facturacion',
        regex: Regex.NOMBRE_2_45,
        value: '',
        default_value: '',
        placeholder: 'Apellido',
        messages: {
          error: 'Por favor, ingrese un apellido válido',
          error_extra: RegexExtra.NOMBRE_2_45,
        },
      },
      v_facturacion_numero_identificacion: {
        auth: true,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Documento',
        id: 'v_facturacion_numero_identificacion',
        name: 'v_facturacion_numero_identificacion',
        change_param: 'editar_datos_facturacion',
        regex: Regex.CUIT_GUION_OPCIONAL,
        value: '',
        default_value: '',
        placeholder: 'Documento',
        messages: {
          error: 'Por favor, ingrese un número de identificación válido',
          error_extra: RegexExtra.CUIT_GUION_OPCIONAL,
        },
      },
      v_facturacion_tipo_identificacion: {
        auth: true,
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de documento',
        id: 'v_facturacion_tipo_identificacion',
        name: 'v_facturacion_tipo_identificacion',
        change_param: 'editar_datos_facturacion',
        value: null,
        default_value: null,
        messages: {
          error: '',
        },
      },
      v_facturacion_calle: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Calle',
        id: 'v_facturacion_calle',
        name: 'v_facturacion_calle',
        change_param: 'editar_datos_facturacion',
        regex: Regex.CALLE_1_30,
        value: '',
        default_value: '',
        placeholder: 'Calle',
        messages: {
          error: 'Por favor, ingrese una calle válida',
          error_extra: RegexExtra.CALLE_1_30,
        },
      },
      v_facturacion_numero: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Número',
        id: 'v_facturacion_numero',
        name: 'v_facturacion_numero',
        change_param: 'editar_datos_facturacion',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: 'Número',
        messages: {
          error: 'Por favor, ingrese un número válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      v_facturacion_piso: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Piso',
        id: 'v_facturacion_piso',
        name: 'v_facturacion_piso',
        change_param: 'editar_datos_facturacion',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: 'Piso',
        messages: {
          error: 'Por favor, ingrese un piso válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      v_facturacion_dpto: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Dpto',
        id: 'v_facturacion_dpto',
        name: 'v_facturacion_dpto',
        change_param: 'editar_datos_facturacion',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: 'Dpto',
        messages: {
          error: 'Por favor, ingrese un dpto válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      v_facturacion_ciudad: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Ciudad',
        id: 'v_facturacion_ciudad',
        name: 'v_facturacion_ciudad',
        change_param: 'editar_datos_facturacion',
        regex: Regex.CALLE_1_30,
        value: '',
        default_value: '',
        placeholder: 'Ciudad',
        messages: {
          error: 'Por favor, ingrese una ciudad válida',
          error_extra: RegexExtra.CALLE_1_30,
        },
      },
      v_facturacion_provincia: {
        validate: false,
        required: false,
        error: false,
        label: 'Provincia',
        id: 'v_facturacion_provincia',
        name: 'v_facturacion_provincia',
        change_param: 'editar_datos_facturacion',
        value: '',
        messages: {
          error: '',
        },
      },
      v_facturacion_postal: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Código postal',
        id: 'v_facturacion_postal',
        name: 'v_facturacion_postal',
        change_param: 'editar_datos_facturacion',
        regex: Regex.CODIGO_POSTAL,
        value: '',
        default_value: '',
        placeholder: 'Código postal',
        messages: {
          error: 'Por favor, ingrese un código postal válido',
          error_extra: RegexExtra.CODIGO_POSTAL,
        },
      },
    },

    //enviar mensaje al cliente
    actualizacion_manual_mp: {
      amao_numero_operacion: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Número de operación',
        id: 'amao_numero_operacion',
        name: 'amao_numero_operacion',
        change_param: 'actualizacion_manual_mp',
        regex: Regex.NUMERO_ENTERO,
        value: '',
        default_value: '',
        placeholder: 'XXXXXXXXXXX',
        messages: {
          error:
            'Por favor, ingrese un número de operación de Mercado Pago válido',
          help: 'Es el número de operación de Mercado Pago que figura en el detalle del pago (no es la referencia externa). Ingresar sin #.',
          error_extra: RegexExtra.NUMERO_ENTERO,
        },
      },
    },

    //abonar envio
    abonar_envio: {
      modal_subtitle: {
        validate: false,
        value: '',
      },
      modal_title: {
        validate: false,
        value: '',
      },
      modal_button: {
        validate: false,
        value: '',
      },
      abonar_envio_metodo_pago_emprepack: {
        validate: false,
        required: true,
        error: false,
        label: 'Método de pago',
        id: 'abonar_envio_metodo_pago_emprepack',
        name: 'abonar_envio_metodo_pago_emprepack',
        change_param: 'abonar_envio',
        value: 5,
        messages: {
          error: '',
        },
      },
    },

    //cancelo un envio
    cancelar_envio: {},

    //editar datos de envio
    editar_datos_envio: {
      modal_subtitle: {
        validate: false,
        value:
          'Al editar los datos de envío, debes tener en cuenta que si editas localidad o provincia, puede que tu envío termine teniendo un costo diferente al tarifado por la tienda.',
      },
      v_numero: {
        validate: false,
        value: 0,
      },
      v_envio_nombre: {
        auth: true,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nombre',
        id: 'v_envio_nombre',
        name: 'v_envio_nombre',
        change_param: 'editar_datos_envio',
        regex: Regex.NOMBRE_2_45,
        value: '',
        default_value: '',
        placeholder: 'Nombre',
        messages: {
          error: 'Por favor, ingrese un nombre válido',
          error_extra: RegexExtra.NOMBRE_2_45,
        },
      },
      v_envio_apellido: {
        auth: true,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Apellido',
        id: 'v_envio_apellido',
        name: 'v_envio_apellido',
        change_param: 'editar_datos_envio',
        regex: Regex.NOMBRE_2_45,
        value: '',
        default_value: '',
        placeholder: 'Apellido',
        messages: {
          error: 'Por favor, ingrese un apellido válido',
          error_extra: RegexExtra.NOMBRE_2_45,
        },
      },
      v_envio_numero_identificacion: {
        auth: true,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Documento',
        id: 'v_envio_numero_identificacion',
        name: 'v_envio_numero_identificacion',
        change_param: 'editar_datos_envio',
        regex: Regex.DOCUMENTO,
        value: '',
        default_value: '',
        placeholder: 'Documento',
        messages: {
          error: 'Por favor, ingrese un número de identificación válido',
          error_extra: RegexExtra.DOCUMENTO,
        },
      },
      v_envio_tipo_identificacion: {
        auth: true,
        validate: false,
        required: true,
        error: false,
        label: 'Tipo de documento',
        id: 'v_envio_tipo_identificacion',
        name: 'v_envio_tipo_identificacion',
        change_param: 'editar_datos_envio',
        value: null,
        default_value: null,
        messages: {
          error: '',
        },
      },
      v_envio_telefono: {
        auth: true,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Teléfono',
        id: 'v_envio_telefono',
        name: 'v_envio_telefono',
        change_param: 'editar_datos_envio',
        regex: Regex.TELEFONO_COMPUESTO,
        value: '',
        default_value: '',
        placeholder: 'Teléfono',
        messages: {
          error: 'Por favor, ingrese un número de teléfono válido',
          error_extra: RegexExtra.TELEFONO_COMPUESTO,
        },
      },
      v_envio_calle: {
        auth: true,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Calle',
        id: 'v_envio_calle',
        name: 'v_envio_calle',
        change_param: 'editar_datos_envio',
        regex: Regex.CALLE_1_30,
        value: '',
        default_value: '',
        placeholder: 'Calle',
        messages: {
          error: 'Por favor, ingrese una calle válida',
          error_extra: RegexExtra.CALLE_1_30,
        },
      },
      v_envio_numero: {
        auth: true,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Número',
        id: 'v_envio_numero',
        name: 'v_envio_numero',
        change_param: 'editar_datos_envio',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: 'Número',
        messages: {
          error: 'Por favor, ingrese un número válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      v_envio_piso: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Piso',
        id: 'v_envio_piso',
        name: 'v_envio_piso',
        change_param: 'editar_datos_envio',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: 'Piso',
        messages: {
          error: 'Por favor, ingrese un piso válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      v_envio_dpto: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Dpto',
        id: 'v_envio_dpto',
        name: 'v_envio_dpto',
        change_param: 'editar_datos_envio',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: 'Dpto',
        messages: {
          error: 'Por favor, ingrese un dpto válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      v_envio_ciudad: {
        auth: true,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Ciudad',
        id: 'v_envio_ciudad',
        name: 'v_envio_ciudad',
        change_param: 'editar_datos_envio',
        regex: Regex.CALLE_1_30,
        value: '',
        default_value: '',
        placeholder: 'Ciudad',
        messages: {
          error: 'Por favor, ingrese una ciudad válida',
          error_extra: RegexExtra.CALLE_1_30,
        },
      },
      v_envio_provincia: {
        validate: false,
        required: true,
        error: false,
        label: 'Provincia',
        id: 'v_envio_provincia',
        name: 'v_envio_provincia',
        change_param: 'editar_datos_envio',
        value: '',
        messages: {
          error: '',
        },
      },
      v_envio_postal: {
        auth: true,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Código postal',
        id: 'v_envio_postal',
        name: 'v_envio_postal',
        change_param: 'editar_datos_envio',
        regex: Regex.CODIGO_POSTAL,
        value: '',
        default_value: '',
        placeholder: 'Código postal',
        messages: {
          error: 'Por favor, ingrese un código postal válido',
          error_extra: RegexExtra.CODIGO_POSTAL,
        },
      },
      v_observacion: {
        auth: true,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Observación',
        id: 'v_observacion',
        name: 'v_observacion',
        change_param: 'editar_datos_envio',
        regex: Regex.DESCRIPCION_0_5000,
        value: '',
        default_value: '',
        placeholder: 'Observación',
        messages: {
          error: 'Por favor, ingrese una observación válida',
          error_extra: RegexExtra.DESCRIPCION_0_5000,
        },
      },
    },

    marcar_como_facturada: {
      modal_subtitle: {
        validate: false,
        value:
          'Con esta opción podés marcar la orden como facturada para llevar un mejor control. Opcionalmente podés adjuntar la factura al cliente y en caso de ser asi, la factura es enviada por email.',
      },
      v_numero: {
        validate: false,
        value: 0,
      },
      v_factura_pdf: {
        validate: false,
        auth: false,
        required: false,
        id: 'v_factura_pdf',
        name: 'v_factura_pdf',
        change_param: 'marcar_como_facturada',
        file: null,
        messages: {
          help: '',
          error: '',
        },
      },
    },

    //selects
    selects: {
      aoca_tipo_envio: [
        {
          value: 1,
          label: 'Lo entrego personalmente en sucursal OCA',
        },
        {
          value: 0,
          label: 'Quiero que lo retiren en mi domicilio',
        },
      ],
      aoca_franja_horaria_retiro: [
        {
          value: 1,
          label: 'De 8hs a 17hs',
        },
        {
          value: 2,
          label: 'De 8hs a 12hs',
        },
        {
          value: 3,
          label: 'De 14hs a 17hs',
        },
      ],
      aoca_sucursal_entrega: [],
      aoca_embalaje: [
        {
          value: 1,
          label: 'Envio todo en sus paquetes por separado',
        },
        {
          value: 0,
          label: 'Envio todo en un mismo paquete',
        },
      ],
      abonar_envio_metodo_pago_emprepack: [
        {
          value: 5,
          label: 'Abonar con Mercado Pago',
        },
        {
          value: 4,
          label: 'Abonar con Mobbex',
        },
      ],
      v_facturacion_tipo_identificacion: [
        {
          value: 'DNI',
          label: 'DNI',
        },
        {
          value: 'CUIT',
          label: 'CUIT',
        },
      ],
      v_provincia: [
        {
          value: 'BUENOS AIRES',
          label: 'BUENOS AIRES',
        },
        {
          value: 'CAPITAL FEDERAL',
          label: 'CAPITAL FEDERAL',
        },
        {
          value: 'CATAMARCA',
          label: 'CATAMARCA',
        },
        {
          value: 'CHACO',
          label: 'CHACO',
        },
        {
          value: 'CHUBUT',
          label: 'CHUBUT',
        },
        {
          value: 'CORDOBA',
          label: 'CORDOBA',
        },
        {
          value: 'CORRIENTES',
          label: 'CORRIENTES',
        },
        {
          value: 'ENTRE RIOS',
          label: 'ENTRE RIOS',
        },
        {
          value: 'FORMOSA',
          label: 'FORMOSA',
        },
        {
          value: 'JUJUY',
          label: 'JUJUY',
        },
        {
          value: 'LA PAMPA',
          label: 'LA PAMPA',
        },
        {
          value: 'LA RIOJA',
          label: 'LA RIOJA',
        },
        {
          value: 'MENDOZA',
          label: 'MENDOZA',
        },
        {
          value: 'MISIONES',
          label: 'MISIONES',
        },
        {
          value: 'NEUQUEN',
          label: 'NEUQUEN',
        },
        {
          value: 'RIO NEGRO',
          label: 'RIO NEGRO',
        },
        {
          value: 'SALTA',
          label: 'SALTA',
        },
        {
          value: 'SAN JUAN',
          label: 'SAN JUAN',
        },
        {
          value: 'SAN LUIS',
          label: 'SAN LUIS',
        },
        {
          value: 'SANTA CRUZ',
          label: 'SANTA CRUZ',
        },
        {
          value: 'SANTA FE',
          label: 'SANTA FE',
        },
        {
          value: 'SGO. DEL ESTERO',
          label: 'SGO. DEL ESTERO',
        },
        {
          value: 'TIERRA DEL FUEGO',
          label: 'TIERRA DEL FUEGO',
        },
        {
          value: 'TUCUMAN',
          label: 'TUCUMAN',
        },
      ],
    },

    //alerta
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  componentDidMount() {
    let v_numero = this.props.id;
    this.props.ventaObtenerVenta(
      {
        v_numero: v_numero,
      },
      (err, resp) => {
        let data = resp.data;
        this.setState({
          loading_venta: false,
          loading_error: this.state.loading_error || err ? true : false,
          data: err ? this.state.data : data,
          label_cupon: err ? '' : data.cupon,
        });
      }
    );

    this.props.metodosenvioObtener((err, resp) => {
      this.setState({
        loading_metodosenvio: false,
        loading_error: this.state.loading_error || err ? true : false,
      });
    });

    this.props.tiendaObtener((err, resp) => {
      this.setState({
        loading_tienda: false,
        loading_error: this.state.loading_error || err ? true : false,
      });
    });

    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams.back_topic && queryParams.back_method) {
      if (queryParams.back_topic === 'shipment_payment') {
        let status = '';
        switch (queryParams.back_method) {
          case 'mercadopago':
            if (queryParams.collection_status === 'approved') {
              status = 'paid';
            } else if (queryParams.collection_status === 'pending') {
              status = 'pending';
            }
            break;
          case 'mobbex':
            if (Validate.in_array(queryParams.status, [200, 300, 301])) {
              status = 'paid';
            } else if (Validate.in_array(queryParams.status, [2])) {
              status = 'pending';
            }
            break;
          default:
            break;
        }

        if (status === 'paid') {
          this.setState({
            modals: {
              ...this.state.modals,
              alta_envio_emprepack_imprimir: true,
            },
          });
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              message: true,
            },
            message: {
              ...this.state.message,
              description:
                status === 'pending'
                  ? 'Debes terminar de abonar tu envío para poder imprimir la etiqueta'
                  : 'Tu pago no ha podido ser procesado, por favor, intenta nuevamente',
            },
          });
        }
      }
    }
  }

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      {
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: input,
            },
          });
        });
      }
    );
  };

  handleChangeDoc = (err, data) => {
    let { change_param, name } = data;

    this.setState({
      [change_param]: {
        ...this.state[change_param],
        [name]: data,
      },
    });
  };

  handleChangeSelect = (e, aditional) => {
    let name = e.target.name;
    let select_type_string = Validate.in_array(name, [
      'v_facturacion_provincia',
      'v_envio_provincia',
      'v_facturacion_tipo_identificacion',
    ]);
    let value = select_type_string
      ? Validate.trim(e.target.value)
      : Validate.trim_int(e.target.value);

    this.setState({
      [aditional]: {
        ...this.state[aditional],
        [name]: {
          ...this.state[aditional][name],
          value: value,
        },
      },
    });
  };

  handleChangeDate = (momentobj, name, aditional) => {
    this.setState({
      [aditional]: {
        ...this.state[aditional],
        [name]: {
          ...this.state[aditional][name],
          momentobj: momentobj,
        },
      },
    });
  };

  //abre y cierra los modales
  modalHandler = (open, modal, description) => {
    switch (modal) {
      case 'message':
        if (open) {
          this.setState({
            modals: {
              ...this.state.modals,
              [modal]: open,
            },
            message: {
              ...this.state.message,
              description: description,
            },
          });
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              [modal]: open,
            },
          });
        }
        break;

      case 'cancelar_orden':
        if (open) {
          this.setState({
            modals: {
              ...this.state.modals,
              [modal]: open,
            },
            cancelar_orden: {
              ...this.state.cancelar_orden,
              modal_subtitle: {
                ...this.state.cancelar_orden.modal_subtitle,
                value: description,
              },
            },
          });
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              [modal]: open,
            },
          });
        }
        break;

      case 'abonar_envio':
        if (open) {
          const epick = this.state.data.venta.v_metodo_envio === 9;
          this.setState({
            modals: {
              ...this.state.modals,
              [modal]: open,
            },
            abonar_envio: {
              ...this.state.abonar_envio,
              modal_subtitle: {
                ...this.state.abonar_envio.modal_subtitle,
                value: description,
              },
              modal_title: {
                ...this.state.abonar_envio.modal_title,
                value: epick
                  ? 'Serás redirigido a e-pick.com.ar'
                  : 'Abonar envío',
              },
              modal_button: {
                ...this.state.abonar_envio.modal_button,
                value: epick ? 'CONFIRMAR' : 'ABONAR',
              },
            },
          });
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              [modal]: open,
            },
          });
        }
        break;

      case 'alta_envio_oca_imprimir':
        if (open) {
          this.setState(
            {
              loading_sucursales: true,
            },
            () => {
              this.props.metodoenvioObtenerSucursalesAdmisionOca(
                (err, resp) => {
                  if (err) {
                    this.setState({
                      loading_sucursales: false,
                      alert: {
                        ...this.state.alert,
                        open: true,
                        message: resp.message,
                        type: 'error',
                      },
                    });
                  } else {
                    this.setState({
                      modals: {
                        ...this.state.modals,
                        [modal]: open,
                      },
                      loading_sucursales: false,
                      alta_envio_oca_imprimir: {
                        ...this.state.alta_envio_oca_imprimir,
                        aoca_sucursal_entrega: {
                          ...this.state.alta_envio_oca_imprimir
                            .aoca_sucursal_entrega,
                          value: resp.data.length ? resp.data[0].value : null,
                        },
                      },
                      selects: {
                        ...this.state.selects,
                        aoca_sucursal_entrega: resp.data,
                      },
                    });
                  }
                }
              );
            }
          );
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              [modal]: open,
            },
            loading_sucursales: false,
          });
        }
        break;

      case 'notificar_envio_enviado':
        if (open) {
          this.setState({
            modals: {
              ...this.state.modals,
              [modal]: open,
            },
            notificar_envio_enviado: {
              ...this.state.notificar_envio_enviado,
              modal_subtitle: {
                ...this.state.notificar_envio_enviado.modal_subtitle,
                value: description,
              },
            },
          });
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              [modal]: open,
            },
          });
        }
        break;

      case 'editar_datos_facturacion':
        if (open) {
          let facturacion = this.state.data.venta;

          Validate.cargar_formulario_sin_nulos(
            facturacion,
            this.state.editar_datos_facturacion,
            (editar_obj) => {
              this.setState({
                editar_datos_facturacion: editar_obj,
                modals: {
                  ...this.state.modals,
                  [modal]: open,
                },
              });
            }
          );
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              [modal]: open,
            },
          });
        }
        break;

      case 'editar_datos_envio':
        if (open) {
          let envio = this.state.data.venta;

          Validate.cargar_formulario_sin_nulos(
            envio,
            this.state.editar_datos_envio,
            (editar_obj) => {
              this.setState({
                editar_datos_envio: editar_obj,
                modals: {
                  ...this.state.modals,
                  [modal]: open,
                },
              });
            }
          );
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              [modal]: open,
            },
          });
        }
        break;

      case 'marcar_como_facturada':
        if (open) {
          let venta = this.state.data.venta;

          Validate.cargar_formulario_sin_nulos(
            venta,
            this.state.marcar_como_facturada,
            (editar_obj) => {
              this.setState({
                marcar_como_facturada: editar_obj,
                modals: {
                  ...this.state.modals,
                  [modal]: open,
                },
              });
            }
          );
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              [modal]: open,
            },
          });
        }
        break;

      case 'imprimir_orden_compra':
        this.handleSubmitImprimirOrdenCompra();
        break;

      case 'devolver_dinero':
        if (open) {
          this.setState({
            modals: {
              ...this.state.modals,
              [modal]: open,
            },
            devolver_dinero: {
              ...this.state.devolver_dinero,
              modal_subtitle: {
                ...this.state.devolver_dinero.modal_subtitle,
                value: description,
              },
            },
          });
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              [modal]: open,
            },
          });
        }
        break;

      //devolver stock, colocar retirada, cancelar envio
      //colocar abonada, enviar mensaje cliente, entregar descargables
      //archivar orden, abonar envio emprepack, alta envio e imprimir etiqueta emprepack
      default:
        this.setState({
          modals: {
            ...this.state.modals,
            [modal]: open,
          },
        });
        break;
    }
  };

  //cierra las alertas
  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  //cancelar orden
  handleSubmitCancelarOrden = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        cancelar_orden: true,
      },
    });

    let form = this.state.cancelar_orden;

    if (Validate.validar_formulario(form)) {
      this.props.ventaCancelarVenta(
        {
          cao_razon: form.cao_razon.value,
          v_numero: this.state.data.venta.v_numero,
        },
        (err, resp) => {
          this.setState(
            {
              forms: {
                ...this.state.forms,
                cancelar_orden: false,
              },
              alert: {
                ...this.state.alert,
                open: true,
                message: resp.message,
                type: err ? 'error' : 'success',
              },
              modals: {
                ...this.state.modals,
                cancelar_orden: err ? true : false,
              },
              data: err ? this.state.data : resp.data,
            },
            () => {
              createAmplitudeEventWithDevice(
                sales_amplitude_events.ventas_cancelar_orden.event_name
              );
            }
          );
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          forms: {
            ...this.state.forms,
            cancelar_orden: false,
          },
          cancelar_orden: new_form,
        });
      });
    }
  };

  //devolver stock
  handleSubmitDevolverStock = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        devolver_stock: true,
      },
    });

    this.props.ventaDevolverStock(
      {
        v_numero: this.state.data.venta.v_numero,
      },
      (err, resp) => {
        this.setState({
          forms: {
            ...this.state.forms,
            devolver_stock: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          },
          modals: {
            ...this.state.modals,
            devolver_stock: err ? true : false,
          },
          data: err
            ? this.state.data
            : {
                ...this.state.data,
                venta: resp.data.venta,
                ayuda: resp.data.ayuda,
              },
        });
      }
    );
  };

  //devolver dinero
  handleSubmitDevolverDinero = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        devolver_dinero: true,
      },
    });

    let form = this.state.devolver_dinero;

    if (Validate.validar_formulario(form)) {
      this.props.ventaDevolverDinero(
        {
          devo_razon: form.devo_razon.value,
          v_numero: this.state.data.venta.v_numero,
        },
        (err, resp) => {
          this.setState({
            forms: {
              ...this.state.forms,
              devolver_dinero: false,
            },
            alert: {
              ...this.state.alert,
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            },
            modals: {
              ...this.state.modals,
              devolver_dinero: err ? true : false,
            },
            data: err ? this.state.data : resp.data,
          });
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          forms: {
            ...this.state.forms,
            devolver_dinero: false,
          },
          devolver_dinero: new_form,
        });
      });
    }
  };

  //alta de envio oca
  handleSubmitAltaEnvioOca = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        alta_envio_oca_imprimir: true,
      },
    });

    let form = this.state.alta_envio_oca_imprimir;

    if (Validate.validar_formulario(form)) {
      this.props.ventaDarAltaEnvioOca(
        {
          v_numero: this.state.data.venta.v_numero,
          aoca_nro_remito: form.aoca_nro_remito.value,
          aoca_tipo_envio: form.aoca_tipo_envio.value,
          aoca_franja_horaria_retiro: form.aoca_franja_horaria_retiro.value,
          aoca_fecha_retiro:
            form.aoca_fecha_retiro.momentobj.format('YYYYMMDD'),
          aoca_fecha_entrega:
            form.aoca_fecha_entrega.momentobj.format('YYYYMMDD'),
          aoca_sucursal_entrega: form.aoca_sucursal_entrega.value,
          aoca_embalaje: form.aoca_embalaje.value,
        },
        (err, resp) => {
          this.setState({
            forms: {
              ...this.state.forms,
              alta_envio_oca_imprimir: false,
            },
            alert: {
              ...this.state.alert,
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            },
            alta_envio_oca_imprimir: {
              ...this.state.alta_envio_oca_imprimir,
              aoca_alta_finalizada: {
                validate: false,
                value: err ? false : true,
              },
            },
            data: err ? this.state.data : resp.data,
          });
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          forms: {
            ...this.state.forms,
            alta_envio_oca_imprimir: false,
          },
          alta_envio_oca_imprimir: new_form,
          alert: {
            ...this.state.alert,
            open: true,
            message: 'Hay algunos campos con errores, revisalos',
            type: 'error',
          },
        });
      });
    }
  };

  //notificar envio al cliente y colocar enviada
  handleSubmitNotificarEnvioColocarEnviada = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        notificar_envio_enviado: true,
      },
    });

    let form = this.state.notificar_envio_enviado;

    if (Validate.validar_formulario(form)) {
      this.props.ventaNotificarEnvioColocarEnviado(
        {
          neo_razon: form.neo_razon.value,
          v_numero: this.state.data.venta.v_numero,
        },
        (err, resp) => {
          this.setState({
            forms: {
              ...this.state.forms,
              notificar_envio_enviado: false,
            },
            alert: {
              ...this.state.alert,
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            },
            modals: {
              ...this.state.modals,
              notificar_envio_enviado: err ? true : false,
            },
            data: err ? this.state.data : resp.data,
          });
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          forms: {
            ...this.state.forms,
            notificar_envio_enviado: false,
          },
          notificar_envio_enviado: new_form,
        });
      });
    }
  };

  //entrego los archivos autodescargables
  handleSubmitEntregarDescargables = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        entregar_descargables: true,
      },
    });

    let form = this.state.entregar_descargables;

    if (Validate.validar_formulario(form)) {
      this.props.ventaEntregarDescargables(
        {
          edo_mensaje: form.edo_mensaje.value,
          v_numero: this.state.data.venta.v_numero,
        },
        (err, resp) => {
          this.setState({
            forms: {
              ...this.state.forms,
              entregar_descargables: false,
            },
            alert: {
              ...this.state.alert,
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            },
            modals: {
              ...this.state.modals,
              entregar_descargables: err ? true : false,
            },
            data: err ? this.state.data : resp.data,
          });
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          forms: {
            ...this.state.forms,
            entregar_descargables: false,
          },
          entregar_descargables: new_form,
        });
      });
    }
  };

  //marco una orden como retirada
  handleSubmitColocarRetirada = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        colocar_retirada: true,
      },
    });

    this.props.ventaColocarComoRetirada(
      {
        v_numero: this.state.data.venta.v_numero,
      },
      (err, resp) => {
        this.setState(
          {
            forms: {
              ...this.state.forms,
              colocar_retirada: false,
            },
            alert: {
              ...this.state.alert,
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            },
            modals: {
              ...this.state.modals,
              colocar_retirada: err ? true : false,
            },
            data: err
              ? this.state.data
              : {
                  ...this.state.data,
                  venta: resp.data.venta,
                  ayuda: resp.data.ayuda,
                },
          },
          () => {
            if (!err) {
              createAmplitudeEventWithDevice(
                sales_amplitude_events.ventas_orden_retirada.event_name
              );
            }
          }
        );
      }
    );
  };

  //marco una orden como abonada
  handleSubmitColocarAbonada = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        colocar_abonada: true,
      },
    });

    this.props.ventaColocarComoAbonada(
      {
        v_numero: this.state.data.venta.v_numero,
      },
      (err, resp) => {
        this.setState(
          {
            forms: {
              ...this.state.forms,
              colocar_abonada: false,
            },
            alert: {
              ...this.state.alert,
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            },
            modals: {
              ...this.state.modals,
              colocar_abonada: err ? true : false,
            },
            data: err
              ? this.state.data
              : {
                  ...this.state.data,
                  venta: resp.data.venta,
                  ayuda: resp.data.ayuda,
                },
          },
          () => {
            if (!err) {
              createAmplitudeEventWithDevice(
                sales_amplitude_events.ventas_orden_abonada.event_name
              );
            }
          }
        );
      }
    );
  };

  //envio un mensaje al cliente
  handleSubmitEnviarMensaje = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        enviar_mensaje_cliente: true,
      },
    });

    let form = this.state.enviar_mensaje_cliente;

    if (Validate.validar_formulario(form)) {
      this.props.ventaEnviarMensajeCliente(
        {
          emo_mensaje: form.emo_mensaje.value,
          v_numero: this.state.data.venta.v_numero,
        },
        (err, resp) => {
          this.setState({
            forms: {
              ...this.state.forms,
              enviar_mensaje_cliente: false,
            },
            alert: {
              ...this.state.alert,
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            },
            modals: {
              ...this.state.modals,
              enviar_mensaje_cliente: err ? true : false,
            },
            data: err ? this.state.data : resp.data,
          });
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          forms: {
            ...this.state.forms,
            enviar_mensaje_cliente: false,
          },
          enviar_mensaje_cliente: new_form,
        });
      });
    }
  };

  //editar datos de facturacion
  handleSubmitEditarDatosFacturacion = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        editar_datos_facturacion: true,
      },
    });

    let form = this.state.editar_datos_facturacion;

    if (Validate.validar_formulario(form)) {
      this.props.ventaEditarDatosFacturacion(
        {
          v_numero: this.state.data.venta.v_numero,
          v_facturacion_nombre: form.v_facturacion_nombre.value,
          v_facturacion_apellido: form.v_facturacion_apellido.value,
          v_facturacion_tipo_identificacion:
            form.v_facturacion_tipo_identificacion.value,
          v_facturacion_numero_identificacion:
            form.v_facturacion_numero_identificacion.value,
          v_facturacion_calle: form.v_facturacion_calle.value,
          v_facturacion_numero: form.v_facturacion_numero.value,
          v_facturacion_piso: form.v_facturacion_piso.value,
          v_facturacion_dpto: form.v_facturacion_dpto.value,
          v_facturacion_ciudad: form.v_facturacion_ciudad.value,
          v_facturacion_provincia: form.v_facturacion_provincia.value,
          v_facturacion_postal: form.v_facturacion_postal.value,
        },
        (err, resp) => {
          this.setState(
            {
              forms: {
                ...this.state.forms,
                editar_datos_facturacion: false,
              },
              alert: {
                ...this.state.alert,
                open: true,
                message: resp.message,
                type: err ? 'error' : 'success',
              },
              modals: {
                ...this.state.modals,
                editar_datos_facturacion: err ? true : false,
              },
              data: {
                ...this.state.data,
                venta: err ? this.state.data.venta : resp.data,
              },
            },
            () => {
              if (!err) {
                createAmplitudeEventWithDevice(
                  sales_amplitude_events.ventas_editar_datos_facturacion
                    .event_name
                );
              }
            }
          );
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          forms: {
            ...this.state.forms,
            editar_datos_facturacion: false,
          },
          editar_datos_facturacion: new_form,
        });
      });
    }
  };

  //editar datos de envio
  handleSubmitEditarDatosEnvio = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        editar_datos_envio: true,
      },
    });

    let form = this.state.editar_datos_envio;

    if (Validate.validar_formulario(form)) {
      this.props.ventaEditarDatosEnvio(
        {
          v_numero: this.state.data.venta.v_numero,
          v_envio_nombre: form.v_envio_nombre.value,
          v_envio_apellido: form.v_envio_apellido.value,
          v_envio_numero_identificacion:
            form.v_envio_numero_identificacion.value,
          v_envio_tipo_identificacion: form.v_envio_tipo_identificacion.value,
          v_envio_telefono: form.v_envio_telefono.value,
          v_envio_calle: form.v_envio_calle.value,
          v_envio_numero: form.v_envio_numero.value,
          v_envio_piso: form.v_envio_piso.value,
          v_envio_dpto: form.v_envio_dpto.value,
          v_envio_ciudad: form.v_envio_ciudad.value,
          v_envio_provincia: form.v_envio_provincia.value,
          v_envio_postal: form.v_envio_postal.value,
          v_observacion: form.v_observacion.value,
        },
        (err, resp) => {
          this.setState(
            {
              forms: {
                ...this.state.forms,
                editar_datos_envio: false,
              },
              alert: {
                ...this.state.alert,
                open: true,
                message: resp.message,
                type: err ? 'error' : 'success',
              },
              modals: {
                ...this.state.modals,
                editar_datos_envio: err ? true : false,
              },
              data: {
                ...this.state.data,
                venta: err ? this.state.data.venta : resp.data,
              },
            },
            () => {
              if (!err) {
                createAmplitudeEventWithDevice(
                  sales_amplitude_events.ventas_editar_datos_envio.event_name
                );
              }
            }
          );
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          forms: {
            ...this.state.forms,
            editar_datos_envio: false,
          },
          editar_datos_envio: new_form,
        });
      });
    }
  };

  //marcar como facturada
  handleSubmitMarcarComoFacturada = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        marcar_como_facturada: true,
      },
    });

    let form = this.state.marcar_como_facturada;

    if (Validate.validar_formulario(form)) {
      this.props.ventaMarcarComoFacturada(
        {
          v_numero: this.state.data.venta.v_numero,
          v_factura_pdf: form.v_factura_pdf.file,
        },
        (err, resp) => {
          this.setState(
            {
              forms: {
                ...this.state.forms,
                marcar_como_facturada: false,
              },
              alert: {
                ...this.state.alert,
                open: true,
                message: resp.message,
                type: err ? 'error' : 'success',
              },
              modals: {
                ...this.state.modals,
                marcar_como_facturada: err ? true : false,
              },
              data: {
                ...this.state.data,
                venta: err ? this.state.data.venta : resp.data,
              },
            },
            () => {
              if (!err) {
                createAmplitudeEventWithDevice(
                  sales_amplitude_events.ventas_orden_facturada.event_name
                );
              }
            }
          );
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          forms: {
            ...this.state.forms,
            marcar_como_facturada: false,
          },
          marcar_como_facturada: new_form,
        });
      });
    }
  };

  //imprimir orden de compra
  handleSubmitImprimirOrdenCompra = () => {
    let { tienda } = this.props;
    let { venta, ayuda, cliente } = this.state.data;
    let envio_seleccionado = JSON.parse(venta.v_arr_envio_seleccionado_json);
    let printWindow = window.open();
    createAmplitudeEventWithDevice(
      sales_amplitude_events.ventas_imprimir_orden_compra.event_name
    );
    printWindow.document.write('<!DOCTYPE html><html>');
    let html = '<head>';
    html += `
            <style type="text/css">
                @page {size:auto;margin:0mm;}
                @media print
                {
                    body {-webkit-print-color-adjust: exact;}
                }
                .iorden-container
                {
                    width:95%;
                    font-family: sans-serif;
                    margin-left:auto;
                    margin-right:auto;
                    margin-top:30px;
                    display:block;
                }
                .iorden-header,.iorden-subheader
                {
                    width: 100%;
                    display: flex;
                    padding: 0px;
                    margin: 0px;
                    box-sizing: border-box;
                }
                .iorden-header
                {
                    align-items: center;
                }
                .iorden-productos,.iorden-productos-table
                {
                    width: 100%;
                    padding: 0px;
                    margin: 0px;
                    box-sizing: border-box;
                }
                .iorden-productos-table
                {
                    text-align: center;
                    border-collapse: collapse;
                }
                .iorden-productos-table thead
                {
                    font-weight: bold;
                }
                .iorden-productos-table td, .iorden-productos-table th
                {
                    padding: 1px;
                }
                .iorden-productos-detail, .iorden-productos-nombre
                {
                    text-align: left;
                }
                table {
                    border: 0px solid transparent;
                }
                th, td {
                    border: 1px solid #888;
                }
                .iorden-productos
                {
                    display:block;
                }
                .iorden-header-left
                {
                    flex: 1.5;
                }
                .iorden-header-right,.iorden-subheader-right,.iorden-subheader-left
                {
                    flex: 1;
                }
                .iorden-header-left,.iorden-header-right
                {
                    padding: 0px;
                    margin: 0px;
                    overflow:hidden;
                    box-sizing:border-box;
                }
                .iorden-header-right
                {
                    text-align: right;
                }
                .iorden-orden-title
                {
                    font-size:25px;
                    padding:0;
                    margin:0px;
                }
                .iorden-orden-subtitle
                {
                    font-size:20px;
                    padding:0;
                    margin:0px;
                }
                .iorden-orden-subsubtitle
                {
                    font-size:15px;
                    padding:0;
                    margin:0px;
                }
                .iorden-header-subinfo
                {
                    font-weight: 500;
                    font-size: 15px;
                    margin:0px 0px 2px 0px;
                    padding:0;
                }
                .iorden-logo-wrapper
                {
                    display: flex;
                    justify-content: flex-end;
                }
                #iorden-logo-tienda
                {
                    width: auto;
                    height: auto;
                    max-height:75px;
                    max-width:200px;
                    display:block;
                }
                .divider
                {
                    margin: 10px 0px;
                    height: 1px;
                    background-color: #888;
                    display: block;
                }
                .iorden-footer
                {
                    margin-top: 15px;
                }
                .iorden-footer-text
                {
                    text-align: center;
                    font-size: 11px;
                    padding: 0px;
                    margin: 0px;
                }
            </style>
        `;
    html += '</head>';

    html += '<body>';
    html += '<div class="iorden-container">';

    //direccion de envio (si no es retiro o mail)
    let direccion_envio = '';

    const idCountry = this.props.idCountry;

    const direccionEnvio = () => {
      if (idCountry === 'COL') {
        return `<p class="iorden-header-subinfo"><strong>Dirección:</strong>
        ${Validate.trim_ucwords(venta.v_envio_calle)} - ${
          venta.v_envio_numero
        }, 
        ${
          venta.v_envio_dpto
            ? Validate.trim_ucwords(venta.v_envio_dpto) + ','
            : ''
        } 
        ${Validate.trim_ucwords(venta.v_envio_ciudad)}, ${
          venta.v_envio_provincia
        }
          </p>`;
      } else {
        return `<p class="iorden-header-subinfo"><strong>Dirección:</strong>
      ${Validate.trim_ucwords(venta.v_envio_calle)} ${venta.v_envio_numero}
      Piso: ${
        venta.v_envio_piso ? Validate.trim_ucwords(venta.v_envio_piso) : '-'
      }
      Dpto: ${
        venta.v_envio_dpto ? Validate.trim_ucwords(venta.v_envio_dpto) : '-'
      }
      , ${Validate.trim_ucwords(venta.v_envio_ciudad)}, ${
          venta.v_envio_provincia
        }
      , CP ${venta.v_envio_postal}
        </p>`;
      }
    };

    if (venta.v_metodo_envio !== 2 && venta.v_metodo_envio !== 7) {
      direccion_envio += direccionEnvio();
    }

    //reviso si hay una sucursal
    let sucursal_html =
      !Validate.in_array(venta.v_metodo_envio, [1, 2, 3, 5, 7, 9]) &&
      venta.v_metodo_envio_subtipo === 1
        ? '<p class="iorden-header-subinfo"><strong>Sucursal destino:</strong> ' +
          envio_seleccionado.descripcion
            .splice(0, envio_seleccionado.descripcion.length - 1)
            .join(' - ') +
          '</p>'
        : '';

    //reviso si debo mostrar el peso
    let peso_html =
      venta.v_metodo_envio === 8
        ? '<p class="iorden-header-subinfo"><strong>Peso total:</strong> ' +
          Validate.obtener_peso_venta(venta).toFixed(2) +
          'kg</p>'
        : '';

    //reviso si hay observaciones
    let observaciones = venta.v_observacion
      ? '<div class="iorden-subheader" style="margin-bottom: 10px;"><div class="iorden-subheader-left"><p class="iorden-header-subinfo"><strong>Observaciones:</strong> ' +
        venta.v_observacion +
        '</p></div></div>'
      : '';

    html += `
            <div class="iorden-header">
                <div class="iorden-header-left">
                    <h3 class="iorden-orden-title">Orden: #${
                      venta.v_numero
                    }</h3>
                    <p class="iorden-orden-subsubtitle"><strong>Fecha:</strong> ${Validate.utc2local_format(
                      venta.v_fecha,
                      Config.DATE_FORMAT
                    )}</p>
                    <p class="iorden-orden-subsubtitle"><strong>Pago:</strong> ${Validate.label_metodo_pago(
                      venta.v_metodo_pago
                    )} (${Validate.label_estado_pago(venta.v_estado_pago)})</p>
                </div>
                <div class="iorden-header-right">
                    ${
                      tienda.t_logo
                        ? '<div class="iorden-logo-wrapper"><img id="iorden-logo-tienda" src="' +
                          Config.CLOUDFRONT_LOGOS_CDN +
                          tienda.t_logo +
                          '"/></div>'
                        : '<h4 class="iorden-orden-subtitle">' +
                          tienda.t_nombre +
                          '</h4>'
                    }
                </div>
            </div>
            <div class="divider"></div>
            <div class="iorden-subheader">
                <div class="iorden-subheader-left">
                    <p class="iorden-header-subinfo"><strong>Método de envío:</strong> ${
                      ayuda.metodo_envio.nombre
                    }</p>
                    ${sucursal_html}
                    ${peso_html}
                </div>
            </div>
            <div class="divider"></div>
            <div class="iorden-subheader">
                <div class="iorden-subheader-left">
                    <p class="iorden-header-subinfo"><strong>${
                      venta.v_metodo_envio === 2 ? 'Retira:' : 'Recibe:'
                    }</strong> ${
      venta.v_envio_nombre
        ? Validate.trim_ucwords(
            venta.v_envio_nombre + ' ' + venta.v_envio_apellido
          )
        : ''
    }</p>
                    <p class="iorden-header-subinfo"><strong>Teléfono:</strong> ${
                      venta.v_envio_telefono
                    }${
      venta.v_envio_tipo_identificacion
        ? ' - <strong>' +
          venta.v_envio_tipo_identificacion +
          ':</strong> ' +
          venta.v_envio_numero_identificacion
        : ''
    }</p>
                    <p class="iorden-header-subinfo"><strong>Email:</strong> ${
                      cliente.cl_email
                    }</p>
                    ${direccion_envio}
                </div>
            </div>
            <div class="divider"></div>
            ${observaciones}
            <div class="iorden-productos">
                <table class="iorden-productos-table">
                    <thead>
                        <th>Código</th>
                        <th>Cantidad</th>
                        <th>Descripción</th>
                        <th>P. unitario</th>
                        <th>Total</th>
                    <thead>
                    <tbody>
                        ${this.cargarDetalleCompraImprimir(
                          venta,
                          envio_seleccionado
                        )}
                    </tbody>
                </table>
            </div>
            <div class="iorden-footer">
                ${
                  tienda.t_direccion
                    ? '<p class="iorden-footer-text">' +
                      Validate.trim_ucwords(tienda.t_direccion) +
                      '</p>'
                    : ''
                }
                <p class="iorden-footer-text">Email: ${tienda.t_email}${
      tienda.t_telefono ? ' | Tel: ' + tienda.t_telefono : ''
    } | Web: https://${this.props.dominios.d_nombre}</p>
            </div>
        `;

    html += '</div>';
    html += '</body>';

    printWindow.document.write(html);
    printWindow.document.write('</html>');
    if (tienda.t_logo) {
      let img = printWindow.document.getElementById('iorden-logo-tienda');
      img.onload = function () {
        printWindow.document.close();
        printWindow.print();
      };
    } else {
      printWindow.document.close();
      printWindow.print();
    }
  };

  cargarDetalleCompraImprimir = (venta, envio_seleccionado) => {
    let i, precio, j;
    let productos = JSON.parse(venta.v_arr_carrito_json);
    let cantidad_productos = 0;
    let total = venta.v_monto * 1;
    let newHtml = '';
    const idCountry = this.props.idCountry;

    for (i in productos) {
      cantidad_productos += productos[i].cantidad;
      newHtml += '<tr>';
      precio = productos[i].precio_unitario * productos[i].cantidad;
      newHtml += '<td>' + (productos[i].sku ? productos[i].sku : '-') + '</td>';
      newHtml += '<td>' + productos[i].cantidad + '</td>';
      newHtml += '<td class="iorden-productos-nombre">' + productos[i].nombre;
      if (productos[i].atributos) {
        for (j in productos[i].info_atributos) {
          newHtml +=
            ' - ' +
            productos[i].info_atributos[j].at_nombre +
            ': ' +
            productos[i].info_atributos[j].vat_valor;
        }
      }
      newHtml += '</td>';
      newHtml +=
        '<td>$' +
        Validate.number_format(productos[i].precio_unitario, idCountry) +
        '</td>';
      newHtml += '<td>$' + Validate.number_format(precio, idCountry) + '</td>';
      newHtml += '</tr>';
    }

    newHtml +=
      '<tr><td colspan="4" class="iorden-productos-detail">Subtotal <strong>(' +
      cantidad_productos +
      ' ' +
      (cantidad_productos === 1 ? 'producto' : 'productos') +
      ')</strong></td><td>$' +
      Validate.number_format(venta.v_monto_bruto, idCountry) +
      '</td></tr>';

    if (venta.v_cupon > 0) {
      let v_cupon_monto =
        venta.v_cupon_porcentaje > 0
          ? Validate.get_fixed_percent(
              venta.v_monto_bruto,
              venta.v_cupon_porcentaje
            )
          : venta.v_cupon_monto;
      newHtml +=
        '<tr><td colspan="4" class="iorden-productos-detail">Descuento por cupón</td><td>-$' +
        Validate.number_format(v_cupon_monto, idCountry) +
        '</td></tr>';
    }

    if (venta.v_metodo_pago_descuento) {
      let metodo_pago_descuento_label =
        venta.v_metodo_pago === 2
          ? 'Descuento (pago en efectivo)'
          : 'Descuento (pago con transferencia/depósito)';
      newHtml +=
        '<tr><td colspan="4" class="iorden-productos-detail">' +
        metodo_pago_descuento_label +
        '</td><td>-$' +
        Validate.number_format(venta.v_metodo_pago_descuento_monto, idCountry) +
        '</td></tr>';
    }

    switch (envio_seleccionado.tipo) {
      case 2:
        break;
      case 3:
        break;
      case 5:
        switch (envio_seleccionado.cobro_envio) {
          case 0:
            newHtml +=
              '<tr><td colspan="4" class="iorden-productos-detail">Envío</td><td>$0,00</td></tr>';
            break;
          case 1:
            newHtml +=
              '<tr><td colspan="4" class="iorden-productos-detail">Envío</td><td>$' +
              Validate.number_format(envio_seleccionado.precio, idCountry) +
              '</td></tr>';
            total += envio_seleccionado.precio;
            break;
          default:
            break;
        }
        break;
      case 7:
        break;
      default:
        newHtml +=
          '<tr><td colspan="4" class="iorden-productos-detail">Envío</td><td>$' +
          Validate.number_format(envio_seleccionado.precio, idCountry) +
          '</td></tr>';
        if (envio_seleccionado.precio > 0) {
          total += envio_seleccionado.precio;
        }
        break;
    }
    newHtml +=
      '<tr><td colspan="4" class="iorden-productos-detail"><strong>Total</strong></td><td><strong>$' +
      Validate.number_format(total, idCountry) +
      '</strong></td></tr>';
    return newHtml;
  };

  //actualizar estado pago mp
  handleSubmitActualizarManualmenteMercadoPago = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        actualizacion_manual_mp: true,
      },
    });

    let form = this.state.actualizacion_manual_mp;

    if (Validate.validar_formulario(form)) {
      this.props.ventaActualizacionManualMercadoPago(
        {
          v_codigo_pago_adicional: form.amao_numero_operacion.value,
          v_numero: this.state.data.venta.v_numero,
        },
        (err, resp) => {
          this.setState({
            forms: {
              ...this.state.forms,
              actualizacion_manual_mp: false,
            },
            alert: {
              ...this.state.alert,
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            },
            modals: {
              ...this.state.modals,
              actualizacion_manual_mp: err ? true : false,
            },
            data: err
              ? this.state.data
              : {
                  ...this.state.data,
                  venta: resp.data.venta,
                  ayuda: resp.data.ayuda,
                },
          });
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          forms: {
            ...this.state.forms,
            actualizacion_manual_mp: false,
          },
          actualizacion_manual_mp: new_form,
        });
      });
    }
  };

  //archivo una orden
  handleSubmitArchivarOrden = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        archivar_orden: true,
      },
    });

    this.props.ventaArchivarVentaDetail(
      {
        v_numero: this.state.data.venta.v_numero,
      },
      (err, resp) => {
        if (err) {
          this.setState({
            forms: {
              ...this.state.forms,
              archivar_orden: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'error',
            },
          });
        } else {
          this.setState(
            {
              forms: {
                ...this.state.forms,
                archivar_orden: false,
              },
              alert: {
                open: true,
                message: resp.message,
                type: 'success',
              },
              data: err
                ? this.state.data
                : {
                    ...this.state.data,
                    venta: resp.data,
                  },
              modals: {
                ...this.state.modals,
                archivar_orden: false,
              },
            },
            () => {
              createAmplitudeEventWithDevice(
                sales_amplitude_events.ventas_archivar_orden.event_name,
                { source: 'detalle_orden' }
              );
            }
          );
        }
      }
    );
  };

  //abono un envio
  handleSubmitAbonarEnvio = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        abonar_envio: true,
      },
    });

    let body = {
      v_numero: this.state.data.venta.v_numero,
    };

    //si es emprepack, debo agregar el metodo de pago
    if (this.state.data.venta.v_metodo_envio === 4) {
      body = {
        ...body,
        metodo_pago:
          this.state.abonar_envio.abonar_envio_metodo_pago_emprepack.value,
      };
    }

    this.props.ventaAbonarEnvio(body, (err, resp) => {
      if (err) {
        this.setState({
          forms: {
            ...this.state.forms,
            abonar_envio: false,
          },
          alert: {
            open: true,
            message: resp.message,
            type: 'error',
          },
        });
      } else {
        this.setState(
          {
            forms: {
              ...this.state.forms,
              abonar_envio: false,
            },
            modals: {
              ...this.state.modals,
              abonar_envio: false,
            },
          },
          () => {
            window.location.replace(resp.data.url);
          }
        );
      }
    });
  };

  //doy de alta un envio de emprepack
  handleSubmitAltaEnvioEmprepack = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        alta_envio_emprepack_imprimir: true,
      },
    });

    this.props.ventaDarAltaEnvioEmprepack(
      {
        v_numero: this.state.data.venta.v_numero,
      },
      (err, resp) => {
        this.setState({
          forms: {
            ...this.state.forms,
            alta_envio_emprepack_imprimir: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          },
          alta_envio_emprepack_imprimir: {
            ...this.state.alta_envio_emprepack_imprimir,
            aemp_alta_finalizada: {
              validate: false,
              value: err ? false : true,
            },
          },
          data: err ? this.state.data : resp.data,
        });
      }
    );
  };

  handleSubmitCancelarEnvio = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        cancelar_envio: true,
      },
    });

    this.props.ventaCancelarEnvio(
      {
        v_numero: this.state.data.venta.v_numero,
      },
      (err, resp) => {
        if (err) {
          this.setState({
            forms: {
              ...this.state.forms,
              cancelar_envio: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'error',
            },
          });
        } else {
          this.setState({
            forms: {
              ...this.state.forms,
              cancelar_envio: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'success',
            },
            data: err
              ? this.state.data
              : {
                  ...this.state.data,
                  venta: resp.data.venta,
                  ayuda: resp.data.ayuda,
                },
            modals: {
              ...this.state.modals,
              cancelar_envio: false,
            },
          });
        }
      }
    );
  };

  render() {
    let data = this.state.data;
    return (
      <WrapperLayout
        loading={
          this.state.loading_venta ||
          this.state.loading_metodosenvio ||
          this.state.loading_tienda
        }
        error={this.state.loading_error}
        url={this.props.router_url}
      >
        <AlertUI
          open={this.state.alert.open}
          type={this.state.alert.type}
          message={this.state.alert.message}
          handleCloseAlert={this.handleCloseAlert}
        />
        <ModalMessage
          message={this.state.message}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.message}
        />
        <ModalCancelarOrden
          cancelar_orden={this.state.cancelar_orden}
          handleSubmit={this.handleSubmitCancelarOrden}
          handleChange={this.handleChange}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.cancelar_orden}
          form_state={this.state.forms.cancelar_orden}
        />
        <ModalDevolverStock
          handleSubmit={this.handleSubmitDevolverStock}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.devolver_stock}
          form_state={this.state.forms.devolver_stock}
        />
        <ModalDevolverDinero
          devolver_dinero={this.state.devolver_dinero}
          handleSubmit={this.handleSubmitDevolverDinero}
          handleChange={this.handleChange}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.devolver_dinero}
          form_state={this.state.forms.devolver_dinero}
        />
        <ModalAltaEnvioOca
          alta_envio_oca_imprimir={this.state.alta_envio_oca_imprimir}
          handleSubmit={this.handleSubmitAltaEnvioOca}
          handleChange={this.handleChange}
          handleChangeSelect={this.handleChangeSelect}
          handleChangeDate={this.handleChangeDate}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.alta_envio_oca_imprimir}
          form_state={this.state.forms.alta_envio_oca_imprimir}
          metodos_envio={this.props.metodos_envio}
          selects={this.state.selects}
          venta={this.state.data.venta}
        />
        <ModalNotificarEnvio
          notificar_envio_enviado={this.state.notificar_envio_enviado}
          venta={this.state.data.venta}
          handleSubmit={this.handleSubmitNotificarEnvioColocarEnviada}
          handleChange={this.handleChange}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.notificar_envio_enviado}
          form_state={this.state.forms.notificar_envio_enviado}
        />
        <ModalEntregarDescargables
          entregar_descargables={this.state.entregar_descargables}
          handleSubmit={this.handleSubmitEntregarDescargables}
          handleChange={this.handleChange}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.entregar_descargables}
          form_state={this.state.forms.entregar_descargables}
        />
        <ModalColocarRetirada
          handleSubmit={this.handleSubmitColocarRetirada}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.colocar_retirada}
          form_state={this.state.forms.colocar_retirada}
        />
        <ModalColocarAbonada
          handleSubmit={this.handleSubmitColocarAbonada}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.colocar_abonada}
          form_state={this.state.forms.colocar_abonada}
          venta={this.state.data.venta}
        />
        <ModalMensajeOrden
          enviar_mensaje_cliente={this.state.enviar_mensaje_cliente}
          handleSubmit={this.handleSubmitEnviarMensaje}
          handleChange={this.handleChange}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.enviar_mensaje_cliente}
          form_state={this.state.forms.enviar_mensaje_cliente}
        />
        <ModalActualizarOrdenMercadoPago
          actualizacion_manual_mp={this.state.actualizacion_manual_mp}
          handleSubmit={this.handleSubmitActualizarManualmenteMercadoPago}
          handleChange={this.handleChange}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.actualizacion_manual_mp}
          form_state={this.state.forms.actualizacion_manual_mp}
        />
        <ModalArchivarOrden
          handleSubmit={this.handleSubmitArchivarOrden}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.archivar_orden}
          form_state={this.state.forms.archivar_orden}
        />
        <ModalAbonarEnvio
          abonar_envio={this.state.abonar_envio}
          handleSubmit={this.handleSubmitAbonarEnvio}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.abonar_envio}
          form_state={this.state.forms.abonar_envio}
        />
        <ModalAltaEnvioEmprepack
          alta_envio_emprepack_imprimir={
            this.state.alta_envio_emprepack_imprimir
          }
          handleSubmit={this.handleSubmitAltaEnvioEmprepack}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.alta_envio_emprepack_imprimir}
          form_state={this.state.forms.alta_envio_emprepack_imprimir}
          venta={this.state.data.venta}
        />
        <ModalCancelarEnvio
          handleSubmit={this.handleSubmitCancelarEnvio}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.cancelar_envio}
          form_state={this.state.forms.cancelar_envio}
        />
        <ModalEditarDatosFacturacion
          editar={this.state.editar_datos_facturacion}
          handleSubmit={this.handleSubmitEditarDatosFacturacion}
          handleChange={this.handleChange}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.editar_datos_facturacion}
          form_state={this.state.forms.editar_datos_facturacion}
          handleChangeSelect={this.handleChangeSelect}
          selects={this.state.selects}
        />
        <ModalEditarDatosEnvio
          editar={this.state.editar_datos_envio}
          handleSubmit={this.handleSubmitEditarDatosEnvio}
          handleChange={this.handleChange}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.editar_datos_envio}
          form_state={this.state.forms.editar_datos_envio}
          handleChangeSelect={this.handleChangeSelect}
          selects={this.state.selects}
          venta={data.venta}
        />
        <ModalMarcarComoFacturada
          marcar_como_facturada={this.state.marcar_como_facturada}
          handleSubmit={this.handleSubmitMarcarComoFacturada}
          handleChangeDoc={this.handleChangeDoc}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.marcar_como_facturada}
          form_state={this.state.forms.marcar_como_facturada}
        />
        <OrderNavigationButtons
          saleId={this.props.id}
          lastSaleId={this.props.lastSaleId}
          redirectToPreviousSale={this.props.redirectToPreviousSale}
          redirectToNextSale={this.props.redirectToNextSale}
        />
        <ModuleTitleUI
          title={'Orden #' + data.venta.v_numero}
          subtitles={[
            <span
              style={{
                fontSize: '1rem',
                fontWeight: '500',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Icon style={{ marginRight: '5px' }}>event</Icon>
              {Validate.utc2local_format(
                data.venta.v_fecha,
                Config.DATE_FORMAT
              )}
            </span>,
          ]}
          subtitles_icon={false}
          button={
            <OrdenAcciones
              loading={this.state.loading_sucursales}
              venta={data.venta}
              modalHandler={this.modalHandler}
              idCountry={this.props.idCountry}
            />
          }
        />
        <Grid sx={{ marginTop: '20px' }} container>
          <Grid
            item
            xl={7}
            lg={8}
            xs={12}
            sx={{
              paddingRight: { xs: '0px', lg: 3 },
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <OrdenOverview venta={data.venta} ayuda={data.ayuda} />
              </Grid>
              <Grid item xs={12}>
                <OrdenDetalle
                  venta={data.venta}
                  label_cupon={this.state.label_cupon}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={5} lg={4} xs={12} pt={{ xs: 3, lg: 0 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <OrdenInfoCliente venta={data.venta} cliente={data.cliente} />
              </Grid>
              <Grid item xs={12}>
                <OrdenInfoEnvio data={data} idCountry={this.props.idCountry} />
              </Grid>
              <Grid item xs={12}>
                <OrdenInfoFacturacion
                  venta={data.venta}
                  cliente={data.cliente}
                  idCountry={this.props.idCountry}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </WrapperLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
    metodos_envio: state.metodos_envio,
    dominios: state.dominios,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ventaObtenerVenta: (data, callback) =>
      dispatch(ventaObtenerVenta(data, callback)),
    ventaCancelarVenta: (data, callback) =>
      dispatch(ventaCancelarVenta(data, callback)),
    ventaDevolverStock: (data, callback) =>
      dispatch(ventaDevolverStock(data, callback)),
    ventaDevolverDinero: (data, callback) =>
      dispatch(ventaDevolverDinero(data, callback)),
    ventaDarAltaEnvioOca: (data, callback) =>
      dispatch(ventaDarAltaEnvioOca(data, callback)),
    ventaDarAltaEnvioEmprepack: (data, callback) =>
      dispatch(ventaDarAltaEnvioEmprepack(data, callback)),
    ventaNotificarEnvioColocarEnviado: (data, callback) =>
      dispatch(ventaNotificarEnvioColocarEnviado(data, callback)),
    ventaEntregarDescargables: (data, callback) =>
      dispatch(ventaEntregarDescargables(data, callback)),
    ventaColocarComoRetirada: (data, callback) =>
      dispatch(ventaColocarComoRetirada(data, callback)),
    ventaColocarComoAbonada: (data, callback) =>
      dispatch(ventaColocarComoAbonada(data, callback)),
    ventaEnviarMensajeCliente: (data, callback) =>
      dispatch(ventaEnviarMensajeCliente(data, callback)),
    ventaEditarDatosFacturacion: (data, callback) =>
      dispatch(ventaEditarDatosFacturacion(data, callback)),
    ventaEditarDatosEnvio: (data, callback) =>
      dispatch(ventaEditarDatosEnvio(data, callback)),
    ventaActualizacionManualMercadoPago: (data, callback) =>
      dispatch(ventaActualizacionManualMercadoPago(data, callback)),
    ventaAbonarEnvio: (data, callback) =>
      dispatch(ventaAbonarEnvio(data, callback)),
    ventaCancelarEnvio: (data, callback) =>
      dispatch(ventaCancelarEnvio(data, callback)),
    ventaArchivarVentaDetail: (data, callback) =>
      dispatch(ventaArchivarVentaDetail(data, callback)),
    metodosenvioObtener: (callback) => dispatch(metodosenvioObtener(callback)),
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    metodoenvioObtenerSucursalesAdmisionOca: (callback) =>
      dispatch(metodoenvioObtenerSucursalesAdmisionOca(callback)),
    ventaMarcarComoFacturada: (data, callback) =>
      dispatch(ventaMarcarComoFacturada(data, callback)),
  };
};

export const VentaDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(VentaDetails_);
