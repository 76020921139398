import React, { Component } from 'react';
import PropTypes from 'prop-types';
import async from 'async';
import arrayMove from 'array-move';
import { Grid, Icon } from '@mui/material';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { ModuleCardTitleUI, AlertUI } from './';
import { Validate } from '../../other';
import 'blueimp-canvas-to-blob/js/canvas-to-blob';
import loadImage from 'blueimp-load-image';
import { withStyles } from '@mui/styles';
class EmpicUI_ extends Component {
  state = {
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  /**
   * Proceso un input con un solo archivo para evitar sobre procesamiento
   */
  processSingleFile = () => {
    let { data, reducer_max_height, reducer_max_width, handleChange } =
      this.props;
    let id = data.id;
    let empic = document.getElementById('empic_' + id);
    let image = empic.files[0];

    if (image === undefined) {
      empic.value = '';
    } else {
      let { valid, message } = this.validarImagen(image);
      if (valid) {
        if (image.type === 'image/gif') {
          handleChange(true, {
            ...data,
            auth: true,
            files: [
              {
                type: 0,
                link: URL.createObjectURL(image),
                file: image,
              },
            ],
            messages: {
              ...data.messages,
              error: message,
            },
          });
          empic.value = '';
        } else {
          loadImage(
            image,
            (img) => {
              img.toBlob((blob) => {
                let imgReduced = new File([blob], image.name, {
                  type: image.type,
                  lastModified: Date.now(),
                });
                handleChange(true, {
                  ...data,
                  auth: true,
                  files: [
                    {
                      type: 0,
                      link: URL.createObjectURL(imgReduced),
                      file: imgReduced,
                    },
                  ],
                  messages: {
                    ...data.messages,
                    error: message,
                  },
                });
                empic.value = '';
              }, image.type);
            },
            {
              meta: true,
              canvas: true,
              imageSmoothingQuality: 'high',
              imageSmoothingEnabled: true,
              maxWidth: reducer_max_width,
              maxHeight: reducer_max_height,
              orientation: true,
            }
          );
        }
      } else {
        this.setState(
          {
            alert: {
              ...this.state.alert,
              open: true,
              message: message,
            },
          },
          () => {
            handleChange(false, {
              ...data,
              auth: data.required ? false : true,
              files: [],
              messages: {
                ...data.messages,
                error: message,
              },
            });
            empic.value = '';
          }
        );
      }
    }
  };

  /**
   * Proceso un input con mas de un solo archivo para evitar el procesamiento
   */
  processMultipleFiles = () => {
    let {
      data,
      max_files,
      reducer_max_height,
      reducer_max_width,
      handleChange,
    } = this.props;
    let id = data.id;
    let empic = document.getElementById('empic_' + id);
    let files = [...empic.files];

    if (files.length === 0 || files[0] === undefined) {
      empic.value = '';
    } else {
      if (files.length + data.files.length <= max_files) {
        let filesArr = [];
        let i, validation;
        for (i = 0; i < files.length; i++) {
          validation = this.validarImagen(files[i]);
          if (validation.valid) {
            filesArr.push(files[i]);
          } else {
            break;
          }
        }

        if (validation.valid) {
          async.map(
            filesArr,
            (image, callback) => {
              if (image.type === 'image/gif') {
                return callback(null, {
                  type: 0,
                  link: URL.createObjectURL(image),
                  file: image,
                });
              } else {
                loadImage(
                  image,
                  (img) => {
                    img.toBlob((blob) => {
                      let imgReduced = new File([blob], image.name, {
                        type: image.type,
                        lastModified: Date.now(),
                      });
                      return callback(null, {
                        type: 0,
                        link: URL.createObjectURL(imgReduced),
                        file: imgReduced,
                      });
                    }, image.type);
                  },
                  {
                    meta: true,
                    canvas: true,
                    imageSmoothingQuality: 'high',
                    imageSmoothingEnabled: true,
                    maxWidth: reducer_max_width,
                    maxHeight: reducer_max_height,
                    orientation: true,
                  }
                );
              }
            },
            (err, imagesReduced) => {
              handleChange(false, {
                ...data,
                auth: true,
                files: data.files.concat(imagesReduced),
                messages: {
                  ...data.messages,
                  error: validation.message,
                },
              });
              empic.value = '';
            }
          );
        } else {
          this.setState(
            {
              alert: {
                ...this.state.alert,
                open: true,
                message: validation.message,
              },
            },
            () => {
              handleChange(false, {
                ...data,
                auth: data.required && data.files.length === 0 ? false : true,
                messages: {
                  ...data.messages,
                  error: validation.message,
                },
              });
              empic.value = '';
            }
          );
        }
      } else {
        this.setState(
          {
            alert: {
              ...this.state.alert,
              open: true,
              message: 'Puedes subir hasta ' + max_files + ' imágenes',
            },
          },
          () => {
            handleChange(true, {
              ...data,
              messages: {
                ...data.messages,
                error: 'Puedes subir hasta ' + max_files + ' imágenes',
              },
            });
          }
        );
      }
    }
  };

  /**
   * Valido que la imagen cumpla con las condiciones
   * tamano y tipo pasados por parametro
   */
  validarImagen = (file) => {
    let { allowed_files, max_size } = this.props;
    let { name, size } = file;

    let extensionArr = name.split('.');
    if (extensionArr.length > 1) {
      let extension = Validate.trim_lowercase(
        extensionArr[extensionArr.length - 1]
      ); //obtengo la extension
      if (Validate.in_array(extension, allowed_files)) {
        size = size / 1024 / 1024; //convierto a mb
        if (size <= max_size) {
          if (extension === 'gif') {
            if (size <= 0.59) {
              return {
                valid: true,
                message: '',
              };
            } else {
              return {
                valid: false,
                message:
                  'La imagen ' +
                  name +
                  ' no debe exceder los 500kb de tamaño, intenta reduciéndola con alguna herramienta',
              };
            }
          } else {
            return {
              valid: true,
              message: '',
            };
          }
        } else {
          return {
            valid: false,
            message:
              'La imagen ' +
              name +
              ' no debe exceder los ' +
              max_size +
              'mb de tamaño',
          };
        }
      } else {
        let tipos = allowed_files.join(', ');
        return {
          valid: false,
          message: 'La imagen ' + name + ' debe ser de tipo: ' + tipos,
        };
      }
    } else {
      let tipos = allowed_files.join(', ');
      return {
        valid: false,
        message: 'La imagen ' + name + ' debe ser de tipo: ' + tipos,
      };
    }
  };

  /**
   * Quito una imagen
   */
  removerImagen = (index) => {
    let { data, handleChange } = this.props;
    let { files } = data;

    files = files.filter((file, idx) => {
      return index !== idx;
    });

    handleChange(true, {
      ...data,
      auth: data.required && files.length === 0 ? false : true,
      files: files,
      messages: {
        ...data.messages,
        error: '',
      },
    });
  };

  /**
   * Renderizo una imagen ya ingresada para subir o ya cargada en el server
   */
  renderizarImagen = (file, index) => {
    let { xs, sm, md, lg, classes, row_height, cdn_prefix } = this.props;

    let link;

    if (file.type) {
      link = cdn_prefix + file.link;
    } else {
      link = file.link;
    }

    return (
      <Grid
        item
        lg={lg}
        sm={sm}
        md={md}
        xs={xs}
        key={index}
        style={{ zIndex: 1201 }}
      >
        <div className={classes.empic_image_box} style={{ height: row_height }}>
          <Icon
            className={classes.empic_remove_icon}
            onClick={(e) => this.removerImagen(index)}
          >
            clear
          </Icon>
          <img
            src={link}
            className={classes.empic_image_preview}
            alt="imagen empic"
          />
        </div>
      </Grid>
    );
  };

  /**
   * Renderizo un input sin imagen
   */
  renderizarEmpty = (index) => {
    let { xs, sm, md, lg, classes, row_height, data } = this.props;

    let { id } = data;

    return (
      <Grid item lg={lg} sm={sm} md={md} xs={xs} key={index}>
        <label htmlFor={'empic_' + id}>
          <div
            className={classes.empic_empty_box}
            style={{ height: row_height }}
          >
            <Icon className={classes.empic_empty_icon}>photo</Icon>
          </div>
        </label>
      </Grid>
    );
  };

  /**
   * Swapeo los elementos que se ordenan
   */
  onSortEnd = ({ newIndex, oldIndex }) => {
    let { data, handleChange } = this.props;

    if (newIndex !== oldIndex) {
      let new_files = arrayMove(data.files, oldIndex, newIndex);
      handleChange(false, {
        ...data,
        files: new_files,
      });
    }
  };

  /**
   * Armo empic para una sola imagen
   */
  singleMode = () => {
    let { input_allowed_mime, classes } = this.props;
    let { files, label, sublabels, id } = this.props.data;

    let html_head = label ? (
      <ModuleCardTitleUI title={label} subtitles={sublabels} />
    ) : (
      ''
    );

    return (
      <Grid container spacing={1.5}>
        {html_head}
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <input
          type="file"
          id={'empic_' + id}
          accept={input_allowed_mime}
          className={classes.empic_input}
          onChange={this.processSingleFile}
        />
        {files.length
          ? this.renderizarImagen(files[0], 0)
          : this.renderizarEmpty(0)}
      </Grid>
    );
  };

  /**
   * Armo empic para muchas imagenes
   */
  multipleMode = () => {
    let { input_allowed_mime, max_files, classes } = this.props;

    let { files, label, sublabels, id } = this.props.data;

    const SortableItem = SortableElement(({ file, idx }) =>
      this.renderizarImagen(file, idx)
    );

    const SortableContainerr = SortableContainer(({ children }) => {
      return (
        <Grid item xs={12}>
          <Grid container spacing={1.5}>
            {children}
          </Grid>
        </Grid>
      );
    });

    let html = files.map((file, index) => {
      return <SortableItem file={file} idx={index} index={index} key={index} />;
    });

    let html_empties = new Array(max_files - files.length)
      .fill({})
      .map((emptie, index) => {
        return this.renderizarEmpty(index + files.length);
      });

    let html_head = label ? (
      <ModuleCardTitleUI title={label} subtitles={sublabels} />
    ) : (
      ''
    );

    let mobile = Validate.is_mobile();

    return (
      <Grid container spacing={1.5}>
        {html_head}
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <input
          type="file"
          id={'empic_' + id}
          accept={input_allowed_mime}
          className={classes.empic_input}
          onChange={this.processMultipleFiles}
          multiple
        />
        <SortableContainerr
          axis="xy"
          onSortEnd={this.onSortEnd}
          pressDelay={mobile ? 100 : 200}
        >
          {html}
          {html_empties}
        </SortableContainerr>
      </Grid>
    );
  };

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  render() {
    let { max_files } = this.props;

    if (max_files === 1) {
      return this.singleMode();
    } else {
      return this.multipleMode();
    }
  }
}

const styles = (theme) => ({
  empic_empty_box: {
    border: '1px dotted #9e9e9e',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  empic_empty_icon: {
    fontSize: '50px',
  },
  empic_input: {
    display: 'none',
  },
  empic_image_box: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  empic_image_preview: {
    width: 'auto',
    height: 'auto',
    maxHeight: '100%',
    maxWidth: '100%',
    zIndex: '998 !important',
  },
  empic_remove_icon: {
    position: 'absolute',
    fontSize: '25px',
    alignSelf: 'flex-start',
    color: '#fff',
    top: '0',
    right: '0',
    cursor: 'pointer',
    textShadow: '1px 0px 3px rgba(150, 150, 150, 1)',
    zIndex: '999 !important',
  },
});

EmpicUI_.propTypes = {
  /**
   * Object used to add some styling with withStyles
   */
  classes: PropTypes.object,

  /**
   * The object initialized in state
   */
  data: PropTypes.shape({
    validate: PropTypes.bool.isRequired,
    auth: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.any,
    sublabels: PropTypes.array,
    files: PropTypes.arrayOf(
      PropTypes.shape({
        //solo contiene el numero de archivos insertados
        file: PropTypes.any, //blob - null
        link: PropTypes.string.isRequired, //link for preview
        type: PropTypes.number.isRequired, //0 blob, 1 link
      })
    ).isRequired,
    messages: PropTypes.shape({
      help: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      error: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }).isRequired,
  }),

  /**
   * number of files allowed
   */
  max_files: PropTypes.number.isRequired,

  /**
   * max size in mb for a single file
   */
  max_size: PropTypes.number.isRequired,

  /**
   * max size in mb for a single file
   */
  reducer_max_width: PropTypes.number.isRequired,

  /**
   * max size in mb for a single file
   */
  reducer_max_height: PropTypes.number.isRequired,

  /**
   * file extensions allowed, used in validation func
   * ej: ["jpg","png",...]
   */
  allowed_files: PropTypes.arrayOf(PropTypes.string).isRequired,

  /**
   * file extensions allowed, used in the input
   * ej: image/jpg,image/png,image/jpeg
   */
  input_allowed_mime: PropTypes.string.isRequired,

  /**
   * the function used to send the files data
   * handleChange(data:obj,err:bool)
   */
  handleChange: PropTypes.func.isRequired,

  /**
   * this is used to preview images
   */
  cdn_prefix: PropTypes.string.isRequired,

  /**
   * defines the height of each single image square
   */
  row_height: PropTypes.string.isRequired,

  /**
   * preview size in mobile (default 12)
   */
  xs: PropTypes.number,

  /**
   * preview size in tablet (default 12)
   */
  sm: PropTypes.number,

  /**
   * preview size in big tablets and small desktop screens (default 12)
   */
  md: PropTypes.number,

  /**
   * preview size in large and hd screens (default 12)
   */
  lg: PropTypes.number,
};

export const EmpicUI = withStyles(styles)(EmpicUI_);
