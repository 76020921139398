import React from 'react';
import { withStyles } from '@mui/styles';

const InputColorCircle_ = ({ color, classes }) => {
  return (
    <div className={classes.color_preview} style={{ backgroundColor: color }} />
  );
};

const styles = () => ({
  color_preview: {
    borderRadius: '50px',
    width: '25px',
    height: '25px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
});

export const InputColorCircle = withStyles(styles, { withTheme: true })(
  InputColorCircle_
);
