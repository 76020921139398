import React from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import {
  AllRoute,
  PrivateRoute,
  PublicRoute,
  ThemeUI,
} from './components/common';
import {
  Promo,
  RedesSociales,
  Blog,
  BlogAgregar,
  BlogEditar,
  Paginas,
  PaginaAgregar,
  PaginaEditar,
  Chat,
  VentaMayorista,
  Dominios,
  Categorias,
  Clientes,
  GoogleAnalytics,
  Metricas,
  MetodosPago,
  MetodosEnvio,
  MercadoEnvios,
  Oca,
  CorreoArgentino,
  EPick,
  Emprepack,
  Local,
  EnvioPersonalizado,
  EnvioPersonalizadoAgregar,
  EnvioPersonalizadoEditar,
  ProductoEditar,
  ProductoAgregar,
  Productos,
  AumentoMasivoPrecios,
  Cupones,
  CuponesAgregar,
  CuponesEditar,
  AgregarOferta,
  BorrarOferta,
  Plan,
  Baja,
  PinterestTag,
  Ofertas,
  Builder,
  ProductosImportar,
  ImportacionEstadoTrack,
  PlanMetodoPago,
  Applications,
} from './components';

//pages
import MetaAds from './pages/meta-ads';
import HtmlTags from './pages/etiquetas-html';
import FacebookPixel from './pages/facebook-pixel';
import GoogleShopping from './pages/google-shopping';
import SeoDescripcion from './pages/seo-descripcion';
import EmailMarketingPage from './pages/email-marketing';

//styles
import './libs/css/style.css';
import AmplitudeProvider from './components/amplitude/AmplitudeProvider';
import { withUseAmplitudeHOC } from './components/amplitude';
import { Suscripcion } from './components/plan/metodo-pago/suscripcion';
import { BillingInformationPage } from './pages/datos-facturacion';
import { ApplicationDetail } from './components/aplicaciones/ApplicationDetail';
import { LoginStep, SignUpStep } from './components/auth0';
import CreateShop from './pages/formulario-crear-tienda';
import { SupportPage } from './pages/soporte';
import { ContactFormPage } from './pages/formulario-contacto';
import { ForumPage } from './pages/foro';
import { DigitalProductsPage } from './pages/productos-digitales';
import { TurnOffTemporarilyPage } from './pages/apagar-temporalmente';
import { EmpreshopsPage } from './pages/empreshops';
import { FiscalDataPage } from './pages/data-fiscal';
import { StockNotificationsPage } from './pages/notificaciones-stock';
import Reactivation from './components/plan/reactivacion';
import { HomePage } from './pages/home';
import { ShopInformationPage } from './pages/informacion-tienda';
import { SalesListPage } from './pages/listado-de-ventas';
import { OrderPage } from './pages/listado-de-ventas/orden/[id]';

const App = () => {
  return (
    <ThemeUI>
      <BrowserRouter>
        <AmplitudeProvider>
          <Switch>
            <PrivateRoute exact path="/" component={HomePage} />
            <PrivateRoute
              exact
              path="/informacion-tienda"
              component={withUseAmplitudeHOC(ShopInformationPage)}
            />
            <PrivateRoute
              exact
              path="/redes-sociales"
              component={withUseAmplitudeHOC(RedesSociales)}
            />
            <PrivateRoute
              exact
              path="/blog"
              component={withUseAmplitudeHOC(Blog)}
            />
            <PrivateRoute
              exact
              path="/blog/agregar"
              component={withUseAmplitudeHOC(BlogAgregar)}
            />
            <PrivateRoute
              exact
              path="/blog/:idBlog"
              component={withUseAmplitudeHOC(BlogEditar)}
            />
            <PrivateRoute
              exact
              path="/paginas"
              component={withUseAmplitudeHOC(Paginas)}
            />
            <PrivateRoute
              exact
              path="/paginas/agregar"
              component={withUseAmplitudeHOC(PaginaAgregar)}
            />
            <PrivateRoute
              exact
              path="/paginas/:idPaginas"
              component={withUseAmplitudeHOC(PaginaEditar)}
            />
            <PrivateRoute
              exact
              path="/chat"
              component={withUseAmplitudeHOC(Chat)}
            />
            <PrivateRoute
              exact
              path="/data-fiscal"
              component={withUseAmplitudeHOC(FiscalDataPage)}
            />
            <PrivateRoute
              exact
              path="/formulario-contacto"
              component={withUseAmplitudeHOC(ContactFormPage)}
            />
            <PrivateRoute
              exact
              path="/apagar-temporalmente"
              component={withUseAmplitudeHOC(TurnOffTemporarilyPage)}
            />
            <PrivateRoute
              exact
              path="/venta-mayorista"
              component={withUseAmplitudeHOC(VentaMayorista)}
            />
            <PrivateRoute
              exact
              path="/productos-digitales"
              component={withUseAmplitudeHOC(DigitalProductsPage)}
            />
            <PrivateRoute
              exact
              path="/categorias"
              component={withUseAmplitudeHOC(Categorias)}
            />
            <PrivateRoute exact path="/clientes" component={Clientes} />
            <PrivateRoute
              exact
              path="/meta-ads"
              component={withUseAmplitudeHOC(MetaAds)}
            />
            <PrivateRoute
              exact
              path="/google-shopping"
              component={withUseAmplitudeHOC(GoogleShopping)}
            />
            <PrivateRoute
              exact
              path="/facebook-pixel"
              component={withUseAmplitudeHOC(FacebookPixel)}
            />
            <PrivateRoute
              exact
              path="/pinterest-tag"
              component={withUseAmplitudeHOC(PinterestTag)}
            />
            <PrivateRoute
              exact
              path="/seo-descripcion"
              component={withUseAmplitudeHOC(SeoDescripcion)}
            />
            <PrivateRoute
              exact
              path="/email-marketing"
              component={withUseAmplitudeHOC(EmailMarketingPage)}
            />
            <PrivateRoute
              exact
              path="/dominios"
              component={withUseAmplitudeHOC(Dominios)}
            />
            <PrivateRoute
              exact
              path="/google-analytics"
              component={withUseAmplitudeHOC(GoogleAnalytics)}
            />
            <PrivateRoute
              exact
              path="/metricas"
              component={withUseAmplitudeHOC(Metricas)}
            />
            <PrivateRoute
              exact
              path="/foro"
              component={withUseAmplitudeHOC(ForumPage)}
            />
            <PrivateRoute
              exact
              path="/soporte"
              component={withUseAmplitudeHOC(SupportPage)}
            />
            <PrivateRoute
              exact
              path="/metodos-pago"
              component={withUseAmplitudeHOC(MetodosPago)}
            />
            <PrivateRoute
              exact
              path="/metodos-envio"
              component={withUseAmplitudeHOC(MetodosEnvio)}
            />
            <PrivateRoute
              exact
              path="/metodos-envio/mercado-envios"
              component={withUseAmplitudeHOC(MercadoEnvios)}
            />
            <PrivateRoute
              exact
              path="/metodos-envio/oca"
              component={withUseAmplitudeHOC(Oca)}
            />
            <PrivateRoute
              exact
              path="/metodos-envio/correo-argentino"
              component={withUseAmplitudeHOC(CorreoArgentino)}
            />
            <PrivateRoute
              exact
              path="/metodos-envio/epick"
              component={withUseAmplitudeHOC(EPick)}
            />
            <PrivateRoute
              exact
              path="/metodos-envio/emprepack"
              component={withUseAmplitudeHOC(Emprepack)}
            />
            <PrivateRoute
              exact
              path="/metodos-envio/locales"
              component={withUseAmplitudeHOC(Local)}
            />
            <PrivateRoute
              exact
              path="/metodos-envio/envios-personalizados"
              component={withUseAmplitudeHOC(EnvioPersonalizado)}
            />
            <PrivateRoute
              exact
              path="/metodos-envio/envios-personalizados/agregar"
              component={withUseAmplitudeHOC(EnvioPersonalizadoAgregar)}
            />
            <PrivateRoute
              exact
              path="/metodos-envio/envios-personalizados/:idEnviosPersonalizados"
              component={EnvioPersonalizadoEditar}
            />
            <PrivateRoute
              exact
              path="/productos"
              component={withUseAmplitudeHOC(Productos)}
            />
            <PrivateRoute
              exact
              path="/productos/agregar"
              component={withUseAmplitudeHOC(ProductoAgregar)}
            />
            <PrivateRoute
              exact
              path="/productos/agregar/:idProductos"
              component={withUseAmplitudeHOC(ProductoAgregar)}
            />
            <PrivateRoute
              exact
              path="/productos/aumento-masivo"
              component={withUseAmplitudeHOC(AumentoMasivoPrecios)}
            />
            <PrivateRoute
              exact
              path="/productos/importacion/:idTrack"
              component={ImportacionEstadoTrack}
            />
            <PrivateRoute
              exact
              path="/productos/importacion"
              component={ProductosImportar}
            />
            <PrivateRoute
              exact
              path="/productos/:idProductos"
              component={withUseAmplitudeHOC(ProductoEditar)}
            />
            <PrivateRoute
              exact
              path="/notificaciones-stock"
              component={withUseAmplitudeHOC(StockNotificationsPage)}
            />
            <PrivateRoute
              exact
              path="/cupones"
              component={withUseAmplitudeHOC(Cupones)}
            />
            <PrivateRoute
              exact
              path="/cupones/agregar"
              component={withUseAmplitudeHOC(CuponesAgregar)}
            />
            <PrivateRoute
              exact
              path="/cupones/:idCuponesDescuento"
              component={withUseAmplitudeHOC(CuponesEditar)}
            />
            <PrivateRoute
              exact
              path="/plan"
              component={withUseAmplitudeHOC(Plan)}
            />
            <PrivateRoute
              exact
              path="/plan/reactivacion"
              component={withUseAmplitudeHOC(Reactivation)}
            />
            <PrivateRoute
              exact
              path="/plan/metodo-pago"
              component={withUseAmplitudeHOC(PlanMetodoPago)}
            />
            <PrivateRoute
              exact
              path="/plan/datos-facturacion"
              component={withUseAmplitudeHOC(BillingInformationPage)}
            />
            <PrivateRoute
              exact
              path="/plan/metodo-pago/suscripcion"
              component={withUseAmplitudeHOC(Suscripcion)}
            />
            <Redirect from="/ventas" to="/listado-de-ventas" />
            <PrivateRoute
              exact
              path="/listado-de-ventas"
              component={SalesListPage}
            />
            <PrivateRoute
              exact
              path="/listado-de-ventas/orden/:id"
              component={withUseAmplitudeHOC(OrderPage)}
            />
            <PrivateRoute
              exact
              path="/baja"
              component={withUseAmplitudeHOC(Baja)}
            />
            <PrivateRoute
              exact
              path="/ofertas"
              component={withUseAmplitudeHOC(Ofertas)}
            />
            <PrivateRoute
              exact
              path="/ofertas/agregar"
              component={withUseAmplitudeHOC(AgregarOferta)}
            />
            <PrivateRoute
              exact
              path="/ofertas/eliminar"
              component={withUseAmplitudeHOC(BorrarOferta)}
            />
            <PrivateRoute
              exact
              path="/empreshops"
              component={withUseAmplitudeHOC(EmpreshopsPage)}
            />
            <PrivateRoute
              exact
              path="/etiquetas-html"
              component={withUseAmplitudeHOC(HtmlTags)}
            />
            <PrivateRoute
              exact
              path="/builder"
              component={withUseAmplitudeHOC(Builder)}
            />
            <PrivateRoute
              exact
              path="/aplicaciones"
              component={withUseAmplitudeHOC(Applications)}
            />
            <PrivateRoute
              exact
              path="/aplicaciones/:appName"
              component={withUseAmplitudeHOC(ApplicationDetail)}
            />
            <PrivateRoute
              exact
              withoutShopId
              path="/formulario-crear-tienda"
              component={CreateShop}
            />
            <PublicRoute exact path="/crear-tienda" component={SignUpStep} />
            <PublicRoute
              exact
              path="/crear-tienda/partner/:ca_codigo"
              component={SignUpStep}
            />
            <PublicRoute exact path="/login" component={LoginStep} />
            <AllRoute exact path="/activate/:token/:code" component={Promo} />
          </Switch>
        </AmplitudeProvider>
      </BrowserRouter>
    </ThemeUI>
  );
};

export default App;
