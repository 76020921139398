/* eslint-disable no-unreachable */
/* eslint-disable default-case */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Avatar,
  IconButton,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Validate } from '../../../../other';

const renderizarEnviosPersonalizadosList = (
  enviospe,
  modalHandler,
  classes
) => {
  let mobile = Validate.is_mobile();

  const obtenerTipoEnvio = (ep_cobro_envio) => {
    switch (ep_cobro_envio) {
      case 0:
        return 'Envío gratuito';
        break;
      case 1:
        return 'Envío con costo fijo (pago online)';
        break;
      case 2:
        return 'Envío con costo fijo (pago en destino)';
        break;
      case 3:
        return 'Envío sin costo fijo (pago en destino)';
        break;
    }
  };

  const obtenerRangoGeografico = (enviope) => {
    switch (enviope.ep_filtrado_geografico) {
      case 0:
        return (
          <span className={classes.span}>
            Método habilitado para todo el país
          </span>
        );
        break;
      case 1:
        return enviope.provincias.map((provincia) => {
          return (
            <Chip
              className={classes.chip}
              component="span"
              color="primary"
              label={provincia.pro_nombre}
              key={provincia.idProvincias}
            />
          );
        });
        break;
      case 2:
        return enviope.ciudades.map((ciudad) => {
          return (
            <Chip
              className={classes.chip}
              component="span"
              color="primary"
              label={ciudad.ciu_nombrecompleto}
              key={ciudad.idCiudades}
            />
          );
        });
        break;
    }
  };

  if (enviospe.length > 0) {
    return enviospe.map((enviope, index) => {
      return (
        <ListItem
          divider={index < enviospe.length - 1}
          key={enviope.idEnviosPersonalizados}
        >
          {!mobile ? (
            <ListItemAvatar>
              <Avatar>
                <Icon>local_shipping</Icon>
              </Avatar>
            </ListItemAvatar>
          ) : (
            ''
          )}
          <ListItemText
            primary={enviope.ep_nombre}
            secondary={
              <span className={classes.span}>
                <span className={classes.span}>
                  Tipo: {obtenerTipoEnvio(enviope.ep_cobro_envio)}
                </span>

                {enviope.ep_cobro_envio === 1 ||
                enviope.ep_cobro_envio === 2 ? (
                  <span className={classes.span}>
                    Costo: ${Validate.number_format(enviope.ep_precio)}
                  </span>
                ) : (
                  ''
                )}

                <span className={classes.span}>
                  Pesos permitidos:{' '}
                  {enviope.ep_filtrar_peso
                    ? enviope.ep_peso_minimo +
                      'kg a ' +
                      enviope.ep_peso_maximo +
                      'kg'
                    : 'Todos'}
                </span>

                <span className={classes.span}>
                  Precio mínimo de compra:{' '}
                  {enviope.ep_filtrar_precio
                    ? '$' + Validate.number_format(enviope.ep_precio_minimo)
                    : 'No'}
                </span>

                {obtenerRangoGeografico(enviope)}
              </span>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              aria-label="Editar"
              component={(props) => (
                <Link
                  to={
                    '/metodos-envio/envios-personalizados/' +
                    enviope.idEnviosPersonalizados
                  }
                  {...props}
                />
              )}
              size="large"
            >
              <Icon>edit</Icon>
            </IconButton>
            <IconButton
              aria-label="Borrar"
              onClick={(e) => modalHandler(true, 'borrar', enviope)}
              size="large"
            >
              <Icon>delete</Icon>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
  } else {
    return (
      <ListItem>
        <ListItemText
          primary="No tenés métodos de envíos personalizados activos"
          secondary="Puedes agregar uno haciendo click en Agregar"
        />
      </ListItem>
    );
  }
};

const EnviosPersonalizadosList_ = (props) => {
  let { envio_personalizado, modalHandler, classes } = props;

  return (
    <List classes={{ root: classes.list }}>
      {renderizarEnviosPersonalizadosList(
        envio_personalizado,
        modalHandler,
        classes
      )}
    </List>
  );
};

const styles = (theme) => ({
  span: {
    display: 'block',
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  list: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '0',
    '&:last-child': {
      borderBottom: '0px solid transparent',
    },
  },
  chip: theme.chip,
});

const mapStateToProps = (state) => {
  return {
    envio_personalizado: state.envio_personalizado,
  };
};

const mapDispatchToProps = null;

export const EnviosPersonalizadosList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(EnviosPersonalizadosList_));
