import React from 'react';
import { Grid } from '@mui/material';
import { ModalUI, InputUI } from '../../common';

const ModalEditar = (props) => {
  let {
    editar,
    handleSubmit,
    handleChange,
    modalHandler,
    modal_state,
    form_state,
  } = props;

  return (
    <ModalUI
      open={modal_state}
      id="editar"
      title="Editar categoría"
      modalHandler={modalHandler}
      aditional_param="editar"
      handleSubmit={handleSubmit}
      button_label="Editar"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <InputUI handleChange={handleChange} input={editar.c_nombre} />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalEditar };
