import { Grid, Icon, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { ButtonUI } from '../../common';

const PlantillaCard_ = ({
  classes,
  template,
  handleChangeTemplate,
  template_id,
}) => (
  <Grid item xs={12}>
    <div className={classes.template_container}>
      <img
        src={template.imagen}
        className={classes.template_image}
        alt="Plantilla"
      />
      <div className={classes.template_caption}>
        <Typography classes={{ root: classes.template_name }}>
          {template.nombre}
        </Typography>
      </div>
      <div className={classes.template_caption}>
        {template.id === template_id ? (
          <div className={classes.msjTemplateSelected}>
            <Icon>check_circle</Icon>
            En uso
          </div>
        ) : (
          <ButtonUI
            type="callf"
            label={template.id === template_id ? 'En uso' : 'Probar'}
            onClickFunc={(e) => handleChangeTemplate(e, template)}
            disabled={template.id === template_id}
          />
        )}
      </div>
    </div>
  </Grid>
);

const styles = (theme) => ({
  msjTemplateSelected: {
    position: 'relative',
    width: '100%',
    height: '40px',
    display: 'flex',
    fontWeight: 500,
    borderRadius: '2px',
    alignItems: 'center',
    margin: '0',
    justifyContent: 'center',
    border: '1px solid #53B755',
    color: theme.palette.primary.main,
    gap: '10px',
  },
  template_container: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  template_image: {
    width: '100%',
    height: 'auto',
  },
  template_caption: {
    padding: '10px',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  template_name: {
    fontSize: '20px',
    fontWeight: '300',
  },
});

export const PlantillaCard = withStyles(styles, { withTheme: true })(
  PlantillaCard_
);
