import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  tiendaObtener,
  cuponObtenerCupones,
  cuponBorrar,
  cuponDesactivar,
  tiendaCuponesActivar,
  tiendaCuponesDesactivar,
} from '../../../store/actions';
import { withStyles } from '@mui/styles';
import { Card, CardHeader, CardContent, Divider } from '@mui/material';
import { ButtonUI, AlertUI, ModuleTitleUI, ToggleUI } from '../../common';
import {
  CuponesList,
  ModalBorrar,
  ModalDesactivarActivar,
} from './subcomponents';
import { WrapperLayout } from '../../../layouts/WrapperLayout';

class Cupones_ extends Component {
  state = {
    //carga de datos
    loading: {
      tienda: true,
      cupones: true,
      error: false,
    },

    modals: {
      borrar: false,
      desactivar_activar: false,
    },

    borrar: {
      idCuponesDescuento: null,
      cd_codigo: '',
    },

    desactivar_activar: {
      idCuponesDescuento: null,
      cd_desactivado: 0,
      cd_codigo: '',
    },

    //forms
    forms: {
      activar_cupones: false,
      desactivar_cupones: false,
      borrar: false,
      desactivar_activar: false,
    },

    //alerta
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  componentDidMount() {
    this.props.tiendaObtener((err, resp) => {
      this.setState({
        loading: {
          ...this.state.loading,
          tienda: false,
          error: this.state.loading.error || err ? true : false,
        },
      });
    });
    this.props.cuponObtenerCupones((err, resp) => {
      this.setState({
        loading: {
          ...this.state.loading,
          cupones: false,
          error: this.state.loading.error || err ? true : false,
        },
      });
    });
  }

  //submits
  handleActivarCuponesSubmit = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        activar_cupones: true,
      },
    });

    this.props.tiendaCuponesActivar((err, resp) => {
      this.setState({
        forms: {
          ...this.state.forms,
          activar_cupones: false,
        },
        alert: {
          ...this.state.alert,
          open: true,
          message: resp.message,
          type: err ? 'error' : 'success',
        },
      });
    });
  };
  handleDesactivarCuponesSubmit = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        desactivar_cupones: true,
      },
    });

    this.props.tiendaCuponesDesactivar((err, resp) => {
      this.setState({
        forms: {
          ...this.state.forms,
          desactivar_cupones: false,
        },
        alert: {
          ...this.state.alert,
          open: true,
          message: resp.message,
          type: err ? 'error' : 'success',
        },
      });
    });
  };
  handleSubmitBorrar = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        borrar: true,
      },
    });

    let form = this.state.borrar;

    this.props.cuponBorrar(
      { idCuponesDescuento: form.idCuponesDescuento },
      (err, resp) => {
        if (err) {
          this.setState({
            forms: {
              ...this.state.forms,
              borrar: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'error',
            },
          });
        } else {
          this.setState({
            forms: {
              ...this.state.forms,
              borrar: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'success',
            },
            modals: {
              ...this.state.modals,
              borrar: false,
            },
          });
        }
      }
    );
  };
  handleSubmitDesactivarActivar = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        desactivar_activar: true,
      },
    });

    let form = this.state.desactivar_activar;

    this.props.cuponDesactivar(
      {
        idCuponesDescuento: form.idCuponesDescuento,
        cd_desactivado: form.cd_desactivado ? 0 : 1,
      },
      (err, resp) => {
        if (err) {
          this.setState({
            forms: {
              ...this.state.forms,
              desactivar_activar: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'error',
            },
          });
        } else {
          this.setState({
            forms: {
              ...this.state.forms,
              desactivar_activar: false,
            },
            alert: {
              open: true,
              message: resp.message,
              type: 'success',
            },
            modals: {
              ...this.state.modals,
              desactivar_activar: false,
            },
          });
        }
      }
    );
  };

  //cierra las alertas
  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  //abre y cierra los modales
  modalHandler = (open, id, aditional) => {
    switch (id) {
      case 'borrar':
        if (open) {
          let cupon = aditional;
          this.setState({
            borrar: {
              ...this.state.borrar,
              idCuponesDescuento: cupon.idCuponesDescuento,
              cd_codigo: cupon.cd_codigo,
            },
            modals: {
              ...this.state.modals,
              [id]: open,
            },
          });
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              [id]: open,
            },
          });
        }
        break;
      case 'desactivar_activar':
        if (open) {
          let cupon = aditional;
          this.setState({
            desactivar_activar: {
              ...this.state.borrar,
              idCuponesDescuento: cupon.idCuponesDescuento,
              cd_codigo: cupon.cd_codigo,
              cd_desactivado: cupon.cd_desactivado,
            },
            modals: {
              ...this.state.modals,
              [id]: open,
            },
          });
        } else {
          this.setState({
            modals: {
              ...this.state.modals,
              [id]: open,
            },
          });
        }
        break;
      default:
        break;
    }
  };

  render() {
    const { classes } = this.props;

    const activar_desactivar_btn = this.props.tienda.t_cupones_descuento ? (
      <ButtonUI
        type="callf"
        label="Desactivar"
        fullWidth={false}
        minHeight={true}
        onClickFunc={this.handleDesactivarCuponesSubmit}
        isLoading={this.state.forms.desactivar_cupones}
      />
    ) : (
      <ButtonUI
        type="callf"
        label="Activar"
        fullWidth={false}
        minHeight={true}
        onClickFunc={this.handleActivarCuponesSubmit}
        isLoading={this.state.forms.activar_cupones}
      />
    );

    return (
      <WrapperLayout
        loading={this.state.loading.cupones || this.state.loading.tienda}
        error={this.state.loading.error}
      >
        <AlertUI
          open={this.state.alert.open}
          type={this.state.alert.type}
          message={this.state.alert.message}
          handleCloseAlert={this.handleCloseAlert}
        />
        <ModalBorrar
          borrar={this.state.borrar}
          handleSubmit={this.handleSubmitBorrar}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.borrar}
          form_state={this.state.forms.borrar}
        />
        <ModalDesactivarActivar
          desactivar_activar={this.state.desactivar_activar}
          handleSubmit={this.handleSubmitDesactivarActivar}
          modalHandler={this.modalHandler}
          modal_state={this.state.modals.desactivar_activar}
          form_state={this.state.forms.desactivar_activar}
        />
        <ModuleTitleUI
          title="Cupones de descuento"
          subtitles={[
            'Activando esta función podrás generar diferentes tipos de cupones de descuento.',
          ]}
          button={activar_desactivar_btn}
        />
        <ToggleUI show={this.props.tienda.t_cupones_descuento ? true : false}>
          <Card className={classes.card_below}>
            <CardHeader
              title="Mis cupones"
              className={classes.card_header}
              classes={{ action: classes.card_action_button }}
              action={
                <ButtonUI type="link" label="Agregar" link="/cupones/agregar" />
              }
            />
            <Divider />
            <CardContent className={classes.card_content}>
              <CuponesList modalHandler={this.modalHandler} />
            </CardContent>
          </Card>
        </ToggleUI>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_below: {
    ...theme.card.card,
    marginTop: '40px',
  },
  card_header: theme.card.card_header,
  card_content: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  card_action_button: theme.card.card_action_button,
});

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
    cupones: state.cupones,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    cuponObtenerCupones: (callback) => dispatch(cuponObtenerCupones(callback)),
    cuponBorrar: (data, callback) => dispatch(cuponBorrar(data, callback)),
    cuponDesactivar: (data, callback) =>
      dispatch(cuponDesactivar(data, callback)),
    tiendaCuponesActivar: (callback) =>
      dispatch(tiendaCuponesActivar(callback)),
    tiendaCuponesDesactivar: (callback) =>
      dispatch(tiendaCuponesDesactivar(callback)),
  };
};

export const Cupones = withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(Cupones_)
);
