import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  tiendaObtener,
  tiendaActualizarRedesSociales,
} from '../../store/actions';
import { Regex, RegexExtra, Validate } from '../../other';
import { Card, CardContent, Grid, Icon } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  InputUI,
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
} from '../common';
import { WrapperLayout } from '../../layouts/WrapperLayout';

class RedesSociales_ extends Component {
  state = {
    redes_sociales: {
      t_facebook: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Facebook',
        id: 't_facebook',
        name: 't_facebook',
        change_param: 'redes_sociales',
        regex: Regex.RED_SOCIAL,
        value: '',
        default_value: '',
        placeholder: 'empretiendaok',
        messages: {
          error: 'Ingrese una cuenta de facebook válida',
          error_extra: RegexExtra.RED_SOCIAL,
        },
      },
      t_instagram: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Instagram',
        id: 't_instagram',
        name: 't_instagram',
        change_param: 'redes_sociales',
        regex: Regex.RED_SOCIAL,
        value: '',
        default_value: '',
        placeholder: 'empretiendaok',
        messages: {
          error: 'Ingrese una cuenta de instragram válida',
          error_extra: RegexExtra.RED_SOCIAL,
        },
      },
      t_twitter: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Twitter',
        id: 't_twitter',
        name: 't_twitter',
        change_param: 'redes_sociales',
        regex: Regex.RED_SOCIAL,
        value: '',
        default_value: '',
        placeholder: 'empretiendaok',
        messages: {
          error: 'Ingrese una cuenta de twitter válida',
          error_extra: RegexExtra.RED_SOCIAL,
        },
      },
      t_pinterest: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Pinterest',
        id: 't_pinterest',
        name: 't_pinterest',
        change_param: 'redes_sociales',
        regex: Regex.RED_SOCIAL,
        value: '',
        default_value: '',
        placeholder: 'empretiendaok',
        messages: {
          error: 'Ingrese una cuenta de pinterest válida',
          error_extra: RegexExtra.RED_SOCIAL,
        },
      },
      t_tiktok: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'TikTok',
        id: 't_tiktok',
        name: 't_tiktok',
        change_param: 'redes_sociales',
        regex: Regex.RED_SOCIAL,
        value: '',
        default_value: '',
        placeholder: 'empretiendaok',
        messages: {
          error: 'Ingrese una cuenta de TikTok válida',
          error_extra: RegexExtra.RED_SOCIAL,
        },
      },
      t_youtube: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'YouTube',
        id: 't_youtube',
        name: 't_youtube',
        change_param: 'redes_sociales',
        regex: Regex.RED_SOCIAL,
        value: '',
        default_value: '',
        placeholder: 'c/empretienda',
        messages: {
          error: 'Ingrese un link de canal de YouTube válido',
          error_extra: RegexExtra.RED_SOCIAL,
        },
      },
    },
    loading: {
      redes_sociales: true,
      error: false,
    },
    forms: {
      redes_sociales: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  componentDidMount() {
    this.props.tiendaObtener((err, resp) => {
      if (err) {
        this.setState({
          loading: {
            ...this.state.loading,
            redes_sociales: false,
            error: true,
          },
        });
      } else {
        Validate.cargar_formulario_sin_nulos(
          this.props.tienda,
          this.state.redes_sociales,
          (redes_sociales_obj) => {
            this.setState({
              redes_sociales: redes_sociales_obj,
              loading: {
                ...this.state.loading,
                redes_sociales: false,
                error: false,
              },
            });
          }
        );
      }
    });
  }

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      {
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: input,
            },
          });
        });
      }
    );
  };

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        redes_sociales: true,
      },
    });

    let form = this.state.redes_sociales;

    if (Validate.validar_formulario(form)) {
      this.props.tiendaActualizarRedesSociales(
        {
          t_facebook: form.t_facebook.value,
          t_instagram: form.t_instagram.value,
          t_twitter: form.t_twitter.value,
          t_pinterest: form.t_pinterest.value,
          t_tiktok: form.t_tiktok.value,
          t_youtube: form.t_youtube.value,
        },
        (err, resp) => {
          this.setState({
            forms: {
              ...this.state.forms,
              redes_sociales: false,
            },
            alert: {
              ...this.state.alert,
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            },
          });
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          forms: {
            ...this.state.forms,
            redes_sociales: false,
          },
          redes_sociales: new_form,
        });
      });
    }
  };

  render() {
    let classes = this.props.classes;
    return (
      <WrapperLayout
        loading={this.state.loading.redes_sociales}
        error={this.state.loading.error}
      >
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <form onSubmit={this.handleSubmit}>
              <ModuleTitleUI
                title="Redes sociales"
                subtitles={[
                  'En esta sección podés colocar los nombres de usuario de tus redes sociales para que aparezcan en tu tienda online.',
                  'Recordá agregar el link de tu tienda a tus redes sociales para tener todo vinculado.',
                ]}
              />
              <Card className={classes.card}>
                <CardContent className={classes.card_content}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        adornment={
                          <Icon
                            sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                            className="fab fa-facebook-f"
                          />
                        }
                        adornment_extra="/"
                        input={this.state.redes_sociales.t_facebook}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        adornment={
                          <Icon
                            sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                            className="fab fa-instagram"
                          />
                        }
                        adornment_extra="/"
                        input={this.state.redes_sociales.t_instagram}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        adornment={
                          <Icon
                            sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                            className="fab fa-twitter"
                          />
                        }
                        adornment_extra="/"
                        input={this.state.redes_sociales.t_twitter}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        adornment={
                          <Icon
                            sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                            className="fab fa-pinterest-p"
                          ></Icon>
                        }
                        adornment_extra="/"
                        input={this.state.redes_sociales.t_pinterest}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        adornment={
                          <Icon
                            sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                            className="fab fa-tiktok"
                          ></Icon>
                        }
                        adornment_extra="/@"
                        input={this.state.redes_sociales.t_tiktok}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={this.handleChange}
                        adornment={
                          <Icon
                            sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                            className={`fab fa-youtube
                            ${classes.icon_width}`}
                          ></Icon>
                        }
                        adornment_extra="/"
                        input={this.state.redes_sociales.t_youtube}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <ModuleButtonBoxUI>
                <ButtonUI
                  type="submit"
                  label="Guardar"
                  minHeight={true}
                  isLoading={this.state.forms.redes_sociales}
                />
              </ModuleButtonBoxUI>
            </form>
          </Grid>
        </Grid>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
  card_subheader: theme.card.card_subheader,
  card_subheader_icon: theme.card.card_subheader_icon,
  icon_width: {
    width: '30px',
  },
});

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    tiendaActualizarRedesSociales: (data, callback) =>
      dispatch(tiendaActualizarRedesSociales(data, callback)),
  };
};

export const RedesSociales = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(RedesSociales_)
);
