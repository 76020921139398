import React, { Component } from 'react';
import { Grid } from '@mui/material';

import { ToggleUI } from '../../common';
import { ProgressCard } from '../subcomponents/ProgressCard';

import { UploadSpreadsheet } from './UploadSpreadsheet';
import { ValidarFormato } from './ValidarFormato';
import { ValidarDatos } from './ValidarDatos';
import { ErroresEnFormato } from './ErroresEnFormato';
import { ErroresEnDatos } from './ErroresEnDatos';
import { FormatoChunks } from './FormatoChunks';
import { EnviarChunks } from './EnviarChunks';
import { ProcesarChunks } from './ProcesarChunks';
import { ErrorInesperado } from './ErrorInesperado';
import { ChunkSepararNuevosEditados } from './ChunkSepararNuevosEditados';

import { convertListOfBackendErrors } from './ImportError';
import { ChunkSeparateChangedFromNewProducts } from './chunkUtils';
import { FiltrarProducto } from './FiltrarProducto';

const procesamiento_etapa = {
  subir_planilla: 'SUBIR_PLANILLA',

  validar_formato: 'VALIDAR_FORMATO',
  error_en_formato: 'ERROR_EN_FORMATO',

  validar_datos: 'VALIDAR_DATOS',
  error_en_datos: 'ERROR_EN_DATOS',

  formato_chunks: 'FORMATO_CHUNKS',

  enviar_chunks: 'ENVIAR_CHUNKS',
  error_en_chunks: 'ERROR_EN_CHUNKS',

  separar_nuevos_editados: 'SEPARAR_NUEVOS_EDITADOS',

  confirmar_cambios: 'CONFIRMAR_CAMBIOS',

  procesar_chunks: 'PROCESAR_CHUNKS',

  error_en_tienda: 'ERROR_EN_TIENDA',
  error_inesperado: 'ERROR_INESPERADO',

  impactar_tienda: 'IMPACTAR_TIENDA',
};

const CHUNK_SIZE = 100;

const noOpAsync = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('resolved');
    }, 0);
  });
};

export class PlanillaCicloDeVida extends Component {
  //Por tamaño quedan fuera de state
  planilla_datos = []; //todas las celdas a validar y enviar
  planilla_errores = []; //errores encontrados
  products_hash_table = []; //cada entrada contendrá las variantes de un producto
  planilla_chunks = []; //cada elemento es un chunk de 100 o menos productos
  planilla_chunks_errores = [];
  planilla_a_confirmar_nuevos = [];
  planilla_a_confirmar_editados = [];

  state = {
    progress_card: {
      title: 'Procesando tus datos ingresados',
      subtitle: 'Validando la estructura de tu documento',
      text: 'Por favor no cierres esta ventana o el proceso no podrá finalizarse.',
      completion_percentage: 0,
    },
    etapa: procesamiento_etapa.subir_planilla,
    format_validation: {
      err: false,
      valid_columns: [],
      error_messages: [],
    },
    planilla_metadata: {
      cantidad_registros: 0,
      registro_de_titulos: [],
      normalized_product_names: [],
    },
    chunks: {
      chunks_index: 0,
      chunks_cantidad: 0,
      chunk_size: CHUNK_SIZE,
      chunks_hay_errores: false,
      chunks_jsons: [],
    },
    constantes: {
      validar_titulo_inicio: 0, //inicio: 0%
      validar_titulo_percentage: 5,
      validar_datos_inicio: 5, //inicio: 5%
      validar_datos_percentage: 10,
      formato_chunks_inicio: 15, //inicio: 15%
      formato_chunks_percentage: 10,
      enviar_chunks_inicio: 25, //inicio: 25%
      enviar_chunks_percentage: 30,
      //confirmar_procesamiento. estado intermedio con intervención del usuario
      procesar_chunks_inicio: 55, //inicio: 55%
      procesar_chunks_percentage: 5,
      impactar_chunks: 40, //inicio: 60% No se usa acá. Se muestra en el endpoint de track de importación
    },
  };

  asignarPlanilla = (rows) => {
    this.planilla_datos = rows.filter((row) => rows.indexOf(row) > 0); //sin los títulos
    // this.planilla_errores = [];
    this.products_hash_table = [];
    this.planilla_chunks = [];
    //this.planilla_chunks_errores = [];
    this.planilla_a_confirmar_nuevos = [];
    this.planilla_a_confirmar_editados = [];

    /**
     * Debido a que asignar planilla se llama desde SubirPlanilla y
     * desde cualquier instancia de error, debo inicializar todos
     * los parámetros de procesamiento
     */
    this.setState({
      planilla_metadata: {
        cantidad_registros: rows.length,
        registro_de_titulos: rows[0],
        normalized_product_names: [],
      },
    });
  };
  //+validar formato
  validarFormatoStart = () => {
    this.planilla_errores = [];
    this.planilla_chunks_errores = [];
    this.setState({
      progress_card: {
        title: 'Procesando tus datos ingresados',
        subtitle: 'Validando la estructura de tu documento',
        text: 'Por favor no cierres esta ventana o el proceso no podrá finalizarse.',
        completion_percentage: 0,
      },
      format_validation: {
        err: false,
        valid_columns: [],
        error_messages: [],
      },
      etapa: procesamiento_etapa.validar_formato,
    });
  };

  handleValidarFormatoFinish = (err, valid_columns, error_messages) => {
    this.setState({
      // ...this.state,
      etapa: err
        ? procesamiento_etapa.error_en_formato
        : procesamiento_etapa.validar_datos,
      format_validation: {
        err,
        valid_columns,
        error_messages,
      },
      progress_card: {
        ...this.state.progress_card,
        subtitle: 'Validando la información ingresada',
        completion_percentage: this.state.constantes.validar_titulo_percentage,
      },
    });
  };

  handleUpdateCompletionPercentage = (completion_percentage) => {
    this.setState({
      progress_card: {
        ...this.state.progress_card,
        completion_percentage: Math.ceil(completion_percentage),
      },
    });
  };
  //-validar formato
  //+validar datos
  handleValidarDatosFinish = (
    err,
    import_errors,
    products_hash_table,
    normalized_product_names
  ) => {
    if (err) {
      this.planilla_errores = [...import_errors]; //todo:consultar¿es necesaria la copia?
      this.setState({
        etapa: procesamiento_etapa.error_en_datos,
      });
    } else {
      this.products_hash_table = products_hash_table; //cada entrada tiene las variantes del producto

      this.setState({
        etapa: procesamiento_etapa.formato_chunks,
        planilla_metadata: {
          ...this.planilla_metadata,
          normalized_product_names,
        },
      });
    }
  };
  //-validar datos
  //+formato chunks
  handleFormatoChunksFinish = (chunks) => {
    this.planilla_chunks = chunks;

    this.setState({
      //todo: ir a enviar_chunks
      etapa: procesamiento_etapa.enviar_chunks, //todo llamar a .enviar_chunks
      chunks: {
        chunks_index: 0,
        chunks_cantidad: chunks.length,
        chunk_size: CHUNK_SIZE,
        chunks_hay_errores: false,
        chunks_jsons: [],
      },
    });
  };
  //-formato chunks
  //+enviar_chunks. alterna componentes para ir tomando this.planilla_chunks[i]
  handleChunkEnviado = (err, resp) => {
    if (err) {
      const chunk_errors = convertListOfBackendErrors(resp.data);
      this.planilla_chunks_errores.push(...chunk_errors);
    }
    const chunks_hay_errores = this.state.chunks.chunks_hay_errores || err;
    let new_state;
    if (
      this.state.chunks.chunks_index ===
      this.state.chunks.chunks_cantidad - 1
    ) {
      //veo si algún chunk tuvo errores
      new_state = {
        //etapa: chunks_hay_errores ? procesamiento_etapa.error_en_chunks : procesamiento_etapa.confirmar_cambios,
        etapa: chunks_hay_errores
          ? procesamiento_etapa.error_en_chunks
          : procesamiento_etapa.separar_nuevos_editados,
        chunks: {
          ...this.state.chunks,
          chunks_jsons: [...this.state.chunks.chunks_jsons, resp.data.batch],
        },
      };

      if (err) {
        new_state = {
          ...new_state,
          chunks: {
            ...this.state.chunks,
            chunks_hay_errores: chunks_hay_errores,
          },
        };
      }
    } else {
      new_state = {
        chunks: {
          ...this.state.chunks,
          chunks_index: this.state.chunks.chunks_index + 1,
          chunks_jsons: [...this.state.chunks.chunks_jsons, resp.data.batch],
          chunks_hay_errores: chunks_hay_errores,
        },
      };
    }

    this.setState(new_state);
  };
  //-enviar_chunks
  //+confirmar
  handleVolverASubir = () => {
    this.setState({
      etapa: procesamiento_etapa.subir_planilla,
    });
  };
  handleConfirmarProceso = () => {
    this.setState({
      etapa: procesamiento_etapa.procesar_chunks,
    });
  };
  //-confirmar
  //+Separar nuevos de editados
  handleChunkSepararNuevosEditados = () => {
    for (const chunk of this.planilla_chunks) {
      const { new_products, edited_products } =
        ChunkSeparateChangedFromNewProducts(chunk);
      if (new_products.length > 0)
        this.planilla_a_confirmar_nuevos.push(...new_products);
      if (edited_products.length > 0)
        this.planilla_a_confirmar_editados.push(...edited_products);
    }
  };

  handleFinishSepararNuevosEditados = () => {
    this.setState({
      etapa: procesamiento_etapa.confirmar_cambios,
    });
  };
  //-Separar nuevos de editados
  //+procesar_chunks
  handleProcesarChunksFinish = (err, resp) => {
    if (err) {
      this.setState({
        etapa: procesamiento_etapa.error_inesperado,
      });
    } else {
      //todo: ir al avance del track
      this.props.handleImpactandoTienda(resp.data.idTrack);
    }
  };
  //-procesar_chunks
  render() {
    const { handleVolverAApagar, dimensiones_requeridas } = this.props;
    return (
      <Grid container spacing={1.5}>
        <ToggleUI
          show={
            this.state.etapa === procesamiento_etapa.subir_planilla
              ? true
              : false
          }
        >
          <Grid item xs={12}>
            <UploadSpreadsheet
              exportStart={this.props.exportStart}
              exportFinish={this.props.exportFinish}
              categorias_flatten_jerarquico={
                this.props.categorias_flatten_jerarquico
              }
              assignSpeadsheet={this.asignarPlanilla}
              startFormatValidation={this.validarFormatoStart}
              handleTurnOffAgain={handleVolverAApagar}
            />
          </Grid>
        </ToggleUI>
        <ToggleUI
          show={
            this.state.etapa === procesamiento_etapa.validar_formato
              ? true
              : false
          }
        >
          <Grid item xs={12}>
            <ProgressCard
              title={this.state.progress_card.title}
              subtitle={this.state.progress_card.subtitle}
              text={this.state.progress_card.text}
              completion_percentage={
                this.state.progress_card.completion_percentage
              }
            />
            <ValidarFormato
              noOpAsync={noOpAsync}
              planilla_titulos={
                this.state.planilla_metadata.registro_de_titulos
              }
              cantidad_registros={
                this.state.planilla_metadata.cantidad_registros
              }
              handleValidarFormatoFinish={this.handleValidarFormatoFinish}
            />
          </Grid>
        </ToggleUI>
        <ToggleUI
          show={
            this.state.etapa === procesamiento_etapa.error_en_formato
              ? true
              : false
          }
        >
          <Grid item xs={12}>
            <ErroresEnFormato
              error_messages={this.state.format_validation.error_messages}
              asignarPlanilla={this.asignarPlanilla}
              validarFormatoStart={this.validarFormatoStart}
              handleVolverAApagar={handleVolverAApagar}
            />
          </Grid>
        </ToggleUI>
        <ToggleUI
          show={
            this.state.etapa === procesamiento_etapa.validar_datos
              ? true
              : false
          }
        >
          <Grid item xs={12}>
            <ProgressCard
              title={this.state.progress_card.title}
              subtitle={this.state.progress_card.subtitle}
              text={this.state.progress_card.text}
              completion_percentage={
                this.state.progress_card.completion_percentage
              }
            />
            <ValidarDatos
              noOpAsync={noOpAsync}
              planilla_datos={this.planilla_datos}
              valid_columns={this.state.format_validation.valid_columns}
              handleValidarDatosFinish={this.handleValidarDatosFinish}
              initial_completion_percentage={
                this.state.constantes.validar_datos_inicio
              }
              validar_datos_percentage={
                this.state.constantes.validar_datos_percentage
              }
              handleUpdateCompletionPercentage={
                this.handleUpdateCompletionPercentage
              }
              dimensiones_requeridas={dimensiones_requeridas}
            />
          </Grid>
        </ToggleUI>
        <ToggleUI
          show={
            this.state.etapa === procesamiento_etapa.error_en_datos
              ? true
              : false
          }
        >
          <Grid item xs={12}>
            <ErroresEnDatos
              import_errors={this.planilla_errores}
              asignarPlanilla={this.asignarPlanilla}
              validarFormatoStart={this.validarFormatoStart}
              handleVolverAApagar={handleVolverAApagar}
            />
          </Grid>
        </ToggleUI>
        <ToggleUI
          show={
            this.state.etapa === procesamiento_etapa.formato_chunks
              ? true
              : false
          }
        >
          <Grid item xs={12}>
            <ProgressCard
              title={this.state.progress_card.title}
              subtitle={this.state.progress_card.subtitle}
              text={this.state.progress_card.text}
              completion_percentage={
                this.state.progress_card.completion_percentage
              }
            />
            <FormatoChunks
              noOpAsync={noOpAsync}
              chunk_size={this.state.chunks.chunk_size}
              products_hash_table={this.products_hash_table}
              normalized_product_names={
                this.state.planilla_metadata.normalized_product_names
              }
              initial_completion_percentage={
                this.state.constantes.formato_chunks_inicio
              }
              formato_chunks_percentage={
                this.state.constantes.formato_chunks_percentage
              }
              handleUpdateCompletionPercentage={
                this.handleUpdateCompletionPercentage
              }
              handleFormatoChunksFinish={this.handleFormatoChunksFinish}
            />
          </Grid>
        </ToggleUI>
        <ToggleUI
          show={
            this.state.etapa === procesamiento_etapa.enviar_chunks
              ? true
              : false
          }
        >
          <ProgressCard
            title={this.state.progress_card.title}
            subtitle={this.state.progress_card.subtitle}
            text={this.state.progress_card.text}
            completion_percentage={
              this.state.progress_card.completion_percentage
            }
          />
          <ToggleUI
            show={this.state.chunks.chunks_index % 2 === 0 ? true : false}
          >
            <Grid item xs={12}>
              <EnviarChunks
                noOpAsync={noOpAsync}
                chunk={this.planilla_chunks[this.state.chunks.chunks_index]}
                chunks_index={this.state.chunks.chunks_index}
                chunks_cantidad={this.state.chunks.chunks_cantidad}
                initial_completion_percentage={
                  this.state.constantes.enviar_chunks_inicio
                }
                enviar_chunks_percentage={
                  this.state.constantes.enviar_chunks_percentage
                }
                handleUpdateCompletionPercentage={
                  this.handleUpdateCompletionPercentage
                }
                handleChunkEnviado={this.handleChunkEnviado}
              />
            </Grid>
          </ToggleUI>
          <ToggleUI
            show={this.state.chunks.chunks_index % 2 !== 0 ? true : false}
          >
            <Grid item xs={12}>
              <EnviarChunks
                noOpAsync={noOpAsync}
                chunk={this.planilla_chunks[this.state.chunks.chunks_index]}
                chunks_index={this.state.chunks.chunks_index}
                chunks_cantidad={this.state.chunks.chunks_cantidad}
                initial_completion_percentage={
                  this.state.constantes.enviar_chunks_inicio
                }
                enviar_chunks_percentage={
                  this.state.constantes.enviar_chunks_percentage
                }
                handleUpdateCompletionPercentage={
                  this.handleUpdateCompletionPercentage
                }
                handleChunkEnviado={this.handleChunkEnviado}
              />
            </Grid>
          </ToggleUI>
        </ToggleUI>
        <ToggleUI
          show={
            this.state.etapa === procesamiento_etapa.error_en_chunks
              ? true
              : false
          }
        >
          <Grid item xs={12}>
            <ErroresEnDatos
              import_errors={this.planilla_chunks_errores}
              asignarPlanilla={this.asignarPlanilla}
              validarFormatoStart={this.validarFormatoStart}
              handleVolverAApagar={handleVolverAApagar}
            />
          </Grid>
        </ToggleUI>

        <ToggleUI
          show={
            this.state.etapa === procesamiento_etapa.separar_nuevos_editados
              ? true
              : false
          }
        >
          <Grid item xs={12}>
            <ChunkSepararNuevosEditados
              handleChunkSepararNuevosEditados={
                this.handleChunkSepararNuevosEditados
              }
              handleFinishSepararNuevosEditados={
                this.handleFinishSepararNuevosEditados
              }
            />
          </Grid>
        </ToggleUI>
        <ToggleUI
          show={
            this.state.etapa === procesamiento_etapa.confirmar_cambios
              ? true
              : false
          }
        >
          <Grid item xs={12}>
            <FiltrarProducto
              new_products={this.planilla_a_confirmar_nuevos}
              edited_products={this.planilla_a_confirmar_editados}
              handleConfirmarProceso={this.handleConfirmarProceso}
              handleVolverASubir={this.handleVolverASubir}
            />
          </Grid>
        </ToggleUI>
        <ToggleUI
          show={
            this.state.etapa === procesamiento_etapa.procesar_chunks
              ? true
              : false
          }
        >
          <Grid item xs={12}>
            <ProgressCard
              title={this.state.progress_card.title}
              subtitle={this.state.progress_card.subtitle}
              text={this.state.progress_card.text}
              completion_percentage={
                this.state.progress_card.completion_percentage
              }
            />
            <ProcesarChunks
              noOpAsync={noOpAsync}
              chunks_jsons={this.state.chunks.chunks_jsons}
              initial_completion_percentage={
                this.state.progress_card.completion_percentage
              }
              procesar_chunks_percentage={
                this.state.constantes.procesar_chunks_percentage
              }
              handleUpdateCompletionPercentage={
                this.handleUpdateCompletionPercentage
              }
              handleProcesarChunksFinish={this.handleProcesarChunksFinish}
            />
          </Grid>
        </ToggleUI>
        <ToggleUI
          show={
            this.state.etapa === procesamiento_etapa.error_inesperado
              ? true
              : false
          }
        >
          <Grid item xs={12}>
            <ErrorInesperado handleVolverAApagar={handleVolverAApagar} />
          </Grid>
        </ToggleUI>
      </Grid>
    );
  }
}
