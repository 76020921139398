import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';
import ventaDummy from '../../__mocks__/ventaDummy';

const ventaObtenerVentas = (data, callback) => {
  if (Config.MOCK_ACTIVE) {
    const error = false;
    const resp = ventaDummy.ventaObtenerVentas();

    return () => new Promise((resolve) => resolve(callback(error, resp)));
  }

  let page = data.page;
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/venta', {
        params: data,
      })
      .then((resp) => {
        //guardo en redux
        const ventas = resp.data.data;

        if (page <= 0) {
          dispatch({ type: Reducers.VENTA_GUARDAR, data: ventas });
        } else {
          dispatch({
            type: Reducers.VENTA_GUARDAR_NO_CLEAN,
            data: ventas,
          });
        }

        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const ventaObtenerVentasExportar = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/venta', {
        params: data,
      })
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const ventaObtenerVenta = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/venta/' + data.v_numero)
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const ventaArchivarVenta = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/venta/' + data.v_numero + '/archivar')
      .then((resp) => {
        //guardo en redux
        const venta = resp.data.data;
        dispatch({ type: Reducers.VENTA_ARCHIVAR, data: venta });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const ventaArchivarVentaDetail = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/venta/' + data.v_numero + '/archivar')
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const ventaCancelarVenta = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        Config.BACKEND_ENDPOINT + '/venta/' + data.v_numero + '/cancelar',
        data
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const ventaDevolverStock = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        Config.BACKEND_ENDPOINT + '/venta/' + data.v_numero + '/devolver-stock'
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const ventaDevolverDinero = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        Config.BACKEND_ENDPOINT +
          '/venta/' +
          data.v_numero +
          '/devolver-dinero',
        data
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const ventaDarAltaEnvioOca = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        Config.BACKEND_ENDPOINT + '/venta/' + data.v_numero + '/alta-envio-oca',
        data
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const ventaDarAltaEnvioEmprepack = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        Config.BACKEND_ENDPOINT +
          '/venta/' +
          data.v_numero +
          '/alta-envio-emprepack',
        data
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const ventaNotificarEnvioColocarEnviado = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        Config.BACKEND_ENDPOINT +
          '/venta/' +
          data.v_numero +
          '/notificar-envio',
        data
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const ventaEntregarDescargables = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        Config.BACKEND_ENDPOINT +
          '/venta/' +
          data.v_numero +
          '/entregar-descargables',
        data
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const ventaEnviarMensajeCliente = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        Config.BACKEND_ENDPOINT + '/venta/' + data.v_numero + '/enviar-mensaje',
        data
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const ventaEditarDatosFacturacion = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        Config.BACKEND_ENDPOINT + '/venta/' + data.v_numero + '/facturacion',
        data
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const ventaColocarComoRetirada = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        Config.BACKEND_ENDPOINT + '/venta/' + data.v_numero + '/marcar-retirada'
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const ventaColocarComoAbonada = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        Config.BACKEND_ENDPOINT + '/venta/' + data.v_numero + '/marcar-abonada'
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const ventaActualizacionManualMercadoPago = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        Config.BACKEND_ENDPOINT +
          '/venta/' +
          data.v_numero +
          '/actualizacion-manual-mp',
        data
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const ventaAbonarEnvio = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .get(
        Config.BACKEND_ENDPOINT + '/venta/' + data.v_numero + '/abonar-envio',
        {
          params: data,
        }
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const ventaCancelarEnvio = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        Config.BACKEND_ENDPOINT + '/venta/' + data.v_numero + '/cancelar-envio',
        data
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const ventaEditarDatosEnvio = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/venta/' + data.v_numero + '/envio', data)
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const ventaMarcarComoFacturada = (data, callback) => {
  let fdata = new FormData();
  if (data.v_factura_pdf !== null)
    fdata.append('v_factura_pdf', data.v_factura_pdf);

  return (dispatch, _getState) => {
    axios
      .post(
        Config.BACKEND_ENDPOINT + '/venta/' + data.v_numero + '/facturado',
        fdata,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

export {
  ventaObtenerVentas,
  ventaObtenerVentasExportar,
  ventaObtenerVenta,
  ventaCancelarVenta,
  ventaDevolverStock,
  ventaArchivarVenta,
  ventaArchivarVentaDetail,
  ventaDevolverDinero,
  ventaDarAltaEnvioOca,
  ventaDarAltaEnvioEmprepack,
  ventaEntregarDescargables,
  ventaNotificarEnvioColocarEnviado,
  ventaColocarComoRetirada,
  ventaEnviarMensajeCliente,
  ventaEditarDatosFacturacion,
  ventaColocarComoAbonada,
  ventaActualizacionManualMercadoPago,
  ventaAbonarEnvio,
  ventaCancelarEnvio,
  ventaEditarDatosEnvio,
  ventaMarcarComoFacturada,
};
