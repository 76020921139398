import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import { uploadImageEditor } from '../../store/actions';
import { Grid } from '@mui/material';
import { ModalUI, InputUI, AlertUI, EmpicUI } from './';
import { Validate, Config, Regex, RegexExtra } from '../../other';
import 'react-quill/dist/quill.snow.css';

class QuillUI_ extends Component {
  quillRef = React.createRef();
  reactQuillRef = React.createRef();

  state = {
    link: {
      html_link: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Link a utilizar',
        id: 'html_link',
        name: 'html_link',
        change_param: 'link',
        regex: Regex.LINK,
        value: '',
        default_value: '',
        placeholder: ' ',
        messages: {
          help: 'Si el link va a un sitio web externo, debe poseer http o https al inicio',
          error: 'Por favor, ingrese un link válido',
          error_extra: RegexExtra.LINK,
        },
      },
    },
    video: {
      html_video: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Link del video',
        id: 'html_video',
        name: 'html_video',
        change_param: 'video',
        regex: Regex.LINK,
        value: '',
        default_value: '',
        placeholder: ' ',
        messages: {
          help: 'Acá ingresá el link del video deseado',
          error: 'Por favor, ingrese un link de video válido',
          error_extra: RegexExtra.LINK,
        },
      },
    },
    upload: {
      validate: false,
      auth: false,
      file: null,
      required: true,
      label: '',
      id: 'quill',
      sublabels: [],
      files: [],
      messages: {
        help: '',
        error: '',
      },
    },
    forms: {
      upload: false,
      link: false,
      video: false,
    },
    modals: {
      upload: false,
      link: false,
      video: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  componentDidMount() {
    this.attachQuillRefs();
  }

  componentDidUpdate() {
    this.attachQuillRefs();
  }

  attachQuillRefs = () => {
    if (typeof this.reactQuillRef.getEditor !== 'function') return;
    this.quillRef = this.reactQuillRef.getEditor();
  };

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      {
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: input,
            },
          });
        });
      }
    );
  };

  handleChangeFile = (err, data) => {
    this.setState({
      upload: data,
    });
  };

  imageHandler = (link) => {
    let range = this.quillRef.getSelection();
    let position = range ? range.index : 0;
    if (link) {
      this.quillRef.insertEmbed(position, 'image', link, 'user');
      this.quillRef.setSelection(position + 1);
    }
  };

  modalHandlerLink = (value) => {
    if (value) {
      let selection = this.quillRef.getSelection();
      if (selection.length) {
        this.setState({
          modals: {
            ...this.state.modals,
            link: !this.state.modals.link,
          },
        });
      } else {
        this.setState({
          alert: {
            ...this.state.alert,
            open: true,
            message:
              'Debes seleccionar un texto o imagen para insertar un link',
            type: 'error',
          },
        });
      }
    } else {
      this.quillRef.format('link', false);
      let form_obj = Validate.limpiar_formulario(this.state.link);
      this.setState({
        link: form_obj,
        modals: {
          ...this.state.modals,
          link: !this.state.modals.link,
        },
      });
    }
  };

  modalHandlerVideo = (value) => {
    if (value) {
      this.setState({
        modals: {
          ...this.state.modals,
          video: !this.state.modals.video,
        },
      });
    } else {
      this.quillRef.format('video', false);
      let form_obj = Validate.limpiar_formulario(this.state.video);
      this.setState({
        video: form_obj,
        modals: {
          ...this.state.modals,
          video: !this.state.modals.video,
        },
      });
    }
  };

  modalHandler = (args) => {
    this.setState({
      modals: {
        ...this.state.modals,
        upload: !this.state.modals.upload,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        upload: true,
      },
    });

    let form = this.state.upload;

    if (form.auth) {
      this.props.uploadImageEditor(
        {
          file: form.files[0].file,
        },
        (err, resp) => {
          if (err) {
            this.setState({
              forms: {
                ...this.state.forms,
                upload: false,
              },
              alert: {
                ...this.state.alert,
                open: true,
                message: resp.message,
                type: 'error',
              },
            });
          } else {
            this.imageHandler(resp.data.link);
            this.setState({
              forms: {
                ...this.state.forms,
                upload: false,
              },
              modals: {
                ...this.state.modals,
                upload: false,
              },
              upload: {
                ...this.state.upload,
                validate: false,
                auth: false,
                files: [],
              },
            });
          }
        }
      );
    } else {
      this.setState({
        alert: {
          ...this.state.alert,
          open: true,
          message: 'Por favor, seleccione una imagen',
          type: 'error',
        },
      });
    }
  };

  handleSubmitLink = (e) => {
    e.preventDefault();

    let form = this.state.link;

    if (Validate.validar_formulario(form)) {
      this.quillRef.format('link', form.html_link.value);
      this.modalHandlerLink(false);
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          link: new_form,
        });
      });
    }
  };

  handleSubmitVideo = (e) => {
    e.preventDefault();

    let form = this.state.video;

    if (Validate.validar_formulario(form)) {
      let video_link = form.html_video.value;
      let format = false;

      //reviso si necesito manipular el video de youtube
      if (Validate.contiene_substring(video_link, 'youtu.be/')) {
        video_link = video_link.split('youtu.be/')[1];
        format = true;
      } else if (Validate.contiene_substring(video_link, '/watch?v=')) {
        if (Validate.contiene_substring(video_link, '&list=')) {
          video_link = video_link.split('&list=')[0];
          video_link = video_link.split('/watch?v=')[1];
          format = true;
        } else {
          video_link = video_link.split('/watch?v=')[1];
          format = true;
        }
      }
      if (format) {
        video_link = 'https://www.youtube.com/embed/' + video_link;
      }

      this.quillRef.format('video', video_link);
      this.modalHandlerVideo(false);
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          video: new_form,
        });
      });
    }
  };

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  render() {
    let { contenido, placeholder, handleChangeQuill } = this.props;

    return (
      <div>
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <ModalUI
          open={this.state.modals.video}
          id="video"
          title="Agregar video"
          modalHandler={this.modalHandlerVideo}
          aditional_param="video"
          handleSubmit={this.handleSubmitVideo}
          button_label="Agregar"
          button_loading={this.state.forms.video}
        >
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <InputUI
                handleChange={this.handleChange}
                input={this.state.video.html_video}
              />
            </Grid>
          </Grid>
        </ModalUI>
        <ModalUI
          open={this.state.modals.link}
          id="link"
          title="Agregar link"
          modalHandler={this.modalHandlerLink}
          aditional_param="link"
          handleSubmit={this.handleSubmitLink}
          button_label="Agregar"
          button_loading={this.state.forms.link}
        >
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <InputUI
                handleChange={this.handleChange}
                input={this.state.link.html_link}
              />
            </Grid>
          </Grid>
        </ModalUI>
        <ModalUI
          open={this.state.modals.upload}
          id="upload"
          title="Agregar imagen"
          modalHandler={this.modalHandler}
          aditional_param="upload"
          handleSubmit={this.handleSubmit}
          button_label="Subir"
          button_disabled={!this.state.upload.auth}
          button_loading={this.state.forms.upload}
        >
          <EmpicUI
            data={this.state.upload}
            max_files={1}
            max_size={7}
            allowed_files={['jpg', 'png', 'jpeg', 'webp']}
            input_allowed_mime="image/jpg,image/jpeg,image/png,image/webp"
            handleChange={this.handleChangeFile}
            cdn_prefix={Config.CLOUDFRONT_DESCIMAGES_CDN}
            row_height="200px"
            reducer_max_width={960}
            reducer_max_height={960}
            xs={12}
            sm={12}
            md={12}
            lg={12}
          />
        </ModalUI>
        <ReactQuill
          value={contenido}
          onChange={handleChangeQuill}
          placeholder={placeholder}
          ref={(el) => {
            this.reactQuillRef = el;
          }}
          modules={{
            toolbar: {
              container: [
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [
                  { list: 'ordered' },
                  { list: 'bullet' },
                  { indent: '-1' },
                  { indent: '+1' },
                ],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }],
                [{ align: [] }],
                ['link', 'image', 'video'],
                ['clean'],
              ],
              handlers: {
                image: this.modalHandler,
                link: this.modalHandlerLink,
                video: this.modalHandlerVideo,
              },
            },
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
  return {
    uploadImageEditor: (data, callback) =>
      dispatch(uploadImageEditor(data, callback)),
  };
};

export const QuillUI = connect(mapStateToProps, mapDispatchToProps)(QuillUI_);
