import React from 'react';
import { Grid } from '@mui/material';
import { ModalUI, InputUI, SelectUI, ToggleUI } from '../../common';

const EfectivoModal = (props) => {
  const {
    modals,
    inputs,
    forms,
    selects,
    modalHandler,
    handleSubmit,
    handleChange,
    handleChangeSelect,
  } = props;

  return (
    <ModalUI
      title="Configurar método de pago efectivo"
      open={modals.activar_editar_efectivo}
      id="activar_editar_efectivo"
      button_label="Guardar"
      button_loading={forms.activar_editar_efectivo}
      modalHandler={modalHandler}
      handleSubmit={handleSubmit}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <SelectUI
            data={inputs.mp_efectivo_descuento}
            options={selects.opciones_mp_efectivo_descuento}
            handleChangeSelect={handleChangeSelect}
          />
        </Grid>
        <ToggleUI show={inputs.mp_efectivo_descuento.value ? true : false}>
          <Grid item xs={12}>
            <InputUI
              input={inputs.mp_efectivo_porcentaje}
              end_adornment="%"
              step="1"
              handleChange={handleChange}
            />
          </Grid>
        </ToggleUI>
        <Grid item xs={12}>
          <InputUI
            input={inputs.mp_efectivo_descripcion}
            multiline
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { EfectivoModal };
