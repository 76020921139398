import React from 'react';
import { ButtonUI } from '../../common';
import { CustomCardUI } from '../components/CustomCardUI';
import { GridItemCard } from '../components/GridItemCard';
import { Config } from '../../../other';

const Acordar = (props) => {
  const {
    metodos_pago,
    forms,
    handleActivarAcordarSubmit,
    handleDesactivarAcordarSubmit,
  } = props;

  const cargarBoton = () => {
    if (metodos_pago.mp_acordar) {
      return (
        <ButtonUI
          type="callf"
          onClickFunc={handleDesactivarAcordarSubmit}
          label="Desactivar"
          size="large"
          isLoading={forms.desactivar_acordar}
        />
      );
    } else {
      return (
        <ButtonUI
          type="callf"
          onClickFunc={handleActivarAcordarSubmit}
          label="Activar"
          size="large"
          isLoading={forms.activar_acordar}
        />
      );
    }
  };

  return (
    <GridItemCard lg={6}>
      <CustomCardUI
        title="Acordar"
        subtitle={
          metodos_pago.mp_acordar ? 'Método activo' : 'Método no activo'
        }
        info="Te permitirá acordar el pago con tu comprador luego de la venta."
        image={`${Config.CLOUDFRONT_CDN_PROD}/panel/medios_pago/acordar-card-img.png`}
      >
        {cargarBoton()}
      </CustomCardUI>
    </GridItemCard>
  );
};

export { Acordar };
