import React from 'react';
import { Card, CardContent, Table, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import SalesTableBody from './SalesTableBody';
import SalesTableHead from './SalesTableHead';

const VentasList_ = ({ classes, modalHandler }) => {
  const { idCountry, ventas } = useSelector((state) => ({
    idCountry: state.tienda.Country_idCountry,
    ventas: state.ventas,
  }));

  if (!ventas.length) {
    return (
      <Card className={classes.card}>
        <CardContent className={classes.card_content}>
          <Typography className={classes.list_item_title}>
            No se han encontrado ordenes
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.card_content_no_padding}>
        <Table className={classes.table}>
          <SalesTableHead classes={classes} />
          <SalesTableBody
            ventas={ventas}
            classes={classes}
            idCountry={idCountry}
            modalHandler={modalHandler}
          />
        </Table>
      </CardContent>
    </Card>
  );
};

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: '100%',
  },
  card: {
    ...theme.card.card,
    padding: '0px !important',
    marginBottom: '24px',
  },
  card_content: theme.card.card_content,
  card_content_no_padding: {
    ...theme.card.card_content,
    padding: '0px !important',
    overflowX: 'auto',
  },
  card_actions: theme.card.card_actions_public,
  list_item_title: {
    fontSize: '1rem',
    margin: 0,
    padding: 0,
  },
  table_cell: {
    padding: '6px 16px',
    lineHeight: 1,
  },
  table_cell_title: {
    fontSize: '13px',
    padding: '14px 16px',
    color: 'rgba(0, 0, 0, 0.54)',
    lineHeight: 1,
  },
  table_cell_last: {
    borderBottom: '0px solid transparent',
    padding: '6px 16px',
  },
  link_order: {
    fontWeight: '700',
    color: 'inherit',
  },
  tooltip: {
    backgroundColor: '#000',
    whiteSpace: 'pre-line',
    lineHeight: '1.5',
    fontSize: '12px',
  },
  customer_name: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const VentasList = withStyles(styles, { withTheme: true })(VentasList_);
