import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  IconButton,
  Grow,
  Grid,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { ButtonUI } from './';
import ClearIcon from '@mui/icons-material/Clear';

const ModalUI_ = (props) => {
  let {
    id,
    open,
    title,
    classes,
    children,
    button_label,
    handleSubmit,
    modalHandler,
    subtitle = '',
    button_loading = false,
    wide_modal = false,
    onClickCancelButton,
    allow_overflow = false,
    aditional_param = null,
    button_disabled = false,
    cancel_button_label = '',
    titleWithoutDivider = false,
  } = props;

  let modal_classes = allow_overflow ? classes.form_overflow : classes.form;
  modal_classes = wide_modal ? classes.form_wide : modal_classes;

  return (
    <Modal
      open={open}
      disableRestoreFocus
      onClose={(_event, reason) => {
        if (reason === 'backdropClick') {
          modalHandler(false, id, aditional_param);
        }
      }}
    >
      <Grow in={open} timeout={300}>
        <form onSubmit={(e) => handleSubmit(e, id)} className={modal_classes}>
          <Card
            className={allow_overflow ? classes.card_overflow : classes.card}
          >
            <CardHeader
              title={title}
              subheader={subtitle}
              classes={{
                root: classes.card_header,
                action: classes.card_action_button,
                title: classes.card_header_title,
              }}
              action={
                <IconButton
                  onClick={() => modalHandler(false, id, aditional_param)}
                  size="large"
                >
                  <ClearIcon />
                </IconButton>
              }
            />
            {children && (
              <>
                {!titleWithoutDivider && <Divider />}
                <CardContent className={classes.card_content}>
                  {children}
                </CardContent>
              </>
            )}
            <Divider />
            <CardActions className={classes.card_actions}>
              <Grid container spacing={2} justifyContent="flex-end">
                {cancel_button_label && (
                  <Grid item lg={4} md={4} sm={5} xs={12}>
                    <ButtonUI
                      type="callf"
                      variant="outlined"
                      label={cancel_button_label}
                      onClickFunc={() => {
                        onClickCancelButton
                          ? onClickCancelButton()
                          : modalHandler(false, id, aditional_param);
                      }}
                      classes={{ root: classes.button }}
                    />
                  </Grid>
                )}
                <Grid item lg={4} md={4} sm={5} xs={12}>
                  <ButtonUI
                    type="submit"
                    label={button_label}
                    disabled={button_disabled}
                    isLoading={button_loading}
                    minHeight={true}
                    classes={{ root: classes.button }}
                  />
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </form>
      </Grow>
    </Modal>
  );
};

const styles = (theme) => ({
  form: {
    outline: 'none !important',
    overflow: 'auto',
    maxHeight: '95%',
    width: '95%',
    [theme.breakpoints.up('sm')]: {
      marginTop: '1%',
      width: '600px',
    },
    marginTop: '3%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  form_overflow: {
    outline: 'none !important',
    overflow: 'visible',
    maxHeight: '95%',
    width: '95%',
    [theme.breakpoints.up('sm')]: {
      marginTop: '1%',
      width: '600px',
    },
    marginTop: '3%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  form_wide: {
    outline: 'none !important',
    overflow: 'auto',
    maxHeight: '95%',
    width: '95%',
    [theme.breakpoints.up('sm')]: {
      marginTop: '1%',
      width: '70%',
    },
    marginTop: '3%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  card: {
    borderRadius: '4px',
    outline: 'none !important',
    paddingBottom: '5px',
  },
  card_overflow: {
    borderRadius: '4px',
    outline: 'none !important',
    overflow: 'visible',
  },
  card_content: theme.card.card_content,
  card_action_button: theme.card.card_action_button,
  card_actions: {
    boxSizing: 'border-box',
    padding: '15px 15px 10px 15px',
    [theme.breakpoints.up('sm')]: {
      padding: '10px 5px',
    },
  },
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  button: {
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
      right: '15px',
    },
  },
});

ModalUI_.propTypes = {
  //Object used to add some styling with withStyles
  classes: PropTypes.object,

  //true to open the modal
  open: PropTypes.bool.isRequired,

  //The title of the modal
  title: PropTypes.string.isRequired,

  //The subtitle of the modal
  //Default: ""
  subtitle: PropTypes.node,

  //The function which opens and closes the modal
  //Called: modalHandler(open,id,aditional_param)
  modalHandler: PropTypes.func.isRequired,

  //The function is fired when a cancel label button exists
  //Called: onClickCancelButton()
  onClickCancelButton: PropTypes.func,

  //The id of the modal
  //It's passed as argument in modalHandler and handleSubmit
  //To identify the modal
  id: PropTypes.string.isRequired,

  //The aditional param which
  //is passed in modalHandler
  //Default: null
  aditional_param: PropTypes.any,

  //The form submit function
  //Called: handleSubmit(event,id)
  handleSubmit: PropTypes.func.isRequired,

  //Label of the button at the bottom
  button_label: PropTypes.string.isRequired,

  //Indicates whether the submit button disabled or not
  //Default: false
  button_disabled: PropTypes.bool,

  //Indicates whether the submit button is loading or not
  //Default: false
  button_loading: PropTypes.bool,

  //Label of the cancel button at the bottom
  //Default: undefined
  cancel_button_label: PropTypes.string,

  //Hiden title bottom divider
  //Default: false
  titleWithoutDivider: PropTypes.bool,

  //The content of the modal
  children: PropTypes.node,
};

export const ModalUI = withStyles(styles)(ModalUI_);
