import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { Grid } from '@mui/material';
import { AlertUI, ToggleUI } from '../common';

import {
  tiendaObtener,
  tiendaActualizarApagarTemporalmente,
  categoriasObtenerFlattenJerarquico,
  productoObtenerProductos,
  metodosenvioObtener,
  trackObtenerTracks,
} from '../../store/actions';

import { Regex, RegexExtra, Validate } from '../../other';
import { ImportarProductos } from './importar/ImportarProductos';
import {
  on_off_store_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../amplitude';
import { WrapperLayout } from '../../layouts/WrapperLayout';

class ProductosImportar_ extends Component {
  state = {
    apagar_temporalmente: {
      t_offmode_mensaje: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Mensaje para tus clientes',
        id: 't_offmode_mensaje',
        name: 't_offmode_mensaje',
        change_param: 'apagar_temporalmente',
        regex: Regex.DESCRIPCION_0_200,
        value: '',
        default_value: '',
        placeholder: 'Estaremos cerrados hasta el ....',
        messages: {
          error: 'Por favor, ingrese un mensaje válido',
          error_extra: RegexExtra.DESCRIPCION_0_200,
        },
      },
    },
    loading: {
      apagar_temporalmente: true,
      importar: true,
      exportar: false,
      categorias: true,
      productos: true,
      dimensiones_requeridas: true,
      ultimo_track_de_migracion: true,
      error: false,
    },
    forms: {
      apagar_temporalmente_apagar: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    etapa: '',
    ultimo_idTrack: 0,
    dimensiones_requeridas: false,
    listas: {
      categorias_flatten_jerarquico: [],
    },
  };

  componentDidMount() {
    this.props.tiendaObtener((err, resp) => {
      if (err) {
        this.setState({
          loading: {
            ...this.state.loading,
            apagar_temporalmente: false,
            importar: false,
            error: true,
          },
        });
      } else {
        Validate.cargar_formulario_sin_nulos(
          this.props.tienda,
          this.state.apagar_temporalmente,
          (apagar_temporalmente_obj) => {
            this.setState({
              apagar_temporalmente: apagar_temporalmente_obj,
              loading: {
                ...this.state.loading,
                apagar_temporalmente: false,
                importar: false,
                error: false,
              },
            });
          }
        );
      }
    });

    this.props.categoriasObtenerFlattenJerarquico((err, resp) => {
      this.setState({
        loading: {
          ...this.state.loading,
          error: err ? true : this.state.loading.error,
          categorias: false,
        },
        listas: {
          ...this.state.listas,
          categorias_flatten_jerarquico: [
            ...this.state.listas.categorias_flatten_jerarquico,
            ...resp.data,
          ],
        },
      });
    });

    this.cargarProductos();

    this.dimensionesRequeridas();

    this.cargarUltimoTrackDeMigracion();
  }

  getCategoriasFromState = () => {
    return [...this.state.listas.categorias_flatten_jerarquico];
  };
  //+ver si la tienda tiene al menos un producto físico
  filtros_primer_producto_fisico = () => {
    return {
      idCategorias: 0,
      p_tipo_venta: -1,
      p_producto_digital: 0,
      p_oferta: -1,
      dimensiones_configuradas: -1,
      estado_stock: -1,
      orden: 0,
      page: 0,
      limit: 1,
    };
  };

  cargarProductos = () => {
    this.setState({
      loading: {
        ...this.state.loading,
        productos: true,
      },
    });

    this.props.productoObtenerProductos(
      this.filtros_primer_producto_fisico(),
      (err, resp) => {
        this.setState({
          loading: {
            ...this.state.loading,
            productos: false,
            error: this.state.loading.error || err ? true : false,
          },
        });
      }
    );
  };
  //-ver si la tienda tiene al menos un producto físico

  cargarUltimoTrackDeMigracion = () => {
    this.setState({
      loading: {
        ...this.state.loading,
        ultimo_track_de_migracion: true,
      },
    });
    this.props.trackObtenerTracks(
      {
        track_type: 2,
        track_subtype: 1,
        page: 0,
        limit: 1,
      },
      (err, resp) => {
        let ultimo_track_corriendo = false;
        let ultimo_idTrack = 0;
        if (!err && resp.data.length === 1 && resp.data[0].track_batch === 1) {
          ultimo_track_corriendo = true;
          ultimo_idTrack = resp.data[0].idTrack;
        }
        this.setState({
          loading: {
            ...this.state.loading,
            ultimo_track_de_migracion: false,
            error: this.state.loading.error || err ? true : false,
          },
          etapa: ultimo_track_corriendo ? 'IMPACTANDO_TIENDA' : 'APAGAR_TIENDA',
          ultimo_idTrack,
        });
      }
    );
  };

  handleImpactandoTienda = (ultimo_idTrack) => {
    this.setState({
      etapa: 'IMPACTANDO_TIENDA',
      ultimo_idTrack,
    });
  };

  dimensionesRequeridas = () => {
    this.props.metodosenvioObtener((err, resp) => {
      if (err) {
        this.setState({
          loading: {
            ...this.state.loading,
            dimensiones_requeridas: false,
            error: true,
          },
        });
      } else {
        const metodos_envio = resp.data;

        const dimensiones_requeridas =
          Validate.dimensiones_requeridas(metodos_envio);
        this.setState({
          dimensiones_requeridas,
          loading: {
            ...this.state.loading,
            dimensiones_requeridas: false,
          },
        });
      }
    });
  };

  handleSubmitApagar = (e) => {
    e.preventDefault();

    this.setState(
      {
        forms: {
          ...this.state.forms,
          apagar_temporalmente_apagar: true,
        },
      },
      () => {
        createAmplitudeEventWithDevice(
          on_off_store_amplitude_events
            .mi_tienda_selecciona_apagar_tienda_importacion.event_name,
          on_off_store_amplitude_events
            .mi_tienda_selecciona_apagar_tienda_importacion.event_params
        );
      }
    );

    let form = this.state.apagar_temporalmente;
    if (Validate.validar_formulario(form)) {
      this.props.tiendaActualizarApagarTemporalmente(
        {
          t_offmode_mensaje: form.t_offmode_mensaje.value,
          t_offmode: 1,
        },
        (err, resp) => {
          if (err) {
            this.setState({
              forms: {
                ...this.state.forms,
                apagar_temporalmente_apagar: false,
              },
              alert: {
                ...this.state.alert,
                open: true,
                message: resp.message,
                type: 'error',
              },
            });
          } else {
            this.setState({
              forms: {
                ...this.state.forms,
                apagar_temporalmente_apagar: false,
              },
              alert: {
                ...this.state.alert,
                open: true,
                message: resp.message,
                type: 'success',
              },
              etapa: 'PROCESAR_PLANILLA',
            });
          }
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          forms: {
            ...this.state.forms,
            apagar_temporalmente_apagar: false,
          },
          apagar_temporalmente: new_form,
        });
      });
    }
  };

  handleVolverAApagar = () => {
    this.setState({
      etapa: 'APAGAR_TIENDA',
    });
  };

  //+Export
  exportStart = () => {
    this.setState({
      loading: {
        ...this.state.loading,
        exportar: true,
      },
    });
  };
  exportFinish = (err, resp, message) => {
    if (err) {
      this.setState({
        loading: {
          ...this.state.loading,
          exportar: false,
          error: this.state.loading.error || resp ? true : false,
        },
        alert: {
          ...this.state.alert,
          open: true,
          message: resp.message,
          type: 'error',
        },
      });
    } else {
      let state = {
        loading: {
          ...this.state.loading,
          exportar: false,
          error: this.state.loading.error || err ? true : false,
        },
      };

      if (message.show) {
        state = {
          ...state,
          alert: {
            ...this.state.alert,
            open: true,
            message: message.message,
            type: 'error',
          },
        };
      }

      this.setState(state);
    }
  };
  //-Export

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      {
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: input,
            },
          });
        });
      }
    );
  };
  render() {
    // Esperar hasta que se hayan consultado todos los endpoints
    if (
      this.state.listas.categorias_flatten_jerarquico.length >= 0 &&
      !this.state.loading.importar &&
      !this.state.loading.categorias &&
      !this.state.loading.productos &&
      !this.state.loading.dimensiones_requeridas &&
      !this.state.loading.ultimo_track_de_migracion
    ) {
      return (
        <>
          <ToggleUI
            show={this.state.etapa === 'IMPACTANDO_TIENDA' ? true : false}
          >
            <Route
              render={(props) => (
                <Redirect
                  to={{
                    pathname: `/productos/importacion/${this.state.ultimo_idTrack}`,
                    state: { from: props.location },
                  }}
                />
              )}
            />
          </ToggleUI>
          <ToggleUI
            show={this.state.etapa !== 'IMPACTANDO_TIENDA' ? true : false}
          >
            <WrapperLayout
              loading={this.state.loading.importar}
              error={this.state.loading.error}
            >
              <AlertUI
                open={this.state.alert.open}
                message={this.state.alert.message}
                type={this.state.alert.type}
                handleCloseAlert={this.handleCloseAlert}
              />

              <Grid container spacing={1.5}>
                <Grid item xs={12}>
                  <ImportarProductos
                    handleSubmitApagar={this.handleSubmitApagar}
                    handleChange={this.handleChange}
                    etapa={this.state.etapa}
                    input_apagar_temporalmente={
                      this.state.apagar_temporalmente.t_offmode_mensaje
                    }
                    exportStart={this.exportStart}
                    exportFinish={this.exportFinish}
                    categorias_flatten_jerarquico={
                      this.state.listas.categorias_flatten_jerarquico
                    }
                    handleVolverAApagar={this.handleVolverAApagar}
                    handleImpactandoTienda={this.handleImpactandoTienda}
                    dimensiones_requeridas={this.state.dimensiones_requeridas}
                    apagar_temporalmente={
                      this.state.forms.apagar_temporalmente_apagar
                    }
                  />
                </Grid>
              </Grid>
            </WrapperLayout>
          </ToggleUI>
        </>
      );
    } else {
      return <WrapperLayout loading={this.state.loading.importar} />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    productoObtenerProductos: (data, callback) =>
      dispatch(productoObtenerProductos(data, callback)),
    tiendaActualizarApagarTemporalmente: (data, callback) =>
      dispatch(tiendaActualizarApagarTemporalmente(data, callback)),
    categoriasObtenerFlattenJerarquico: (callback) =>
      dispatch(categoriasObtenerFlattenJerarquico(callback)),
    metodosenvioObtener: (callback) => dispatch(metodosenvioObtener(callback)),
    trackObtenerTracks: (data, callback) =>
      dispatch(trackObtenerTracks(data, callback)),
  };
};

export const ProductosImportar = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductosImportar_);
