import React from 'react';
import { ButtonUI, DropdownUI, MenuItemUI } from '../../common';
import { CustomCardUI } from '../components/CustomCardUI';
import { GridItemCard } from '../components/GridItemCard';
import { Config } from '../../../other';

const Deposito = (props) => {
  const {
    metodos_pago,
    forms,
    modalHandler,
    handleDeshabilitarDepositoSubmit,
  } = props;

  const cargarBoton = () => {
    if (metodos_pago.mp_deposito) {
      return (
        <DropdownUI
          label="Configuración"
          size="large"
          isLoading={forms.desactivar_deposito}
        >
          <MenuItemUI
            type="callf"
            onClickFunction={() =>
              modalHandler(true, 'activar_editar_deposito')
            }
          >
            Editar datos
          </MenuItemUI>
          <MenuItemUI
            type="callf"
            onClickFunction={handleDeshabilitarDepositoSubmit}
          >
            Desactivar
          </MenuItemUI>
        </DropdownUI>
      );
    } else {
      return (
        <ButtonUI
          type="callf"
          onClickFunc={() => modalHandler(true, 'activar_editar_deposito')}
          label="Activar"
          size="large"
        />
      );
    }
  };

  return (
    <GridItemCard lg={6}>
      <CustomCardUI
        title="Depósito/Transferencia"
        subtitle={
          metodos_pago.mp_deposito ? 'Método activo' : 'Método no activo'
        }
        info="Te permitirá que tus clientes te puedan pagar via transferencia bancaria o depósito."
        image={`${Config.CLOUDFRONT_CDN_PROD}/panel/medios_pago/deposito-card-img.png`}
      >
        {cargarBoton()}
      </CustomCardUI>
    </GridItemCard>
  );
};

export { Deposito };
