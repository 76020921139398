import React from 'react';
import { Grid } from '@mui/material';
import { ModalUI, InputUI } from '../../../common';

const ModalAgregar = (props) => {
  let {
    agregar,
    handleSubmit,
    handleChange,
    modalHandler,
    modal_state,
    form_state,
  } = props;

  return (
    <ModalUI
      open={modal_state}
      id="agregar"
      title="Agregar nuevo local"
      modalHandler={modalHandler}
      aditional_param="agregar"
      handleSubmit={handleSubmit}
      button_label="Agregar"
      button_loading={form_state}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <InputUI handleChange={handleChange} input={agregar.l_nombre} />
        </Grid>
        <Grid item xs={12}>
          <InputUI handleChange={handleChange} input={agregar.l_direccion} />
        </Grid>
        <Grid item xs={12}>
          <InputUI handleChange={handleChange} input={agregar.l_horarios} />
        </Grid>
        <Grid item xs={12}>
          <InputUI handleChange={handleChange} input={agregar.l_telefono} />
        </Grid>
        <Grid item xs={12}>
          <InputUI
            handleChange={handleChange}
            multiline
            input={agregar.l_descripcion}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export { ModalAgregar };
