import React, { useEffect, useState } from 'react';
import { ButtonUI } from '../common';
import { Avatar, Card, CardContent, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ModalInstallApp } from './subcomponents/ModalInstallApp';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  aplicacionesObtener,
  aplicacionesPermisosObtener,
} from '../../store/actions';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import {
  applications_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../amplitude';

const ApplicationDetail_ = ({ classes, getApplications, getPermissions }) => {
  const { appName } = useParams();
  const [error, setError] = useState(false);
  const [appInfo, setAppInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [openModalInstallApp, setOpenModalInstallApp] = useState(false);
  const [permissionsTitles, setPermissionsTitles] = useState([]);

  useEffect(() => {
    if (appName) {
      getApplications((err, resp) => {
        if (err) {
          setError(true);
        } else {
          const application = resp.filter((app) => app.name === appName);

          if (application.length > 0) {
            setAppInfo(application[0]);

            setLoading(false);
          } else {
            setError(true);
          }
        }
      });
    }
  }, [appName]);

  useEffect(() => {
    if (Object.keys(appInfo).length > 0) {
      getPermissions((err, resp) => {
        if (err) {
          setError(err);
        } else {
          setPermissionsTitles(getTitlePermissions(resp, appInfo.scope));
        }
      });
    }
  }, [appInfo]);

  const getTitlePermissions = (permissions, scopeToFind) => {
    if (permissions.length > 0) {
      let foundPermissions = [];

      permissions.forEach((permission) => {
        if (
          scopeToFind &&
          scopeToFind.length > 0 &&
          scopeToFind.some((s) => permission.scope.includes(s))
        ) {
          foundPermissions.push(permission.title);
        }
      });

      return foundPermissions;
    }
  };

  const handleOpenModal = () => {
    setOpenModalInstallApp(true);

    createAmplitudeEventWithDevice(
      applications_amplitude_events.aplicaciones_instalar.event_name,
      {
        aplicacion: `(${appName})`,
        source: 'ver_mas',
      }
    );
  };

  return (
    <WrapperLayout error={error} loading={loading}>
      <ModalInstallApp
        app={{
          name: appName,
          logo: appInfo.logo,
          app_id: appInfo.app_id,
          policy_url: appInfo.policy_url,
        }}
        open={openModalInstallApp}
        modalHandler={setOpenModalInstallApp}
        permissionsTitles={permissionsTitles}
      />
      <Card className={classes.card}>
        <CardContent className={classes.card_content}>
          <Grid container className={classes.container}>
            <Grid item md={2} className={classes.containerImage}>
              <Avatar
                src={appInfo.logo}
                className={classes.avatar}
                alt={`application-logo-${appName}`}
              />
            </Grid>
            <Grid item className={classes.containerDescription}>
              <Grid container>
                <div className={classes.containerInfo}>
                  <h3 className={classes.title}>{appName}</h3>
                  <p className={classes.text} style={{ WebkitLineClamp: 3 }}>
                    {appInfo.description}
                  </p>
                </div>
                <Grid item xs={12} md={10} className={classes.containerButtons}>
                  <Grid className={classes.wrapperButton} item xs={12}>
                    <ButtonUI
                      type="link"
                      label="Volver"
                      fullWidth={true}
                      variant="text"
                      link={'/aplicaciones'}
                    />
                  </Grid>
                  <Grid className={classes.wrapperButton} item xs={12}>
                    <ButtonUI
                      type="callf"
                      fullWidth={true}
                      variant="contained"
                      label="Instalar"
                      onClickFunc={() => handleOpenModal()}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <iframe
        className={classes.containerIframe}
        src={appInfo.iframe_url}
      ></iframe>
    </WrapperLayout>
  );
};

const styles = (theme) => ({
  card: { ...theme.card.card, marginBottom: '24px' },
  card_content: {
    ...theme.card.card_content,
    padding: '0 !important',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '38px 34px 38px',
    },
  },
  containerImage: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 10px 11px',
    [theme.breakpoints.up('sm')]: {
      padding: '0',
      marginBottom: '12px',
    },
  },
  containerDescription: {
    alignSelf: 'center',
  },
  containerInfo: {
    '& h3,p': {
      margin: '0px 24px 16px 24px',
    },
    [theme.breakpoints.up('sm')]: {
      '& h3': {
        margin: '0px 0px 8px 0px',
      },
      '& p': {
        margin: '0px 0px 24px 0px',
      },
    },
  },
  containerButtons: {
    gap: '5px',
    display: 'flex',
    padding: '11px 16px 8px',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      padding: '0',
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  title: {
    color: '#3A3A3A',
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  text: {
    color: '#3A3A3A',
    fontSize: '0.87rem',
    fontWeight: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '200px',
    },
  },
  button: {
    textTransform: 'capitalize',
    fontSize: '0.87rem',
    fontWeight: 600,
    textWrap: 'nowrap',
  },
  wrapperButton: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '164px',
    },
  },
  containerIframe: {
    ...theme.card.card,
    width: '100%',
    height: 'calc(100vh - 10vh)',
    border: 0,
    backgroundColor: '#fff',
  },
  avatar: {
    width: '90px',
    height: '100%',
    alignSelf: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '139px',
      height: '100%',
      marginRight: '32px',
    },
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    getApplications: (callback) => dispatch(aplicacionesObtener(callback)),
    getPermissions: (callback) =>
      dispatch(aplicacionesPermisosObtener(callback)),
  };
};

export const ApplicationDetail = withStyles(styles)(
  connect(null, mapDispatchToProps)(ApplicationDetail_)
);
