const eventList = {
  '/login': 'empretienda_login',
  '/': 'empretienda_inicio',
  '/informacion-tienda': 'empretienda_mi_tienda_general',
  '/plan': 'empretienda_mi_tienda_plan',
  '/metodos-pago': 'empretienda_mi_tienda_metodos_pago',
  '/metodos-envio': 'empretienda_mi_tienda_metodos_envio',
  '/productos-digitales': 'empretienda_mi_tienda_productos_digitales',
  '/venta-mayorista': 'empretienda_mi_tienda_venta_mayorista',
  '/paginas': 'empretienda_mi_tienda_paginas',
  '/blog': 'empretienda_mi_tienda_blog',
  '/chat': 'empretienda_mi_tienda_chat',
  '/dominios': 'empretienda_mi_tienda_dominios',
  '/redes-sociales': 'empretienda_mi_tienda_redes_sociales',
  '/data-fiscal': 'empretienda_mi_tienda_data_fiscal',
  '/baja': 'empretienda_mi_tienda_dar_de_baja',
  '/etiquetas-html': 'empretienda_mi_tienda_etiquetas',
  '/apagar-temporalmente': 'empretienda_mi_tienda_apagar_temporalmente',
  '/formulario-contacto': 'empretienda_mi_tienda_formulario_contacto',
  '/categorias': 'empretienda_categorias',
  '/aplicaciones': 'empretienda_aplicaciones',

  '/productos/agregar': 'empretienda_productos_agregar',
  '/productos': 'empretienda_productos_administrar',
  '/notificaciones-stock': 'empretienda_productos_notificaciones_stock',
  '/productos/aumento-masivo': 'empretienda_productos_aumento_masivo',
  '/productos/importacion': 'empretienda_productos_importacion',

  '/ofertas': 'empretienda_descuentos_ofertas',
  '/cupones': 'empretienda_descuentos_cupones',
  '/builder': 'empretienda_diseño',

  '/google-analytics': 'empretienda_metricas_ga',
  '/metricas': 'empretienda_metricas_ventas',

  '/empreshops': 'empretienda_marketing_empreshops',
  '/meta-ads': 'empretienda_marketing_meta_ads',
  '/google-shopping': 'empretienda_marketing_google_shopping',
  '/facebook-pixel': 'empretienda_marketing_facebook_pixel',
  '/email-marketing': 'empretienda_marketing_email',
  '/pinterest-tag': 'empretienda_marketing_pinterest',
  '/seo-descripcion': 'empretienda_marketing_descripcion_tienda',

  '/foro': 'empretienda_ayuda_foro',
  '/blog/agregar': 'empretienda_mi_tienda_blog_agregar',
  '/paginas/agregar': 'empretienda_mi_tienda_paginas_agregar',
  '/cupones/agregar': 'empretienda_descuentos_cupones_agregar',
  '/ofertas/agregar': 'empretienda_descuentos_ofertas_agregar',
  '/ofertas/eliminar': 'empretienda_descuentos_ofertas_eliminar',
};

export const import_amplitude_events = {
  exportar_desde_admin_productos_success: {
    event_name: 'empretienda_productos_exportar_productos',
    event_params: {
      source: 'administrar_productos',
      success: true,
    },
  },
  exportar_desde_admin_productos_failed: {
    event_name: 'empretienda_productos_exportar_productos',
    event_params: {
      source: 'administrar_productos',
      success: false,
    },
  },
  exportar_desde_importacion_success: {
    event_name: 'empretienda_productos_exportar_productos',
    event_params: {
      source: 'planilla_actual',
      success: true,
    },
  },
  exportar_desde_importacion_failed: {
    event_name: 'empretienda_productos_exportar_productos',
    event_params: {
      source: 'planilla_actual',
      success: false,
    },
  },
  ver_tutorial: {
    event_name: 'empretienda_productos_importacion_ver_tutorial',
  },
  descargar_planilla_vacia: {
    event_name: 'empretienda_productos_descargar_planilla_vacia',
  },
  importar_producto_successfull: {
    event_name: 'empretienda_productos_selecciona_importar_productos',
    event_params: {
      success: true,
    },
  },
  importar_producto_failed: {
    event_name: 'empretienda_productos_selecciona_importar_productos',
    event_params: {
      success: false,
    },
  },
  confirmar_importacion_successfull: {
    event_name: 'empretienda_productos_confirmar_importacion_productos',
    event_params: {
      success: true,
    },
  },
  confirmar_importacion_failed: {
    event_name: 'empretienda_productos_confirmar_importacion_productos',
    event_params: {
      success: false,
    },
  },
};
export const amplitude_events = {
  activa_uala_bis: {
    event_name: 'empretienda_activa_uala_bis',
  },
  productos_importacion_masiva: {
    event_name: 'empretienda_productos_importacion_masiva',
  },
  ayuda_enviar_consulta: {
    event_name: 'empretienda_ayuda_enviar_consulta',
  },
  ayuda_selecciona_enviar: {
    event_name: 'empretienda_ayuda_selecciona_enviar',
  },
  ayuda_blog: {
    event_name: 'empretienda_ayuda_blog',
  },
  selecciona_soporte: {
    event_name: 'empretienda_selecciona_soporte',
  },
  selecciona_salir: {
    event_name: 'empretienda_selecciona_salir',
  },
  ventas__panel_lateral: {
    event_name: 'empretienda_ventas',
  },
  clientes__panel_lateral: {
    event_name: 'empretienda_clientes',
  },
  ventas_activas: {
    event_name: 'empretienda_dashboard_selecciona_ventas_activas',
  },
  envios_pendientes: {
    event_name: 'empretienda_dashboard_selecciona_envios_pendientes',
  },
  cobros_pendientes: {
    event_name: 'empretienda_dashboard_selecciona_cobros_pendientes',
  },
  clientes: {
    event_name: 'empretienda_dashboard_selecciona_clientes',
  },
  selecciona_home_logo: {
    event_name: 'empretienda_selecciona_home',
    event_params: {
      source: 'logo_empretienda',
    },
  },
  selecciona_home_panel: {
    event_name: 'empretienda_selecciona_home',
    event_params: {
      source: 'panel_lateral',
    },
  },
  tutoriales__panel_superior: {
    event_name: 'empretienda_tutoriales',
    event_params: {
      source: 'panel_superior',
    },
  },
  ver_tienda__panel_superior: {
    event_name: 'empretienda_ver_tienda',
    event_params: {
      source: 'panel_superior',
    },
  },
  ver_tienda__panel_lateral: {
    event_name: 'empretienda_ver_tienda',
    event_params: {
      source: 'panel_lateral',
    },
  },
  crear_tienda: {
    event_name: 'empretienda_crea_tienda',
  },
  agregar_categoria_successful: {
    event_name: 'empretienda_categoria_selecciona_agregar_categoria',
    event_params: {
      success: true,
    },
  },
  agregar_categoria_failed: {
    event_name: 'empretienda_categoria_selecciona_agregar_categoria',
    event_params: {
      success: false,
    },
  },
  activar_metodo_pago: {
    event_name: 'empretienda_metodos_pago_activar',
  },
  desactivar_metodo_pago: {
    event_name: 'empretienda_metodos_pago_desactivar',
  },
  agregar_producto_successfull: {
    event_name: 'empretienda_productos_selecciona_agregar_producto',
    event_params: {
      success: true,
    },
  },
  agregar_producto_failed: {
    event_name: 'empretienda_productos_selecciona_agregar_producto',
    event_params: {
      success: false,
    },
  },
  visualiza_video_home: {
    event_name: 'empretienda_home_visualiza_video ',
  },
  home_banner_selecciona_suscribirme: {
    event_name: ' empretienda_home_banner_selecciona_suscribirme',
  },
};

export const design_amplitude_events = {
  diseño_selecciona_ver_cambios: {
    event_name: 'empretienda_diseño_selecciona_ver_cambios',
  },
  diseño_selecciona_guardar: {
    event_name: 'empretienda_diseño_selecciona_guardar',
  },
  diseño_selecciona_salir: {
    event_name: 'empretienda_diseño_selecciona_salir',
  },
  diseño_selecciona_personalizacion: {
    event_name: 'empretienda_diseño_selecciona_personalizacion',
  },
  diseño_selecciona_plantillas: {
    event_name: 'empretienda_diseño_selecciona_plantillas',
  },
  diseño_selecciona_secciones: {
    event_name: 'empretienda_diseño_selecciona_secciones',
  },
  diseño_selecciona_productos: {
    event_name: 'empretienda_diseño_selecciona_productos',
  },
  diseño_selecciona_checkout: {
    event_name: 'empretienda_diseño_selecciona_checkout',
  },
  diseño_selecciona_tutoriales: {
    event_name: 'empretienda_diseño_selecciona_tutoriales',
  },
};

export const on_off_store_amplitude_events = {
  mi_tienda_selecciona_apagar_tienda_panel: {
    event_name: 'empretienda_mi_tienda_selecciona_apagar_tienda',
    event_params: {
      source: 'panel_lateral',
    },
  },
  mi_tienda_selecciona_apagar_tienda_importacion: {
    event_name: 'empretienda_mi_tienda_selecciona_apagar_tienda',
    event_params: {
      source: 'importacion masiva',
    },
  },
  mi_tienda_selecciona_encender_tienda_panel: {
    event_name: 'empretienda_mi_tienda_selecciona_encender_tienda',
    event_params: {
      source: 'panel_lateral',
    },
  },
  mi_tienda_selecciona_encender_tienda_importacion: {
    event_name: 'empretienda_mi_tienda_selecciona_encender_tienda',
    event_params: {
      source: 'importacion masiva',
    },
  },
};
export const method_shipment_amplitude_events = {
  metodos_envio_activar: {
    event_name: 'empretienda_metodos_envio_activar',
  },
  metodos_envio_desactivar: {
    event_name: 'empretienda_metodos_envio_desactivar',
  },
};
export const notifications_stock_amplitude_events = {
  productos_notificaciones_stock_activar: {
    event_name: 'empretienda_productos_notificaciones_stock_activar',
  },
};
export const massive_increase_price_amplitude_events = {
  aumento_masivo_selecciona_aumentar: {
    event_name: 'empretienda_aumento_masivo_selecciona_aumentar',
  },
};
export const suscription_amplitude_events = {
  plan_reactiva_tienda: {
    event_name: 'empretienda_plan_reactiva_tienda',
  },
  plan_datos_facturacion_guardar: {
    event_name: 'empretienda_plan_datos_facturacion_guardar',
  },
  plan_configuracion_metodo_pago_seleccionar: {
    event_name: 'empretienda_plan_configuracion_metodo_pago_seleccionar',
  },
  plan_descargar_factura: {
    event_name: 'empretienda_plan_descargar_factura',
  },
  plan_selecciona_informar_pago: {
    event_name: 'empretienda_plan_selecciona_informar_pago',
  },
  suscripcion_confirma_datos_tarjeta: {
    event_name: 'empretienda_suscripcion_confirma_datos_tarjeta',
  },
  mi_plan_selecciona_actualizar_tarjeta: {
    event_name: 'empretienda_mi_plan_selecciona_actualizar_tarjeta',
  },
  mi_plan_selecciona_cancelar_suscripcion: {
    event_name: 'empretienda_mi_plan_selecciona_cancelar_suscripcion.',
  },
  mi_plan_confirmar_cancelar_suscripcion: {
    event_name: 'empretienda_mi_plan_confirmar_cancelar_suscripcion',
  },
  inicio_con_deuda: {
    event_name: 'empretienda_inicio_con_deuda',
  },
};

export const sales_amplitude_events = {
  ventas_ingreso_orden: {
    event_name: 'empretienda_ventas_ingreso_orden',
  },
  ventas_cancelar_orden: {
    event_name: 'empretienda_ventas_cancelar_orden',
  },
  ventas_orden_retirada: {
    event_name: 'empretienda_ventas_orden_retirada',
  },
  ventas_orden_abonada: {
    event_name: 'empretienda_ventas_orden_abonada',
  },
  ventas_imprimir_orden_compra: {
    event_name: 'empretienda_ventas_imprimir_orden_compra',
  },
  ventas_orden_facturada: {
    event_name: 'empretienda_ventas_orden_facturada',
  },
  ventas_archivar_orden: {
    event_name: 'empretienda_ventas_archivar_orden',
  },
  ventas_editar_datos_facturacion: {
    event_name: 'empretienda_ventas_editar_datos_facturacion',
  },
  ventas_editar_datos_envio: {
    event_name: 'empretienda_ventas_editar_datos_envio',
  },
  ventas_filtrar: {
    event_name: 'empretienda_ventas_filtrar',
  },
  ventas_selecciona_aplicar_filtros: {
    event_name: 'empretienda_ventas_selecciona_aplicar_filtros',
  },
};

export const applications_amplitude_events = {
  aplicaciones_instalar: {
    event_name: 'empretienda_aplicaciones_instalar',
  },
  aplicaciones_desinstalar: {
    event_name: 'empretienda_aplicaciones_desinstalar',
  },
  aplicaciones_confirma_desinstalar: {
    event_name: 'empretienda_aplicaciones_confirma_desinstalar',
  },
  aplicaciones_conocer_mas: {
    event_name: 'empretienda_aplicaciones_conocer_mas',
  },
  aplicaciones_acepta_permisos: {
    event_name: 'empretienda_aplicaciones_acepta_permisos',
  },
};
const events_regex = [
  {
    event_regex: /^\/blog\/[\w \/]{1,}/,
    event_name: 'empretienda_mi_tienda_blog_editar',
  },
  {
    event_regex: /^\/paginas\/[\w \/]{1,}/,
    event_name: 'empretienda_mi_tienda_paginas_editar',
  },
  {
    event_regex: /^\/productos\/agregar\/[\w \/]{1,}/,
    event_name: 'empretienda_productos_duplicar_producto',
  },
  {
    event_regex: /^\/productos\/[\w \/]{1,}/,
    event_name: 'empretienda_productos_editar_producto',
  },
  {
    event_regex: /^\/cupones\/[\w \/]{1,}/,
    event_name: 'empretienda_descuentos_cupones_editar',
  },
  {
    event_regex: /^\/venta\/[\w \/]{1,}/,
    event_name: 'empretienda_ventas_ver_detalle',
  },
];

const getEventNameByPathname = () => {
  const { pathname } = window.location;
  let event_name = eventList.hasOwnProperty(pathname)
    ? eventList[pathname]
    : '';
  if (event_name === '') {
    for (const regex_name of events_regex) {
      if (regex_name.event_regex.test(pathname)) {
        event_name = regex_name.event_name;
        break;
      }
    }
  }
  return event_name;
};

export { getEventNameByPathname };
