/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { ButtonUI, DropdownUI, MenuItemUI } from '../../common';
import { CustomCardUI } from '../components/CustomCardUI';
import { GridItemCard } from '../components/GridItemCard';
import { Config } from '../../../other';

const Mobbex = (props) => {
  const {
    metodos_pago,
    forms,
    handleObtenerUrlMobbexSubmit,
    handleDesactivarMobbexSubmit,
  } = props;

  const cargarBoton = () => {
    if (metodos_pago.mp_mobbex) {
      return (
        <DropdownUI
          label="Configuración"
          size="large"
          isLoading={forms.obtener_url_mobbex || forms.desactivar_mobbex}
        >
          <MenuItemUI
            type="callf"
            onClickFunction={handleObtenerUrlMobbexSubmit}
          >
            Cambiar cuenta
          </MenuItemUI>
          <MenuItemUI
            type="callf"
            onClickFunction={handleDesactivarMobbexSubmit}
          >
            Desactivar
          </MenuItemUI>
        </DropdownUI>
      );
    } else {
      return (
        <ButtonUI
          type="callf"
          onClickFunc={handleObtenerUrlMobbexSubmit}
          isLoading={forms.obtener_url_mobbex}
          label="Activar"
          size="large"
        />
      );
    }
  };

  let mobbex_subtitle;

  if (metodos_pago.mp_mobbex) {
    switch (metodos_pago.mp_mobbex_pahora) {
      case 1:
        mobbex_subtitle = 'Método activo con ahora 3 y 6';
        break;
      case 2:
        mobbex_subtitle = 'Método activo con ahora 12 y 18';
        break;
      case 3:
        mobbex_subtitle = 'Método activo con ahora 3, 6, 12 y 18';
        break;
      default:
        mobbex_subtitle = 'Método activo';
        break;
    }
  } else {
    mobbex_subtitle = 'Método no activo';
  }

  return (
    <GridItemCard lg={6}>
      <CustomCardUI
        title="Mobbex"
        subtitle={mobbex_subtitle}
        info={
          <span>
            Tus clientes podrán pagar con tarjetas de crédito, débito, PIM o
            efectivo. Mobbex te cobrará una comisión por cada venta. Además, si
            tu rubro es elegible, podés ofrecer pagos con el plan ahora 12{' '}
            <a
              href="https://www.mobbex.com/costofinanciero"
              style={{
                color: 'inherit',
                textDecoration: 'underline',
              }}
              target="_blank"
            >
              (más información)
            </a>
            .
          </span>
        }
        image={`${Config.CLOUDFRONT_CDN_PROD}/panel/medios_pago/mobbex-card-img.png`}
      >
        {cargarBoton()}
      </CustomCardUI>
    </GridItemCard>
  );
};

export { Mobbex };
