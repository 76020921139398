/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = {
  fetch: true,
  close: false,
  type: 'error',
  messages: [],
  code: 0,
};

const alertaReducer = (state = initState, action) => {
  switch (action.type) {
    case Reducers.ALERTA_GUARDAR:
      let code = action.data.code;
      let close = localStorage.getItem('message_' + code) ? true : false;

      return {
        fetch: false,
        close: close,
        ...action.data,
      };
    case Reducers.ALERTA_CLOSE:
      localStorage.setItem('message_' + state.code, '1');
      return {
        ...state,
        close: true,
      };
    case Reducers.AUTH_UNSET:
      return initState;
    default:
      return state;
  }
};

export { alertaReducer };
