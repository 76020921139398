import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  SwipeableDrawer,
  Grid,
  IconButton,
  Typography,
  Icon,
  Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { ProductoDownloadExcel } from './../DownloadExcel/ProductoDownloadExcel';

import { SelectUI, ToggleUI, ButtonUI } from '../../../common';
import { Validate } from '../../../../other';
const es_iphone = Validate.is_iphone();

export const FilterSidenav_ = (props) => {
  const [showExport, setShowExport] = useState(false);

  let {
    classes,
    filter,
    selects,
    isFiltersDrawerOpen,
    handleDrawer,
    handleChangeSelect,
    limpiarFiltros,
    tienda,
    handleSubmitFilter,
    type,
    loading,
    exportFinish,
    exportStart,
    obtenerParametros,
  } = props;

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={isFiltersDrawerOpen}
        onClose={() => handleDrawer(false, type)}
        onOpen={() => handleDrawer(true, type)}
        disableBackdropTransition={!es_iphone}
        disableDiscovery={es_iphone}
      >
        <div className={classes.filters_sidenav_box}>
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={0.5}>
                <Grid item>
                  <IconButton
                    aria-label="Cancelar"
                    onClick={() => handleDrawer(false, type)}
                    size="large"
                  >
                    <Icon>clear</Icon>
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    {type === 'filtrar' ? 'Filtrar' : 'Exportar'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SelectUI
                data={filter.idCategorias}
                options={selects.categorias}
                handleChangeSelect={handleChangeSelect}
                value_atribute="idCategorias"
                label_atribute={es_iphone ? 'c_nombre_slash' : 'c_nombre'}
              />
            </Grid>
            <ToggleUI show={tienda.t_mayorista ? true : false}>
              <Grid item xs={12}>
                <SelectUI
                  data={filter.p_tipo_venta}
                  options={selects.p_tipo_venta}
                  handleChangeSelect={handleChangeSelect}
                />
              </Grid>
            </ToggleUI>
            <ToggleUI
              show={
                tienda.t_productos_digitales && type !== 'exportar'
                  ? true
                  : false
              }
            >
              <Grid item xs={12}>
                <SelectUI
                  data={filter.p_producto_digital}
                  options={selects.p_producto_digital}
                  handleChangeSelect={handleChangeSelect}
                />
              </Grid>
            </ToggleUI>
            <Grid item xs={12}>
              <SelectUI
                data={filter.estado_stock}
                options={selects.estado_stock}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectUI
                data={filter.p_oferta}
                options={selects.p_oferta}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectUI
                data={filter.dimensiones_configuradas}
                options={selects.dimensiones_configuradas}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectUI
                data={filter.orden}
                options={selects.orden}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
            <ToggleUI show={type === 'exportar' ? true : false}>
              <Grid item xs={12}>
                <SelectUI
                  data={filter.incluir_descripcion}
                  options={selects.incluir_descripcion}
                  handleChangeSelect={handleChangeSelect}
                />
              </Grid>
            </ToggleUI>
            <Grid item xs={12}>
              <ButtonUI
                type="callf"
                label={type === 'exportar' ? 'Exportar' : 'Aplicar filtros'}
                size="large"
                isLoading={
                  type === 'exportar' ? loading.exportar : loading.productos
                }
                onClickFunc={
                  type === 'exportar'
                    ? () => {
                        exportStart();
                        setShowExport(true);
                      }
                    : () => handleSubmitFilter(type)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={(args) => limpiarFiltros()}
                variant="outlined"
                size="large"
                className={classes.button}
                fullWidth
              >
                <div className={classes.icon_center}>
                  <Icon className={classes.icon_badge}>delete</Icon>
                  Limpiar filtros
                </div>
              </Button>
            </Grid>
          </Grid>
        </div>
      </SwipeableDrawer>
      <ToggleUI show={showExport ? true : false}>
        <ProductoDownloadExcel
          exportFinish={exportFinish}
          obtenerParametros={obtenerParametros}
          setShowExport={setShowExport}
          show_mayorista={tienda.t_mayorista === 1 ? true : false}
          show_descripcion={
            filter.incluir_descripcion.value === 1 ? true : false
          }
        />
      </ToggleUI>
    </>
  );
};

const styles = (theme) => ({
  icon_center: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px 0px',
  },
  icon_badge: {
    marginRight: '10px',
  },
  button: {
    marginTop: '2px',
  },
  filters_sidenav_box: {
    width: '300px',
    padding: theme.spacing(2),
  },
});

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};

const mapDispatchToProps = null;

export const FilterSidenav = withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(FilterSidenav_)
);
