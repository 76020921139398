import React from 'react';
import { Icon, Tooltip, Typography } from '@mui/material';
import { ToggleUI } from '../../common';
import { withStyles } from '@mui/styles';

const InfoButton = ({ text, position = 'top-start' }) => {
  return (
    <Tooltip title={text} placement={position}>
      <Icon fontSize="small">help_outline</Icon>
    </Tooltip>
  );
};

const InfoText_ = (props) => {
  let { title, subtitle = '', text = '', first = false, classes } = props;
  text = '';
  return (
    <div className={first ? classes.info_box_first : classes.info_box}>
      <div className={classes.info_title_box}>
        <Typography className={classes.info_title}>{title}</Typography>
        <ToggleUI show={subtitle ? true : false}>
          <Typography className={classes.info_subtitle}>{subtitle}</Typography>
        </ToggleUI>
      </div>
      <ToggleUI show={text ? true : false}>
        <InfoButton text={text} position="right" />
      </ToggleUI>
    </div>
  );
};

const styles = (theme) => ({
  info_box_first: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
  },
  info_box: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '15px',
  },
  info_title_box: {
    marginRight: '5px',
  },
  info_title: {
    fontWeight: 500,
    fontSize: '0.875rem',
    userSelect: 'none',
  },
  info_subtitle: {
    padding: 0,
    margin: 0,
    color: '#9e9e9e',
    fontSize: '0.8rem',
    userSelect: 'none',
  },
});

export const InfoText = withStyles(styles, { withTheme: true })(InfoText_);
