import React, { useState } from 'react';
import { Validate, Config } from '../../../other';
import { Grid, Icon, Tooltip, Typography } from '@mui/material';
import {
  InputUI,
  ToggleUI,
  SelectUI,
  ImageUI,
  ButtonUI,
  ModalLiteUI,
  CodeUI,
} from '../../common';
import { withStyles } from '@mui/styles';
import { InputColorCircle, SelectFont, InfoText } from './';

const ShopSettings_ = (props) => {
  let {
    settings,
    selects,
    handleChange,
    handlerShowAlertUI,
    handleChangeSelect,
    handleChangeFileSettings,
    classes,
  } = props;

  //toggler de opciones avanzadas de color
  const [opcionesAvanzadas, setOpcionesAvanzadas] = useState(false);
  const toggleOpcionesAvanzadas = () =>
    setOpcionesAvanzadas(!opcionesAvanzadas);
  const [modal_state_css, setModalStateCSS] = useState(false);
  const toggleModalStateCSS = () => setModalStateCSS(!modal_state_css);

  return (
    <>
      <ModalLiteUI
        open={modal_state_css}
        title="Código CSS"
        subtitle='Te permite editar o sobreescribir los estilos de la plantilla, recuerda que esta función es avanzada y un código CSS mal escrito puede provocar problemas en tu tienda. Adicionalmente, queda prohibido remover/ocultar la leyenda "Tienda creada con Empretienda".'
        modalHandler={toggleModalStateCSS}
        full_size
        id="codigo_css"
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CodeUI
              input={settings.css}
              lang="css"
              width="100%"
              height="400px"
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonUI
              type="callf"
              onClickFunc={toggleModalStateCSS}
              label="Cerrar editor CSS"
            />
          </Grid>
        </Grid>
      </ModalLiteUI>
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <InfoText first title="Logo" />
          <div
            className={
              settings.t_logo.files.length > 0 ? classes.image_container : ''
            }
          >
            <ImageUI
              handlerShowAlertUI={handlerShowAlertUI}
              data={settings.t_logo}
              max_files={1}
              max_size={2}
              allowed_files={['jpg', 'png', 'jpeg', 'webp', 'gif']}
              input_allowed_mime="image/jpg,image/jpeg,image/png,image/webp,image/gif"
              handleChange={handleChangeFileSettings}
              cdn_prefix={Config.CLOUDFRONT_LOGOS_CDN}
              upload_path="logo"
              row_height="150px"
              reducer_max_width={400}
              reducer_max_height={400}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              row_spacing={0}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center" item xs={12} pt={0.5}>
            <InfoText title="Favicon" />
            <Tooltip
              placement="bottom-start"
              title={
                <>
                  Ícono que se utiliza para representar un sitio web en la
                  pestaña del navegador.
                </>
              }
              classes={{
                tooltip: classes.tooltip,
                popper: classes.popper,
              }}
            >
              <Icon
                sx={{
                  marginTop: '20px',
                  marginBottom: '15px',
                  fontSize: '20px !important',
                }}
              >
                help_outline
              </Icon>
            </Tooltip>
          </Grid>
          <div
            className={
              settings.t_favicon.files.length > 0 ? classes.image_container : ''
            }
          >
            <ImageUI
              handlerShowAlertUI={handlerShowAlertUI}
              data={settings.t_favicon}
              max_files={1}
              max_size={0.5}
              allowed_files={['png']}
              input_allowed_mime="image/png"
              handleChange={handleChangeFileSettings}
              cdn_prefix={Config.CLOUDFRONT_LOGOS_CDN}
              upload_path="favicon"
              row_height="96px"
              reducer_max_width={128}
              reducer_max_height={128}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              row_spacing={0}
            />
          </div>
        </Grid>
        <Grid item xs={12} mt={0.5}>
          <InfoText title="Colores" />
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <SelectUI
                data={settings.color}
                options={selects.colores}
                handleChangeSelect={handleChangeSelect}
                label_atribute="gama"
                value_atribute="id"
              />
              <Typography
                className={classes.toggle_opciones}
                onClick={() => toggleOpcionesAvanzadas()}
              >
                {opcionesAvanzadas
                  ? 'MOSTRAR MENOS OPCIONES DE COLOR'
                  : 'MOSTRAR MÁS OPCIONES DE COLOR'}
              </Typography>
            </Grid>
            <ToggleUI show={opcionesAvanzadas}>
              <Grid item xs={12}>
                <InputUI
                  handleChange={handleChange}
                  input={settings.primary_text}
                  adornment={
                    <InputColorCircle color={settings.primary_text.value} />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <InputUI
                  handleChange={handleChange}
                  input={settings.primary_background}
                  adornment={
                    <InputColorCircle
                      color={settings.primary_background.value}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <InputUI
                  handleChange={handleChange}
                  input={settings.primary_contrastText}
                  adornment={
                    <InputColorCircle
                      color={settings.primary_contrastText.value}
                    />
                  }
                />
              </Grid>
              <ToggleUI
                show={Validate.in_array(
                  settings.template_id.value,
                  [3, 5, 7, 8]
                )}
              >
                <Grid item xs={12}>
                  <InputUI
                    handleChange={handleChange}
                    input={settings.secondary_text}
                    adornment={
                      <InputColorCircle color={settings.secondary_text.value} />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputUI
                    handleChange={handleChange}
                    input={settings.secondary_background}
                    adornment={
                      <InputColorCircle
                        color={settings.secondary_background.value}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputUI
                    handleChange={handleChange}
                    input={settings.secondary_contrastText}
                    adornment={
                      <InputColorCircle
                        color={settings.secondary_contrastText.value}
                      />
                    }
                  />
                </Grid>
              </ToggleUI>
            </ToggleUI>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InfoText title="Tipografías" />
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <SelectFont
                data={settings.font_title}
                options={selects.fonts}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectFont
                data={settings.font_text}
                options={selects.fonts}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InfoText title="Bordes" />
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <SelectUI
                data={settings.other_border_radius}
                options={selects.other_border_radius}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <InfoText title="Mensaje de encabezado" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <InputUI
                  handleChange={handleChange}
                  input={settings.message_text}
                />
              </Grid>
              <Grid item xs={12}>
                <InputUI
                  handleChange={handleChange}
                  input={settings.message_link}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoText title="Edición CSS (avanzado)" />
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <ButtonUI
                  type="callf"
                  variant="outlined"
                  color="primary"
                  onClickFunc={toggleModalStateCSS}
                  label="Abrir editor CSS"
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const styles = (theme) => ({
  toggle_opciones: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.65rem',
    lineHeight: '1rem',
    fontWeight: 400,
    textDecoration: 'underline',
    cursor: 'pointer',
    userSelect: 'none',
  },
  image_container: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  tooltip: {
    fontSize: '.875rem',
    backgroundColor: theme.texts.secondary,
  },
  popper: {
    opacity: 1,
    maxWidth: '232px',
  },
});

export const ShopSettings = withStyles(styles)(ShopSettings_);
