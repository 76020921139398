/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Grid } from '@mui/material';
import { ButtonUI, CustomCardUI } from '../../common';
import { Config } from '../../../other';

const CorreoArgentino = (props) => {
  const { metodos_envio } = props;

  return (
    <Grid item lg={4} sm={6} xs={12}>
      <CustomCardUI
        title="Correo Argentino"
        subtitle={
          metodos_envio.me_correo_argentino
            ? 'Método activo'
            : 'Método no activo'
        }
        info="Integración con el tarifario de precios de Mi Correo. Permite calcular los costos de envíos. Es necesario tener una cuenta en Mi Correo ya que desde allí se dan de alta los envíos."
        image={`${Config.CLOUDFRONT_CDN_PROD}/panel/medios_envio/correoargentino2-card-img.png`}
      >
        <ButtonUI
          type="link"
          link="/metodos-envio/correo-argentino"
          label="Configuración"
          size="large"
        />
      </CustomCardUI>
    </Grid>
  );
};

export { CorreoArgentino };
