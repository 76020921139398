import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { CardText, CardTitle } from '../subcomponents';
import { ModuleTitleUI, CardModuleUI, ButtonUI, ToggleUI } from '../../common';
import { TableErrors } from '../importar/TableErrors';
import {
  createAmplitudeEventWithDevice,
  import_amplitude_events,
} from '../../amplitude';
import { ModalEncender } from '../../../pages/apagar-temporalmente/ModalEncender';
class ResumenImportacion_ extends Component {
  state = {
    loading: {
      cargar_track_de_migracion: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  componentDidMount() {
    createAmplitudeEventWithDevice(
      import_amplitude_events.confirmar_importacion_successfull.event_name,
      import_amplitude_events.confirmar_importacion_successfull.event_params
    );
  }

  render() {
    const {
      classes,
      handleSubmitEncender,
      modalHandler,
      modal_state,
      form_state,
      handleIrAAdministrarProductos,
      t_offmode,
      handleCargarMasProductos,
      products_edited,
      products_created,
      planilla_chunks_errores,
    } = this.props;

    return (
      <>
        <ModalEncender
          handleSubmit={handleSubmitEncender}
          modalHandler={modalHandler}
          modal_state={modal_state}
          form_state={form_state}
        />
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <ModuleTitleUI title="Importación finalizada" />
          </Grid>
          <Grid item xs={12}>
            <CardModuleUI>
              <CardTitle title="Resumen de operaciones realizadas" />
              <CardText text="Podrás visualizar el detalle de cada uno de los productos desde la sección de Administrar productos." />
              <Grid container className={classes.product_card_container}>
                <Grid item className={classes.product_card_item} md={3} xs={12}>
                  <span className={classes.product_icon}>
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.5 13.5L30.375 9.375L34.5 7.5L30.375 5.625L28.5 1.5L26.625 5.625L22.5 7.5L26.625 9.375L28.5 13.5ZM17.25 14.25L13.5 6L9.75 14.25L1.5 18L9.75 21.75L13.5 30L17.25 21.75L25.5 18L17.25 14.25ZM28.5 22.5L26.625 26.625L22.5 28.5L26.625 30.375L28.5 34.5L30.375 30.375L34.5 28.5L30.375 26.625L28.5 22.5Z"
                        fill="#53B755"
                      />
                    </svg>
                  </span>
                  <div>
                    <p className={classes.products_title}>Productos nuevos</p>
                    {/* <h2 className={classes.new_products_quantity}>1500</h2> */}
                    <h2 className={classes.new_products_quantity}>
                      {products_created}
                    </h2>
                  </div>
                </Grid>
                <Grid item className={classes.product_card_item} md={3} xs={12}>
                  <span className={classes.product_icon}>
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.2498 8.4L14.9998 10.5L12.8998 6.75L14.9998 3L11.2498 5.1L7.49984 3L9.59984 6.75L7.49984 10.5L11.2498 8.4ZM29.2498 23.1L25.4998 21L27.5998 24.75L25.4998 28.5L29.2498 26.4L32.9998 28.5L30.8998 24.75L32.9998 21L29.2498 23.1ZM32.9998 3L29.2498 5.1L25.4998 3L27.5998 6.75L25.4998 10.5L29.2498 8.4L32.9998 10.5L30.8998 6.75L32.9998 3ZM21.5548 10.935C20.9698 10.35 20.0248 10.35 19.4398 10.935L1.93484 28.44C1.34984 29.025 1.34984 29.97 1.93484 30.555L5.44484 34.065C6.02984 34.65 6.97484 34.65 7.55984 34.065L25.0498 16.575C25.6348 15.99 25.6348 15.045 25.0498 14.46L21.5548 10.935ZM20.0098 19.17L16.8298 15.99L20.4898 12.33L23.6698 15.51L20.0098 19.17Z"
                        fill="#F99909"
                      />
                    </svg>
                  </span>
                  <div>
                    <p className={classes.products_title}>Productos editados</p>
                    {/* <h2 className={classes.edited_products_quantity}>400</h2> */}
                    <h2 className={classes.edited_products_quantity}>
                      {products_edited}
                    </h2>
                  </div>
                </Grid>
                <Grid item className={classes.product_card_item} md={6} xs={12}>
                  <span className={classes.product_icon}>
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 3C9.705 3 3 9.705 3 18C3 26.295 9.705 33 18 33C26.295 33 33 26.295 33 18C33 9.705 26.295 3 18 3ZM25.5 23.385L23.385 25.5L18 20.115L12.615 25.5L10.5 23.385L15.885 18L10.5 12.615L12.615 10.5L18 15.885L23.385 10.5L25.5 12.615L20.115 18L25.5 23.385Z"
                        fill="#FF0000"
                      />
                    </svg>
                  </span>
                  <div>
                    <p className={classes.products_title}>
                      Errores encontrados
                    </p>
                    <h2 className={classes.errors_products_quantity}>
                      {planilla_chunks_errores.length}
                    </h2>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <hr className={classes.products_divider}></hr>
                </Grid>
                <Grid item xs={12}>
                  <CardTitle
                    title={
                      t_offmode === 1
                        ? 'Encender tu tienda'
                        : 'La tienda ya está encendida'
                    }
                  />
                  <CardText
                    text={
                      t_offmode === 1
                        ? 'Te recomendamos volver a activar tu tienda para que sus clientes puedan volver a comprar productos.'
                        : 'Tus clientes ya pueden acceder a comprar productos'
                    }
                  />
                  <Grid container alignItems="center">
                    <Grid item>
                      <ButtonUI
                        fullWidth
                        type="callf"
                        label="Encender tienda"
                        disabled={t_offmode === 0 ? true : false}
                        onClickFunc={() =>
                          modalHandler(true, 'apagar_temporalmente_encender')
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <ToggleUI show={planilla_chunks_errores.length > 0}>
                  <Grid item xs={12}>
                    <hr className={classes.products_divider}></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <TableErrors import_errors={planilla_chunks_errores} />
                  </Grid>
                </ToggleUI>
              </Grid>
            </CardModuleUI>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.button_desktop}>
              <Grid container justifyContent="flex-end" alignItems="center">
                <Grid item>
                  <ButtonUI
                    type="callf"
                    fullWidth={false}
                    variant="text"
                    label="Administrar productos"
                    onClickFunc={(e) => handleIrAAdministrarProductos()}
                  />
                  <ButtonUI
                    type="callf"
                    fullWidth={false}
                    variant="contained"
                    label="Cargar más productos"
                    onClickFunc={(e) => handleCargarMasProductos()}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.button_mobile}>
              <Grid container>
                <Grid item xs={12}>
                  <ButtonUI
                    type="callf"
                    fullWidth
                    variant="contained"
                    label="Cargar más productos"
                    onClickFunc={(e) => handleCargarMasProductos()}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: '24px' }}>
                  <ButtonUI
                    type="callf"
                    fullWidth
                    variant="text"
                    label="Administrar productos"
                    onClickFunc={(e) => handleIrAAdministrarProductos()}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

const styles = (theme) => ({
  button_desktop: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  button_mobile: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginTop: '24px',
    },
  },
  product_card_container: {
    marginTop: '36px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  product_card_item: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      backgroundColor: '#f4f4f4',
      marginTop: '12px',
      padding: '12px',
    },
  },
  product_icon: {
    [theme.breakpoints.down('md')]: {
      margin: '15px 10px 0px 0px',
    },
  },
  products_title: {
    fontWeight: '400',
    fontSize: '0.87rem',
    lineHeight: '16px',
    color: '#6C6C6C',
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      margin: '0px 0px 6px 0px',
      fontSize: '1rem',
      fontWeight: '700',
      lineHeight: '19px',
      textTransform: 'none',
    },
  },
  products_divider: {
    backgroundColor: '#6C6C6C',
    height: '1px',
    border: 'none',
    margin: '36px 0px 36px 0px',
  },
  new_products_quantity: {
    fontWeight: '700',
    fontSize: '3rem',
    lineHeight: '56px',
    margin: '0px 0px 10px 0px',
    color: '#53B755',
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
      lineHeight: '38px',
    },
  },
  edited_products_quantity: {
    fontWeight: '700',
    fontSize: '3rem',
    lineHeight: '56px',
    margin: '0px 0px 10px 0px',
    color: '#F99909',
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
      lineHeight: '38px',
    },
  },
  errors_products_quantity: {
    fontWeight: '700',
    fontSize: '3rem',
    lineHeight: '56px',
    margin: '0px 0px 10px 0px',
    color: '#FF0000',
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
      lineHeight: '38px',
    },
  },
});

const mapStateToProps = null;

const mapDispatchToProps = null;

export const ResumenImportacion = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ResumenImportacion_));
