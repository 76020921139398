import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  tiendaObtener,
  tiendaDarBaja,
  tiendaActualizarApagarTemporalmente,
  adminLogout,
} from '../../store/actions';
import { Regex, RegexExtra, Validate } from '../../other';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  InputUI,
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
  SelectUI,
  ModuleCardTitleUI,
  ToggleUI,
  NextActionsUI,
  CardModuleUI,
} from '../common';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import { Auth0Context } from '@auth0/auth0-react';

class Baja_ extends Component {
  state = {
    baja: {
      motivo: {
        validate: false,
        required: true,
        error: false,
        label: 'Motivo',
        id: 'motivo',
        name: 'motivo',
        change_param: 'baja',
        value: 0,
        messages: {
          error: '',
        },
      },
      mensaje: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Observación',
        id: 'mensaje',
        name: 'mensaje',
        change_param: 'baja',
        regex: Regex.DESCRIPCION_20_500,
        value: '',
        default_value: '',
        placeholder: 'Escriba su observación aquí',
        messages: {
          error: 'Por favor, ingrese una observación',
          error_extra: RegexExtra.DESCRIPCION_20_500,
        },
      },
    },
    after: false,
    loading: {
      tienda: true,
      error: false,
    },
    forms: {
      baja: false,
      reactivar: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    selects: {
      motivo: [
        {
          label: 'Seleccionar una opción',
          value: 0,
        },
        {
          label: 'No tiene las funcionalidades que necesito',
          value: 1,
        },
        {
          label: 'No entendí la plataforma',
          value: 2,
        },
        {
          label: 'No logre vender como esperaba',
          value: 3,
        },
        {
          label: 'No lo puedo pagar',
          value: 4,
        },
        {
          label: 'Otro',
          value: 5,
        },
      ],
    },
  };

  componentDidMount() {
    this.props.tiendaObtener((err, resp) => {
      this.setState({
        loading: {
          ...this.state.loading,
          tienda: false,
          error: err,
        },
      });
    });
  }

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      {
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: input,
            },
          });
        });
      }
    );
  };

  handleChangeSelect = (e, aditional) => {
    let value = Validate.trim_int(e.target.value);
    let name = e.target.name;

    this.setState({
      [aditional]: {
        ...this.state[aditional],
        [name]: {
          ...this.state[aditional][name],
          value: value,
        },
      },
    });
  };

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  static contextType = Auth0Context;
  logoutBaja = (idCountry) => {
    localStorage.setItem('country', idCountry);
    const { logout } = this.context;
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}?idCountry=${idCountry}`,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.baja.motivo.value > 0) {
      this.setState({
        forms: {
          ...this.state.forms,
          baja: true,
        },
      });

      let form = this.state.baja;

      if (Validate.validar_formulario(form)) {
        this.props.tiendaDarBaja(
          {
            mensaje: form.mensaje.value,
            motivo: form.motivo.value,
          },
          (err, resp) => {
            this.setState({
              forms: {
                ...this.state.forms,
                baja: false,
              },
              after: err ? false : true,
              alert: {
                ...this.state.alert,
                open: true,
                message: resp.message,
                type: err ? 'error' : 'success',
              },
            });
          }
        );
      } else {
        Validate.validar_formulario_actualizar_obj(form, (new_form) => {
          this.setState({
            forms: {
              ...this.state.forms,
              baja: false,
            },
            baja: new_form,
          });
        });
      }
    } else {
      this.setState({
        alert: {
          ...this.state.alert,
          open: true,
          message: 'Debes seleccionar un motivo',
          type: 'error',
        },
      });
    }
  };

  handleSubmitReactivar = (e) => {
    e.preventDefault();

    this.setState({
      forms: {
        ...this.state.forms,
        reactivar: true,
      },
    });

    this.props.tiendaActualizarApagarTemporalmente(
      {
        t_offmode_mensaje: '',
        t_offmode: 0,
      },
      (err, resp) => {
        this.setState(
          {
            forms: {
              ...this.state.forms,
              reactivar: false,
            },
            alert: {
              ...this.state.alert,
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            },
          },
          () => {
            if (!err) this.props.history.push('/');
          }
        );
      }
    );
  };

  render() {
    let classes = this.props.classes;
    const idCountry = this.props.tienda.Country_idCountry;
    return (
      <WrapperLayout
        loading={this.state.loading.tienda}
        error={this.state.loading.error}
      >
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <Grid container spacing={1.5}>
          <ToggleUI show={!this.state.after}>
            <ToggleUI show={this.props.tienda.t_offmode === 2}>
              <NextActionsUI
                title="La tienda se encuentra dada de baja"
                buttons={[
                  <ButtonUI
                    type="callf"
                    label="Reactivar"
                    isLoading={this.state.forms.reactivar}
                    onClickFunc={(e) => this.handleSubmitReactivar(e)}
                  />,
                ]}
              />
            </ToggleUI>
            <ToggleUI show={this.props.tienda.t_offmode !== 2}>
              <Grid item xs={12}>
                <form onSubmit={this.handleSubmit}>
                  <ModuleTitleUI
                    title="Dar de baja"
                    subtitles={[
                      'Al dar de baja tu tienda se cancelará el débito automático o pago manual y se dejará de cobrar.',
                      'Podés encenderla nuevamente cuando lo desees desde esta sección.',
                      'Conservaremos todas las configuraciones actuales durante 60 días por si decidís volver a utilizar Empretienda. ¡Y esperamos que así sea!.',
                    ]}
                  />
                  <CardModuleUI>
                    <Grid container>
                      <Grid item xs={12} pb={3}>
                        <ModuleCardTitleUI
                          title="¿Necesitas ayuda?"
                          subtitles={[
                            <span>
                              Realmente nos importa que te sientas conforme con
                              el servicio de Empretienda. Si existe algún
                              problema que podamos resolver, ayudarte con algo o
                              si crees que tenemos algo en que mejorar, no dudes
                              en{' '}
                              <u>
                                <Link to="/soporte" className={classes.link}>
                                  contactarnos
                                </Link>
                              </u>
                              .
                            </span>,
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12} pb={3}>
                        <SelectUI
                          data={this.state.baja.motivo}
                          options={this.state.selects.motivo}
                          handleChangeSelect={this.handleChangeSelect}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputUI
                          handleChange={this.handleChange}
                          multiline
                          input={this.state.baja.mensaje}
                        />
                      </Grid>
                    </Grid>
                  </CardModuleUI>
                  <ModuleButtonBoxUI>
                    <ButtonUI
                      type="submit"
                      label="Dar de baja"
                      isLoading={this.state.forms.baja}
                    />
                  </ModuleButtonBoxUI>
                </form>
              </Grid>
            </ToggleUI>
          </ToggleUI>
          <ToggleUI show={this.state.after}>
            <NextActionsUI
              title="La tienda ha sido dada de baja con éxito"
              buttons={[
                <ButtonUI
                  type="callf"
                  label="Salir"
                  onClickFunc={(e) => this.logoutBaja(idCountry)}
                />,
              ]}
            />
          </ToggleUI>
        </Grid>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  link: {
    color: 'inherit',
  },
});

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    tiendaActualizarApagarTemporalmente: (data, callback) =>
      dispatch(tiendaActualizarApagarTemporalmente(data, callback)),
    tiendaDarBaja: (data, callback) => dispatch(tiendaDarBaja(data, callback)),
    adminLogout: () => dispatch(adminLogout()),
  };
};

export const Baja = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Baja_)
);
