/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { tiendaObtener, productoAumentoMasivo } from '../../store/actions';
import { Validate, Regex, RegexExtra } from '../../other';
import { Card, CardContent, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  ButtonUI,
  AlertUI,
  ModuleTitleUI,
  ModuleButtonBoxUI,
  SelectUI,
  InputUI,
  ToggleUI,
  NextActionsUI,
} from '../common';
import {
  massive_increase_price_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../amplitude';
import { WrapperLayout } from '../../layouts/WrapperLayout';
class AumentoMasivoPrecios_ extends Component {
  state = {
    aumento_masivo: {
      tipo: {
        validate: false,
        required: true,
        error: false,
        label: 'Cuales productos aumentar',
        id: 'tipo',
        name: 'tipo',
        change_param: 'aumento_masivo',
        value: 0,
        messages: {
          error: 'Por favor, seleccione una opción',
        },
      },
      porcentaje: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Porcentaje de aumento',
        id: 'porcentaje',
        name: 'porcentaje',
        change_param: 'aumento_masivo',
        regex: Regex.NUMERO_ENTERO_POSITIVO,
        value: '',
        default_value: '',
        placeholder: '100',
        messages: {
          help: 'Indica el porcentaje en el cual se van a incrementar los precios de todos los productos',
          error: 'Por favor, ingrese un porcentaje válido',
          error_extra: RegexExtra.NUMERO_ENTERO_POSITIVO,
        },
      },
    },
    after: false,
    loading: {
      tienda: true,
      error: false,
    },
    forms: {
      aumento_masivo: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    selects: {
      tipo: [
        {
          value: 0,
          label: 'Todos los productos',
        },
        {
          value: 1,
          label: 'Solo productos minoristas',
        },
        {
          value: 2,
          label: 'Solo productos mayoristas',
        },
      ],
    },
  };

  componentDidMount() {
    this.props.tiendaObtener((err, resp) => {
      let tipo = !err && resp.data.t_mayorista === 0 ? 1 : 0;

      this.setState({
        loading: {
          ...this.state.loading,
          tienda: false,
          error: err ? true : false,
        },
        aumento_masivo: {
          ...this.state.aumento_masivo,
          tipo: {
            ...this.state.aumento_masivo.tipo,
            value: tipo,
          },
        },
      });
    });
  }

  handleChangeSelect = (e, aditional) => {
    let name = e.target.name;
    let value = Validate.trim_int(e.target.value);

    this.setState({
      [aditional]: {
        ...this.state[aditional],
        [name]: {
          ...this.state[aditional][name],
          value: value,
        },
      },
    });
  };

  handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(
      {
        [aditional]: {
          ...this.state[aditional],
          [name]: {
            ...this.state[aditional][name],
            value: value,
          },
        },
      },
      () => {
        Validate.validate_input(this.state[aditional][name], blur, (input) => {
          this.setState({
            [aditional]: {
              ...this.state[aditional],
              [name]: input,
            },
          });
        });
      }
    );
  };

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      forms: {
        ...this.state.forms,
        aumento_masivo: true,
      },
    });

    let form = this.state.aumento_masivo;
    if (Validate.validar_formulario(form)) {
      let porcentaje = form.porcentaje.value;
      let tipo = form.tipo.value;

      if (porcentaje > 0 && porcentaje <= 500) {
        this.props.productoAumentoMasivo(
          {
            porcentaje: porcentaje,
            tipo: tipo,
          },
          (err, resp) => {
            this.setState(
              {
                forms: {
                  ...this.state.forms,
                  aumento_masivo: false,
                },
                after: err ? false : true,
                alert: {
                  ...this.state.alert,
                  open: true,
                  message: resp.message,
                  type: err ? 'error' : 'success',
                },
              },
              () => {
                createAmplitudeEventWithDevice(
                  massive_increase_price_amplitude_events
                    .aumento_masivo_selecciona_aumentar.event_name
                );
              }
            );
          }
        );
      } else {
        this.setState({
          forms: {
            ...this.state.forms,
            aumento_masivo: false,
          },
          alert: {
            ...this.state.alert,
            open: true,
            message: 'El porcentaje debe estar entre 0 y 500',
            type: 'error',
          },
        });
      }
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        this.setState({
          forms: {
            ...this.state.forms,
            aumento_masivo: false,
          },
          aumento_masivo: new_form,
        });
      });
    }
  };

  render() {
    let classes = this.props.classes;
    return (
      <WrapperLayout
        loading={this.state.loading.tienda}
        error={this.state.loading.error}
      >
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <ToggleUI show={this.state.after}>
              <NextActionsUI
                title="¡Los precios han sido aumentados con éxito!"
                buttons={[
                  <ButtonUI
                    type="link"
                    label="Ver mis productos"
                    link="/productos"
                  />,
                  <ButtonUI
                    type="link"
                    label="Volver a aumentar"
                    link="/productos/aumento-masivo"
                    external_link
                  />,
                ]}
              />
            </ToggleUI>
            <ToggleUI show={!this.state.after}>
              <form onSubmit={this.handleSubmit}>
                <ModuleTitleUI
                  title="Aumento masivo de precios"
                  subtitles={[
                    'Sirve para aumentar masivamente el precio de todos tus productos en función a un porcentaje dado de una manera simple y rápida.',
                    'Una vez aumentados los precios de manera masiva, no es posible volver atrás. No se pueden disminuir los precios de manera masiva.',
                    'Si el producto está en oferta, se aumenta el precio de ambos (con y sin oferta) teniendo en cuenta el porcentaje configurado.',
                  ]}
                />
                <Card className={classes.card}>
                  <CardContent className={classes.card_content}>
                    <Grid container spacing={1.5}>
                      <Grid item xs={12}>
                        <InputUI
                          handleChange={this.handleChange}
                          input={this.state.aumento_masivo.porcentaje}
                          end_adornment="%"
                        />
                      </Grid>
                      <ToggleUI show={this.props.tienda.t_mayorista === 1}>
                        <Grid item xs={12}>
                          <SelectUI
                            data={this.state.aumento_masivo.tipo}
                            options={this.state.selects.tipo}
                            handleChangeSelect={this.handleChangeSelect}
                          />
                        </Grid>
                      </ToggleUI>
                    </Grid>
                  </CardContent>
                </Card>
                <ModuleButtonBoxUI lgSize={3}>
                  <ButtonUI
                    type="submit"
                    label="Aumentar precios"
                    isLoading={this.state.forms.aumento_masivo}
                    minHeight={true}
                  />
                </ModuleButtonBoxUI>
              </form>
            </ToggleUI>
          </Grid>
        </Grid>
      </WrapperLayout>
    );
  }
}

const styles = (theme) => ({
  card: theme.card.card,
  card_header: theme.card.card_header,
  card_header_title: theme.card.card_header_title,
  card_content: theme.card.card_content,
  card_actions: theme.card.card_actions_public,
  text_link: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    tiendaObtener: (callback) => dispatch(tiendaObtener(callback)),
    productoAumentoMasivo: (data, callback) =>
      dispatch(productoAumentoMasivo(data, callback)),
  };
};

export const AumentoMasivoPrecios = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AumentoMasivoPrecios_)
);
