import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Regex, RegexExtra } from '../../../other';
import { Button, Grid } from '@mui/material';
import { InputUI, ModuleTitleUI, CardModuleUI } from '../../common';
import { CardTitle, CardText } from '../subcomponents';
class ApagarTienda_ extends Component {
  state = {
    apagar_temporalmente: {
      t_offmode_mensaje: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Mensaje para tus clientes',
        id: 't_offmode_mensaje',
        name: 't_offmode_mensaje',
        change_param: 'apagar_temporalmente',
        regex: Regex.DESCRIPCION_0_200,
        value: '',
        default_value: '',
        placeholder: 'Estaremos cerrados hasta el ....',
        messages: {
          error: 'Por favor, ingrese un mensaje válido',
          error_extra: RegexExtra.DESCRIPCION_0_200,
        },
      },
    },
    loading: {
      apagar_temporalmente: true,
      error: false,
    },
    forms: {
      apagar_temporalmente_apagar: false,
      apagar_temporalmente_encender: false,
    },
    modals: {
      apagar_temporalmente_apagar: false,
      apagar_temporalmente_encender: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmitApagar}>
        <Grid container spacing={0.5} justifyContent="end">
          <Grid item xs={12}>
            <ModuleTitleUI title="Importación de productos" />
          </Grid>
          <Grid item xs={12}>
            <CardModuleUI>
              <CardTitle title="Antes de comenzar apaga tu tienda temporalmente" />
              <Grid item>
                <CardText text="Para evitar problemas en el stock de tus productos actuales y que se realicen operaciones durante el proceso de importación, te recomendamos apagar tu tienda temporalmente." />
              </Grid>
              <InputUI
                handleChange={this.props.handleChange}
                input={this.props.input_apagar_temporalmente}
              />
            </CardModuleUI>
          </Grid>
          <Grid item xs={12} textAlign="end" mt={1.5}>
            <Button
              variant="contained"
              type="submit"
              sx={{ width: { xs: '100%', sm: '180px' } }}
              isLoading={this.props.apagar_temporalmente}
            >
              Apagar tienda
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tienda: state.tienda,
  };
};

export const ApagarTienda = connect(mapStateToProps)(ApagarTienda_);
