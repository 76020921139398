/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Grid } from '@mui/material';
import { ButtonUI, CustomCardUI } from '../../common';
import { Config } from '../../../other';

const EnviosPersonalizados = (props) => {
  const { metodos_envio } = props;

  return (
    <Grid item lg={4} sm={6} xs={12}>
      <CustomCardUI
        title="Envíos personalizados"
        subtitle={
          metodos_envio.me_personalizados ? 'Método activo' : 'Método no activo'
        }
        info="Podrás configurar tus propios medios de envío. Totalmente personalizados con nombre, precio y forma de pago online o contrateembolso."
        image={`${Config.CLOUDFRONT_CDN_PROD}/panel/medios_envio/enviopersonalizado-card-img.png`}
      >
        <ButtonUI
          type="link"
          link="/metodos-envio/envios-personalizados"
          label="Configuración"
          size="large"
        />
      </CustomCardUI>
    </Grid>
  );
};

export { EnviosPersonalizados };
