import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { Regex, RegexExtra, Validate } from '../../other';
import {
  amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../components/amplitude';
import {
  AlertUI,
  ButtonUI,
  InputUI,
  ModuleButtonBoxUI,
  ModuleTitleUI,
  NextActionsUI,
  ToggleUI,
} from '../../components/common';
import { soporteEnviar } from '../../store/actions';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import { useHistory } from 'react-router-dom';

const SupportPage_ = ({ idCountry, supportSend, isDebtor }) => {
  const [state, setState] = useState({
    after: false,
    next_message: '',
    support: {
      support_message: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Mensaje',
        id: 'support_message',
        name: 'support_message',
        change_param: 'support',
        regex: Regex.DESCRIPCION_20_5000,
        value: '',
        default_value: '',
        placeholder: 'Escriba su mensaje aquí',
        messages: {
          error: 'Por favor, ingrese un mensaje válido',
          error_extra: RegexExtra.DESCRIPCION_20_5000,
        },
      },
    },
    loading: {
      support: false,
      error: false,
    },
    forms: {
      support: false,
    },
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  });
  const router = useHistory();

  const handleChange = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    setState((prevState) => {
      const updatedAditional = {
        ...prevState[aditional],
        [name]: {
          ...prevState[aditional][name],
          value: value,
        },
      };

      Validate.validate_input(updatedAditional[name], blur, (input) => {
        updatedAditional[name] = input;
      });

      return {
        ...prevState,
        [aditional]: updatedAditional,
      };
    });
  };

  const handleCloseAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: {
        ...prevState.alert,
        open: false,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setState((prevState) => ({
      ...prevState,
      forms: {
        ...prevState.forms,
        support: true,
      },
    }));

    let form = state.support;

    if (Validate.validar_formulario(form)) {
      supportSend(
        {
          so_mensaje: form.support_message.value, //so_mensaje => Key que recibe el endpoint
        },
        (err, resp) => {
          setState((prevState) => ({
            ...prevState,
            forms: {
              ...prevState.forms,
              support: false,
            },
            after: err ? false : true,
            next_message: err ? '' : resp.message,
            alert: {
              ...prevState.alert,
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            },
          }));
          createAmplitudeEventWithDevice(
            amplitude_events.ayuda_selecciona_enviar.event_name
          );
          if (isDebtor) {
            router.push('/plan');
          }
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(form, (new_form) => {
        setState((prevState) => ({
          ...prevState,
          forms: {
            ...prevState.forms,
            support: false,
          },
          support: new_form,
        }));
      });
    }
  };

  const wording = {
    ARG: {
      subtitleSection: '¿Necesitás ayuda? Podés revisar nuestras',
      titleCard: '¿Seguís con dudas? Contactanos:',
    },
    COL: {
      subtitleSection: '¿Necesitas ayuda? Puedes revisar nuestras',
      titleCard: '¿Sigues con dudas? Contáctanos:',
    },
  };

  return (
    <WrapperLayout loading={state.loading.support} error={state.loading.error}>
      <AlertUI
        open={state.alert.open}
        message={state.alert.message}
        type={state.alert.type}
        handleCloseAlert={handleCloseAlert}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ModuleTitleUI title={'Enviar consulta'} />
          <Typography as="p" fontWeight="1rem" mb={1.5}>
            {wording[idCountry].subtitleSection}{' '}
            <a
              href="https://empretienda.helpjuice.com/"
              rel="noreferrer"
              target="_blank"
            >
              preguntas frecuentes
            </a>
            .{' '}
          </Typography>
          <ToggleUI show={!state.after}>
            <form onSubmit={handleSubmit}>
              <Card
                sx={{
                  borderRadius: '4px',
                  boxShadow: '0 0.07em 0.125em 0 rgba(0,0,0,.15)',
                  overflow: 'visible',
                }}
              >
                <CardContent>
                  <Typography
                    as="p"
                    fontSize="1.12rem"
                    fontWeight="600"
                    my={1.5}
                  >
                    {wording[idCountry].titleCard}
                  </Typography>
                  <Typography as="p" fontWeight="1rem" my={1.5}>
                    Responderemos tu consulta en 24 horas hábiles a tu correo
                    electrónico.
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <InputUI
                        handleChange={handleChange}
                        multiline
                        input={state.support.support_message}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <ModuleButtonBoxUI>
                <ButtonUI
                  type="submit"
                  label="Enviar"
                  isLoading={state.forms.support}
                  minHeight={true}
                />
              </ModuleButtonBoxUI>
            </form>
          </ToggleUI>
          <ToggleUI show={state.after}>
            <Card
              sx={{
                borderRadius: '4px',
                boxShadow: '0 0.07em 0.125em 0 rgba(0,0,0,.15)',
                overflow: 'visible',
              }}
            >
              <CardContent>
                <Grid container spacing={1.5}>
                  <Grid item xs={12}>
                    <NextActionsUI title={state.next_message} buttons={[]} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </ToggleUI>
        </Grid>
      </Grid>
    </WrapperLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    idCountry: state.tienda.Country_idCountry || 'ARG',
    isDebtor: state.tienda.t_deuda,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    supportSend: (data, callback) => dispatch(soporteEnviar(data, callback)),
  };
};

export const SupportPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportPage_);
