import React from 'react';
import { ButtonUI, DropdownUI, MenuItemUI } from '../../common';
import { CustomCardUI } from '../components/CustomCardUI';
import { GridItemCard } from '../components/GridItemCard';
import { Config } from '../../../other';

const TodoPago = (props) => {
  const {
    metodos_pago,
    forms,
    modalHandler,
    handleDeshabilitarTodoPagoSubmit,
  } = props;

  const cargarBoton = () => {
    if (metodos_pago.mp_todopago) {
      return (
        <DropdownUI
          label="Configuración"
          size="large"
          isLoading={forms.desactivar_todopago}
        >
          <MenuItemUI
            type="callf"
            onClickFunction={() =>
              modalHandler(true, 'activar_editar_todopago')
            }
          >
            Cambiar cuenta
          </MenuItemUI>
          <MenuItemUI
            type="callf"
            onClickFunction={handleDeshabilitarTodoPagoSubmit}
          >
            Desactivar
          </MenuItemUI>
        </DropdownUI>
      );
    } else {
      return (
        <ButtonUI
          type="callf"
          onClickFunc={() => modalHandler(true, 'activar_editar_todopago')}
          label="Activar"
          size="large"
        />
      );
    }
  };

  return (
    <GridItemCard lg={6}>
      <CustomCardUI
        title="Todo Pago"
        subtitle={
          metodos_pago.mp_todopago ? 'Método activo' : 'Método no activo'
        }
        info="Te permitirá que tus clientes puedan pagar con tarjetas de crédito y débito. Todo Pago te cobrará una comisión por cada venta que realices."
        image={`${Config.CLOUDFRONT_CDN_PROD}/panel/medios_pago/todopago-card-img.png`}
      >
        {cargarBoton()}
      </CustomCardUI>
    </GridItemCard>
  );
};

export { TodoPago };
