import { TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';

const SalesTableHead = ({ classes }) => {
  return (
    <TableHead>
      <TableRow sx={{ fontSize: '13px' }}>
        <TableCell className={classes.table_cell_title}>#</TableCell>
        <TableCell className={classes.table_cell_title}>Cliente</TableCell>
        <TableCell className={classes.table_cell_title}>Pago</TableCell>
        <TableCell className={classes.table_cell_title}>Fecha</TableCell>
        <TableCell className={classes.table_cell_title}>
          Método de pago
        </TableCell>
        <TableCell className={classes.table_cell_title}>
          Método de envío
        </TableCell>
        <TableCell className={classes.table_cell_title}>Envío</TableCell>
        <TableCell className={classes.table_cell_title}>
          Costo del envío
        </TableCell>
        <TableCell className={classes.table_cell_title}>
          Monto (sin envío)
        </TableCell>
        <TableCell className={classes.table_cell_title}>Archivar</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default SalesTableHead;
