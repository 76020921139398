const BRAZE_ERRORS = {
  /** * Expiration is a required field for Braze usage. */
  EXPIRATION_REQUIRED: '10',
  /** * Non-matching public key or a general uncaught error. */
  DECODING_ERROR: '20',
  /** * The expected and actual subjects are not the same. */
  SUBJECT_MISMATCH: '21',
  /** * The token provided has expired. */
  EXPIRED: '22',
  /** * The token payload is invalid. */
  INVALID_PAYLOAD: '23',
  /** * The algorithm of the token is not supported. */
  INCORRECT_ALGORITHM: '24',
  /** * The public key could not be converted into the proper format. */
  PUBLIC_KEY_ERROR: '25',
  /** * No token was provided in the request. */
  MISSING_TOKEN: '26',
  /** * No public keys matched the provided token. */
  NO_MATCHING_PUBLIC_KEYS: '27',
  /** * Not all user ids in the request payload match as is required. */
  PAYLOAD_USER_ID_MISMATCH: '28',
};

export { BRAZE_ERRORS };
