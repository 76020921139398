/* eslint-disable no-unreachable */
import Reducers from '../reducers';

const initState = [];

const dominioReducer = (state = initState, action) => {
  let dominios;
  switch (action.type) {
    case Reducers.DOMINIO_GUARDAR:
      return action.data;
    case Reducers.DOMINIO_AGREGAR:
      return [...state, action.data];
    case Reducers.DOMINIO_EDITAR:
      let dominio_editado = action.data;
      dominios = state.map((dominio) => {
        return dominio.d_nombre === dominio_editado.d_nombre
          ? dominio_editado
          : dominio;
      });
      return dominios;
    case Reducers.DOMINIO_MIGRAR:
      let dominio_migrado = action.data;
      dominios = state.map((dominio) => {
        return dominio.d_pago === 0
          ? {
              ...dominio,
              d_nombre: dominio_migrado.d_nombre,
            }
          : dominio;
      });
      return dominios;
    case Reducers.DOMINIO_EDITAR_REGISTROS:
      let zona = action.data;
      dominios = state.map((dominio) => {
        if (dominio.d_nombre === zona.d_nombre) {
          return {
            ...dominio,
            d_metadata: {
              ...dominio.d_metadata,
              dns_zone: {
                ...dominio.d_metadata.dns_zone,
                records: zona.records,
              },
            },
          };
        } else {
          return dominio;
        }
      });
      return dominios;
    case Reducers.AUTH_UNSET:
      return initState;
    default:
      return state;
  }
};

export { dominioReducer };
