import React, { useEffect, useState } from 'react';
import { AlertUI, ToggleUI } from '../../../../components/common';
import { useSelector } from 'react-redux';
import { ShareDomainLinkContainer } from './ShareDomainLinkContainer';
import { CardStoreTips } from './CardStoreTips';
import { CongratulationsMessage } from '../CongratulationsMessage';

export const StepsCompletedMessages = ({
  domainStore,
  salesAmount,
  showCardStoreTips,
  setShowCardStoreTips,
  showCardSuccess,
  setShowCardSuccess,
}) => {
  const { idTienda, idCountry } = useSelector((state) => ({
    idTienda: state.tienda.idTienda,
    idCountry: state.tienda.Country_idCountry || 'ARG',
  }));

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'error',
  });

  const handleSkipTip = () => {
    localStorage.setItem('show-message-tips', 'tips');
    setShowCardSuccess(false);
    setShowCardStoreTips(true);
  };

  const handleCloseCardTips = () => {
    localStorage.setItem('show-message-tips', 'none');
    setShowCardStoreTips(false);
  };

  useEffect(() => {
    const showMessageTips = localStorage.getItem('show-message-tips');

    if (showMessageTips === 'none') {
      setShowCardSuccess(false);
      setShowCardStoreTips(false);
    } else if (showMessageTips === 'tips' || salesAmount === 1) {
      setShowCardSuccess(false);
      setShowCardStoreTips(true);
    }
  }, []);

  return (
    <>
      <AlertUI
        open={alert.open}
        type={alert.type}
        hideSeconds={5000}
        message={alert.message}
        handleCloseAlert={() => setAlert({ ...alert, open: false })}
      />
      <ToggleUI show={showCardSuccess}>
        <CongratulationsMessage idTienda={idTienda} />
        <ShareDomainLinkContainer
          setAlert={setAlert}
          idCountry={idCountry}
          domainStore={domainStore}
          handleSkipTip={handleSkipTip}
        />
      </ToggleUI>
      <ToggleUI show={showCardStoreTips}>
        <CardStoreTips
          idCountry={idCountry}
          salesAmount={salesAmount}
          handleCloseCardTips={handleCloseCardTips}
        />
      </ToggleUI>
    </>
  );
};
