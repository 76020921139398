import React, { Component } from 'react';
import { Grid, Input, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  ButtonUI,
  ModuleTitleUI,
  CardModuleUI,
  AlertUI,
  ToggleUI,
} from '../../common';
import { Page, SpinnerProgress, CardText } from '../subcomponents';
import { Validate } from '../../../other';
import { connect } from 'react-redux';
import { workbookBufferToRows } from './workBookUtils';
import {
  createAmplitudeEventWithDevice,
  import_amplitude_events,
} from '../../amplitude';
class ErroresEnFormato_ extends Component {
  state = {
    importDisabled: true,
    filename: 'Subí tu planilla de productos aquí',
    icon: (
      <svg
        width="25"
        height="16"
        viewBox="0 0 25 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.85 6.04C19.17 2.59 16.14 0 12.5 0C9.61 0 7.1 1.64 5.85 4.04C2.84 4.36 0.5 6.91 0.5 10C0.5 13.31 3.19 16 6.5 16H19.5C22.26 16 24.5 13.76 24.5 11C24.5 8.36 22.45 6.22 19.85 6.04ZM14.5 9V13H10.5V9H7.5L12.5 4L17.5 9H14.5Z"
          fill="#242424"
        />
      </svg>
    ),
    show_spinner_progress: false,
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  };

  componentDidMount() {
    createAmplitudeEventWithDevice(
      import_amplitude_events.importar_producto_failed.event_name,
      import_amplitude_events.importar_producto_failed.event_params
    );
  }

  asignarPlanilla = async (ev) => {
    if (!(ev.target.files[0] && ev.target.files[0].type)) return;
    const file_type = ev.target.files[0].type;

    if (
      Validate.in_array(file_type, [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ])
    ) {
      const file_rows = ev.target.files;
      this.setState({
        filename: 'Cargando archivo',
        show_spinner_progress: true,
      });

      const file = await file_rows?.[0]?.arrayBuffer();
      const { rows } = workbookBufferToRows(file);

      this.setState({
        filename: file_rows[0].name,
        importDisabled: false,
        icon: (
          <svg
            width="17"
            height="20"
            viewBox="0 0 17 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 0H2.5C1.4 0 0.51 0.9 0.51 2L0.5 18C0.5 19.1 1.39 20 2.49 20H14.5C15.6 20 16.5 19.1 16.5 18V6L10.5 0ZM12.5 16H4.5V14H12.5V16ZM12.5 12H4.5V10H12.5V12ZM9.5 7V1.5L15 7H9.5Z"
              fill="#53B755"
            />
          </svg>
        ),
        show_spinner_progress: false,
      });

      this.props.asignarPlanilla(rows); //asignar la planilla al ciclo de vida
    } else {
      this.setState({
        alert: {
          ...this.state.alert,
          open: true,
          type: 'error',
          message: 'Solo se permiten archivos .xlsx',
        },
      });
    }
  };

  iniciarValidacion = () => {
    this.props.validarFormatoStart(); //pasar a la siguiente etapa: validarPlanilla
  };

  handleCloseAlert = () => {
    this.setState({
      alert: {
        ...this.state.alert,
        open: false,
      },
    });
  };

  render() {
    const { classes, handleVolverAApagar, error_messages } = this.props;

    // const errores = ['No se encontró la columna Nombre de producto','Se encontraron más de 50.000 productos','Se detectaron columnas invalidas que fueron agregadas al documento']

    return (
      <>
        <AlertUI
          open={this.state.alert.open}
          message={this.state.alert.message}
          type={this.state.alert.type}
          handleCloseAlert={this.handleCloseAlert}
        />
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <ModuleTitleUI
              title="Errores encontrados"
              button={
                <span>
                  <ButtonUI
                    type="callf"
                    fullWidth={false}
                    variant="outlined"
                    onClickFunc={(e) => {
                      createAmplitudeEventWithDevice(
                        import_amplitude_events.ver_tutorial.event_name
                      );
                      const link =
                        'https://recursos.empretienda.com/importacion-masiva.pdf';
                      window.open(link, '_blank');
                    }}
                    label={
                      <div className={this.props.classes.icon_center}>
                        <svg
                          className={this.props.classes.icon_badge}
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z"
                            fill="#53B755"
                          />
                        </svg>{' '}
                        Tutorial
                      </div>
                    }
                  />
                </span>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <CardModuleUI>
              <Grid container>
                <Grid item xs={12}>
                  <p className={classes.import_text}>
                    Se detectaron{' '}
                    <span className={classes.error_text}>errores</span> en su
                    planilla de productos
                  </p>
                  <ul className={classes.list_errors}>
                    {error_messages.map((error, i) => (
                      <li
                        className={classes.icon_center}
                        key={i}
                        style={{ marginBottom: '24px' }}
                      >
                        <span className={classes.icon_badge}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z"
                              fill="#FF0000"
                            />
                          </svg>
                        </span>
                        {error}
                      </li>
                    ))}
                  </ul>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.import_container}>
                    <Grid container alignItems="center" justifyContent="center">
                      <ToggleUI show={!this.state.show_spinner_progress}>
                        <Grid item xs={12}>
                          <label htmlFor="import-excel-file">
                            <Input
                              style={{
                                display: 'none',
                              }}
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              id="import-excel-file"
                              type="file"
                              onChange={(e) => this.asignarPlanilla(e)}
                            />
                            <Button
                              variant="text"
                              size="large"
                              color="info"
                              component="span"
                              className={classes.button_upload}
                              fullWidth
                            >
                              <div className={classes.icon_center}>
                                <span className={classes.icon_badge}>
                                  {this.state.icon}
                                </span>
                                {this.state.filename}
                              </div>
                            </Button>
                          </label>
                        </Grid>
                      </ToggleUI>
                      <ToggleUI show={this.state.show_spinner_progress}>
                        <Grid item xs={12}>
                          <div className={this.props.classes.spinner}>
                            <SpinnerProgress />
                            <CardText text="Cargando archivo" />
                          </div>
                        </Grid>
                      </ToggleUI>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <p className={classes.format_text}>
                    Formatos permitidos .xlsx.
                  </p>
                </Grid>
              </Grid>
            </CardModuleUI>
          </Grid>
          <Grid item xs={12}>
            <div
              className={classes.buttons_mobile}
              style={{ marginTop: '36px' }}
            >
              <ButtonUI
                fullWidth={true}
                type="callf"
                label="Importar productos"
                disabled={this.state.importDisabled}
                onClickFunc={(e) => this.iniciarValidacion()}
              />
            </div>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item className={classes.page_mobile}>
                <Page firstvalue="1" lastvalue="de 4" />
              </Grid>
              <div className={classes.buttons_desktop}>
                <Grid item>
                  <ButtonUI
                    fullWidth={false}
                    type="callf"
                    variant="text"
                    label="Volver"
                    onClickFunc={(e) => handleVolverAApagar()}
                  />
                  <ButtonUI
                    fullWidth={false}
                    type="callf"
                    label="Importar productos"
                    disabled={this.state.importDisabled}
                    onClickFunc={(e) => this.iniciarValidacion()}
                  />
                </Grid>
              </div>
              <div
                className={classes.buttons_mobile}
                style={{ marginTop: '20px' }}
              >
                <Grid item>
                  <ButtonUI
                    fullWidth={false}
                    type="callf"
                    variant="text"
                    label="Volver"
                    onClickFunc={(e) => handleVolverAApagar()}
                  />
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

const styles = (theme) => ({
  import_container: {
    border: '1px dashed #242424',
    borderRadius: '6px',
    padding: '40px',
    [theme.breakpoints.down('md')]: {
      padding: '75px 0px 75px 0px',
      margin: '10px 0px 0px 0px',
    },
  },
  icon_center: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px 0px',
    textTransform: 'none',
    fontSize: '1rem',
    lineHeight: '18.75px',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.87rem',
      lineHeight: '16px',
    },
  },
  icon_badge: {
    marginRight: '10px',
    [theme.breakpoints.down('md')]: {
      marginRight: '5px',
    },
  },
  button_upload: {
    marginTop: '2px',
  },
  import_text: {
    fontWeight: '500',
    fontSize: '1.25rem',
    lineHeight: '23px',
    color: '#000000',
    marginBottom: '24px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '0px',
      fontSize: '0.87rem',
      lineHeight: '20px',
    },
  },
  error_text: {
    color: '#FF0000',
  },
  format_text: {
    fontWeight: '400',
    lineHeight: '16px',
    fontSize: '0.87rem',
  },
  list_errors: {
    padding: '0px',
    margin: '20px 0px 40px 0px',
  },
  error_item: {
    marginTop: '24px',
  },
  buttons_desktop: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  buttons_mobile: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  page_mobile: {
    [theme.breakpoints.down('md')]: {
      marginTop: '24px',
    },
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
});

const mapStateToProps = (state) => {
  return {
    productos: state.productos,
  };
};
const mapDispatchToProps = null;

export const ErroresEnFormato = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ErroresEnFormato_));
